import styled from 'styled-components'

export const Create = styled.button`
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  padding: 0 8px;
  font-weight: bold;
  text-decoration: none;
  color: #4bf28b;
  background-color: transparent;

  &:hover {
    color: #7bffbb;
  }
`
