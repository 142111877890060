import styled from 'styled-components'

export default function Tabs({ active, onClick }) {
  const items = [
    {
      label: 'Biblioteca',
      value: 'library',
    },
    {
      label: 'Pesquisar',
      value: 'search',
    },
  ]

  async function onClickTab(e) {
    onClick(e)
  }

  return (
    <Container>
      {items.map((e, i) => {
        return (
          <TabListItem
            key={i}
            active={active.includes(e.value)}
            onClick={() => onClickTab(e)}
          >
            {e.label}
          </TabListItem>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  border-bottom: 3px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.foregroundAlt};
  font-size: 0.9rem;
  font-weight: 500;
`

const TabListItem = styled.div`
  height: 35px;
  background-color: ${props =>
    props.active ? props.theme.colors.primary : 'transparent'};
  color: ${props => (props.active ? '#fff' : props.theme.colors.foreground)};
  margin-right: 8px;
  padding: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`
