import LogoIcon from '~/components/imgs/logotripmee_parafundobranco.png'
import { Container, Footer, Header, Section } from './styles'

export default function About() {
  return (
    <>
      <h1 className='page-header'>Sobre</h1>

      <Container>
        <Header>
          <img src={LogoIcon} alt='Logotipo TriMee Agent' />

          <span>Plataforma do consultor de Viagens</span>
        </Header>

        <Section>
          <p style={{ fontSize: '12px' }}>
            <b>Para o consultor de Viagens</b>
          </p>

          <p style={{ fontSize: '12px' }}>
            Já pensou em organizar viagens na palma da mão? Com o Tripmee você
            pode criar e organizar as viagens dos seus clientes com toda
            facilidade e tecnologia.
          </p>

          <p style={{ fontSize: '12px' }}>
            Crie o roteiro de viagem e ofereça esse serviço ao seu cliente. Nele
            você terá várias funcionalidades. Cotação da moeda, Checklist,
            Cartão de Embarque, Day by Day, Vouchers de hoteis, voos, passeios e
            ingressos, dicas e experiências no destino.
          </p>

          <p style={{ fontSize: '12px' }}>
            Tudo em um só lugar. Crie a viagem personalizada dos seu cliente e
            entregue essa facilidade na palma da mão. Tripmee.
          </p>
        </Section>

        <Footer>
          <span>Versão {process.env.REACT_APP_APP_VERSION}</span>

          <a href='privacy-policy.pdf'>
            Política de privacidade e uso de cookies
          </a>

          <a href='Termos_de_Serviço_de_Uso_da_Tripmee_Final.pdf'>
            Termos e condições
          </a>
        </Footer>
      </Container>
    </>
  )
}
