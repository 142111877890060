import { useSelector } from 'react-redux'
import { getApiClient } from '~/services/apiClient'

export default function useAxios() {
  const auth = useSelector(state => state.auth)

  const apiClient = getApiClient()

  apiClient.defaults.headers.Authorization = `Bearer ${auth.accessToken}`

  return { apiClient }
}
