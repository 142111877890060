import * as S from './styles'
import { AvatarSoft } from '~/components/Header/styles'

export default function Header({
  userName,
  currentContactPerfilImage,
  currentContactName,
}) {
  return (
    <S.ChatHeader>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {currentContactPerfilImage && (
          <AvatarSoft
            style={{
              width: '47px',
              height: '47px',
              backgroundSize: 'cover',
              backgroundImage: `url(data:image/*;base64,${currentContactPerfilImage})`,
            }}
          />
        )}

        {!currentContactPerfilImage && (
          <S.AvatarIcon className='fas fa-user-circle'></S.AvatarIcon>
        )}

        <S.CurrentContactName>{currentContactName}</S.CurrentContactName>

        <S.WhatsAppIcon className='fab fa-whatsapp' />
      </div>

      {/* <S.ContactActions>
        <S.IconRight className='far fa-clock'></S.IconRight>{' '}
        <S.IconRight className='far fa-check-circle'></S.IconRight>{' '}
        <S.IconRight className='fas fa-closed-captioning'></S.IconRight>

        {userPerfilImage && (
          <AvatarSoft
            style={{
              width: '28px',
              height: '28px',
              backgroundSize: 'cover',
              marginRight: '6px',
              padding: '0px 6px',
              backgroundImage: `url(data:image/*;base64,'${userPerfilImage}')`,
            }}
          />
        )}

        {!userPerfilImage && (
        <S.AvatarIcon className='fas fa-user-circle'></S.AvatarIcon>

        <S.IconLeft className='fas fa-ellipsis-v'></S.IconLeft>{' '}
      </S.ContactActions> */}
    </S.ChatHeader>
  )
}
