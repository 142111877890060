import { Form } from '@unform/web'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { DeleteButton, SaveButton } from '~/components/Button/styles'
import CitiesSelect from '~/components/CitiesSelect'
import Input from '~/components/Input'
import ValueInput from '~/components/ValueInput'
import { getDateFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import { Container, LeftCol, RightCol } from './styles'
import TextArea from '~/components/TextArea'

export default function Edit({ toEdit, onClose, onDelete }) {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)

  const [carToEdit, setCarToEdit] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelcar/${toEdit.entity.id}`,
        )

        setCarToEdit({
          locator: data.locator,
          provider: data.provider,
          originPurchase: data.originPurchase,
          origin: {
            label: data.origin,
            value: data.originId,
          },
          destiny: {
            label: data.destiny,
            value: data.destinyId,
          },
          originAddress: data.originAddress,
          destinyAddress: data.destinyAddress,
          checkin: getDateFormated(data.checkIn, 'YYYY-MM-DD'),
          checkout: getDateFormated(data.checkOut, 'YYYY-MM-DD'),
          timeCheckin: getDateFormated(data.checkIn, 'HH:mm'),
          timeCheckout: getDateFormated(data.checkOut, 'HH:mm'),
          tips: data.tips,
          description: data.description,
          id: data.id,
          travelId: data.travelId,
        })

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    getSource()
  }, [])

  async function handleSubmit() {
    try {
      setLoading(true)

      await axiosApis.put(`${process.env.REACT_APP_ENDPOINT_APIS}/travelcar`, {
        id: carToEdit.id,
        locator: carToEdit.locator,
        originPurchase: carToEdit.originPurchase,
        provider: carToEdit.provider,
        origin: carToEdit.origin.value,
        originAddress: carToEdit.originAddress,
        destiny: carToEdit.destiny.value,
        destinyAddress: carToEdit.destinyAddress,
        checkin: carToEdit.checkin + ' ' + carToEdit.timeCheckin,
        checkout: carToEdit.checkout + ' ' + carToEdit.timeCheckout,
        tips: carToEdit.tips,
        description: carToEdit.description,
        travelId: carToEdit.travelId,
      })

      setLoading(false)

      toast.success('Transfer/Locação editado com sucesso!')

      onClose(true)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível editar o Transfer/Locação.')
    }
  }

  async function handleSubmitDelete() {
    setLoading(true)

    await onDelete({ id: toEdit.entity.id, type: toEdit.entity.type })

    await onClose(true)

    setLoading(false)
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} md={6} lg={4}>
            <Input
              disabled={false}
              name='originPurchase'
              label={'Origem da Compra'}
              maxLength={30}
              value={carToEdit.originPurchase}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  originPurchase: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Input
              disabled={false}
              name='locator'
              label={'Código Reserva/Localizador'}
              maxLength={20}
              value={carToEdit.locator}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  locator: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Input
              disabled={false}
              name='provider'
              label={'Locadora/Fornecedor'}
              maxLength={30}
              value={carToEdit.provider}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  provider: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Input
              name='originAddress'
              label={'Local de chegada'}
              maxLength={200}
              value={carToEdit.originAddress}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  originAddress: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <CitiesSelect
              name='origin'
              label={'Cidade do Transfer/Locação'}
              placeholder={'Pesquise por uma cidade...'}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  origin: e.target.value,
                })
              }
              defaultValue={carToEdit.origin}
            />
          </Col>

          <Col sm={12} md={6} lg={2}>
            <Input
              disabled={false}
              name='checkin'
              label={'Início *'}
              required
              type='date'
              value={carToEdit.checkin}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  checkin: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={2}>
            <Input
              disabled={false}
              name='timeCheckin'
              label={'Hora do Início *'}
              required
              type='time'
              value={carToEdit.timeCheckin}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  timeCheckin: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Input
              name='destinyAddress'
              label={'Local de chegada'}
              maxLength={200}
              value={carToEdit.destinyAddress}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  destinyAddress: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <CitiesSelect
              name='destiny'
              label={'Cidade da Chegada/Entrega'}
              placeholder={'Pesquise por uma cidade...'}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  destiny: e.target.value,
                })
              }
              defaultValue={carToEdit.destiny}
            />
          </Col>

          <Col sm={12} md={6} lg={2}>
            <Input
              disabled={false}
              name='checkout'
              type='date'
              label={'Fim *'}
              required
              value={carToEdit.checkout}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  checkout: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={2}>
            <Input
              disabled={false}
              name='timeCheckin'
              label={'Hora do Fim *'}
              required
              type='time'
              value={carToEdit.timeCheckout}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  timeCheckout: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={3}>
            <ValueInput
              name='tips'
              type='value'
              title='Amenidades'
              placeholder='Descrição'
              defaultValue={carToEdit.tips}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  tips: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <TextArea
              name='description'
              label='Observação'
              placeholder='Observação'
              defaultValue={carToEdit.description}
              onChange={e =>
                setCarToEdit({
                  ...carToEdit,
                  description: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <LeftCol>
            <DeleteButton onClick={handleSubmitDelete} type='button'>
              Excluir
            </DeleteButton>
          </LeftCol>
          <RightCol>
            <SaveButton disabled={loading} type='submit'>
              Salvar
            </SaveButton>
          </RightCol>
        </Row>
      </Form>
    </Container>
  )
}
