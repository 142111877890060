import styled from 'styled-components'
import ChatWrapper from './ChatWrapper'
import ChatContextProvider from './context/ChatContext'

export default function CustomerServiceChat() {
  return (
    <Container>
      <ChatContextProvider>
        <ChatWrapper />
      </ChatContextProvider>
    </Container>
  )
}

const Container = styled.div`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  height: 100%;

  .row {
    margin: 0px;
    padding: 0px;

    [class^='col'] {
      margin: 0px;
      padding: 4px;
    }
  }

  p {
    margin-bottom: 4px;
  }
`
