import * as S from './styles'
import ContactList from './ContactList'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import FormInput from '~/components/FormInput'
import SegmentNavigation from './SegmentNavigation'

export default function Contacts({
  auth,
  currentChat,
  currentConversation,
  onChangeCurrentConversation,
  clientsData,
  conversations,
}) {
  const [active, setActive] = useState(1)
  const [chatConversations, setChatConversation] = useState([])
  const { register } = useForm()
  const { name } = register('contact')

  const contacts = useSelector(state => state.contactReducer.contacts)

  useEffect(() => {
    if (conversations !== chatConversations) {
      setChatConversation(conversations)
    }
  }, [conversations])

  const handleInputSearch = e => {
    const value = e.target.value
    if (value === '') return setChatConversation(conversations)
    if (!isNaN(value[0])) {
      const filtered = conversations.filter(conversation =>
        conversation.phoneNumber.includes(value),
      )
      setChatConversation(filtered)
      return
    }

    // Filtra as três primeiras ocorrências caso exista até três elementos no array de conversation que corresponda a string buscada
    const filteredByName = contacts.filter(contact =>
      contact.name.toLowerCase().includes(value.toLowerCase()),
    )

    let newConversationArray = []
    if (filteredByName.length >= 1) {
      const filtered = conversations.filter(conversation =>
        conversation.phoneNumber.includes(filteredByName[0].phone),
      )
      newConversationArray.push(filtered[0])
    }

    if (filteredByName.length >= 2) {
      const filteredOne = conversations.filter(conversation =>
        conversation.phoneNumber.includes(filteredByName[1].phone),
      )
      newConversationArray.push(filteredOne[0])
    }

    if (filteredByName.length >= 3) {
      const filteredTwo = conversations.filter(conversation =>
        conversation.phoneNumber.includes(filteredByName[2].phone),
      )
      newConversationArray.push(filteredTwo[0])
    }
    setChatConversation(newConversationArray)
  }

  return (
    <S.Contacts>
      <S.AccordionTop>
        <FormInput
          type={'text'}
          name={name}
          style={{ padding: '0px !important' }}
          onChange={e => handleInputSearch(e)}
          placeholder={'Buscar...'}
          onBlur={() => {
            setChatConversation(conversations)
          }}
          register={register}
        />

        <div>
          <S.InputSearchButton type={'submit'} size={'sm'} color={'none'}>
            <S.Icon className='fas fa-search'></S.Icon>
          </S.InputSearchButton>
        </div>
      </S.AccordionTop>

      <SegmentNavigation
        active={active}
        setActive={setActive}
        hasNewMessage={chatConversations.filter(c => c.newMessage)?.length > 0}
      />

      {active === 1 && (
        <ContactList
          currentChat={currentChat}
          auth={auth}
          setActive={setActive}
          contacts={contacts}
          clientsData={clientsData}
          conversations={chatConversations}
          currentConversation={currentConversation}
          onChangeCurrentConversation={onChangeCurrentConversation}
        />
      )}
      {active === 2 && (
        <>
          {chatConversations.filter(c => c.newMessage)?.length > 0 && (
            <ContactList
              currentChat={currentChat}
              auth={auth}
              setActive={setActive}
              contacts={contacts}
              clientsData={clientsData}
              conversations={chatConversations.filter(c => c.newMessage)}
              currentConversation={currentConversation}
              onChangeCurrentConversation={onChangeCurrentConversation}
            />
          )}

          {chatConversations.filter(c => c.newMessage)?.length === 0 && (
            <p style={{ paddingTop: '16px', textAlign: 'center' }}>
              Não há mensagens novas
            </p>
          )}
        </>
      )}
    </S.Contacts>
  )
}
