import { Col } from 'reactstrap'
import styled from 'styled-components'

export const ImageContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;

  @media (max-width: 1280) {
    > img {
      width: 400px;
    }
  }
`
