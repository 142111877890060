import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'

export default function MotivationDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const defaultValue = {
    ...location.state.motivation,
  }

  async function onSubmit(data) {
    try {
      const body = {
        id: location.state.motivation.id,
        title: data.title,
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/motivation`,
        body,
      )

      toast.success('Motivo editado com sucesso!')

      navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  // async function onDelete() {
  //   if (window.confirm('Você tem certeza que deseja excluir esse viajante?')) {
  //     await axiosApis.delete(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/${location.state.traveler.id}`,
  //     )

  //     toast.success(`Viajante excluído com sucesso!`)

  //     return navigate(-1)
  //   }
  // }

  return (
    <Container>
      <PageTitle title='Motivos' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Container>
            <DefaultForm
              defaultValue={defaultValue}
              callbackSubmit={onSubmit}
              inputs={[
                {
                  type: 'text',
                  name: 'title',
                  label: 'Motivo',
                  required: true,
                  maxLength: 100,
                },
              ]}
            />
          </Container>
        </PanelBody>
      </Panel>
    </Container>
  )
}
