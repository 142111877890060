import { LinkRegisterNewPanel } from '~/components/Link/styles'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import { SpaceBetweenDiv } from '~/styles/divs'
import Filter from './Filter'
import Table from './Table'
import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'

export default function List() {
  const { axiosApis } = GetAxios()
  const [categoriesOriginal, setCategoriesOriginal] = useState([])
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const resCategories = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/getexperiencecategory`,
        )

        setCategories(resCategories.data)

        setCategoriesOriginal(resCategories.data)

        return setLoading(false)
      } catch (error) {
        toast.info('Nenhum resultado encontrado.')

        return setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <div>
      <PageTitle title='Categorias' to={'/experiences'} />

      <Panel>
        <PanelHeader title='Filtros' noButton />

        <PanelBody>
          <Container>
            <Filter
              setCategories={setCategories}
              categoriesOriginal={categoriesOriginal}
              setLoading={setLoading}
            />
          </Container>
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton>
          <SpaceBetweenDiv>
            <b>Resultado da pesquisa</b>

            <LinkRegisterNewPanel to='create'>
              Nova Categoria
            </LinkRegisterNewPanel>
          </SpaceBetweenDiv>
        </PanelHeader>

        <PanelBody>
          <Container>
            <Table loading={loading} categories={categories} />
          </Container>
        </PanelBody>
      </Panel>
    </div>
  )
}
