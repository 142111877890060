import { useEffect, useState } from 'react'
import { LinkEdit } from '~/components/Link/styles'
import ReactTable from '~/components/Table'
import { GetAxios } from '~/services/api'

export default function Table({ data, loading }) {
  const { axiosApis } = GetAxios()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
        )

        return setRoles(data)
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'Nome do Hotel',
          accessor: 'nome_hotel',
          width: 200,
        },
        {
          Header: 'Cidade',
          accessor: 'city',
          width: 150,
        },
        {
          Header: 'Data Inicial da Reserva',
          accessor: 'date_max',
          width: 250,
        },
      ]}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
