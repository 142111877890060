import {
  CLEAR_CONVERSATIONS,
  SET_CONVERSATIONS,
  READ_CONVERSATIONS,
} from '../actions/conversationAction'

const initialState = {
  conversations: [],
}

const conversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.data,
      }
    case CLEAR_CONVERSATIONS:
      return {
        ...state,
        conversations: [],
      }
    case READ_CONVERSATIONS: {
      let newConversations = [...state.conversations]

      const indexOfConversation = newConversations.findIndex(
        x => x.phoneNumber == action.data,
      )

      newConversations[indexOfConversation] = {
        ...newConversations[indexOfConversation],
        newMessage: false,
      }

      return {
        ...state,
        conversations: newConversations,
      }
    }
    default:
      return state
  }
}

export default conversationReducer
