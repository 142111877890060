import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import { useSelector } from 'react-redux'
import { inputsDefaultFormDetails } from '../List/inputs'
import { useEffect, useState } from 'react'

export default function TravelersDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const [traveler, setTraveler] = useState({ accessCode: '' })
  const [loading, setLoading] = useState(false)
  const [ddi, setDdi] = useState({})
  const [selectDdi, setSelectDdi] = useState({})

  useEffect(() => {
    requestApiGetTraveler()
    getOptionsDDI()
    setSelectDdi({
      label: '',
      value: '',
    })
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  const requestApiGetTraveler = async () => {
    try {
      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/${location.state.traveler.travelerId}`,
      )

      setTraveler({
        ...response.data,
        bairro: response.data.neighborhood,
        cep: response.data.zipCode,
      })
    } catch (err) {
      toast.info(err.response.data)
    }
  }

  async function onValidate(data) {
    if (!data.email && !data.phone) {
      toast.info('Informe email ou telefone.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      for (let key in data) {
        if (data[key] != null && data[key] != 'null') {
          formData.append(key, data[key])
        }
      }
      formData.set('ddi', data.ddi.value)
      formData.set('id', location.state.traveler.userId)
      formData.set('birthDay', data.birthDay ?? '0001-01-01')
      formData.set('city', data.city?.label)
      formData.append('cityId', data.city?.value)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
        formData,
      )

      toast.success('Viajante editado com sucesso!')

      navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  async function deleteTraveler() {
    if (!confirm('Deseja excluir o viajante?')) {
      return
    }

    await axiosApis.delete(
      `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/${location.state.traveler.userId}`,
    )

    toast.success('Viajante excluído com sucesso!')
    navigate(-1)
  }

  const resetAccessCode = async () => {
    try {
      const response = await axiosApis.patch(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/remake-access-code/${location.state.traveler.travelerId}`,
      )

      setTraveler(oldState => ({
        ...oldState,
        accessCode: response.data.accessCode,
      }))

      toast.success(response.data.messageStatus)
    } catch (err) {
      console.log(err)
      toast.info(err.response.data.statusMessage)
    }
  }

  async function onSearchCEP(e, oldData = {}) {
    try {
      setLoading(true)

      var { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/cep/${e.target.value}`,
      )

      if (!data.cep) {
        toast.info('CEP não encontrado!')

        return setLoading(false)
      }

      setTraveler({
        ...oldData,
        cep: data.cep,
        address: data.logradouro,
        bairro: data.bairro,
        city: data.localidade,
      })

      return setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }

      return setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle title={traveler.fullName ?? 'Viajante'} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Container>
            <DefaultForm
              onValidate={onValidate}
              callbackReset={traveler?.accessCode == null && resetAccessCode}
              resetLabel='Criar código de acesso'
              callbackSubmit={onSubmit}
              callbackDelete={() => deleteTraveler()}
              defaultValue={traveler}
              loading={loading}
              inputs={inputsDefaultFormDetails(
                user.isOperator,
                onSearchCEP,
                ddi,
                selectDdi,
                setSelectDdi,
              )}
            />
          </Container>
        </PanelBody>
      </Panel>
    </Container>
  )
}
