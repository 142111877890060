// import { useLocation, useNavigate, Link } from 'react-router-dom'
// import PageTitle from '~/components/PageTitle'
// import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
// import { Container } from './styles'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import context from '~/context/createContext'

export default function Material() {
  const { axiosApis } = GetAxios()
  const { valueModal, showModalMaterial, setShowModalMaterial } =
    useContext(context)

  async function onUpdate(data) {
    try {
      const formData = new FormData()

      formData.append('amountTripmee', data.amountTripmee)
      formData.append('booklet', data.booklet)
      formData.append('amountBooklet', data.amountBooklet)
      formData.append('gift', data.gift)
      formData.append('amountGift', data.amountGift)
      formData.append('printedVoucher', data.printedVoucher)

      formData.append('checkIn', false)
      formData.append('material', true)

      // formData.append('travelId', location.state.travelId)
      // formData.append('travelerId', location.state.travelerId)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/updateCheckInAndMaterialTravel/${valueModal.id}`,
        formData,
      )

      setShowModalMaterial(!showModalMaterial)
      toast.success('Entrega de material!')
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  return (
    <DefaultForm
      // onValidate={onValidate}
      // defaultValue={location.state.service}
      callbackSubmit={onUpdate}
      defaultValue={valueModal}
      callbackReset={() => {
        return
      }}
      inputs={[
        {
          type: 'text',
          name: 'amountTripmee',
          label: 'Quantos Tripmees:',
          placeholder: 'Quantos Tripmees:',
          required: true,
          maxLength: 150,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
        {
          type: 'check',
          name: 'booklet',
          label: 'Livreto Impresso',
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          // required: true,
        },
        {
          type: 'number',
          name: 'amountBooklet',
          label: 'Quantidade de livretos',
          placeholder: 'Quantidade de livretos',
          required: true,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
        },
        {
          type: 'textarea',
          name: 'gift',
          label: 'Brindes',
          placeholder: 'Brindes',
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
        {
          type: 'number',
          name: 'amountGift',
          label: 'Quantidade de brindes',
          placeholder: 'Quantidade de brindes',
          required: true,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
        {
          type: 'check',
          name: 'printedVoucher',
          label: 'Voucher Impresso',
          required: true,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
      ]}
    />
  )
}
