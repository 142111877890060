export const SET_CONTACTS = 'SET_CONTACTS'
export const ADD_CONTACT = 'ADD_CONTACT'
export const REMOVE_DATA = 'REMOVE_DATA'

export const setContacts = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CONTACTS,
  })
}

export const addContact = (data, dispatch) => {
  dispatch({
    data: data,
    type: ADD_CONTACT,
  })
}

export const removeData = dispatch => {
  dispatch({
    type: REMOVE_DATA,
  })
}
