import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { FormatValueBRL } from '~/functions/FormatValue'

export default function SaleDashboard({
  data = [],
  loading,
  onDashboardFilter,
  showValue = true,
}) {
  return (
    <Container>
      {data.map((e, i) => {
        return (
          <Item
            key={i}
            onClick={
              e.count === 0 ? null : () => onDashboardFilter({ ids: e.ids })
            }
          >
            <h5>{e.title}</h5>

            {!loading && (
              <div>
                <h2>{e.count ?? 0}</h2>

                {showValue ? (
                  <p style={{ textAlign: 'right' }}>
                    <b>{FormatValueBRL(e.ammount)}</b>
                  </p>
                ) : (
                  <div style={{ paddingTop: '25px' }}>
                    <div></div>
                  </div>
                )}
              </div>
            )}

            {loading && (
              <div style={{ marginBottom: '16px' }}>
                <Spinner size={'sm'} />
              </div>
            )}
          </Item>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -4px 16px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #cccccc;
  padding: 8px 4px;
  margin: 4px;
  border-radius: 8px;
  min-height: 130px;
  text-align: center;
  min-width: calc(20% - 8px);
  max-width: calc(20% - 8px);
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
  }
`
