import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Popover, PopoverBody } from 'reactstrap'

export const NavUlItems = styled.ul`
  list-style-type: none;
  padding: 0px;
  color: #333;
  cursor: pointer;

  & > li {
    border-radius: 6px;

    & > a {
      color: #333;
    }
  }

  & > li.active {
    background-color: #ccc;

    & > a,
    & > span {
      text-decoration: none;
    }
  }

  & > li:not(.active):hover {
    background: #eaeaea;

    & > a,
    & > span {
      text-decoration: none;
    }
  }
`

export const NavUlSubItems = styled.ul`
  list-style-type: none;
  padding: 0 12px 12px 36px;

  & > li {
    border-radius: 6px;

    & > a {
      color: #333;
    }
  }

  & > li.active {
    background-color: #ccc;

    & > a,
    & > span {
      text-decoration: none;
    }
  }

  & > li:not(.active):hover {
    background: #eaeaea;

    & > a,
    & > span {
      text-decoration: none;
    }
  }
`

export const NavUlSubItemsPopover = styled.ul`
  list-style-type: none;
  padding: 0px;

  a {
    text-decoration: none;
  }

  li {
    border-radius: 6px;
  }

  li.active {
    background-color: #ccc;
    span {
      color: #eaeaea;
    }
  }

  li:not(.active):hover {
    background: #eaeaea;
  }
`

export const NavLiItem = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.minify && '44px'};
  display: flex;
  justify-content: ${props => (props.minify ? 'center' : 'space-between')};
  align-items: center;
  padding: ${props => (props.minify ? '0' : '4px 12px')};
  font-size: 13px;
  /* color: #333; */
  font-weight: 500;
  margin-top: 2px 0 2px 0;

  i {
    font-size: ${props => props.minify && '28px'};
    width: ${props => !props.minify && '24px'};
  }
`

export const NavLiItemLink = styled(Link)`
  width: 100%;
  height: ${props => props.minify && '48px'};
  display: flex;
  justify-content: ${props => (props.minify ? 'center' : 'flex-start')};
  align-items: center;
  padding: ${props => (props.minify ? '0' : '6px 12px')};
  font-size: 13px;
  /* color: #333; */
  font-weight: 500;
  margin-top: 2px 0 2px 0;

  i {
    font-size: ${props => props.minify && '28px'};
    width: ${props => !props.minify && '24px'};
  }
`

export const NavLiSubItemLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 12px;
  font-size: 12px;
  /* color: #333; */
  font-weight: 500;
  margin-top: 2px 0 2px 0;
`

export const SubmenuPopover = styled(Popover)`
  border-radius: 6px !important;
  padding-bottom: 0px !important;
`

export const SubmenuPopoverBody = styled(PopoverBody)`
  ul {
    margin: 0;
  }
`
