const inputsToDetailsPerson = (
  ddi,
  setSelectDdi,
  selectDdiValue,
  userIsOperator,
  onSearchCEP,
) => {
  const detailsPerson = [
    {
      empty: true,
      text: '<b>Dados pessoais</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome Completo',
      required: true,
      maxLength: 150,
      sm: 12,
      lg: 3,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      disabled: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'select',
      name: 'ddi',
      label: 'DDI',
      options: ddi,
      placeholder: 'Selecione...',
      onChange: e => setSelectDdi(e.target.value),
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    selectDdiValue == '' || selectDdiValue == 55
      ? {
          type: 'phone',
          name: 'phone',
          label: 'Telefone',
          sm: 12,
          lg: 2,
        }
      : {
          type: 'text',
          name: 'phone',
          label: 'Telefone',
          mask: '999 99999999',
          sm: 12,
          lg: 2,
        },
    {
      type: 'text',
      name: 'cpfCnpj',
      label: 'Cpf',
      mask: '999.999.999-99',
      required: false,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'rg',
      label: 'RG/CNH',
      required: false,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'nacionality',
      label: 'Nacionalidade',
      required: false,
      sm: 12,
      lg: 2,
    },
    {
      type: 'date',
      name: 'birthday',
      label: 'Aniversário',
      required: false,
      sm: 12,
      lg: 2,
    },
    userIsOperator && {
      type: 'text',
      name: 'contactName',
      label: 'Nome do Contato',
      sm: 12,
      lg: 2,
    },
    userIsOperator && {
      type: 'phone',
      name: 'contactPhone',
      label: 'Telefone do Contato',
      sm: 12,
      lg: 2,
    },
    userIsOperator && {
      type: 'email',
      name: 'contactEmail',
      label: 'E-mail do Contato',
      sm: 12,
      lg: 2,
    },
    {
      type: 'check',
      name: 'prospection',
      label: 'Lead',
      sm: 12,
      lg: 2,
    },
    {
      empty: true,
      text: '<b>Documentos</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'visaNumber',
      label: 'Número do visto',
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'visaType',
      label: 'Tipo do visto',
      sm: 12,
      lg: 2,
    },
    {
      type: 'date',
      name: 'visaIssueDate',
      label: 'Emissão do visto',
      required: false,
      sm: 12,
      lg: 2,
    },
    {
      type: 'date',
      name: 'visaExpirationDate',
      label: 'Validade do visto',
      required: false,
      sm: 12,
      lg: 2,
    },
    {
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'passportNumber',
      label: 'Número do passaporte',
      sm: 12,
      lg: 2,
    },
    {
      type: 'date',
      name: 'passportIssueDate',
      label: 'Emissão do passaporte',
      required: false,
      sm: 12,
      lg: 2,
    },
    {
      type: 'date',
      name: 'passportExpirationDate',
      label: 'Validade do passaporte',
      required: false,
      sm: 12,
      lg: 2,
    },
    {
      empty: true,
      text: '<b>Endereço</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'cep',
      label: 'CEP',
      mask: '99999-999',
      isClearable: true,
      required: false,
      searchButton: true,
      callbackSearch: onSearchCEP,
      sm: 12,
      lg: 3,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'n',
      label: 'N°',
      required: false,
      sm: 12,
      lg: 1,
    },
    {
      type: 'text',
      name: 'compl',
      label: 'Compl.',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'bairro',
      label: 'Bairro',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'city',
      name: 'city',
      label: 'Cidade',
      placeholder: 'Pesquise por uma cidade...',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'text',
      name: 'state',
      label: 'UF',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      empty: true,
      text: '<b>Outras informações</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'profession',
      label: 'Profissão',
      required: false,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'fidelidade',
      label: 'Fidelidade',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'origem',
      label: 'Origem',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    userIsOperator && {
      type: 'text',
      name: 'belongingAgency',
      label: 'Agência',
      sm: 12,
      lg: 3,
    },
    userIsOperator && {
      type: 'check',
      name: 'isAgent',
      label: 'Consultor de viagem',
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'enterprise',
      label: 'Nome da empresa para qual trabalha:',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'text',
      name: 'commercialPhone',
      label: 'Telefone Comercial',
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'image',
      name: 'avatar',
      label: 'Avatar',
      required: false,
      sm: 12,
      lg: 12,
    },
    {
      type: 'check',
      name: 'intoTraveler',
      label: 'Transformar em viajante?',
      sm: 12,
      lg: 2,
    },
  ]

  return detailsPerson
}

const inputsToDetailsEnterprise = (
  ddi,
  setSelectDdi,
  selectDdiValue,
  onSearchCEP,
) => {
  const detailsEnterprise = [
    {
      empty: true,
      text: '<b>Dados empresariais</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome ',
      required: true,
      maxLength: 150,
      sm: 12,
      lg: 3,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      disabled: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'select',
      name: 'ddi',
      label: 'DDI',
      options: ddi,
      placeholder: 'Selecione...',
      onChange: e => setSelectDdi(e.target.value),
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    selectDdiValue == '' || selectDdiValue == 55
      ? {
          type: 'phone',
          name: 'phone',
          label: 'Telefone',
          sm: 12,
          lg: 2,
        }
      : {
          type: 'text',
          name: 'phone',
          label: 'Telefone',
          mask: '999 99999999',
          sm: 12,
          lg: 2,
        },
    {
      type: 'text',
      name: 'cpfCnpj',
      label: 'Cnpj',
      required: false,
      mask: '99.999.999/9999-99',
      sm: 12,
      lg: 2,
    },
    {
      empty: true,
      text: '<b>Endereço</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'cep',
      label: 'CEP',
      mask: '99999-999',
      isClearable: true,
      required: false,
      searchButton: true,
      callbackSearch: onSearchCEP,
      sm: 12,
      lg: 3,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'n',
      label: 'N°',
      required: false,
      sm: 12,
      lg: 1,
    },
    {
      type: 'text',
      name: 'compl',
      label: 'Compl.',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'bairro',
      label: 'Bairro',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'city',
      name: 'city',
      label: 'Cidade',
      placeholder: 'Pesquise por uma cidade...',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'text',
      name: 'state',
      label: 'UF',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    // {
    //   empty: true,
    //   text: '<b>Dados do contato</b>',
    //   sm: 12,
    // },
    // {
    //   type: 'text',
    //   name: 'fullName',
    //   label: 'Nome ',
    //   required: true,
    //   maxLength: 150,
    //   sm: 12,
    //   lg: 3,
    // },
    // {
    //   type: 'email',
    //   name: 'email',
    //   label: 'E-mail',
    //   disabled: false,
    //   sm: 12,
    //   lg: 3,
    // },
    // {
    //   type: 'select',
    //   name: 'ddi',
    //   label: 'DDI',
    //   options: ddi,
    //   placeholder: 'Selecione...',
    //   onChange: e => setSelectDdi(e.target.value),
    //   sm: 2,
    //   md: 2,
    //   lg: 2,
    //   xl: 2,
    // },
    // selectDdiValue == '' || selectDdiValue == 55
    //   ? {
    //       type: 'phone',
    //       name: 'phone',
    //       label: 'Telefone',
    //       sm: 12,
    //       lg: 2,
    //     }
    //   : {
    //       type: 'text',
    //       name: 'phone',
    //       label: 'Telefone',
    //       mask: '999 99999999',
    //       sm: 12,
    //       lg: 2,
    //     },
    // {
    //   type: 'text',
    //   name: 'cpfCnpj',
    //   label: 'Cpf',
    //   required: false,
    //   mask: '999.999.999-99',
    //   sm: 12,
    //   lg: 2,
    // },
  ]

  return detailsEnterprise
}

export const inputs = (
  isEnterprise,
  ddi,
  setSelectDdi,
  selectDdiValue,
  userIsOperator,
  onSearchCEP,
) => {
  const detailsPerson = inputsToDetailsPerson(
    ddi,
    setSelectDdi,
    selectDdiValue,
    userIsOperator,
    onSearchCEP,
  )

  const detailsEnterprise = inputsToDetailsEnterprise(
    ddi,
    setSelectDdi,
    selectDdiValue,
    onSearchCEP,
  )

  const result = isEnterprise ? detailsEnterprise : detailsPerson

  return result
}
