import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { LinkEdit, LinkRegisterNewTraveler } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import ReactTable from '~/components/Table'
import { getDateDiffLabel, getDateFormated, getMoment } from '~/functions/Date'
import { Container } from '~/styles/container'
import useAxios from '~/hooks/useAxios'
import IconButton from '~/components/IconButton'
import { Link } from 'react-router-dom'

export default function AgencyList() {
  const { apiClient } = useAxios()
  const [loadingPage, setLoadingPage] = useState(false)
  const [agencysOriginal, setAgencysOriginal] = useState([])
  const [agencys, setAgencys] = useState([])

  useEffect(() => {
    async function getSource() {
      try {
        setLoadingPage(true)

        const res = await apiClient('/agency')

        setAgencys(res.data)

        setAgencysOriginal(res.data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingPage(false)
      }
    }

    getSource()

    return () => {
      setLoadingPage(false)
      setAgencys([])
      setAgencysOriginal([])
    }
  }, [])

  async function onSearch(data) {
    if (
      data.code ||
      data.name ||
      data.email ||
      data.freeTrial ||
      data.campaign
    ) {
      setLoadingPage(true)

      const newAgencys = agencysOriginal.filter(
        x =>
          (data.campaign &&
            x.campaign?.toLowerCase().includes(data.campaign?.toLowerCase())) ||
          (data.code &&
            x.code?.toLowerCase().includes(data.code?.toLowerCase())) ||
          (data.name &&
            x.name?.toLowerCase().includes(data.name?.toLowerCase())) ||
          (data.email &&
            x.email?.tolowercase().includes(data.email?.tolowercase())) ||
          (data.freeTrial && x.freeTrial),
      )

      setTimeout(() => {
        setAgencys(newAgencys)

        setLoadingPage(false)
      }, [500])
    }
  }

  async function onReset() {
    setLoadingPage(true)

    setTimeout(() => {
      setAgencys(agencysOriginal)

      setLoadingPage(false)
    }, [500])
  }

  return (
    <Container>
      <h1 className='page-header'>Gestão de Agências</h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSearch}
            callbackReset={onReset}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Nome da agência',
                placeholder: 'Nome da agência',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'email',
                label: 'E-mail',
                placeholder: 'E-mail',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'code',
                label: 'Código',
                placeholder: 'Código',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'check',
                name: 'freeTrial',
                label: 'Free-trial',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'campaign',
                label: 'Campanha',
                placeholder: 'Campanha',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}>
          <LinkRegisterNewTraveler to='create'>
            Nova Agência
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <ReactTable
            data={agencys}
            columns={[
              {
                Header: 'Status',
                accessor: 'status',
                width: 60,
                Cell: props => {
                  return props.row.original.status ? (
                    <i className={'fas fa-check'} style={{ color: '#3a3' }} />
                  ) : null
                },
              },
              {
                Header: 'WhatsApp',
                accessor: 'allowWhatsapp',
                width: 70,
                Cell: props => {
                  return props.row.original.allowWhatsapp ? (
                    <i className={'fas fa-check'} style={{ color: '#3a3' }} />
                  ) : null
                },
              },
              {
                Header: 'Free-trial',
                accessor: 'freeTrial',
                width: 70,
                Cell: props => {
                  return props.row.original.freeTrial ? (
                    <>
                      <i className={'fas fa-check'} style={{ color: '#3a3' }} />

                      {props.row.original.campaign && (
                        <>
                          <br />

                          <span>({props.row.original.campaign})</span>
                        </>
                      )}
                    </>
                  ) : null
                },
              },
              {
                Header: 'Nome',
                accessor: 'name',
                width: 150,
              },
              {
                Header: 'Tipo',
                Cell: props => {
                  if (props.row.original.isHolding) {
                    return <span>Holding</span>
                  }

                  if (props.row.original.isOperator) {
                    return <span>Operadora</span>
                  }

                  return null
                },
                width: 80,
              },
              {
                Header: 'Grupo',
                accessor: 'groupName',
                width: 120,
              },
              {
                Header: 'E-mail',
                accessor: 'email',
                width: 200,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 120,
              },
              {
                Header: 'Criado em',
                accessor: 'createdAt',
                width: 80,
                Cell: props => {
                  return props.row.original.createdAt
                    ? getDateFormated(props.row.original.createdAt, 'DD/MM/YY')
                    : ''
                },
              },
              {
                Header: 'Free-trial(fim)',
                accessor: 'freeTrialEnd',
                width: 100,
                Cell: props => {
                  if (
                    props.row.original.freeTrial &&
                    props.row.original.freeTrialEnd
                  ) {
                    const now = getMoment()
                    const freeTrialEnd = getMoment(
                      props.row.original.freeTrialEnd,
                    )
                    const date = getDateFormated(freeTrialEnd)

                    let diff = ''

                    if (freeTrialEnd > now) {
                      diff = getDateDiffLabel(now, freeTrialEnd, true, 'days')
                    }

                    return (
                      <>
                        <span>{date}</span>

                        {freeTrialEnd > now && (
                          <>
                            <br />
                            <span>({diff})</span>
                          </>
                        )}
                      </>
                    )
                  }

                  return null
                },
              },
              {
                Header: 'Campanha',
                accessor: 'campaign',
                width: 100,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <Link
                      to={'details'}
                      state={{
                        agency: props.row.original,
                      }}
                    >
                      <IconButton
                        id={`details-${props.row.index}`}
                        type='details'
                      />
                    </Link>
                  )
                },
                width: 80,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loadingPage,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
