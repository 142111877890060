import { useEffect, useMemo, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { toast } from 'react-toastify'
import { getDateDiff, getDateFormated, getMoment } from '~/functions/Date'
import { FormatValueBRL } from '~/functions/FormatValue'
import PageTitle from '~/components/PageTitle'

export default function SalesBackofficeByIssuerList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [data, setData] = useState([])
  const [filters, setFilters] = useState({
    consolidators: [],
    issuers: [],
    branchs: [],
  })

  const [defaultValueFilters, setDefaultValueFilters] = useState({
    start: getDateFormated(getMoment().add(-1, 'month'), 'YYYY-MM-DD'),
    end: getDateFormated(getMoment(), 'YYYY-MM-DD'),
  })

  useEffect(() => {
    onSubmit(defaultValueFilters)

    getFilters()
  }, [])

  async function getFilters() {
    try {
      setLoadingFilters(true)

      const resConsolidators = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesbackoffice/consolidators`,
      )

      const resIssuers = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesbackoffice/issuers`,
      )

      const resBranchs = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesbackoffice/branchs`,
      )

      setFilters({
        consolidators: resConsolidators.data,
        issuers: resIssuers.data,
        branchs: resBranchs.data,
      })

      setLoadingFilters(false)
    } catch (error) {
      setLoadingFilters(false)

      if (error && error.response.data) {
        toast.info(error.response.data)
      }

      return toast.info(
        'Não foi possível realizar a solicitação, tente mais tarde!',
      )
    }
  }

  async function onSubmit(e) {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesbackoffice/byissuer`,
        {
          params: {
            start: e?.start,
            end: e?.end,
            issuer: e.issuer?.value ?? undefined,
            branch: e.branch?.value ?? undefined,
          },
        },
      )

      setData(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onValidate = data => {
    if ((data.start && !data.end) || (!data.start && data.end)) {
      toast.info('Preencha os campos Data Inicial e Data Final.')

      return false
    }

    if (getMoment(data.start) > getMoment(data.end)) {
      toast.info('A Data Final precisa ser maior que a Data Inicial.')

      return false
    }

    const diff = getDateDiff(data.start, data.end)

    if (diff.days > 180) {
      toast.info('Informe um período de no máximo 6 meses.')

      return false
    }

    if (
      !data.start &&
      !data.end &&
      !data.issuer &&
      (filters.branchs.length > 0 ? !data.branch : false)
    ) {
      toast.info('Preencha os filtros!')

      return false
    }

    return true
  }

  return (
    <Container>
      <PageTitle title={'Vendas Backoffice por Consultor'} head={'h1'} />

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={() => {
              setDefaultValueFilters(undefined)

              setData([])

              return
            }}
            submitLabel={'Pesquisar'}
            defaultValue={defaultValueFilters}
            disabled={loading || loadingFilters}
            inputs={[
              {
                type: 'date',
                name: 'start',
                label: 'Data inicial',
                sm: 12,
                lg: 3,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Data final',
                sm: 12,
                lg: 3,
              },
              {
                type: 'select',
                name: 'issuer',
                label: 'Consultor',
                options: filters.issuers,
                isClearable: true,
                sm: 12,
                lg: 3,
              },
              filters.branchs.length > 1
                ? {
                    type: 'select',
                    name: 'branch',
                    label: 'Filial',
                    options: filters.branchs,
                    isClearable: true,
                    sm: 12,
                    lg: 3,
                  }
                : {},
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton />

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            tableProps={{
              hasFooter: true,
            }}
            columns={[
              {
                Header: 'Nome',
                accessor: 'issuer',
                width: 200,
              },
              {
                Header: 'Filial',
                accessor: 'idPostoAtendimento',
                width: 80,
                hide: filters.branchs.length <= 1,
              },
              {
                Header: 'Qtde.',
                accessor: 'count',
                width: 120,
                Footer: info => {
                  const count = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.count, 0),
                    [info.rows],
                  )

                  return <span>{count}</span>
                },
              },
              {
                Header: 'Fee',
                accessor: 'totalFee',
                width: 80,
                Cell: props => {
                  return FormatValueBRL(props.row.original.totalFee)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce(
                        (sum, row) => sum + row.values.totalFee,
                        0,
                      ),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Markup',
                accessor: 'totalMarkup',
                width: 120,
                Cell: props => {
                  return FormatValueBRL(props.row.original.totalMarkup)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce(
                        (sum, row) => sum + row.values.totalMarkup,
                        0,
                      ),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'DU',
                accessor: 'totalDu',
                width: 120,
                Cell: props => {
                  return FormatValueBRL(props.row.original.totalDu)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce(
                        (sum, row) => sum + row.values.totalDu,
                        0,
                      ),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Taxa',
                accessor: 'totalTaxa',
                width: 120,
                Cell: props => {
                  return FormatValueBRL(props.row.original.totalTaxa)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce(
                        (sum, row) => sum + row.values.totalTaxa,
                        0,
                      ),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Outras Taxas',
                accessor: 'totalOutrasTaxa',
                width: 120,
                Cell: props => {
                  return FormatValueBRL(props.row.original.totalOutrasTaxa)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce(
                        (sum, row) => sum + row.values.totalOutrasTaxa,
                        0,
                      ),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Tarifa',
                accessor: 'totalTarifa',
                width: 120,
                Cell: props => {
                  return FormatValueBRL(props.row.original.totalTarifa)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce(
                        (sum, row) => sum + row.values.totalTarifa,
                        0,
                      ),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Total',
                accessor: 'total',
                width: 120,
                Cell: props => {
                  return FormatValueBRL(props.row.original.total)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.total, 0),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
