import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import Divisor from '~/components/Divisor'
import PageTitle from '~/components/PageTitle'
import useSalesReleasesForm from '../../hooks/useSalesReleasesForm'
import {
  SalesReleaseFormComponent,
  SalesReleaseFormContainer,
  SalesReleaseFormValuesTable,
} from '../../styles'
import SalesReleasesFormInput from '../SalesReleasesFormInput'

export default function SalesReleasesRentalForm({
  onChange,
  defaultValue = [],
  internalOptionData = {},
}) {
  const { handleSubmit, getValues, register, reset, setValue, watch } =
    useSalesReleasesForm()
  const [list, setList] = useState(defaultValue ?? [])

  useEffect(() => {
    if (!list || list.length == 0) {
      setList([{}])
    }

    if (list[0]) {
      setValue('withdrawalCity', list[0].withdrawalCity)
      setValue('paymentType', list[0].paymentType)
    }

    return () => {
      setList([])
    }
  }, [])

  async function onSubmit(data) {
    const newList = [data]
    let formattedData = formatData(newList)

    setList(newList)

    onChange(newList, formattedData)
  }

  function formatData(data) {
    return data
      .map(
        item => `${item.vehicleCategory}
Ret.: ${item.withdrawalDate} ${item.withdrawalTime} - ${item.withdrawalLocation}
Dev.: ${item.returnDate} ${item.returnTime} - ${item.returnLocation}`,
      )
      .join('\n')
  }

  return (
    <SalesReleaseFormContainer>
      <SalesReleaseFormComponent onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <Row>
            <PageTitle title='Retirada' head='h6' noButton />

            <SalesReleasesFormInput
              label='Cidade'
              name='withdrawalCity'
              type='text'
              register={register}
              setValue={setValue}
              defaultValue={list[0]?.withdrawalCity}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={3}
              selectOptions={internalOptionData?.cities ?? []}
            />

            <SalesReleasesFormInput
              label='Local'
              name='withdrawalLocation'
              type='text'
              register={register}
              defaultValue={list[0]?.withdrawalLocation}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={5}
            />

            <SalesReleasesFormInput
              label='Data'
              name='withdrawalDate'
              type='date'
              register={register}
              defaultValue={list[0]?.withdrawalDate}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Hora'
              name='withdrawalTime'
              type='time'
              register={register}
              defaultValue={list[0]?.withdrawalTime}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />
          </Row>

          <PageTitle title='Devolução' head='h6' noButton />

          <Row>
            <SalesReleasesFormInput
              label='Local'
              name='returnLocation'
              type='text'
              register={register}
              defaultValue={list[0]?.returnLocation}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={5}
            />

            <SalesReleasesFormInput
              label='Data'
              name='returnDate'
              type='date'
              register={register}
              defaultValue={list[0]?.returnDate}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Hora'
              name='returnTime'
              type='time'
              register={register}
              defaultValue={list[0]?.returnTime}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />
          </Row>

          <PageTitle
            title='Valores por diária(ao cliente)'
            head='h6'
            noButton
          />

          <Row>
            <SalesReleasesFormInput
              label='Moeda'
              name='currency'
              type='text'
              register={register}
              defaultValue={list[0]?.currency ?? 'BRL'}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Vlr. diária'
              name='dailyValue'
              type='text'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Outras txs. 1'
              name='otherTaxs1'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs1}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Código'
              name='taxCode1'
              type='text'
              register={register}
              defaultValue={list[0]?.taxCode1}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Outras txs. 2'
              name='otherTaxs2'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs2}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />
            <SalesReleasesFormInput
              label='Código'
              name='taxCode2'
              type='text'
              register={register}
              defaultValue={list[0]?.taxCode2}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Outras txs. 3'
              name='otherTaxs3'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs3}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />
            <SalesReleasesFormInput
              label='Código'
              name='taxCode3'
              type='text'
              register={register}
              defaultValue={list[0]?.taxCode3}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Markup'
              name='markup'
              type='text'
              register={register}
              defaultValue={list[0]?.markup}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />
          </Row>

          <Row>
            <SalesReleasesFormInput
              label='Câmbio'
              name='exchange'
              type='text'
              register={register}
              defaultValue={list[0]?.exchange}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Vlr. diária R$'
              name='dailyValueBRL'
              type='text'
              register={register}
              defaultValue={list[0]?.dailyValueBRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Outras txs. 1 R$'
              name='otherTaxs1BRL'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs1BRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Outras txs. 2 R$'
              name='otherTaxs2BRL'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs2BRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Outras txs. 3 R$'
              name='otherTaxs3BRL'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs3BRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Markup R$'
              name='markupBRL'
              type='text'
              register={register}
              defaultValue={list[0]?.markupBRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Total R$'
              name='totalBRL'
              type='text'
              register={register}
              defaultValue={list[0]?.totalBRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />
          </Row>

          <Row>
            <SalesReleasesFormInput
              label='Categoria Veículo'
              name='vehicleCategory'
              type='text'
              register={register}
              defaultValue={list[0]?.vehicleCategory}
              size='sm'
              sm={6}
              md={6}
              lg={3}
              xl={3}
            />
            <SalesReleasesFormInput
              register={register}
              defaultValue={list[0]?.paymentType}
              setValue={setValue}
              name={'paymentType'}
              label={'Tipo de Pagamento'}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
              selectOptions={internalOptionData?.paymentTypes ?? []}
            />

            <SalesReleasesFormInput
              label='Localizador/Cód. Confirm.'
              name='trackerConfCode'
              type='text'
              register={register}
              defaultValue={list[0]?.trackerConfCode}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Dt. Confirmação'
              name='confirmationDate'
              type='date'
              register={register}
              defaultValue={list[0]?.confirmationDate}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Confirmado Por'
              name='confirmedBy'
              type='text'
              register={register}
              defaultValue={list[0]?.confirmedBy}
              size='sm'
              sm={6}
              md={6}
              lg={3}
              xl={3}
            />
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12} className='submit-area'>
              <Button
                size={'sm'}
                type='button'
                color='none'
                onClick={() => reset()}
              >
                Limpar
              </Button>

              <Button size={'sm'} type='submit' color='primary'>
                Adicionar
              </Button>
            </Col>
          </Row>
        </fieldset>
      </SalesReleaseFormComponent>
    </SalesReleaseFormContainer>
  )
}
