import { useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Spinner } from 'reactstrap'
import { Container } from '~/styles/container'
import { Form } from '@unform/web'
import Input from '~/components/Input'
import { TravelDetailBlock } from './styles'
import { GetAxios } from '~/services/api'
import TextArea from '~/components/TextArea'
import CitiesSelect from '~/components/CitiesSelect'
import { getDateFormated, getMoment } from '~/functions/Date'

import {
  SaveButton,
  EditButton,
  DeleteButton,
  RegisterNewButton,
} from '~/components/Button/styles'
import { SpaceBetweenDiv } from '~/styles/divs'
import LoadingContainer from '~/components/LoadingContainer'
import { useSelector } from 'react-redux'

function TravelBlock({ travel }) {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loadingLink, setLoadingLink] = useState(false)
  const [loadingWhatsAppText, setLoadingWhatsAppText] = useState(false)
  const [loadingTravelSurveyLink, setLoadingTravelSurveyLink] = useState(false)
  const [loadingSendWhatsAppText, setLoadingSendWhatsAppText] = useState(false)
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [travelDetails, setTravelDetails] = useState(travel)
  const [isEditing, setIsEditing] = useState(false)
  const [travelLink, setTravelLink] = useState()
  const [whatsAppText, setWhatsAppText] = useState()
  const [travelSurveyLink, setTravelSurveyLink] = useState()
  const [copyTravelLinkSuccessMessage, setCopyTravelLinkSuccessMessage] =
    useState()
  const [copyWhatsAppTextSuccessMessage, setCopyWhatsAppTextSuccessMessage] =
    useState()
  const [
    copyTravelSurveyLinkSuccessMessage,
    setCopyTravelSurveyLinkSuccessMessage,
  ] = useState()
  const [travelToEdit, setTravelToEdit] = useState({})
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    let cancel = false

    async function getTravel() {
      if (cancel) return

      try {
        setLoading(true)

        const { data: dataTravel } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travel/${travel.id}`,
        )

        setTravelDetails(dataTravel)

        setLoading(false)
      } catch (error) {
        console.log(error)

        setLoading(false)

        return navigate('/travels')
      }
    }

    getTravel()

    return () => {
      cancel = true
    }
  }, [])

  async function handleSubmitEdit() {
    try {
      if (getMoment(travelToEdit.start) > getMoment(travelToEdit.end)) {
        return toast.info('O início da viagem não pode ser depois que o fim!')
      }

      setLoading(true)

      const editTravel = {
        id: travelToEdit.id,
        passengers: travelToEdit.passengers,
        title: travelToEdit.title,
        desc: travelToEdit.desc,
        origin: travelToEdit.origin.label,
        originId: travelToEdit.origin.value,
        destiny: travelToEdit.destiny.label,
        destinyId: travelToEdit.destiny.value,
        start: travelToEdit.start,
        end: travelToEdit.end,
        belongingAgency: travelToEdit.belongingAgency,
      }

      if (
        getDateFormated(travel.start) != getDateFormated(travelToEdit.start) ||
        getDateFormated(travel.end) != getDateFormated(travelToEdit.end)
      ) {
        if (
          !window.confirm(
            'Alterando as datas da viagem, você irá perder itens do Day By Day. Deseja continuar?',
          )
        )
          return setLoading(false)
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/update`,
        editTravel,
      )

      toast.success('Viagem editada com sucesso!')

      setIsEditing(false)

      setLoading(false)

      navigate('/travels')

      navigate('/travels/details', { state: { id: travel.id } })
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível editar a viagem, por favor tente mais tarde.',
      )
    }
  }

  async function handleSubmitDelete(id) {
    try {
      setLoading(true)

      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/${id}`,
      )

      toast.success(`Viagem excluída com sucesso!`)

      setLoading(false)

      return navigate('/travels')
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível excluir a viagem, por favor tente mais tarde.',
      )
    }
  }

  const OnDeleteClick = id => {
    if (window.confirm('Você tem certeza que deseja excluir essa viagem?')) {
      handleSubmitDelete(id)
    }
  }

  async function onHandleEdit() {
    setTravelToEdit({
      ...travelToEdit,
      id: travelDetails.id,
      title: travelDetails.title,
      desc: travelDetails.desc,
      passengers: travelDetails.passengers,
      start: getDateFormated(travelDetails.start, 'YYYY-MM-DD'),
      end:
        travelDetails.end == null
          ? null
          : getDateFormated(travelDetails.end, 'YYYY-MM-DD'),
      origin: {
        label: travelDetails.origin,
        value: travelDetails.originId,
      },
      destiny: {
        label: travelDetails.destiny,
        value: travelDetails.destinyId,
      },
      belongingAgency: travelDetails.belongingAgency,
    })
    setIsEditing(true)
  }

  async function generateLink() {
    try {
      setLoadingLink(true)

      const { data } = await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelresume`,
        { travelId: travelDetails.id },
      )

      setTravelLink(data.link)

      setLoadingLink(false)
    } catch (error) {
      console.log(error)

      setTravelLink(undefined)

      setLoadingLink(false)
    }
  }

  async function copyTravelLink() {
    navigator.clipboard.writeText(travelLink)

    setCopyTravelLinkSuccessMessage('Link copiado!')

    setTimeout(() => {
      setCopyTravelLinkSuccessMessage(undefined)
    }, 2000)
  }

  async function copyTravelSurveyLink() {
    navigator.clipboard.writeText(travelSurveyLink)

    setCopyTravelSurveyLinkSuccessMessage('Link copiado!')

    setTimeout(() => {
      setCopyTravelSurveyLinkSuccessMessage(undefined)
    }, 2000)
  }

  async function copyWhatsAppText() {
    navigator.clipboard.writeText(whatsAppText)

    setCopyWhatsAppTextSuccessMessage('Texto copiado!')

    setTimeout(() => {
      setCopyWhatsAppTextSuccessMessage(undefined)
    }, 2000)
  }

  async function redirectToTravelLink() {
    var win = window.open(travelLink, '_blank')
    win.focus()
  }

  async function redirectToTravelSurveyLink() {
    var win = window.open(travelSurveyLink, '_blank')
    win.focus()
  }

  async function getWhatsAppText() {
    try {
      setLoadingWhatsAppText(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/details/getwhatsapptext/${travel.id}`,
      )

      setWhatsAppText(data)

      setLoadingWhatsAppText(false)
    } catch (error) {
      console.log(error)

      setWhatsAppText(undefined)

      setLoadingWhatsAppText(false)
    }
  }

  async function getTravelSurveyLink() {
    setLoadingTravelSurveyLink(true)
    try {
      const { data } = await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship`,
        { travelId: travel.id },
      )

      setTravelSurveyLink(data.link)

      setLoadingTravelSurveyLink(false)
    } catch (error) {
      console.log(error)

      setTravelSurveyLink(undefined)
    } finally {
      setLoadingWhatsAppText(false)
    }
  }

  async function buttonEmail() {
    try {
      setLoadingEmail(true)

      if (
        window.confirm(
          'Deseja enviar o e-mail de roteiro pronto para o viajante?',
        )
      ) {
        const { data } = await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications/email/send-itinerary/${travel.id}`,
        )

        toast.success(data)
      }

      setLoadingEmail(false)
    } catch (error) {
      setLoadingEmail(false)

      toast.info(error.response.data)
    }
  }

  async function sendWhatsappToTraveler() {
    try {
      setLoadingSendWhatsAppText(true)

      if (
        window.confirm(
          'Deseja enviar o texto gerado para o WhatsApp do viajante?',
        )
      ) {
        const formData = new FormData()

        formData.append('text', whatsAppText)

        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications/send-whatsapp/${travel.id}`,
          formData,
        )

        toast.success('Mensagem enviada!')

        return setLoadingSendWhatsAppText(false)
      }

      return setLoadingSendWhatsAppText(false)
    } catch (error) {
      console.log(error)

      return setLoadingSendWhatsAppText(false)
    }
  }

  return (
    <div style={{ maxWidth: '40%' }}>
      <TravelDetailBlock
        style={{
          fontSize: '14px',
        }}
      >
        <SpaceBetweenDiv>
          <h5>Dados da Viagem</h5>

          {!isEditing && (
            <EditButton
              style={{ fontSize: '12px' }}
              onClick={onHandleEdit}
              type='button'
            >
              <i className='fas fa-edit'></i>
            </EditButton>
          )}

          {isEditing && (
            <EditButton
              style={{ fontSize: '12px' }}
              onClick={() => setIsEditing(false)}
              type='button'
            >
              <i className='fas fa-times'></i>
            </EditButton>
          )}
        </SpaceBetweenDiv>

        <SpaceBetweenDiv style={{ flexDirection: 'column' }}>
          {isEditing && travelToEdit && (
            <Form ref={formRef} onSubmit={handleSubmitEdit}>
              <Row>
                <Container>
                  <Row>
                    <Row>
                      <Col sm={12}>
                        <Input
                          disabled={loading}
                          name={'title'}
                          label={'Título *'}
                          value={travelToEdit.title}
                          maxLength={100}
                          onChange={e =>
                            setTravelToEdit({
                              ...travelToEdit,
                              title: e.target.value,
                            })
                          }
                          required
                        />
                      </Col>

                      <Col sm={12}>
                        <Input
                          name={'passengers'}
                          label={'Quantidade de Viajantes *'}
                          value={travelToEdit.passengers}
                          type={'number'}
                          maxLength={30}
                          onChange={e =>
                            setTravelToEdit({
                              ...travelToEdit,
                              passengers: e.target.value,
                            })
                          }
                          required
                        />
                      </Col>
                    </Row>

                    <Col sm={12}>
                      <Input
                        name={'start'}
                        label={'Início *'}
                        type={'date'}
                        value={travelToEdit.start}
                        onChange={e =>
                          setTravelToEdit({
                            ...travelToEdit,
                            start: getDateFormated(
                              e.target.value,
                              'YYYY-MM-DD',
                            ),
                          })
                        }
                        required
                      />
                    </Col>

                    <Col sm={12}>
                      <Input
                        name={'end'}
                        label={'Fim'}
                        type={'date'}
                        value={travelToEdit.end}
                        onChange={e =>
                          setTravelToEdit({
                            ...travelToEdit,
                            end: getDateFormated(e.target.value, 'YYYY-MM-DD'),
                          })
                        }
                        // required
                      />
                    </Col>

                    <Col sm={12}>
                      <CitiesSelect
                        name={'origin'}
                        label={'Origem'}
                        placeholder={'Pesquise por uma cidade...'}
                        onChange={e =>
                          setTravelToEdit({
                            ...travelToEdit,
                            origin: e.target.value,
                          })
                        }
                        required
                        defaultValue={travelToEdit.origin}
                      />
                    </Col>

                    {user.isOperator && (
                      <Col sm={12}>
                        <Input
                          name={'BelongingAgency'}
                          label={'Agência'}
                          type={'text'}
                          value={travelToEdit.belongingAgency}
                          onChange={e =>
                            setTravelToEdit({
                              ...travelToEdit,
                              belongingAgency: e.target.value,
                            })
                          }
                          required
                        />
                      </Col>
                    )}
                  </Row>

                  <Col sm={12}>
                    <TextArea
                      style={{ minHeight: '200px' }}
                      name={'desc'}
                      label={'Observações (visível somente para o consultor)'}
                      value={travelToEdit.desc}
                      maxLength={2000}
                      placeholder={'Escreva aqui...'}
                      onChange={e =>
                        setTravelToEdit({
                          ...travelToEdit,
                          desc: e.target.value,
                        })
                      }
                    />
                  </Col>

                  <Col sm={12}>
                    <SpaceBetweenDiv>
                      <DeleteButton
                        onClick={() => OnDeleteClick(travelDetails.id)}
                        type='button'
                      >
                        {loading && <Spinner size={'sm'} />}

                        {!loading && 'Excluir'}
                      </DeleteButton>

                      <SaveButton disabled={loading} type='submit'>
                        {loading && <Spinner size={'sm'} />}

                        {!loading && 'Salvar'}
                      </SaveButton>
                    </SpaceBetweenDiv>
                  </Col>
                </Container>
              </Row>
            </Form>
          )}

          {!isEditing && (
            <>
              <Row>
                <Col sm={12} lg={6}>
                  <span>
                    <b>Título: </b> <br />
                    {travelDetails.title}
                  </span>
                </Col>
                <Col sm={12} lg={6}>
                  <span>
                    <b>Quantidade de Viajantes: </b> <br />
                    {travelDetails.passengers}
                  </span>
                </Col>

                <Col sm={12} lg={6}>
                  <span>
                    <b>Inicio: </b> <br />
                    {getDateFormated(travelDetails.start, 'DD/MM/YYYY')}
                  </span>
                </Col>

                {travelDetails.end && (
                  <Col sm={12} lg={6}>
                    <span>
                      <b>Fim: </b> <br />
                      {getDateFormated(travelDetails.end, 'DD/MM/YYYY')}
                    </span>
                  </Col>
                )}

                <Col sm={12} lg={6}>
                  <span>
                    <b>Origem: </b> <br />
                    {travelDetails.origin}
                  </span>
                </Col>

                {user.isOperator && (
                  <Col sm={12} lg={6}>
                    <span>
                      <b>Agência: </b> <br />
                      {travelDetails.belongingAgency}
                    </span>
                  </Col>
                )}
              </Row>

              <Row style={{ width: '100%' }}>
                <Col sm={12}>
                  {travelDetails.desc && (
                    <div>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          maxHeight: '150px',
                          overflow: 'auto',
                        }}
                      >
                        <b>Observações: </b> <br />
                        <div>{travelDetails.desc}</div>
                      </p>
                    </div>
                  )}
                </Col>
              </Row>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  gap: '8px',
                }}
              >
                {!travelLink && (
                  <RegisterNewButton
                    style={{ fontSize: '12px' }}
                    onClick={generateLink}
                    disabled={loadingLink}
                    type='button'
                  >
                    {!loadingLink && 'Gerar link para o viajante'}

                    {loadingLink && <LoadingContainer size={'sm'} />}
                  </RegisterNewButton>
                )}

                {travelLink && (
                  <SpaceBetweenDiv>
                    <Form ref={null} style={{ width: '100%' }}>
                      <Row>
                        <Col sm={12}>
                          <Input
                            label={'Link para o Viajante'}
                            successMessage={copyTravelLinkSuccessMessage}
                            name={'link'}
                            disabled={true}
                            value={travelLink}
                            searchButton={true}
                            cancelButton={true}
                            searchIcon={'fas fa-copy'}
                            cancelIcon={'fas fa-external-link-alt'}
                            callbackSearch={() => copyTravelLink()}
                            callbackCancel={() => redirectToTravelLink()}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </SpaceBetweenDiv>
                )}

                {travel.travelers.some(x => x.email) && (
                  <RegisterNewButton
                    style={{ fontSize: '12px' }}
                    onClick={buttonEmail}
                    disabled={loadingEmail}
                    type='button'
                  >
                    {!loadingEmail && 'Enviar e-mail de roteiro pronto'}

                    {loadingEmail && <LoadingContainer size={'sm'} />}
                  </RegisterNewButton>
                )}

                {!whatsAppText && (
                  <RegisterNewButton
                    style={{ fontSize: '12px' }}
                    onClick={getWhatsAppText}
                    disabled={loadingWhatsAppText}
                    type='button'
                  >
                    {!loadingWhatsAppText && 'Gerar texto para Whats App'}

                    {loadingWhatsAppText && <LoadingContainer size={'sm'} />}
                  </RegisterNewButton>
                )}

                {whatsAppText && (
                  <SpaceBetweenDiv>
                    <Form ref={null} style={{ width: '100%' }}>
                      <Row>
                        <Col sm={12}>
                          <TextArea
                            name={'whatsapptext'}
                            label={'Texto para WhatsApp'}
                            value={whatsAppText}
                            icon={'fas fa-copy'}
                            clickIcon={copyWhatsAppText}
                            successMessage={copyWhatsAppTextSuccessMessage}
                            disabled
                          />

                          {user.agencyAllowWhatsapp &&
                            user.allowWhatsapp &&
                            travel.travelers.some(x => x.phone) && (
                              <SaveButton
                                onClick={sendWhatsappToTraveler}
                                type={'button'}
                              >
                                {!loadingSendWhatsAppText &&
                                  'Enviar agora para o WhatsApp do viajante?'}

                                {loadingSendWhatsAppText && (
                                  <LoadingContainer size={'sm'} />
                                )}
                              </SaveButton>
                            )}
                        </Col>
                      </Row>
                    </Form>
                  </SpaceBetweenDiv>
                )}

                {!travelSurveyLink && (
                  <RegisterNewButton
                    style={{ fontSize: '12px' }}
                    onClick={getTravelSurveyLink}
                    disabled={loadingTravelSurveyLink}
                    type='button'
                  >
                    {!loadingTravelSurveyLink && 'Gerar link para pesquisa'}

                    {loadingTravelSurveyLink && (
                      <LoadingContainer size={'sm'} />
                    )}
                  </RegisterNewButton>
                )}
                {travelSurveyLink && (
                  <SpaceBetweenDiv>
                    <Form ref={null} style={{ width: '100%' }}>
                      <Row>
                        <Col sm={12}>
                          <Input
                            label={'Link para pesquisa'}
                            successMessage={copyTravelSurveyLinkSuccessMessage}
                            name={'travelsurveylink'}
                            disabled={true}
                            value={travelSurveyLink}
                            searchButton={true}
                            cancelButton={true}
                            searchIcon={'fas fa-copy'}
                            cancelIcon={'fas fa-external-link-alt'}
                            callbackSearch={() => copyTravelSurveyLink()}
                            callbackCancel={() => redirectToTravelSurveyLink()}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </SpaceBetweenDiv>
                )}
              </div>
            </>
          )}
        </SpaceBetweenDiv>
      </TravelDetailBlock>
    </div>
  )
}

export default TravelBlock
