import { useEffect, useState } from 'react'
import styled from 'styled-components'
import DivForHTML from '~/components/DivForHTML'
import {
  getDateDDMMM,
  getDateFormated,
  getDateFull,
  getDateTimeFormated,
  getMoment,
  getTimeFormated,
} from '~/functions/Date'
import { getFirstWords } from '~/functions/String'
import RoadMapItem from './RoadMapItem'

export default function ItineraryListItem({
  item,
  dateFormat,
  showTitle,
  // index,
  nameAgency,
}) {
  const [items, setItems] = useState([])

  useEffect(() => {
    const newItems = []

    item.airs.map(e =>
      newItems.push({
        ...e,
        type: 'air',
        startDate: e.departure,
        endDate: e.arrival,
      }),
    )

    item.trains.map(e =>
      newItems.push({
        ...e,
        type: 'train',
        startDate: e.departure,
        endDate: e.arrival,
      }),
    )

    item.nautical.map(e =>
      newItems.push({
        ...e,
        typeNautical: e.type,
        type: 'nautical',
        startDate: e.departure,
        endDate: e.arrival,
      }),
    )

    item.hotels.map(e =>
      newItems.push({
        ...e,
        type: 'hotel',
        startDate: e.checkIn,
        endDate: e.checkOut,
      }),
    )
    item.cars.map(e =>
      newItems.push({
        ...e,
        type: 'car',
        startDate: e.checkIn,
        endDate: e.checkOut,
      }),
    )
    item.generics.map(e =>
      newItems.push({
        ...e,
        type: 'generic',
        startDate: e.start,
        endDate: e.end,
      }),
    )
    item.roadMaps.map(e =>
      newItems.push({
        ...e,
        type: 'roadMap',
        startDate: e.start,
        endDate: e.end,
      }),
    )
    item.tickets.map(e =>
      newItems.push({
        ...e,
        type: 'ticket',
        startDate: e.start,
        endDate: e.end,
      }),
    )
    item.vehicleRentals.map(e =>
      newItems.push({
        ...e,
        type: 'vehicleRentals',
        startDate: e.checkIn,
        endDate: e.checkOut,
      }),
    )

    newItems.sort((a, b) => {
      const dateA =
        getDateFormated(item.date) === getDateFormated(a.startDate)
          ? getMoment(a.startDate)
          : getMoment(a.endDate)

      const dateB =
        getDateFormated(item.date) === getDateFormated(b.startDate)
          ? getMoment(b.startDate)
          : getMoment(b.endDate)

      if (dateA < dateB) {
        return -1
      }

      if (dateA > dateB) {
        return 1
      }

      return 0
    })

    setItems(newItems)
  }, [])

  return (
    <div className='a4-height no-break day-section'>
      {showTitle &&
        (nameAgency.name == 'Wee Travel' ? (
          <ItineraryTitleWee>Roteiro</ItineraryTitleWee>
        ) : (
          <ItineraryTitle style={{ color: `${nameAgency.secondaryColor}` }}>
            Roteiro
          </ItineraryTitle>
        ))}

      {nameAgency.name == 'Wee Travel' ? (
        <TitleWee>
          {`${getDateDDMMM(item.date)} (${getDateFull(item.date).dayOfWeek})`}
        </TitleWee>
      ) : (
        // <Title>{`${item.order}º dia | ${getDateFormated(
        //   item.date,
        // )} (${getDateFull(item.date)
        //   .dayOfWeek.toUpperCase()
        //   .substring(0, 3)})`}</Title>
        <Title>
          {getDateFormated(item.date)} (
          {getDateFull(item.date).dayOfWeek.toUpperCase().substring(0, 3)})
        </Title>
      )}

      <Items>
        {items.map((e, i) => {
          return (
            <Item key={i}>
              {e.type === 'air' && (
                <>
                  {nameAgency.name == 'Wee Travel' ? (
                    <IconWee className='fas fa-plane' />
                  ) : (
                    <Icon
                      style={{ color: `${nameAgency.primaryColor}` }}
                      className='fas fa-plane'
                    />
                  )}
                  <h5 className='hour-label'>
                    {/* {item.date.format(dateFormat) ===
                      getDateFormated(e.departure, dateFormat) && (
                      <b>{getTimeFormated(e.departure)}</b>
                    )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.departure, dateFormat) &&
                      item.date.format(dateFormat) ===
                        getDateFormated(e.arrival, dateFormat) && (
                        <b>&nbsp;-&nbsp;</b>
                      )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.arrival, dateFormat) && (
                      <b>{getTimeFormated(e.arrival)}</b>
                    )} */}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.departure, dateFormat) &&
                      item.date.format(dateFormat) ===
                        getDateFormated(e.arrival, dateFormat) && (
                        <b>
                          {`${getTimeFormated(e.departure)} - ${getTimeFormated(
                            e.arrival,
                          )}`}
                        </b>
                      )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.departure, dateFormat) &&
                      item.date.format(dateFormat) !==
                        getDateFormated(e.arrival, dateFormat) && (
                        <b>
                          {`${getTimeFormated(e.departure)} - ${getTimeFormated(
                            e.arrival,
                          )} (${getDateFormated(e.arrival, 'DD/MM')})`}
                        </b>
                      )}
                  </h5>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-between',
                      gap: '10px',
                      width: '75%',
                      maxWidth: '100%',
                    }}
                  >
                    {/* <div>
                      <b>{e.origin}</b>
                      <span>
                        <SmallIcon className='fas fa-arrow-right' />
                      </span>
                      <b>{e.destiny}</b>
                    </div> */}
                  </div>
                  <div style={{ maxWidth: '100%' }}>
                    <div>
                      {e.departure && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <span
                            style={{
                              width: '40%',
                            }}
                          >
                            Partida:<b> {e.origin} </b>
                          </span>
                          <b
                            style={{
                              width: '20%',
                              textAlign: 'center',
                            }}
                          >
                            {getTimeFormated(e.departure)}
                          </b>
                          <b
                            style={{
                              width: '40%',
                              textAlign: 'center',
                            }}
                          >
                            {e.airportOrigin && ` ${e.airportOrigin}`}
                          </b>
                        </div>
                      )}
                    </div>
                    <div>
                      {e.arrival && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <span
                            style={{
                              width: '40%',
                            }}
                          >
                            Chegada:
                            <b> {e.destiny} </b>
                          </span>
                          <b
                            style={{
                              width: '20%',
                              textAlign: 'center',
                            }}
                          >
                            {getTimeFormated(e.arrival)}
                          </b>
                          <b
                            style={{
                              width: '40%',
                              textAlign: 'center',
                            }}
                          >
                            {e.airportDestiny && ` ${e.airportDestiny}`}
                          </b>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {e.cia && (
                        <span
                          style={{
                            width: '25%',
                          }}
                        >
                          Cia: <b>{e.cia}</b>
                        </span>
                      )}

                      {e.ciaIata && (
                        <span
                          style={{
                            width: '25%',
                          }}
                        >
                          Código da cia: <b>{e.ciaIata}</b>
                        </span>
                      )}

                      {e.flight && (
                        <span
                          style={{
                            width: '25%',
                          }}
                        >
                          N° do voo: <b>{e.flight}</b>
                        </span>
                      )}

                      {e.seat && (
                        <span
                          style={{
                            width: '25%',
                          }}
                        >
                          Assento: <b>{e.seat}</b>
                        </span>
                      )}
                    </div>

                    <br />

                    {e.description && (
                      <span
                        style={{
                          width: '25%',
                        }}
                      >
                        Observação: <b>{e.description}</b>
                      </span>
                    )}
                  </div>

                  <br />

                  <font size='2px'>
                    **Esse horário refere-se ao voo. Fique atento para o horário
                    de chegada ao aeroporto.
                  </font>
                </>
              )}

              {e.type === 'train' && (
                <>
                  {nameAgency.name == 'Wee Travel' ? (
                    <IconWee className='fas fa-train' />
                  ) : (
                    <Icon
                      style={{ color: `${nameAgency.primaryColor}` }}
                      className='fas fa-train'
                    />
                  )}
                  <h5 className='hour-label'>
                    {item.date.format(dateFormat) ===
                      getDateFormated(e.departure, dateFormat) && (
                      <b>{getTimeFormated(e.departure)}</b>
                    )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.departure, dateFormat) &&
                      item.date.format(dateFormat) ===
                        getDateFormated(e.arrival, dateFormat) && (
                        <b>&nbsp;-&nbsp;</b>
                      )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.arrival, dateFormat) && (
                      <b>{getTimeFormated(e.arrival)}</b>
                    )}
                  </h5>

                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-between',
                      gap: '10px',
                      width: '75%',
                      maxWidth: '100%',
                    }}
                  >
                    <div>
                      <b>{e.origin}</b>
                      <span>
                        <SmallIcon className='fas fa-arrow-right' />
                      </span>
                      <b>{e.destiny}</b>
                    </div>
                  </div>

                  <div style={{ maxWidth: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.departure && (
                        <p>
                          Partida:{' '}
                          <b>
                            {getDateFormated(e.departure, 'DD/MM/YYYY HH:mm')}
                          </b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.arrival && (
                        <p>
                          Chegada:{' '}
                          <b>
                            {getDateFormated(e.arrival, 'DD/MM/YYYY HH:mm')}
                          </b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.airportOrigin && (
                        <p>
                          Estação de Origem: <b>{e.airportOrigin}</b>
                        </p>
                      )}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.airportDestiny && (
                        <p>
                          Estação de Destino: <b>{e.airportDestiny}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.cia && (
                        <p>
                          Cia: <b>{e.cia}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.ciaIata && (
                        <p>
                          Código cia ferroviária:
                          <b>{e.ciaIata}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.locator && (
                        <p>
                          Bilhete/Localizador: <b>{e.locator}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.originPurchase && (
                        <p>
                          Fornecedor: <b>{e.originPurchase}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.seat && (
                        <p>
                          Assento: <b>{e.seat}</b>
                        </p>
                      )}
                    </div>

                    <br />

                    {e.description && (
                      <span
                        style={{
                          width: '25%',
                        }}
                      >
                        Observação: <b>{e.description}</b>
                      </span>
                    )}
                  </div>
                </>
              )}

              {e.type === 'ticket' && (
                <>
                  {nameAgency.name == 'Wee Travel' ? (
                    <IconWee className='fas fa-ticket-alt' />
                  ) : (
                    <Icon
                      style={{ color: `${nameAgency.primaryColor}` }}
                      className='fas fa-ticket-alt'
                    />
                  )}

                  <div>
                    {getTimeFormated(e.startDate) == '00:00' &&
                    getTimeFormated(e.endDate) == '00:00' ? (
                      <h5 className='hour-label'>Horário livre</h5>
                    ) : (
                      <h5 className='hour-label'>
                        {item.date.format(dateFormat) ===
                          getDateFormated(e.startDate, dateFormat) && (
                          <b>{getTimeFormated(e.startDate)}</b>
                        )}

                        {item.date.format(dateFormat) ===
                          getDateFormated(e.startDate, dateFormat) &&
                          item.date.format(dateFormat) ===
                            getDateFormated(e.endDate, dateFormat) && (
                            <b>&nbsp;-&nbsp;</b>
                          )}

                        {item.date.format(dateFormat) ===
                          getDateFormated(e.endDate, dateFormat) && (
                          <b>{getTimeFormated(e.endDate)}</b>
                        )}
                      </h5>
                    )}
                  </div>

                  <b>{e.title}</b>

                  <InlineItems>
                    {/* <InlineItem style={{ display: 'inline-block' }}>
                      Início: <b>{getTimeFormated(e.start)}</b>
                    </InlineItem> */}

                    {getDateFormated(e.start) !== getDateFormated(e.end) && (
                      <InlineItem style={{ display: 'inline-block' }}>
                        Fim: <b>{getDateTimeFormated(e.end)}</b>
                      </InlineItem>
                    )}

                    {e.startAddress && (
                      <InlineItem>
                        Endereço: <b>{e.startAddress}</b>
                      </InlineItem>
                    )}

                    {e.city && (
                      <InlineItem>
                        Cidade: <b>{e.city}</b>
                      </InlineItem>
                    )}
                  </InlineItems>

                  <br />

                  {e.desc && <DivForHTML text={getFirstWords(e.desc, 2000)} />}
                </>
              )}

              {e.type === 'nautical' && (
                <>
                  {nameAgency.name == 'Wee Travel' ? (
                    <IconWee className='fas fa-anchor' />
                  ) : (
                    <Icon
                      style={{ color: `${nameAgency.primaryColor}` }}
                      className='fas fa-anchor'
                    />
                  )}
                  <h5 className='hour-label'>
                    {item.date.format(dateFormat) ===
                      getDateFormated(e.departure, dateFormat) && (
                      <b>{getTimeFormated(e.departure)}</b>
                    )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.departure, dateFormat) &&
                      item.date.format(dateFormat) ===
                        getDateFormated(e.arrival, dateFormat) && (
                        <b>&nbsp;-&nbsp;</b>
                      )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.arrival, dateFormat) && (
                      <b>{getTimeFormated(e.arrival)}</b>
                    )}
                  </h5>

                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-between',
                      gap: '10px',
                      width: '75%',
                      maxWidth: '100%',
                    }}
                  >
                    <div>
                      <b>{e.origin}</b>
                      <span>
                        <SmallIcon className='fas fa-arrow-right' />
                      </span>
                      <b>{e.destiny}</b>
                    </div>

                    {e.typeNautical && (
                      <span>
                        Tipo do náutico: <b>{e.typeNautical}</b>
                      </span>
                    )}
                  </div>

                  <div style={{ maxWidth: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.departure && (
                        <p>
                          Partida: <b>{getTimeFormated(e.departure)}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.arrival && (
                        <p>
                          Chegada: <b>{getTimeFormated(e.arrival)}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.seaportOrigin && (
                        <p>
                          Porto de Origem: <b>{e.seaportOrigin}</b>
                        </p>
                      )}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.seaportDestiny && (
                        <p>
                          Porto de Destino: <b>{e.seaportDestiny}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.cia && (
                        <p>
                          Cia: <b>{e.cia}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.vesselName && (
                        <p>
                          Nome da embarcação:
                          <b>{e.vesselName}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.locator && (
                        <p>
                          Bilhete/Localizador: <b>{e.locator}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.flight && (
                        <p>
                          Número do náutico: <b>{e.flight}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.cabin && (
                        <p>
                          Cabine: <b>{e.cabin}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.originPurchase && (
                        <p>
                          Fornecedor: <b>{e.originPurchase}</b>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {e.description && (
                        <p>
                          Descrição: <b>{e.description}</b>
                        </p>
                      )}
                    </div>
                  </div>
                  <font size='2px'>
                    **Esse horário refere-se ao partida. Fique atento para o
                    horário de chegada ao porto.
                  </font>
                </>
              )}

              {e.type === 'hotel' && (
                <>
                  {nameAgency.name == 'Wee Travel' ? (
                    <IconWee className='fas fa-hotel' />
                  ) : (
                    <Icon
                      style={{ color: `${nameAgency.primaryColor}` }}
                      className='fas fa-hotel'
                    />
                  )}

                  <span className='hour-label'>
                    {item.date.format(dateFormat) ===
                      getDateFormated(e.checkIn, dateFormat) && (
                      <>
                        <p
                          style={{
                            fontSize: '22.4px',
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          {getTimeFormated(e.checkIn)} - Check-in
                        </p>
                        <p style={{ fontWeight: 'bold', color: '#000' }}>
                          {e.provider}
                        </p>
                        <DivForHTML
                          text={e.desc ? e.desc.substring(0, 500) : ''}
                        />
                        <div
                          style={{
                            // display: 'flex',
                            // gap: '10px',
                            color: '#000',
                          }}
                        >
                          {e.city && (
                            <p>
                              Cidade: <b>{e.city}</b>
                            </p>
                          )}
                          {e.fullAddress && (
                            <p>
                              Endereço: <b>{e.fullAddress}</b>
                            </p>
                          )}
                        </div>
                      </>
                    )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.checkIn, dateFormat) &&
                      item.date.format(dateFormat) ===
                        getDateFormated(e.checkOut, dateFormat) && (
                        <b>&nbsp;&nbsp;</b>
                      )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.checkOut, dateFormat) && (
                      <>
                        <p
                          style={{
                            fontSize: '22.4px',
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          {getTimeFormated(e.checkOut)} - Check-out
                        </p>
                        <p style={{ fontWeight: 'bold', color: '#000' }}>
                          {e.provider}
                        </p>
                        {/* <br /> <br /> */}
                        <DivForHTML
                          text={
                            e.descCheckOut
                              ? e.descCheckOut.substring(0, 500)
                              : ''
                          }
                        />
                      </>
                    )}
                  </span>

                  {/* <InlineItems>
                    <InlineItem>
                      Cidade: <b>{e.city}</b>
                    </InlineItem>
                    {e.fullAddress && (
                      <InlineItem>
                        Endereço: <b>{e.fullAddress}</b>
                      </InlineItem>
                    )}
                  </InlineItems> */}
                </>
              )}

              {e.type === 'car' && (
                <>
                  {nameAgency.name == 'Wee Travel' ? (
                    <IconWee className='fas fa-car' />
                  ) : (
                    <Icon
                      style={{ color: `${nameAgency.primaryColor}` }}
                      className='fas fa-car'
                    />
                  )}

                  <span className='hour-label'>
                    {item.date.format(dateFormat) ===
                      getDateFormated(e.checkIn, dateFormat) && (
                      <>
                        <p
                          style={{
                            fontSize: '22.4px',
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          {getTimeFormated(e.checkIn)} - Partida
                        </p>
                        <div style={{ color: '#000' }}>
                          <div style={{ display: 'flex', gap: '10px' }}>
                            {e.provider && (
                              <span>
                                Locadora/Fornecedor: <b>{e.provider}</b>
                              </span>
                            )}
                            {e.locator && (
                              <span>
                                Localizador: <b>{e.locator}</b>
                              </span>
                            )}
                          </div>
                          {e.origin && (
                            <p>
                              Cidade do Transfer/Locação: <b>{e.origin}</b>
                            </p>
                          )}
                          {e.originAddress && (
                            <p>
                              Local de partida: <b>{e.originAddress}</b>
                            </p>
                          )}
                        </div>

                        <br />

                        {e.description && (
                          <span
                            style={{
                              width: '25%',
                            }}
                          >
                            Observação: <b>{e.description}</b>
                          </span>
                        )}

                        <br />
                      </>
                    )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.checkIn, dateFormat) &&
                      item.date.format(dateFormat) ===
                        getDateFormated(e.checkOut, dateFormat) && (
                        <b>&nbsp;&nbsp;</b>
                      )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.checkOut, dateFormat) && (
                      <>
                        <p
                          style={{
                            fontSize: '22.4px',
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          {getTimeFormated(e.checkOut)} - Chegada
                        </p>
                        <div style={{ color: '#000' }}>
                          {e.destiny && (
                            <p>
                              Cidade de Chegada/Entrega: <b>{e.destiny}</b>
                            </p>
                          )}
                          {e.destinyAddress && (
                            <p>
                              Local de Chegada: <b>{e.destinyAddress}</b>
                            </p>
                          )}
                        </div>
                      </>
                    )}
                  </span>
                </>
              )}

              {e.type === 'vehicleRentals' && (
                <>
                  {nameAgency.name == 'Wee Travel' ? (
                    <IconWee className='fas fa-car-side' />
                  ) : (
                    <Icon
                      style={{ color: `${nameAgency.primaryColor}` }}
                      className='fas fa-car-side'
                    />
                  )}

                  <span className='hour-label'>
                    {item.date.format(dateFormat) ===
                      getDateFormated(e.checkIn, dateFormat) && (
                      <>
                        <p
                          style={{
                            fontSize: '22.4px',
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: 'bold',
                            paddingLeft: '3px',
                          }}
                        >
                          {getTimeFormated(e.checkIn)}
                        </p>
                        <div style={{ color: '#000' }}>
                          <div style={{ display: 'flex', gap: '10px' }}>
                            {e.provider && (
                              <span>
                                Locadora: <b>{e.provider}</b>
                              </span>
                            )}
                            {e.locator && (
                              <span>
                                Localizador: <b>{e.locator}</b>
                              </span>
                            )}
                          </div>
                          {e.originAddress && (
                            <p>
                              Endereço de locação: <b>{e.originAddress}</b>
                            </p>
                          )}
                        </div>

                        <br />

                        {e.description && (
                          <span
                            style={{
                              width: '25%',
                            }}
                          >
                            Observação: <b>{e.description}</b>
                          </span>
                        )}

                        <br />
                      </>
                    )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.checkIn, dateFormat) &&
                      item.date.format(dateFormat) ===
                        getDateFormated(e.checkOut, dateFormat) && (
                        <b>&nbsp;&nbsp;</b>
                      )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.checkOut, dateFormat) && (
                      <>
                        <p
                          style={{
                            fontSize: '22.4px',
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: 'bold',
                            paddingLeft: '3px',
                          }}
                        >
                          {getTimeFormated(e.checkOut)}
                        </p>
                        <div style={{ color: '#000' }}>
                          {e.destinyAddress && (
                            <p>
                              Endereço de devolução: <b>{e.destinyAddress}</b>
                            </p>
                          )}
                        </div>
                      </>
                    )}
                  </span>
                </>
              )}

              {e.type === 'generic' && (
                <>
                  {nameAgency.name == 'Wee Travel' ? (
                    <IconWee className='fas fa-check' />
                  ) : (
                    <Icon
                      style={{ color: `${nameAgency.primaryColor}` }}
                      className='fas fa-check'
                    />
                  )}

                  <h5 className='hour-label'>
                    {item.date.format(dateFormat) ===
                      getDateFormated(e.start, dateFormat) &&
                      getDateFormated(e.start, 'HH:mm') == '00:00' &&
                      getDateFormated(e.end, 'HH:mm') == '00:00' && (
                        <b>{e.title}</b>
                      )}

                    {item.date.format(dateFormat) ===
                      getDateFormated(e.start, dateFormat) &&
                      item.date.format(dateFormat) ===
                        getDateFormated(e.end, dateFormat) &&
                      (getDateFormated(e.start, 'HH:mm') != '00:00' ||
                        getDateFormated(e.end, 'HH:mm') != '00:00') && (
                        <b>
                          {getTimeFormated(e.start)} - {getTimeFormated(e.end)}
                        </b>
                      )}
                  </h5>
                  {item.date.format(dateFormat) ===
                    getDateFormated(e.start, dateFormat) &&
                    ((getDateFormated(e.start, 'HH:mm') == '00:00' &&
                      getDateFormated(e.end, 'HH:mm') == '00:00') ||
                      (getDateFormated(e.start, 'HH:mm') != '00:00' &&
                        getDateFormated(e.end, 'HH:mm') == '00:00') ||
                      (getDateFormated(e.start, 'HH:mm') == '00:00' &&
                        getDateFormated(e.end, 'HH:mm') != '00:00')) && <b></b>}

                  {getDateFormated(e.start, 'HH:mm') != '00:00' &&
                    getDateFormated(e.end, 'HH:mm') != '00:00' && (
                      <b>
                        <b>{e.title}</b>
                      </b>
                    )}

                  <InlineItems>
                    {item.date.format(dateFormat) ===
                      getDateFormated(e.start, dateFormat) &&
                      ((getDateFormated(e.start, 'HH:mm') != '00:00' &&
                        getDateFormated(e.end, 'HH:mm') != '00:00') ||
                        (getDateFormated(e.start, 'HH:mm') == '00:00' &&
                          getDateFormated(e.end, 'HH:mm') != '00:00') ||
                        (getDateFormated(e.start, 'HH:mm') != '00:00' &&
                          getDateFormated(e.end, 'HH:mm') == '00:00')) && (
                        <>
                          <InlineItem style={{ display: 'inline-block' }}>
                            Início: <b>{getTimeFormated(e.start)}</b>
                          </InlineItem>

                          <InlineItem style={{ display: 'inline-block' }}>
                            Fim: <b>{getTimeFormated(e.end)}</b>
                          </InlineItem>
                        </>
                      )}

                    <InlineItem>
                      Endereço: <b>{e.startAddress}</b>
                    </InlineItem>

                    <InlineItem>
                      Cidade: <b>{e.city}</b>
                    </InlineItem>
                  </InlineItems>

                  <br />

                  <DivForHTML
                    text={getFirstWords(e.desc, 2000)}
                    imgSrc={
                      e.image ? `data:image/*;base64,${e.image}` : undefined
                    }
                    imgAlt={
                      e.image ? `Imagem do serviço ${e.title}` : undefined
                    }
                  />
                </>
              )}

              {e.type === 'roadMap' && (
                <RoadMapItem data={e} index={i} nameAgency={nameAgency} />
              )}
            </Item>
          )
        })}
      </Items>
    </div>
  )
}

const ItineraryTitle = styled.h3`
  /* color: ${props => props.theme.colors.primary}; */
  padding-left: 40px;
`

const Title = styled.h4`
  /* color: ${props => props.theme.colors.secondary}; */
  padding-left: 40px;
  margin-bottom: 0;
`

const ItineraryTitleWee = styled.h3`
  color: #d54a26;
  padding-left: 40px;
`

const TitleWee = styled.h4`
  color: #d54a26;
  padding-left: 40px;
  margin-bottom: 0;
`

const Items = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const Item = styled.li`
  position: relative;
  width: 100%;
  padding-left: 40px;
  padding-top: 16px;
  clear: both;
  overflow: hidden;

  .hour-label {
    color: #777;
  }
`

const InlineItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
`

const InlineItem = styled.li`
  position: relative;
  margin-right: 16px;
`

const Icon = styled.i`
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  font-size: 28px;
  text-align: center;
  /* color: ${props => props.theme.colors.primary}; */
`

const IconWee = styled.i`
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  font-size: 28px;
  text-align: center;
  color: #d54a26;
`

const SmallIcon = styled.i`
  width: 15px;
  height: 15px;
  margin-right: 2px;
  padding: 2px;
  font-size: 11px;
  text-align: center;
`
