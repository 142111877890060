import React from 'react'
import styled from 'styled-components'
import SkeletonLine from '~/components/Skeleton'

const SkeletonCardActivities = ({ count, width, key = 'styledCard' }) => {
  return (
    <>
      <Content key={key}>
        <Date>
          <SkeletonLine height={'30px'} />
        </Date>
        <Period>
          <SkeletonLine height={'24px'} />
        </Period>
        <Subtitle>
          <SkeletonLine />
        </Subtitle>
        <Container>
          <Text>
            <SkeletonLine count={count ? count : 8} />
          </Text>
          <Image>
            <SkeletonLine width={width ? width : '100%'} height={'320px'} />
          </Image>
        </Container>
      </Content>
    </>
  )
}

export default SkeletonCardActivities

const Content = styled.div``

const Container = styled.div`
  display: flex;
  gap: 10px;
`
const Text = styled.div`
  margin-right: 0rem;
  width: 49%;
`
const Image = styled.div`
  width: 430px;
  heigt: 320px;
  float: right;
  margin: 0 0 16px 16px;
  object-fit: contain;
  break-inside: avoid-page;
`
const Period = styled.div`
  width: 8%;
`
const Subtitle = styled.div`
  width: 10%;
`
const Date = styled.div`
  width: 18%;
  padding: 10px 0px 10px 0px;
`
