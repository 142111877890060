import { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody } from '~/components/Panel'
import { toast } from 'react-toastify'
import PageTitle from '~/components/PageTitle'
import { Container } from './styles'
import DefaultForm from '~/components/DefaultForm'

export default function CreateDocument() {
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)

  async function handleSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      for (let key in data) {
        formData.append(key, data[key])
      }

      formData.append('travelId', travel.id)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelDocs`,
        formData,
      )

      setLoading(false)

      toast.success('Documento cadastrado com sucesso!')

      navigate(-1)
    } catch (error) {
      setLoading(false)

      toast.info('Não foi possível cadastrar o documento. Tente mais tarde!')
    }
  }

  return (
    <Panel>
      <PanelBody>
        <Container>
          <PageTitle title={'Cadastrar documento'} head={'h5'} />

          <DefaultForm
            callbackSubmit={handleSubmit}
            callbackReset={() => {
              return
            }}
            loading={loading}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Título',
                placeholder: 'Título',
                sm: 12,
                lg: 12,
                required: true,
              },
              {
                type: 'text',
                name: 'desc',
                label: 'Descrição',
                placeholder: 'Descrição',
                sm: 12,
                lg: 12,
              },
              {
                type: 'fileInput',
                name: 'data',
                label: 'Arquivo',
                sm: 12,
                lg: 12,
                required: true,
              },
            ]}
          />
        </Container>
      </PanelBody>
    </Panel>
  )
}
