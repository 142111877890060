import { getCapitalize } from '~/functions/String'
import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-br')

export function getMoment(date) {
  return moment(date)
}

export function getMomentUtc(date) {
  return moment(date)
}

export function getDateFormated(date, format) {
  const newDate = getMoment(date)

  return newDate.format(format ?? 'DD/MM/YY')
}

export function getDateTimeFormated(date) {
  const newDate = getMoment(date)

  return newDate.format('DD/MM/YYYY HH:mm')
}

export function getTimeFormated(date) {
  const newDate = getMoment(date)

  return newDate.format('HH:mm')
}

export function getDateStatus(from, to) {
  const dateNow = getMoment()
  const dateFrom = getMoment(from, 'YYYY-MM-DD')
  const dateTo = getMoment(to, 'YYYY-MM-DD')

  if (dateNow.diff(dateFrom) < 0) {
    return -1
  }

  if (dateNow.diff(dateTo) > 0) {
    return 1
  }

  return 0
}

export function getDateDiff(from, to) {
  const dateFrom = getMoment(from)
  const dateTo = getMoment(to)
  const seconds =
    dateFrom < dateTo
      ? dateTo.diff(dateFrom, 'seconds')
      : dateFrom.diff(dateTo, 'seconds')
  const minutes =
    dateFrom < dateTo
      ? dateTo.diff(dateFrom, 'minutes')
      : dateFrom.diff(dateTo, 'minutes')
  const hours =
    dateFrom < dateTo
      ? dateTo.diff(dateFrom, 'hours')
      : dateFrom.diff(dateTo, 'hours')
  const days =
    dateFrom < dateTo
      ? dateTo.diff(dateFrom, 'days')
      : dateFrom.diff(dateTo, 'days')
  const months =
    dateFrom < dateTo
      ? dateTo.diff(dateFrom, 'months')
      : dateFrom.diff(dateTo, 'months')
  const years =
    dateFrom < dateTo
      ? dateTo.diff(dateFrom, 'years')
      : dateFrom.diff(dateTo, 'years')
  const hmString = `${Math.floor((minutes % 1440) / 60)}h ${Math.floor(
    (minutes % 1440) % 60,
  )}min`

  return {
    seconds,
    minutes,
    hours,
    days,
    months,
    years,
    hmString,
  }
}

export function getDateDDMMM(date) {
  return getMoment(date).format('DD [de] MMM')
}

export function getDateDDMMMFullName(date) {
  return getMoment(date).format('DD [de] MMMM')
}

export function getDateFull(date = getMoment()) {
  const dateToReturn = getMoment(date)

  const dayOfWeek = getCapitalize(dateToReturn.format('dddd'))
  const month = getCapitalize(dateToReturn.format('MM'))
  const monthLabel = getCapitalize(dateToReturn.format('MMMM'))
  const day = getCapitalize(dateToReturn.format('DD'))
  const year = getCapitalize(dateToReturn.format('YYYY'))

  return {
    dayOfWeek,
    month,
    monthLabel,
    day,
    year,
    full: `${dayOfWeek}, ${day} de ${monthLabel.toLowerCase()} de ${year}`,
  }
}

export function getDateDiffLabel(
  from,
  to,
  includeStartDay = false,
  minCheck = 'seconds',
) {
  const dateFrom = getMoment(from)
  const dateTo = getMoment(to)
  const seconds = dateFrom < dateTo ? dateTo.diff(dateFrom, 'seconds') : 0
  const minutes = dateFrom < dateTo ? dateTo.diff(dateFrom, 'minutes') : 0
  const hours = dateFrom < dateTo ? dateTo.diff(dateFrom, 'hours') : 0
  const days = dateFrom < dateTo ? dateTo.diff(dateFrom, 'days') : 0
  const months = dateFrom < dateTo ? dateTo.diff(dateFrom, 'months') : 0
  const years = dateFrom < dateTo ? dateTo.diff(dateFrom, 'years') : 0

  if (years > 0) {
    return `${years} ano${years !== 1 ? 's' : ''}`
  }

  if (months > 0) {
    return `${months} ${months !== 1 ? 'meses' : 'mês'}`
  }

  if (includeStartDay && days > 0) {
    return `${days + 1} dia${days + 1 !== 1 ? 's' : ''}`
  }

  if (days > 0) {
    return `${days} dia${days !== 1 ? 's' : ''}`
  }

  if (minCheck === 'days') {
    return 'hoje'
  }

  if (hours > 0) {
    return `${hours} hora${hours !== 1 ? 's' : ''}`
  }

  if (minutes > 0) {
    return `${minutes} minuto${hours !== 1 ? 's' : ''}`
  }

  if (seconds > 0) {
    return `${seconds} segundo${hours !== 1 ? 's' : ''}`
  }

  return ``
}

export function isPastDate(date) {
  return getMoment(date) < getMoment()
}

export function getStartEndThisMonth() {
  return {
    start: getMomentUtc().startOf('month'),
    end: getMomentUtc().endOf('month'),
  }
}

export function getStartEndLast6Month() {
  const start = moment().add(-5, 'months').startOf('month')

  const end = getMomentUtc().endOf('month')

  return {
    start,
    end,
  }
}

export function isValidDate(date) {
  if (!date || getMoment(date) < getMoment('1900-01-01')) {
    return false
  }

  return true
}
