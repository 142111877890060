import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'

export default function AddParticipantForm({
  modalData,
  onClose,
  pageFilters,
}) {
  const { axiosApis } = GetAxios()
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  const [fileDocument1, setFileDocument1] = useState(undefined)
  const [fileDocument2, setFileDocument2] = useState(undefined)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const res = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/participants-to-add`,
          {
            params: {
              eventId: pageFilters.event ? pageFilters.event.value : undefined,
            },
          },
        )

        setOptions(res.data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = false

      setOptions([])
    }
  }, [])

  async function onValidate(data) {
    if (data && data.participant) {
      return true
    }

    toast.info('Selecione uma inscrição')

    return false
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('id', data.participant.value)
      formData.append('stage', modalData.stage.key)
      formData.append('desc', data.desc)
      formData.append('file1', fileDocument1)
      formData.append('file2', fileDocument2)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban`,
        formData,
      )

      await onClose()

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function onChangeParticipant(e) {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/${e.target.value.value}`,
      )

      setDefaultData({
        ...defaultData,
        participant: e.target.value,
        desc: `${res.data.desc}`,
      })

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  return (
    <Container>
      <DefaultForm
        defaultValue={defaultData}
        onValidate={onValidate}
        callbackSubmit={onSubmit}
        disabled={loading}
        inputs={[
          {
            type: 'select',
            name: 'participant',
            label: 'Posição/Nome completo do inscrito',
            placeholder: 'Selecione...',
            options: options,
            onChange: onChangeParticipant,
            required: true,
            sm: 12,
          },
          {
            type: 'file',
            name: 'file1',
            label: 'Anexo 1',
            onChange: e => setFileDocument1(e.target.files[0]),
            sm: 12,
            md: 6,
          },
          {
            type: 'file',
            name: 'file2',
            label: 'Anexo 2',
            onChange: e => setFileDocument2(e.target.files[0]),
            sm: 12,
            md: 6,
          },
          {
            type: 'textarea',
            name: 'desc',
            label: 'Observações',
            placeholder: 'Digite aqui...',
            sm: 12,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100;
`
