import { useEffect, useState } from 'react'
import { rules } from '~/constants/holdingRules'

export default function useHoldingMenus() {
  const [menus, setMenus] = useState([])

  useEffect(() => {
    async function updateMenus() {
      let newMenus = []

      newMenus.push(rules.home.menu)
      newMenus.push(rules.customers.menu)
      newMenus.push(rules.sales.menu)
      newMenus.push(rules.services.menu)
      newMenus.push(rules.proposals.menu)

      setMenus(newMenus)
    }

    updateMenus()
  }, [])

  return { menus }
}
