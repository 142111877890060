import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function RelationshipContactRegister() {
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()
  const location = useLocation()
  const [contactFormSelect, setContactFormSelect] = useState([])
  const [statusLead, setStatusLead] = useState([])

  async function onSubmit(data) {
    try {
      const formData = new FormData()
      const customerId = location.state.customers.Id

      formData.append('contactDate', data.contactDate)
      formData.append('contactForm', data.contactForm.label)
      formData.append('statusLead', data.statusLead.label)
      if (data.nextAction) {
        formData.append('nextAction', data.nextAction)
      }
      formData.append('customerId', location.state.customers.id)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/insertContact/`,
        formData,
      )

      toast.success('Contato registrado com sucesso!')

      return navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  return (
    <Container>
      <PageTitle title={location.state.customers.fullName} />

      <Panel>
        <PanelHeader title={'Registrar Contato'} noButton />

        <PanelBody>
          <DefaultForm
            callbackCancel={() => navigate(-1)}
            callbackSubmit={onSubmit}
            inputs={[
              {
                type: 'date',
                name: 'contactDate',
                label: 'Data do Contato',
                required: true,
              },
              {
                type: 'select',
                name: 'contactForm',
                label: 'Forma de Contato',
                options: [
                  {
                    label: 'E-mail',
                    value: 'email',
                  },
                  {
                    label: 'Telefone',
                    value: 'telefone',
                  },
                  {
                    label: 'SMS',
                    value: 'sms',
                  },
                  {
                    label: 'Whatsapp',
                    value: 'whatsapp',
                  },
                  {
                    label: 'Linkedin',
                    value: 'linkedin',
                  },
                  {
                    label: 'Instagram',
                    value: 'instagram',
                  },
                  {
                    label: 'Facebook',
                    value: 'facebook',
                  },
                  {
                    label: 'Reunião Presencial',
                    value: 'reuniao_presencial',
                  },
                ],
                onChange: e => setContactFormSelect(e.target.value),
                placeholder: 'Selecione...',
                required: true,
              },
              {
                type: 'select',
                name: 'statusLead',
                label: 'Status do Lead',
                options: [
                  {
                    label: 'Novo',
                    value: 'novo',
                  },
                  {
                    label: 'Qualificado',
                    value: 'qualificado',
                  },
                  {
                    label: 'Proposta Enviada',
                    value: 'porposta_enviada',
                  },
                  {
                    label: 'Em Negociação',
                    value: 'em_negociação',
                  },
                  {
                    label: 'Venda Realizada',
                    value: 'venda_realizada',
                  },
                  {
                    label: 'Venda Perdida',
                    value: 'venda_perdida',
                  },
                ],
                onChange: e => setStatusLead(e.target.value),
                placeholder: 'Selecione...',
                required: true,
              },
              {
                type: 'text',
                name: 'nextAction',
                label: 'Próxima Ação',
                placeholder: 'Próxima Ação',
                required: true,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
