import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function TagsAgencyCreate() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [options, setOptions] = useState([])
  const [fields, setFields] = useState([])
  const [loading, setLoading] = useState(false)

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('tag', data.tag)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/tags`,
        formData,
      )

      setLoading(false)

      toast.success('Tag cadastrada com sucesso.')

      navigate('/tagsagency')

      return
    } catch (error) {
      console.log(error)

      toast.error(error?.response?.data)

      return setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle title='Cadastrar nova tag' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <DefaultForm
            loading={loading}
            disabled={loading}
            callbackSubmit={onSubmit}
            callbackReset={() => {
              return
            }}
            inputs={[
              {
                type: 'text',
                name: 'tag',
                label: 'Tag',
                placeholder: 'Tag',
                required: true,
                maxLength: 15,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

const Container = styled.div``
