import DefaultForm from '~/components/DefaultForm'
import {
  Body,
  Container,
  CoverHeader,
  Div,
  Footer,
  Header,
  SubTitle,
  Title,
  ImageButton,
  SubTitleM,
  WppButton,
} from './style'
import { GetAxios } from '~/services/api'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { phoneNumber } from '~/utils/supportPhone'

export default function FreeTrial() {
  const { axiosApis } = GetAxios()
  // const [loading, setLoading] = useState()
  const [step, setStep] = useState(0)
  const [agencyName, setAgencyName] = useState()
  const [instagram, setInstagram] = useState()
  const [name, setName] = useState()
  const [cpf, setCpf] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [whatSearching, setWhatsSearching] = useState({})

  const requestApiPostToCreate = async data => {
    try {
      // setLoading(true)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/64b58220-69f3-11ee-8c99-0242ac120002`,
        data,
      )

      // toast.success(response.data)
      setStep(1)
      // setLoading(true)
    } catch (err) {
      // setLoading(false)
      toast.info(err.response.data)
      console.log(err)
    }
  }

  const requestApiPostToValidate = async data => {
    try {
      // setLoading(true)

      const body = {
        token: data.token,
        agencyName: agencyName,
        instagram: instagram,
        name: name,
        cpf: cpf,
        email: email,
        phone: phone,
        whatSearching: whatSearching,
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/64b58220-69f3-11ee-8c99-0242ac120002/e292d401-7e9e-49c7-8323-42cfa358dbaf`,
        body,
      )

      // toast.success(response.data)
      setStep(2)
      // setLoading(true)
    } catch (err) {
      // setLoading(false)
      toast.info(err.response.data)
      console.log(err)
    }
  }

  return (
    <Div>
      <div>
        <CoverHeader>
          <Header>
            <ImageButton
              onClick={() => window.open('https://www.tripmee.com.br/')}
            >
              <img
                src={'/images/logotripmee_parafundobranco.png'}
                alt='logo'
                style={{ maxWidth: '150px' }}
              />
            </ImageButton>

            <div>
              <button
                type='button'
                onClick={() => window.open('https://agente.tripmee.com.br/')}
              >
                Login
              </button>
              <button
                type='button'
                onClick={() =>
                  window.open('https://agente.tripmee.com.br/support')
                }
              >
                Ajuda
              </button>
            </div>
          </Header>
        </CoverHeader>

        <Container>
          {step == 0 && (
            <>
              <p style={{ marginTop: '25px' }}>
                <Title>
                  Uma plataforma para descomplicar o dia a dia do consultor de
                  Viagens
                </Title>
                <br />
                <SubTitle>
                  Preencha seus dados e tenha 10 dias de acesso
                </SubTitle>
              </p>

              <Body>
                <DefaultForm
                  callbackSubmit={requestApiPostToCreate}
                  callbackCancel={() => {}}
                  submitLabel='Solicitar acesso'
                  cancelLabel='Limpar'
                  backgroundColor='#D54A26'
                  color='#fff'
                  inputs={[
                    {
                      type: 'text',
                      name: 'agencyName',
                      label: 'Nome da agencia',
                      onChange: e => setAgencyName(e.target.value),
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'instagram',
                      label: 'Instagram',
                      onChange: e => setInstagram(e.target.value),
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'name',
                      label: 'Nome completo',
                      required: true,
                      onChange: e => setName(e.target.value),
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'cpf',
                      label: 'CPF/CNPJ',
                      onChange: e => setCpf(e.target.value),
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'phone',
                      label: 'WhatsApp (não mandamos span)',
                      mask: '(99) 99999-9999',
                      required: true,
                      onChange: e => setPhone(e.target.value),
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'email',
                      name: 'email',
                      label: 'Seu melhor email',
                      required: true,
                      onChange: e => setEmail(e.target.value),
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'select',
                      name: 'whatSearching',
                      label: 'O que você busca ao participar do TripMee',
                      required: true,
                      placeholder: 'Selecione...',
                      options: [
                        {
                          label: 'Organizar o processo da minha agência.',
                          value: 'Organizar o processo da minha agência.',
                        },
                        {
                          label: 'Entender o que é a jornada do Cliente.',
                          value: 'Entender o que é a jornada do Cliente',
                        },
                        {
                          label: 'Controle de clientes qualificados.',
                          value: 'Controle de clientes qualificados',
                        },
                        {
                          label: 'Gerenciar minhas vendas.',
                          value: 'Gerenciar minhas vendas',
                        },
                        {
                          label: 'Gestão de pós venda.',
                          value: 'Gestão de pós venda',
                        },
                        {
                          label:
                            'Aumentar a possibilidade de recompra no próximo ano',
                          value:
                            'Aumentar a possibilidade de recompra no próximo ano',
                        },
                        { label: 'Outros', value: 'Outros' },
                      ],
                      onChange: e => setWhatsSearching(e.target.value),
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                  ]}
                />
              </Body>
            </>
          )}
        </Container>

        <Container>
          {step == 1 && (
            <>
              <p style={{ marginTop: '25px' }}>
                <Title>
                  Insira o código que enviamos via whatsapp para receber o seu
                  acesso!
                </Title>
              </p>

              <Body>
                <DefaultForm
                  callbackSubmit={requestApiPostToValidate}
                  // callbackCancel={() => {}}
                  submitLabel='Enviar'
                  cancelLabel='Limpar'
                  backgroundColor='#D54A26'
                  color='#fff'
                  inline={true}
                  inputs={[
                    {
                      type: 'text',
                      name: 'token',
                      label: 'Código',
                      required: true,
                      max: 6,
                      xs: 8,
                      sm: 8,
                      md: 8,
                      lg: 8,
                      xl: 4,
                    },
                  ]}
                />
              </Body>
            </>
          )}
        </Container>
      </div>

      {step == 2 && (
        <p
          style={{
            // textAlign: 'center',
            // border: '1px solid black',
            height: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title fontSize='45px'>Bem vindo a Tripmee!</Title>

          <span style={{ textAlign: 'center' }}>
            <SubTitleM>
              Em instantes você receberá seu acesso. <br />
              Qualquer dúvida entre em contato com os nossos consultores!
            </SubTitleM>
            <br />
            <br />
            <br />
            <br />
            <SubTitleM>
              Vamos juntos organizar os processos da sua agência <br /> e
              garantir que seu cliente tenha uma ótima jornada com você!
            </SubTitleM>
          </span>

          <WppButton
            onClick={() =>
              window.open(
                `https://api.whatsapp.com/send?phone=${phoneNumber('support')}`,
              )
            }
          >
            WhatsApp
            <i
              className='fab fa-whatsapp'
              style={{
                fontSize: '20px',
                paddingLeft: '10px',
                verticalAlign: 'middle',
              }}
            ></i>
          </WppButton>
        </p>
      )}

      <Footer>
        <div>
          <img
            src={'/images/logotripmee_parafundobranco.png'}
            alt='logo'
            style={{ maxWidth: '150px' }}
          />

          <div>
            <span>
              <b>Contato</b> <br />
              contato@tripmee.com.br
            </span>
          </div>
        </div>

        <button
          type='button'
          onClick={() =>
            window.open(
              'https://instagram.com/tripmeeapp?igshid=MmU2YjMzNjRlOQ==',
            )
          }
        >
          <i className='fab fa-instagram'></i>
        </button>
      </Footer>
    </Div>
  )
}
