import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import Tooltip from '~/components/Tooltip'

export default function IconButton({
  id,
  loading,
  type,
  onClick,
  tooltipOverride,
  iconOverride,
  colorOverride,
  style,
  tooltipEnable = true,
  applyColorTheme = false,
}) {
  let icon = 'fas fa-edit'
  let tooltip = tooltipOverride ?? 'Detalhes'
  let iconType = type ?? 'details'
  let color = '#333'

  switch (iconType) {
    case 'details':
      icon = iconOverride ?? 'fas fa-edit'
      tooltip = tooltipOverride ?? 'Detalhes'
      color = colorOverride ?? '#333'
      break
    case 'user':
      icon = iconOverride ?? 'far fa-user'
      tooltip = tooltipOverride ?? 'Usuário'
      color = colorOverride ?? '#333'
      break
    case 'file':
      icon = iconOverride ?? 'fas fa-file-alt'
      tooltip = tooltipOverride ?? 'Arquivo'
      color = colorOverride ?? '#333'
      break
    case 'share':
      icon = iconOverride ?? 'fas fa-share-square'
      tooltip = tooltipOverride ?? 'Ir para'
      color = colorOverride ?? '#333'
      break
    case 'trash':
      icon = iconOverride ?? 'far fa-trash-alt'
      tooltip = tooltipOverride ?? 'Remover'
      color = colorOverride ?? '#dc3545'
      break
    case 'create':
      icon = iconOverride ?? 'fas fa-plus'
      tooltip = tooltipOverride ?? 'Criar'
      color = colorOverride ?? '#198754'
      break
    case 'copy':
      icon = iconOverride ?? 'far fa-copy'
      tooltip = tooltipOverride ?? 'Copiar'
      color = colorOverride ?? '#333'
      break
    case 'link':
      icon = iconOverride ?? 'fas fa-link'
      tooltip = tooltipOverride ?? 'Link'
      color = colorOverride ?? '#333'
      break
    case 'close':
      icon = iconOverride ?? 'fas fa-times'
      tooltip = tooltipOverride ?? 'Fechar'
      color = colorOverride ?? '#dc3545'
      break
    case 'download':
      icon = iconOverride ?? 'fas fa-download'
      tooltip = tooltipOverride ?? 'Baixar'
      color = colorOverride ?? '#333'
      break
    case 'remove':
      icon = iconOverride ?? 'fas fa-trash-alt'
      tooltip = tooltipOverride ?? 'Remover'
      color = colorOverride ?? '#dc3545'
      break
    case 'noRemove':
      icon = iconOverride ?? 'fas fa-trash-alt'
      tooltip = tooltipOverride ?? 'Remover'
      color = colorOverride ?? '#333'
      break
    case 'mainTraveler':
      icon = iconOverride ?? 'fas fa-star'
      tooltip = tooltipOverride ?? 'Viajante principal'
      color = colorOverride ?? '#dc3'
      break
    case 'noMainTraveler':
      icon = iconOverride ?? 'far fa-star'
      tooltip = tooltipOverride ?? 'Tornar viajante principal'
      color = colorOverride ?? '#333'
      break
    case 'pauseCircle':
      icon = iconOverride ?? 'fas fa-pause'
      tooltip = tooltipOverride ?? 'Pausar'
      color = colorOverride ?? 'red'
      break
    case 'microphone':
      icon = iconOverride ?? 'fas fa-microphone'
      tooltip = tooltipOverride ?? 'Gravar Áudio'
      color = colorOverride ?? 'grey'
      break
    case 'paperPlane':
      icon = iconOverride ?? 'fas fa-paper-plane'
      tooltip = tooltipOverride ?? 'Enviar Áudio'
      color = colorOverride ?? 'grey'
      break
    case 'play':
      icon = iconOverride ?? 'fas fa-play'
      tooltip = tooltipOverride ?? 'Escutar Áudio'
      color = colorOverride ?? 'grey'
      break
    default:
      icon = iconOverride ?? 'fas fa-edit'
      tooltip = tooltipOverride ?? 'Detalhes'
      color = colorOverride ?? '#333'
      break
  }

  return (
    <>
      <Button
        id={'icon-button-' + iconType + '-' + id}
        type={'button'}
        onClick={onClick}
        color={color}
        style={style}
        applyColorTheme={applyColorTheme}
      >
        {loading && <Spinner size={'sm'} />}

        {!loading && <i className={icon} />}
      </Button>

      {tooltipEnable && (
        <Tooltip target={'icon-button-' + iconType + '-' + id} text={tooltip} />
      )}
    </>
  )
}

const Button = styled.button`
  color: ${props =>
    props.applyColorTheme ? props.theme.colors.primary : props.color};
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 6px;
  border-radius: 6px;
  text-align: center;
  vertical-align: middle;

  &:hover {
    background-color: #00000011;
  }
`

// $blue:    #0d6efd;
// $indigo:  #6610f2;
// $purple:  #6f42c1;
// $pink:    #d63384;
// $red:     #dc3545;
// $orange:  #fd7e14;
// $yellow:  #ffc107;
// $green:   #198754;
// $teal:    #20c997;
// $cyan:    #0dcaf0;
