import { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { CancelButton, SaveButton } from '~/components/Button/styles'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'
import { DivFlexRight, SpaceBetweenDiv } from '~/styles/divs'

export default function AddCategoryModal({ callback, callbackCancel }) {
  const { axiosApis } = GetAxios()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  async function onSubmit() {
    try {
      setLoading(true)

      if (!data || !data.desc) {
        toast.info('Preencha o Nome da Categoria!')

        return setLoading(false)
      }

      const formData = new FormData()

      formData.append('desc', data.desc)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/createexperiencecategory`,
        formData,
      )

      toast.success('Categoria criada com sucesso!')

      setLoading(false)

      await callback(data.desc)
    } catch (error) {
      setLoading(false)

      console.log(error)

      toast.info(
        error && error.response && error.response.data
          ? error.response.data
          : 'Não foi possível criar a categoria.',
      )
    }
  }

  return (
    <Container>
      <SpaceBetweenDiv>
        <h6>Adicionar Nova categoria</h6>

        <CancelButton type={'button'} onClick={callbackCancel}>
          <i className='fas fa-times' style={{ padding: '4px' }} />
        </CancelButton>
      </SpaceBetweenDiv>

      <DefaultForm
        onChangeData={setData}
        inputs={[
          {
            type: 'text',
            name: 'desc',
            label: 'Nome da categoria',
            placeholder: 'Nome da categoria',
            sm: 12,
          },
        ]}
      />

      <DivFlexRight>
        <SaveButton type={'button'} onClick={onSubmit} disabled={loading}>
          Salvar
        </SaveButton>
      </DivFlexRight>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  width: 300px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 0 10px #777;
  padding: 8px;
  z-index: 999;
`
