import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import Divisor from '~/components/Divisor'
import PageTitle from '~/components/PageTitle'
import useSalesReleasesForm from '../../hooks/useSalesReleasesForm'
import {
  SalesReleaseFormComponent,
  SalesReleaseFormContainer,
  SalesReleaseFormValuesTable,
} from '../../styles'
import SalesReleasesFormInput from '../SalesReleasesFormInput'

export default function SalesReleasesHotelForm({
  onChange,
  defaultValue = [],
  internalOptionData = {},
}) {
  const { handleSubmit, getValues, register, reset, setValue, watch } =
    useSalesReleasesForm()
  const [list, setList] = useState(defaultValue ?? [])

  useEffect(() => {
    if (!list || list.length == 0) {
      setList([{}])
    }

    if (list[0]) {
      setValue('paymentType', list[0].paymentType)
    }

    return () => {
      setList([])
    }
  }, [])

  async function onSubmit(data) {
    const newList = [data]
    let formattedData = formatData(newList)

    setList(newList)

    onChange(newList, formattedData)
  }
  function formatData(data) {
    return data
      .map(
        item =>
          `${item.apts} APT ${item.categoryApts} ${item.typeApts}\nCHECK-IN: ${item.checkIn} -  CHECK-OUT: ${item.checkOut}`,
      )
      .join('\n')
  }

  return (
    <SalesReleaseFormContainer>
      <SalesReleaseFormComponent onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <Row>
            <SalesReleasesFormInput
              label='Nr. Apts.'
              name='apts'
              type='text'
              register={register}
              defaultValue={list[0]?.apts}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Categ. Apt.'
              name='categoryApts'
              type='text'
              register={register}
              defaultValue={list[0]?.categoryApts}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={3}
            />

            <SalesReleasesFormInput
              label='Tipo Apt.'
              name='typeApts'
              type='text'
              register={register}
              defaultValue={list[0]?.typeApts}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Check-in'
              name='checkIn'
              type='date'
              register={register}
              defaultValue={list[0]?.checkIn}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Check-out'
              name='checkOut'
              type='date'
              register={register}
              defaultValue={list[0]?.checkOut}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Nr. noites'
              name='nights'
              type='text'
              register={register}
              defaultValue={list[0]?.nights}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Nr. hóspedes'
              name='paxCount'
              type='text'
              register={register}
              defaultValue={list[0]?.paxCount}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />
          </Row>

          <PageTitle
            title='Valores por diária(ao cliente)'
            head='h6'
            noButton
          />

          <Row>
            <SalesReleasesFormInput
              label='Moeda'
              name='currency'
              type='text'
              register={register}
              defaultValue={list[0]?.currency ?? 'BRL'}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Vlr. diária'
              name='dailyValue'
              type='text'
              register={register}
              defaultValue={list[0]?.dailyValue}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Outras txs. 1'
              name='otherTaxs1'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs1}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Outras txs. 2'
              name='otherTaxs2'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs2}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Outras txs. 3'
              name='otherTaxs3'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs3}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Markup'
              name='markup'
              type='text'
              register={register}
              defaultValue={list[0]?.markup}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />
          </Row>

          <Row>
            <SalesReleasesFormInput
              label='Câmbio'
              name='exchange'
              type='text'
              register={register}
              defaultValue={list[0]?.exchange}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Vlr. diária R$'
              name='dailyValueBRL'
              type='text'
              register={register}
              defaultValue={list[0]?.dailyValueBRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Outras txs. 1 R$'
              name='otherTaxs1BRL'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs1BRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Outras txs. 2 R$'
              name='otherTaxs2BRL'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs2BRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Outras txs. 3 R$'
              name='otherTaxs3BRL'
              type='text'
              register={register}
              defaultValue={list[0]?.otherTaxs3BRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Markup R$'
              name='markupBRL'
              type='text'
              register={register}
              defaultValue={list[0]?.markupBRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Total R$'
              name='totalBRL'
              type='text'
              register={register}
              defaultValue={list[0]?.totalBRL}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />
          </Row>

          <Row>
            <SalesReleasesFormInput
              label='Reg Alimentação'
              name='feedRegister'
              type='text'
              register={register}
              defaultValue={list[0]?.feedRegister}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />
            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'paymentType'}
              label={'Tipo de Pagamento'}
              defaultValue={list[0]?.paymentType}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
              selectOptions={internalOptionData?.paymentTypes ?? []}
            />

            <SalesReleasesFormInput
              label='Localizador/Cód. Confirm.'
              name='trackerConfCode'
              type='text'
              register={register}
              defaultValue={list[0]?.trackerConfCode}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Dt. Confirmação'
              name='confirmationDate'
              type={'date'}
              register={register}
              defaultValue={list[0]?.confirmationDate}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Confirmado Por'
              name='confirmedBy'
              type='text'
              register={register}
              defaultValue={list[0]?.confirmedBy}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12} className='submit-area'>
              <Button
                size={'sm'}
                type='button'
                color='none'
                onClick={() => reset()}
              >
                Limpar
              </Button>

              <Button size={'sm'} type='submit' color='primary'>
                Adicionar
              </Button>
            </Col>
          </Row>
        </fieldset>
      </SalesReleaseFormComponent>
    </SalesReleaseFormContainer>
  )
}
