import styled from 'styled-components'

import Footer from './Footer'
import Header from './Header'
import Main from './Main/index'

export default function Dashboard({
  travel,
  onHandlePrint,
  travelImage,
  partnerImage,
  agencyImage,
  token,
  showPrint,
  markers,
  center,
  routes,
  nameAgency,
  travelResume,
}) {
  const onHandleShowInApp = () => {
    return
  }

  return (
    <Container>
      <Header
        travel={travel}
        onHandlePrint={onHandlePrint}
        onHandleShowInApp={onHandleShowInApp}
        agencyImage={agencyImage}
        partnerImage={partnerImage}
        showPrint={showPrint}
        nameAgency={nameAgency}
      />

      <Main
        travel={travel}
        travelImage={travelImage}
        token={token}
        markers={markers}
        routes={routes}
        center={center}
        nameAgency={nameAgency}
        travelResume={travelResume}
      />

      <Footer />
    </Container>
  )
}

const Container = styled.div`
  background-color: #fff;
  width: 100%;
  min-width: 720px;
  min-height: 100vh;
  font-size: 16px;
  color: #333;
  word-wrap: break-word;
  text-align: justify;

  @media (max-width: 1600px) {
    font-size: 15px;
  }

  @media (max-width: 1080px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 13px;
  }

  * {
    box-sizing: border-box;
    font-family: ${props => props.theme.fontFamily};
  }

  .day-section {
    margin-bottom: 32px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  h1 {
    font-size: 2.2em;
    margin-bottom: 0.55em;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  h3 {
    font-size: 1.8em;
    margin-bottom: 0.45em;
  }

  h4 {
    font-size: 1.6em;
    margin-bottom: 0.4em;
  }

  h5 {
    font-size: 1.4em;
    margin-bottom: 0.35em;
  }

  h6 {
    font-size: 1.2em;
    margin-bottom: 0.3em;
  }

  p,
  span,
  b {
    font-size: 1em;
    margin-bottom: 0.25em;
    font-weight: 500;
  }

  b {
    font-weight: 700;
  }
`
