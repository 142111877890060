import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { LinkEdit, LinkRegisterNewTraveler } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import ReactTable from '~/components/Table'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function AgencyList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [agencysOriginal, setAgencysOriginal] = useState([])
  const [country, setCountry] = useState([])
  const [getCities, setGetCities] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/city/countries`,
        )

        setCountry(data)
        setAgencysOriginal(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSearch(data) {
    await axiosApis
      .get(`${process.env.REACT_APP_ENDPOINT_APIS}/city/countries`, {
        params: { country: data.country.value },
      })
      .then(data => setGetCities(data.data))
  }

  async function onReset() {
    setLoading(true)

    setTimeout(() => {
      setCountry(agencysOriginal)

      setLoading(false)
    }, [500])
  }

  return (
    <Container>
      <h1 className='page-header'>Cidades</h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSearch}
            callbackReset={onReset}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                options: country,
                name: 'country',
                label: 'País',
                placeholder: 'País',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}>
          <LinkRegisterNewTraveler to='create'>
            Adicionar Cidade
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <ReactTable
            data={getCities}
            columns={[
              {
                Header: 'Cidade',
                accessor: 'name',
                width: 240,
              },
              {
                Header: 'Estado',
                accessor: 'adminName',
                width: 120,
              },
              {
                Header: 'País',
                accessor: 'country',
                width: 120,
              },
              {
                Header: 'Latitude',
                accessor: 'lat',
                width: 120,
              },
              {
                Header: 'Longitude',
                accessor: 'lng',
                width: 120,
              },
              {
                Header: 'ISO2',
                accessor: 'iso2',
                width: 120,
              },
              {
                Header: 'ISO3',
                accessor: 'iso3',
                width: 120,
              },
              {
                Header: 'População',
                accessor: 'population',
                width: 120,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <LinkEdit
                      to={'details'}
                      state={{
                        agency: props.row.original,
                      }}
                    >
                      Detalhes
                    </LinkEdit>
                  )
                },
                width: 80,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
