import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled, { keyframes } from 'styled-components'
import { GetAxios } from '~/services/api'
import LoadingContainer from '../LoadingContainer'

export default function DaybydaySuggestionsAI({
  travelId = 0,
  onClickItem,
  reset,
  image,
  citys,
}) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [loadingSelected, setLoadingSelected] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [suggestionsFake, setSuggestionsFake] = useState([])
  const [selected, setSelected] = useState()

  useEffect(() => {
    async function load() {
      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveldaybyday/activity/ai-suggestions`,
          { params: { travelId, citys } },
        )

        setSuggestions(data.suggestions)

        setLoading(false)
      } catch (error) {
        console.log(error)

        toast.info(
          'Ops! Não conseguimos encontrar sugestões para sua viagem. Verifique sua viagem tem destinos adicionados.',
        )

        setLoading(false)
      }
    }

    load()
  }, [])

  useEffect(() => {
    setSuggestionsFake([])

    function addSuggestionFake(e) {
      setSuggestionsFake(state => [...state, e])
    }

    if (loading) {
      for (let i = 0; i < 10; i++) {
        setTimeout(() => addSuggestionFake(), 1000 * i)
      }
    }
  }, [loading])

  useEffect(() => {
    setSelected(undefined)
  }, [reset])

  async function getDetails(suggestion) {
    try {
      if (suggestion === selected) {
        setSelected(undefined)

        return onClickItem({ clear: true })
      }

      setLoadingSelected(true)

      setSelected(suggestion)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldaybyday/activity/ai-suggestions/details`,
        { params: { suggestion, image } },
      )

      onClickItem(data)

      setLoadingSelected(false)
    } catch (error) {
      console.log(error)

      setLoadingSelected(false)
    }
  }

  return (
    <Container>
      <Title>
        Sugestões<SpanIA>IA</SpanIA>
      </Title>

      <Content>
        <List>
          {!loading &&
            suggestions.map((e, i) => (
              <Item
                key={i}
                onClick={loadingSelected ? undefined : () => getDetails(e)}
                selected={e === selected}
                loading={loadingSelected}
              >
                {selected !== e && e}

                {selected === e && (
                  <>
                    {!loadingSelected && e}

                    {loadingSelected && (
                      <LoadingContainer size={'sm'} color='#eee' />
                    )}
                  </>
                )}
              </Item>
            ))}

          {loading && (
            <>
              {suggestionsFake.map((e, i) => {
                return (
                  <Item key={i} style={{ minWidth: '120px' }}>
                    <LoadingContainer size={'sm'} color='#eee' />
                  </Item>
                )
              })}
            </>
          )}
        </List>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
`

const Content = styled.div`
  position: relative;
  width: 100%;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
  min-width: 50%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`

const slideIn = keyframes`
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const Item = styled.li`
  padding: 6px 12px;
  margin: 0;
  height: 52px;
  max-width: 200px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 0 8px #eaeaea;
  cursor: ${props => (props.loading ? 'default' : 'pointer')};
  display: flex;
  text-align: center;
  align-items: center;
  font-weight: 600;
  opacity: ${props => (props.loading ? '0.5' : '0')};
  transform: translateX(-20px);
  animation: ${slideIn} 0.25s ease forwards;
  color: ${props => (props.selected ? 'white' : '#333')};
  background-color: ${props =>
    props.selected ? props.theme.colors.primary : 'transparent'};
`

const Title = styled.h6`
  position: relative;
`

const SpanIA = styled.span`
  padding: 2px;
  margin: 0;
  position: absolute;
  right: -20px;
  top: -10px;
  border: 1px solid #555;
  box-shadow: 0 0 8px #eaeaea;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: 700;
`
