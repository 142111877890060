import { useState, useEffect } from 'react'
import PageTitle from '~/components/PageTitle'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import DefaultForm from '~/components/DefaultForm'

export default function TravelInsuranceDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [insurance, setInsurance] = useState()

  useEffect(() => {
    requestApiGet()
  }, [])

  async function requestApiGet() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelInsurance/${location.state?.id}`,
      )

      for (let key in data) {
        data[key] ?? delete data[key]
      }

      setInsurance(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      navigate(-1)
    }
  }

  async function onSubmit(data) {
    try {
      setLoading(true)
      const formData = new FormData()

      for (let key in data) {
        formData.append(key, data[key])
      }

      formData.append('insuranceId', location.state?.id)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelInsurance`,
        formData,
      )

      setLoading(false)
      navigate(-1)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle title={'Seguro'} head={'h5'} />

      <DefaultForm
        callbackSubmit={onSubmit}
        callbackCancel={() => {}}
        loading={loading}
        defaultValue={insurance}
        inputs={[
          {
            type: 'date',
            name: 'timeStart',
            label: 'Início',
            required: true,
            sm: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'date',
            name: 'timeEnd',
            label: 'Fim',
            required: true,
            sm: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'text',
            name: 'typeCoverage',
            label: 'Tipo de cobertura',
            placeholder: 'Tipo de cobertura',
            required: true,
            sm: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'text',
            name: 'numberPolicy',
            label: 'Número da apólice',
            sm: 6,
            lg: 6,
            xl: 6,
          },
          {
            type: 'text',
            name: 'nameOfTheInsured',
            label: 'Nome do(a) assegurado(a)',
            sm: 6,
            lg: 6,
            xl: 6,
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Descrição da cobertura do seguro',
            sm: 12,
            lg: 12,
            xl: 12,
          },
        ]}
      />
    </Container>
  )
}
