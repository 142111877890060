import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'
import '../../style.scss'
import { useParams } from 'react-router-dom'
import {
  Body,
  CoverHeader,
  Div,
  Footer,
  Header,
  Title,
  ImageButton,
  Container,
} from './style'

export default function SurForm() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [eventSource, setEventSource] = useState()
  const [value, setValue] = useState({
    address: '',
    phone: '',
    fullName: '',
    email: '',
    agentName: '',
  })

  const params = useParams()

  document.title = 'Pesquisa de satisfação'

  useEffect(() => {
    getSource()
  }, [])

  async function getSource() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/token/${params.token}`,
      )

      setEventSource(data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const body = {
        token: params.token,
        destinyrate: data.destinyrate,
        hotelrate: data.hotelrate,
        transferrate: data.transferrate,
        tipsrate: data.tipsrate,
        travelcomment: data.travel_comment,
        agencyrate: data.agencyrate,
        agentrate: data.agentrate,
        feedback: data.feedback,
        travelId: eventSource?.travelId,
      }

      if (window.confirm('Você deseja enviar sua Pesquisa?')) {
        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/relationship`,
          body,
        )

        toast.success('Pesquisa respondida com sucesso!')

        // setSended(true)
      }

      setLoading(false)
      // window.location.reload()
      setValue({
        address: '',
        phone: '',
        nome: '',
        email: '',
        agentName: '',
      })
    } catch (error) {
      toast.info(
        'Não foi possível enviar sua resposta. Tente Novamente em instantes.',
      )

      console.log(error)

      setLoading(false)
    }
  }

  return (
    <Div>
      <div>
        <CoverHeader>
          <Header>
            <ImageButton>
              <img
                src={`data:image/*;base64,${eventSource?.image}`}
                style={{ maxWidth: '150px' }}
              />
            </ImageButton>
          </Header>
        </CoverHeader>

        <Container>
          {eventSource && (
            <>
              {eventSource?.image && (
                <>
                  <p style={{ marginTop: '25px' }}>
                    <Title>
                      {!eventSource?.textSurvey
                        ? `Qual sua avaliação, de 0 a 10 para os seguintes serviços:
                      (Somente numeros inteiros)`
                        : eventSource?.textSurvey}
                    </Title>
                  </p>

                  <div className='titleImageMobile'>
                    <div className='title'>
                      <h1 className='page-header'>Pesquisa de satisfação</h1>
                    </div>

                    <div>
                      <img src={`data:image/*;base64,${eventSource?.image}`} />
                    </div>
                  </div>
                </>
              )}

              {eventSource?.primaryColor && (
                <Body>
                  <div className='wrapper-survey'>
                    <DefaultForm
                      callbackSubmit={onSubmit}
                      defaultValue={value}
                      // onValidate={onValidate}
                      disabled={loading}
                      // callbackCancel={() => {
                      //   if (window.confirm('Você deseja cancelar a inscrição?')) {
                      //     setCancel(true)
                      //   }
                      // }}
                      // callbackReset={onReset}
                      submitLabel={'Enviar'}
                      fontSize='16px'
                      backgroundColor={`${eventSource?.primaryColor}`}
                      color='#fff'
                      borderRadius='10px'
                      inputs={[
                        {
                          empty: true,
                          text: '<b>Dados do(a) Viajante</b>',
                          sm: 12,
                        },
                        {
                          type: 'number',
                          name: 'destinyrate',
                          label:
                            'Sobre o(s) destino(s), o que achou em relação à estrutura, atrativos e belezas naturais?',
                          min: 0,
                          max: 10,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          xl: 4,
                        },
                        {
                          type: 'number',
                          name: 'hotelrate',
                          label:
                            'Sobre o(s) hotel(s), o que achou conforto, atendimento e estrutura?',
                          min: 0,
                          max: 10,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          xl: 4,
                        },
                        {
                          type: 'number',
                          name: 'transferrate',
                          label: 'Passeios, serviço, atração, guiamento.',
                          min: 0,
                          max: 10,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          xl: 4,
                        },
                        {
                          type: 'number',
                          name: 'tipsrate',
                          label:
                            'Guias, cordialidades, conhecimento e comunicação.',
                          min: 0,
                          max: 10,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          xl: 4,
                        },
                        {
                          type: 'number',
                          name: 'agencyrate',
                          label:
                            'De 0 à 10, você recomendaria a agência que você viajou para seus amigos e familiares?',
                          min: 0,
                          max: 10,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          xl: 4,
                        },
                        {
                          type: 'number',
                          name: 'agentrate',
                          label:
                            'De 0 à 10, como foi o atendimento do seu consultor de viagens?',
                          min: 0,
                          max: 10,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          xl: 4,
                        },
                        {
                          type: 'textarea',
                          name: 'travel_comment',
                          label: 'Qual foi o ponto alto da viagem?',
                          maxLength: 150,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          xl: 4,
                        },
                        {
                          type: 'textarea',
                          name: 'feedback',
                          label: 'Feedback',
                          maxLength: 150,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          xl: 4,
                        },
                      ]}
                    />
                  </div>
                </Body>
              )}
            </>
          )}
        </Container>
      </div>

      <Footer>
        <div>
          {eventSource && (
            <>
              <img
                src={`data:image/*;base64,${eventSource?.image}`}
                style={{ maxWidth: '150px' }}
              />
              <div>
                <span>
                  <b>Contato</b> <br />
                  {eventSource?.agencyContact}
                </span>
              </div>
            </>
          )}
        </div>
      </Footer>
    </Div>
  )
}
