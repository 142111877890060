import { useEffect, useState } from 'react'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import Table from '~/components/Table'
import { getDateFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'

export default function SalesBackofficeHotelList({ order }) {
  const { axiosApis } = GetAxios()
  const [hotels, setHotels] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/salesbackoffice/hotels/${order}`,
        )

        setHotels(response.data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '8px',
      }}
    >
      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Table
            data={hotels}
            loading={loading}
            columns={[
              {
                Header: 'Qtde Apto',
                accessor: 'nrApts',
                width: 120,
              },
              {
                Header: 'Categ Apto',
                accessor: 'categApt',
                width: 120,
              },
              {
                Header: 'Tipo Apto',
                accessor: 'tipoApt',
                width: 120,
              },
              {
                Header: 'Hóspedes',
                accessor: 'nrHospedes',
                width: 150,
              },
              {
                Header: 'Check-In',
                accessor: 'dtCheckIn',
                width: 120,
                Cell: props => {
                  return (
                    <>
                      <span>
                        {getDateFormated(
                          props.row.original.dtCheckIn,
                          'DD/MM/YYYY',
                        )}
                      </span>

                      <br />

                      <span>
                        {getDateFormated(props.row.original.dtCheckIn, 'HH:mm')}
                      </span>
                    </>
                  )
                },
              },
              {
                Header: 'Check-Out',
                accessor: 'dtCheckOut',
                width: 120,
                Cell: props => {
                  return (
                    <>
                      <span>
                        {getDateFormated(
                          props.row.original.dtCheckOut,
                          'DD/MM/YYYY',
                        )}
                      </span>

                      <br />

                      <span>
                        {getDateFormated(
                          props.row.original.dtCheckOut,
                          'HH:mm',
                        )}
                      </span>
                    </>
                  )
                },
              },
              {
                Header: 'Confirmação',
                accessor: 'nrConfirmacao',
                width: 120,
              },
              {
                Header: 'Data Confirmação',
                accessor: 'dtConfirmacao',
                width: 120,
                Cell: props => {
                  return (
                    <>
                      <span>
                        {getDateFormated(
                          props.row.original.dtCheckOut,
                          'DD/MM/YYYY',
                        )}
                      </span>

                      <br />

                      <span>
                        {getDateFormated(
                          props.row.original.dtCheckOut,
                          'HH:mm',
                        )}
                      </span>
                    </>
                  )
                },
              },
              {
                Header: 'Confirmado Por',
                accessor: 'confirmadoPor',
                width: 150,
              },
              {
                Header: 'Regime Alimentação',
                accessor: 'regAlimentacao',
                width: 150,
              },
              {
                Header: 'Tipo de Pagamento',
                accessor: 'codTipoPagto',
                width: 150,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}
