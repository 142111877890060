import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { apiRequest } from '~/apiService'
// import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
import context from '~/context/createContext'
// import useAxios from '~/hooks/useAxios'

export default function useHoldingBackofficeHomeData({
  filters,
  showSalesStats,
}) {
  // const { apiClient } = useAxios()
  const [loading, setLoading] = useState(false)
  // const dashboardsPlaceholders = getDashboardPlaceholders(
  //   showSalesStats ? 3 : 2,
  // )
  const [dashboards, setDashboards] = useState([])
  const [ammountLast6Months, setAmmountLast6Months] = useState(undefined)
  const [newLeadsLast6Months, setNewLeadsLast6Months] = useState(undefined)
  const [newProposalsLast6Months, setNewProposalsLast6Months] =
    useState(undefined)
  const { handleProfile } = useContext(context)

  const params = {
    branchs: filters?.branchs ? filters.branchs.map(x => x.value) : [],
    start: filters?.start ?? undefined,
    end: filters?.end ?? undefined,
    requestFromAgency: handleProfile,
  }

  useEffect(() => {
    getData()
  }, [filters])

  const getData = async () => {
    try {
      setLoading(true)

      const [
        dashboards,
        newLeadsLast6Months,
        newProposalsLast6Months,
        ammountLast6Months,
      ] = await Promise.allSettled([
        apiRequest('get', 'holdingbackoffice/home/dashboards', params),
        apiRequest(
          'get',
          'holdingbackoffice/home/charts/new-leads-last-6-months',
          params,
        ),
        apiRequest(
          'get',
          'holdingbackoffice/home/charts/new-proposals-last-6-months',
          params,
        ),
        apiRequest(
          'get',
          'holdingbackoffice/home/charts/ammount-last-6-months',
          params,
        ),
      ])

      const dashboardsFiltered = showSalesStats
        ? dashboards?.value?.data
        : dashboards?.value?.data?.filter(x => x?.label != 'Vendas')

      setDashboards(dashboardsFiltered)
      setNewLeadsLast6Months(newLeadsLast6Months.value.data)
      setNewProposalsLast6Months(newProposalsLast6Months.value.data)
      setAmmountLast6Months(ammountLast6Months.value.data)
    } catch (error) {
      toast.info('Dados não encontrados. Tente mais tarde!')
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    dashboards,
    ammountLast6Months,
    newLeadsLast6Months,
    newProposalsLast6Months,
  }
}
