import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import Select from '../Select'

export default function CitiesSelect({
  name,
  label,
  placeholder,
  onChange,
  defaultValue,
  disabled,
  isClearable,
  isMulti,
}) {
  const { axiosApis } = GetAxios()
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  let searchTimer

  useEffect(() => {
    async function getSource() {
      try {
        if (typeof defaultValue === 'string') {
          setValue(undefined)

          await searchCity(defaultValue)
        } else {
          setValue(defaultValue ?? null)
        }
      } catch (error) {
        console.log(error)
      }
    }

    getSource()

    return () => {
      setOptions([])
      setValue(undefined)
    }
  }, [defaultValue])

  useEffect(() => {
    if (options.length === 1) {
      const newOption = options[0]

      if (isMulti) {
        const newValueWrapper = value ?? []

        const valuesFiltered = newValueWrapper.filter(
          x => x.value !== newOption.value,
        )

        const newValue = [...valuesFiltered, newOption]

        setValue(newValue)

        onChange({
          target: {
            name: name,
            value: newValue,
          },
        })
      } else {
        setValue(newOption)

        onChange({
          target: {
            name: name,
            value: newOption,
          },
        })
      }
    }
  }, [options])

  async function searchOptions(e) {
    await searchCity(e)
  }

  async function searchCity(e) {
    try {
      setIsLoading(true)

      var { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/city/filter?label=${e}`,
      )

      setOptions(data)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const handleSearch = e => {
    if (e.length > 2) {
      clearTimeout(searchTimer)

      searchTimer = setTimeout(() => {
        searchOptions(e)
      }, 1000)
    } else {
      clearTimeout(searchTimer)
    }
  }

  const handleChange = e => {
    setValue(e)
    onChange({
      target: {
        name: name,
        value: e,
      },
    })
  }

  return (
    <div>
      <Select
        name={name}
        label={label}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={e => handleChange(e.target.value)}
        onInputChange={e => handleSearch(e)}
        isSearchable
        isClearable={isClearable}
        isLoading={isLoading}
        disabled={disabled}
        isMulti={isMulti}
        formatOptionLabel={e => {
          return <div>{e.label}</div>
        }}
      />
    </div>
  )
}
