import { useCallback } from 'react'

export default function UseNamedMessage() {
  const setUserMessageTextWithUserName = useCallback((text, inline = false) => {
    let userName = ''
    const matchedUserName = text?.match(/\*(.*?)\*/m)

    if (matchedUserName?.length > 0) {
      userName = matchedUserName[1]
    }

    const newText = text?.replace(/^[\s\S]*?\n/, '') ?? ''

    return (
      <>
        {userName && (
          <span>
            <b>{userName}:&nbsp;</b>

            {!inline && <br></br>}

            {newText}
          </span>
        )}

        {!userName && <>{text}</>}
      </>
    )
  })

  return {
    setUserMessageTextWithUserName,
  }
}
