import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SidebarNavSubItem from './NavSubItem'
import {
  NavUlSubItems,
  NavLiItem,
  NavLiItemLink,
  NavUlSubItemsPopover,
  SubmenuPopover,
  SubmenuPopoverBody,
} from '../styles'

function SidebarNavItem({
  data,
  location,
  pageSettings,
  keyItem,
  showPopover,
  setShowPopover,
}) {
  const [itemState, setItemState] = useState({
    hasSub: false,
    active: false,
    clicked: false,
    popover: false,
  })
  const [expanded, setExpanded] = useState(false)

  let showPopoverTimeout

  useEffect(() => {
    async function loadState() {
      const childrens =
        data.childs.filter(x => location.pathname.includes(x)).length > 0

      return setItemState({
        ...itemState,
        hasSub: data.menuSubMenu && data.menuSubMenu.length > 0,
        active:
          (location && location.pathname.includes(data.path)) || childrens,
      })
    }

    loadState()
  }, [data, location])

  const togglePopover = () => {
    setShowPopover(keyItem)

    setItemState({
      ...itemState,
      popover: !itemState.popover,
    })
  }

  async function hoverInItem() {
    if (showPopover === keyItem) {
      setItemState(state => ({
        ...state,
        popover: true,
      }))

      clearTimeout(showPopoverTimeout)
    }
  }

  async function hoverOutItem() {
    if (showPopover === keyItem) {
      clearTimeout(showPopoverTimeout)

      showPopoverTimeout = setTimeout(() => {
        setItemState(state => ({
          ...state,
          popover: false,
        }))
      }, 1000)
    }
  }

  return (
    <li
      className={`
        ${itemState.active && 'active '}
        ${itemState.hasSub && 'hasSub '}
      `}
      onMouseOver={hoverInItem}
      onMouseOut={hoverOutItem}
    >
      {data.exact && (
        <NavLiItemLink
          minify={pageSettings.pageSidebarMinify ? true : undefined}
          to={data.path}
        >
          <i
            style={{ fontSize: 18, textAlign: 'center', marginRight: 4 }}
            className={data.icon}
          />

          {!pageSettings.pageSidebarMinify && <span>{data.title}</span>}
        </NavLiItemLink>
      )}

      {!data.exact && (
        <NavLiItem
          id={`sidebarItem${keyItem}`}
          minify={pageSettings.pageSidebarMinify ? true : undefined}
          onClick={() => setExpanded(!expanded)}
        >
          <div>
            <i
              style={{ fontSize: 18, textAlign: 'center', marginRight: 4 }}
              className={data.icon}
            />

            {!pageSettings.pageSidebarMinify && <span>{data.title}</span>}
          </div>

          {itemState.hasSub &&
            !pageSettings.pageSidebarMinify &&
            ((!expanded && (
              <i style={{ width: '8px' }} className='fas fa-caret-down' />
            )) ||
              (expanded && (
                <i style={{ width: '8px' }} className='fas fa-caret-up' />
              )))}

          {itemState.hasSub && pageSettings.pageSidebarMinify && (
            <i
              className='fas fa-caret-right'
              style={{
                position: 'absolute',
                fontSize: '12px',
                right: '0',
              }}
            />
          )}

          {itemState.hasSub && pageSettings.pageSidebarMinify && (
            <SubmenuPopover
              placement='right'
              isOpen={itemState.popover && keyItem === showPopover}
              target={`sidebarItem${keyItem}`}
              toggle={togglePopover}
            >
              <SubmenuPopoverBody>
                <NavUlSubItemsPopover>
                  {data.menuSubMenu.map(({ subMenu }, i) => {
                    return (
                      <SidebarNavSubItem
                        key={i}
                        data={subMenu}
                        location={location}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        pageSettings={pageSettings}
                        keyItem={i}
                      />
                    )
                  })}
                </NavUlSubItemsPopover>
              </SubmenuPopoverBody>
            </SubmenuPopover>
          )}
        </NavLiItem>
      )}

      {itemState.hasSub && !pageSettings.pageSidebarMinify && (
        <NavUlSubItems
          className={`
            ${expanded ? 'd-block ' : 'd-none '}
          `}
        >
          {data.menuSubMenu.map(({ subMenu }, i) => {
            return (
              <SidebarNavSubItem
                data={subMenu}
                location={location}
                expanded={expanded}
                setExpanded={setExpanded}
                pageSettings={pageSettings}
                key={i}
              />
            )
          })}
        </NavUlSubItems>
      )}
    </li>
  )
}

SidebarNavItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    childrens: PropTypes.array,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

export default SidebarNavItem
