import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container, Div, LinkPrint } from './styles'
import LoadingContainer from '~/components/LoadingContainer'
import {
  getParticipantInputs,
  getResponsibleInputs,
  getEventInputs,
  getInstitutionInputs,
  getSchoolInputs,
} from './constants'
import SendToKanbanButton from '../SendToKanbanButton'
// import { JsonToExcel } from 'react-json-to-excel'

export default function SchoolDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const [participant, setParticipant] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/event/manage/participants/details/${location.state.id}`,
        )

        setParticipant(response.data)

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <Container>
      <PageTitle
        title={`${
          participant
            ? 'Participante - ' + participant.fullName
            : 'Participante'
        }`}
      />

      <Panel>
        <PanelHeader noButton>
          {/* <JsonToExcel
            title='Download as Excel'
            data={[student]}
            fileName='sample-file'
            btnClassName='custom-classname'
          /> */}
        </PanelHeader>

        <PanelBody>
          {participant && !loading && (
            <Container>
              <Div>
                <PageTitle title='Dados da viagem' head='h5' noButton />

                <div style={{ display: 'flex' }}>
                  {participant && !loading && (
                    <SendToKanbanButton data={participant} />
                  )}
                  <LinkPrint
                    className='link'
                    to='/print-student-details'
                    state={{ participant: participant }}
                  >
                    Preparar Impressão
                  </LinkPrint>
                </div>
              </Div>

              <DefaultForm
                defaultValue={participant.event}
                disabled={loading}
                inputs={getEventInputs()}
              />

              <PageTitle title='Dados do participante' head='h5' noButton />

              <DefaultForm
                defaultValue={participant}
                disabled={loading}
                inputs={getParticipantInputs()}
              />

              <PageTitle
                title='Dados do Responsável financeiro'
                head='h5'
                noButton
              />

              <DefaultForm
                defaultValue={participant.responsible}
                disabled={loading}
                inputs={getResponsibleInputs()}
              />

              {participant.institutions.map((e, i) => {
                return (
                  <Fragment key={i}>
                    <PageTitle title={e.name} head='h5' noButton />

                    <DefaultForm
                      defaultValue={e}
                      disabled={loading}
                      inputs={getInstitutionInputs()}
                    />
                  </Fragment>
                )
              })}
            </Container>
          )}

          {(!participant || loading) && <LoadingContainer />}
        </PanelBody>
      </Panel>
    </Container>
  )
}
