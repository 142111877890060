import { useEffect, useRef, useState } from 'react'
import * as S from '../styles'
import { useDispatch, useSelector } from 'react-redux'
import Contacts from '../Contacts'
import Chat from '../Chat'
import Information from '../Information'
import useChat from '../hooks/ChatHook'
import { clearAllMessages } from '~/store/actions/messageAction'
import { navigateHeaderNavItem } from '~/store/root/slices/page'
import InicialImage from '../InicialImage'
import { readConversation } from '~/store/actions/conversationAction'
import SkeletonHeader from '../Chat/Skeleton/SkeletonHeader'
import { MessagesContainer } from '../Chat/styles'
import SkeletonMessages from '../Chat/Skeleton/SkeletonMessages'
import SkeletonBottom from '../Chat/Skeleton/SkeletonBottom'
import InformationSkeleton from '../Information/Skeleton/SkeletonInformation'
import { setClientsData } from '~/store/actions/chatAction'
import { getClientsDataForContacts } from '~/services/chat'

export default function ChatWrapper() {
  const dispatch = useDispatch()
  const chat = useSelector(state => state.chatReducer.chat) || []
  const startedOnce = useSelector(state => state.chatReducer.startedOnce)
  const auth = useSelector(state => state.auth)
  const contacts = useSelector(state => state.contactReducer.contacts)
  const currentChat = useSelector(state => state.chatReducer.currentChat) || []
  const scroll = useRef()
  const [currentConversation, setCurrentConversation] = useState()
  const { getMessagesByCurrentConversation } = useChat()
  const [loading, setLoading] = useState(false)
  const clientsData = useSelector(state => state.chatReducer.clientsData)
  const conversations = useSelector(
    state => state.conversationReducer.conversations,
  )
  useEffect(() => {
    dispatch(navigateHeaderNavItem('chat'))
    clearAllMessages(dispatch)
    const loadClientData = async () => {
      const phoneList = conversations
        .filter(c => !c.phoneNumber.includes('group'))
        .map(c => c.phoneNumber)

      const response = await getClientsDataForContacts(phoneList)
      if (response?.data) setClientsData(response.data, dispatch)
    }

    loadClientData()
  }, [])

  async function onChangeCurrentConversation(currentConversation) {
    setLoading(true)
    setCurrentConversation(currentConversation)
    await getMessagesByCurrentConversation(currentConversation)
    readConversation(currentConversation.phoneNumber, dispatch)
    setLoading(false)
  }

  return (
    <S.Container>
      <S.ContactsSection>
        <Contacts
          chat={chat}
          auth={auth}
          currentChat={currentChat}
          clientsData={clientsData}
          conversations={conversations}
          currentConversation={currentConversation}
          onChangeCurrentConversation={onChangeCurrentConversation}
        ></Contacts>
      </S.ContactsSection>

      {!currentConversation && <InicialImage />}

      {currentConversation && (
        <>
          {!loading && (
            <>
              <S.SectionChat>
                <Chat
                  scroll={scroll}
                  auth={auth}
                  currentChat={currentChat}
                  chat={chat}
                  started={startedOnce}
                  contacts={contacts}
                  currentConversation={currentConversation}
                ></Chat>
              </S.SectionChat>

              <S.SectionInfo>
                {!currentConversation && <></>}

                {currentConversation && (
                  <Information
                    auth={auth}
                    contacts={contacts}
                    currentChat={currentChat}
                    clientsData={clientsData}
                    conversations={conversations}
                    currentConversation={currentConversation}
                  ></Information>
                )}
              </S.SectionInfo>
            </>
          )}

          {loading && (
            <>
              <S.SectionChat>
                <SkeletonHeader />

                <MessagesContainer>
                  <SkeletonMessages />
                </MessagesContainer>

                <SkeletonBottom />
              </S.SectionChat>

              <S.SectionInfo>
                <InformationSkeleton />
              </S.SectionInfo>
            </>
          )}
        </>
      )}
    </S.Container>
  )
}
