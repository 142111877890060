import React, { useEffect, useState } from 'react'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import Table from './Table'
import { LinkRegisterNewPanel } from '~/components/Link/styles'
import { Container } from '~/styles/container'
import { GetAxios } from '~/services/api'

export default function GeneralNotificationsList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/generalnotifications`,
        )

        setNotifications(data)

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])
  return (
    <Container>
      <h1 className='page-header'>Notificações Gerais</h1>
      <Panel>
        <PanelHeader noButton>
          <LinkRegisterNewPanel to='/general-notifications/create'>
            Nova Notificação
          </LinkRegisterNewPanel>
        </PanelHeader>

        <PanelBody>
          <Table loading={loading} notifications={notifications} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
