import { toast } from 'react-toastify'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import useModal from '~/hooks/useModal'
import { GetAxios } from '~/services/api'
import { KANBAN_LIST_FORMS_INPUT } from '../constants'

export default function AddListComponent() {
  const { axiosApis } = GetAxios()
  const { hideModal } = useModal()

  const defaultValue = {
    title: 'Nova lista',
    status: true,
    showTravelerButton: false,
    showProposalButton: false,
    useAsLossRate: false,
    useAsConversionRate: false,
    useAsInitContact: false,
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('title', data.title)
      formData.append('status', data.status)
      formData.append('showTravelerButton', data.showTravelerButton)
      formData.append('showProposalButton', data.showProposalButton)
      formData.append('useAsLossRate', data.useAsLossRate)
      formData.append('useAsConversionRate', data.useAsConversionRate)
      formData.append('useAsInitContact', data.useAsInitContact)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban`,
        formData,
      )

      toast.success('Lista criada com sucesso!')

      hideModal()
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  async function onValidate(data) {
    if (data.title && !/[a-zA-Z0-9]{4,}/.test(data.title)) {
      toast.info(
        'O título da lista tem que conter letras ou números, e ter mais que 3 caracteres.',
      )

      return false
    }

    return true
  }

  return (
    <Container>
      <DefaultForm
        callbackSubmit={onSubmit}
        defaultValue={defaultValue}
        onValidate={onValidate}
        inputs={KANBAN_LIST_FORMS_INPUT}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
