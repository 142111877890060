import { useEffect, useState } from 'react'
import styled from 'styled-components'
import DivForHTML from '~/components/DivForHTML'
import Divisor from '~/components/Divisor'
import FileResponsive from '~/components/FileResponsive'
import LoadingContainer from '~/components/LoadingContainer'
import { getDateFormated, getTimeFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'

function MapDefaultModal({ token, marker, onCloseModal }) {
  const { axiosApis } = GetAxios()
  const [image, setImage] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        setImage(undefined)

        const { data } = await axiosApis(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelmap/markers/image/${token}/${marker.data.type}/${marker.data.id}`,
        )

        setImage(data)

        setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [marker])

  return (
    <ModalContainer>
      <ModalContent>
        <ModalContentHeader>
          <h3>{marker.data.label}</h3>

          <i className='fas fa-times' onClick={onCloseModal} />
        </ModalContentHeader>

        <ModalContentBody>
          {marker.data && marker.data.title && <b>{marker.data.title}</b>}

          {!loading && image && (
            <FileResponsive src={`data:image/*;base64,${image}`} />
          )}

          {loading && <LoadingContainer height='200px' />}

          {marker.data && marker.data.desc && <Divisor noBorder />}

          {marker.data && marker.data.desc && (
            <DivForHTML text={marker.data.desc} />
          )}

          {marker.data && marker.data.start && <Divisor noBorder />}

          {marker.data && marker.data.start && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: marker.data.end ? 'column' : 'row',
                  alignItems: 'flex-start',
                }}
              >
                {marker.data.type !== 'walking' && (
                  <span
                    style={{
                      fontSize: '1rem',
                      color: '#7f2dc2',
                      fontWeight: 'bold',
                    }}
                  >
                    {getDateFormated(marker.data.start)}
                  </span>
                )}

                {!marker.data.end && <Divisor noBorder />}

                <span
                  style={{
                    fontSize: '1rem',
                    color: '#7f2dc2',
                    fontWeight: 'bold',
                  }}
                >
                  {getTimeFormated(marker.data.start)}
                </span>
              </div>

              {marker.data.end && (
                <>
                  <i
                    className={'fas fa-arrow-right'}
                    style={{
                      fontSize: '1rem',
                      color: '#7f2dc2',
                      fontWeight: 'bold',
                    }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    {marker.data.type !== 'walking' && (
                      <span
                        style={{
                          fontSize: '1rem',
                          color: '#7f2dc2',
                          fontWeight: 'bold',
                        }}
                      >
                        {getDateFormated(marker.data.end)}
                      </span>
                    )}

                    <span
                      style={{
                        fontSize: '1rem',
                        color: '#7f2dc2',
                        fontWeight: 'bold',
                      }}
                    >
                      {getTimeFormated(marker.data.end)}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}

          {marker.data && marker.data.type === 'walking' && (
            <Divisor noBorder />
          )}

          {marker.data && marker.data.startAddress && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: marker.data.startAddress ? 'column' : 'row',
                  alignItems: 'flex-start',
                }}
              >
                <span
                  style={{
                    fontSize: '0.8rem',
                    color: '#777',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  {marker.data.startAddress}
                </span>
              </div>

              {marker.data && marker.data.endAddress && (
                <>
                  <Divisor noBorder vertical margin='8px' />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '0.8rem',
                        color: '#777',
                        fontWeight: 'bold',
                        textAlign: 'right',
                      }}
                    >
                      {marker.data.endAddress}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}

          {marker.data && <Divisor noBorder />}

          {marker.data && marker.data.locator && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <b>Reseva/Localizador</b>

              <span>{marker.data.locator}</span>
            </div>
          )}

          {marker.data && marker.data.flight && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <b>Voo</b>

              <span>{marker.data.flight}</span>
            </div>
          )}

          {marker.data && marker.data.originPurchase && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <b>Origem da compra</b>

              <span>{marker.data.originPurchase}</span>
            </div>
          )}

          {marker.data && marker.data.provider && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <b>Empresa/Fornecedor</b>

              <span>{marker.data.provider}</span>
            </div>
          )}
        </ModalContentBody>
      </ModalContent>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  position: absolute;
  width: calc(40% + 8px);
  height: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  background-color: #fff;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  border-radius: 0 8px 8px 0;
  padding: 8px;
  box-shadow: 4px 0px 4px #ccc;
  overflow: auto;
`

const ModalContentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 0 0 8px 0;

  h3 {
    padding: 0;
    margin: 0;
    color: '#ccc';
  }

  i {
    padding: 8px;
    font-size: 1.2rem;
    cursor: pointer;
  }
`

const ModalContentBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0;
`

export default MapDefaultModal
