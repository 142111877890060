import PageSettings from '~/config/PageSettings'

export default function Content({ children }) {
  return (
    <PageSettings.Consumer>
      {({
        pageContentFullWidth,
        pageContentClass,
        pageContentInverseMode,
        pageFullContent,
      }) => (
        <div
          className={`${pageFullContent ? '' : 'content'} ${
            pageContentFullWidth ? 'content-full-width ' : ''
          }${
            pageContentInverseMode ? 'content-inverse-mode ' : ''
          }${pageContentClass}`}
        >
          {children}
        </div>
      )}
    </PageSettings.Consumer>
  )
}
