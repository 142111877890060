import styled from 'styled-components'
import { Col } from 'reactstrap'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  * {
    box-sizing: border-box;
  }

  [class^='col'],
  .row {
    padding: 0;
    margin: 0;
  }

  [class^='col'] {
    padding: 2px 8px;

    .row {
      [class^='col'] {
        padding: 0;
      }
    }
  }

  p {
    margin-bottom: 4px;
  }
`

export const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LinkPrint = styled(Link)`
  font-weight: bold;
  font-size: 12px;
  border-radius: 8px;
  padding: 5px;
  background-color: #7f2ec2;
  color: #fff;
  margin: 5px;
  white-space: nowrap;

  &:hover {
    color: #fff;
  }
`

export const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`
