import HoldingContext from '~/config/HoldingContext'

export default function Content({ children }) {
  return (
    <HoldingContext.Consumer>
      {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
        <div
          className={`content ${
            pageContentFullWidth ? 'content-full-width ' : ''
          }${
            pageContentInverseMode ? 'content-inverse-mode ' : ''
          }${pageContentClass}`}
        >
          {children}
        </div>
      )}
    </HoldingContext.Consumer>
  )
}
