import { combineReducers } from 'redux'
import auth from './auth'
import page from './page'
import chatReducer from '~/store/reducers/chatReducer'
import conversationReducer from '~/store/reducers/conversationReducer'
import messageReducer from '~/store/reducers/messageReducer'
import contactReducer from '~/store/reducers/contactReducer'
import currentConversationReducer from '~/store/reducers/currentConversationReducer'

const slicers = combineReducers({
  auth: auth,
  chatReducer: chatReducer,
  conversationReducer: conversationReducer,
  messageReducer: messageReducer,
  contactReducer: contactReducer,
  currentConversationReducer: currentConversationReducer,
  page: page,
})

export default slicers
