import { useEffect, useState } from 'react'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import IconButton from '~/components/IconButton'
import { Row } from 'reactstrap'
import useModal from '~/hooks/useModal'
import ItemDetails from '../Details'
import { Button } from 'reactstrap'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'

export default function SalesReleaseGeneralDataList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [optionTypes, setOptionTypes] = useState([])
  const [selectedType, setSelectedType] = useState(null)
  const [itemsList, setItemsList] = useState([])
  const { showModal, hideModal } = useModal()

  useEffect(() => {
    getOptionsTypes()
  }, [])

  async function getOptionsTypes() {
    try {
      setLoading(true)

      const response = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesrelease/generaldatatypes`,
      )

      setOptionTypes(response.data)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function itemTypeClickHandle(type) {
    try {
      setSelectedType(type)
      setLoading(true)

      const response = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesrelease/generaldata?type=${type.id}`,
      )

      setItemsList(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function showDetails(e) {
    showModal({
      title: 'Cadastrar',
      body: () => (
        <ItemDetails
          typeDsc={selectedType.description}
          typeId={selectedType.id}
          reloadListCallback={() => itemTypeClickHandle(selectedType)}
        />
      ),
    })
  }

  async function newItemTypeClickHandle() {
    try {
      //selectedType
      setLoading(true)

      await showDetails()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Container>
      <h1 className='page-header'>
        Cadastros
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={'Cadastro para outros dados do backoffice'}
          style={{ fontSize: '12px' }}
        />
      </h1>
      <Panel>
        <PanelHeader title={'Tipos'} noButton />
        <PanelBody>
          {optionTypes.map(tp => (
            <div key={tp.id} className='form-check form-check-inline'>
              <input
                color='primary'
                type={'radio'}
                className='form-check-input'
                size='sm'
                key={tp.id}
                id={'tp' + tp.id}
                onClick={() => itemTypeClickHandle(tp)}
                value={tp.id}
                name={'types'}
              />
              <label htmlFor={'tp' + tp.id} className='form-check-label'>
                {tp.description}
              </label>
            </div>
          ))}
        </PanelBody>
      </Panel>
      {selectedType && (
        <Panel>
          <PanelHeader
            noButton
            title={'Resultado da pesquisa - ' + selectedType?.description}
          >
            <LinkRegisterNewTraveler
              to=''
              href={'#'}
              onClick={newItemTypeClickHandle}
            >
              Novo {selectedType?.description}
            </LinkRegisterNewTraveler>
          </PanelHeader>

          <PanelBody>
            <Table
              itemsList={itemsList}
              selectedType={selectedType}
              loading={loading}
              setLoading={setLoading}
              reloadListCallback={() => itemTypeClickHandle(selectedType)}
            />
          </PanelBody>
        </Panel>
      )}
    </Container>
  )
}
