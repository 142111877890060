import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { apiRequest } from '~/apiService'
// import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
// import useAxios from '~/hooks/useAxios'

export default function useHoldingBackofficeProposalsData({
  filters,
  handleProfile,
}) {
  // const { apiClient } = useAxios()
  const [loading, setLoading] = useState(false)
  // const dashboardsPlaceholders = getDashboardPlaceholders(3)
  const [dashboards, setDashboards] = useState([])
  const [chartData, setChartData] = useState()
  const [tableData, setTableData] = useState([])

  const params = {
    branchs: filters?.branchs ? filters.branchs.map(x => x.value) : [],
    start: filters?.start ?? undefined,
    end: filters?.end ?? undefined,
    requestFromAgency: handleProfile,
  }

  useEffect(() => {
    getSource()
  }, [filters])

  async function getSource() {
    try {
      setLoading(true)

      const [dashboards, chartData, tableData] = await Promise.allSettled([
        apiRequest('get', 'holdingbackoffice/proposals/dashboards', params),
        apiRequest('get', 'holdingbackoffice/proposals/chart', params),
        apiRequest('get', 'holdingbackoffice/proposals/table', params),
      ])

      setDashboards(dashboards?.value?.data)
      setChartData(chartData?.value?.data)
      setTableData(tableData?.value?.data)
    } catch (error) {
      toast.info('Dados não encontrados. Tente mais tarde!')
      setLoading(false)
      setDashboards([])
      setChartData([])
      setTableData([])
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    dashboards,
    chartData,
    tableData,
  }
}
