import { Outlet, Route } from 'react-router-dom'
import SalesByIssuerList from './List'
import DefaultLayout from '~/pages/__layouts__/DefaultLayout'

export default function SalesByIssuer() {
  return (
    <Route
      path='/sales-by-issuer'
      element={
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      }
    >
      <Route index element={<SalesByIssuerList />} />
    </Route>
  )
}
