export default function InputMessage({ message }) {
  const inputMessage = message
    ? message
    : 'Arraste e solte o arquivo aqui ou clique para selecionar'
  return (
    <div
      style={{
        textAlign: 'center',
        margin: '8px auto',
      }}
    >
      <p>{inputMessage}</p>
    </div>
  )
}
