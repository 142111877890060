import { useLocation } from 'react-router-dom'
import SidebarNavItem from './NavItem'
import { NavUlItems } from './styles'
import useHoldingMenus from '~/hooks/useHoldingMenus'

export default function SidebarNav({ pageSettings }) {
  let location = useLocation()
  const { menus } = useHoldingMenus()

  return (
    <NavUlItems minify={pageSettings.pageSidebarMinify}>
      {menus.map((item, i) => {
        return (
          <SidebarNavItem
            data={item}
            location={location}
            pageSettings={pageSettings}
            keyItem={i}
            key={i}
          />
        )
      })}
    </NavUlItems>
  )
}
