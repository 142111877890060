import styled from 'styled-components'

export default function BannerTitle({ children, bg }) {
  return (
    <Container bg={bg}>
      {bg && <Image src={bg} alt={'Imagem de capa da viagem'} />}

      <Content bg={bg}>{children}</Content>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${props => (props.bg ? '360px' : 'auto')};
  padding: 0;
  margin: 0;
  color: ${props => (props.bg ? 'white' : '#333')};
`

const Content = styled.div`
  position: ${props => (props.bg ? 'absolute' : 'relative')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${props =>
    props.bg
      ? 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))'
      : 'none'};
`
const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
