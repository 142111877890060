import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import AnalyticsTable from '~/components/Analytics/Table'
import Divisor from '~/components/Divisor'
import useHoldingConsolidatorHomeData from '../../dataHooks/useHoldingConsolidatorHome'

export default function Home({ branchs, filters }) {
  const {
    dashboards,
    ammountLast6Months,
    newSalesLast6Months,
    newProposalsLast6Months,
    newCustomersLast6Months,
  } = useHoldingConsolidatorHomeData({ filters })

  return (
    <Container>
      <AnalyticsDashboard data={dashboards} />

      <Divisor margin='5px' />

      <Row>
        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Novos clientes(últimos 6 meses)'>
            <AnalyticsLineChart data={newCustomersLast6Months} />
          </AnalyticsContainer>
        </Col>

        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Vendas de novos clientes(últimos 6 meses)'>
            <AnalyticsLineChart data={newSalesLast6Months} currency />
          </AnalyticsContainer>
        </Col>

        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Novas propostas(últimos 6 meses)'>
            <AnalyticsLineChart data={newProposalsLast6Months} currency />
          </AnalyticsContainer>
        </Col>

        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Faturamento(últimos 6 meses)'>
            <AnalyticsLineChart data={ammountLast6Months} currency />
          </AnalyticsContainer>
        </Col>
      </Row>

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Franqueados'
        data={branchs}
        columns={[
          {
            Header: 'Nome',
            accessor: 'name',
            width: 150,
          },
          {
            Header: 'E-mail',
            accessor: 'email',
            width: 300,
          },
          {
            Header: 'Telefone',
            accessor: 'phone',
            width: 150,
          },
          {
            Header: 'CNPJ',
            accessor: 'cpfCnpj',
            width: 200,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div``
