import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import AgentBlock from './AgentBlock'
import Tabs from './components/Tabs'
import TravelBlock from './TravelBlock'
import TravelerBlock from './TravelerBlock'
import PartnerBlock from './PartnerBlock'
import LoadingContainer from '~/components/LoadingContainer'

export default function TravelManagement() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [travelDetails, setTravelDetails] = useState()

  useEffect(() => {
    if (travelDetails && !loading) navigate('presentation')
  }, [travelDetails, loading])

  useEffect(() => {
    getTravel(location.state?.id)
  }, [])

  async function getTravel(travelId) {
    try {
      setLoading(true)

      const { data: dataTravel } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/${travelId}`,
      )

      setTravelDetails(dataTravel)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      navigate('/travels')
      return
    }
  }

  return (
    <Container>
      {!loading && travelDetails && (
        <>
          <PageTitle title={travelDetails.title} to={-2} />

          <Panel>
            <PanelHeader noButton />

            <PanelBody>
              <div>
                {travelDetails && (
                  <div
                    style={{
                      display: 'flex',
                      // minWidth: '30%',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <TravelBlock travel={travelDetails} />

                    <TravelerBlock travel={travelDetails} reload={getTravel} />

                    <div
                      style={{
                        gap: '10px',
                        width: '60%',
                        backgroundColor: '#f4f4f4',
                      }}
                    >
                      <AgentBlock travel={travelDetails} />
                      <PartnerBlock travel={travelDetails} />
                    </div>
                  </div>
                )}
              </div>
            </PanelBody>
          </Panel>

          <Tabs />

          <Outlet context={{ travel: travelDetails }} />
        </>
      )}

      {loading && <LoadingContainer height='400px' />}
    </Container>
  )
}
