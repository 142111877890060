import { useEffect, useState } from 'react'
import Content from '~/components/HoldingBackoffice/Content'
import Footer from '~/components/HoldingBackoffice/Footer'
import Header from '~/components/HoldingBackoffice/Header'
import Sidebar from '~/components/HoldingBackoffice/Sidebar'
import HoldingBackofficeContext, {
  DEFAULT_VALUE,
} from '~/config/HoldingBackofficeContext'
import { ModalProvider } from '~/context/modal'

export default function HoldingBackofficeLayout({ children }) {
  const [state, setState] = useState(DEFAULT_VALUE)
  const [classNames, setClassNames] = useState([])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const list = []

    list.push('fade')
    list.push('page-sidebar-fixed')
    list.push('show')
    list.push('page-container')
    list.push(state.pageHeader ? 'page-header-fixed ' : '')
    list.push(state.pageSidebar ? '' : 'page-without-sidebar ')
    list.push(state.pageRightSidebar ? 'page-with-right-sidebar ' : '')
    list.push(state.pageSidebarWide ? 'page-with-wide-sidebar ' : '')
    list.push(state.pageSidebarLight ? 'page-with-light-sidebar ' : '')
    list.push(state.pageSidebarMinify ? 'page-sidebar-minified ' : '')
    list.push(state.pageSidebarToggled ? 'page-sidebar-toggled ' : '')
    list.push(state.pageTopMenu ? 'page-with-top-menu ' : '')
    list.push(state.pageContentFullHeight ? 'page-content-full-height ' : '')
    list.push(state.pageTwoSidebar ? 'page-with-two-sidebar ' : '')
    list.push(state.hasScroll ? 'has-scroll ' : '')
    list.push(
      state.pageMobileRightSidebarToggled ? 'page-right-sidebar-toggled ' : '',
    )

    setClassNames(list)
  }, [state])

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setState({
        ...state,
        hasScroll: true,
      })
    } else {
      setState({
        ...state,
        hasScroll: false,
      })
    }

    var elm = document.getElementsByClassName('nvtooltip')

    for (var i = 0; i < elm.length; i++) {
      elm[i].classList.add('d-none')
    }
  }

  async function toggleSidebarMinify() {
    setState(e => ({
      ...e,
      pageFloatSubMenuActive: e.pageSidebarMinify ? false : true,
      pageSidebarMinify: !e.pageSidebarMinify,
    }))
  }

  async function toggleMobileSidebar() {
    setState(e => ({
      ...e,
      pageSidebarToggled: !e.pageSidebarToggled,
    }))
  }

  return (
    <ModalProvider>
      <HoldingBackofficeContext.Provider
        value={{
          ...state,
          toggleSidebarMinify,
          toggleMobileSidebar,
        }}
      >
        <div className={classNames.join(' ')}>
          {state.pageHeader && <Header />}

          <div className='page-content'>
            {state.pageSidebar && <Sidebar />}

            {state.pageContent && <Content>{children}</Content>}

            {/* {state.pageFooter && <Footer />} */}
          </div>
        </div>
      </HoldingBackofficeContext.Provider>
    </ModalProvider>
  )
}
