import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import PreviewAppTheme from '~/components/PreviewAppTheme'
import { getDateFormated } from '~/functions/Date'
import { getBase64 } from '~/functions/File'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function AgencyDetails() {
  const navigate = useNavigate()
  const location = useLocation()
  const { axiosApis } = GetAxios()
  const [agency, setAgency] = useState()
  const [groups, setGroups] = useState([])
  const [previewProps, setPreviewProps] = useState()
  const [imagePreview, setImagePreview] = useState()
  const [ddi, setDdi] = useState({})
  const groupPartnershipTypeOptions = [
    { label: 'Holding Consolidadora', value: '0' },
    { label: 'Matriz', value: '1' },
    { label: 'Filial', value: '2' },
    { label: 'Holding Backoffice', value: '3' },
  ]

  useEffect(() => {
    async function getSource() {
      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agencygroup`,
        )

        setGroups(data)

        const { data: agencyData } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/${location.state.agency.id}`,
        )

        setAgency({
          id: agencyData.id,
          image: agencyData.image,
          name: agencyData.name,
          email: agencyData.email,
          ddi: agencyData.ddiValue,
          phone: agencyData.phone,
          cnpj: agencyData.cnpj,
          code: agencyData.code,
          primaryColor: agencyData.primaryColor,
          secondaryColor: agencyData.secondaryColor,
          promotionalText: agencyData.promotionalText,
          numAgent: agencyData.numAgent,
          crmIncluded: agencyData.crmIncluded,
          status: agencyData.status,
          allowWhatsapp: agencyData.allowWhatsapp,
          isOperator: agencyData.isOperator,
          enableChatCenter: agencyData.enableChatCenter,
          type: agencyData.isOperator == true ? 'Operadora' : 'Agência',
          group: agencyData.groupName
            ? { label: agencyData.groupName, value: agencyData.groupId }
            : undefined,
          groupPartnershipType: groupPartnershipTypeOptions.find(
            x => x.value == agencyData.groupPartnershipType,
          ),
          freeTrial: agencyData.freeTrial,
          freeTrialStart: !agencyData.freeTrialStart
            ? null
            : getDateFormated(agencyData.freeTrialStart, 'YYYY-MM-DD'),
          freeTrialEnd: !agencyData.freeTrialEnd
            ? null
            : getDateFormated(agencyData.freeTrialEnd, 'YYYY-MM-DD'),
        })
      } catch (error) {
        console.log(error)
      }
    }

    getSource()
    getOptionsDDI()
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('image', data.image)
      formData.append('promotionalText', data.promotionalText)
      formData.append('name', data.name)
      formData.append('email', data.email)
      formData.append('phone', data.phone)
      formData.append('ddiValue', data.ddi.value)
      formData.append('cnpj', data.cnpj)
      formData.append('code', data.code)
      formData.append('primaryColor', data.primaryColor)
      formData.append('secondaryColor', data.secondaryColor)
      formData.append('crmIncluded', data.crmIncluded)
      formData.append('status', data.status)
      formData.append('allowWhatsapp', data.allowWhatsapp)
      formData.append('numAgent', data.numAgent)
      formData.append('freeTrial', data.freeTrial)
      formData.append('enableChatCenter', data.enableChatCenter)

      if (data.freeTrialStart)
        formData.append('freeTrialStart', data.freeTrialStart)
      if (data.freeTrialEnd) formData.append('freeTrialEnd', data.freeTrialEnd)

      if (data.group) {
        formData.append('groupId', data.group.value)

        if (data.groupPartnershipType) {
          const groupPartnershipType = data.groupPartnershipType.value

          formData.append('groupPartnershipType', groupPartnershipType)
        }
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/${location.state.agency.id}`,
        formData,
      )

      return navigate(-1)
    } catch (error) {
      toast.info('Não foi possível editar a Agência.')
    }
  }

  async function onValidate(data) {
    if (
      (data.group && !data.groupPartnershipType) ||
      (!data.group && data.groupPartnershipType)
    ) {
      toast.info(
        'Ao selecionar o Grupo, é obrigatório selecionar o Vínculo com o Grupo.',
      )

      return false
    }

    return true
  }

  async function onChangeData(e) {
    getBase64(e.image, setImagePreview)

    setPreviewProps({
      primaryColor: e.primaryColor,
      secondaryColor: e.secondaryColor,
    })
  }

  return (
    <Container>
      <PageTitle title={location.state.agency.name} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Row>
            <Col sm={12} xl={8}>
              <DefaultForm
                callbackSubmit={onSubmit}
                defaultValue={agency}
                onChangeData={onChangeData}
                onValidate={onValidate}
                inputs={[
                  {
                    type: 'image',
                    name: 'image',
                    label: 'Logo da agência',
                    placeholder: 'Logo da agência',
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                    maxWidth: '300px',
                  },
                  {
                    type: 'text',
                    name: 'name',
                    label: 'Nome da agência',
                    placeholder: 'Nome da agência',
                    required: true,
                    maxLength: 100,
                    sm: 12,
                    md: 12,
                    lg: 6,
                    xl: 4,
                  },
                  {
                    type: 'email',
                    name: 'email',
                    label: 'E-mail',
                    placeholder: 'E-mail',
                    required: true,
                    maxLength: 100,
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4,
                  },
                  {
                    type: 'select',
                    name: 'ddi',
                    label: 'DDI',
                    placeholder: 'Selecione...',
                    options: ddi,
                    required: true,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                  },
                  {
                    type: 'text',
                    name: 'phone',
                    label: 'Telefone',
                    placeholder: 'Telefone',
                    required: true,
                    mask: '(99) 99999-9999',
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                  },
                  {
                    type: 'text',
                    name: 'cnpj',
                    label: 'CNPJ',
                    placeholder: 'CNPJ',
                    mask: '99.999.999/9999-99',
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4,
                  },
                  {
                    type: 'text',
                    name: 'code',
                    label: 'Código',
                    placeholder: 'Código',
                    required: true,
                    maxLength: 100,
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4,
                  },
                  {
                    type: 'color',
                    name: 'primaryColor',
                    label: 'Cor primária',
                    placeholder: 'Cor primária',
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'color',
                    name: 'secondaryColor',
                    label: 'Cor secundária',
                    placeholder: 'Cor secundária',
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'text',
                    name: 'promotionalText',
                    label: 'Texto promocional',
                    placeholder: 'Texto promocional',
                    required: true,
                    maxLength: 100,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                  },
                  {
                    type: 'text',
                    name: 'type',
                    label: 'Tipo',
                    placeholder: `Tipo`,
                    disabled: true,
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'number',
                    name: 'numAgent',
                    label: 'Qtde de consultores',
                    placeholder: 'Qtde de consultores',
                    required: true,
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'check',
                    name: 'crmIncluded',
                    label: 'Plano Viagem + CRM?',
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'check',
                    name: 'allowWhatsapp',
                    label: 'Ativar WhatsApp',
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'check',
                    name: 'enableChatCenter',
                    label: 'Ativar ChatCenter',
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'check',
                    name: 'status',
                    label: 'Status',
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'select',
                    name: 'group',
                    label: 'Grupo',
                    isClearable: true,
                    isSearchable: true,
                    options: groups,
                    sm: 12,
                    lg: 3,
                  },
                  {
                    type: 'select',
                    name: 'groupPartnershipType',
                    label: 'Vinculo com o grupo',
                    isClearable: true,
                    isSearchable: true,
                    options: groupPartnershipTypeOptions,
                    sm: 12,
                    lg: 3,
                  },
                  {
                    type: 'check',
                    name: 'freeTrial',
                    label: 'Free-trial',
                    sm: 12,
                    md: 6,
                    lg: 1,
                  },
                  {
                    type: 'date',
                    name: 'freeTrialStart',
                    label: 'Free-trial começo',
                    sm: 12,
                    md: 6,
                    lg: 2,
                  },
                  {
                    type: 'date',
                    name: 'freeTrialEnd',
                    label: 'Free-trial fim',
                    sm: 12,
                    md: 6,
                    lg: 2,
                  },
                ]}
              />
            </Col>

            <Col sm={12} xl={4}>
              <b
                style={{
                  margin: '2px 4px',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                Preview do App
              </b>

              {previewProps && (
                <PreviewAppTheme
                  image={imagePreview}
                  primaryColor={previewProps.primaryColor}
                  secondaryColor={previewProps.secondaryColor}
                />
              )}
            </Col>
          </Row>
        </PanelBody>
      </Panel>
    </Container>
  )
}
