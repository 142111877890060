import React, { useState } from 'react'
import { toast } from 'react-toastify'

export default function DragAndDropFile({
  children,
  message,
  innerRef,
  isImage = false,
  onChange = () => {},
}) {
  const [selectedFiles, setSelectedFiles] = useState([])

  const handleDrop = event => {
    event.preventDefault()
    const files = Array.from(event.dataTransfer.files)
    event.target.files = files
    if (onChange == null || typeof onChange != 'function') return
    if (files) {
      if (files[0].type.startsWith('image/')) {
        setSelectedFiles(files)
        handleUploadImage(event)
      } else {
        if (isImage) return toast.info('Insira um arquivo do tipo imagem')
        // TODO: Ajustar componente de input para file antes de usar esse método
        // handleUploadFile(event)
      }
    }
  }

  async function handleUploadFile(e) {
    e['dataTransfer'] = e['target']
    delete e['dataTransfer']
    onChange(e)
  }

  function handleUploadImage(e) {
    e['dataTransfer'] = e['target']
    delete e['dataTransfer']
    onChange(e)
  }

  return (
    <div>
      <b style={{ fontWeight: '600', fontSize: '12px' }}>{message ?? ''}</b>
      <div
        ref={innerRef}
        onDragOver={e => e.preventDefault()}
        onDrop={handleDrop}
      >
        {children}
        <p>Clique e arraste aqui</p>
      </div>
      <div>
        {selectedFiles.map((file, index) => (
          <div key={index}>{file.name}</div>
        ))}
      </div>
    </div>
  )
}
