import styled from 'styled-components'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import AnalyticsTable from '~/components/Analytics/Table'
import Divisor from '~/components/Divisor'
import { FormatValueBRL } from '~/functions/FormatValue'
import useHoldingConsolidatorServicesData from '../../dataHooks/useHoldingConsolidatorServices'

export default function HoldingConsolidatorServices({ filters }) {
  const {
    loading,
    dashboards,
    chartData,
    airTableData,
    hotelTableData,
    busTableData,
    serviceTableData,
    ticketTableData,
    tourTableData,
    cruiseTableData,
    hotelPackageTableData,
    airHotelPackageTableData,
  } = useHoldingConsolidatorServicesData({ filters })

  const tableColumns = [
    {
      Header: '',
      accessor: 'id',
      width: 80,
      Cell: props => {
        return `${props.row.index + 1}°`
      },
    },
    {
      Header: 'Fornecedor',
      accessor: 'provider',
      width: 300,
    },
    {
      Header: 'Quantidade',
      accessor: 'count',
      width: 300,
    },
    {
      Header: 'Faturamento',
      accessor: 'ammount',
      width: 150,
      Cell: props => {
        return FormatValueBRL(props.row.original.ammount)
      },
    },
  ]

  return (
    <Container>
      <AnalyticsDashboard data={dashboards} />

      <Divisor margin='5px' />

      <AnalyticsContainer title='Faturamento por tipo de serviço'>
        <AnalyticsLineChart title data={chartData} currency />
      </AnalyticsContainer>

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Top 10 fornecedores(aéreo)'
        data={airTableData}
        columns={tableColumns}
        loading={loading}
      />

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Top 10 fornecedores(hotel)'
        data={hotelTableData}
        columns={tableColumns}
        loading={loading}
      />

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Top 10 fornecedores(ônibus)'
        data={busTableData}
        columns={tableColumns}
        loading={loading}
      />

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Top 10 fornecedores(serviços)'
        data={serviceTableData}
        columns={tableColumns}
        loading={loading}
      />

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Top 10 fornecedores(bilhetes)'
        data={ticketTableData}
        columns={tableColumns}
        loading={loading}
      />

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Top 10 fornecedores(tours)'
        data={tourTableData}
        columns={tableColumns}
        loading={loading}
      />

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Top 10 fornecedores(cruzeiros)'
        data={cruiseTableData}
        columns={tableColumns}
        loading={loading}
      />

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Top 10 fornecedores(pacote(Hotel))'
        data={hotelPackageTableData}
        columns={tableColumns}
        loading={loading}
      />

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Top 10 fornecedores(pacote(Aéreo + Hotel))'
        data={airHotelPackageTableData}
        columns={tableColumns}
        loading={loading}
      />
    </Container>
  )
}

const Container = styled.div``
