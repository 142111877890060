import { AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'
import * as S from '../styles'

export default function InfomationAccordion({
  children,
  targetId,
  title,
  open,
  toggle,
}) {
  return (
    <S.InfomationAccordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId={targetId}>{title}</AccordionHeader>
        <>{children}</>
      </AccordionItem>
    </S.InfomationAccordion>
  )
}
