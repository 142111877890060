import { useState, useEffect, useContext } from 'react'
import context from '~/context/createContext'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { getDateFormated } from '~/functions/Date'
import SubTableOfSearch from '~/components/SubTableOfSearch'
import IconButton from '~/components/IconButton'
import { RegisterNewButton2 } from '~/components/Button/styles'
import useModal from '~/hooks/useModal'
import ShowInfo from './showInfo'

export default function RelationshipSearch() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [travelers, setTravelers] = useState([])
  const [pageFilters, setPageFilters] = useState({})
  const { showModal } = useModal()
  const { globalFilters, resetGlobalFilters } = useContext(context)

  useEffect(() => {
    setPageFilters({
      dateStart: globalFilters.dateStart,
      dateEnd: globalFilters.dateEnd,
    })

    let cancel = false

    async function loadSource() {
      if (cancel) return
      await onSubmit({
        dateStart: globalFilters.dateStart,
        dateEnd: globalFilters.dateEnd,
      })
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      if (data) {
        if (data.fullName) {
          formData.append('fullName', data.fullName)
        }

        if (data.phone) {
          formData.append('phone', data.phone)
        }

        if (data.email) {
          formData.append('email', data.email)
        }

        if (data.again) {
          formData.append('again', data.again.value)
        }

        if (data.dateStart && data.dateEnd) {
          formData.append('dateStart', data.dateStart)
          formData.append('dateEnd', data.dateEnd)
        }
      }

      const response = await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/gettraveler/satisfaction`,
        formData,
      )

      setTravelers(response.data)

      return setLoading(false)
    } catch (error) {
      return setLoading(false)
    }
  }

  async function showInfo() {
    showModal({
      title: 'Gerar link',
      body: () => <ShowInfo />,
      width: '50%',
    })
  }

  const onValidate = data => {
    if (data.dateEnd) {
      if (data.dateStart) {
        return true
      } else {
        toast.info('Preencha a data Inical')
        return false
      }
    }

    if (data.dateStart) {
      if (data.dateEnd) {
        return true
      } else {
        toast.info('Preencha a data final')
        return false
      }
    }

    return true
  }

  return (
    <Container>
      <h1 className='page-header'>
        Pesquisas de satisfação
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Na tela você terá todos os resultados das Pesquisas de Satisfação respondida pelos viajantes. A Pesquisa é respondida pelo Aplicativo disponível no ultimo dia de viagem.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            onValidate={onValidate}
            callbackSubmit={onSubmit}
            callbackReset={() => resetGlobalFilters()}
            defaultValue={pageFilters}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'fullName',
                label: 'Viajante',
                placeholder: 'Viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'email',
                name: 'email',
                label: 'E-mail',
                placeholder: 'E-mail',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'phone',
                label: 'Telefone',
                placeholder: 'Telefone',
                mask: '(99) 99999-9999',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'select',
                name: 'again',
                label: 'Viajaria Novamente?',
                placeholder: 'Selecione...',
                isClearable: true,
                options: [
                  {
                    label: 'Sim',
                    value: 'yes',
                  },
                  {
                    label: 'Talvez',
                    value: 'maybe',
                  },
                  {
                    label: 'Não',
                    value: 'no',
                  },
                ],
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Data Inicial',
                placeholder: '',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Data da Final',
                placeholder: '',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div>
              <b>Resultado da pesquisa</b>
            </div>

            <div>
              <b>
                * O dado do Viajaria Novamente é uma probabilidade com base na
                média de respostas da pesquisa
              </b>
            </div>

            <div>
              <RegisterNewButton2
                onClick={() => {
                  showInfo()
                }}
                disabled={loading}
              >
                Gerar link
              </RegisterNewButton2>
            </div>
          </div>
        </PanelHeader>

        <PanelBody>
          <Table
            data={travelers}
            loading={loading}
            columns={[
              {
                id: 'expander',
                Header: () => null,
                Cell: ({ row }) => {
                  if (row.original.surveys.length > 0) {
                    return (
                      <span {...row.getToggleRowExpandedProps()}>
                        <i
                          className={`fas fa-caret-${
                            row.isExpanded ? 'up' : 'down'
                          }`}
                        />
                      </span>
                    )
                  }

                  return null
                },
                width: 40,
              },
              {
                Header: 'Viajante',
                accessor: 'fullName',
                width: 180,
              },
              {
                Header: 'E-mail',
                accessor: 'email',
                width: 200,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 120,
              },
              {
                Header: 'Viajaria Novamente?',
                accessor: 'again',
                width: 150,
                Cell: props => {
                  if (props.row.original.again === 'yes') {
                    return (
                      <div
                        style={{
                          padding: '4px',
                          borderRadius: '4px',
                          backgroundColor: '#1bc25b',
                          color: '#fff',
                          fontWeight: '600',
                          minWidth: '60px',
                          maxWidth: '80px',
                          margin: 'auto',
                        }}
                      >
                        Sim
                      </div>
                    )
                  }

                  if (props.row.original.again === 'no') {
                    return (
                      <div
                        style={{
                          padding: '4px',
                          borderRadius: '4px',
                          backgroundColor: '#ff7d70',
                          color: '#fff',
                          fontWeight: '600',
                          minWidth: '60px',
                          maxWidth: '80px',
                          margin: 'auto',
                        }}
                      >
                        Não
                      </div>
                    )
                  }

                  return (
                    <div
                      style={{
                        padding: '4px',
                        borderRadius: '4px',
                        backgroundColor: '#f0a040',
                        color: '#fff',
                        fontWeight: '600',
                        minWidth: '60px',
                        maxWidth: '80px',
                        margin: 'auto',
                      }}
                    >
                      Talvez
                    </div>
                  )
                },
              },
              {
                Header: 'Destino Recente',
                accessor: 'lastDestiny',
                width: 150,
              },
              {
                Header: 'Data da Última Viagem',
                accessor: 'lastTravelDate',
                Cell: props => {
                  if (props.row.original.lastTravelDate) {
                    return getDateFormated(props.row.original.lastTravelDate)
                  }

                  return ''
                },
                width: 160,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
            subComponent={props => (
              <SubTableOfSearch data={props.row.original.surveys} />
            )}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
