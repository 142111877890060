import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'

export default function ObservationDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const defaultValue = {
    ...location.state.observation,
  }

  async function onSubmit(data) {
    try {
      const body = {
        ...data,
        id: location?.state?.observation?.id,
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/observation`,
        body,
      )

      toast.success('Observação editada com sucesso!')

      navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  return (
    <Container>
      <PageTitle title='Observações' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Container>
            <DefaultForm
              defaultValue={defaultValue}
              callbackSubmit={onSubmit}
              inputs={[
                {
                  type: 'text',
                  name: 'title',
                  label: 'Observação',
                  required: true,
                  maxLength: 100,
                },
                {
                  sm: 12,
                },
                {
                  type: 'textarea',
                  name: 'description',
                  label: 'Descrição',
                  required: true,
                  maxLength: 2000,
                  sm: 12,
                },
              ]}
            />
          </Container>
        </PanelBody>
      </Panel>
    </Container>
  )
}
