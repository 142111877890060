import styled from 'styled-components'

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 60px;
  margin: 0 0 16px;
`
export const Label = styled.label`
  font-weight: 600;
  margin: 2px 4px;
`

export const Radios = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  label {
    white-space: nowrap;
    margin: 2px 8px 2px 0;
    font-size: 16px;
  }

  input {
    margin-left: 5px;
    margin-right: 5px;
  }
`
