import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import Select from '../Select'

export default function TravelerSelect({
  name,
  label,
  placeholder,
  onChange,
  defaultValue,
  disabled,
  isClearable,
  isMulti,
}) {
  const { axiosApis } = GetAxios()
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  let searchTimer

  useEffect(() => {
    setValue(defaultValue ?? null)
  }, [defaultValue])

  async function searchOptions(e) {
    try {
      setIsLoading(true)

      var { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/select-filter?label=${e}`,
      )

      setOptions(data)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const handleSearch = e => {
    if (e.length > 2) {
      clearTimeout(searchTimer)

      searchTimer = setTimeout(() => {
        searchOptions(e)
      }, 1000)
    } else {
      clearTimeout(searchTimer)
    }
  }

  const handleChange = e => {
    setValue(e)
    onChange({
      target: {
        name: name,
        value: e,
      },
    })
  }

  return (
    <div>
      <Select
        name={name}
        label={label}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={e => handleChange(e.target.value)}
        onInputChange={e => handleSearch(e)}
        isSearchable
        isClearable={isClearable}
        isLoading={isLoading}
        disabled={disabled}
        isMulti={isMulti}
        formatOptionLabel={e => {
          return <div>{e.label}</div>
        }}
      />
    </div>
  )
}
