import { useEffect } from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function AgencyDetails() {
  const navigate = useNavigate()
  const location = useLocation()
  const { axiosApis } = GetAxios()
  const [data, setData] = useState([])

  useEffect(() => {
    requestApiOptions()
  }, [])

  async function requestApiOptions() {
    try {
      const { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/city/countries`,
      )

      setData(data)
    } catch (error) {
      console.log(error)
    }
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('name', data.name)
      formData.append('adminName', data.adminName)
      formData.append(
        'country',
        data.country.value ?? location.state.agency.country,
      )
      formData.append('lat', data.lat)
      formData.append('lng', data.lng)
      formData.append('iso2', data.iso2)
      formData.append('iso3', data.iso3)
      formData.append('population', data.population)
      formData.append('id', location.state.agency.id)
      formData.append(
        'capital',
        data.capital.value ?? location.state.agency.capital,
      )

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/city/countries/${location.state.agency.id}`,
        formData,
      )

      return navigate(-1)
    } catch (error) {
      toast.info('Não foi possível editar essas cidade.')
    }
  }

  const onRemove = async () => {
    try {
      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/city/countries/${location.state.agency.id}`,
        { params: { id: `${location.state.agency.id}` } },
      )

      return navigate(-1)
    } catch (error) {
      toast.info('Não foi possível apagar essas cidade.')
    }
  }

  return (
    <Container>
      <PageTitle title={`Editar - ${location.state.agency.name}`} />
      <Panel>
        <PanelHeader noButton />
        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackDelete={onRemove}
            defaultValue={location.state.agency}
            inputs={[
              {
                type: 'text',
                label: 'Cidade',
                name: 'name',
                placeholder: 'Nome',
                required: true,
                maxLength: 100,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                label: 'Estado',
                name: 'adminName',
                placeholder: 'Estado',
                required: true,
                maxLength: 100,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'select',
                label: 'País',
                name: 'country',
                placeholder: `${location.state.agency.country}`,
                options: data,
                width: 120,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'select',
                label: 'Tipo',
                name: 'capital',
                placeholder: `${location.state.agency.capital}`,
                options: [
                  {
                    label: 'Capital',
                    value: 'admin',
                  },
                  {
                    label: 'Cidade Grande',
                    value: 'primary',
                  },
                  {
                    label: 'Cidade Pequena',
                    value: 'minor',
                  },
                ],
                width: 120,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                label: 'Latitude',
                name: 'lat',
                placeholder: 'Latitude',
                required: true,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                label: 'Longitude',
                name: 'lng',
                placeholder: 'Longitude',
                required: true,
                maxLength: 100,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                label: 'ISO2',
                name: 'iso2',
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                label: 'ISO3',
                name: 'iso3',
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                label: 'População',
                name: 'population',
                placeholder: 'População',
                required: true,
                maxLength: 100,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
