import { useEffect, useState } from 'react'
import PedagogicalForm from './Pedagogical'
import { GetAxios } from '~/services/api'
import { useParams } from 'react-router-dom'
import LoadingContainer from '~/components/LoadingContainer'
import MarriageForm from './Marriage'

export default function ChoiceForm() {
  const { axiosApis } = GetAxios()
  const [eventSource, setEventSource] = useState([])
  const [loading, setLoading] = useState(true)
  const params = useParams()

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/eventSubscription/${params.token}`,
        )

        setEventSource(data)

        setLoading(false)
      } catch (error) {
        console.log(error)

        setLoading(false)
      }
    }

    getSource()
  }, [])

  const forms = {
    pedagogico: <PedagogicalForm data={eventSource} />,
    outros: <MarriageForm data={eventSource} />,
    incentivo: <MarriageForm data={eventSource} />,
  }

  return (
    <>
      {!loading &&
        eventSource &&
        eventSource.status &&
        forms[`${eventSource.type}`]}

      {!loading && eventSource && !eventSource.status && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1>{eventSource.name}</h1>

          {eventSource.agency && <h2>{eventSource.agency.name}</h2>}

          {eventSource.institutions &&
            eventSource.institutions.map((e, i) => {
              return <h3 key={i}>{e.name}</h3>
            })}

          <b>{eventSource.message}</b>
        </div>
      )}

      {!loading && !eventSource && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <b>Link inválido!</b>
        </div>
      )}

      {loading && <LoadingContainer height='100vh' />}
    </>
  )
}
