import { useEffect, useState } from 'react'
import { LinkEdit } from '~/components/Link/styles'
import ReactTable from '~/components/Table'
import { GetAxios } from '~/services/api'

export default function Table({ data, loading }) {
  const { axiosApis } = GetAxios()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
        )

        return setRoles(data)
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <ReactTable
      data={data}
      loading={loading}
      columns={[
        {
          Header: 'Data de Embarque',
          accessor: 'data_embarque',
          width: 150,
        },
        {
          Header: 'Viajante',
          accessor: 'name_viajante',
          width: 400,
        },
        {
          Header: 'Consultor',
          accessor: 'name_agent',
          width: 200,
        },
        {
          Header: 'Origem',
          accessor: 'city_origin',
          width: 100,
        },
        {
          Header: 'Destino',
          accessor: 'city_destiny',
          width: 100,
        },
      ]}
    />
  )
}
