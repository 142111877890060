import ReactTable from '~/components/Table'
import IconButton from '~/components/IconButton'

const DocumentTable = ({ documentList, onDelete, loading, download }) => {
  return (
    <ReactTable
      columns={[
        {
          Header: 'Título',
          accessor: 'title',
          width: 120,
        },
        {
          Header: 'Descrição',
          accessor: 'desc',
          width: 150,
        },
        {
          Header: 'Tipo de Arquivo',
          accessor: 'docType',
          width: 150,
        },
        {
          accessor: 'view',
          Cell: props => {
            return (
              <IconButton
                id={props.row.index}
                type={'view'}
                iconOverride={'far fa-eye'}
                tooltipOverride={'Ver documentos'}
                onClick={() => download(documentList[props.row.index])}
              />
            )
          },
          width: 50,
        },
        {
          accessor: 'delete',
          Cell: props => {
            return (
              <IconButton
                id={props.row.index}
                type={'remove'}
                onClick={() => onDelete(props.row.original)}
              />
            )
          },
          width: 50,
        },
      ]}
      data={documentList}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}

export default DocumentTable
