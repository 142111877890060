import * as S from './styles'
import image from '~/components/imgs/chat-image.png'

export default function InicialImage() {
  return (
    <S.ImageContainer>
      <img style={{ width: '600px' }} src={image} />
    </S.ImageContainer>
  )
}
