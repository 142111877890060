import { useState } from 'react'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function CampaignCreate() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)

  async function onSubmit(data) {
    try {
      setLoading(true)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/campaign`,
        data,
      )

      setLoading(false)

      toast.success('Campanha cadastrado com sucesso.')

      navigate('/campaign')
    } catch (error) {
      toast.error(error?.response?.data)
      setLoading(false)
    }
  }

  return (
    <div>
      <PageTitle title='Nova campanha' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <DefaultForm
            loading={loading}
            disabled={loading}
            callbackSubmit={onSubmit}
            callbackReset={() => {
              return
            }}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Campanha',
                required: true,
                maxLength: 100,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}
