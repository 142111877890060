const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  headerNavItemSelected: 'agency',
}

const slice = createSlice({
  name: 'page',
  initialState: initialState,
  reducers: {
    onClickHeaderNavItem: (state, { payload }) => {
      return {
        ...state,
        headerNavItemSelected: payload,
      }
    },
    navigateHeaderNavItem: (state, { payload }) => {
      return {
        ...state,
        headerNavItemSelected: payload,
      }
    },
  },
})

export const { onClickHeaderNavItem, navigateHeaderNavItem } = slice.actions

export default slice.reducer
