import { AvatarSoft } from '~/components/Header/styles'
import SkeletonLine from '~/components/Skeleton'
import { ChatHeader } from '../Header/styles'

export default function SkeletonHeader({ ...rest }) {
  return (
    <ChatHeader {...rest}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <AvatarSoft
          style={{
            width: '47px',
            height: '47px',
            backgroundSize: 'cover',
            backgroundImage: `url('')`,
          }}
        />

        <SkeletonLine width={100} height={20} />
      </div>
    </ChatHeader>
  )
}
