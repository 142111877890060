import ModalDefault from '~/components/ModalDefault'
import DefaultForm from '~/components/DefaultForm'
import { apiRequest } from '~/apiService'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getFile } from '~/functions/File'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import ReactTable from '~/components/Table'
import { Create } from '~/components/TableToList/style'
import styled from 'styled-components'
import Divisor from '~/components/Divisor'
import IconButton from '~/components/IconButton'
import { getDateFormated } from '~/functions/Date'

export default function ModalAir({
  title = '',
  origin = '',
  id = 0,
  proposalId = 0,
  inputs = [],
  handle = () => {},
  content = '',
  setData,
}) {
  const [type, setType] = useState({ label: 'Selecione...', value: null })
  const [loading, setLoading] = useState(false)
  const [loadingExcerpt, setLoadingExcerpt] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [excerptDetails, setExcerptDetails] = useState(false)
  const [modaStage, setModalStage] = useState('1')
  const [defaultValue, setDefaultValue] = useState({})
  const [defaultValueExcerpt, setDefaultValueExcerpt] = useState({})
  const [dataExcerpts, setDataExcerpts] = useState([])
  const [excerptId, setExcerptId] = useState(0)
  const [flightId, setFlightId] = useState(id)
  const [minDate, setMinDate] = useState(
    defaultValue?.dateOrigin ?? '1900-01-01',
  )

  let operation = flightId == 0 ? 'post' : 'put'

  useEffect(() => {
    if (operation == 'put') {
      get()
      getAllExcerpt()
    }
  }, [])

  const get = async () => {
    const result = await apiRequest(
      'get',
      `proposal/${origin}/${flightId}`,
      {},
      setLoading,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    const newResultHotel = {
      ...result?.data?.content,
      files: [],
    }

    if (result?.data?.content?.files?.length > 0) {
      for (let iF = 0; iF < result?.data?.content?.files?.length; iF++) {
        const file = await getFile(
          result?.data?.content?.files[iF]?.file,
          result?.data?.content?.files[iF]?.fileName,
          result?.data?.content?.files[iF]?.contentType,
        )

        newResultHotel?.files.push(file)
      }
    }

    for (let key in newResultHotel) {
      newResultHotel[key] === null && delete newResultHotel[key]
      newResultHotel[key]?.value === null && delete newResultHotel[key]
    }

    setDefaultValue(newResultHotel)
  }

  const getAllExcerpt = async () => {
    const result = await apiRequest(
      'options',
      `proposal/flight-excerpt/${id}`,
      {},
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    setDataExcerpts(result?.data?.content)
  }

  const getExcerpts = async id => {
    const result = await apiRequest(
      'get',
      `proposal/flight-excerpt/${id}`,
      {},
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    setType({
      label: result?.data?.content?.type?.label,
      value: result?.data?.content?.type?.value,
    })
    setDefaultValueExcerpt(result?.data?.content)
  }

  const onSubmit = async (data, setId, closeModal) => {
    const formData = new FormData()

    for (let key in data) {
      if (data[key] != null) {
        if (key == 'files') {
          data?.files?.map(file => formData.append('files', file))
        } else {
          formData.append(key, data[key])
        }
      }
    }

    formData.set('proposalId', proposalId)
    formData.set('id', flightId)
    formData.set(
      'baggage',
      data?.baggage?.value == undefined ? false : data?.baggage?.value,
    )
    if (data?.passengersDescription?.value)
      formData.set('passengersDescription', data?.passengersDescription?.value)

    if (data?.type?.value) formData.set('type', data?.type?.value)

    const result = await apiRequest(
      operation,
      `proposal/${origin}`,
      formData,
      setLoading,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    // console.log(result)

    if (setId) setFlightId(result.data.id)

    setData(state => {
      const aux = { ...state }

      aux[content] = result?.data?.content

      return aux
    })

    if (closeModal) handle()

    !result.error && toast.success(result?.data?.message)
  }

  const onSubmitExcerpt = async data => {
    const result = await apiRequest(
      excerptId == 0 ? 'post' : 'put',
      `proposal/flight-excerpt`,
      { ...data, proposalId: proposalId, proposalFlightId: flightId },
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    setModalStage('1')
    setOpenModal(state => !state)
    setExcerptDetails(state => !state)
    setDataExcerpts(result?.data?.content)
    setDefaultValueExcerpt({})
    setType({ label: 'Selecione...', value: null })
    toast.success(result?.data?.message)
  }

  const onClone = async (route, id) => {
    const result = await apiRequest(
      'post',
      `proposal/clone-${route}`,
      { id: id, proposalId: proposalId },
      setLoading,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
    } else {
      setDataExcerpts(result?.data?.content)
      toast.success(result?.data?.message)
    }
  }

  async function onSearch(e) {
    if (!e.target.value) {
      return
    }
    const result = await apiRequest(
      'get',
      `travelair/search-flight`,
      { flight: e.target.value.replace(' ', '') },
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info('Vôo não encontrado')
    } else {
      setDefaultValueExcerpt({
        ...defaultValueExcerpt,
        flight: e.target.value.replace(' ', ''),
        cia: result?.data?.response?.airline_name,
        iataOrigin: result?.data?.response?.dep_iata,
        airportOrigin: result?.data?.response?.dep_name,
        origin: result?.data?.response?.dep_city,
        iataDestiny: result?.data?.response?.arr_iata,
        airportDestiny: result?.data?.response?.arr_name,
        destiny: result?.data?.response?.arr_city,
        type: { label: 'Conexão', value: 'Conexão' },
      })

      setType({ label: 'Conexão', value: 'Conexão' })
    }
  }

  async function onSearchRange(e) {
    try {
      const stringSplitted = e?.flightRange?.replace(' ', '')?.split(',')

      const result = await apiRequest(
        'post',
        `travelair/search-flight-range`,
        { flights: stringSplitted, proposalFlightId: flightId },
        setLoadingExcerpt,
      )

      if (result.error) {
        toast.info(result?.erroMessage)
        return
      } else {
        if (result?.data?.content?.length == dataExcerpts?.length) {
          toast.info('Nenhum voo encontrado')
        } else {
          setOpenModal(state => !state)
          setDataExcerpts(result?.data?.content)
          setDefaultValueExcerpt({})
          setType({ label: 'Selecione...', value: null })
          setModalStage('1')
        }
      }
    } catch (err) {
      toast.info(
        'Um erro inesperado ocorreu, por favor entrar em contato com o suporte.',
      )
    }
  }

  async function onChangeIata(e) {
    if (!e.target.value) return

    const result = await apiRequest(
      'get',
      `travelair/search-airport`,
      { airportIata: e.target.value },
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info('Aeroporto não encontrado.')
    } else {
      if (e.target.name === 'iataOrigin') {
        setDefaultValueExcerpt({
          ...defaultValueExcerpt,
          iataOrigin: result.data.id,
          airportOrigin: result.data.name,
          origin: result.data.city,
        })
      }

      if (e.target.name === 'iataDestiny') {
        setDefaultValueExcerpt({
          ...defaultValueExcerpt,
          destinyIata: result.data.id,
          airportDestiny: result.data.name,
          destiny: result.data.city,
        })
      }
    }

    setLoading(false)
  }
  function formatDestinyDate(e) {
    const formatedDate = getDateFormated(e, 'YYYY-MM-DD')
    setMinDate(formatedDate)
    return formatedDate
  }

  const onRemove = async id => {
    const result = await apiRequest(
      'delete',
      `proposal/flight-excerpt/${id}`,
      {},
      setLoading,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    setDataExcerpts(result?.data?.content)

    !result.error && toast.success(result?.data?.message)
  }

  return (
    <ModalDefault
      title={title}
      handleShow={handle}
      content={
        <Container>
          {openModal && (
            <CustomModal>
              {modaStage == '1' && !excerptDetails && (
                <CustomModalContent>
                  <CustomModalContentHeader>
                    <div>
                      <h5>Trecho</h5>
                      <b>
                        ** Utilize o campo a baixo para criar um ou mais voos
                        automaticamente informando apenas os números dos voos
                        separados por vírgula. Ex: ABC123, DEF456, GHI789
                      </b>
                    </div>

                    <IconButton
                      type={'close'}
                      onClick={() => {
                        setDefaultValueExcerpt({})
                        setType({ label: 'Selecione...', value: null })
                        setOpenModal(state => !state)
                        setExcerptId(0)
                        setModalStage('1')
                        setExcerptDetails(false)
                      }}
                    />
                  </CustomModalContentHeader>

                  <Divisor border />

                  <DefaultForm
                    // loading={loadingExcerpt}
                    callbackSubmit={onSearchRange}
                    callbackReset={() => {}}
                    callbackCancel={() => {
                      setModalStage('2')
                      setExcerptDetails(true)
                    }}
                    cancelLabel='Preencher manualmente'
                    submitLabel='Pesquisar'
                    inputs={[
                      {
                        type: 'text',
                        name: 'flightRange',
                        label: 'Voos',
                        placeholder: 'Ex: ABC123, DEF456, GHI789',
                        sm: 12,
                        lg: 12,
                      },
                    ]}
                  />
                </CustomModalContent>
              )}
              {modaStage == '2' && excerptDetails && (
                <CustomModalContent>
                  <CustomModalContentHeader>
                    <div>
                      <h5>Trecho</h5>
                      <b>
                        ** Utilize o campo de pesquisa pra autopreencher
                        informações do voo. Ex: ABC123
                      </b>
                    </div>

                    <IconButton
                      type={'close'}
                      onClick={() => {
                        setDefaultValueExcerpt({})
                        setType({ label: 'Selecione...', value: null })
                        setOpenModal(state => !state)
                        setExcerptId(0)
                        setModalStage('1')
                        setExcerptDetails(false)
                      }}
                    />
                  </CustomModalContentHeader>

                  <Divisor border />

                  <DefaultForm
                    defaultValue={defaultValueExcerpt}
                    loading={loadingExcerpt}
                    callbackSubmit={onSubmitExcerpt}
                    callbackReset={() => {}}
                    callbackCancel={() => {
                      setModalStage('1')
                      setExcerptDetails(false)
                    }}
                    cancelLabel={
                      excerptDetails ? 'Criar automaticamente' : 'Voltar'
                    }
                    inputs={[
                      {
                        type: 'select',
                        name: 'type',
                        label: 'Tipo',
                        placeholder: 'Selecionar...',
                        options: [
                          { label: 'Selecione...', value: null },
                          { label: 'Conexão', value: 'Conexão' },
                          { label: 'Escala', value: 'Escala' },
                        ],
                        onChange: props => setType(props.target.value),
                        sm: 12,
                        lg: 2,
                      },
                      {
                        type: 'text',
                        name: 'flight',
                        label: 'Voo',
                        placeholder: 'Ex: ABC123',
                        searchButton: true,
                        callbackSearch: onSearch,
                        sm: 12,
                        lg: 3,
                      },
                      {
                        type: 'text',
                        name: 'cia',
                        label: 'Cia aérea',
                        sm: 12,
                        lg: 7,
                      },
                      {
                        empty: true,
                        text: '<h6>Embarque</h6>',
                        sm: 12,
                      },
                      {
                        type: 'text',
                        name: 'iataOrigin',
                        label: 'IATA',
                        callbackSearch: onChangeIata,
                        searchButton: true,
                        required: true,
                        sm: 12,
                        lg: 1,
                      },
                      {
                        type: 'text',
                        name: 'airportOrigin',
                        label: 'Aeroporto',
                        required: true,
                        sm: 12,
                        lg: 4,
                      },
                      {
                        type: 'text',
                        name: 'origin',
                        label: 'Cidade',
                        sm: 12,
                        lg: 3,
                      },
                      {
                        type: 'date',
                        name: 'dateOrigin',
                        label: 'Data',
                        sm: 12,
                        lg: 2,
                      },
                      {
                        type: 'time',
                        name: 'timeOrigin',
                        label: 'Hora',
                        sm: 12,
                        lg: 2,
                      },
                      type?.value == 'Conexão'
                        ? {
                            empty: true,
                            text: '<h6>Desembarque</h6>',
                            sm: 12,
                          }
                        : { sm: 12 },
                      type?.value == 'Conexão'
                        ? {
                            type: 'text',
                            name: 'iataDestiny',
                            label: 'IATA',
                            callbackSearch: onChangeIata,
                            searchButton: true,
                            required: true,
                            sm: 12,
                            lg: 1,
                          }
                        : { sm: 12 },
                      type?.value == 'Conexão'
                        ? {
                            type: 'text',
                            name: 'airportDestiny',
                            label: 'Aeroporto',
                            required: true,
                            sm: 12,
                            lg: 4,
                          }
                        : { sm: 12 },
                      type?.value == 'Conexão'
                        ? {
                            type: 'text',
                            name: 'destiny',
                            label: 'Cidade',
                            sm: 12,
                            lg: 3,
                          }
                        : { sm: 12 },
                      type?.value == 'Conexão'
                        ? {
                            type: 'date',
                            name: 'dateDestiny',
                            label: 'Data',
                            sm: 12,
                            lg: 2,
                            minDate: minDate,
                          }
                        : { sm: 12 },
                      type?.value == 'Conexão'
                        ? {
                            type: 'time',
                            name: 'timeDestiny',
                            label: 'Hora',
                            sm: 12,
                            lg: 2,
                          }
                        : { sm: 12 },
                    ]}
                  />
                </CustomModalContent>
              )}
            </CustomModal>
          )}

          {flightId == 0 && (
            <b style={{ fontSize: '16px' }}>
              ** Para criar trechos para esse aéreo é necessário clicar em
              Salvar
            </b>
          )}
          {flightId > 0 && (
            <Panel>
              <PanelHeader noButton title={'Trechos'}>
                <Create
                  onClick={() => {
                    setOpenModal(state => !state)
                  }}
                >
                  Adicionar trecho
                </Create>
              </PanelHeader>

              <PanelBody>
                <ReactTable
                  data={dataExcerpts}
                  columns={[
                    {
                      Header: 'Voo',
                      accessor: 'flight',
                      width: 100,
                    },
                    {
                      Header: 'Cia',
                      accessor: 'cia',
                      width: 200,
                    },
                    {
                      Header: 'Origem',
                      accessor: 'origin',
                      width: 200,
                    },
                    {
                      Header: 'Destino',
                      accessor: 'destiny',
                      width: 200,
                    },
                    {
                      Header: '',
                      accessor: 'edit',
                      width: 60,
                      Cell: props => {
                        return (
                          <IconButton
                            tooltipOverride={'Detalhes'}
                            onClick={() => {
                              setOpenModal(state => !state)
                              getExcerpts(props.row.original.id)
                              setExcerptId(props.row.original.id)
                              setExcerptDetails(true)
                              setModalStage('2')
                            }}
                            type='details'
                          />
                        )
                      },
                    },
                    {
                      Header: '',
                      accessor: 'clone',
                      Cell: props => {
                        return (
                          <IconButton
                            id={props.row.original.id}
                            type={'clone'}
                            iconOverride={'far fa-clone'}
                            tooltipOverride={'Clonar'}
                            onClick={() =>
                              onClone('flight-excerpt', props.row.original.id)
                            }
                          />
                        )
                      },
                      width: 20,
                    },
                    {
                      Header: '',
                      accessor: 'id',
                      width: 60,
                      Cell: props => {
                        return (
                          <IconButton
                            tooltipOverride={'Remover trecho'}
                            onClick={() => onRemove(props.row.original.id)}
                            type='trash'
                          />
                        )
                      },
                    },
                  ]}
                  tableProps={{
                    defaultPageSize: 10,
                    hasFooter: false,
                    hasPagination: true,
                    loading: loading,
                    resizable: true,
                  }}
                  tableStyle={{
                    fontSize: '13px',
                    cellPadding: '6px 4px',
                  }}
                  tableLabels={{
                    loadingText: 'Carregando...',
                    noDataText: 'Sem registros',
                    nextButtonText: 'Próximo',
                    previousButtonText: 'Anterior',
                    pageOfText: 'de',
                    pageSizeSelectText: 'registros',
                    pageText: 'Página',
                  }}
                />
              </PanelBody>
            </Panel>
          )}

          <DefaultForm
            callbackSubmit={data => onSubmit(data, true, true)}
            callbackReset={() => {}}
            callbackCancel={data => onSubmit(data, true, false)}
            submitLabel='Salvar e fechar'
            cancelLabel='Salvar'
            defaultValue={defaultValue}
            disabled={loading}
            loading={loading}
            inputs={inputs}
          />
        </Container>
      }
    />
  )
}

const Container = styled.div`
  position: relative;
`

const CustomModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const CustomModalContent = styled.div`
  background: white;
  width: 95%;
  padding: 8px;
  margin: auto auto;
  border-radius: 8px;
  box-shadow: 0px 0px 16px #333;
`

const CustomModalContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`
