import * as S from '../styles'
import ContactConversation from './ContactConversation'

export default function ContactList({
  auth,
  setActive,
  conversations,
  currentConversation,
  onChangeCurrentConversation,
  contacts,
  clientsData,
}) {
  return (
    <S.ContactListContainer>
      {conversations?.map((e, i) => {
        return (
          <ContactConversation
            contacts={contacts}
            setActive={setActive}
            key={i}
            auth={auth}
            conversation={e}
            clientData={clientsData?.find(c => c.phone === e.phoneNumber) || []}
            onChangeCurrentConversation={onChangeCurrentConversation}
            currentConversation={currentConversation}
          />
        )
      })}
    </S.ContactListContainer>
  )
}
