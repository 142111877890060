import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
// import Divisor from '~/components/Divisor'
import LoadingContainer from '~/components/LoadingContainer'
// import { ref } from 'yup'

export default function RefundDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigation = useNavigate()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    requestGetApi()
  }, [])

  const requestGetApi = async () => {
    setLoading(true)
    const response = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/IntegrationRequest/${location.state.id}`,
    )

    setData(response.data)
    setLoading(false)
  }

  const requestPutApi = async e => {
    setLoading(true)

    const formData = new FormData()

    formData.append('id', location.state.id)
    formData.append('idRequestingAgency', data.idRequestingAgency)

    if (e.nameRequestedAgency)
      formData.append('nameRequestedAgency', e.nameRequestedAgency)

    if (e.desc) formData.append('desc', e.desc)
    if (e.situation) formData.append('situation', e.situation.value)

    await axiosApis.put(
      `${process.env.REACT_APP_ENDPOINT_APIS}/IntegrationRequest/${location.state.id}`,
      formData,
    )

    toast.success('Sua solicitação de parceria foi atualizada com sucesso!')
    navigation(-1)
    setLoading(false)
  }

  const requestDeleteApi = async () => {
    setLoading(true)

    await axiosApis.delete(
      `${process.env.REACT_APP_ENDPOINT_APIS}/IntegrationRequest/${location.state.id}`,
    )

    toast.success('Sua solicitação de parceria foi excluída com sucesso!')
    navigation(-1)
    setLoading(false)
  }

  return (
    <Container>
      <PageTitle title='Detalhes da Solicitação de Parceria' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          {data && !loading && (
            <Container>
              <DefaultForm
                callbackSubmit={requestPutApi}
                callbackDelete={requestDeleteApi}
                defaultValue={data}
                disabled={loading}
                inputs={[
                  {
                    type: 'select',
                    name: 'situation',
                    label: 'Status',
                    placeholder: `${data.situation}`,
                    options: [
                      { label: 'Solicitado', value: 'Solicitado' },
                      { label: 'Aprovado', value: 'Aprovado' },
                      { label: 'Recusado', value: 'Recusado' },
                    ],
                    sm: 12,
                    lg: 6,
                  },
                  {
                    type: 'text',
                    name: 'nameRequestedAgency',
                    label:
                      'Qual Agência gostaria de Vincular as Experiências de Viagens?',
                    sm: 12,
                    lg: 6,
                  },
                  {
                    type: 'textarea',
                    name: 'desc',
                    label: 'Deseja Complementar ou justificar a solicitação?',
                    sm: 12,
                  },
                ]}
              />

              {/* <Divisor border /> */}

              {/* <RefundDocs docs={refund.refundDocs} /> */}

              <DefaultForm disabled inputs={[]} />
            </Container>
          )}

          {(!data || loading) && <LoadingContainer />}
        </PanelBody>
      </Panel>
    </Container>
  )
}
