import styled from 'styled-components'

export const UlAgencys = styled.ul`
  padding: 4px 8px;
  margin: 0;
`

export const LiAgencys = styled.ul`
  padding: 8px;
  margin: 0;
  background-color: #eee;
  border-radius: 8px;

  p {
    margin: 0 2px;
  }
`

export const DefaultUl = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  max-height: 80vh;
  overflow: auto;
`

export const DefaultLi = styled.li`
  background-color: #eaeaea;
  border-radius: 8px;
  padding: 8px;
  width: 32%;
  min-height: 180px;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};

  b {
    color: ${props => props.theme.colors.primary};
  }

  &:hover {
    background-color: #efefef;
  }

  @media screen and (max-width: 1080px) {
    width: 49%;
  }

  @media screen and (max-width: 920px) {
    width: 100%;
  }
`
export const DefaultLiCheck = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 4px;

  &.off {
    border: 1px solid #aaa;
  }

  &.on {
    border: none;
    background-color: ${props => props.theme.colors.primary};
  }
`

export const DefaultLiTitle = styled.b`
  color: ${props => props.theme.colors.primary};
`

export const DefaultLiLabel = styled.b`
  color: ${props => props.theme.colors.primary};
`

export const DefaultLiChilds = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
`

export const DefaultLiChildsItem = styled.li`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px;
  margin: 4px;
  max-width: 28%;
  min-width: 28%;
  /* width: 350px; */
  /* height: 150px; */
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  box-shadow: 0 0 8px #cacaca;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */

  b {
    color: ${props => props.theme.colors.primary};
  }
`

export const SimpleList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 16px;
  flex-wrap: wrap;
  gap: 4px;
`

export const SimpleListItem = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
  border-radius: 8px;
  text-align: left;
  width: 100%;
`
