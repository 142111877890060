import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import Divisor from '~/components/Divisor'
import LoadingContainer from '~/components/LoadingContainer'
import useAxios from '~/hooks/useAxios'
import useSalesReleasesForm from '../../hooks/useSalesReleasesForm'
import SalesReleaseFormSelectItem from '../SalesReleaseFormSelectItem'
import SalesReleasesFormInput from '../SalesReleasesFormInput'
import { Container } from './styles'

export default function SalesReleasesCustomerForm({ callback }) {
  const { apiClient } = useAxios()
  const [customers, setCustomers] = useState([])
  const [customersBackoffice, setCustomersBackoffice] = useState([])
  const [loading, setLoading] = useState('1')
  const [activeTab, setActiveTab] = useState('1')
  const { register, setValue, handleSubmit, reset, getValues } =
    useSalesReleasesForm()

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const resCustomersBackoffice = await apiClient.options(
          'salesrelease/customers/backoffice',
        )

        setCustomersBackoffice(resCustomersBackoffice.data)

        const resCustomers = await apiClient.options('salesrelease/customers')

        setCustomers(resCustomers.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    getSource()

    return () => {
      setActiveTab('1')
      setLoading(false)
      setCustomers([])
      setCustomersBackoffice([])
    }
  }, [])

  async function onSubmit(data) {
    let returnData = {}
    let splittedData

    if (activeTab === '1') {
      if (data?.length > 0) {
        returnData.value = data[0].value
        returnData.label = data[0].label
      }
    } else if (activeTab === '3') {
      if (data.code) returnData.value = returnData.label = getValues('code')
    } else {
      if (data?.length > 0) {
        splittedData = data[0].label.split('-@-')
        returnData.cpfCnpj = splittedData[3]
        returnData.email = splittedData[1]
        returnData.fullName = splittedData[0]
        returnData.phone = splittedData[2]
        returnData.label = splittedData[0]
      }
    }
    callback(returnData)
  }

  return (
    <Container>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={`${activeTab === '1' ? 'active' : ''}`}
            onClick={() => setActiveTab('1')}
          >
            Selecionar cliente Backoffice
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={`${activeTab === '2' ? 'active' : ''}`}
            onClick={() => setActiveTab('2')}
          >
            Selecionar cliente Tripmee
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={`${activeTab === '3' ? 'active' : ''}`}
            onClick={() => setActiveTab('3')}
          >
            Informar código cliente Backoffice
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={'1'}>
          {loading && <LoadingContainer height='300px' />}

          {!loading && customersBackoffice.length > 0 && (
            <SalesReleaseFormSelectItem
              items={customersBackoffice}
              renderItem={item => {
                return (
                  <>
                    <span>
                      {item.value} - {item.label}
                    </span>
                  </>
                )
              }}
              callback={data => {
                onSubmit(data)
              }}
              max={1}
            />
          )}
        </TabPane>

        <TabPane tabId={'2'}>
          {loading && <LoadingContainer height='300px' />}

          {!loading && customers.length > 0 && (
            <SalesReleaseFormSelectItem
              items={customers}
              renderItem={item => {
                return (
                  <>
                    {item.label && (
                      <>
                        <span>{item.label.split('-@-')[0]}</span>

                        <br />

                        {item.label.split('-@-').map((e, i) => {
                          if (i === 0) {
                            return null
                          }

                          return (
                            <span
                              key={i}
                              style={{ marginRight: '8px', opacity: '0.7' }}
                            >
                              {e}
                            </span>
                          )
                        })}
                      </>
                    )}
                  </>
                )
              }}
              callback={data => {
                onSubmit(data)
              }}
              max={1}
            />
          )}
        </TabPane>

        <TabPane tabId={'3'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={loading}>
              <Row>
                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'code'}
                  label={'Código do cliente Backoffice'}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                />
              </Row>

              <Divisor margin='8px' />

              <Row>
                <Col sm={12} md={12} lg={12} xl={12} className='submit-area'>
                  <Button
                    size={'sm'}
                    type='button'
                    color='none'
                    onClick={() => reset()}
                  >
                    Limpar
                  </Button>

                  <Button size={'sm'} type='submit' color='primary'>
                    Salvar
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </form>
        </TabPane>
      </TabContent>
    </Container>
  )
}
