import { Routes, Route } from 'react-router-dom'
import AuthLayout from '~/pages/__layouts__/AuthLayout'
import Register from '~/pages/Register'
import Error from '~/pages/Error'
import RecoveryPassword from '~/pages/RecoveryPassword'
import RequestRecoveryPassword from '~/pages/RequestRecoveryPassword'
import SignIn from '~/pages/Signin'
import BaseRoutes from '~/routes/base'

function UnloggedModule() {
  return (
    <Routes>
      {BaseRoutes()}

      <Route
        path='/forgot-password'
        exact
        element={
          <AuthLayout>
            <RequestRecoveryPassword />
          </AuthLayout>
        }
      />

      <Route
        path='/register'
        exact
        element={
          <AuthLayout>
            <Register />
          </AuthLayout>
        }
      />

      <Route
        path='/forgot-password-code'
        element={
          <AuthLayout>
            <RecoveryPassword />
          </AuthLayout>
        }
      />

      <Route
        path='/forgot-password'
        exact
        element={
          <AuthLayout>
            <RequestRecoveryPassword />
          </AuthLayout>
        }
      />

      <Route
        path='/register'
        exact
        element={
          <AuthLayout>
            <Register />
          </AuthLayout>
        }
      />

      <Route
        path='/error'
        element={
          <AuthLayout>
            <Error />
          </AuthLayout>
        }
      />

      <Route
        path='/'
        exact
        element={
          <AuthLayout>
            <SignIn />
          </AuthLayout>
        }
      />

      <Route
        path='*'
        element={
          <AuthLayout>
            <SignIn />
          </AuthLayout>
        }
      />
    </Routes>
  )
}

export default UnloggedModule
