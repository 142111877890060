import { useState, useEffect } from 'react'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import Table from '~/components/Table'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import PageTitle from '~/components/PageTitle'
import { getDateFormated } from '~/functions/Date'
import IconButton from '~/components/IconButton'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export default function ObservationList() {
  const { axiosApis } = GetAxios()
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  async function showTag(e) {
    navigate('/observation/details', { state: { observation: e } })
  }

  useEffect(() => {
    getSource()
  }, [])

  async function getSource() {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/observation`,
      )
      setData(res.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function handleSubmitDelete(id) {
    try {
      setLoading(true)

      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/observation/${id}`,
      )
      setLoading(false)

      toast.success(`Observação excluída com sucesso!`)
      getSource()
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível excluir a tag, por favor tente mais tarde.')
    }
  }

  const onDelete = id => {
    if (window.confirm('Você tem certeza que deseja excluir essa tag?')) {
      handleSubmitDelete(id)
    }
  }

  return (
    <div>
      <PageTitle title='Observação' to='/agency' />

      <Panel>
        <PanelHeader noButton>
          <LinkRegisterNewTraveler to='create'>
            Nova observação
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Observação',
                accessor: 'title',
                width: 180,
              },
              {
                Header: 'Cadastrado em',
                accessor: 'createdAt',
                width: 180,
                Cell: props => {
                  const date = getDateFormated(
                    props.row.original.createdAt,
                    'DD/MM/YYYY',
                  )

                  const time = getDateFormated(
                    props.row.original.createdAt,
                    'HH:mm',
                  )

                  return (
                    <span>
                      {date} <br />
                      {time}
                    </span>
                  )
                },
              },
              {
                Header: 'Atualizado em',
                accessor: 'updatedAt',
                width: 180,
                Cell: props => {
                  const date = getDateFormated(
                    props.row.original.updatedAt,
                    'DD/MM/YYYY',
                  )

                  const time = getDateFormated(
                    props.row.original.updatedAt,
                    'HH:mm',
                  )

                  return (
                    <span>
                      {date} <br />
                      {time}
                    </span>
                  )
                },
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.index}
                      type={'detail'}
                      onClick={() => showTag(props.row.original)}
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'remove',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.index}
                      type={'trash'}
                      onClick={() => onDelete(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}
