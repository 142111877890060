import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import DocumentTable from './components/DocumentTable'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody } from '~/components/Panel'
import { Container } from '~/styles/container'
import { LinkCreate } from '~/components/Link/styles'
import { toast } from 'react-toastify'
import DownloadFile from '~/functions/DownloadFile'

const Documents = () => {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [document, setDocument] = useState([])

  async function getDocumentTableData() {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs/getalldocuments/${travel.id}`,
      )

      setLoading(false)

      return setDocument(response.data)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  useEffect(() => {
    getDocumentTableData()
  }, [])

  async function onDelete(e) {
    try {
      setLoading(true)

      console.log(e)

      if (
        !window.confirm('Você tem certeza que deseja excluir esse documento?')
      ) {
        return setLoading(false)
      }

      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs/deletedoc/${e.id}/${e.type}`,
      )

      setLoading(false)

      toast.success(`Documento excluído com sucesso!`)

      await getDocumentTableData()
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível excluir o documento. Tente mais tarde.',
      )
    }
  }

  async function download(item) {
    try {
      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs/download/${item.token}/${item.type}`,
      )

      DownloadFile(
        `data:application/octet-stream;base64,${data.data}`,
        data.filename,
      )

      toast.info('Aguarde enquanto o documento é baixado...')

      return setTimeout(() => {
        window.close()
      }, 5000)
    } catch (error) {
      if (error.response.status === 404) {
        return toast.info('Documento não encontrado!')
      }

      if (error.response.status === 401) {
        return toast.info(
          'Link expirou, solicite um novo link ao seu consultor!',
        )
      }

      toast.info(error.response.data ?? 'Link inválido!')

      return setTimeout(() => {
        window.close()
      }, 5000)
    }
  }

  return (
    <Container>
      <Panel>
        <PanelBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LinkCreate to={'/travels/details/create-documents'}>
              Criar Documento
            </LinkCreate>
          </div>

          <DocumentTable
            getDocumentTableData={getDocumentTableData}
            documentList={document}
            onDelete={onDelete}
            loading={loading}
            download={download}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

export default Documents
