import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import PageTitle from '~/components/PageTitle'
import usePermissions from '~/hooks/usePermissions'

export default function AgentList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [agentsOriginal, setAgentsOriginal] = useState([])
  const [agents, setAgents] = useState([])

  const { isMaster, isAdmin } = usePermissions()

  useEffect(() => {
    loadSource()
  }, [])

  async function loadSource() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
      )

      setAgents(data)

      setAgentsOriginal(data)

      return setLoading(false)
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }

  async function onSearch(data) {
    if (data.agency || data.name || data.email) {
      setLoading(true)

      const newAgencys = agentsOriginal.filter(
        x =>
          (data.agency &&
            x.agency.name
              ?.toLowerCase()
              .includes(data.agency?.toLowerCase())) ||
          (data.name &&
            x.fullName?.toLowerCase().includes(data.name?.toLowerCase())) ||
          (data.email &&
            x.email?.toLowerCase().includes(data.email?.toLowerCase())),
      )

      setTimeout(() => {
        setAgents(newAgencys)

        setLoading(false)
      }, [500])
    }
  }

  async function onReset() {
    setLoading(true)

    setTimeout(() => {
      setAgents(agentsOriginal)

      setLoading(false)
    }, [500])
  }

  return (
    <Container>
      <PageTitle title='Gestão de Acessos' />

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSearch}
            callbackReset={onReset}
            submitLabel={'Pesquisar'}
            inputs={[
              isMaster
                ? {
                    type: 'text',
                    name: 'agency',
                    label: 'Agência',
                    placeholder: 'Agência',
                    sm: 12,
                    md: 6,
                    lg: 4,
                    xl: 3,
                  }
                : { sm: 12 },
              {
                type: 'text',
                name: 'name',
                label: 'Nome ou Sobrenome',
                placeholder: 'Nome ou Sobrenome',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'email',
                label: 'E-mail',
                placeholder: 'E-mail',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}>
          {(isMaster || isAdmin) && (
            <LinkRegisterNewTraveler to='create'>
              Novo Usuário
            </LinkRegisterNewTraveler>
          )}
        </PanelHeader>

        <PanelBody>
          <Table
            data={agents}
            getAgents={loadSource}
            loading={loading}
            isMaster={isMaster}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
