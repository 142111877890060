import styled from 'styled-components'

export const Container = styled.div`
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  position: relative;

  .row {
    margin: 0px;
    padding: 0px;

    [class^='col'] {
      margin: 0px;
      padding: 4px;
    }
  }

  p {
    margin-bottom: 4px;
  }
`

export const ContainerPd8 = styled.div`
  padding: 4px 8px;
`

export const ContainerPt8Pb4 = styled.div`
  padding: 8px 0 4px 0;
`

export const ContainerCentered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ContainerTransparent = styled.div`
  border-radius: 8px;
  background-color: ${props => props.bg};
  color: ${props => props.fg};
  font-weight: bold;
  border: none;
  margin-bottom: 4px;
  border: 1px solid #ccc;
  padding: 8px;
`

export const ContainerOverflowY = styled.div`
  max-height: 350px;
  overflow-y: auto;
`
