import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from './table'
import { GetAxios } from '~/services/api'

export default function ReminderList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [traveler, setTraveler] = useState([])

  useEffect(() => {
    getReminders()
  }, [])

  const getReminders = async data => {
    try {
      setLoading(true)
      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/reminder`,
        { params: data },
      )
      setLoading(false)
      setTraveler(response.data)
    } catch (err) {
      setLoading(false)
    }
  }

  const UpdateReminderDone = async id => {
    try {
      setLoading(true)

      await axiosApis.put(`/reminder/done/${id}`)
      await getReminders()

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  async function deleteReminder(e) {
    try {
      setLoading(true)
      if (window.confirm('Tem certeza que deseja excluir essa tarefa?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/reminder/${e}`,
        )
        setLoading(false)
        await getReminders()
      }
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <Container>
      <h1 className='page-header'>Tarefas</h1>

      <Panel>
        <PanelHeader title={'Filtros'} noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={getReminders}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Titulo',
                sm: 8,
                md: 8,
                lg: 8,
                xl: 8,
              },
              {
                type: 'date',
                name: 'shootingData',
                label: 'Data de envio',
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}>
          <LinkRegisterNewTraveler to='create'>
            Nova Tarefa
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            travelerList={traveler}
            loading={loading}
            deleteReminder={deleteReminder}
            isDone={UpdateReminderDone}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
