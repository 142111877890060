import { Form } from '@unform/web'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { ResetButton, SearchButton } from '~/components/Button/styles'
import Input from '~/components/Input'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import Select from '~/components/Select'
import TextArea from '~/components/TextArea'
import { getBase64 } from '~/functions/File'
import usePermissions from '~/hooks/usePermissions'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import SlideInput from '~/components/SlideInput'
import { Avatar, RightCol } from './styles'

export default function AgentCreate() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const { isMaster } = usePermissions()
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [agent, setAgent] = useState({})
  const [newAgency, setNewAgency] = useState('')
  const [newProfile, setNewProfile] = useState('')
  const [agencys, setAgencys] = useState([])
  const [roles, setRoles] = useState([])
  const [imagePreview, setImagePreview] = useState()
  const [ddi, setDdi] = useState({})

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency`,
        )

        setAgencys(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()
    getOptionsDDI()

    return () => {
      cancel = true
    }
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )

      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
        )

        setRoles(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function handleAvatar(image) {
    getBase64(image, setImagePreview)
  }

  const setInputField = (fieldName, value) => {
    setAgent({
      ...agent,
      [fieldName]: value,
    })
  }

  async function resetForm() {
    setAgent({})
  }

  async function submitForm() {
    try {
      setLoading(true)

      if (isMaster && !agent.agencyId) {
        setLoading(false)

        return toast.info('Escolha uma agência.')
      }

      if (!agent.role) {
        setLoading(false)

        return toast.info('Escolha um perfil.')
      }

      if (agent.avatar && agent.avatar.size > 3145728) {
        setLoading(false)

        return toast.info('Insira uma imagem com tamanho máximo de 3 MB')
      }

      const formData = new FormData()

      formData.append('familyName', agent.familyName)
      formData.append('fullName', agent.fullName)
      formData.append('email', agent.email.trim().toLowerCase())
      if (agent.phone) formData.append('phone', agent.phone ?? '')
      if (agent.cpf) formData.append('cpf', agent.cpf ?? '')
      formData.append('agencyId', isMaster ? agent.agencyId : 0)
      if (agent.role) formData.append('role', agent.role)
      if (agent.title) formData.append('title', agent.title)
      if (agent.bio) formData.append('bio', agent.bio)
      if (agent.viewSales) formData.append('viewSales', agent.viewSales)
      if (agent.ddiValue) formData.append('ddiValue', agent.ddiValue.value)
      if (agent.experiences)
        formData.append('experiences', agent.experiences ?? '')
      formData.append(
        'since',
        agent.since ? `01/01/${agent.since}` : '01/01/0001',
      )
      formData.append('avatar', agent.avatar)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
        formData,
      )

      toast.success('Consultor cadastrado(a) com sucesso!')

      setLoading(false)

      return navigate(-1)
    } catch (error) {
      console.log(error)

      if (error.response.data) {
        toast.info(error.response.data)
      }

      return setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle title={'Gestão de Usuários'} />

      <Panel>
        <PanelHeader noButton title={'Cadastrar Novo Usuário'} />

        <PanelBody>
          <Form ref={formRef} onReset={resetForm} onSubmit={submitForm}>
            <Row>
              {isMaster && (
                <Col sm={12}>
                  <Select
                    label={'Agência *'}
                    name={'agencyId'}
                    options={agencys}
                    onChange={e => {
                      setInputField('agencyId', Number(e.target.value.value))
                      setNewAgency(e.target.value.label)
                    }}
                    placeholder={newAgency == '' ? 'Selecione...' : newAgency}
                    maxLength={30}
                    disabled={loading}
                    isSearchable
                    required
                  />
                </Col>
              )}

              <Col sm={12}>
                <Select
                  label={'Perfil *'}
                  name={'role'}
                  options={roles}
                  onChange={e => {
                    setInputField('role', e.target.value.value)
                    setNewProfile(e.target.value.label)
                  }}
                  placeholder={newProfile == '' ? 'Selecione...' : newProfile}
                  disabled={loading}
                  maxLength={30}
                  isSearchable
                  required
                />
              </Col>

              <Col sm={12} md={6}>
                <Input
                  name={'familyName'}
                  label={'Nome de Preferência *'}
                  value={agent['familyName']}
                  onChange={e => setInputField(e.target.name, e.target.value)}
                  maxLength={50}
                  disabled={loading}
                  required
                />
              </Col>

              <Col sm={12} md={6}>
                <Input
                  name={'fullName'}
                  label={'Nome Completo *'}
                  value={agent['fullName']}
                  onChange={e => setInputField(e.target.name, e.target.value)}
                  maxLength={50}
                  disabled={loading}
                  required
                />
              </Col>

              <Col sm={12} md={4}>
                <Input
                  name={'email'}
                  label={'E-mail *'}
                  placeholder={'nome@exemplo.com'}
                  value={agent['email']}
                  onChange={e => setInputField(e.target.name, e.target.value)}
                  maxLength={50}
                  disabled={loading}
                  required
                />
              </Col>

              <Col sm={12} md={2}>
                <Select
                  name={'ddiValue'}
                  label={'DDI *'}
                  options={ddi}
                  value={agent['ddiValue']}
                  onChange={e => setInputField('ddiValue', e.target.value)}
                  placeholder={'Selecione...'}
                  disabled={loading}
                  maxLength={30}
                  isSearchable
                  required
                />
              </Col>

              <Col sm={12} md={4}>
                <Input
                  mask={'(99) 99999-9999'}
                  name={'phone'}
                  label={'Telefone *'}
                  placeholder={'telefone com (DDD)'}
                  value={agent['phone']}
                  onChange={e => setInputField(e.target.name, e.target.value)}
                  disabled={loading}
                  required
                />
              </Col>

              <Col sm={2} md={2}>
                <SlideInput
                  name={'viewSales'}
                  label={'Mostrar módulo de vendas?'}
                  value={agent['viewSales']}
                  onChange={e => setInputField(e.target.name, e.target.checked)}
                />
              </Col>
            </Row>

            <Row>
              <RightCol>
                <ResetButton type='reset'>Limpar</ResetButton>

                <SearchButton disabled={loading} type='submit'>
                  Salvar
                </SearchButton>
              </RightCol>
            </Row>
          </Form>
        </PanelBody>
      </Panel>
    </Container>
  )
}
