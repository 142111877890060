import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import context from '~/context/createContext'
// import { getDateFormated } from '~/functions/Date'
import DownloadFile from '~/functions/DownloadFile'
import { GetAxios } from '~/services/api'
import LoadingContainer from '~/components/LoadingContainer'

export default function InputTreatedFile({ label, value, local }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)

  const { modalData, setModalData } = useContext(context)

  const downloadDoc = async e => {
    try {
      DownloadFile(`data:application/octet-stream;base64,${e.file}`, e.fileName)

      toast.success('Arquivo baixado com sucesso!')
    } catch (error) {
      console.log(error)

      toast.info('Não foi possível baixar o arquivo.')
    }
  }

  const deleteFile = async data => {
    try {
      setLoading(true)
      const formData = new FormData()

      if (local == 'FluxoDeAtendimento') {
        formData.append('id', data.customerActivityId)
        formData.append('contentType', data.contentType)
        formData.append('docType', data.docType)
        formData.append('file', data.file)
        formData.append('fileName', data.fileName)

        await axiosApis.put(
          `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/updateContactFile`,
          formData,
        )

        const res = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/${value.customerActivityId}`,
        )

        // const agent = await axiosApis.get(
        //   `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/agent/${res.data.customer.agentId}`,
        // )

        setModalData({
          details: {
            // id: res.data.id,
            // customerId: res.data.customerId,
            // fullName: res.data.fullName,
            // contactDate: getDateFormated(res.data.contactDate, 'YYYY-MM-DD'),
            // contactTime: getDateFormated(res.data.contactDate, 'HH:mm'),
            // leadForm: res.data.contactForm,
            // nextAction: res.data.nextAction,
            // leadStatus: res.data.statusLead,
            // desc: res.data.desc,
            // agent: agent.data[0].label,
            ...modalData.details,
            data1: {
              file: res.data.file1,
              fileName: res.data.fileName1,
              docType: res.data.docType1,
              contentType: res.data.contentType1,
            },
            data2: {
              file: res.data.file2,
              fileName: res.data.fileName2,
              docType: res.data.docType2,
              contentType: res.data.contentType2,
            },
          },
        })
      }

      if (local == 'QuadroDeInscricoes') {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/deleteContactFile/${data.id}`,
        )

        const res = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/getfiles/${modalData.id}`,
        )

        setModalData({
          ...modalData,
          data1: res.data[0] ? { ...res.data[0] } : null,
          data2: res.data[1] ? { ...res.data[1] } : null,
        })
      }

      setLoading(false)
      toast.success('Arquivo deletado com sucesso!')
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <Container>
      <b style={{ fontWeight: '600' }}>{label}</b>

      <div style={{ paddingTop: '3px' }}>
        <Doc>
          {loading ? (
            <LoadingContainer />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
              // style={{
              //   display: 'flex',
              //   flexDirection: 'row',
              //   alignItems: 'center',
              //   overflow: 'hidden',
              // }}
              >
                <i className='fas fa-file' />

                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {value?.fileName.substring(0, 40)}
                </span>
              </div>

              <div>
                <button
                  type='button'
                  className='fas fa-download'
                  onClick={() => downloadDoc(value)}
                  style={{ border: 'none', paddingRight: '25px' }}
                />

                <button
                  type='button'
                  className='fas fa-trash'
                  onClick={() => deleteFile(value)}
                  style={{ border: 'none' }}
                />
              </div>
            </div>
          )}
        </Doc>
      </div>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 0 16px;
`

const Doc = styled.div`
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  color: #777;
  background-color: #eaeaea;
  cursor: pointer;

  & > i {
    margin-left: 8px;
  }

  div {
    i {
      margin-right: 8px;
    }
  }
`
