import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function RelationshipCreate({
  hideModal,
  onUpdate,
  tableProps,
  userData,
  getAllCustomerData,
}) {
  const { axiosApis } = GetAxios()
  const user = useSelector(state => state.auth.user)
  const [defaultValue, setDefaultValue] = useState({})
  const [selectDdi, setSelectDdi] = useState({})
  const [ddi, setDdi] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getOptionsDDI()
    setSelectDdi({
      label: '',
      value: '',
    })
  }, [])

  const getDdiFromNumber = number => {
    return (number ?? '').substring(0, 2)
  }

  const getCurrentNumberWithoutDdi = number => {
    return (number ?? '').substring(2)
  }

  useEffect(() => {
    if (
      userData?.phoneNumber?.length === 13 ||
      userData?.phoneNumber?.length === 12
    ) {
      let _ddi = getDdiFromNumber(userData?.phoneNumber)
      let phone = getCurrentNumberWithoutDdi(userData?.phoneNumber)

      if (_ddi == '55' && ddi.filter) {
        setDefaultValue({
          phone: phoneMask(phone),
          ddi: ddi.filter(d => d.value == _ddi)[0],
        })
      }
    }
  }, [ddi])

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      if (data.fullName) {
        formData.append('fullName', data.fullName)
      }
      if (data.email) {
        formData.append('email', data.email ?? '')
      }
      if (data.phone) {
        formData.append('phone', data.phone ?? '')
      }
      if (data.ddi) {
        formData.append('ddi', data.ddi.value ?? '')
      }
      if (data.cpfCnpj) {
        formData.append('cpfCnpj', data.cpfCnpj ?? '')
      }
      if (data.prospection) {
        formData.append('prospection', data.prospection)
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/insert`,
        formData,
      )

      getAllCustomerData(data.ddi.value + data.phone)
      toast.success('Cliente criado com sucesso!')

      hideModal()
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  const phoneMask = value => {
    if (!value) return ''
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '($1) $2')
    value = value.replace(/(\d)(\d{4})$/, '$1-$2')
    return value
  }

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  return (
    <Container>
      <Panel>
        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            defaultValue={defaultValue}
            loading={loading}
            inputs={[
              {
                empty: true,
                text: '<b>Dados pessoais</b>',
                sm: 12,
              },
              {
                type: 'text',
                name: 'fullName',
                label: 'Nome Completo',
                required: true,
                maxLength: 150,
                sm: 12,
                lg: 3,
              },
              {
                type: 'email',
                name: 'email',
                label: 'E-mail',
                disabled: false,
                sm: 12,
                lg: 3,
              },
              {
                type: 'select',
                name: 'ddi',
                label: 'DDI',
                options: ddi,
                placeholder: 'Selecione...',
                onChange: e => setSelectDdi(e.target.value),
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },
              selectDdi.value == '' || selectDdi.value == 55
                ? {
                    type: 'phone',
                    name: 'phone',
                    label: 'Telefone',
                    sm: 12,
                    lg: 2,
                    required: true,
                  }
                : {
                    type: 'text',
                    name: 'phone',
                    label: 'Telefone',
                    mask: '999 99999999',
                    sm: 12,
                    lg: 2,
                    required: true,
                  },
              {
                type: 'text',
                name: 'cpfCnpj',
                label: 'CPF/CNPJ',
                required: false,
                sm: 12,
                lg: 2,
              },
              {
                type: 'check',
                name: 'prospection',
                label: 'Lead',
                sm: 12,
                lg: 2,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
