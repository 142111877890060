import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Form } from 'reactstrap'
import styled from 'styled-components'
import { getBase64 } from '~/functions/File'
import { SpaceBetweenDiv } from '~/styles/divs'
import { CancelButton, DeleteButton, SaveButton } from '../Button/styles'
import Input from '../Input'
import FileResponsive from '../FileResponsive'
import DragAndDropFile from '../DragAndDropFile'
import InputIcon from '../InputIcon'

export default function ImageInput({
  onChange = () => {},
  label = 'Imagem',
  name = 'image',
  value,
  style,
  disabled,
  maxWidth,
}) {
  const ref = useRef()
  const [image, setImage] = useState()
  const [showInput, setShowInput] = useState(false)

  useEffect(() => {
    let cancel = false

    async function getImage() {
      if (cancel) return

      try {
        if (value && typeof value !== 'object') {
          const response = await fetch(`data:image/*;base64,${value}`)

          if (response.ok) {
            const blob = await response.blob()

            onChange({ target: { name: name, value: blob } })

            getBase64(blob, setImage)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    getImage()

    return () => {
      cancel = true
    }
  }, [value])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      if (!value) {
        setImage()

        return setShowInput(false)
      }

      return getBase64(value, setImage)
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [value])

  async function handleImage(e) {
    console.log('handleImage: ', e)
    if (e?.target.files.length > 0) {
      if (e.target.files[0].size > 3145728) {
        ref.current?.reset()

        setImage()
        onChange({ target: { name: name, value: undefined } })

        return toast.info('Insira uma imagem com tamanho máximo de 3 MB')
      }

      onChange({ target: { name: name, value: e.target.files[0] } })

      getBase64(e.target.files[0], setImage)

      setShowInput(false)

      return
    }

    if (e?.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files[0].size > 3145728) {
        ref.current?.reset()

        setImage()
        onChange({ dataTransfer: { name: name, value: undefined } })

        return toast.info('Insira uma imagem com tamanho máximo de 3 MB')
      }

      onChange({ target: { name: name, value: e.dataTransfer.files[0] } })

      getBase64(e.dataTransfer.files[0], setImage)

      setShowInput(false)
    }
  }

  async function handleRemoveImage() {
    ref.current?.reset()
    setImage()
    onChange({ target: { name: name, value: undefined } })
    setShowInput(false)
  }

  return (
    <Container>
      <Form innerRef={ref} onSubmit={undefined}>
        <SpaceBetweenDiv>
          <label style={{ margin: '2px 4px' }} htmlFor='file'>
            <b style={{ fontWeight: '600', fontSize: '12px' }}>
              {showInput ? 'Tamanho sugerido de 900x600' : label}
            </b>
          </label>
        </SpaceBetweenDiv>

        {showInput && !disabled && (
          <Input type='file' name={name} onChange={handleImage} />
        )}

        {!disabled && (
          <DivFlexCentered>
            {showInput && image && (
              <DeleteButton type='button' onClick={handleRemoveImage}>
                Remover imagem
              </DeleteButton>
            )}

            {showInput && (
              <CancelButton type='button' onClick={() => setShowInput(false)}>
                Cancelar
              </CancelButton>
            )}

            {!showInput && (
              <SaveButton type='button' onClick={() => setShowInput(true)}>
                Alterar
              </SaveButton>
            )}
          </DivFlexCentered>
        )}
        <FileResponsive
          src={image}
          width={style?.width ?? '50%'}
          maxWidth={maxWidth}
        >
          <DragAndDropFile isImage={true} name={name} onChange={handleImage}>
            <InputIcon />
          </DragAndDropFile>
        </FileResponsive>
      </Form>
    </Container>
  )
}

export const DivFlexCentered = styled.div`
  display: flex;
  justify-content: center;
`

export const DivRowCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
`
