import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'

export default function RequestRefundLink() {
  const { axiosApis } = GetAxios()
  const [message, setMessage] = useState()
  const [data, setData] = useState({
    link: '',
  })

  useEffect(() => {
    let cancel = false

    async function getData() {
      if (cancel) return

      try {
        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/refund/request-link`,
        )

        return setData({
          link: response.data,
        })
      } catch (error) {
        console.log(error)
      }
    }

    getData()

    return () => {
      cancel = true

      setData({
        link: '',
      })
    }
  }, [])

  async function copyToClipboard() {
    if (data.link) {
      navigator.clipboard.writeText(data.link)

      setMessage('Link copiado!')

      setTimeout(() => setMessage(undefined), 1000)
    }
  }

  return (
    <div>
      <DefaultForm
        defaultValue={data}
        inputs={[
          {
            type: 'text',
            name: 'link',
            label: '',
            disabled: true,
            sm: 12,
            searchButton: true,
            searchIcon: 'fas fa-copy',
            callbackSearch: copyToClipboard,
          },
        ]}
      />

      {message && (
        <b style={{ color: '#070', padding: '0 0 8px 8px' }}>{message}</b>
      )}
    </div>
  )
}
