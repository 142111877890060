import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
import useAxios from '~/hooks/useAxios'

export default function useHoldingConsolidatorHomeData({ filters }) {
  const { apiClient } = useAxios()
  const dashboardsPlaceholders = getDashboardPlaceholders(3)
  const [dashboards, setDashboards] = useState(dashboardsPlaceholders)
  const [ammountLast6Months, setAmmountLast6Months] = useState()
  const [newCustomersLast6Months, setNewCustomersLast6Months] = useState()
  const [newProposalsLast6Months, setNewProposalsLast6Months] = useState()
  const [newSalesLast6Months, setNewSalesLast6Months] = useState()

  useEffect(() => {
    async function getSource() {
      try {
        const params = {
          branchs: filters?.branchs ? filters.branchs.map(x => x.value) : [],
          start: filters?.start ?? undefined,
          end: filters?.end ?? undefined,
        }

        const resDashboards = await apiClient.get(
          '/holdingconsolidator/home/dashboards',
          { params },
        )

        setDashboards(resDashboards.data)

        const resNewCustomersLast6Months = await apiClient.get(
          '/holdingconsolidator/home/charts/new-customers-last-6-months',
          { params },
        )

        setNewCustomersLast6Months(resNewCustomersLast6Months.data)

        const resNewProposalsLast6Months = await apiClient.get(
          '/holdingconsolidator/home/charts/new-proposals-last-6-months',
          { params },
        )

        setNewProposalsLast6Months(resNewProposalsLast6Months.data)

        const resNewSalesLast6Months = await apiClient.get(
          '/holdingconsolidator/home/charts/new-sales-last-6-months',
          { params },
        )

        setNewSalesLast6Months(resNewSalesLast6Months.data)

        const resAmmountLast6Months = await apiClient.get(
          '/holdingconsolidator/home/charts/ammount-last-6-months',
          { params },
        )

        setAmmountLast6Months(resAmmountLast6Months.data)
      } catch (error) {
        toast.info('Dados não encontrados. Tente mais tarde!')
      }
    }

    getSource()

    return () => {
      setDashboards(dashboardsPlaceholders)
      setAmmountLast6Months(undefined)
      setNewCustomersLast6Months(undefined)
      setNewProposalsLast6Months(undefined)
      setNewSalesLast6Months(undefined)
    }
  }, [filters])

  return {
    dashboards,
    ammountLast6Months,
    newCustomersLast6Months,
    newProposalsLast6Months,
    newSalesLast6Months,
  }
}
