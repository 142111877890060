import { Group, Label, Radios } from './styles'

export default function RadioInput({
  label,
  name,
  value,
  onChange,
  options,
  required,
}) {
  return (
    <Group>
      <Label>{`${label} ${required ? '*' : ''}`}</Label>

      <Radios>
        {options.map((option, index) => (
          <label htmlFor={option.id} key={index}>
            <input
              id={option.id}
              name={name}
              value={option.value}
              checked={option.value === value}
              onChange={onChange}
              type='radio'
            />

            {option.label}
          </label>
        ))}
      </Radios>
    </Group>
  )
}
