import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import { useEffect, useState } from 'react'
import { getFile } from '~/functions/File'

export default function EditDocument() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [travelers, setTravelers] = useState([])
  const [fileDocument, setFileDocument] = useState(undefined)
  const [desc, setDesc] = useState()

  useEffect(() => {
    requestApiToGetTraveler()
  }, [])

  const requestApiToGetTraveler = async () => {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs/get/${location.state.e.token}`,
      )

      const newFiles = []

      const file = await getFile(
        response.data.data,
        response.data.filename,
        response.data.contentType,
      )

      newFiles.push(file)

      setTravelers({
        title: response.data.title,
        desc: response.data.desc,
        expiresIn: location.state.e.expiresIn,
        files: newFiles,
      })

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function onUpdate(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('token', location.state.e.token)
      formData.append('title', data.title)
      if (data.desc != null) {
        formData.append('desc', data.desc)
      }
      formData.append('expiresIn', data.expiresIn)

      if (data.files && data.files.length > 0) {
        formData.append('file', data.files[0])
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs`,
        formData,
      )

      toast.success('Documento editado com sucesso!')

      setLoading(false)
      navigate(-1)
    } catch (error) {
      setLoading(false)
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  return (
    <Container>
      <Panel>
        <PanelHeader title={'Editar Documento'} noButton />
        <PanelBody>
          <Container>
            <DefaultForm
              callbackSubmit={onUpdate}
              defaultValue={travelers}
              loading={loading}
              inputs={[
                {
                  type: 'text',
                  name: 'title',
                  label: 'Título',
                  required: true,
                  maxLength: 150,
                },
                {
                  type: 'text',
                  name: 'desc',
                  label: 'Descrição',
                  maxLength: 150,
                },
                {
                  type: 'date',
                  name: 'expiresIn',
                  label: 'Data de Expiração',
                  value: location.state.e.expiresIn,
                },
                {
                  type: 'inputFile',
                  name: 'files',
                  label: 'Anexos',
                  required: true,
                  max: 1,
                  sm: 12,
                },
              ]}
            />
          </Container>
        </PanelBody>
      </Panel>
    </Container>
  )
}
