import { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setContacts } from '~/store/actions/contactAction'
import { setConversations } from '~/store/actions/conversationAction'
import { setCurrentConversationAction } from '~/store/actions/currentConversationAction'
import { setAllMessages } from '~/store/actions/messageAction'
import {
  createContactDatabase,
  createContactsIndexes,
  createConversationsIndexes,
  createMessageDatabase,
  createMessagesIndexes,
  getContactsByChange,
  getContactsResumed,
  getConversationsResumed,
  getConversationsResumedByChange,
  getMediaByMessage,
  getMessages,
  getMessagesByChangeToCurrentConversation,
  getNewConversationsByRead,
} from '../../services'
import ChatSkeleton from '../../Skeleton'

export const ChatContext = createContext()

export default function ChatContextProvider({ children }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector(x => x.auth)
  const conversationsState = useSelector(
    state => state.conversationReducer.conversations,
  )
  const agencyPhone = `${
    auth.user.agencyPhoneDDI ?? ''
  }${auth.user.agencyPhone.replace(/[^0-9]/g, '')}`
  const remoteMessageDB = createMessageDatabase({
    tenant: auth.tenant,
    callback: () => navigate('/home'),
  })
  const remoteContactDB = createContactDatabase({
    tenant: auth.tenant,
    callback: () => navigate('/home'),
  })
  const [loading, setLoading] = useState(false)

  let timeoutMessage = undefined
  let timeoutContact = undefined

  useEffect(() => {
    async function getSource() {
      setLoading(true)

      await createConversationsIndexes({ remoteMessageDB })

      await createMessagesIndexes(remoteMessageDB)

      await createContactsIndexes(remoteMessageDB)

      await updateMessages()

      await updateContacts()

      setLoading(false)
    }

    if (remoteMessageDB && remoteContactDB) {
      getSource()

      const remoteMessageDBChanges = remoteMessageDB
        .changes({
          since: 'now',
          live: true,
          include_docs: false,
          selector: {
            tenant: auth.tenant,
          },
        })
        .on('change', change => {
          clearTimeout(timeoutMessage)

          timeoutMessage = setTimeout(() => {
            callbackMessageChange(change)
          }, 3000)
        })

      const remoteContactDBChanges = remoteContactDB
        .changes({
          since: 'now',
          live: true,
          include_docs: false,
          selector: {
            tenant: auth.tenant,
          },
        })
        .on('change', change => {
          clearTimeout(timeoutContact)

          timeoutContact = setTimeout(() => {
            callbackContactChange(change)
          }, 3000)
        })

      return () => {
        clearTimeout(timeoutMessage)
        clearTimeout(timeoutContact)
        remoteMessageDBChanges.cancel()
        remoteContactDBChanges.cancel()
        setAllMessages([], dispatch)
        setCurrentConversationAction(undefined, dispatch)
      }
    } else {
      return () => {
        setAllMessages([], dispatch)
        setCurrentConversationAction(undefined, dispatch)
      }
    }
  }, [])

  async function updateMessages() {
    const conversations = await getConversationsResumed({
      auth,
      remoteMessageDB,
      agencyPhone,
    })

    setConversations(conversations, dispatch)
  }

  async function updateContacts() {
    const contacts = await getContactsResumed({
      auth,
      remoteContactDB,
    })

    setContacts(contacts, dispatch)
  }

  async function callbackMessageChange(change) {
    if (change?.id) {
      const conversations = await getConversationsResumedByChange({
        auth,
        remoteMessageDB,
        agencyPhone,
      })

      setConversations(conversations, dispatch)

      const newMessages = await getMessagesByChangeToCurrentConversation({
        auth,
        remoteMessageDB,
        agencyPhone,
      })

      setAllMessages(newMessages, dispatch)
    }
  }

  async function getMessagesByCurrentConversation(currentConversation) {
    setCurrentConversationAction(currentConversation, dispatch)
    const messages = await getMessages({
      auth,
      remoteMessageDB,
      agencyPhone,
      currentConversation,
    })

    setAllMessages(messages, dispatch)
  }

  async function getMedia(message) {
    if (message && message.type !== 'text') {
      const media = await getMediaByMessage({
        auth,
        remoteMessageDB,
        message,
      })

      if (media) {
        return media
      }
    }

    return undefined
  }

  async function callbackContactChange(change) {
    if (change?.id && !loading) {
      const newContacts = await getContactsByChange({
        auth,
        remoteContactDB,
        id: change.id,
      })

      if (newContacts.changed) {
        setContacts(newContacts.contacts, dispatch)
      }
    }
  }

  async function readConversation(id) {
    const newConversations = await getNewConversationsByRead({
      id: id,
    })

    setConversations(newConversations, dispatch)
  }

  return (
    <ChatContext.Provider
      value={{ getMessagesByCurrentConversation, getMedia, readConversation }}
    >
      {loading && conversationsState?.length === 0 && <ChatSkeleton />}

      {(!loading || conversationsState?.length > 0) && children}
    </ChatContext.Provider>
  )
}
