import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import { useParams } from 'react-router-dom'
import LoadingContainer from '~/components/LoadingContainer'
import SurForm from './Form'
// import { useLocation } from 'react-router-dom'

export default function SurveyForm() {
  const { axiosApis } = GetAxios()
  const [eventSource, setEventSource] = useState()
  const params = useParams()

  useEffect(() => {
    getSource()
  }, [])

  const getSource = async () => {
    try {
      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/token/${params.token}`,
      )

      setEventSource(data)
    } catch (error) {
      console.log(error)
    }
  }

  const forms = {
    Survey: <SurForm />,
  }

  return <>{eventSource ? forms[`Survey`] : <LoadingContainer />}</>
}
