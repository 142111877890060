import { useForm } from 'react-hook-form'

export default function useSalesReleasesForm() {
  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    getValues,
    reset,
    formState: { isDirty, dirtyFields },
    getFieldState,
  } = useForm()

  return {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    getValues,
    reset,
    isDirty,
    dirtyFields,
    getFieldState,
  }
}
