export const proposalInputs = [
  {
    type: 'text',
    name: 'title',
    label: 'Título',
    required: true,
    sm: 12,
    lg: 12,
    xl: 12,
  },
  {
    type: 'city',
    name: 'origin',
    label: 'Origem',
    required: true,
    sm: 4,
    lg: 4,
    xl: 4,
  },
  {
    type: 'date',
    name: 'dateInitial',
    label: 'Data Inicial da viagem',
    required: true,
    sm: 4,
    lg: 4,
    xl: 4,
  },
  {
    type: 'date',
    name: 'dateFinal',
    label: 'Data Final da viagem',
    required: true,
    sm: 4,
    lg: 4,
    xl: 4,
  },
  {
    type: 'html',
    name: 'description',
    label: 'Sobre a viagem',
    maxLength: 15000,
    required: true,
    sm: 6,
    lg: 6,
    xl: 6,
  },
  {
    type: 'html',
    name: 'observation',
    label: 'Observações',
    sm: 6,
    lg: 6,
    xl: 6,
  },
  {
    type: 'image',
    name: 'avatarProposal',
    label: 'Foto',
    sm: 6,
    lg: 6,
    xl: 6,
  },
  {
    type: 'image',
    name: 'avatarProposal2',
    label: 'Foto 2',
    sm: 6,
    lg: 6,
    xl: 6,
  },
  {
    type: 'number',
    min: 0,
    name: 'fullValue',
    label: 'Valor',
    sm: 4,
    lg: 4,
    xl: 4,
  },
  {
    type: 'check',
    name: 'allValues',
    label: 'Mostrar todos os valores?',
    sm: 2,
    lg: 2,
    xl: 2,
  },
  {
    type: 'check',
    name: 'dolar',
    label: 'Mostrar todos os valores em dólar?',
    sm: 3,
    lg: 3,
    xl: 3,
  },
  {
    type: 'check',
    name: 'showTotalValue',
    label: 'Não mostrar o valor total?',
    sm: 3,
    lg: 3,
    xl: 3,
  },
]
