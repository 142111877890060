import IconButton from '~/components/IconButton'
import * as S from './styles'
import DownloadFile from '~/functions/DownloadFile'
import { Suspense, useEffect, useState } from 'react'
import VoiceMessage from '../../../VoiceMessage'
import LoadingContainer from '~/components/LoadingContainer'

export default function ConnectedMessage({ message, getMedia }) {
  const [media, setMedia] = useState(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadSource = async () => {
      try {
        setLoading(true)

        const data = await getMedia(message)

        if (data && data.media) {
          setMedia(data.media)
        }

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    loadSource()

    return () => {
      setMedia(undefined)
    }
  }, [message])

  async function onDowload(data) {
    DownloadFile(`data:${data.contentType};base64,${data.content}`, data.name)
  }

  return (
    <S.ConnectedMessageContainer>
      <S.ConnectedMessageContent>
        {loading && (
          <S.ConnectedMessageDocument>
            <LoadingContainer color='#ccc' width='100%' height='100px' />
          </S.ConnectedMessageDocument>
        )}

        {!loading && (
          <>
            {message?.type == 'image' && media && (
              <S.ConnectedMessageImage>
                <div>
                  <b>{media?.name}</b>

                  <div>
                    <i className='fas fa-image' />
                    &nbsp;
                    <span>Imagem</span>
                  </div>
                </div>

                <img
                  alt={'Imagem'}
                  src={`data:${media?.contentType};base64,${media?.content}`}
                />
              </S.ConnectedMessageImage>
            )}

            {message?.type == 'audio' && media && (
              <Suspense fallback={<p>Carregando áudio</p>}>
                <VoiceMessage content={media.content} />
              </Suspense>
            )}

            {message?.type == 'document' && media && (
              <S.ConnectedMessageDocument>
                <div>
                  <b>{media?.name}</b>

                  <div>
                    <i className='fas fa-file' />
                    &nbsp;
                    <span>Documento</span>
                  </div>
                </div>

                <IconButton
                  id={`input-file-download-file-${message?._id}-contact`}
                  type={'download'}
                  style={{ fontSize: '12px' }}
                  onClick={() => onDowload(media)}
                />
              </S.ConnectedMessageDocument>
            )}

            {message?.type == 'video' && media && (
              <S.ConnectedMessageVideo>
                <div>
                  <b>{media?.name}</b>

                  <div>
                    <i className='fas fa-video' />
                    &nbsp;
                    <span>Vídeo</span>
                  </div>
                </div>

                <video controls>
                  <source
                    src={`data:${media?.contentType};base64,${media?.content}`}
                  />
                </video>
              </S.ConnectedMessageVideo>
            )}

            {message?.type == 'sticker' && media && (
              <S.ConnectedMessageSticker>
                {/* <div>
                    <b>{media?.name}</b>

                    <div>
                      <i className='fas fa-sticky-note'></i>
                      &nbsp;
                      <span>Figurinha</span>
                    </div>
                  </div> */}

                <img
                  alt={'Figurinha'}
                  src={`data:${media?.contentType};base64,${media?.content}`}
                />
              </S.ConnectedMessageSticker>
            )}
          </>
        )}
      </S.ConnectedMessageContent>
    </S.ConnectedMessageContainer>
  )
}
