import styled from 'styled-components'

export default function Controls({ onHandleBack, onHandlePrint, nameAgency }) {
  return (
    <Container>
      <ButtonNoBackground onClick={onHandleBack}>CANCELAR</ButtonNoBackground>

      {nameAgency.name == 'Wee Travel' ? (
        <ButtonWee onClick={onHandlePrint}>FAZER DOWNLOAD</ButtonWee>
      ) : (
        <Button
          style={{ backgroundColor: `${nameAgency.primaryColor}` }}
          onClick={onHandlePrint}
        >
          FAZER DOWNLOAD
        </Button>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 21cm;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  margin: 0 auto;
  font-size: 0.6em;
`

const ButtonNoBackground = styled.button`
  color: #777;
  background-color: transparent;
  font-weight: 700;
  padding: 8px;
  border: none;
  border-radius: 8px;
  font-size: 0.9em;
`

const Button = styled.button`
  /* background-color: ${props => props.theme.colors.primary}; */
  color: #fff;
  font-weight: 700;
  padding: 8px;
  border: none;
  border-radius: 8px;
  font-size: 0.9em;
`

const ButtonWee = styled.button`
  background-color: #7f2ec2;
  color: #fff;
  font-weight: 700;
  padding: 8px;
  border: none;
  border-radius: 8px;
  font-size: 0.9em;
`
