import { useEffect, useState } from 'react'
import { Label, Group, InputV } from './styles'
import styled from 'styled-components'
import IconButton from '../IconButton'
import { newDownloadFile } from '~/functions/File'
import { toast } from 'react-toastify'
import InputMessage from '../InputMessage'

export default function InputFile({
  name,
  value,
  onChange,
  label,
  max = 1,
  ...rest
}) {
  const [files, setFiles] = useState([])

  useEffect(() => {
    if (value) {
      if (value.length !== files.length) {
        setFiles(value)
      }
    } else {
      setFiles([])
    }
  }, [value])

  useEffect(() => {
    onChange({
      target: {
        name: name,
        value: files,
      },
    })
  }, [files])

  async function onChangeInputFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3145728) {
        toast.info('Insira um arquivo com tamanho máximo de 3MB.')
      } else {
        const newFiles = [...files]

        newFiles.push(e.target.files[0])

        setFiles(newFiles)
      }
    } else {
      setFiles([])
    }
  }

  async function onDowload(e) {
    newDownloadFile(e, 'Baixando arquivo...')
  }

  async function onRemove(index) {
    const newFiles = [...files]

    newFiles.splice(index, 1)

    setFiles(newFiles)
  }

  return (
    <Group>
      {label && (
        <Label htmlFor={name}>
          {label}

          {max > 1 && <span>&nbsp;(máx. {max})</span>}
        </Label>
      )}

      {files.map((e, i) => (
        <FileContainer key={i}>
          {e.name}

          <div style={{ display: 'flex', gap: '8px' }}>
            <IconButton
              id={`input-file-download-file-${i}`}
              type={'download'}
              style={{ fontSize: '12px' }}
              onClick={() => onDowload(e)}
            />

            <IconButton
              id={`input-file-delete-file-${i}`}
              type={'trash'}
              tooltipOverride={'Remover'}
              style={{ fontSize: '12px' }}
              onClick={() => onRemove(i)}
            />
          </div>
        </FileContainer>
      ))}

      {files.length < max && (
        <>
          <InputV
            id={name}
            name={name}
            value={''}
            onChange={onChangeInputFile}
            className='form-control'
            type={'file'}
            {...rest}
          />
          <InputMessage />
        </>
      )}
    </Group>
  )
}

const FileContainer = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 8px;
`
