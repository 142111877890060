import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import RequestRefundLink from './components'
import { getDateFormated } from '~/functions/Date'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function RefundList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [refunds, setRefunds] = useState([])
  const [refundStatus, setRefundStatus] = useState([])
  const navigate = useNavigate()

  async function showDetails(e) {
    navigate('/refund/details', { state: { id: e } })
  }

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const response = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/refund/status`,
        )

        setRefundStatus(response.data)

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      await onSubmit()
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/refund`,
        {
          params: {
            name: data?.name ?? undefined,
            requestDate: data?.requestDate ?? undefined,
            locator: data?.locator ?? undefined,
            statusRefund: data?.statusRefund?.value ?? undefined,
          },
        },
      )

      setRefunds(response.data)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }
  return (
    <Container>
      <h1 className='page-header'>
        Pedidos de Reembolso
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Tela que disponibiliza as informações de Reembolso, o cadastro das informações é baseado em um link disponível na própria tela de Reembolso. Dessa forma centraliza as informações em um único local.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title='Link de Solicitação de Reembolso' />

        <PanelBody>
          <RequestRefundLink />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Nome do Viajante',
                placeholder: 'Nome do Viajante',
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
              },
              {
                type: 'text',
                name: 'locator',
                label: 'Localizador',
                placeholder: 'Localizador',
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
              },
              {
                type: 'date',
                name: 'requestDate',
                label: 'Data de Solicitação',
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
              },
              {
                type: 'select',
                name: 'statusRefund',
                label: 'Status do Reembolso',
                placeholder: 'Selecione...',
                isClearable: true,
                options: refundStatus,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton />

        <PanelBody>
          <Table
            data={refunds}
            loading={loading}
            columns={[
              {
                Header: 'Código',
                accessor: 'id',
                width: 100,
              },
              {
                Header: 'Nome do Viajante',
                accessor: 'name',
                width: 200,
              },
              {
                Header: 'Localizador',
                accessor: 'locator',
                width: 100,
              },
              {
                Header: 'Status da Solicitação',
                accessor: 'statusRefund',
                width: 250,
              },
              {
                Header: 'Data da Solicitação',
                accessor: 'requestDate',
                Cell: props => {
                  return getDateFormated(
                    props.row.original.requestDate,
                    'DD/MM/YYYY HH:mm',
                  )
                },
                width: 200,
              },
              {
                Header: 'Empresa/Corp',
                accessor: 'company',
                width: 200,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.index}
                      type={'details'}
                      onClick={() => showDetails(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
