const getPhoneFormated = text => {
  if (!text) {
    return null
  }

  const onlyNumbers = text.replace(/\D/g, '')

  if (onlyNumbers.length === 0) {
    return null
  }

  const numbersPadded = onlyNumbers.padStart(
    onlyNumbers.length < 11 ? 10 : 11,
    'x',
  )

  const chars = numbersPadded.split('')

  let aux = ''

  for (let i = 0; i < chars.length && i < 11; i++) {
    aux += chars[i] !== 'x' ? chars[i] : '_'
  }

  let res = ''

  for (let i = 0; i < aux.length; i++) {
    if (i === 0) {
      res += '('
    }

    if (i === 2) {
      res += ') '
    }

    if (i === aux.length - 4) {
      res += '-'
    }

    res += aux[i]
  }

  return res
}

export { getPhoneFormated }
