import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import DefaultForm from '~/components/DefaultForm'
import Divisor from '~/components/Divisor'
import { RegisterNewButton } from '~/components/Button/styles'
import { toast } from 'react-toastify'
import LoadingContainer from '~/components/LoadingContainer'
import { getDateFormated, getMoment } from '~/functions/Date'
import DivForHTML from '~/components/DivForHTML'

export default function Create({ toCreate, onClose }) {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [showMainForm, setShowMainForm] = useState(false)
  const [hotelLibrary, setHotelLibrary] = useState([])
  const [searchMessage, setSearchMessage] = useState(
    'Você pode pesquise por um hotel, buscar por um hotel existente na sua biblioteca ou preencha os dados manualmente.',
  )
  const [showHotelLibraryForm, setShowHotelLibraryForm] = useState(false)
  const [hotelSuggestions, setHotelSuggestions] = useState([])
  const [hotelSelected, setHotelSelected] = useState(true)
  const [defaultValue, setDefaultValue] = useState({
    checkIn: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
    timeCheckIn: '14:00',
    checkOut: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
    timeCheckOut: '12:00',
  })
  const [loading, setLoading] = useState(false)

  const handleStateHotelLibrary = () => setShowHotelLibraryForm(state => !state)

  async function onValidate(data) {
    const startDate = getMoment(`${data.checkIn} ${data.timeCheckIn}`)
    const endDate = getMoment(`${data.checkOut} ${data.timeCheckOut}`)

    if (endDate < startDate) {
      toast.info('O data de check-out precisa ser depois do check-in.')

      return true
    }

    return true
  }

  async function onSubmit(data) {
    const body = {
      ...data,
      travelId: travel.id,
    }

    await axiosApis.post(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelhotel`,
      body,
    )

    toast.success('Hotel cadastrado com sucesso.')

    onClose(true)
  }

  async function onSearchValidate(data) {
    if (!data.hotelQuery) {
      toast.info('Preencha o campo para pesquisar.')

      return false
    }

    return true
  }

  async function onSearchHotel(data) {
    const res = await axiosApis.options(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelhotel/search/${data.hotelQuery}`,
    )

    setHotelSuggestions(res.data)

    if (res.data.length === 0) {
      setSearchMessage('Nenhum resultado encontrado.')
    }
  }

  async function onSelectSuggestion(e) {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelhotel/search/details/${e.id}`,
      )

      setHotelSelected(true)

      setDefaultValue(state => ({
        ...state,
        ...res.data,
      }))

      setShowMainForm(true)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      setShowMainForm(true)
    }
  }

  const allTrue = () => {
    setShowMainForm(true)
    setShowHotelLibraryForm(true)
  }

  const allFalse = () => {
    setShowMainForm(false)
    setShowHotelLibraryForm(false)
  }

  async function onSearchHotelLibrary(data) {
    const response = await axiosApis.get(`/hotelLibrary`, {
      params: {
        name: data?.name,
        fullAddress: data?.fullAddress,
        city: data?.city?.value,
      },
    })

    setHotelLibrary(response.data)

    if (response.data.length === 0) {
      setSearchMessage('Nenhum resultado encontrado.')
    }
  }

  async function onSelectHotelLibrary(e) {
    try {
      setLoading(true)

      const response = await axiosApis.get(`/hotelLibrary/${e.id}`)

      setHotelSelected(true)

      setDefaultValue(state => ({
        ...state,
        ...response.data,
        locationName: response.data?.city?.label,
      }))

      setShowMainForm(true)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      setShowMainForm(true)
    }
  }

  return (
    <Container>
      {!loading && !showMainForm && !showHotelLibraryForm && (
        <>
          <DefaultForm
            onValidate={onSearchValidate}
            callbackSubmit={onSearchHotel}
            callbackCancel={allTrue}
            callbackReset={handleStateHotelLibrary}
            resetLabel={'Buscar hotel da biblioteca'}
            cancelLabel={'Preencher dados manualmente'}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'hotelQuery',
                label: 'Pesquise por um Hotel',
                placeholder: 'Pesquise por um Hotel...',
                subPlaceholder2: 'Valor',
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
            ]}
          />

          <Divisor border />

          <div>
            {hotelSuggestions.length === 0 && (
              <p>
                <b>{searchMessage}</b>
              </p>
            )}

            {hotelSuggestions.length > 0 && (
              <ul type='none'>
                {hotelSuggestions?.map((e, i) => {
                  return (
                    <li
                      key={i}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '8px',
                      }}
                    >
                      <div>
                        <b>{e.label}</b>

                        <DivForHTML text={e.sublabel} />
                      </div>

                      <div>
                        <RegisterNewButton
                          onClick={() => onSelectSuggestion(e)}
                        >
                          Escolher
                        </RegisterNewButton>
                      </div>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </>
      )}
      {!loading && !showMainForm && showHotelLibraryForm && (
        <div>
          <DefaultForm
            callbackSubmit={onSearchHotelLibrary}
            callbackReset={handleStateHotelLibrary}
            callbackCancel={allTrue}
            cancelLabel={'Preencher dados manualmente'}
            resetLabel={'Buscar hotel da integração'}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Nome do Hotel',
                sm: 12,
                lg: 4,
              },
              {
                type: 'text',
                name: 'fullAddress',
                label: 'Endereço',
                sm: 12,
                lg: 4,
              },
              {
                type: 'city',
                name: 'city',
                label: 'Cidade',
                placeholder: 'Pesquise por uma cidade...',
                sm: 12,
                lg: 4,
              },
            ]}
          />

          <Divisor border />

          <div>
            {hotelLibrary.length === 0 && (
              <p>
                <b>{searchMessage}</b>
              </p>
            )}

            {hotelLibrary.length > 0 && (
              <ul type='none'>
                {hotelLibrary?.map((e, i) => {
                  return (
                    <li
                      key={i}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '8px',
                      }}
                    >
                      <div>
                        <b>{e.name}</b>
                      </div>

                      <div>
                        <RegisterNewButton
                          onClick={() => onSelectHotelLibrary(e)}
                        >
                          {loading && <LoadingContainer />}
                          {!loading && 'Escolher'}
                        </RegisterNewButton>
                      </div>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
      )}

      {!loading && showMainForm && !showHotelLibraryForm && (
        <>
          {hotelSelected && defaultValue.staticMapUrl && (
            <>
              <p>
                <b>Preview do Mapa do Hotel</b>
              </p>

              <a
                href={defaultValue.staticMapUrl}
                target={'_blank'}
                rel='noreferrer'
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 10px',
                  marginBottom: '6px',
                  textDecoration: 'none',
                  color: '#1bc25b',
                  backgroundColor: '#eee',
                }}
              >
                Clique aqui
              </a>
            </>
          )}

          {hotelSelected && defaultValue.images && (
            <>
              <p>
                <b>Imagens do Hotel</b>
              </p>

              <ul
                type='none'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'auto',
                  width: '100%',
                  padding: 0,
                  margin: 0,
                }}
              >
                {defaultValue.images?.map((e, i) => {
                  return (
                    <>
                      {i < 5 && (
                        <li key={i}>
                          <img
                            src={e.mainUrl}
                            alt={`Image ${i + 1} do Hotel ${defaultValue.name}`}
                            style={{
                              height: '180px',
                            }}
                          />
                        </li>
                      )}
                    </>
                  )
                })}

                {defaultValue.images?.length >= 5 && (
                  <li>
                    <div
                      style={{
                        width: '180px',
                        height: '180px',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      + {defaultValue.images?.length - 5}
                    </div>
                  </li>
                )}
              </ul>
            </>
          )}

          <DefaultForm
            onValidate={onValidate}
            callbackSubmit={onSubmit}
            callbackCancel={allFalse}
            cancelLabel={'Voltar'}
            onErrorMessage={'Não foi possível cadastrar o Hotel.'}
            defaultValue={defaultValue}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Nome do Hotel',
                required: true,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                type: 'text',
                name: 'fullAddress',
                label: 'Endereço',
                required: true,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 6,
                maxLength: 200,
              },
              {
                type: 'text',
                name: 'locationName',
                label: 'Cidade',
                required: true,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 6,
              },
              {
                type: 'date',
                name: 'checkIn',
                label: 'Data de check-in',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 3,
              },
              {
                type: 'time',
                name: 'timeCheckIn',
                label: 'Hora de check-in',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 3,
              },
              {
                type: 'date',
                name: 'checkOut',
                label: 'Data de check-out',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 3,
              },
              {
                type: 'time',
                name: 'timeCheckOut',
                label: 'Hora de check-out',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 3,
              },
              {
                type: 'text',
                name: 'originPurchase',
                label: 'Fornecedor',
                maxLength: 30,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 6,
              },
              {
                type: 'text',
                name: 'locator',
                label: 'Código Reserva/Localizador',
                maxLength: 30,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'desc',
                label: 'Descrição do check-in',
                maxLength: 2000,
                rows: 5,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'descCheckOut',
                label: 'Descrição do check-out',
                maxLength: 2000,
                rows: 5,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'value',
                name: 'tips',
                label: 'Amenidades',
                placeholder: 'Descrição',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
            ]}
          />
        </>
      )}

      {!loading && showMainForm && showHotelLibraryForm && (
        <>
          <DefaultForm
            onValidate={onValidate}
            callbackSubmit={onSubmit}
            callbackCancel={allFalse}
            cancelLabel={'Voltar'}
            onErrorMessage={'Não foi possível cadastrar o Hotel.'}
            defaultValue={defaultValue}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Nome do Hotel',
                required: true,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                type: 'text',
                name: 'fullAddress',
                label: 'Endereço',
                required: true,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 6,
                maxLength: 200,
              },
              {
                type: 'text',
                name: 'locationName',
                label: 'Cidade',
                required: true,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 6,
              },
              {
                type: 'date',
                name: 'checkIn',
                label: 'Data de check-in',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 3,
              },
              {
                type: 'time',
                name: 'timeCheckIn',
                label: 'Hora de check-in',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 3,
              },
              {
                type: 'date',
                name: 'checkOut',
                label: 'Data de check-out',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 3,
              },
              {
                type: 'time',
                name: 'timeCheckOut',
                label: 'Hora de check-out',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 3,
              },
              {
                type: 'text',
                name: 'originPurchase',
                label: 'Fornecedor',
                maxLength: 30,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 6,
              },
              {
                type: 'text',
                name: 'locator',
                label: 'Código Reserva/Localizador',
                maxLength: 30,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'desc',
                label: 'Descrição do check-in',
                maxLength: 2000,
                rows: 5,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'descCheckOut',
                label: 'Descrição do check-out',
                maxLength: 2000,
                rows: 5,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'value',
                name: 'tips',
                label: 'Amenidades',
                placeholder: 'Descrição',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
            ]}
          />
        </>
      )}
      {loading && <LoadingContainer height='400px' size={'lg'} />}
    </Container>
  )
}
