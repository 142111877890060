import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'
import { getMoment } from '~/functions/Date'

PouchDB.plugin(PouchDBFind)

const createPouchDBLocal = ({ databaseName }) => new PouchDB(databaseName)

const createPouchDBConnection = ({ databaseName }) => {
  const databaseNameLowerCase = databaseName.toLowerCase()

  return new PouchDB(
    `${process.env.REACT_APP_CHATCENTER_COUCH_URL}/couch/${databaseNameLowerCase}`,
    {
      auth: {
        username: process.env.REACT_APP_USER_ACCESS_API_CHATCENTER,
        password: process.env.REACT_APP_PASSWORD_ACCESS,
      },
    },
  )
}

const createMessageDatabase = ({ tenant, callback }) => {
  if (!tenant) {
    return callback()
  }

  const databaseName = `message_${tenant}`

  return createPouchDBConnection({ databaseName })
}

const createContactDatabase = ({ tenant, callback }) => {
  if (!tenant) {
    return callback()
  }

  const databaseName = `contact_${tenant}`

  return createPouchDBConnection({ databaseName })
}

const createConversationsIndexes = async ({ remoteMessageDB }) => {
  remoteMessageDB.createIndex({
    index: {
      fields: ['tenant', 'from', 'to'],
    },
    name: 'conversation-tenant-from-to-index',
    type: 'json',
  })

  remoteMessageDB.createIndex({
    index: {
      fields: ['tenant', 'timestamp'],
    },
    name: 'conversation-tenant-timestamp-index',
    type: 'json',
  })

  remoteMessageDB.createIndex({
    index: {
      fields: ['timestamp'],
    },
    name: 'conversation-timestamp-index',
    type: 'json',
  })
}

const createContactsIndexes = async remoteContactDB => {
  remoteContactDB.createIndex({
    index: {
      fields: ['_id', 'tenant', 'photo', 'phone', 'name'],
    },
    name: 'contacts-index',
    type: 'json',
  })
}

const createMessagesIndexes = async remoteMessagesDB => {
  remoteMessagesDB.createIndex({
    index: {
      fields: [
        '_id',
        'from',
        'to',
        'sent',
        'read',
        'delivered',
        'type',
        'text.body',
        'timestamp',
      ],
    },
    name: 'messages-index',
    type: 'json',
  })
}

const getLocalStorageConversations = async () => {
  const root = localStorage.getItem('persist:tripmee-traveler:root')

  if (root) {
    const conversationReducer = JSON.parse(root).conversationReducer

    if (conversationReducer) {
      const conversations = JSON.parse(conversationReducer).conversations

      if (conversations) {
        return conversations
      }
    }
  }

  return []
}

const getLocalStorageCurrentConversation = async () => {
  const root = localStorage.getItem('persist:tripmee-traveler:root')

  if (root) {
    const currentConversationReducer =
      JSON.parse(root).currentConversationReducer

    if (currentConversationReducer) {
      const currentConversation = JSON.parse(
        currentConversationReducer,
      ).currentConversation

      if (currentConversation) {
        return currentConversation
      }
    }
  }

  return undefined
}

const getLocalStorageMessages = async () => {
  const root = localStorage.getItem('persist:tripmee-traveler:root')

  if (root) {
    const messageReducer = JSON.parse(root).messageReducer

    if (messageReducer) {
      const messages = JSON.parse(messageReducer).messages

      if (messages) {
        return messages
      }
    }
  }

  return []
}

const getLocalStorageContacts = async () => {
  const root = localStorage.getItem('persist:tripmee-traveler:root')

  if (root) {
    const contactReducer = JSON.parse(root).contactReducer

    if (contactReducer) {
      const contacts = JSON.parse(contactReducer).contacts

      if (contacts) {
        return contacts
      }
    }
  }

  return []
}

const getConversationsResumed = async ({
  auth,
  remoteMessageDB,
  agencyPhone,
}) => {
  let filterTimestamp = getMoment().add(-2, 'month').valueOf()

  const messages = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          tenant: auth.tenant,
        },
        {
          timestamp: {
            $gt: filterTimestamp,
          },
        },
      ],
    },
    fields: ['_id', 'from', 'to', 'timestamp'],
    limit: 1000,
    sort: [{ timestamp: 'desc' }],
  })

  const messagesSorted = messages.docs.sort((a, b) => b.timestamp - a.timestamp)

  const conversations = []

  messagesSorted.forEach(x => {
    if (
      x.from !== agencyPhone &&
      x.to === 'me' &&
      !conversations.find(y => y.phoneNumber === x.from)
    ) {
      conversations.push({
        ...x,
        phoneNumber: x.from,
      })
    }

    if (
      x.from === agencyPhone &&
      x.to !== 'me' &&
      x.to !== agencyPhone &&
      !conversations.find(y => y.phoneNumber === x.to)
    ) {
      conversations.push({
        ...x,
        phoneNumber: x.to,
      })
    }
  })

  const res = []

  for (let i = 0; i < conversations.length; i++) {
    const conversation = conversations[i]

    const details = await getMessageDetails({
      remoteMessageDB: remoteMessageDB,
      _id: conversation._id,
    })

    if (details) {
      if (details.from !== agencyPhone && details.to === 'me') {
        res.push({
          ...details,
          phoneNumber: details.from,
        })
      }

      if (
        details.from === agencyPhone &&
        details.to !== 'me' &&
        details.to !== agencyPhone
      ) {
        res.push({
          ...details,
          phoneNumber: details.to,
        })
      }
    }
  }

  return res
}

const getContactsResumed = async ({ auth, remoteContactDB }) => {
  const contacts = await remoteContactDB.find({
    selector: {
      $and: [
        {
          tenant: auth.tenant,
        },
        {
          $or: [
            {
              name: {
                $gt: null,
              },
            },
            {
              phone: {
                $gt: null,
              },
            },
          ],
        },
      ],
    },
    fields: ['_id', 'name', 'photo', 'phone'],
    limit: 200,
  })

  return contacts.docs
}

const getConversationsResumedByChange = async ({
  auth,
  remoteMessageDB,
  agencyPhone,
}) => {
  const conversations = await getLocalStorageConversations()

  const lastMessage = conversations.sort((a, b) => b.timestamp - a.timestamp)[0]

  let lastTimestamp = lastMessage.timestamp

  if (!lastTimestamp) {
    lastTimestamp = getMoment().add(-1, 'month').valueOf()
  }

  const messagesFromLastTimestamp = await getMessagesByFromTimestamp({
    auth,
    remoteMessageDB,
    lastTimestamp,
  })

  const messagesFromLastTimestampSorted = messagesFromLastTimestamp.sort(
    (a, b) => b.timestamp - a.timestamp,
  )

  const newConversationsFromLastTimestamp = []

  for (let i = 0; i < messagesFromLastTimestampSorted.length; i++) {
    let message = messagesFromLastTimestampSorted[i]

    if (
      message.from !== agencyPhone &&
      message.to === 'me' &&
      !newConversationsFromLastTimestamp.find(x => x.from === message.from)
    ) {
      newConversationsFromLastTimestamp.push({
        ...message,
        newMessage: true,
        phoneNumber: message.from,
      })
    }

    if (
      message.from === agencyPhone &&
      message.to !== 'me' &&
      message.to !== agencyPhone &&
      !newConversationsFromLastTimestamp.find(x => x.from === message.from)
    ) {
      newConversationsFromLastTimestamp.push({
        ...message,
        newMessage: true,
        phoneNumber: message.to,
      })
    }
  }

  const conversationsFiltered = conversations.filter(x => {
    if (
      !newConversationsFromLastTimestamp.find(
        y => y.phoneNumber === x.phoneNumber,
      )
    ) {
      return true
    }

    return false
  })

  const res = newConversationsFromLastTimestamp
    .concat(conversationsFiltered)
    .sort((a, b) => b.timestamp - a.timestamp)

  return res
}

const getMessages = async ({
  auth,
  remoteMessageDB,
  agencyPhone,
  currentConversation,
}) => {
  if (!currentConversation || !currentConversation.phoneNumber) {
    return []
  }

  const messages = await getMessagesByFrom({
    auth,
    remoteMessageDB,
    agencyPhone,
    phoneNumber: currentConversation.phoneNumber,
  })

  return messages
}

// TODO ajusta lógica para não duplicar mensagens
const getMessagesByChangeToCurrentConversation = async ({
  auth,
  remoteMessageDB,
  agencyPhone,
}) => {
  const currentConversation = await getLocalStorageCurrentConversation()

  if (currentConversation) {
    const messages = await getLocalStorageMessages()

    const lastMessage = messages.sort((a, b) => b.timestamp - a.timestamp)[0]

    let lastTimestamp = lastMessage?.timestamp

    if (!lastTimestamp) {
      lastTimestamp = getMoment().add(-1, 'week').valueOf()
    }

    if (lastMessage) {
      const newMessages = await getMessagesByFromTimestampAndFrom({
        auth,
        remoteMessageDB,
        lastTimestamp: lastTimestamp,
        agencyPhone: agencyPhone,
        from: currentConversation.phoneNumber,
      })

      // Verifica a mensagem já existe para não adicionar de novo
      if (
        newMessages.length > 0 &&
        messages.some(m => m._id == newMessages[0]._id)
      ) {
        return messages
      }

      return [...messages, ...newMessages]
    }

    return messages
  }

  return []
}

const getMessageDetails = async ({ remoteMessageDB, _id }) => {
  const details = await remoteMessageDB.find({
    selector: {
      _id: _id,
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
    ],
    limit: 1,
    sort: [{ timestamp: 'desc' }],
  })

  if (details.docs.length > 0) {
    return details.docs[0]
  }

  return undefined
}

const getMessagesByFromTimestamp = async ({
  auth,
  remoteMessageDB,
  lastTimestamp,
}) => {
  const res = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          tenant: auth.tenant,
        },
        {
          timestamp: {
            $gt: lastTimestamp,
          },
        },
      ],
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
    ],
    limit: 100,
  })

  return res.docs
}

const getMessagesByFromTimestampAndFrom = async ({
  auth,
  remoteMessageDB,
  lastTimestamp,
  agencyPhone,
  from,
}) => {
  const res = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          tenant: auth.tenant,
        },
        {
          timestamp: {
            $gt: lastTimestamp,
          },
        },
        {
          $or: [
            {
              $and: [
                {
                  from: from,
                },
                {
                  to: 'me',
                },
              ],
            },
            {
              $and: [
                {
                  from: agencyPhone,
                },
                {
                  to: from,
                },
              ],
            },
          ],
        },
      ],
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
    ],
    limit: 200,
    sort: [{ timestamp: 'desc' }],
  })

  return res.docs
}

const getMessagesByFrom = async ({
  auth,
  agencyPhone,
  remoteMessageDB,
  phoneNumber,
}) => {
  const resToMe = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          tenant: auth.tenant,
        },
        {
          from: phoneNumber,
        },
        {
          to: 'me',
        },
      ],
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
    ],
    limit: 50,
    sort: [{ timestamp: 'desc' }],
  })

  const resToOther = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          tenant: auth.tenant,
        },
        {
          from: agencyPhone,
        },
        {
          to: phoneNumber,
        },
      ],
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
    ],
    limit: 50,
    sort: [{ timestamp: 'desc' }],
  })

  const res = [...resToMe.docs, ...resToOther.docs].sort(
    (a, b) => b.timestamp - a.timestamp,
  )

  return res
}

const getMediaByMessage = async ({ auth, remoteMessageDB, message }) => {
  const res = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          tenant: auth.tenant,
        },
        {
          _id: message._id,
        },
      ],
    },
    limit: 1,
    fields: ['_id', 'media'],
  })

  if (res.docs.length > 0) {
    return res.docs[0]
  }

  return undefined
}

const getContactsByChange = async ({ auth, remoteContactDB, id }) => {
  const contacts = await getLocalStorageContacts()

  if (!contacts.find(x => x._id === id)) {
    const res = await remoteContactDB.find({
      selector: {
        $and: [
          {
            tenant: auth.tenant,
          },
          {
            _id: id,
          },
        ],
      },
      fields: ['_id', 'tenant', 'name', 'photo', 'phone'],
      limit: 1,
    })

    if (res.docs.length > 0) {
      const newContact = res.docs[0]

      return {
        changed: true,
        contacts: [...contacts, newContact],
      }
    }
  }

  return {
    changed: false,
    contacts: contacts,
  }
}

const getNewConversationsByRead = async ({ id }) => {
  const conversations = await getLocalStorageConversations()

  const newConversations = conversations.map(c => {
    if (c._id === id) {
      return {
        ...c,
        read: true,
      }
    }

    return c
  })

  return newConversations
}

export {
  createPouchDBLocal,
  createPouchDBConnection,
  createConversationsIndexes,
  createContactsIndexes,
  createMessagesIndexes,
  getConversationsResumed,
  getConversationsResumedByChange,
  getMessages,
  getMediaByMessage,
  getMessagesByChangeToCurrentConversation,
  getContactsResumed,
  createMessageDatabase,
  createContactDatabase,
  getContactsByChange,
  getNewConversationsByRead,
}
