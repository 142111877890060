import { useContext, useEffect, useState } from 'react'
import { rules } from '~/constants/holdingBackofficeRules'
import context from '~/context/createContext'

export default function useHoldingMenus() {
  const [menus, setMenus] = useState([])
  const { handleProfile, showSalesStats } = useContext(context)

  useEffect(() => {
    async function updateMenus() {
      let newMenus = []

      newMenus.push(rules.home.menu)
      if (handleProfile) rules.home.menu.title = 'Resumo'

      newMenus.push(rules.customers.menu)

      if (handleProfile) {
        if (showSalesStats) {
          newMenus.push(rules.sales.menu)
        }
      } else {
        newMenus.push(rules.sales.menu)
      }

      newMenus.push(rules.proposals.menu)

      setMenus(newMenus)
    }

    updateMenus()
  }, [handleProfile])

  return { menus }
}
