import {
  SET_CURRENT_CONVERSATION,
  SET_DATA,
  SET_STARTED_ONCE,
  SET_CURRENT_CHAT_DATA,
  SET_CURRENT_CONTACTS,
  SET_CLIENTS_DATA,
  SET_CUSTOMER,
} from '../actions/chatAction'

const initialState = {
  chat: [],
  conversation: [],
  currentConversation: [],
  startedOnce: false,
  currentChatData: null,
  currentContacts: [],
  clientsData: [],
  customer: null,
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STARTED_ONCE:
      return {
        ...state,
        startedOnce: true,
      }
    case SET_DATA:
      return {
        ...state,
        chat: action.data,
      }
    case SET_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: action.data,
      }
    case SET_CURRENT_CHAT_DATA:
      return {
        ...state,
        currentChatData: action.data,
      }
    case SET_CURRENT_CONTACTS:
      return {
        ...state,
        currentContacts: action.data,
      }
    case SET_CLIENTS_DATA:
      return {
        ...state,
        clientsData: action.data,
      }
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.data,
      }
    default:
      return state
  }
}

export default chatReducer
