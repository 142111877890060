import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
// import LoadingContainer from '~/components/LoadingContainer'
import { toast } from 'react-toastify'

export default function InstitutionDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [institution, setInstitution] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    requestApiGetId()
  }, [])

  async function requestApiGetId() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/institution/${location.state.id}`,
      )

      setInstitution(data)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      if (data.id) formData.append('id', location.state.id)
      if (data.image) formData.append('image', data.image)
      if (data.name) formData.append('name', data.name)
      if (data.address) formData.append('address', data.address)
      if (data.phone) formData.append('phone', data.phone)
      if (data.email) formData.append('email', data.email)
      if (data.site) formData.append('site', data.site)
      if (data.type) formData.append('type', data.type)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/institution`,
        formData,
      )

      toast.success('Grupo cadastrado com sucesso!')
      navigate(-1)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function onDelete() {
    try {
      setLoading(true)

      if (window.confirm('Deseja realmente remover o grupo?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/institution/${location.state.id}`,
        )

        navigate(-1)

        return setLoading(false)
      }

      return setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle title={`${institution ? institution.name : 'Grupo'}`} />

      <Panel>
        <PanelHeader noButton></PanelHeader>

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackDelete={onDelete}
            disabled={loading}
            defaultValue={institution}
            loading={loading}
            inputs={[
              {
                type: 'image',
                name: 'image',
                label: 'Logo/Capa do Grupo',
                require: true,
                maxWidth: 200,
                sm: 12,
                lg: 12,
              },
              {
                type: 'text',
                name: 'name',
                label: 'Nome do Grupo',
                placeholder: 'Nome do Grupo',
                require: true,
                sm: 12,
                lg: 12,
              },
              // {
              //   type: 'text',
              //   name: 'address',
              //   label: 'Endereço',
              //   placeholder: 'Endereço',
              //   require: true,
              //   sm: 12,
              //   lg: 6,
              // },
              {
                type: 'text',
                name: 'site',
                label: 'Site',
                placeholder: 'Site',
                require: true,
                sm: 12,
                lg: 12,
              },
              {
                type: 'text',
                name: 'phone',
                label: 'Telefone',
                placeholder: 'telefone',
                mask: '(99) 99999-9999',
                require: true,
                sm: 12,
                lg: 6,
              },
              {
                type: 'email',
                name: 'email',
                label: 'E-mail',
                placeholder: 'E-mail',
                require: true,
                sm: 12,
                lg: 6,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
