import { useState, useEffect } from 'react'
import { GetAxios } from '~/services/api'
import styled from 'styled-components'
import DivForHTML from '~/components/DivForHTML'
import { getDateFormated } from '~/functions/Date'

export default function DestinysItem({ destiny, nameAgency }) {
  const { axiosApis } = GetAxios()
  const [image, setImage] = useState()

  const destinyDesc = destiny.desc != null ? destiny.desc : ''

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const res = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/image/destiny/${destiny.id}`,
        )

        setImage(res.data)
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <Item>
      <div>
        {nameAgency.name == 'Wee Travel' ? (
          <IconWee className='fas fa-map-marker-alt' />
        ) : (
          <Icon
            className='fas fa-map-marker-alt'
            style={{ color: `${nameAgency.primaryColor}` }}
          />
        )}
      </div>

      <div style={{ width: '100%' }}>
        <ItemHeader>
          <b>{destiny.city}</b>
        </ItemHeader>
        {destiny.start && (
          <>
            <span>
              Início:&nbsp;<b>{getDateFormated(destiny.start)}</b>
            </span>
            &nbsp; &nbsp;
            <span>
              Fim:&nbsp;<b>{getDateFormated(destiny.end)}</b>
            </span>
            <br></br>
            <br></br>
          </>
        )}
        <ItemContent>
          <DivForHTML
            imgSrc={image ? `data:image/*;base64,${image}` : undefined}
            text={destinyDesc}
          />
        </ItemContent>
        {destiny.entryAndHabit && destiny.entryAndHabit !== '<p><br></p>' && (
          <ItemTips>
            <div
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '22px',
              }}
            >
              <b>Entrada no país e costumes </b>
            </div>

            <DivForHTML text={destiny.entryAndHabit} />
          </ItemTips>
        )}
        {destiny.tips && destiny.tips !== '<p><br></p>' && (
          <ItemEntryAndHabit>
            <div
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '22px',
              }}
            >
              <b>Dicas e informações úteis </b>
            </div>
            <DivForHTML text={destiny.tips} />
          </ItemEntryAndHabit>
        )}
      </div>
    </Item>
  )
}

const IconWee = styled.i`
  width: 40px;
  height: 40px;
  font-size: 28px;
  text-align: center;
  color: #d54a26;
`

const Icon = styled.i`
  width: 40px;
  height: 40px;
  font-size: 28px;
  text-align: center;
  /* color: ${props => props.theme.colors.primary}; */
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const ItemHeader = styled.div`
  display: flex;
  font-size: 24px;
  color: #777777;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: flex-start;
`

const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5px;
`

const ItemTips = styled.div`
  margin-bottom: 10px;
`

const ItemEntryAndHabit = styled.div`
  margin-bottom: 20px;
`
