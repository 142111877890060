import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function AgencyCreate() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [data, setData] = useState([])

  useEffect(() => {
    requestApiOptions()
  }, [])

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('name', data.name)
      formData.append('adminName', data.adminName)
      formData.append('country', data.country.value)
      formData.append('lat', data.lat)
      formData.append('lng', data.lng)
      formData.append('iso2', data.iso2)
      formData.append('iso3', data.iso3)
      formData.append('population', data.population)
      formData.append('capital', data.capital.value)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/city/countries`,
        formData,
      )

      return navigate(-1)
    } catch (error) {
      console.log(error)
      toast.info('Não foi possível adicionar uma nova cidade.')
    }
  }

  async function requestApiOptions() {
    try {
      const { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/city/countries`,
      )

      setData(data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      <PageTitle title={'Adicionar Cidade'} />

      <Panel>
        <PanelHeader noButton title={'Nova cidade'} />

        <PanelBody>
          <DefaultForm
            callbackReset={() => {}}
            callbackSubmit={onSubmit}
            inputs={[
              {
                type: 'text',
                label: 'Cidade',
                name: 'name',
                width: 240,
              },
              {
                type: 'text',
                label: 'Estado',
                name: 'adminName',
                width: 120,
              },
              {
                type: 'select',
                label: 'País',
                name: 'country',
                options: data,
                width: 120,
              },
              {
                type: 'select',
                label: 'Tipo',
                name: 'capital',
                options: [
                  {
                    label: 'Capital',
                    value: 'admin',
                  },
                  {
                    label: 'Cidade Grande',
                    value: 'primary',
                  },
                  {
                    label: 'Cidade Pequena',
                    value: 'minor',
                  },
                ],
                width: 120,
              },
              {
                type: 'text',
                label: 'Latitude',
                name: 'lat',
                width: 120,
              },
              {
                type: 'text',
                label: 'Longitude',
                name: 'lng',
                width: 120,
              },
              {
                type: 'text',
                label: 'ISO2',
                name: 'iso2',
                width: 120,
              },
              {
                type: 'text',
                label: 'ISO3',
                name: 'iso3',
                width: 120,
              },
              {
                type: 'text',
                label: 'População',
                name: 'population',
                width: 120,
              },
            ]}
          />

          {/* <Col sm={12} xl={4}>
              <b
                style={{
                  margin: '2px 4px',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                Preview do App
              </b>

              <PreviewAppTheme
                image={image}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              />
            </Col> */}
        </PanelBody>
      </Panel>
    </Container>
  )
}
