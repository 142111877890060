import DefaultForm from '../DefaultForm'
import PageTitle from '../PageTitle'
import { Panel, PanelBody } from '../Panel'
import { Container } from '~/styles/container'

export default function DefaultFormComponent({
  onValidate = null,
  onSubmit = null,
  onReset = null,
  inputs = [],
  title = '',
  h = 'h5',
  submitLabel,
  resetLabel,
  loading,
}) {
  return (
    <Container>
      <Panel>
        <div style={{ padding: '8px' }}>
          <PageTitle head={h} title={title} />
          <PanelBody>
            <DefaultForm
              onValidate={onValidate}
              callbackSubmit={onSubmit}
              callbackReset={onReset}
              inputs={inputs}
              submitLabel={submitLabel}
              resetLabel={resetLabel}
              loading={loading}
            />
          </PanelBody>
        </div>
      </Panel>
    </Container>
  )
}
