// import { Form } from '@unform/web'
// import { useEffect, useRef, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { toast } from 'react-toastify'
// import { SaveButton } from '~/components/Button/styles'
// import Select from '~/components/Select'
// import { GetAxios } from '~/services/api'
// import { DivFlexRight } from '~/styles/divs'
import { Col, Row } from 'reactstrap'
import { EditButton } from '~/components/Button/styles'
import { SpaceBetweenDiv } from '~/styles/divs'
import { TravelDetailBlock } from './styles'

function TravelerBlock({ travel, reload }) {
  // const formRef = useRef(null)
  // const { axiosApis } = GetAxios()
  // const navigate = useNavigate()
  // const [loading, setLoading] = useState(false)
  // const [travelers, setTravelers] = useState([])
  // const [selectedTraveler, setSelectedTraveler] = useState()
  // const [isEditing, setIsEditing] = useState(false)

  // useEffect(() => {
  //   getTraveler()
  // }, [])

  // async function getTraveler() {
  //   try {
  //     setLoading(true)

  //     const { data: dataTravelers } = await axiosApis.get(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/main-traveler-in-travel/${travel.id}`,
  //     )

  //     setTravelers(dataTravelers)

  //     setLoading(false)
  //   } catch (error) {
  //     console.log(error)

  //     setLoading(false)

  //     return navigate('/travels')
  //   }
  // }

  // async function handleSubmitEdit() {
  //   try {
  //     if (!selectedTraveler) {
  //       return toast.info('Selecione um novo viajante!')
  //     }

  //     setLoading(true)

  //     await axiosApis.put(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/travel/${travel.id}/traveler/${selectedTraveler.value}`,
  //     )

  //     toast.success('Viajante editado com sucesso!')

  //     setSelectedTraveler(undefined)

  //     setIsEditing(false)

  //     setLoading(false)

  //     navigate('/travels')
  //     navigate('/travels/details', { state: { id: travel.id } })
  //   } catch (error) {
  //     setLoading(false)

  //     return toast.error(
  //       'Não foi possível editar o viajante, por favor tente mais tarde.',
  //     )
  //   }
  // }

  return (
    <div style={{ padding: '0 5px', minWidth: '30%' }}>
      <TravelDetailBlock style={{ fontSize: '14px' }}>
        <SpaceBetweenDiv>
          {travel.travelers[0] ? (
            <h5>Viajante principal</h5>
          ) : (
            <h5>Viagem sem viajante principal</h5>
          )}

          <EditButton
            style={{ fontSize: '12px' }}
            // onClick={() => setIsEditing(true)}
            onClick={() => reload(travel.id)}
            type='button'
          >
            <i className='fas fa-redo'></i>
          </EditButton>

          {/* {isEditing && travel.travelers[0] && (
            <EditButton
              style={{ fontSize: '12px' }}
              onClick={() => setIsEditing(false)}
              type='button'
            >
              Cancelar
            </EditButton>
          )} */}
        </SpaceBetweenDiv>

        {travel.travelers[0] && (
          <Row>
            <Col sm={12}>
              <span>
                <b>Nome: </b> <br />
                {travel.travelers[0]?.fullName}
              </span>
            </Col>

            {travel.travelers[0]?.phone && (
              <Col sm={12}>
                <span>
                  <b>Telefone: </b> <br />
                  {travel.travelers[0]?.phone}
                </span>
              </Col>
            )}

            {travel.travelers[0]?.email && (
              <Col sm={12}>
                <span
                  style={{
                    wordWrap: 'break-word',
                  }}
                >
                  <b>E-mail: </b> <br />
                  {travel.travelers[0]?.email}
                </span>
              </Col>
            )}

            <Col sm={12}>
              <span
                style={{
                  wordWrap: 'break-word',
                }}
              >
                <b>Ultimo acesso ao app: </b> <br />
                {travel.travelers[0]?.lastAccess ||
                  'Viajante ainda não acessou o app.'}
              </span>
            </Col>
          </Row>
        )}

        {/* {isEditing && travel.travelers[0] && (
          <Form
            ref={formRef}
            onSubmit={handleSubmitEdit}
            style={{ width: '100%' }}
          >
            <Row>
              <Col sm={12}>
                <Select
                  label={'Nome'}
                  name={'traveler'}
                  options={travelers}
                  onChange={e => setSelectedTraveler(e.target.value)}
                  placeholder={'Selecione...'}
                  disabled={loading}
                  value={selectedTraveler}
                  isSearchable
                />
              </Col>

              <Col sm={12}>
                <DivFlexRight>
                  <SaveButton disabled={loading} type='submit'>
                    Salvar
                  </SaveButton>
                </DivFlexRight>
              </Col>
            </Row>
          </Form>
        )} */}
      </TravelDetailBlock>
    </div>
  )
}

export default TravelerBlock
