import { toast } from 'react-toastify'

const toastWrapper = async ({
  error,
  onSuccessMessage,
  onErrorMessage,
  onNotFoundMessage,
  onBadRequestMessage,
}) => {
  if (!error) {
    return toast.success(onSuccessMessage)
  }

  if (error.response?.status === 404) {
    return toast.info(onNotFoundMessage ?? error.response?.data)
  }

  if (error.response?.status === 400) {
    return toast.info(onBadRequestMessage ?? error.response?.data)
  }

  if (error.response?.status === 500) {
    return toast.error(onErrorMessage ?? error.response?.data)
  }

  return toast.error(
    `${error.response?.status}: ${error.response?.statusText} ${
      error.response?.data ?? ''
    }`,
  )
}

export { toastWrapper }
