// import { ellipsis } from 'polished'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import DownloadFile from '~/functions/DownloadFile'

export default function RefundDocs({ docs = [] }) {
  async function downloadDoc(e) {
    try {
      DownloadFile(`data:application/octet-stream;base64,${e.data}`, e.filename)

      toast.success('Arquivo baixado com sucesso!')
    } catch (error) {
      console.log(error)

      toast.info('Não foi possível baixar o arquivo.')
    }
  }

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <b style={{ padding: '2px 4px', fontWeight: '600' }}>Anexos</b>
        </Col>

        {docs.map((e, i) => (
          <Col key={i} sm={12} lg={6}>
            <Doc onClick={() => downloadDoc(e)}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <i className='fas fa-file' />

                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {e.filename}
                </span>
              </div>

              <i className='fas fa-download' />
            </Doc>
          </Col>
        ))}

        {docs.length === 0 && <span>Nenhum documento anexado!</span>}
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 0 16px;
`

const Doc = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  color: #777;
  background-color: #eaeaea;
  cursor: pointer;

  & > i {
    margin-left: 8px;
  }

  div {
    i {
      margin-right: 8px;
    }
  }
`
