import { useState, useEffect, useContext } from 'react'
import context from '~/context/createContext'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import { useLocation } from 'react-router-dom'
import IconButton from '~/components/IconButton'

export default function ReportAniversariantes() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [agents, setAgents] = useState([])
  // const [agentsOriginal, setAgentsOriginal] = useState([])
  // const [filters, setFilters] = useState({})
  const [pageFilters, setPageFilters] = useState({})
  const location = useLocation()

  const { globalFilters, resetGlobalFilters } = useContext(context)

  useEffect(() => {
    if (location.state?.preset) {
      setPageFilters({
        dateStart: globalFilters.dateStart,
        dateEnd: globalFilters.dateEnd,
      })
    } else {
      resetGlobalFilters()
      setPageFilters({
        dateStart: undefined,
        dateEnd: undefined,
      })
    }
  }, [globalFilters])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      if (location.state?.preset) {
        setPageFilters({
          dateStart: globalFilters.dateStart,
          dateEnd: globalFilters.dateEnd,
        })
        await onSubmit({
          dateStart: globalFilters.dateStart,
          dateEnd: globalFilters.dateEnd,
        })
      } else {
        resetGlobalFilters()
        setPageFilters({
          dateStart: undefined,
          dateEnd: undefined,
        })
        await onSubmit({
          dateStart: undefined,
          dateEnd: undefined,
        })
      }
    }
    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    setLoading(true)

    const response = await axiosApis.get(`/quotation/report/birthday`, {
      params: {
        ...data,
        month: data?.month?.value,
      },
    })

    // setAgentsOriginal(response.data)
    setAgents(response.data)
    setLoading(false)
  }

  // const setInputField = (fieldName, value) => {
  //   setFilters({
  //     ...filters,
  //     [fieldName]: value,
  //   })
  // }

  // async function resetForm() {
  //   setFilters({})
  // }

  // async function submitForm() {
  //   try {
  //     setLoading(true)

  //     const { data } = await axiosApis.get(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/agent/filter`,
  //       {
  //         params: filters,
  //       },
  //     )

  //     setAgents(data)

  //     return setLoading(false)
  //   } catch (error) {
  //     toast.info('Nenhum resultado encontrado.')

  //     return setLoading(false)
  //   }
  // }

  // async function onSearch(data) {
  //   if (data.agency || data.name || data.email) {
  //     setLoading(true)

  //     const newAgencys = agentsOriginal.filter(
  //       x =>
  //         (data.agency &&
  //           x.agency.name
  //             ?.toLowerCase()
  //             .includes(data.agency?.toLowerCase())) ||
  //         (data.name &&
  //           x.fullName?.toLowerCase().includes(data.name?.toLowerCase())) ||
  //         (data.email &&
  //           x.email?.toLowerCase().includes(data.email?.toLowerCase())),
  //     )

  //     setTimeout(() => {
  //       setAgents(newAgencys)

  //       setLoading(false)
  //     }, [500])
  //   }
  // }

  // async function onReset() {
  //   setLoading(true)

  //   setTimeout(() => {
  //     setAgents(agentsOriginal)

  //     setLoading(false)
  //   }, [500])
  // }

  const onValidate = data => {
    if (data.dateEnd) {
      if (data.dateStart) {
        return true
      } else {
        toast.info('Preencha a data Inical')
        return false
      }
    }

    if (data.dateStart) {
      if (data.dateEnd) {
        return true
      } else {
        toast.info('Preencha a data final')
        return false
      }
    }

    return true
  }

  return (
    <Container>
      <h1 className='page-header'>
        Aniversariantes
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Lista de aniversariantes do mês, onde você pode mandar uma mensagem padronizada para o WhatsApp.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={() => resetGlobalFilters()}
            defaultValue={pageFilters}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'name_viajante',
                label: 'Nome do Viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'email',
                label: 'E-mail',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Data Inicial',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 2,
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Data Final',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 2,
              },
              {
                type: 'select',
                name: 'month',
                label: 'Mês',
                placeholder: 'Selecione...',
                options: [
                  { label: 'Selecione...', value: 0 },
                  { label: 'Janeiro', value: 1 },
                  { label: 'Fevereiro', value: 2 },
                  { label: 'Março', value: 3 },
                  { label: 'Abril', value: 4 },
                  { label: 'Maio', value: 5 },
                  { label: 'Junho', value: 6 },
                  { label: 'Julho', value: 7 },
                  { label: 'Agosto', value: 8 },
                  { label: 'Setembro', value: 9 },
                  { label: 'Outubro', value: 10 },
                  { label: 'Novembro', value: 11 },
                  { label: 'Dezembro', value: 12 },
                ],
                sm: 12,
                md: 6,
                lg: 4,
                xl: 2,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table data={agents} loading={loading} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
