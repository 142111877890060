import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import { useNavigate, useOutletContext } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import { toast } from 'react-toastify'
import PageTitle from '~/components/PageTitle'

export default function List() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const { travel } = useOutletContext()
  const navigate = useNavigate()
  const [destinys, setDestinys] = useState([])
  const [destinyObj, setDestinyObj] = useState([{}])
  const [newDestinyObj, setNewDestinyObj] = useState([{}])
  const [newDestiny, setNewDestiny] = useState(false)

  useEffect(async () => {
    await getOptionsDestiny()
  }, [])

  async function getOptionsDestiny() {
    try {
      setLoading(true)

      const { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperiencedestiny`,
      )

      setDestinys(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const body = {
        ...data,
        destinies: newDestiny ? newDestinyObj : destinyObj,
        travelId: travel.id,
      }

      delete body.newDestiny
      delete body.destiny

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldestiny`,
        body,
      )

      navigate(-1)
      setLoading(false)
    } catch (error) {
      toast.info(error.response.data)
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle
        title={'Adicionar destino'}
        head={'h5'}
        onHeadButton={() => navigate(-1)}
      />

      <DefaultForm
        callbackSubmit={onSubmit}
        loading={loading}
        inputs={[
          {
            type: 'check',
            name: 'newDestinyFlag',
            label: 'Novo destino',
            sm: 12,
            onChange: () => setNewDestiny(!newDestiny),
          },
          newDestiny
            ? {
                type: 'city',
                name: 'newDestiny',
                label: 'Destino',
                placeholder: 'Selecione um destino...',
                required: true,
                isMulti: true,
                onChange: newDestinyObj => {
                  setNewDestinyObj(newDestinyObj.target.value),
                    setDestinyObj([])
                },
                sm: 6,
              }
            : {
                type: 'select',
                name: 'destiny',
                label: 'Destino',
                placeholder: 'Selecione um destino...',
                required: true,
                isMulti: true,
                options: destinys,
                onChange: destinyObj => {
                  setDestinyObj(destinyObj.target.value)
                  setNewDestinyObj([])
                },
                sm: 6,
              },
          {
            type: 'date',
            name: 'start',
            label: 'Início',
            sm: 3,
          },
          {
            type: 'date',
            name: 'end',
            label: 'Fim',
            sm: 3,
          },
        ]}
      />
    </>
  )
}
