import * as S from './styles'
import TravelerHistory from './TravelerHistory'
import HistorySkeleton from './Skeleton/SkeletonHistory'
import ServiceSkeleton from './Skeleton/SkeletonService'
import ServiceHistory from './ServiceHistory'
import UseFetchClientInformation from './Hooks/useFetchClientInformation'
import UseAccordion from './Hooks/useAccordion'
import InfomationAccordion from './InformationAccordion'
import { AccordionBody, Spinner } from 'reactstrap'
import { UseContact } from './Hooks/useContact'
import ModalDefault from '~/components/ModalDefault'
import FormContactForm from '~/pages/RelationshipKanban/FormContactForm'
import PropTypes from 'prop-types'
import Header from './Header'
import { useEffect, useState } from 'react'
import RelationshipCreate from './CreateCustomer'
import useModal from '~/hooks/useModal'
import { GetAxios } from '~/services/api'
import SkeletonLine from '~/components/Skeleton'
import ReminderCreate from '~/pages/Reminder/Create'

export default function Information({
  currentConversation,
  auth,
  contacts,
  clientsData,
  conversations,
}) {
  const {
    customer,
    travelHistory,
    tripsHistory,
    loadingHistory,
    loadingService,
    loadingCustomer,
    getAllCustomerData,
  } = UseFetchClientInformation(currentConversation, auth, conversations)

  const {
    openContactForm,
    setOpenContactForm,
    callbackOnClose,
    callbackOnSave,
    motivationOptions,
    observationOptions,
    agentOptions,
    leadFormOptions,
    leadStatusOptions,
    campaignOptions,
  } = UseContact()

  const { showModal, hideModal } = useModal()
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const { toggleHistory, toggleService, openHistory, openService } =
    UseAccordion()

  const callback = async () => {
    callbackOnSave()
    if (customer?.phone) {
      getAllCustomerData(customer.phone)
    }
  }

  async function setOpenNewClientForm() {
    showModal({
      title: 'Novo cliente',
      body: () => (
        <RelationshipCreate
          hideModal={hideModal}
          onUpdate={onSubmit}
          userData={currentConversation}
          getAllCustomerData={getAllCustomerData}
        />
      ),
    })
  }

  async function setOpenNewReminder() {
    showModal({
      title: 'Novo lembrete',
      body: () => (
        <ReminderCreate
          onlyForm
          redirect={false}
          showSuccess={true}
          callback={() => hideModal()}
        />
      ),
    })
  }

  async function onSubmit(data, skip, take) {
    try {
      setLoading(true)

      const params = {
        ...data,
        skip,
        take,
        isTraveler: !!data?.traveler,
        fullName: data?.fullName ?? null,
        email: data?.email ?? null,
        cpfCnpj: data?.cpfCnpj ?? null,
        phone: data?.phone ?? null,
        prospection: data?.prospection?.value ?? null,
        contactName: data?.contactName ?? null,
        contactPhone: data?.contactPhone ?? null,
        contactEmail: data?.contactEmail ?? null,
        tags:
          String(data?.tags?.map(tag => tag.label)) == 'undefined'
            ? null
            : String(data?.tags?.map(tag => tag.label)),
      }

      await axiosApis.get(`${process.env.REACT_APP_ENDPOINT_APIS}/customer`, {
        params: {
          ...params,
        },
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <S.Container>
      <S.ServiceButtonContainer>
        {loadingCustomer && (
          <>
            <SkeletonLine width={'64px'} height={'27px'} />

            <SkeletonLine width={'64px'} height={'27px'} />

            <SkeletonLine width={'64px'} height={'27px'} />
          </>
        )}

        {!loadingCustomer && (
          <>
            {!customer && setOpenNewClientForm && (
              <S.ServiceButton onClick={() => setOpenNewClientForm()}>
                <i className='fas fa-user-plus' />
                &nbsp; Cliente
              </S.ServiceButton>
            )}

            {setOpenContactForm && (
              <S.ServiceButton onClick={() => setOpenContactForm(true)}>
                <i className='fas fa-plus' />
                &nbsp; Atendimento
              </S.ServiceButton>
            )}

            {setOpenNewReminder && (
              <S.ServiceButton onClick={() => setOpenNewReminder()}>
                <i className='fas fa-calendar-day' />
                &nbsp; Lembrete
              </S.ServiceButton>
            )}
          </>
        )}
      </S.ServiceButtonContainer>

      <Header
        currentConversation={currentConversation}
        contacts={contacts}
        customer={customer}
        clientsData={clientsData}
        loadingCustomer={loadingCustomer}
        getAllCustomerData={getAllCustomerData}
      />

      <S.Content>
        <InfomationAccordion
          open={openService}
          toggle={toggleService}
          targetId='2'
          title={'Histórico de Atendimento'}
        >
          <AccordionBody
            style={{
              maxHeight: '400px',
              overflow: 'auto',
            }}
            accordionId={'2'}
          >
            {loadingService && <ServiceSkeleton />}

            {tripsHistory.length > 0 && !loadingService && (
              <>
                {tripsHistory.map((traveler, i) => (
                  <ServiceHistory
                    key={`service-${traveler.id}`}
                    traveler={traveler}
                    index={i}
                  />
                ))}
              </>
            )}
            {tripsHistory.length === 0 && !loadingService && (
              <p>Nenhum histórico encontrado</p>
            )}
          </AccordionBody>
        </InfomationAccordion>

        <InfomationAccordion
          open={openHistory}
          toggle={toggleHistory}
          targetId='3'
          title={'Histórico de Viagens'}
        >
          <AccordionBody
            style={{
              maxHeight: '400px',
              overflow: 'auto',
            }}
            accordionId={'3'}
          >
            {loadingHistory && <HistorySkeleton />}
            {travelHistory.length > 0 && !loadingHistory && (
              <>
                {travelHistory.map((traveler, i) => (
                  <TravelerHistory
                    key={`${traveler.id}`}
                    traveler={traveler}
                    index={i}
                  />
                ))}
              </>
            )}
            {travelHistory.length === 0 && !loadingHistory && (
              <p>Nenhum histórico encontrado</p>
            )}
          </AccordionBody>
        </InfomationAccordion>

        {openContactForm && (
          <ModalDefault
            title='Criar atendimento'
            content={
              <FormContactForm
                data={{
                  chatCenterDetails: {
                    customer: customer,
                    currentConversation: currentConversation,
                    name: currentConversation?.name,
                  },
                }}
                motivationOptions={motivationOptions}
                observationOptions={observationOptions}
                agentOptions={agentOptions}
                leadFormOptions={leadFormOptions}
                leadStatusOptions={leadStatusOptions}
                callback={() => callback()}
                isWhatsapp={true}
                campaignOptions={campaignOptions}
                successMessage={'Atendimento criado com sucesso!'}
              />
            }
            handleShow={callbackOnClose}
          ></ModalDefault>
        )}
      </S.Content>
    </S.Container>
  )
}

UseFetchClientInformation.propTypes = {
  currentConversation: {
    photo: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    lastMessage: PropTypes.string,
    read: PropTypes.bool,
    delivered: PropTypes.bool,
    sent: PropTypes.bool,
  },
  auth: PropTypes.node,
}
