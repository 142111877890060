import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import RefundDocs from './RefundDocs'
import Divisor from '~/components/Divisor'
// import { getRefundInputs } from './contants'
import LoadingContainer from '~/components/LoadingContainer'
// import { ref } from 'yup'

export default function RefundDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const [refund, setRefund] = useState()
  const [loading, setLoading] = useState(false)
  const [refundStatus, setRefundStatus] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const response = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/refund/status`,
        )

        setRefundStatus(response.data)

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false
    async function getSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/refund/${location.state.id}`,
        )

        setRefund({
          ...data,
          statusRefund: {
            label: data.statusRefund,
            value: data.statusRefund,
          },
        })

        return setLoading(false)
      } catch (error) {
        return setLoading(false)
      }
    }

    getSource()

    return () => {
      setRefund(undefined)
      setLoading(false)
    }
  }, [])

  async function onChangeStatus(data) {
    try {
      const formData = new FormData()

      formData.append('id', refund.id)
      formData.append('refundComent', data.refundComent)
      formData.append('statusRefund', data.statusRefund.value)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/refund`,
        formData,
      )

      return toast.success('Atualização realizada com sucesso')
    } catch (error) {
      return toast.error('Não foi possível atualizar o reembolso.')
    }
  }

  return (
    <Container>
      <PageTitle title='Solicitação de Reembolso' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          {refund && !loading && (
            <Container>
              <DefaultForm
                callbackSubmit={onChangeStatus}
                defaultValue={refund}
                disabled={loading}
                inputs={[
                  {
                    empty: true,
                    text: '<span style="font-size: 13px"><strong style="color: red">*</strong> A cada mudança de Status, um <b>EMAIL</b> é disparado para o viajante.</span>',
                    sm: 12,
                  },
                  {
                    type: 'select',
                    name: 'statusRefund',
                    label: 'Status do Reembolso',
                    options: refundStatus,
                    sm: 12,
                  },
                  {
                    type: 'textarea',
                    name: 'refundComent',
                    label: 'Comentário',
                    sm: 12,
                  },
                ]}
              />

              <Divisor border />

              <RefundDocs docs={refund.refundDocs} />

              <DefaultForm
                defaultValue={refund}
                disabled
                inputs={getInputs(refund)}
              />
            </Container>
          )}

          {(!refund || loading) && <LoadingContainer />}
        </PanelBody>
      </Panel>
    </Container>
  )
}

const getInputs = refund => {
  let inputs = [
    {
      type: 'text',
      name: 'name',
      label: 'Nome',
      placeholder: 'Nome',
      required: true,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      placeholder: 'usuario@email.com',
      required: true,
      maxLength: 100,
      sm: 12,
      md: 4,
      xl: 4,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefone',
      placeholder: 'Telefone',
      required: true,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'company',
      label: 'Empresa/Corp',
      placeholder: 'Empresa/Corp',
      required: true,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'city',
      label: 'Cidade',
      placeholder: 'Cidade',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
      xl: 4,
    },
    {
      type: 'text',
      name: 'state',
      label: 'Estado',
      placeholder: 'Estado',
      required: true,
      maxLength: 100,
      sm: 12,
      md: 4,
      xl: 4,
    },
    {
      type: 'textarea',
      name: 'bankData',
      label: 'Dados bancários',
      placeholder: 'Dados bancários',
      required: false,
      maxLength: 1000,
      sm: 12,
    },
    {
      empty: true,
      text: '<b>Serviço 1</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'type1',
      label: 'Tipo de Serviço',
      placeholder: 'Selecione...',
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'loc1',
      label: 'Localizador',
      placeholder: 'Localizador',
      required: true,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'ticket1',
      label: 'Bilhete',
      placeholder: 'Bilhete',
      required: true,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'pax1',
      label: 'Passageiro',
      placeholder: 'Passageiro',
      required: true,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'cia1',
      label: 'Cia / Hotel / Operadora',
      placeholder: 'Cia / Hotel / Operadora',
      required: true,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'voluntary1',
      label: 'É Voluntário?',
      placeholder: 'É Voluntário?',
      required: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'textarea',
      name: 'reason1',
      label: 'Descreva o motivo',
      placeholder: 'Descreva o motivo',
      required: true,
      maxLength: 100,
      sm: 12,
    },
  ]

  const ticketInputs2 = [
    {
      empty: true,
      text: '<b>Serviço 2</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'type2',
      label: 'Tipo de Serviço',
      placeholder: 'Selecione...',
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'loc2',
      label: 'Localizador',
      placeholder: 'Localizador',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'ticket2',
      label: 'Bilhete',
      placeholder: 'Bilhete',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'pax2',
      label: 'Passageiro',
      placeholder: 'Passageiro',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'cia2',
      label: 'Cia / Hotel / Operadora',
      placeholder: 'Cia / Hotel / Operadora',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'voluntary2',
      label: 'É Voluntário?',
      placeholder: 'É Voluntário?',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'textarea',
      name: 'reason2',
      label: 'Descreva o motivo',
      placeholder: 'Descreva o motivo',
      required: false,
      maxLength: 100,
      sm: 12,
    },
  ]

  const ticketInputs3 = [
    {
      empty: true,
      text: '<b>Serviço 3</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'type3',
      label: 'Tipo de Serviço',
      placeholder: 'Selecione...',
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'loc3',
      label: 'Localizador',
      placeholder: 'Localizador',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'ticket3',
      label: 'Bilhete',
      placeholder: 'Bilhete',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'pax3',
      label: 'Passageiro',
      placeholder: 'Passageiro',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'cia3',
      label: 'Cia / Hotel / Operadora',
      placeholder: 'Cia / Hotel / Operadora',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'voluntary3',
      label: 'É Voluntário?',
      placeholder: 'É Voluntário?',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'textarea',
      name: 'reason3',
      label: 'Descreva o motivo',
      placeholder: 'Descreva o motivo',
      required: false,
      maxLength: 100,
      sm: 12,
    },
  ]

  const ticketInputs4 = [
    {
      empty: true,
      text: '<b>Serviço 4</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'type4',
      label: 'Tipo de Serviço',
      placeholder: 'Selecione...',
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'loc4',
      label: 'Localizador',
      placeholder: 'Localizador',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'ticket4',
      label: 'Bilhete',
      placeholder: 'Bilhete',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'pax4',
      label: 'Passageiro',
      placeholder: 'Passageiro',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'cia4',
      label: 'Cia / Hotel / Operadora',
      placeholder: 'Cia / Hotel / Operadora',
      required: false,
      maxLength: 100,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'voluntary4',
      label: 'É Voluntário?',
      placeholder: 'É Voluntário?',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'textarea',
      name: 'reason4',
      label: 'Descreva o motivo',
      placeholder: 'Descreva o motivo',
      required: false,
      maxLength: 100,
      sm: 12,
    },
  ]

  if (
    refund.ticket2 ||
    refund.loc2 ||
    refund.pax2 ||
    refund.cia2 ||
    refund.voluntary2 ||
    refund.reason2 ||
    refund.type2
  ) {
    inputs = inputs.concat(ticketInputs2)
  }

  if (
    refund.ticket3 ||
    refund.loc3 ||
    refund.pax3 ||
    refund.cia3 ||
    refund.voluntary3 ||
    refund.reason3 ||
    refund.type3
  ) {
    inputs = inputs.concat(ticketInputs3)
  }

  if (
    refund.ticket4 ||
    refund.loc4 ||
    refund.pax4 ||
    refund.cia4 ||
    refund.voluntary4 ||
    refund.reason4 ||
    refund.type4
  ) {
    inputs = inputs.concat(ticketInputs4)
  }

  return inputs
}
