import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import WaveSurfer from 'https://cdn.jsdelivr.net/npm/wavesurfer.js@7/dist/wavesurfer.esm.js'
import IconButton from '~/components/IconButton'
import { Col, Row } from 'reactstrap'
import { sendAudioMessage } from '~/services/chat'

export default function RecordingAudio({ to, from, key, hide }) {
  const [isRecording, setIsRecording] = useState(false)
  const [recordedAudio, setRecordedAudio] = useState(null)
  const [waveForm, setWaveForm] = useState(null)
  const [recordingDuration, setRecordingDuration] = useState(0)
  const [currentPlaybackTime, setCurrentPlaybackTime] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [renderedAudio, setRenderedAudio] = useState(null)

  const mediaRecorderRef = useRef(null)
  const audioRef = useRef(null)
  const waveFormRef = useRef(null)

  useEffect(() => {
    let interval
    if (isRecording) {
      interval = setInterval(() => {
        setRecordingDuration(prevDuration => {
          setTotalDuration(prevDuration + 1)
          return prevDuration + 1
        })
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [isRecording])

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: waveFormRef.current,
      waveColor: '#4F4A85',
      progressColor: '#383351',
      // url: '/audio.mp3',
      height: 30,
      cursorColor: '#7ae3c3',
      barWidth: 2,
      width: 100,
    })
    setWaveForm(waveSurfer)

    waveSurfer.on('finish', () => {
      setIsPlaying(false)
    })

    return () => {
      waveSurfer.destroy()
    }
  }, [])

  useEffect(() => {
    if (waveForm) handleStartRecording()
  }, [waveForm])

  useEffect(() => {
    if (recordedAudio) {
      const updatePlaybackTime = () => {
        setCurrentPlaybackTime(recordedAudio.currentTime)
      }
      recordedAudio.addEventListener('timeupdate', updatePlaybackTime)
      return () => {
        recordedAudio.removeEventListener('timeupdate', updatePlaybackTime)
      }
    }
  }, [recordedAudio])

  const formatTime = time => {
    if (isNaN(time)) return '00:00'
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }

  const handleStartRecording = () => {
    setRecordingDuration(0)
    setCurrentPlaybackTime(0)
    setTotalDuration(0)
    setIsRecording(true)
    setRecordedAudio(null)
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream)
        mediaRecorderRef.current = mediaRecorder
        audioRef.current.srcObject = stream

        const chunks = []
        mediaRecorder.ondataavailable = e => chunks.push(e.data)
        mediaRecorder.onstop = () => {
          const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
          console.log('blob ', blob)
          const audioURL = URL.createObjectURL(blob)
          console.log('audioURL ', audioURL)
          const audio = new Audio(audioURL)
          // console.log('audio ', audio)
          setRecordedAudio(audio)

          waveForm.load(audioURL)
        }

        mediaRecorder.start()
      })
      .catch(error => {
        console.error('Erro ao acessar o microfone: ', error)
      })
  }

  const audioToBase64 = audioFile => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onerror = reject
      reader.onload = e => resolve(e.target.result)
      reader.readAsDataURL(audioFile)
    })
  }

  const handleStopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop()
      setIsRecording(false)
      waveForm.stop()

      const audioChunks = []
      mediaRecorderRef.current.addEventListener('dataavailable', event => {
        audioChunks.push(event.data)
      })
      console.log('audioChunks ', audioChunks)
      mediaRecorderRef.current.addEventListener('stop', async () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/ogg' })
        console.log('audioBlob ', audioBlob)
        const audioFile = new File([audioBlob], { type: 'recording.ogg' })
        console.log('audioFile ', audioFile)
        const audioBase64 = await audioToBase64(audioFile)
        console.log('audioBase64 ', audioBase64)
        const newAudioBlob = new Blob([audioBase64], { type: 'audio/ogg' })
        console.log('newAudioBlob ', newAudioBlob)
        const newAudioFile = new File([audioBlob], { type: 'recording.ogg' })
        console.log('newAudioFile ', newAudioFile)
        setRenderedAudio(newAudioBlob)
      })
    }
  }

  // function blobToBase64(blob) {
  //   return new Promise((resolve, _) => {
  //     const reader = new FileReader()
  //     reader.onloadend = () => resolve(reader.result)
  //     reader.readAsDataURL(blob)
  //   })
  // }

  const handlePlayRecording = () => {
    if (recordedAudio) {
      waveForm.stop()
      waveForm.play()
      recordedAudio.play()
      setIsPlaying(true)
    }
  }

  const handlePauseRecording = () => {
    waveForm.stop()
    recordedAudio.pause()
    setIsPlaying(false)
  }

  const sendRecording = async () => {
    // sendMessage()
    // enviar o renderedAudio
    console.log('renderedAudio ', renderedAudio)
    sendAudioMessage(renderedAudio, to, from, key)
    // mediaRecorderRef.current.forEach(track => {
    //   if (track.kind === 'audio') {
    //     track.stop() // This will close the active microphone
    //   }
    // })
  }

  return (
    <Container>
      <Partition>
        <IconButton
          id={'trash'}
          colorOverride={'grey'}
          type={'trash'}
          onClick={() => hide()}
        />
      </Partition>
      {isRecording && (
        <RecordingLabel data-cy='recording-chat-info'>
          Recording <span>{recordingDuration}s</span>
        </RecordingLabel>
      )}
      {!isRecording && (
        <RecordedContent>
          {recordedAudio && (
            <>
              {isPlaying && (
                <IconButton
                  onClick={() => handlePauseRecording()}
                  type='pauseCircle'
                  colorOverride={'red'}
                />
              )}
              {!isPlaying && (
                <IconButton onClick={() => handlePlayRecording()} type='play' />
              )}
            </>
          )}
        </RecordedContent>
      )}
      <div ref={waveFormRef} hidden={isRecording}></div>
      {recordedAudio && isPlaying && (
        <Timer>{formatTime(currentPlaybackTime)}</Timer>
      )}
      {recordedAudio && !isPlaying && (
        <Timer>{formatTime(totalDuration)}</Timer>
      )}
      <audio ref={audioRef} hidden />

      {isRecording && (
        <Partition>
          <IconButton
            data-cy='pause-chat-icon'
            onClick={() => handleStopRecording()}
            type='pauseCircle'
            colorOverride={'red'}
          />
        </Partition>
      )}

      <Partition>
        <IconButton onClick={sendRecording} type='paperPlane' />
      </Partition>
    </Container>
  )
}

export const PlayerIcon = styled.i`
  cursor: pointer;
  margin: 0px 6px;
`

export const Container = styled.div`
  display: flex;
  // justify-content: flex-start;
  max-height: 44px;
  // height: 100%;
  width: 100%;
  text-align: center;
  // max-width: 500px;
  margin: 10px;
`
export const Partition = styled.div`
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  width: 4%;
`

export const RecordingLabel = styled.div`
  color: red;
  padding: 6px;
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  width: 12%;
`
export const RecordedContent = styled.div`
  color: white;
  display: inline-block;
  vertical-align: top;
  max-width: 20%;
`
export const Timer = styled.span`
  margin: 6px 0px 6px 6px;
`
