import { useEffect } from 'react'
import useChat from '../../hooks/ChatHook'
import DefaultMessage from '../Messages/DefaultMessage'

export default function ChatConversation({
  scroll,
  currentConversationMessages = [],
  userNumber,
  dispatch,
}) {
  const { getMedia } = useChat()

  useEffect(() => {
    setTimeout(() => {
      scroll?.current?.scrollIntoView()
    }, 300)
  }, [currentConversationMessages])

  return (
    <>
      {[...currentConversationMessages]
        .sort((a, b) => a.timestamp - b.timestamp)
        .map((c, i) => {
          return (
            <DefaultMessage
              key={`${i}`}
              dispatch={dispatch}
              conversation={c}
              index={i}
              toMe={c.from === userNumber}
              getMedia={getMedia}
            />
          )
        })}

      <div ref={scroll}></div>
    </>
  )
}
