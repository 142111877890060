import { Panel, PanelBody, PanelHeader } from '~/components/Panel'

export default function BackofficeWarning() {
  return (
    <Panel>
      <PanelHeader title={'Avisos IMPORTANTES!'} noButton />

      <PanelBody>
        <ul
          style={{
            fontWeight: 'bold',
            fontSize: '14px',
          }}
        >
          <li>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#700',
              }}
            >
              ** A Tripmee não se responsabiliza por CUSTOS COBRADOS pelas
              consultas de dados no Backoffice. **
            </p>
          </li>

          <li>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              A Tripmee, somente puxa os dados fornecidos e exibe em formato
              ReadOnly .
            </p>
          </li>

          <li>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              Os dados são atualizados todos os dias, às 5:00 da manhã.
            </p>
          </li>

          <li>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              Vendas emitidas há mais de 2 meses não são atualizadas, mas
              permanecem disponíveis aqui no Tripmee.
            </p>
          </li>
        </ul>
      </PanelBody>
    </Panel>
  )
}
