import React, { useState, useRef } from 'react'
import { Form } from '@unform/web'
import { FiArrowLeft } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'

import { Container, Content, Button, LinkBack } from './styles'

import Input from '~/components/Input'

import { axiosAuth } from '~/services/api'

import history from '~/services/history'

export default function Register() {
  const formRef = useRef(null)

  const [loading, setLoading] = useState(false)

  const initialState = {
    userData: {
      name: '',
      surname: '',
      fullname: '',
      cpf: '',
      email: '',
      phone: '',
      password: '',
      confirmpassword: '',
    },
  }

  const [pageProps, setPageProps] = useState(initialState)

  const setInputField = (fieldName, value) => {
    const newData = {
      ...pageProps,
      userData: {
        ...pageProps.userData,
        [fieldName]: value,
      },
    }
    setPageProps(newData)
  }

  async function handleSubmit() {
    try {
      setLoading(true)

      if (pageProps.userData.password === pageProps.userData.confirmpassword) {
        await axiosAuth.post(`${process.env.REACT_APP_ENDPOINT_AUTH}/user`, {
          Name: pageProps.userData.name,
          Surname: pageProps.userData.surname,
          Fullname: pageProps.userData.fullname,
          Cpf: pageProps.userData.cpf,
          Phone: pageProps.userData.phone,
          Email: pageProps.userData.email,
          password: pageProps.userData.password,
        })

        toast.success(
          // 'Link enviado com sucesso! Verifique a caixa de entrada ou spam do seu e-mail.'
          'Usuário criado com sucesso!',
        )

        setLoading(false)

        history.push('/')

        return history.go()
      } else {
        setLoading(false)
        return toast.info('Senhas não coincidem')
      }
    } catch (error) {
      setLoading(false)

      if (error && error.response) {
        return toast.error(error.response.data.message)
      }

      return toast.error(
        'Não foi possível realizar a solicitação, tente novamente mais tarde.',
      )
    }
  }

  return (
    <Container style={{ background: '#7f2ec2' }}>
      <Content style={{ background: '#66259d' }}>
        <section>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '0',
              margin: '0 0 16px',
            }}
          >
            <div className='login-header'>
              <div className='brand'>
                <h1
                  style={{
                    fontSize: '3.03125rem',
                    // color: "#333"
                    color: 'white',
                  }}
                >
                  TripMee
                  <sub>agent</sub>
                </h1>
              </div>
              <div className='icon'>
                <i className='fa fa-sign-in' />
              </div>
            </div>

            <h1
              style={{
                padding: '0',
                margin: '0',
                color: 'white',
              }}
            >
              Cadastre-se
            </h1>
          </div>

          <LinkBack className='back-link' style={{ color: 'white' }} to='/'>
            <FiArrowLeft size={16} style={{ color: 'white' }} />
            Voltar
          </LinkBack>
        </section>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            disabled={false}
            name={'name'}
            value={pageProps.userData.name}
            placeholder={'Nome'}
            style={{ color: 'white' }}
            maxLength={20}
            onChange={e => setInputField('name', e.target.value)}
            required
          />
          <Input
            disabled={false}
            name={'surname'}
            value={pageProps.userData.surname}
            placeholder={'Sobrenome'}
            maxLength={20}
            onChange={e => setInputField('surname', e.target.value)}
            required
          />
          <Input
            disabled={false}
            name={'fullname'}
            value={pageProps.userData.fullname}
            placeholder={'Nome Completo'}
            maxLength={50}
            onChange={e => setInputField('fullname', e.target.value)}
            required
          />

          <Input
            disabled={false}
            name={'cpf'}
            value={pageProps.userData.cpf}
            placeholder={'CPF'}
            onChange={e => setInputField('cpf', e.target.value)}
            required
          />
          <Input
            disabled={false}
            mask='(99) 99999-9999'
            name={'phone'}
            value={pageProps.userData.phone}
            placeholder={'telefone com (DDD)'}
            onChange={e => setInputField('phone', e.target.value)}
            required
          />

          <Input
            name='email'
            placeholder='E-mail'
            type='email'
            maxLength={50}
            onChange={e => setInputField('email', e.target.value)}
            value={pageProps.userData.email}
            required
          />

          <Input
            name='password'
            placeholder='Password'
            type='password'
            onChange={e => setInputField('password', e.target.value)}
            value={pageProps.userData.password}
            required
          />

          <Input
            name='confirmpassword'
            placeholder='Confirme a senha'
            type='password'
            onChange={e => setInputField('confirmpassword', e.target.value)}
            value={pageProps.userData.confirmpassword}
            required
          />

          <Button
            className='button'
            type='submit'
            disabled={loading}
            style={{
              background: 'rgb(213,76,53)',
              color: 'white',
            }}
          >
            {loading ? <Spinner color='#FFFF' /> : 'Enviar'}
          </Button>
        </Form>
      </Content>
    </Container>
  )
}
