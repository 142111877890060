import { useEffect, useState } from 'react'
import IconButton from '~/components/IconButton'
import { GetAxios } from '~/services/api'

export default function ShowLink({ travelId }) {
  const { axiosApis } = GetAxios()
  const [travelLink, setTravelLink] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    generateLinkTravel()
  }, [])

  async function generateLinkTravel() {
    setLoading(true)
    try {
      const { data } = await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship`,
        { travelId: travelId },
      )

      setTravelLink(data.link)
    } catch (error) {
      console.log(error)
      setTravelLink(undefined)
    } finally {
      setLoading(false)
    }
  }
  // async function getData() {
  //   const [
  //     responseLinkRequest,
  //     responseLeadFormRequest,
  //     responseCampaignOptionsRequest,
  //   ] = await Promise.allSettled([
  //     apiRequest('get', '/customer/request-link', {}, setLoading),
  //     apiRequest('options', '/customer/leadForm', {}, setLoading),
  //     apiRequest('options', '/campaign', {}, setLoading),
  //   ])

  //   setLink(responseLinkRequest?.value?.data)
  //   setLeadFormOptions(responseLeadFormRequest?.value?.data)
  //   setLeadFormCurrent(responseLinkRequest?.value?.data?.leadFormDefault)
  // }

  function copyTravelLink(link) {
    navigator.clipboard.writeText(link)
  }

  async function redirectToTravelLink(link) {
    var win = window.open(link, '_blank')
    win.focus()
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          height: 'auto',
        }}
      >
        <b>Link da viagem</b>
        <input
          style={{
            height: '38px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '90%',
            marginRight: '2%',
          }}
          type='text'
          label='Link para o Viajante'
          name={'link'}
          value={loading ? '   Carregando...' : travelLink}
        />
        <IconButton
          iconOverride={'fas fa-copy'}
          tooltipOverride={'Copiar'}
          onClick={() => copyTravelLink(travelLink)}
        />
        <IconButton
          iconOverride={'fas fa-external-link-alt'}
          tooltipOverride={'Link'}
          onClick={() => redirectToTravelLink(travelLink)}
        />
      </div>
    </>
  )
}
