import { Button, Col, FormGroup, InputGroup, Label } from 'reactstrap'
import FormInput from '~/components/FormInput'
import Select, { createFilter } from 'react-select'
import { Component } from 'react'
import { FixedSizeList as List } from 'react-window'
import { SalesReleaseSelectRWindow } from '../../styles'
import styled from 'styled-components'

const height = 35
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props
    const [value] = getValue()
    const initialOffset = options.indexOf(value) * height

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    )
  }
}

export default function SalesReleasesFormInput({
  register,
  setValue,
  name,
  label = '',
  sm = 12,
  md = 4,
  lg = 2,
  xl = 2,
  type = 'text',
  actionLeftButton,
  actionRightButton,
  actionLeftButtonIcon = 'fas fa-search',
  actionRightButtonIcon = 'fas fa-search',
  radioOptions = [],
  selectOptions = [],
  Wrapper = FormGroup,
  size,
  getValues,
  required,
  ...rest
}) {
  let inputFontSize = '13px'

  if (size === 'sm') {
    inputFontSize = '12px'
  }

  return (
    <Col sm={sm} md={md} lg={lg} xl={xl}>
      <Container>
        <Wrapper style={{ fontSize: inputFontSize }}>
          {label && (
            <Label>
              {label}
              {required ? ' *' : ''}
            </Label>
          )}

          {radioOptions.length === 0 && selectOptions.length === 0 && (
            <InputGroup>
              {actionLeftButton && (
                <Button onClick={actionLeftButton}>
                  <i className={actionLeftButtonIcon}></i>
                </Button>
              )}

              <FormInput
                type={type}
                name={name}
                register={register}
                required={required}
                {...rest}
              />

              {actionRightButton && (
                <Button onClick={actionRightButton}>
                  <i className={actionRightButtonIcon}></i>
                </Button>
              )}
            </InputGroup>
          )}

          {(actionRightButton || actionLeftButton) && (
            <InputGroup>
              <LabelSubtt name={name + '_subt'} {...register(name + '_subt')}>
                {getValues ? getValues(name + '_subt')?.label : ''}
              </LabelSubtt>
            </InputGroup>
          )}

          {radioOptions.map((e, i) => (
            <InputGroup key={i}>
              <input
                type='radio'
                name={name}
                register={register}
                value={e.value}
                onChange={e => setValue(e.target.name, e.target.value)}
                required={required}
                {...rest}
              />
              <Label>{e.label}</Label>
            </InputGroup>
          ))}

          {selectOptions.length !== 0 && (
            <InputGroup>
              <SalesReleaseSelectRWindow>
                <Select
                  filterOption={createFilter({ ignoreAccents: false })}
                  components={{ MenuList }}
                  className='basic-single col-12'
                  classNamePrefix='select'
                  defaultValue={selectOptions[0]}
                  isClearable={false}
                  isSearchable={true}
                  name={name}
                  options={selectOptions}
                  {...register(name)}
                  onChange={e => {
                    if (setValue) setValue(name, e)
                  }}
                  required={required}
                  {...rest}
                />
              </SalesReleaseSelectRWindow>
            </InputGroup>
          )}
        </Wrapper>
      </Container>
    </Col>
  )
}

const LabelSubtt = styled.label`
  font-size: 0.8em;
  font-weight: 600;
  padding: 0 0 0 6px;
`

const Container = styled.div`
  .mb-3 {
    margin-bottom: 8px !important;
  }
`
