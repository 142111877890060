import styled from 'styled-components'

export const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  * {
    margin: 0;
  }
`

export const DivFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`

export const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 4px;
    margin: 0 4px;
    border-radius: 4px;
    background-color: #ddd;
  }
`

export const DivFlexCentered = styled.div`
  display: flex;
  justify-content: flex-start;
  /* border: 1px solid red; */
  /* align-items: center; */
  /* width: 100%; */

  /* min-height: ${props => props.minHeight ?? '0px'}; */
`

export const DivFlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  min-height: ${props => props.minHeight ?? '0px'};
`

export const DivFlexLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 2px;

  min-height: ${props => props.minHeight ?? '0px'};
`

export const DivShadowNonFlex = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #777;
  border: none;
`

export const DivFull = styled.div`
  width: 100%;
`

export const ColorLegend = styled.div`
  width: 40px;
  height: 20px;
  border-radius: 4px;
  background: ${props => props.bg};
`

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #bdbfbf;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
`

export const Divider = styled.div`
  height: 2px;
  border-bottom: 2px solid #aaa;
  margin: 16px 0;
  width: 100%;
`

export const DetailBlock = styled.div`
  background-color: #f4f4f4;
  padding: 8px;
  width: 100%;
  height: 100%;
`
