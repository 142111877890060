import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useField } from '@unform/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {
  Label,
  Group,
  InputV,
  Disabled,
  Search,
  MessageError,
  Color,
  Buscar,
} from './styles'
import { FormatPhoneBR } from '~/functions/FormatPhone'

import { Spinner } from 'reactstrap'

export default function Input({
  name,
  value,
  label,
  icon,
  disabledButton,
  searchButton,
  searchButtonLabel,
  searchButtonText,
  cancelButton,
  errorMessage,
  colorSelected,
  isPhone,
  isPasswordForLogin,
  loading,
  callbackSearch = () => {},
  callbackCancel = () => {},
  callbackHidePassword = () => {},
  hidePass = true,
  searchIcon = 'fas fa-search',
  cancelIcon = 'fas fa-times',
  ...rest
}) {
  const inputRef = useRef(null)

  const eye = <FontAwesomeIcon icon={hidePass ? faEyeSlash : faEye} />

  const { fieldName, registerField } = useField(name)

  const [disabled, setDisabled] = useState(true)
  const [searching, setSearching] = useState(true)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  const handleDisabled = () => setDisabled(!disabled)

  const handleSearch = () => {
    setSearching(!searching)

    return callbackSearch()
  }

  const handleCancel = () => callbackCancel()

  const Component = (
    <>
      <InputV
        className='form-control'
        name={name}
        id={name}
        ref={inputRef}
        disabled={disabledButton && disabled}
        value={isPhone ? FormatPhoneBR(value) : value}
        {...rest}
      />

      {disabledButton === true && (
        <Disabled onClick={handleDisabled} type='button'>
          {disabled && <i className='fas fa-lock' />}

          {!disabled && <i className='fas fa-lock-open' />}
        </Disabled>
      )}

      {searchButton === true && (
        <Search onClick={handleSearch} type='button'>
          {!loading && <i className={searchIcon} />}

          {loading && <Spinner color='#777' size='sm' />}
        </Search>
      )}

      {searchButtonLabel === true && (
        <Buscar
          onClick={handleSearch}
          type='button'
          style={{
            backgroundColor: '#000',
            color: '#fff',
          }}
        >
          {!loading && `${searchButtonText}`}

          {loading && <Spinner color='#777' size='sm' />}
        </Buscar>
      )}

      {cancelButton === true && (
        <Search onClick={handleCancel} type='button'>
          {!loading && <i className={cancelIcon} />}

          {loading && <Spinner color='#777' size='sm' />}
        </Search>
      )}
    </>
  )

  return (
    <Group>
      {label && (
        <Label htmlFor={name}>
          {label}

          {icon && <i className={icon} />}
        </Label>
      )}

      {disabledButton && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {Component}

          {colorSelected && (
            <Color type='button' colorSelected={colorSelected} />
          )}
        </div>
      )}

      {!disabledButton && (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {Component}

          {colorSelected && (
            <Color type='button' colorSelected={colorSelected} />
          )}
          {isPasswordForLogin && (
            <i
              onClick={callbackHidePassword}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                color: '#fff',
                right: '8px',
              }}
            >
              {eye}
            </i>
          )}
        </div>
      )}

      <MessageError>{errorMessage}</MessageError>
    </Group>
  )
}

Input.defaultProps = {
  label: null,
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  colorSelected: PropTypes.string,
  isPhone: PropTypes.bool,
}
