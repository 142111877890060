import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import { Panel, PanelHeader, PanelBody } from '~/components/Panel'
import { Container } from '~/styles/container'
import PageTitle from '~/components/PageTitle'
import DefaultForm from '~/components/DefaultForm'

export default function Create() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)

  async function handleSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('desc', data.desc)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/createexperiencecategory`,
        formData,
      )

      toast.success('Categoria criada com sucesso!')

      setLoading(false)

      return navigate('/experiences/categories')
    } catch (error) {
      setLoading(false)

      toast.info(
        error && error.response && error.response.data
          ? error.response.data
          : 'Não foi possível criar a categoria.',
      )
    }
  }

  return (
    <Container>
      <PageTitle title={'Categorias'} />

      <Panel>
        <PanelHeader title={'Criar'} noButton />
        <PanelBody>
          <DefaultForm
            callbackSubmit={handleSubmit}
            inputs={[
              {
                type: 'text',
                name: 'desc',
                label: 'Nome da Categoria',
                sm: 12,
                lg: 6,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
