import styled from 'styled-components'

export const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
`

export const Label = styled.b`
  font-weight: 600;
`

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 0px 12px;
  font-weight: bold;
  font-size: 13px;
  background-color: transparent;
  color: #ff7d70;

  &:hover {
    color: #ff7d70;
    background-color: #00000011;
    text-decoration: none;
  }
`

export const DownloadButton = styled.button`
  display: flex;
  border: transparent;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0px 12px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
    color: #333;
  }
`
