export const colors = {
  OXFORD: '#092644',
  OXFORD_TRANSPARENT_1: 'rgba(9, 38, 68, 0.1)',
  OXFORD_TRANSPARENT_2: 'rgba(9, 38, 68, 0.2)',
  OXFORD_TRANSPARENT_3: 'rgba(9, 38, 68, 0.3)',
  OXFORD_TRANSPARENT_4: 'rgba(9, 38, 68, 0.4)',
  OXFORD_TRANSPARENT_5: 'rgba(9, 38, 68, 0.5)',
  OXFORD_TRANSPARENT_6: 'rgba(9, 38, 68, 0.6)',
  OXFORD_TRANSPARENT_7: 'rgba(9, 38, 68, 0.7)',
  OXFORD_TRANSPARENT_8: 'rgba(9, 38, 68, 0.8)',
  OXFORD_TRANSPARENT_9: 'rgba(9, 38, 68, 0.9)',
  PRUSSIAN: '#0F3859',
  PRUSSIAN_TRANSPARENT_1: 'rgba(15, 56, 89, 0.1)',
  PRUSSIAN_TRANSPARENT_2: 'rgba(15, 56, 89, 0.2)',
  PRUSSIAN_TRANSPARENT_3: 'rgba(15, 56, 89, 0.3)',
  PRUSSIAN_TRANSPARENT_4: 'rgba(15, 56, 89, 0.4)',
  PRUSSIAN_TRANSPARENT_5: 'rgba(15, 56, 89, 0.5)',
  PRUSSIAN_TRANSPARENT_6: 'rgba(15, 56, 89, 0.6)',
  PRUSSIAN_TRANSPARENT_7: 'rgba(15, 56, 89, 0.7)',
  PRUSSIAN_TRANSPARENT_8: 'rgba(15, 56, 89, 0.8)',
  PRUSSIAN_TRANSPARENT_9: 'rgba(15, 56, 89, 0.9)',
  DARK_IMPERIAL: '#11426B',
  DARK_IMPERIAL_TRANSPARENT_1: 'rgba(15, 56, 89, 0.1)',
  DARK_IMPERIAL_TRANSPARENT_2: 'rgba(15, 56, 89, 0.2)',
  DARK_IMPERIAL_TRANSPARENT_3: 'rgba(15, 56, 89, 0.3)',
  DARK_IMPERIAL_TRANSPARENT_4: 'rgba(15, 56, 89, 0.4)',
  DARK_IMPERIAL_TRANSPARENT_5: 'rgba(15, 56, 89, 0.5)',
  DARK_IMPERIAL_TRANSPARENT_6: 'rgba(15, 56, 89, 0.6)',
  DARK_IMPERIAL_TRANSPARENT_7: 'rgba(15, 56, 89, 0.7)',
  DARK_IMPERIAL_TRANSPARENT_8: 'rgba(15, 56, 89, 0.8)',
  DARK_IMPERIAL_TRANSPARENT_9: 'rgba(15, 56, 89, 0.9)',
  GREEN_BLUE: '#0B62AD',
  CYAN_CORN_FLOWER: '#0F7DC2',
  RICH_ELETRIC_BLUE: '#1791D0',
  BRIGHT_CERULEAN: '#31B0DB',
  BLUE_JEANS: '#64C1ED',
  FLAME: '#D64B26',
  DEEP_CARROT_ORANGE: '#F26132',
  BLACK: '#010202',
  LAVENDER_GRAY: '#C7C6C9',
  GAINS_BORO: '#DCDBDE',
  PLATINUM: '#E6E8E7',
  COLOR_BLUE: '#0078ff',
  COLOR_BLUE_LIGHTER: '#409aff',
  COLOR_BLUE_DARKER: '#005abf',
  COLOR_BLUE_TRANSPARENT_1: 'rgba(0, 120, 255, 0.1)',
  COLOR_BLUE_TRANSPARENT_2: 'rgba(0, 120, 255, 0.2)',
  COLOR_BLUE_TRANSPARENT_3: 'rgba(0, 120, 255, 0.3)',
  COLOR_BLUE_TRANSPARENT_4: 'rgba(0, 120, 255, 0.4)',
  COLOR_BLUE_TRANSPARENT_5: 'rgba(0, 120, 255, 0.5)',
  COLOR_BLUE_TRANSPARENT_6: 'rgba(0, 120, 255, 0.6)',
  COLOR_BLUE_TRANSPARENT_7: 'rgba(0, 120, 255, 0.7)',
  COLOR_BLUE_TRANSPARENT_8: 'rgba(0, 120, 255, 0.8)',
  COLOR_BLUE_TRANSPARENT_9: 'rgba(0, 120, 255, 0.9)',
  COLOR_AQUA: '#1bbcea',
  COLOR_AQUA_LIGHTER: '#54cdef',
  COLOR_AQUA_DARKER: '#148db0',
  COLOR_AQUA_TRANSPARENT_1: 'rgba(27, 188, 234, 0.1)',
  COLOR_AQUA_TRANSPARENT_2: 'rgba(27, 188, 234, 0.2)',
  COLOR_AQUA_TRANSPARENT_3: 'rgba(27, 188, 234, 0.3)',
  COLOR_AQUA_TRANSPARENT_4: 'rgba(27, 188, 234, 0.4)',
  COLOR_AQUA_TRANSPARENT_5: 'rgba(27, 188, 234, 0.5)',
  COLOR_AQUA_TRANSPARENT_6: 'rgba(27, 188, 234, 0.6)',
  COLOR_AQUA_TRANSPARENT_7: 'rgba(27, 188, 234, 0.7)',
  COLOR_AQUA_TRANSPARENT_8: 'rgba(27, 188, 234, 0.8)',
  COLOR_AQUA_TRANSPARENT_9: 'rgba(27, 188, 234, 0.9)',
  COLOR_GREEN: '#0f9e3e',
  COLOR_GREEN_LIGHTER: '#4bb66e',
  COLOR_GREEN_DARKER: '#0b772f',
  COLOR_GREEN_TRANSPARENT_1: 'rgba(15, 158, 62, 0.1)',
  COLOR_GREEN_TRANSPARENT_2: 'rgba(15, 158, 62, 0.2)',
  COLOR_GREEN_TRANSPARENT_3: 'rgba(15, 158, 62, 0.3)',
  COLOR_GREEN_TRANSPARENT_4: 'rgba(15, 158, 62, 0.4)',
  COLOR_GREEN_TRANSPARENT_5: 'rgba(15, 158, 62, 0.5)',
  COLOR_GREEN_TRANSPARENT_6: 'rgba(15, 158, 62, 0.6)',
  COLOR_GREEN_TRANSPARENT_7: 'rgba(15, 158, 62, 0.7)',
  COLOR_GREEN_TRANSPARENT_8: 'rgba(15, 158, 62, 0.8)',
  COLOR_GREEN_TRANSPARENT_9: 'rgba(15, 158, 62, 0.9)',
  COLOR_YELLOW: '#ffd900',
  COLOR_YELLOW_LIGHTER: '#ffe340',
  COLOR_YELLOW_DARKER: '#bfa300',
  COLOR_YELLOW_TRANSPARENT_1: 'rgba(255, 217, 0, 0.1)',
  COLOR_YELLOW_TRANSPARENT_2: 'rgba(255, 217, 0, 0.2)',
  COLOR_YELLOW_TRANSPARENT_3: 'rgba(255, 217, 0, 0.3)',
  COLOR_YELLOW_TRANSPARENT_4: 'rgba(255, 217, 0, 0.4)',
  COLOR_YELLOW_TRANSPARENT_5: 'rgba(255, 217, 0, 0.5)',
  COLOR_YELLOW_TRANSPARENT_6: 'rgba(255, 217, 0, 0.6)',
  COLOR_YELLOW_TRANSPARENT_7: 'rgba(255, 217, 0, 0.7)',
  COLOR_YELLOW_TRANSPARENT_8: 'rgba(255, 217, 0, 0.8)',
  COLOR_YELLOW_TRANSPARENT_9: 'rgba(255, 217, 0, 0.9)',
  COLOR_ORANGE: '#ff9700',
  COLOR_ORANGE_LIGHTER: '#ffb140',
  COLOR_ORANGE_DARKER: '#bf7100',
  COLOR_ORANGE_TRANSPARENT_1: 'rgba(255, 151, 0, 0.1)',
  COLOR_ORANGE_TRANSPARENT_2: 'rgba(255, 151, 0, 0.2)',
  COLOR_ORANGE_TRANSPARENT_3: 'rgba(255, 151, 0, 0.3)',
  COLOR_ORANGE_TRANSPARENT_4: 'rgba(255, 151, 0, 0.4)',
  COLOR_ORANGE_TRANSPARENT_5: 'rgba(255, 151, 0, 0.5)',
  COLOR_ORANGE_TRANSPARENT_6: 'rgba(255, 151, 0, 0.6)',
  COLOR_ORANGE_TRANSPARENT_7: 'rgba(255, 151, 0, 0.7)',
  COLOR_ORANGE_TRANSPARENT_8: 'rgba(255, 151, 0, 0.8)',
  COLOR_ORANGE_TRANSPARENT_9: 'rgba(255, 151, 0, 0.9)',
  COLOR_PURPLE: '#5058e6',
  COLOR_PURPLE_LIGHTER: '#7c82ec',
  COLOR_PURPLE_DARKER: '#3c42ad',
  COLOR_PURPLE_TRANSPARENT_1: 'rgba(80, 88, 230, 0.1)',
  COLOR_PURPLE_TRANSPARENT_2: 'rgba(80, 88, 230, 0.2)',
  COLOR_PURPLE_TRANSPARENT_3: 'rgba(80, 88, 230, 0.3)',
  COLOR_PURPLE_TRANSPARENT_4: 'rgba(80, 88, 230, 0.4)',
  COLOR_PURPLE_TRANSPARENT_5: 'rgba(80, 88, 230, 0.5)',
  COLOR_PURPLE_TRANSPARENT_6: 'rgba(80, 88, 230, 0.6)',
  COLOR_PURPLE_TRANSPARENT_7: 'rgba(80, 88, 230, 0.7)',
  COLOR_PURPLE_TRANSPARENT_8: 'rgba(80, 88, 230, 0.8)',
  COLOR_PURPLE_TRANSPARENT_9: 'rgba(80, 88, 230, 0.9)',
  COLOR_RED: '#ff1b30',
  COLOR_RED_LIGHTER: '#ff5464',
  COLOR_RED_DARKER: '#bf1424',
  COLOR_RED_TRANSPARENT_1: 'rgba(255, 27, 48, 0.1)',
  COLOR_RED_TRANSPARENT_2: 'rgba(255, 27, 48, 0.2)',
  COLOR_RED_TRANSPARENT_3: 'rgba(255, 27, 48, 0.3)',
  COLOR_RED_TRANSPARENT_4: 'rgba(255, 27, 48, 0.4)',
  COLOR_RED_TRANSPARENT_5: 'rgba(255, 27, 48, 0.5)',
  COLOR_RED_TRANSPARENT_6: 'rgba(255, 27, 48, 0.6)',
  COLOR_RED_TRANSPARENT_7: 'rgba(255, 27, 48, 0.7)',
  COLOR_RED_TRANSPARENT_8: 'rgba(255, 27, 48, 0.8)',
  COLOR_RED_TRANSPARENT_9: 'rgba(255, 27, 48, 0.9)',
  COLOR_GREY: '#eff2f7',
  COLOR_GREY_LIGHTER: '#f3f5f9',
  COLOR_GREY_DARKER: '#b3b6b9',
  COLOR_GREY_TRANSPARENT_1: 'rgba(239, 242, 247, 0.1)',
  COLOR_GREY_TRANSPARENT_2: 'rgba(239, 242, 247, 0.2)',
  COLOR_GREY_TRANSPARENT_3: 'rgba(239, 242, 247, 0.3)',
  COLOR_GREY_TRANSPARENT_4: 'rgba(239, 242, 247, 0.4)',
  COLOR_GREY_TRANSPARENT_5: 'rgba(239, 242, 247, 0.5)',
  COLOR_GREY_TRANSPARENT_6: 'rgba(239, 242, 247, 0.6)',
  COLOR_GREY_TRANSPARENT_7: 'rgba(239, 242, 247, 0.7)',
  COLOR_GREY_TRANSPARENT_8: 'rgba(239, 242, 247, 0.8)',
  COLOR_GREY_TRANSPARENT_9: 'rgba(239, 242, 247, 0.9)',
  COLOR_SILVER: '#eff2f7',
  COLOR_SILVER_LIGHTER: '#f3f5f9',
  COLOR_SILVER_DARKER: '#b3b6b9',
  COLOR_SILVER_TRANSPARENT_1: 'rgba(239, 242, 247, 0.1)',
  COLOR_SILVER_TRANSPARENT_2: 'rgba(239, 242, 247, 0.2)',
  COLOR_SILVER_TRANSPARENT_3: 'rgba(239, 242, 247, 0.3)',
  COLOR_SILVER_TRANSPARENT_4: 'rgba(239, 242, 247, 0.4)',
  COLOR_SILVER_TRANSPARENT_5: 'rgba(239, 242, 247, 0.5)',
  COLOR_SILVER_TRANSPARENT_6: 'rgba(239, 242, 247, 0.6)',
  COLOR_SILVER_TRANSPARENT_7: 'rgba(239, 242, 247, 0.7)',
  COLOR_SILVER_TRANSPARENT_8: 'rgba(239, 242, 247, 0.8)',
  COLOR_SILVER_TRANSPARENT_9: 'rgba(239, 242, 247, 0.9)',
  COLOR_MUTED: '#899fc4',
  COLOR_MUTED_LIGHTER: '#a7b7d3',
  COLOR_MUTED_DARKER: '#677793',
  COLOR_MUTED_TRANSPARENT_1: 'rgba(137, 159, 196, 0.1)',
  COLOR_MUTED_TRANSPARENT_2: 'rgba(137, 159, 196, 0.2)',
  COLOR_MUTED_TRANSPARENT_3: 'rgba(137, 159, 196, 0.3)',
  COLOR_MUTED_TRANSPARENT_4: 'rgba(137, 159, 196, 0.4)',
  COLOR_MUTED_TRANSPARENT_5: 'rgba(137, 159, 196, 0.5)',
  COLOR_MUTED_TRANSPARENT_6: 'rgba(137, 159, 196, 0.6)',
  COLOR_MUTED_TRANSPARENT_7: 'rgba(137, 159, 196, 0.7)',
  COLOR_MUTED_TRANSPARENT_8: 'rgba(137, 159, 196, 0.8)',
  COLOR_MUTED_TRANSPARENT_9: 'rgba(137, 159, 196, 0.9)',
  COLOR_DARK: '#1f2225',
  COLOR_DARK_LIGHTER: '#57595c',
  COLOR_DARK_DARKER: '#000000',
  COLOR_DARK_TRANSPARENT_1: 'rgba(31, 34, 37, 0.1)',
  COLOR_DARK_TRANSPARENT_2: 'rgba(31, 34, 37, 0.2)',
  COLOR_DARK_TRANSPARENT_3: 'rgba(31, 34, 37, 0.3)',
  COLOR_DARK_TRANSPARENT_4: 'rgba(31, 34, 37, 0.4)',
  COLOR_DARK_TRANSPARENT_5: 'rgba(31, 34, 37, 0.5)',
  COLOR_DARK_TRANSPARENT_6: 'rgba(31, 34, 37, 0.6)',
  COLOR_DARK_TRANSPARENT_7: 'rgba(31, 34, 37, 0.7)',
  COLOR_DARK_TRANSPARENT_8: 'rgba(31, 34, 37, 0.8)',
  COLOR_DARK_TRANSPARENT_9: 'rgba(31, 34, 37, 0.9)',
  COLOR_BLACK: '#000000',
  COLOR_BLACK_LIGHTER: '#404040',
  COLOR_BLACK_DARKER: '#000000',
  COLOR_BLACK_TRANSPARENT_1: 'rgba(0, 0, 0, 0.1)',
  COLOR_BLACK_TRANSPARENT_2: 'rgba(0, 0, 0, 0.2)',
  COLOR_BLACK_TRANSPARENT_3: 'rgba(0, 0, 0, 0.3)',
  COLOR_BLACK_TRANSPARENT_4: 'rgba(0, 0, 0, 0.4)',
  COLOR_BLACK_TRANSPARENT_5: 'rgba(0, 0, 0, 0.5)',
  COLOR_BLACK_TRANSPARENT_6: 'rgba(0, 0, 0, 0.6)',
  COLOR_BLACK_TRANSPARENT_7: 'rgba(0, 0, 0, 0.7)',
  COLOR_BLACK_TRANSPARENT_8: 'rgba(0, 0, 0, 0.8)',
  COLOR_BLACK_TRANSPARENT_9: 'rgba(0, 0, 0, 0.9)',
  COLOR_WHITE: '#FFFFFF',
  COLOR_WHITE_TRANSPARENT_1: 'rgba(255, 255, 255, 0.1)',
  COLOR_WHITE_TRANSPARENT_2: 'rgba(255, 255, 255, 0.2)',
  COLOR_WHITE_TRANSPARENT_3: 'rgba(255, 255, 255, 0.3)',
  COLOR_WHITE_TRANSPARENT_4: 'rgba(255, 255, 255, 0.4)',
  COLOR_WHITE_TRANSPARENT_5: 'rgba(255, 255, 255, 0.5)',
  COLOR_WHITE_TRANSPARENT_6: 'rgba(255, 255, 255, 0.6)',
  COLOR_WHITE_TRANSPARENT_7: 'rgba(255, 255, 255, 0.7)',
  COLOR_WHITE_TRANSPARENT_8: 'rgba(255, 255, 255, 0.8)',
  COLOR_WHITE_TRANSPARENT_9: 'rgba(255, 255, 255, 0.9)',
  COLOR_INDIGO: '#7b2cff',
  COLOR_INDIGO_LIGHTER: '#9c61ff',
  COLOR_INDIGO_DARKER: '#5c21bf',
  COLOR_INDIGO_TRANSPARENT_1: 'rgba(123, 44, 255, 0.1)',
  COLOR_INDIGO_TRANSPARENT_2: 'rgba(123, 44, 255, 0.2)',
  COLOR_INDIGO_TRANSPARENT_3: 'rgba(123, 44, 255, 0.3)',
  COLOR_INDIGO_TRANSPARENT_4: 'rgba(123, 44, 255, 0.4)',
  COLOR_INDIGO_TRANSPARENT_5: 'rgba(123, 44, 255, 0.5)',
  COLOR_INDIGO_TRANSPARENT_6: 'rgba(123, 44, 255, 0.6)',
  COLOR_INDIGO_TRANSPARENT_7: 'rgba(123, 44, 255, 0.7)',
  COLOR_INDIGO_TRANSPARENT_8: 'rgba(123, 44, 255, 0.8)',
  COLOR_INDIGO_TRANSPARENT_9: 'rgba(123, 44, 255, 0.9)',
  COLOR_PINK: '#ff107e',
  COLOR_PINK_LIGHTER: '#ff4c9e',
  COLOR_PINK_DARKER: '#bf0c5f',
  COLOR_PINK_TRANSPARENT_1: 'rgba(255, 16, 126, 0.1)',
  COLOR_PINK_TRANSPARENT_2: 'rgba(255, 16, 126, 0.2)',
  COLOR_PINK_TRANSPARENT_3: 'rgba(255, 16, 126, 0.3)',
  COLOR_PINK_TRANSPARENT_4: 'rgba(255, 16, 126, 0.4)',
  COLOR_PINK_TRANSPARENT_5: 'rgba(255, 16, 126, 0.5)',
  COLOR_PINK_TRANSPARENT_6: 'rgba(255, 16, 126, 0.6)',
  COLOR_PINK_TRANSPARENT_7: 'rgba(255, 16, 126, 0.7)',
  COLOR_PINK_TRANSPARENT_8: 'rgba(255, 16, 126, 0.8)',
  COLOR_PINK_TRANSPARENT_9: 'rgba(255, 16, 126, 0.9)',
  COLOR_LIME: '#75c713',
  COLOR_LIME_LIGHTER: '#98d54e',
  COLOR_LIME_DARKER: '#58950e',
  COLOR_LIME_TRANSPARENT_1: 'rgba(117, 199, 19, 0.1)',
  COLOR_LIME_TRANSPARENT_2: 'rgba(117, 199, 19, 0.2)',
  COLOR_LIME_TRANSPARENT_3: 'rgba(117, 199, 19, 0.3)',
  COLOR_LIME_TRANSPARENT_4: 'rgba(117, 199, 19, 0.4)',
  COLOR_LIME_TRANSPARENT_5: 'rgba(117, 199, 19, 0.5)',
  COLOR_LIME_TRANSPARENT_6: 'rgba(117, 199, 19, 0.6)',
  COLOR_LIME_TRANSPARENT_7: 'rgba(117, 199, 19, 0.7)',
  COLOR_LIME_TRANSPARENT_8: 'rgba(117, 199, 19, 0.8)',
  COLOR_LIME_TRANSPARENT_9: 'rgba(117, 199, 19, 0.9)',
  COLOR_TEAL: '#04c5c5',
  COLOR_TEAL_LIGHTER: '#43d4d4',
  COLOR_TEAL_DARKER: '#039494',
  COLOR_TEAL_TRANSPARENT_1: 'rgba(4, 197, 197, 0.1)',
  COLOR_TEAL_TRANSPARENT_2: 'rgba(4, 197, 197, 0.2)',
  COLOR_TEAL_TRANSPARENT_3: 'rgba(4, 197, 197, 0.3)',
  COLOR_TEAL_TRANSPARENT_4: 'rgba(4, 197, 197, 0.4)',
  COLOR_TEAL_TRANSPARENT_5: 'rgba(4, 197, 197, 0.5)',
  COLOR_TEAL_TRANSPARENT_6: 'rgba(4, 197, 197, 0.6)',
  COLOR_TEAL_TRANSPARENT_7: 'rgba(4, 197, 197, 0.7)',
  COLOR_TEAL_TRANSPARENT_8: 'rgba(4, 197, 197, 0.8)',
  COLOR_TEAL_TRANSPARENT_9: 'rgba(4, 197, 197, 0.9)',
}
