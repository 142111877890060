import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'

export default function Edit() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const travelExperienceToEdit = {
    title: location.state.travelExperienceDetails.title,
    desc: location.state.travelExperienceDetails.desc,
    address: location.state.travelExperienceDetails.address,
    city: {
      label: location.state.travelExperienceDetails.city,
      value: location.state.travelExperienceDetails.cityId,
    },
    flag: location.state.travelExperienceDetails.flag,
    image: location.state.travelExperienceDetails.image,
    links:
      location.state.travelExperienceDetails.links === 'undefined'
        ? '[]'
        : location.state.travelExperienceDetails.links,
    category: {
      label: location.state.travelExperienceDetails.categoryDesc,
      value: location.state.travelExperienceDetails.categoryId,
    },
  }

  useEffect(() => {
    async function getExperienceCategory() {
      try {
        setLoading(true)

        const resCategory = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/getexperiencecategorytocreate`,
        )

        setCategories(resCategory.data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        return toast.error(
          'Não foi possível obter o categoria das experiências.',
        )
      }
    }

    getExperienceCategory()
  }, [])

  async function onValidate(data) {
    if (!data.city) {
      toast.info('Selecione uma cidade.')

      return false
    }

    if (!data.category) {
      toast.info('Selecione uma categoria.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('id', location.state.travelExperienceDetails.id)
      formData.append('title', data.title)
      formData.append('desc', data.desc)
      formData.append('image', data.image)
      formData.append('city', data.city.label)
      formData.append('cityId', data.city.value)
      formData.append('categoryId', data.category.value)
      formData.append('links', data.links)

      if (data.flag) {
        formData.append('flag', data.flag)
      }

      if (data.address) {
        formData.append('address', data.address)
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/editexperience/${location.state.travelExperienceDetails.id}`,
        formData,
      )

      setLoading(false)

      toast.success('Experiência editada com sucesso!')

      navigate(-1)
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível editar a experiência, por favor tente mais tarde.',
      )
    }
  }

  async function onDelete(data) {
    try {
      setLoading(true)

      if (
        window.confirm('Você tem certeza que deseja excluir essa experiência?')
      ) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/deleteexperience/${location.state.travelExperienceDetails.id}`,
        )

        toast.success(`Experiência excluída com sucesso!`)

        setLoading(false)

        return navigate('/experiences')
      }
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível excluir a experiência, por favor tente mais tarde.',
      )
    }
  }

  return (
    <div>
      <PageTitle
        title={travelExperienceToEdit ? `${travelExperienceToEdit.title}` : ''}
      />

      <Panel>
        <PanelHeader title={'Editar'} noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackDelete={onDelete}
            defaultValue={travelExperienceToEdit}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Título',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
                maxLength: 200,
              },
              {
                type: 'city',
                name: 'city',
                label: 'Cidade',
                placeholder: 'Pesquise por uma cidade...',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'select',
                name: 'category',
                label: 'Categoria',
                placeholder: 'Selecione',
                options: categories,
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'text',
                name: 'flag',
                label: 'Tags',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'image',
                name: 'image',
                label: 'Imagem',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'keyvalue',
                name: 'links',
                label: 'Links úteis',
                placeholder: 'Descrição',
                subPlaceholder2: 'Link',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'text',
                name: 'address',
                label: 'Endereço',
                required: false,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
                maxLength: 200,
              },
              {
                type: 'html',
                name: 'desc',
                label: 'Descrição',
                required: true,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}
