import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import Table from '~/components/Table'
import { LinkEdit, LinkRegisterNewTraveler } from '~/components/Link/styles'
import PageTitle from '~/components/PageTitle'
import { getDateFormated } from '~/functions/Date'

export default function IntegrationAgencyList() {
  const { axiosApis } = GetAxios()
  const [data, setData] = useState([])
  const [canCreate, setCanCreate] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const res = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3`,
        )

        setData(res.data)

        const resCanCreate = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3/to-create`,
        )

        setCanCreate(resCanCreate.data.length > 0)

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    getSource()
  }, [])

  return (
    <Container>
      <PageTitle title='Integrações' />

      <Panel>
        <PanelHeader noButton>
          {canCreate && (
            <LinkRegisterNewTraveler to='create'>
              Nova integração
            </LinkRegisterNewTraveler>
          )}
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Consolidadora',
                accessor: 'consolidator',
                width: 180,
              },
              {
                Header: 'URL',
                accessor: 'url',
                width: 300,
              },
              {
                Header: 'Usuário',
                accessor: 'username',
                width: 120,
              },
              {
                Header: 'Cadastrado em',
                accessor: 'createdAt',
                width: 120,
                Cell: props => {
                  const date = getDateFormated(
                    props.row.original.createdAt,
                    'DD/MM/YYYY',
                  )

                  const time = getDateFormated(
                    props.row.original.createdAt,
                    'HH:mm',
                  )

                  return (
                    <span>
                      {date} <br />
                      {time}
                    </span>
                  )
                },
              },
              {
                Header: 'Atualizado em',
                accessor: 'updatedAt',
                width: 120,
                Cell: props => {
                  const date = getDateFormated(
                    props.row.original.updatedAt,
                    'DD/MM/YYYY',
                  )

                  const time = getDateFormated(
                    props.row.original.updatedAt,
                    'HH:mm',
                  )

                  return (
                    <span>
                      {date} <br />
                      {time}
                    </span>
                  )
                },
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <LinkEdit
                      to={`details`}
                      state={{ integration: props.row.original }}
                    >
                      Detalhes
                    </LinkEdit>
                  )
                },
                width: 80,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

const Container = styled.div``
