import styled from 'styled-components'

export const Container = styled.div`
  z-index: 9999999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  margin: auto;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DeleteButton = styled.button`
  font-weight: bold;
  padding: 8px 10px;
  color: #ff7d70;
  background-color: transparent;
  border: none;
  font-size: 12px;

  &:hover {
    background-color: #00000011;
    text-decoration: none;
    border-radius: 4px;
  }
`

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 16px; */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 8px;
`

export const Content = styled.div`
  padding: 8px;
`

export const Modal = styled.div`
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 16px #000;
  width: ${props => props.width};
  height: ${props => props.height};
  overflow-y: ${props => props.scroll};
  max-height: 100%;
  z-index: 1;
`
