import { GoogleMap, Marker } from '@react-google-maps/api'
import React, { useState } from 'react'
import styled from 'styled-components'
import { getDateDiff, getDateFormated, getMoment } from '~/functions/Date'

import { getPinIcon } from '~/icons'
import MapDefaultDirections from './MapDefaultDirections'
import MapDefaultFilters from './MapDefaultFilters'
import MapDefaultModal from './MapDefaultModal'

function MapDefault({
  routes = [],
  markers = [],
  center = {
    lat: 0,
    lng: 0,
  },
  zoom = 2,
  token,
  travel,
}) {
  const [markersToList, setMarkersToList] = useState(markers)
  const [routesToList, setRoutesToList] = useState(routes)
  const [mapCenter, setMapCenter] = useState(center)
  const [mapZoom, setMapZoom] = useState(zoom)
  const [markerToDetails, setMarkerToDetails] = useState()

  async function onClickMarker(marker) {
    setMapCenter({ lat: marker.lat, lng: marker.lng })
    setMapZoom(16)
    setMarkerToDetails(marker)
  }

  async function onCloseModal() {
    setMarkerToDetails(undefined)
  }

  async function filterMarkersAndRoutes(type) {
    const newMarkersToList = []

    markers.map(x => {
      if (x.type === type) {
        newMarkersToList.push(x)
      }
    })

    if (newMarkersToList.length > 0) {
      setMapCenter({
        lat: newMarkersToList[0].lat,
        lng: newMarkersToList[0].lng,
      })
      setMapZoom(12)
    } else {
      setMapCenter(center)
      setMapZoom(12)
    }

    setRoutesToList([])

    setMarkersToList(newMarkersToList)
  }

  async function filterMarkersAndRoutesByDate(date) {
    const newMarkersToList = []

    markers.map(x => {
      if (x.date && getDateFormated(x.date, 'DD/MM/YYYY') === date) {
        newMarkersToList.push(x)
      }
    })

    const newRoutesToList = []

    routes.map(x => {
      if (x.date && getDateFormated(x.date, 'DD/MM/YYYY') === date) {
        newRoutesToList.push(x)
      }
    })

    if (newMarkersToList.length > 0) {
      setMapCenter({
        lat: newMarkersToList[0].lat,
        lng: newMarkersToList[0].lng,
      })

      setMapZoom(12)
    } else {
      setMapCenter(center)

      setMapZoom(12)
    }

    setRoutesToList(newRoutesToList)

    setMarkersToList(newMarkersToList)
  }

  async function cleanFilters() {
    setMarkersToList(markers)
    setRoutesToList(routes)
    setMapCenter(center)
    setMapZoom(12)
  }

  async function getDates() {
    const newDates = []

    routes.map(e => {
      if (
        !newDates.find(x => x.value === getDateFormated(e.date, 'DD/MM/YYYY'))
      ) {
        newDates.push({
          label: getDateFormated(e.date, 'DD/MM/YYYY'),
          value: getDateFormated(e.date, 'DD/MM/YYYY'),
        })
      }
    })

    markers.map(e => {
      if (
        e.date &&
        !newDates.find(x => x.value === getDateFormated(e.date, 'DD/MM/YYYY'))
      ) {
        newDates.push({
          label: getDateFormated(e.date, 'DD/MM/YYYY'),
          value: getDateFormated(e.date, 'DD/MM/YYYY'),
        })
      }
    })

    const travelDiffDays = getDateDiff(travel.start, travel.end).days + 1

    let travelStart = getMoment(travel.start)

    for (let i = 0; i <= travelDiffDays; i++) {
      newDates.map(x => {
        if (x.value === getDateFormated(travelStart, 'DD/MM/YYYY')) {
          x.label = `Dia ${i + 1}`
        }
      })

      travelStart = getMoment(travelStart.add(1, 'days'))
    }

    return newDates
  }

  return (
    <Container>
      <GoogleMap
        mapContainerStyle={{
          width: markerToDetails ? '60%' : '100%',
          height: '100%',
          position: 'absolute',
          right: '0',
        }}
        center={mapCenter}
        zoom={mapZoom}
        clickableIcons={true}
      >
        {markersToList.map((e, i) => {
          return (
            <Marker
              key={i}
              title={e.data.label}
              zIndex={999 - i}
              onClick={() => onClickMarker(e)}
              position={{
                lat: e.lat,
                lng: e.lng,
              }}
              icon={{
                url: getPinIcon(e.type),
                scaledSize: {
                  width: 48,
                  height: 48,
                },
              }}
            />
          )
        })}

        {routesToList.map((e, i) => {
          return <MapDefaultDirections key={i} route={e} />
        })}
      </GoogleMap>

      {markerToDetails && (
        <MapDefaultModal
          marker={markerToDetails}
          onCloseModal={onCloseModal}
          token={token}
        />
      )}

      <MapDefaultFilters
        filter={filterMarkersAndRoutes}
        otherFilter={filterMarkersAndRoutesByDate}
        clean={cleanFilters}
        getDates={getDates}
        hide={!markerToDetails}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  border: 1px solid #ccc;
`

export default React.memo(MapDefault)
