import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
// import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Col } from 'reactstrap'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import IconButton from '~/components/IconButton'

export default function RelationshipCustomers() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [services, setServices] = useState([])
  const [tip, setTip] = useState([])

  useEffect(() => {
    onSubmit()
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      if (data == undefined) {
        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/service`,
        )
        setServices(response.data)
      } else {
        const result = {
          ...data,
          label: data.type?.label,
          value: data.type?.value,
        }

        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/service`,
          {
            params: result,
          },
        )
        setServices(response.data)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Container>
      <h1 className='page-header'>
        Lançamentos
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Tela contendo todos os lançamentos, sendo capaz de mudar status, editar ou excluir.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {
              return
            }}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'CustomerName',
                label: 'Nome do Cliente',
                placeholder: 'Nome do Cliente',
              },
              {
                type: 'text',
                name: 'travel',
                label: 'Viagem',
                placeholder: 'Viagem',
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Data do Início',
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Data do Fim',
              },
              {
                type: 'select',
                name: 'type',
                label: 'Tipo',
                options: [
                  {
                    label: 'Ticket aéreo',
                    value: 'Ticket aéreo',
                  },
                  {
                    label: 'Hotel',
                    value: 'Hotel',
                  },
                  {
                    label: 'Pacote',
                    value: 'Pacote',
                  },
                  {
                    label: 'Cruzeiro',
                    value: 'Cruzeiro',
                  },
                  {
                    label: 'Serviços',
                    value: 'Serviços',
                  },
                  {
                    label: 'Seguro',
                    value: 'Seguro',
                  },
                  {
                    label: 'Locação de carro',
                    value: 'Locação de carro',
                  },
                  {
                    label: 'Rodoviário',
                    value: 'Rodoviário',
                  },
                  {
                    label: 'Ingresso',
                    value: 'Ingresso',
                  },
                  {
                    label: 'Visto',
                    value: 'Visto',
                  },
                  {
                    label: 'Trem',
                    value: 'Trem',
                  },
                  {
                    label: 'Transfer',
                    value: 'Transfer',
                  },
                ],
                maxLength: 150,
              },
              {
                type: 'check',
                name: 'status',
                label: 'Serviço validado?',
                placeholder: 'Serviço validado?',
                sm: 12,
                md: 6,
                lg: 2,
                xl: 2,
              },
            ]}
          />

          <Col sm={12} lg={4} xl={3}></Col>
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table data={services} setServices={loading} onSubmit={onSubmit} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
