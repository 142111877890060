export const SET_CONVERSATIONS = 'SET_CONVERSATIONS'
export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS'
export const READ_CONVERSATIONS = 'READ_CONVERSATIONS'

export const setConversations = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CONVERSATIONS,
  })
}

export const clearConversationsAction = dispatch => {
  dispatch({
    type: CLEAR_CONVERSATIONS,
  })
}

export const readConversation = (data, dispatch) => {
  dispatch({
    data: data,
    type: READ_CONVERSATIONS,
  })
}
