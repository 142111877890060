import { useEffect, useState } from 'react'
import { getPhoneFormated } from '~/functions/Phone'
import { axiosApis } from '~/services/api'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setClientsData, setCustomer } from '~/store/actions/chatAction'
import { getClientsDataForContacts, getCustomerInfo } from '~/services/chat'

export default function UseFetchClientInformation(
  currentConversation,
  auth,
  conversations,
) {
  const [contacts, setContacts] = useState([])
  const [travelHistory, setTravelHistory] = useState([])
  const [tripsHistory, setTripsHistory] = useState([])
  const [loadingHistory, setLoadingHistory] = useState(true)
  const [loadingService, setLoadingService] = useState(true)
  const [loadingCustomer, setLoadingCustomer] = useState(true)

  const dispatch = useDispatch()

  const customer = useSelector(state => state.chatReducer.customer)

  const alldocs = { docs: [] }

  const controller = new AbortController()

  useEffect(() => {
    let cancel = false
    async function loadSource() {
      if (cancel) return
      if (currentConversation?.phoneNumber) {
        getAllCustomerData(currentConversation.phoneNumber)
      }
    }

    loadSource()

    return () => {
      cancel = true
      controller.abort()
      setLoadingHistory(true)
      setLoadingCustomer(true)
      setTravelHistory([])
      setCustomer(null, dispatch)
    }
  }, [currentConversation?.phoneNumber])

  const getAllCustomerData = async number => {
    let formatedNumber = `${number?.replace(/55/, '')}`
    try {
      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/gettraveler`,
        { params: { phone: getPhoneFormated(formatedNumber) } },
      )

      const clientInfoWithouNumberNine = await getCustomerInfo(formatedNumber)
      if (clientInfoWithouNumberNine?.data?.items.length > 0) {
        setCustomer(clientInfoWithouNumberNine.data.items[0], dispatch)
        setLoadingCustomer(false)
      }

      if (response?.data?.length > 0) {
        const travelerData = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/gettraveler/${response.data[0].id}`,
        )
        setTravelHistory(travelerData.data)
        setLoadingHistory(false)
      } else {
        let formatNumberWithNine = `${currentConversation.phoneNumber.replace(
          /55/,
          '',
        )}`
        const ddi = formatNumberWithNine.substring(0, 2)
        formatNumberWithNine = formatNumberWithNine.substring(2)
        formatedNumber = ddi + '9' + formatNumberWithNine
        const response2 = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/gettraveler`,
          { params: { phone: getPhoneFormated(formatedNumber) } },
        )
        if (response2.data?.length > 0) {
          const travelerData = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/gettraveler/${response2.data[0].id}`,
          )
          setTravelHistory(travelerData.data)
          setLoadingHistory(false)
        } else {
          setLoadingHistory(false)
        }
      }
      const clientInfo = await getCustomerInfo(formatedNumber)
      if (clientInfo?.data?.items.length > 0) {
        setCustomer(clientInfo.data.items[0], dispatch)
        setLoadingCustomer(false)
      } else {
        setLoadingCustomer(false)
        setLoadingService(false)
      }
    } catch (error) {
      console.log(error)
    }

    //Atualiza as informações nos Contacts
    const phoneList = conversations
      .filter(c => !c.phoneNumber.includes('group'))
      .map(c => c.phoneNumber)
    const response = await getClientsDataForContacts(phoneList)
    if (response?.data) setClientsData(response.data, dispatch)
  }

  useEffect(() => {
    if (alldocs.state == 'done') {
      const filterByUser = alldocs.rows.filter(
        row => row.doc.tenant == auth?.tenant && row.doc?.phone != null,
      )
      setContacts(filterByUser)
    }
    return () => {
      controller.abort()
    }
  }, [alldocs])

  useEffect(() => {
    const loadSource = async () => {
      if (customer) {
        try {
          const customerHistory = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/customerhistory/${customer.id}`,
          )
          setTripsHistory(customerHistory.data)
          setLoadingService(false)
        } catch (e) {
          console.log(e)
          setLoadingService(false)
        }
      }
    }
    loadSource()
    return () => {
      controller.abort()
      setLoadingService(true)
      setTripsHistory([])
    }
  }, [customer])

  return {
    customer,
    contacts,
    travelHistory,
    tripsHistory,
    loadingHistory,
    loadingService,
    loadingCustomer,
    getAllCustomerData,
  }
}

UseFetchClientInformation.propTypes = {
  currentConversation: {
    photo: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    lastMessage: PropTypes.string,
    read: PropTypes.bool,
    delivered: PropTypes.bool,
    sent: PropTypes.bool,
  },
  auth: PropTypes.node,
}
