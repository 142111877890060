import { useState, useEffect } from 'react'
import PageTitle from '~/components/PageTitle'
import { useLocation, useNavigate } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import DivForHTML from '~/components/DivForHTML'
import DefaultForm from '~/components/DefaultForm'
import { getDateFormated } from '~/functions/Date'
import styled from 'styled-components'
import LoadingContainer from '~/components/LoadingContainer'

export default function Details() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [entityDetails, setEntityDetails] = useState()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperiencedestiny/${location.state?.travelExperienceId}`,
        )

        setEntityDetails(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        return navigate(-1)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    try {
      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldestiny`,
        {
          start: data.start,
          end: data.end,
          oldStart: location.state.start,
          oldEnd: location.state.end,
          travelId: location.state.travelId,
          travelExperienceId: location.state.travelExperienceId,
        },
      )

      return navigate(-1)
    } catch (error) {
      return navigate(-1)
    }
  }

  return (
    <Container>
      <PageTitle title={'Destino'} head={'h5'} />

      <div style={{ background: '#f4f4f4', padding: '8px' }}>
        {entityDetails && !loading && (
          <div>
            <Row style={{ width: '100%' }}>
              <Col sm={12} lg={6}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    marginBottom: '16px',
                  }}
                >
                  {entityDetails.title && (
                    <span style={{ margin: '0 4px 4px 0' }}>
                      Título:{' '}
                      <LabelHighlight>{entityDetails.title}</LabelHighlight>
                    </span>
                  )}

                  {entityDetails.city && (
                    <span style={{ margin: '0 4px 4px 0' }}>
                      Cidade:{' '}
                      <LabelHighlight>{entityDetails.city}</LabelHighlight>
                    </span>
                  )}

                  {entityDetails.flag && (
                    <span style={{ margin: '0 4px 4px 0' }}>
                      Tags:{' '}
                      <LabelHighlight>{entityDetails.flag}</LabelHighlight>
                    </span>
                  )}
                </div>

                <DivForHTML text={entityDetails.desc} />

                <br />

                {entityDetails.entryAndHabit &&
                  entityDetails.entryAndHabit !== '<p><br></p>' && (
                    <>
                      <h6>Entrada no país e costumes</h6>
                      <DivForHTML text={entityDetails.entryAndHabit} />
                    </>
                  )}

                <br />

                {entityDetails.tips && entityDetails.tips !== '<p><br></p>' && (
                  <>
                    <h6>Dicas e informações úteis </h6>
                    <DivForHTML text={entityDetails.tips} />
                  </>
                )}

                {entityDetails.image && (
                  <img
                    style={{
                      width: '100%',
                      borderRadius: '6px',
                      marginBottom: '16px',
                    }}
                    src={`data:image/*;base64,${entityDetails.image}`}
                    alt='imagem'
                    onError={event => (event.target.style.display = 'none')}
                  />
                )}

                {!entityDetails.image && (
                  <div
                    style={{
                      width: '100%',
                      fontSize: '18px',
                      textAlign: 'center',
                    }}
                  >
                    <i
                      style={{
                        fontSize: '50px',
                      }}
                      className='fas fa-image'
                    />
                    <p>Nenhuma imagem adicionada</p>
                  </div>
                )}
              </Col>

              <Col sm={12} lg={6}>
                <DefaultForm
                  callbackSubmit={onSubmit}
                  defaultValue={{
                    start: getDateFormated(location.state.start, 'YYYY-MM-DD'),
                    end: getDateFormated(location.state.end, 'YYYY-MM-DD'),
                  }}
                  inputs={[
                    {
                      type: 'date',
                      name: 'start',
                      label: 'Início',
                      required: true,
                      sm: 12,
                      lg: 6,
                    },
                    {
                      type: 'date',
                      name: 'end',
                      label: 'Fim',
                      required: true,
                      sm: 12,
                      lg: 6,
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
        )}

        {loading && <LoadingContainer height='200px' />}
      </div>
    </Container>
  )
}

const LabelHighlight = styled.b`
  color: ${props => props.theme.colors.primary};
`
