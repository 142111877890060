import { LoadScript } from '@react-google-maps/api'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from '~/store/root'
import './config/ReactotronConfig'
import './locales/i18n'
import GlobalStyles from './styles/global'
import { useClearCache } from 'react-clear-cache'
import React, { useEffect } from 'react'
import RoutesController from './routes'
import { AuthContextWrapper } from './context/auth'
import { ThemeContextWrapper } from './context/theme'
import { FilterContextWrapper } from './context/filter'

const AppComponents = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache()

  useEffect(() => {
    async function getSource() {
      try {
        if (!isLatestVersion) {
          await emptyCacheStorage()
        }
      } catch (error) {
        console.log(error)
      }
    }

    getSource()

    return () => {}
  }, [isLatestVersion])

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthContextWrapper>
            <ThemeContextWrapper>
              <FilterContextWrapper>
                <BrowserRouter>
                  <RoutesController />

                  <GlobalStyles />

                  <ToastContainer
                    autoClose={4000}
                    style={{
                      fontSize: '16px',
                      fontWeight: 500,
                      zIndex: 100000,
                    }}
                  />

                  <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_API_KEY_GOOGLE_MAPS}
                    loadingElement={<span></span>}
                  />
                </BrowserRouter>
              </FilterContextWrapper>
            </ThemeContextWrapper>
          </AuthContextWrapper>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  )
}

const AppBuilder = () => {
  return AppComponents
}

const App = AppBuilder()

export default App
