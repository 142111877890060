import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import ReactTable from '~/components/Table'
import { Create } from './style'
// import { Container } from '~/styles/container'

export default function TableToList({
  value = [],
  loading,
  columns = [],
  title = '',
  label,
  handle = () => {
    return
  },
  nameButton = '',
}) {
  const formatPrice = price => {
    let dollarUSLocale = price.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    })

    return dollarUSLocale
  }

  const reorderValues = () => {
    value.sort(function (a, b) {
      if (a.value > b.value) {
        return 1
      }
      if (a.value < b.value) {
        return -1
      }
      return 0
    })
    // TODO: arrumar uma maneira de aplicar a máscara de valor
    // value.map(v => {
    //   v.value = formatPrice(v.value)
    //   return v
    // })
  }

  const applyFilters = columns => {
    if (columns.find(c => c?.Header == 'Valor')) reorderValues(value)
    return columns
  }

  return (
    <div>
      <b>{label}</b>
      <Panel>
        <PanelHeader noButton title={`${title}`}>
          <Create type='button' onClick={() => handle()}>
            {nameButton}
          </Create>
        </PanelHeader>

        <PanelBody>
          <ReactTable
            data={value}
            loading={loading}
            columns={applyFilters(columns)}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}
