import { Outlet, Route } from 'react-router-dom'
import DefaultLayout from '~/pages/__layouts__/DefaultLayout'
import SalesReleaseList from './List'
import SalesReleaseCreate from './Create'

export default function SalesReleases() {
  return (
    <Route
      path='/sales-releases'
      element={
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      }
    >
      <Route index element={<SalesReleaseList />} />

      <Route path='create' element={<SalesReleaseCreate />} />
    </Route>
  )
}
