import React from 'react'
import SkeletonLine from '~/components/Skeleton'

const SkeletonTravelResume = () => {
  return (
    <>
      <SkeletonLine width={'10%'} />
      <SkeletonLine width={'80%'} />
      <SkeletonLine width={'100%'} />
      <SkeletonLine width={'60%'} />
      <SkeletonLine width={'90%'} />
    </>
  )
}

export default SkeletonTravelResume
