import { Navigate, Route, Routes } from 'react-router-dom'
import BaseRoutes from '~/routes/base'
import HoldingLayout from '~/pages/__layouts__/HoldingLayout'
import About from '~/pages/About'
import Home from './pages/Home'
import HoldingConsolidatorProposals from './pages/Proposals'
import HoldingConsolidatorCustomers from './pages/Customers'
import HoldingConsolidatorServices from './pages/Services'
import HoldingConsolidatorSales from './pages/Sales'
import HoldingHeader from './components/Header'
import useHoldingConsolidatorData from './dataHooks/useHoldingConsolidator'

export default function HoldingConsolidatorModule() {
  const { branchs, branchsOptions, filters, changeFilters, resetFilters } =
    useHoldingConsolidatorData()

  const Header = ({ title }) => {
    return (
      <HoldingHeader
        title={title}
        filters={filters}
        onFilter={changeFilters}
        onReset={resetFilters}
        branchsOptions={branchsOptions}
      />
    )
  }

  return (
    <Routes>
      {BaseRoutes()}

      <Route
        path={'/home'}
        exact
        element={
          <HoldingLayout>
            <Header title={'Home'} />

            <Home filters={filters} branchs={branchs} />
          </HoldingLayout>
        }
      />

      <Route
        path={'/customers'}
        exact
        element={
          <HoldingLayout>
            <Header title='Clientes' />

            <HoldingConsolidatorCustomers filters={filters} />
          </HoldingLayout>
        }
      />

      <Route
        path={'/sales'}
        exact
        element={
          <HoldingLayout>
            <Header title='Vendas' />

            <HoldingConsolidatorSales filters={filters} />
          </HoldingLayout>
        }
      />

      <Route
        path={'/services'}
        exact
        element={
          <HoldingLayout>
            <Header title='Tipo de serviços' />

            <HoldingConsolidatorServices filters={filters} />
          </HoldingLayout>
        }
      />

      <Route
        path={'/proposals'}
        exact
        element={
          <HoldingLayout>
            <Header title='Propostas' />

            <HoldingConsolidatorProposals filters={filters} />
          </HoldingLayout>
        }
      />

      <Route
        path='/about'
        exact
        element={
          <HoldingLayout>
            <About />
          </HoldingLayout>
        }
      />

      <Route path='*' element={<Navigate to={'/home'} replace />} />
    </Routes>
  )
}
