import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import {
  ButtonToLinkRoadMap,
  LinkEdit,
  LinkRegisterNewTraveler,
  LinkTravelDetails,
} from '~/components/Link/styles'
import { getDateFull } from '~/functions/Date'
import { useSelector } from 'react-redux'

export default function TravelGroupsList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const user = useSelector(state => state.auth.user)

  useEffect(() => {}, [])

  async function generateLink(travelId) {
    try {
      const { data } = await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelresume`,
        { travelId: travelId },
      )

      window.open(data.link)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async data => {
    try {
      setLoading(true)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const toOperator = [
    {
      Header: 'Itinerário',
      accessor: 'title',
      width: 125,
    },
    {
      Header: 'Viajante',
      accessor: 'traveler',
      width: 100,
    },
    {
      Header: 'Origem',
      accessor: 'origin',
      width: 150,
    },
    {
      Header: 'Destino',
      accessor: 'destiny',
      width: 125,
    },
    {
      Header: 'Viajantes',
      accessor: 'passengers',
      width: 75,
    },
    {
      Header: 'Dias',
      accessor: 'days',
      width: 60,
    },
    {
      Header: 'Início',
      accessor: 'start',
      Cell: props => {
        const dateStart = getDateFull(props.row.original.start)
        return dateStart.day + '/' + dateStart.month + '/' + dateStart.year
      },
      width: 85,
    },
    {
      Header: 'Fim',
      accessor: 'end',
      Cell: props => {
        const dateEnd = getDateFull(props.row.original.end)
        return dateEnd.day + '/' + dateEnd.month + '/' + dateEnd.year
      },
      width: 85,
    },
    {
      Header: 'Agência',
      accessor: 'belongingAgency',
      width: 100,
    },
    {
      Header: '',
      accessor: 'detalhes-viagem',
      Cell: props => {
        return (
          <LinkTravelDetails
            to={'/travels/details'}
            state={{ id: props.row.original.id }}
          >
            Detalhes
          </LinkTravelDetails>
        )
      },
      width: 70,
    },
    {
      Header: '',
      accessor: 'roteiro-viagem',
      Cell: props => {
        return (
          <ButtonToLinkRoadMap
            type='button'
            onClick={() => generateLink(props.row.original.id)}
          >
            Ver Roteiro
          </ButtonToLinkRoadMap>
        )
      },
      width: 110,
    },
  ]

  const toAgency = [
    {
      Header: 'Itinerário',
      accessor: 'title',
      width: 150,
    },
    {
      Header: 'Viajante',
      accessor: 'traveler',
      width: 150,
    },
    {
      Header: 'Origem',
      accessor: 'origin',
      width: 150,
    },
    {
      Header: 'Destino',
      accessor: 'destiny',
      width: 125,
    },
    {
      Header: 'Viajantes',
      accessor: 'passengers',
      width: 75,
    },
    {
      Header: 'Dias',
      accessor: 'days',
      width: 75,
    },
    {
      Header: 'Início',
      accessor: 'start',
      Cell: props => {
        const dateStart = getDateFull(props.row.original.start)
        return dateStart.day + '/' + dateStart.month + '/' + dateStart.year
      },
      width: 85,
    },
    {
      Header: 'Fim',
      accessor: 'end',
      Cell: props => {
        const dateEnd = getDateFull(props.row.original.end)
        return dateEnd.day + '/' + dateEnd.month + '/' + dateEnd.year
      },
      width: 85,
    },
    {
      Header: '',
      accessor: 'detalhes-viagem',
      Cell: props => {
        return (
          <LinkTravelDetails
            to={'/travels/details'}
            state={{ id: props.row.original.id }}
          >
            Detalhes
          </LinkTravelDetails>
        )
      },
      width: 70,
    },
    {
      Header: '',
      accessor: 'roteiro-viagem',
      Cell: props => {
        return (
          <ButtonToLinkRoadMap
            type='button'
            onClick={() => generateLink(props.row.original.id)}
          >
            Ver Roteiro
          </ButtonToLinkRoadMap>
        )
      },
      width: 110,
    },
  ]

  return (
    <Container>
      <h1 className='page-header'>Viagens em Grupo</h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'traveler',
                label: 'Viajante',
                required: true,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
              },
              {
                type: 'text',
                name: 'title',
                label: 'Itinerário',
                required: true,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
              },
              {
                type: 'date',
                name: 'start',
                label: 'Inicio',
                required: true,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Fim',
                required: true,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}>
          <LinkRegisterNewTraveler to='create'>
            Nova Viagem
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={user.isOperator == true ? toOperator : toAgency}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
