import { getSaleState } from '~/functions/Sales'

const getSaleStateSpan = saleState => {
  let backgroundColor = '#fff'
  let color = '#555'

  switch (saleState) {
    case 'Issued':
      backgroundColor = '#28a745'
      color = '#fff'
      break
    case 'Canceled':
      backgroundColor = '#dc3545'
      color = '#fff'
      break
    default:
      break
  }

  return (
    <span
      style={{
        backgroundColor: backgroundColor,
        color: color,
        fontSize: '11px',
        fontWeight: '600',
        padding: '4px',
        borderRadius: '4px',
      }}
    >
      {getSaleState(saleState)}
    </span>
  )
}

export { getSaleStateSpan }
