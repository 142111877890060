import React, { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import ReactTable from '~/components/Table'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { getDateFormated } from '~/functions/Date'
import { LinkRegisterNewPanel } from '~/components/Link/styles'
import { DeleteButton } from '~/components/Button/styles'
import IconButton from '~/components/IconButton'
import DownloadFile from '~/functions/DownloadFile'

export default function DocumentTable() {
  const { axiosApis } = GetAxios()
  const { traveler } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([])
  const navigate = useNavigate()

  async function download(item) {
    try {
      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs/download/traleler/${item}`,
      )

      DownloadFile(
        `data:application/octet-stream;base64,${data.data}`,
        data.filename,
      )

      toast.info('Aguarde enquanto o documento é baixado...')

      return setTimeout(() => {
        window.close()
      }, 5000)
    } catch (error) {
      if (error.response.status === 404) {
        return toast.info('Documento não encontrado!')
      }

      if (error.response.status === 401) {
        return toast.info(
          'Link expirou, solicite um novo link ao seu consultor!',
        )
      }

      toast.info(error.response.data ?? 'Link inválido!')

      return setTimeout(() => {
        window.close()
      }, 5000)
    }
  }

  async function showDetails(e) {
    navigate('/travelers/documents/edit-document', {
      state: { traveler: traveler, e: e },
    })
  }

  async function getDocumentTableData() {
    try {
      setLoading(true)

      let response

      if (traveler.fromCustomer) {
        response = await axiosApis.get(
          `traveldocs/get-all-documents-traveler-from-customer/${traveler.travelerId}`,
        )
      } else {
        response = await axiosApis.get(
          `traveldocs/getalldocumentstraveler/${traveler.id}`,
        )
      }

      setLoading(false)

      setDocuments(response.data)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDocumentTableData()
  }, [])

  async function handleSubmitDelete(id) {
    try {
      setLoading(true)

      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs/deletedoc/${id}/Viajante`,
      )
      setLoading(false)

      toast.success(`Documento excluído com sucesso!`)
      getDocumentTableData()
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível excluir o documento, por favor tente mais tarde.',
      )
    }
  }

  const OnDeleteclick = id => {
    if (window.confirm('Você tem certeza que deseja excluir esse documento?')) {
      handleSubmitDelete(id)
    }
  }
  return (
    <Panel>
      <PanelHeader noButton>
        <LinkRegisterNewPanel to='create-document' state={{ traveler }}>
          Novo Documento
        </LinkRegisterNewPanel>
      </PanelHeader>
      <PanelBody>
        <ReactTable
          columns={[
            {
              Header: 'Título',
              accessor: 'title',
              width: 120,
            },
            {
              Header: 'Descrição',
              accessor: 'desc',
              width: 150,
            },
            {
              Header: 'Nome do Arquivo',
              accessor: 'filename',
              width: 200,
            },
            {
              Header: 'Data de Expiração',
              accessor: 'expiresIn',
              width: 150,
              Cell: props => {
                return props.row.original.expiresIn
                  ? getDateFormated(props.row.original.expiresIn, 'DD/MM/YYYY')
                  : ''
              },
            },
            {
              Header: 'Qte de Dias',
              accessor: 'days',
              Cell: props => {
                if (props.row.original.days == 0) {
                  return `Expira hoje!`
                }
                if (props.row.original.days < 0) {
                  return `Expirou há ${Math.abs(
                    props.row.original.days,
                  )} dias(s)`
                }
                return `Expira em  ${props.row.original.days} dia(s)`
              },
              width: 100,
            },
            {
              accessor: 'view',
              Cell: props => {
                return (
                  <IconButton
                    id={props.row.index}
                    type={'view'}
                    iconOverride={'far fa-eye'}
                    tooltipOverride={'Ver documentos'}
                    onClick={() => download(props.row.original.token)}
                  />
                )
              },
              width: 40,
            },
            {
              accessor: 'details',
              Cell: props => {
                return (
                  <IconButton
                    id={props.row.index}
                    type={'details'}
                    tooltipOverride={'Editar'}
                    onClick={() => showDetails(props.row.original)}
                  />
                )
              },
              width: 40,
            },
            {
              accessor: 'delete-document',
              Cell: props => {
                return (
                  <IconButton
                    id={props.row.index}
                    type={'remove'}
                    onClick={() => OnDeleteclick(props.row.original.id)}
                  />
                )
              },
              width: 40,
            },
          ]}
          data={documents}
          tableProps={{
            defaultPageSize: 10,
            hasFooter: false,
            hasPagination: true,
            loading: loading,
            resizable: true,
          }}
          tableStyle={{
            fontSize: '13px',
            cellPadding: '6px 4px',
          }}
          tableLabels={{
            loadingText: 'Carregando...',
            noDataText: 'Sem registros',
            nextButtonText: 'Próximo',
            previousButtonText: 'Anterior',
            pageOfText: 'de',
            pageSizeSelectText: 'registros',
            pageText: 'Página',
          }}
        />
      </PanelBody>
    </Panel>
  )
}
