import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import { inputs } from './inputs'

export default function RelationshipCreate({
  hideModal,
  onUpdate,
  tableProps,
}) {
  const { axiosApis } = GetAxios()
  // const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const [defaultValue, setDefaultValue] = useState({})
  const [selectDdi, setSelectDdi] = useState({})
  const [ddi, setDdi] = useState({})
  const [loading, setLoading] = useState(false)
  const [isEnterprise, setIsEnterprise] = useState(false)

  useEffect(() => {
    getOptionsDDI()
    setSelectDdi({
      label: '',
      value: '',
    })
  }, [])

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      if (data.isEnterprise) {
        formData.append('isEnterprise', data.isEnterprise)
      }
      if (data.fullName) {
        formData.append('fullName', data.fullName)
      }
      if (data.email) {
        formData.append('email', data.email ?? '')
      }
      if (data.phone) {
        formData.append('phone', data.phone ?? '')
      }
      if (data.ddi) {
        formData.append('ddi', data.ddi.value ?? '')
      }
      if (data.cpfCnpj) {
        formData.append('cpfCnpj', data.cpfCnpj ?? '')
      }
      if (data.rg) {
        formData.append('rg', data.rg ?? '')
      }
      if (data.birthday) {
        formData.append('birthdayDate', data.birthday ?? '')
      }
      if (data.profession) {
        formData.append('profession', data.profession ?? '')
      }
      if (data.address) {
        formData.append('address', data.address ?? '')
      }
      if (data.city) {
        formData.append('city', data.city.label ?? '')
        formData.append('cityId', data.city.value ?? '')
      }
      if (data.state) {
        formData.append('state', data.state ?? '')
      }
      if (data.country) {
        formData.append('country', data.country ?? '')
      }
      if (data.avatar) {
        formData.append('avatar', data.avatar ?? '')
      }
      if (data.prospection) {
        formData.append('prospection', data.prospection)
      }
      if (data.belongingAgency) {
        formData.append('belongingAgency', data.belongingAgency ?? '')
      }
      if (data.cep) {
        formData.append('zipCode', data.cep ?? '')
      }
      if (data.bairro) {
        formData.append('neighborhood', data.bairro ?? '')
      }
      if (data.fidelidade) {
        formData.append('loyalty', data.fidelidade ?? '')
      }
      if (data.origem) {
        formData.append('origin', data.origem ?? '')
      }
      if (data.isAgent) {
        formData.append('isAgent', data.isAgent ?? '')
      }
      if (data.nacionality) {
        formData.append('nacionality', data.nacionality ?? '')
      }
      if (data.n) {
        formData.append('n', data.n ?? '')
      }
      if (data.compl) {
        formData.append('compl', data.compl ?? '')
      }
      if (data.commercialPhone) {
        formData.append('commercialPhone', data.commercialPhone ?? '')
      }
      if (data.company) {
        formData.append('company', data.company ?? '')
      }
      if (data.intoTraveler) {
        formData.append('intoTraveler', data.intoTraveler)
      }

      if (data.passportNumber) {
        formData.append('passportNumber', data.passportNumber)
      }
      if (data.visaNumber) {
        formData.append('visaNumber', data.visaNumber)
      }
      if (data.visaType) {
        formData.append('visaType', data.visaType)
      }
      if (data.passportIssueDate && data.passportIssueDate != 'Data inválida') {
        formData.append('passportIssueDate', data.passportIssueDate ?? '')
      }
      if (
        data.passportExpirationDate &&
        data.passportExpirationDate != 'Data inválida'
      ) {
        formData.append(
          'passportExpirationDate',
          data.passportExpirationDate ?? '',
        )
      }
      if (data.visaIssueDate && data.visaIssueDate != 'Data inválida') {
        formData.append('visaIssueDate', data.visaIssueDate ?? '')
      }
      if (
        data.visaExpirationDate &&
        data.visaExpirationDate != 'Data inválida'
      ) {
        formData.append('visaExpirationDate', data.visaExpirationDate ?? '')
      }
      if (data.contactName) {
        formData.append('contactName', data.contactName ?? '')
      }
      if (data.contactPhone) {
        formData.append('contactPhone', data.contactPhone ?? '')
      }
      if (data.contactEmail) {
        formData.append('contactEmail', data.contactEmail ?? '')
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/insert`,
        formData,
      )

      toast.success('Cliente criado com sucesso!')

      hideModal()

      await onUpdate(
        tableProps.filters,
        (tableProps.page - 1) * tableProps.limit,
        tableProps.limit,
      )
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  async function onSearchCEP(e, oldData = {}) {
    try {
      setLoading(true)

      var { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/cep/${e.target.value}`,
      )

      if (!data.cep) {
        toast.info('CEP não encontrado!')

        return setLoading(false)
      }

      setDefaultValue({
        ...oldData,
        cep: data.cep,
        address: data.logradouro,
        bairro: data.bairro,
        city: data.localidade,
        state: data.uf,
      })

      return setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }

      return setLoading(false)
    }
  }

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  return (
    <Container>
      <Panel>
        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            defaultValue={defaultValue}
            loading={loading}
            inputs={inputs(
              isEnterprise,
              setIsEnterprise,
              ddi,
              setSelectDdi,
              selectDdi.Value,
              user.isOperator,
              onSearchCEP,
            )}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
