import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import { getDateFormated, getMoment } from '~/functions/Date'
import { toast } from 'react-toastify'

export default function EventDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [event, setEvent] = useState()
  const [loading, setLoading] = useState(false)
  const [dataOptions, setInstitutions] = useState([])

  const statusOptions = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ]

  const typeOptions = [
    // { value: 'Religioso', label: 'Religioso' },
    { label: 'Pedagógico', value: 'pedagogico' },
    { label: 'Incentivo', value: 'incentivo' },
    // { value: 'Universitário', label: 'Universitário' },
    { label: 'Outros', value: 'outros' },
  ]

  useEffect(() => {
    requestApi()
  }, [])

  async function requestApi() {
    try {
      setLoading(true)

      const responseGet = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/event/${location.state.id}`,
      )

      const responseOptions = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/institution`,
      )

      setInstitutions(responseOptions.data)

      setEvent({
        ...responseGet.data,
        status: statusOptions.find(x => x.value === responseGet.data.status),
        type: { label: responseGet.data.type, value: responseGet.data.type },
        institutions: responseOptions.data.filter(e =>
          responseGet.data.institutions.includes(e.value),
        ),
        start: getDateFormated(responseGet.data.start, 'YYYY-MM-DD'),
        end: getDateFormated(responseGet.data.end, 'YYYY-MM-DD'),
        periodInfo: responseGet.data.periodInfo,
        subscribeDateStart: !responseGet.data.subscribeStart
          ? undefined
          : getDateFormated(responseGet.data.subscribeStart, 'YYYY-MM-DD'),
        subscribeTimeStart: !responseGet.data.subscribeStart
          ? undefined
          : getDateFormated(responseGet.data.subscribeStart, 'HH:mm'),
        subscribeDateEnd: !responseGet.data.subscribeEnd
          ? undefined
          : getDateFormated(responseGet.data.subscribeEnd, 'YYYY-MM-DD'),
        subscribeTimeEnd: !responseGet.data.subscribeEnd
          ? undefined
          : getDateFormated(responseGet.data.subscribeEnd, 'HH:mm'),
      })

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onEdit = async data => {
    try {
      setLoading(true)

      const formData = new FormData()

      if (data.id) formData.append('id', location.state.id)
      if (data.image) formData.append('image', data.image)
      if (data.title) formData.append('title', data.title)
      if (data.destiny) formData.append('destiny', data.destiny)
      if (data.origin) formData.append('origin', data.origin)
      if (data.vacancies) formData.append('vacancies', data.vacancies)
      if (data.start) formData.append('start', data.start)
      if (data.end) formData.append('end', data.end)
      if (data.desc) formData.append('desc', data.desc)
      if (data.type) formData.append('type', data.type?.value)
      if (data.status) formData.append('status', data.status?.value)
      if (data.institutions) {
        data.institutions.map(e => formData.append('institutions', e.value))
      }

      if (data.periodInfo) formData.append('periodInfo', data.periodInfo)

      if (data.subscribeDateStart) {
        const subscribeStart = getMoment(
          data.subscribeDateStart + ' ' + data.subscribeTimeStart ?? '00:00',
        )

        formData.append(
          'subscribeStart',
          getDateFormated(subscribeStart, 'YYYY-MM-DD HH:mm'),
        )
      }

      if (data.subscribeDateEnd) {
        const subscribeEnd = getMoment(
          data.subscribeDateEnd + ' ' + data.subscribeTimeEnd ?? '23:59',
        )

        formData.append(
          'subscribeEnd',
          getDateFormated(subscribeEnd, 'YYYY-MM-DD HH:mm'),
        )
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/event`,
        formData,
      )

      toast.success('Evento editado com sucesso.')

      setLoading(false)

      navigate(-1)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  const onDelete = async () => {
    setLoading(true)
    await axiosApis.delete(
      `${process.env.REACT_APP_ENDPOINT_APIS}/event/${location.state.id}`,
    )

    navigate(-1)
    setLoading(false)
  }

  async function onValidate(data) {
    if (
      (!data.subscribeDateStart && data.subscribeTimeStart) ||
      (!data.subscribeDateEnd && data.subscribeTimeEnd)
    ) {
      toast.info(
        'Revise os campos de Início e Fim das inscrições. Você pode deixar vazio, selecionar somente datas ou datas com horários.',
      )

      return false
    }

    return true
  }

  return (
    <Container>
      <PageTitle title={`${event ? 'Evento - ' + event.title : 'Evento'}`} />

      <Panel>
        <PanelHeader noButton></PanelHeader>

        <PanelBody>
          <DefaultForm
            defaultValue={event}
            callbackSubmit={onEdit}
            callbackDelete={onDelete}
            onValidate={onValidate}
            loading={loading}
            disabled={loading}
            inputs={[
              {
                type: 'image',
                name: 'image',
                label: 'Logo/Capa do evento',
                sm: 12,
              },
              {
                type: 'text',
                name: 'title',
                label: 'Nome do Evento',
                maxWidth: '300px',
                required: true,
                sm: 12,
                lg: 6,
              },
              {
                type: 'date',
                name: 'start',
                label: 'Início do evento',
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Fim do evento',
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'html',
                name: 'periodInfo',
                label: 'Informações sobre as datas, horários, grupos',
                sm: 12,
              },
              {
                type: 'text',
                name: 'origin',
                label: 'Origem',
                required: true,
                sm: 12,
                lg: 6,
              },
              {
                type: 'text',
                name: 'destiny',
                label: 'Destino',
                required: true,
                sm: 12,
                lg: 6,
              },
              {
                type: 'select',
                name: 'type',
                label: 'Tipo de evento',
                options: typeOptions,
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'select',
                name: 'institutions',
                label: 'Grupos',
                isMulti: true,
                options: dataOptions,
                sm: 12,
                lg: 3,
              },
              {
                type: 'select',
                name: 'status',
                label: 'Status',
                options: statusOptions,
                sm: 12,
                lg: 3,
              },
              {
                type: 'number',
                name: 'vacancies',
                label: 'Número de Vagas',
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'date',
                name: 'subscribeDateStart',
                label: 'Início das incrições',
                sm: 12,
                lg: 3,
              },
              {
                type: 'time',
                name: 'subscribeTimeStart',
                label: 'Horário de início das incrições',
                sm: 12,
                lg: 3,
              },
              {
                type: 'date',
                name: 'subscribeDateEnd',
                label: 'Fim das incrições',
                sm: 12,
                lg: 3,
              },
              {
                type: 'time',
                name: 'subscribeTimeEnd',
                label: 'Horário do fim das incrições',
                sm: 12,
                lg: 3,
              },
              {
                type: 'html',
                name: 'desc',
                label: 'Termos e Condições',
                required: true,
                sm: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
