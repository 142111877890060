import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import PageTitle from '~/components/PageTitle'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { LinkEditExperience } from '~/components/Link/styles'
import { ColInputs } from './styles'
import usePermissions from '~/hooks/usePermissions'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import DivForHTML from '~/components/DivForHTML'
import styled from 'styled-components'

export default function Details() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [travelExperienceDetails, setTravelExperienceDetails] = useState()
  const { manageExperiences } = usePermissions()

  useEffect(() => {
    async function getTravelExperienceFromRoute() {
      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/getbyid/experience-${location.state.id}`,
        )

        setTravelExperienceDetails(data)

        return setLoading(false)
      } catch (error) {
        toast.info('Não foi possível carregar os dados da Experiência.')

        setLoading(false)

        return navigate('/experiences')
      }
    }

    getTravelExperienceFromRoute()
  }, [])

  return (
    <div>
      <PageTitle
        title={
          travelExperienceDetails ? `${travelExperienceDetails.title}` : ''
        }
        className='page-header'
        to={'/experiences'}
      />

      <Panel>
        <PanelHeader title={'Detalhes'} noButton />

        <PanelBody>
          <div style={{ background: '#f4f4f4', padding: '8px' }}>
            {travelExperienceDetails && !loading && (
              <div>
                <Row style={{ width: '100%' }}>
                  <Col sm={12} lg={6}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        marginBottom: '16px',
                      }}
                    >
                      <span style={{ margin: '0 4px 4px 0' }}>
                        Categoria:{' '}
                        <LabelHighlight>{`${travelExperienceDetails.categoryDesc}`}</LabelHighlight>
                      </span>

                      {travelExperienceDetails.address && (
                        <span style={{ margin: '0 4px 4px 0' }}>
                          Endereço:{' '}
                          <LabelHighlight>{`${travelExperienceDetails.address}`}</LabelHighlight>
                        </span>
                      )}

                      {travelExperienceDetails.city && (
                        <span style={{ margin: '0 4px 4px 0' }}>
                          Cidade:{' '}
                          <LabelHighlight>
                            {travelExperienceDetails.city}
                          </LabelHighlight>
                        </span>
                      )}

                      {travelExperienceDetails.flag && (
                        <span style={{ margin: '0 4px 4px 0' }}>
                          Tags:{' '}
                          <LabelHighlight>
                            {travelExperienceDetails.flag}
                          </LabelHighlight>
                        </span>
                      )}
                    </div>

                    <DivForHTML text={travelExperienceDetails.desc} />
                  </Col>

                  <Col sm={12} lg={6}>
                    {travelExperienceDetails.image && (
                      <img
                        style={{
                          width: '100%',
                          borderRadius: '6px',
                          marginBottom: '16px',
                        }}
                        src={`data:image/*;base64,${travelExperienceDetails.image}`}
                        alt='imagem'
                        onError={event => (event.target.style.display = 'none')}
                      />
                    )}

                    {!travelExperienceDetails.image && (
                      <div
                        style={{
                          width: '100%',
                          fontSize: '18px',
                          textAlign: 'center',
                        }}
                      >
                        <i
                          style={{
                            fontSize: '50px',
                          }}
                          className='fas fa-image'
                        />
                        <p>Nenhuma imagem adicionada</p>
                      </div>
                    )}
                  </Col>
                </Row>

                {manageExperiences && !travelExperienceDetails.shared && (
                  <Row>
                    <ColInputs>
                      <LinkEditExperience
                        to='/experiences/edit'
                        state={{ travelExperienceDetails }}
                      >
                        Editar
                      </LinkEditExperience>
                    </ColInputs>
                  </Row>
                )}
              </div>
            )}
          </div>
        </PanelBody>
      </Panel>
    </div>
  )
}

const LabelHighlight = styled.b`
  color: ${props => props.theme.colors.primary};
`
