import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export default function printStudentDetails() {
  const user = useSelector(state => state.auth.user)
  const [show, setShow] = useState(true)

  const location = useLocation()

  const data = location.state.participant

  return (
    <div
      style={{
        padding: '1cm',
        width: '21cm',
        fontSize: '14px',
      }}
    >
      {show && (
        <div>
          <button
            onClick={() => {
              setShow(false)

              setTimeout(() => window.print(), 25)
              setTimeout(() => setShow(true), 50)
            }}
          >
            Imprimir
          </button>
        </div>
      )}

      <h4>Dados do participante</h4>
      <div style={{ display: 'flex' }}>
        <ul>
          <b>Posição de incrição: </b>
          <span>{data.index}</span>
          <br />
          <b>Série: </b>
          <span>{data.grade}</span>
          <br />
          <b>Turma: </b>
          <span>{data.class}</span>
          <br />
          <b>Nome completo: </b>
          <span>{data.fullName}</span>
          <br />
          <b>E-mail: </b>
          <span>{data.email}</span>
          <br />
          <b>Telefone: </b>
          <span>{data.phone}</span>
          <br />
          <b>Cpf: </b>
          <span>{data.cpf}</span>
          <br />
          <b>Rg: </b>
          <span>{data.rg}</span>
          <br />
          <b>Data de nascimento: </b>
          <span>{data.birth}</span>
          <br />
          <b>Nome do pai: </b>
          <span>{data.fatherName}</span>
          <br />
          <b>Telefone do pai: </b>
          <span>{data.fatherPhone}</span>
          <br />
          <b>Nome da mãe: </b>
          <span>{data.motherName}</span>
          <br />
          <b>Telefone da mãe: </b>
          <span>{data.motherPhone}</span>
          <br />
        </ul>

        <ul>
          <b>Nome do contato de emergência: </b>
          <span>{data.emergencyName}</span>
          <br />
          <b>Telefone do contato de emergência: </b>
          <span>{data.emergencyPhone}</span>
          <br />
          <b>E-mail do contato de emergência: </b>
          <span>{data.emergencyEmail}</span>
          <br />
          <b>Número da camisa: </b>
          <span>{data.tshirtNumber}</span>
          <br />
          <b>Parentesco com responsável financeiro: </b>
          <span>{data.responsibleKinship}</span>
          <br />
          <b>Peso (em quilos): </b>
          <span>{data.weight}</span>
          <br />
          <b>Número do Pé: </b>
          <span>{data.shoeNumber}</span>
          <br />
          {user.agencyName === 'Wee Travel' && (
            <>
              <b>
                O(a) aluno(a) irá participar da experiência do PlanaSub? Valor
                adicional R$ 375,00 por pessoa:{' '}
              </b>
              <span>{data.planaSub}</span>
              <br />
            </>
          )}
          <b>Alergias: </b>
          <span>{data.medicalAllergy}</span>
          <br />
          <b>Medicamentos de uso contínuo: </b>
          <span>{data.medicalMedication}</span>
          <br />
          <b>Doença crônica/fobia ou necessidade especial: </b>
          <span>{data.medicalDiseasePhobia}</span>
          <br />
          <b>Restrição alimentar ou algo que não aprecie: </b>
          <span>{data.medicalFoodRestriction}</span>
          <br />
        </ul>
      </div>

      <h4>Dados do Responsável financeiro</h4>
      <ul>
        <b>Nome completo: </b>
        <span>{data.responsible.fullName}</span>
        <br />
        <b>E-mail: </b>
        <span>{data.responsible.email}</span>
        <br />
        <b>Telefone: </b>
        <span>{data.responsible.phone}</span>
        <br />
        <b>Profissão: </b>
        <span>{data.responsible.profession}</span>
        <br />
        <b>Telefone comercial: </b>
        <span>{data.responsible.businessPhone}</span>
        <br />
        <b>Rg: </b>
        <span>{data.responsible.rg}</span>
        <br />
        <b>Cpf: </b>
        <span>{data.responsible.cpf}</span>
        <br />
        <b>Data de nascimento: </b>
        <span>{data.responsible.birth}</span>
        <br />
        <b>Endereço: </b>
        <span>{data.responsible.address}</span>
        <br />
        <b>Bairro: </b>
        <span>{data.responsible.hood}</span>
        <br />
        <b>Cidade: </b>
        <span>{data.responsible.city}</span>
        <br />
        <b>Estado: </b>
        <span>{data.responsible.state}</span>
        <br />
        <b>Forma de pagamento: </b>
        <span>{data.responsible.paymentMethod}</span>
        <br />
        <b>Quantidade de parcelas: </b>
        <span>{data.responsible.installments}</span>
        <br />
      </ul>

      <h3>Dados da viagem</h3>
      <ul>
        <b>Título: </b>
        <span>{data.event.title}</span>
        <br />
        <b>Destino: </b>
        <span>{data.event.destiny}</span>
        <br />
        <b>Início: </b>
        <span>{data.event.start}</span>
        <br />
        <b>Fim: </b>
        <span>{data.event.end}</span>
        <br />
      </ul>

      <h3>Dados do Grupo</h3>
      {data.institutions.map((e, i) => (
        <ul key={i}>
          <b>Nome: </b>
          <span>{e.name}</span>
          <br />
          <b>Telefone: </b>
          <span>{e.phone}</span>
          <br />
          <b>E-mail: </b>
          <span>{e.email}</span>
          <br />
          <b>Site: </b>
          <span>{e.site}</span>
          <br />
          <b>Endereço: </b>
          <span>{e.address}</span>
          <br />
          {/* <b>Bairro: </b>
          <span>{e.hood}</span>
          <br />
          <b>Cidade: </b>
          <span>{e.city}</span>
          <br />
          <b>Estado: </b>
          <span>{e.state}</span> */}
        </ul>
      ))}
      {/* <ul>
        <b>Nome da escola: </b>
        <span>{data.school.name}</span>
        <br />
        <b>Telefone: </b>
        <span>{data.school.phone}</span>
        <br />
        <b>E-mail: </b>
        <span>{data.school.email}</span>
        <br />
        <b>Site: </b>
        <span>{data.school.site}</span>
        <br />
        <b>Endereço: </b>
        <span>{data.school.address}</span>
        <br />
        <b>Bairro: </b>
        <span>{data.school.hood}</span>
        <br />
        <b>Cidade: </b>
        <span>{data.school.city}</span>
        <br />
        <b>Estado: </b>
        <span>{data.school.state}</span>
      </ul> */}
    </div>
  )
}
