import React, { useEffect, useRef, useState } from 'react'
import { Form, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { DivFlexRight } from '~/styles/divs'
import { RegisterNewButton, SaveButton } from '../Button/styles'
import Input from '../Input'

export default function KeyValueInput({
  title = 'Título',
  keyPlaceholder = 'Chave',
  valuePlaceholder = 'Valor',
  onChange = () => {},
  defaultValue = '[]',
  name,
}) {
  const ref = useRef()
  const [values, setValues] = useState([])
  const [label, setLabel] = useState()
  const [value, setValue] = useState()
  const [showInputs, setShowInputs] = useState(false)

  useEffect(() => {
    setValues(JSON.parse(defaultValue))
  }, [defaultValue])

  function handleRemove(e, item) {
    const message = `${keyPlaceholder}: ${item.label}\n${valuePlaceholder}: ${item.value}\n\nDeseja remover o item?`

    if (window.confirm(message)) {
      let newValues = [...values]

      newValues.splice(e, 1)

      setValues(newValues)

      onChange({
        target: {
          name: name,
          value: JSON.stringify(newValues),
        },
      })
    }
  }

  function handleSubmit() {
    if (label && value) {
      const newValues = values.concat([{ label, value }])

      setValues(newValues)

      onChange({
        target: {
          name: name,
          value: JSON.stringify(newValues),
        },
      })

      setValue('')

      setLabel('')

      setShowInputs(false)
    }
  }

  return (
    <Form ref={ref} onSubmit={undefined}>
      <Container>
        <b>{title}</b>

        <IconAdd
          className={`fas fa-${showInputs ? 'times' : 'plus'}`}
          onClick={() => setShowInputs(state => !state)}
        />
      </Container>

      {showInputs && (
        <>
          <Container>
            <Input
              name={`label`}
              placeholder={keyPlaceholder}
              maxLength={50}
              value={label}
              onChange={e => setLabel(e.target.value)}
            />

            <SpanEquals />

            <Input
              name={`value`}
              placeholder={valuePlaceholder}
              maxLength={500}
              value={value}
              onChange={e => setValue(e.target.value)}
            />
          </Container>

          <DivFlexRight style={{ marginBottom: '8px' }}>
            {label && value && (
              <RegisterNewButton type='button' onClick={handleSubmit}>
                Adicionar
              </RegisterNewButton>
            )}
          </DivFlexRight>
        </>
      )}

      {values.length === 0 && !showInputs && (
        <p style={{ textAlign: 'center' }}>Nenhum link adicionado</p>
      )}

      {values.map((item, i) => {
        return (
          <div
            key={i}
            style={{
              padding: '4px',
              borderRadius: '4px',
              marginBottom: '4px',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#eee',
            }}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <b>{item.label}: </b> {item.value}
            </span>

            <IconAdd
              className={'fas fa-times'}
              onClick={() => handleRemove(i, item)}
              style={{ color: '#f00' }}
            />
          </div>
        )
      })}
    </Form>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;

  .mb-3 {
    margin-bottom: 0px !important;
  }
`

const SpanEquals = styled.span`
  width: 16px;
`

const IconRemove = styled.i`
  color: #333;
  padding: 8px;
  cursor: pointer;
`

const IconAdd = styled.i`
  color: #333;
  padding: 8px;
  cursor: pointer;
`

const Button = styled.button`
  border: none;
  background-color: transparent;
  color: #333;
  padding: 4px 8px;
  border-radius: 4px;

  &:hover {
    background-color: #eee;
  }
`
