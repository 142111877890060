import styled from 'styled-components'
import { Col } from 'reactstrap'

export const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const TravelDetailColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const TravelDetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`

export const TravelDetailBlock = styled.div`
  flex: 1;
  background-color: #f4f4f4;
  padding: 8px;
  width: 100%;
  height: 100%;
`

export const RegisterNewButton = styled.button`
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  text-decoration: none;
  padding: 8px 10px;
  color: #1bc25b;
  background-color: transparent;
  font-size: 12px;

  &:hover {
    color: #1bc25b;
    background-color: #00000011;
  }
`

export const DeleteButton = styled.button`
  font-weight: bold;
  padding: 8px 10px;
  color: #ff7d70;
  background-color: transparent;
  border: none;
  font-size: 12px;

  &:hover {
    background-color: #00000011;
    text-decoration: none;
    border-radius: 4px;
  }
`
