import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { getDateFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import { Container } from './styles'

export default function Edit({ toEdit, onClose, onDelete }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [hotelToEdit, setHotelToEdit] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelhotel/${toEdit.entity.id}`,
        )

        setHotelToEdit({
          ...data,
          checkIn: getDateFormated(data.checkIn, 'YYYY-MM-DD'),
          timeCheckIn: getDateFormated(data.checkIn, 'HH:mm'),
          checkOut: getDateFormated(data.checkOut, 'YYYY-MM-DD'),
          timeCheckOut: getDateFormated(data.checkOut, 'HH:mm'),
        })

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    getSource()
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelhotel`,
        data,
      )

      setLoading(false)

      toast.success('Hotel editado com sucesso!')

      onClose(true)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function onDeleteClick() {
    setLoading(true)

    await onDelete({ id: toEdit.entity.id, type: toEdit.entity.type })

    await onClose(true)

    setLoading(false)
  }

  return (
    <Container>
      {hotelToEdit.staticMapUrl && (
        <>
          <p>
            <b>Preview do Mapa do Hotel</b>
          </p>

          <a
            href={hotelToEdit.staticMapUrl}
            target={'_blank'}
            rel='noreferrer'
            style={{
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              border: 'none',
              borderRadius: '4px',
              padding: '8px 10px',
              marginBottom: '6px',
              textDecoration: 'none',
              color: '#1bc25b',
              backgroundColor: '#eee',
            }}
          >
            Clique aqui
          </a>
        </>
      )}

      {hotelToEdit.images &&
        JSON.parse(hotelToEdit.images) &&
        JSON.parse(hotelToEdit.images).length > 0 && (
          <>
            <p>
              <b>Imagens do Hotel</b>
            </p>

            <ul
              type='none'
              style={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'auto',
                width: '100%',
                padding: 0,
                margin: 0,
              }}
            >
              {JSON.parse(hotelToEdit.images)?.map((e, i) => {
                return (
                  <>
                    {i < 5 && (
                      <li key={i}>
                        <img
                          src={e.MainUrl}
                          alt={`Image ${i + 1} do Hotel ${hotelToEdit.name}`}
                          style={{
                            height: '180px',
                          }}
                        />
                      </li>
                    )}
                  </>
                )
              })}

              {JSON.parse(hotelToEdit.images)?.length >= 5 && (
                <li>
                  <div
                    style={{
                      width: '180px',
                      height: '180px',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    + {JSON.parse(hotelToEdit.images)?.length - 5}
                  </div>
                </li>
              )}
            </ul>
          </>
        )}

      <DefaultForm
        callbackSubmit={onSubmit}
        callbackDelete={onDeleteClick}
        defaultValue={hotelToEdit}
        loading={loading}
        disabled={loading}
        inputs={[
          {
            type: 'text',
            name: 'name',
            label: 'Nome do Hotel',
            required: true,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          {
            type: 'text',
            name: 'fullAddress',
            label: 'Endereço',
            required: true,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 6,
          },
          {
            type: 'text',
            name: 'locationName',
            label: 'Cidade',
            required: true,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 6,
          },
          {
            type: 'date',
            name: 'checkIn',
            label: 'Data de check-in',
            required: true,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeCheckIn',
            label: 'Hora de check-in',
            required: true,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 3,
          },
          {
            type: 'date',
            name: 'checkOut',
            label: 'Data de check-out',
            required: true,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeCheckOut',
            label: 'Hora de check-out',
            required: true,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 3,
          },
          {
            type: 'text',
            name: 'originPurchase',
            label: 'Fornecedor',
            maxLength: 30,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 6,
          },
          {
            type: 'text',
            name: 'locator',
            label: 'Código Reserva/Localizador',
            maxLength: 30,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 6,
          },
          {
            type: 'textarea',
            name: 'desc',
            label: 'Descrição do check-in',
            maxLength: 2000,
            rows: 5,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
          },
          {
            type: 'textarea',
            name: 'descCheckOut',
            label: 'Descrição do check-out',
            maxLength: 2000,
            rows: 5,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
          },
          {
            type: 'value',
            name: 'tips',
            label: 'Amenidades',
            placeholder: 'Descrição',
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
          },
        ]}
      />
    </Container>
  )
}
