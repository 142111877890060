import styled from 'styled-components'
import logo from '~/components/imgs/logotripmee_parafundobranco.png'
import { DivFlexRight } from '~/styles/divs'
import LoadingContainer from '~/components/LoadingContainer'
import SkeletonLine from '~/components/Skeleton'

export default function Header({
  // travel,
  onHandlePrint,
  agencyImage,
  partnerImage,
  showPrint,
  nameAgency,
}) {
  return (
    <Container>
      <Content>
        {nameAgency?.name == 'Wee Travel' ? (
          <DivColumnWee>
            <Logo
              src={agencyImage ? `data:image/*;base64,${agencyImage}` : logo}
              alt='Logo da agência'
            />

            {partnerImage && (
              <Logo
                src={
                  partnerImage ? `data:image/*;base64,${partnerImage}` : logo
                }
                alt='Logo do parceiro'
              />
            )}
          </DivColumnWee>
        ) : (
          <DivColumn>
            <Logo
              src={agencyImage ? `data:image/*;base64,${agencyImage}` : logo}
              alt='Logo da agência'
            />

            {partnerImage && (
              <Logo
                src={
                  partnerImage ? `data:image/*;base64,${partnerImage}` : logo
                }
                alt='Logo do parceiro'
              />
            )}
          </DivColumn>
        )}
        {!nameAgency && <SkeletonLine width={'100%'} height={'360px'} />}
        {nameAgency && (
          <DivFlexRight>
            {nameAgency.name == 'Wee Travel' ? (
              <ButtonWee type='button' onClick={onHandlePrint}>
                {showPrint ? <LoadingContainer /> : 'BAIXAR PDF'}
              </ButtonWee>
            ) : (
              <Button
                style={{ backgroundColor: `${nameAgency.primaryColor}` }}
                type='button'
                onClick={onHandlePrint}
              >
                {showPrint ? <LoadingContainer /> : 'BAIXAR PDF'}
              </Button>
            )}
          </DivFlexRight>
        )}
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding-top: 60px;
`

const Content = styled.div`
  max-width: 920px;
  max-height: 126px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 8px 16px;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    li {
      margin: 4px;
    }
  }
`

const Logo = styled.img`
  max-width: 180px;
  max-height: 110px;
`

const Button = styled.button`
  /* background-color: ${props => props.theme.colors.primary}; */
  color: #fff;
  font-weight: 700;
  padding: 8px;
  border: none;
  border-radius: 8px;
  font-size: 0.9em;
`

const ButtonWee = styled.button`
  background-color: #7f2ec2;
  color: #fff;
  font-weight: 700;
  padding: 8px;
  border: none;
  border-radius: 8px;
  font-size: 0.9em;
`

export const DivColumnWee = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  /* align-items: center; */
  width: 100%;
  color: #777;
  font-weight: 700;
  /* border: 1px solid red; */
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: #777;
  font-weight: 700;
  gap: 50px;
`
