import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
// import { useLocation } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
// import { toast } from 'react-toastify'
import { LinkEdit } from '~/components/Link/styles'

export default function RefundList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  // const location = useLocation()
  const [data, setData] = useState([])
  const [newData, setNewData] = useState([])
  // const [agencies, setAgencies] = useState([])

  useEffect(() => {
    requestGetApi()
  }, [])

  const requestGetApi = async () => {
    setLoading(true)
    const response = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/IntegrationRequest`,
    )

    const response2 = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/agency`,
    )

    // setAgencies(response2.data)

    setData(
      response.data.reduce((acc, value) => {
        const obj = {
          idRequestingAgency: response2.data
            .filter(e => e.id == value.idRequestingAgency)
            .map(e => e.name),
          nameRequestedAgency: value.nameRequestedAgency,
          deleted: value.deleted,
          createdAt: value.createdAt,
          desc: value.desc,
          id: value.id,
          status: value.status,
          situation: value.situation,
          updatedAt: value.updatedAt,
        }
        acc.push(obj)
        return acc
      }, []),
    )

    setNewData(
      response.data.reduce((acc, value) => {
        const obj = {
          idRequestingAgency: response2.data
            .filter(e => e.id == value.idRequestingAgency)
            .map(e => e.name),
          nameRequestedAgency: value.nameRequestedAgency,
          deleted: value.deleted,
          createdAt: value.createdAt,
          desc: value.desc,
          id: value.id,
          status: value.status,
          situation: value.situation,
          updatedAt: value.updatedAt,
        }
        acc.push(obj)
        return acc
      }, []),
    )

    setLoading(false)
  }

  async function onSubmit(e) {
    if (e.nameRequestedAgency && e.idRequestingAgency && e.situation) {
      return setData(
        newData.filter(
          e2 =>
            e2.nameRequestedAgency
              .toLowerCase()
              .replaceAll('á', 'a')
              .replaceAll('é', 'e')
              .replaceAll('í', 'i')
              .replaceAll('ó', 'o')
              .replaceAll('ú', 'ó')
              .replaceAll('â', 'a')
              .replaceAll('ê', 'e')
              .replaceAll('ô', 'o')
              .replaceAll('ã', 'a')
              .replaceAll('õ', 'o')
              .replaceAll('ç', 'c')
              .includes(
                e.nameRequestedAgency
                  .toLowerCase()
                  .replaceAll('á', 'a')
                  .replaceAll('é', 'e')
                  .replaceAll('í', 'i')
                  .replaceAll('ó', 'o')
                  .replaceAll('ú', 'ó')
                  .replaceAll('â', 'a')
                  .replaceAll('ê', 'e')
                  .replaceAll('ô', 'o')
                  .replaceAll('ã', 'a')
                  .replaceAll('õ', 'o')
                  .replaceAll('ç', 'c'),
              ) &&
            e2.idRequestingAgency[0]
              .toLowerCase()
              .replaceAll('á', 'a')
              .replaceAll('é', 'e')
              .replaceAll('í', 'i')
              .replaceAll('ó', 'o')
              .replaceAll('ú', 'ó')
              .replaceAll('â', 'a')
              .replaceAll('ê', 'e')
              .replaceAll('ô', 'o')
              .replaceAll('ã', 'a')
              .replaceAll('õ', 'o')
              .replaceAll('ç', 'c')
              .includes(
                e.idRequestingAgency
                  .toLowerCase()
                  .replaceAll('á', 'a')
                  .replaceAll('é', 'e')
                  .replaceAll('í', 'i')
                  .replaceAll('ó', 'o')
                  .replaceAll('ú', 'ó')
                  .replaceAll('â', 'a')
                  .replaceAll('ê', 'e')
                  .replaceAll('ô', 'o')
                  .replaceAll('ã', 'a')
                  .replaceAll('õ', 'o')
                  .replaceAll('ç', 'c'),
              ) &&
            e2.situation == e.situation.value,
        ),
      )
    }

    if (e.nameRequestedAgency && e.idRequestingAgency) {
      return setData(
        newData.filter(
          e2 =>
            e2.nameRequestedAgency
              .toLowerCase()
              .replaceAll('á', 'a')
              .replaceAll('é', 'e')
              .replaceAll('í', 'i')
              .replaceAll('ó', 'o')
              .replaceAll('ú', 'ó')
              .replaceAll('â', 'a')
              .replaceAll('ê', 'e')
              .replaceAll('ô', 'o')
              .replaceAll('ã', 'a')
              .replaceAll('õ', 'o')
              .replaceAll('ç', 'c')
              .includes(
                e.nameRequestedAgency
                  .toLowerCase()
                  .replaceAll('á', 'a')
                  .replaceAll('é', 'e')
                  .replaceAll('í', 'i')
                  .replaceAll('ó', 'o')
                  .replaceAll('ú', 'ó')
                  .replaceAll('â', 'a')
                  .replaceAll('ê', 'e')
                  .replaceAll('ô', 'o')
                  .replaceAll('ã', 'a')
                  .replaceAll('õ', 'o')
                  .replaceAll('ç', 'c'),
              ) &&
            e2.idRequestingAgency[0]
              .toLowerCase()
              .replaceAll('á', 'a')
              .replaceAll('é', 'e')
              .replaceAll('í', 'i')
              .replaceAll('ó', 'o')
              .replaceAll('ú', 'ó')
              .replaceAll('â', 'a')
              .replaceAll('ê', 'e')
              .replaceAll('ô', 'o')
              .replaceAll('ã', 'a')
              .replaceAll('õ', 'o')
              .replaceAll('ç', 'c')
              .includes(
                e.idRequestingAgency
                  .toLowerCase()
                  .replaceAll('á', 'a')
                  .replaceAll('é', 'e')
                  .replaceAll('í', 'i')
                  .replaceAll('ó', 'o')
                  .replaceAll('ú', 'ó')
                  .replaceAll('â', 'a')
                  .replaceAll('ê', 'e')
                  .replaceAll('ô', 'o')
                  .replaceAll('ã', 'a')
                  .replaceAll('õ', 'o')
                  .replaceAll('ç', 'c'),
              ),
        ),
      )
    }

    if (e.nameRequestedAgency && e.situation) {
      return setData(
        newData.filter(
          e2 =>
            e2.nameRequestedAgency
              .toLowerCase()
              .replaceAll('á', 'a')
              .replaceAll('é', 'e')
              .replaceAll('í', 'i')
              .replaceAll('ó', 'o')
              .replaceAll('ú', 'ó')
              .replaceAll('â', 'a')
              .replaceAll('ê', 'e')
              .replaceAll('ô', 'o')
              .replaceAll('ã', 'a')
              .replaceAll('õ', 'o')
              .replaceAll('ç', 'c')
              .includes(
                e.nameRequestedAgency
                  .toLowerCase()
                  .replaceAll('á', 'a')
                  .replaceAll('é', 'e')
                  .replaceAll('í', 'i')
                  .replaceAll('ó', 'o')
                  .replaceAll('ú', 'ó')
                  .replaceAll('â', 'a')
                  .replaceAll('ê', 'e')
                  .replaceAll('ô', 'o')
                  .replaceAll('ã', 'a')
                  .replaceAll('õ', 'o')
                  .replaceAll('ç', 'c'),
              ) && e2.situation == e.situation.value,
        ),
      )
    }

    if (e.idRequestingAgency && e.situation) {
      return setData(
        newData.filter(
          e2 =>
            e2.idRequestingAgency[0]
              .toLowerCase()
              .replaceAll('á', 'a')
              .replaceAll('é', 'e')
              .replaceAll('í', 'i')
              .replaceAll('ó', 'o')
              .replaceAll('ú', 'ó')
              .replaceAll('â', 'a')
              .replaceAll('ê', 'e')
              .replaceAll('ô', 'o')
              .replaceAll('ã', 'a')
              .replaceAll('õ', 'o')
              .replaceAll('ç', 'c')
              .includes(
                e.idRequestingAgency
                  .toLowerCase()
                  .replaceAll('á', 'a')
                  .replaceAll('é', 'e')
                  .replaceAll('í', 'i')
                  .replaceAll('ó', 'o')
                  .replaceAll('ú', 'ó')
                  .replaceAll('â', 'a')
                  .replaceAll('ê', 'e')
                  .replaceAll('ô', 'o')
                  .replaceAll('ã', 'a')
                  .replaceAll('õ', 'o')
                  .replaceAll('ç', 'c'),
              ) && e2.situation == e.situation.value,
        ),
      )
    }

    if (e.nameRequestedAgency) {
      return setData(
        newData.filter(e2 =>
          e2.nameRequestedAgency
            .toLowerCase()
            .replaceAll('á', 'a')
            .replaceAll('é', 'e')
            .replaceAll('í', 'i')
            .replaceAll('ó', 'o')
            .replaceAll('ú', 'ó')
            .replaceAll('â', 'a')
            .replaceAll('ê', 'e')
            .replaceAll('ô', 'o')
            .replaceAll('ã', 'a')
            .replaceAll('õ', 'o')
            .replaceAll('ç', 'c')
            .includes(
              e.nameRequestedAgency
                .toLowerCase()
                .replaceAll('á', 'a')
                .replaceAll('é', 'e')
                .replaceAll('í', 'i')
                .replaceAll('ó', 'o')
                .replaceAll('ú', 'ó')
                .replaceAll('â', 'a')
                .replaceAll('ê', 'e')
                .replaceAll('ô', 'o')
                .replaceAll('ã', 'a')
                .replaceAll('õ', 'o')
                .replaceAll('ç', 'c'),
            ),
        ),
      )
    }

    if (e.idRequestingAgency) {
      return setData(
        newData.filter(e2 =>
          e2.idRequestingAgency[0]
            .toLowerCase()
            .replaceAll('á', 'a')
            .replaceAll('é', 'e')
            .replaceAll('í', 'i')
            .replaceAll('ó', 'o')
            .replaceAll('ú', 'ó')
            .replaceAll('â', 'a')
            .replaceAll('ê', 'e')
            .replaceAll('ô', 'o')
            .replaceAll('ã', 'a')
            .replaceAll('õ', 'o')
            .replaceAll('ç', 'c')
            .includes(
              e.idRequestingAgency
                .toLowerCase()
                .replaceAll('á', 'a')
                .replaceAll('é', 'e')
                .replaceAll('í', 'i')
                .replaceAll('ó', 'o')
                .replaceAll('ú', 'ó')
                .replaceAll('â', 'a')
                .replaceAll('ê', 'e')
                .replaceAll('ô', 'o')
                .replaceAll('ã', 'a')
                .replaceAll('õ', 'o')
                .replaceAll('ç', 'c'),
            ),
        ),
      )
    }

    if (e.situation) {
      return setData(newData.filter(e2 => e2.situation == e.situation.value))
    }
  }

  return (
    <Container>
      <h1 className='page-header'>Solicitação de Parceria</h1>

      <Panel>
        <PanelHeader noButton title={'Faça a sua solicitação'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={requestGetApi}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'idRequestingAgency',
                label: 'Agência Requerente',
                sm: 12,
                lg: 4,
              },
              {
                type: 'text',
                name: 'nameRequestedAgency',
                label: 'Agência Requerida',
                sm: 12,
                lg: 4,
              },
              {
                type: 'select',
                name: 'situation',
                label: 'Status',
                placeholder: 'Status',
                options: [
                  { label: 'Solicitado', value: 'Solicitado' },
                  { label: 'Aprovado', value: 'Aprovado' },
                  { label: 'Recusado', value: 'Recusado' },
                ],
                sm: 12,
                lg: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Tabela de Solicitações'} noButton />

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Agência Requerente',
                accessor: 'idRequestingAgency',
                // Cell: props => (
                //   <p>
                //     {agencies
                //       .filter(
                //         e => e.id == props.row.original.idRequestingAgency,
                //       )
                //       .map(e => e.name)}
                //   </p>
                // ),
                width: 150,
              },
              {
                Header: 'Agência Requerida',
                accessor: 'nameRequestedAgency',
                width: 150,
              },
              {
                Header: 'Status',
                accessor: 'situation',
                width: 150,
              },
              {
                Header: 'Complemento / Justificativa',
                accessor: 'desc',
                width: 300,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <LinkEdit
                      to='details'
                      state={{ id: props.row.original.id }}
                    >
                      Detalhes
                    </LinkEdit>
                  )
                },
                width: 80,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
