import styled from 'styled-components'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsTable from '~/components/Analytics/Table'
import Divisor from '~/components/Divisor'
import useHoldingConsolidatorCustomersData from '../../dataHooks/useHoldingConsolidatorCustomers'

export default function HoldingConsolidatorCustomers({ filters }) {
  const { loading, dashboards, tableData } =
    useHoldingConsolidatorCustomersData({ filters })

  return (
    <Container>
      <AnalyticsDashboard data={dashboards} />

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Clientes por estado'
        data={tableData}
        loading={loading}
        columns={[
          {
            Header: 'Estado',
            accessor: 'state',
            width: 300,
          },
          {
            Header: 'Quantidade de clientes',
            accessor: 'customersCount',
            width: 300,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div``
