import { useEffect, useState } from 'react'
import { LinkEdit } from '~/components/Link/styles'
import ReactTable from '~/components/Table'
import { GetAxios } from '~/services/api'
import {
  LinkTravelDetails,
  ButtonToLinkRoadMap,
} from '~/components/Link/styles'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function Table({ data, loading }) {
  const { axiosApis } = GetAxios()
  const [roles, setRoles] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
        )

        return setRoles(data)
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function showDetails(e) {
    navigate('/travels/details', { state: { id: e } })
  }

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'Ranking',
          accessor: 'id',
          Cell: props => <span>{props.row.index + 1}º</span>,
          width: 100,
        },
        {
          Header: 'Tipo de serviço',
          accessor: 'type',
          width: 120,
        },
        {
          Header: 'Fornecedor',
          accessor: 'name',
          width: 150,
        },
        {
          Header: 'Quantidade',
          accessor: 'quant_Viagens',
          width: 90,
        },
        {
          Header: 'Último consultor',
          accessor: 'agent',
          width: 200,
        },
        {
          Header: 'Cidade',
          accessor: 'city',
          width: 200,
        },
        {
          Header: '',
          accessor: 'detalhes-viagem',
          Cell: props => {
            return (
              // <LinkTravelDetails
              //   to={'/travels/details'}
              //   state={{ id: props.row.original.travelId }}
              // >
              //   Detalhes
              // </LinkTravelDetails>
              <IconButton
                id={props.row.index}
                type={'details'}
                tooltipOverride={'Detalhes da última viagem'}
                onClick={() => showDetails(props.row.original.travelId)}
              />
            )
          },
          width: 20,
        },
      ]}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
