import { useRef } from 'react'
import { toast } from 'react-toastify'
import { Form } from 'reactstrap'
import styled from 'styled-components'
import { SpaceBetweenDiv } from '~/styles/divs'
import Input from '../Input'

export default function MultiFileInput({
  onChange = () => {},
  label = 'Arquivo',
  name = 'file',
}) {
  const ref = useRef()

  async function handleFile(e) {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size > 3145728) {
        ref.current?.reset()

        onChange({ target: { name: name, value: undefined } })

        return toast.info('Insira um arquivo com tamanho máximo de 3 MB')
      }

      onChange({ target: { name: name, value: e.target.files } })
    }
  }

  return (
    <Container>
      <Form innerRef={ref} onSubmit={undefined}>
        <SpaceBetweenDiv>
          <label style={{ margin: '2px 4px' }} htmlFor='file'>
            <b style={{ fontWeight: '600', fontSize: '12px' }}>{label}</b>
          </label>
        </SpaceBetweenDiv>
        <Input type='file' name={name} onChange={handleFile} multiple />
      </Form>
    </Container>
  )
}

export const DivFlexCentered = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
`
