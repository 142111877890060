import { useState, useEffect } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import { getDateFormated, getMoment } from '~/functions/Date'
import { checkValidRange } from '~/utils/date'
import { toast } from 'react-toastify'
import IconButton from '~/components/IconButton'

const DEFAULT_FILTERS = {
  dateStart:
    location.state?.start ??
    getDateFormated(getMoment().add(-1, 'months'), 'YYYY-MM-DD'),
  dateEnd: location.state?.end ?? getDateFormated(getMoment(), 'YYYY-MM-DD'),
}

export default function ReportTravels() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [departures, setDepartures] = useState([])

  useEffect(() => {
    onSubmit(DEFAULT_FILTERS)
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      if (data.name_viajante) {
        formData.append('name_viajante', data.name_viajante)
      }

      if (data.city_origin) {
        formData.append('city_origin', data.city_origin)
      }

      if (data.city_destiny) {
        formData.append('city_destiny', data.city_destiny)
      }

      if (data.dateStart && data.dateEnd) {
        formData.append('dateStart', data.dateStart)

        formData.append('dateEnd', data.dateEnd)
      }

      if (data.name_agent) {
        formData.append('name_agent', data.name_agent)
      }

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/quotation/report/embarques`,
        { params: data },
      )

      setDepartures(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)

      return toast.info('Não foi possível realizar a solicitação.')
    }
  }

  async function onValidate(data) {
    if (
      data.dateStart &&
      data.dateEnd &&
      !checkValidRange(data.dateStart, data.dateEnd, null, 6)
    ) {
      return false
    }

    if (Object.keys(data).length === 0) {
      toast.info('Preencha os filtros.')

      return false
    }

    return true
  }

  return (
    <Container>
      <h1 className='page-header'>
        Embarques
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'A Tela apresenta todos os embarques com base nas integrações caso a agencia não tenha integração é puxado as informações dos aéreos cadastrados.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {
              return
            }}
            onValidate={onValidate}
            defaultValue={DEFAULT_FILTERS}
            loading={loading}
            disabled={loading}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'name_viajante',
                label: 'Viajante',
                sm: 12,
                lg: 2,
              },
              {
                type: 'text',
                name: 'name_agent',
                label: 'Consultor',
                sm: 12,
                lg: 2,
              },
              {
                type: 'text',
                name: 'city_origin',
                label: 'Origem',
                sm: 12,
                lg: 2,
              },
              {
                type: 'text',
                name: 'city_destiny',
                label: 'Destino',
                sm: 12,
                lg: 2,
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Data Inicial',
                sm: 12,
                lg: 2,
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Data Final',
                sm: 12,
                lg: 2,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table data={departures} loading={loading} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
