export const SET_STARTED_ONCE = 'SET_STARTED_ONCE'
export const SET_DATA = 'SET_DATA'
export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION'
export const SET_CURRENT_CHAT_DATA = 'SET_CURRENT_CHAT_DATA'
export const SET_CURRENT_CONTACTS = 'SET_CURRENT_CONTACTS'
export const SET_CLIENTS_DATA = 'SET_CLIENTS_DATA'
export const SET_CUSTOMER = 'SET_CUSTOMER'

export const setStart = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_STARTED_ONCE,
  })
}

export const setData = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_DATA,
  })
}

export const setCurrentChatData = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CURRENT_CHAT_DATA,
  })
}

export const setCurrentContacts = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CURRENT_CONTACTS,
  })
}

export const setClientsData = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CLIENTS_DATA,
  })
}

export const setCustomer = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CUSTOMER,
  })
}
