import React from 'react'
import { Container } from '~/styles/container'
import PageTitle from '~/components/PageTitle'
import { Outlet, useLocation } from 'react-router-dom'

export default function Documents() {
  const location = useLocation()
  return (
    <Container>
      <PageTitle
        title={'Documentos do Evento "' + location.state.event.title + '"'}
      />

      <Outlet context={{ event: location.state.event }} />
    </Container>
  )
}
