import { Form } from '@unform/web'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { SaveButton } from '~/components/Button/styles'
import ImageInput from '~/components/ImageInput'
import Input from '~/components/Input'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import SlideInput from '~/components/SlideInput'
import TextArea from '~/components/TextArea'
import { getDateFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import { onUserUpdate } from '~/store/root/slices/auth'
import { DivFlexRight } from '~/styles/divs'
import { Container } from './styles'
import Select from '~/components/Select'

export default function Profile() {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const user = useSelector(state => state.auth.user)
  const [ddi, setDdi] = useState({})
  const [entity, setEntity] = useState({
    familyName:
      user.familyName == 'null' || user.familyName == 'undefined'
        ? ' '
        : user.familyName,
    fullName:
      user.fullName == 'null' || user.fullName == 'undefined'
        ? ' '
        : user.fullName,
    email: user.email == 'null' || user.email == 'undefined' ? ' ' : user.email,
    phone: user.phone == 'null' || user.phone == 'undefined' ? ' ' : user.phone,
    cpf: user.cpf == 'null' || user.cpf == 'undefined' ? ' ' : user.cpf,
    title: user.title == 'null' || user.title == 'undefined' ? ' ' : user.title,
    since:
      getDateFormated(user.since, 'YYYY') == '0001'
        ? ''
        : getDateFormated(user.since, 'YYYY'),
    bio: user.bio == 'null' || user.bio == 'undefined' ? ' ' : user.bio,
    experiences:
      user.experiences == 'null' || user.experiences == 'undefined'
        ? ' '
        : user.experiences,
    ddiValue: user.ddiValue,
    avatar:
      user.avatar == 'null' || user.avatar == 'undefined' ? ' ' : user.avatar,
    allowWhatsapp: user.allowWhatsapp ? true : false,
  })

  useEffect(() => {
    getOptionsDDI()
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )

      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  function setEntityField(prop, value) {
    setEntity({
      ...entity,
      [prop]: value,
    })
  }

  async function handleSubmit() {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('familyName', entity.familyName)
      formData.append('fullName', entity.fullName)
      formData.append('email', entity.email)
      formData.append('phone', entity.phone)
      formData.append('cpf', entity.cpf)
      formData.append('title', entity.title)
      formData.append(
        'since',
        entity.since ? `01/01/${entity.since}` : '01/01/0001',
      )
      formData.append('bio', entity.bio)
      formData.append('experiences', entity.experiences)
      formData.append('avatar', entity.avatar)
      formData.append('allowWhatsapp', entity.allowWhatsapp)
      formData.append('ddi', entity.ddiValue.value)

      const { data } = await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/profileagent`,
        formData,
      )

      dispatch(
        onUserUpdate({
          ...user,
          familyName: data.familyName,
          fullName: data.fullName,
          email: data.email,
          phone: data.phone,
          cpf: data.cpf,
          title: data.title,
          since: data.since,
          bio: data.bio,
          experiences: data.experiences,
          avatar: data.avatar,
          ddi: data.ddi.value,
          allowWhatsapp: data.allowWhatsapp,
        }),
      )

      toast.success('Usuário editado com sucesso!')

      setLoading(false)
    } catch (error) {
      setLoading(false)

      toast.info('Não foi possível editar o usuário.')
    }
  }

  return (
    <Container>
      <h1 className='page-header'>Meu Perfil</h1>

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Container>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h4>Dados Pessoais </h4>

              <Row>
                <Col sm={12} md={6} xl={4}>
                  <Input
                    name={'familyName'}
                    label={'Nome de Preferência'}
                    maxLength={20}
                    disabled={loading}
                    value={entity.familyName}
                    onChange={e => setEntityField('familyName', e.target.value)}
                    required
                  />
                </Col>

                <Col sm={12} md={6} xl={4}>
                  <Input
                    name={'fullName'}
                    label={'Nome Completo'}
                    value={entity.fullName}
                    maxLength={30}
                    disabled={loading}
                    onChange={e => setEntityField('fullName', e.target.value)}
                    required
                  />
                </Col>

                <Col sm={12} md={6} xl={4}>
                  <Input
                    disabled={true}
                    name={'email'}
                    label={'E-mail'}
                    maxLength={50}
                    value={entity.email}
                    onChange={e => setEntityField('email', e.target.value)}
                    required
                  />
                </Col>

                <Col sm={12} md={2}>
                  <Select
                    name={'ddiValue'}
                    label={'DDI'}
                    options={ddi}
                    value={entity['ddiValue']}
                    onChange={e => setEntityField('ddiValue', e.target.value)}
                    placeholder={'Selecione...'}
                    disabled={loading}
                    maxLength={30}
                    isSearchable
                    // required
                  />
                </Col>

                <Col sm={12} md={6} xl={2}>
                  <Input
                    name={'phone'}
                    label={'Telefone'}
                    placeholder={'Telefone com (DDD)'}
                    mask={'(99) 99999-9999'}
                    value={entity.phone}
                    disabled={loading}
                    onChange={e => setEntityField('phone', e.target.value)}
                    // required
                  />
                </Col>

                <Col sm={12} md={6} xl={2}>
                  <Input
                    name={'cpf'}
                    label={'CPF'}
                    value={entity.cpf}
                    disabled={loading}
                    onChange={e => setEntityField('cpf', e.target.value)}
                    // required
                  />
                </Col>

                <Col sm={12} md={6} xl={2}>
                  <Input
                    name={'title'}
                    maxLength={30}
                    disabled={loading}
                    label={'Profissão'}
                    value={entity.title}
                    onChange={e => setEntityField('title', e.target.value)}
                    // required
                  />
                </Col>

                <Col sm={12} md={6} xl={4}>
                  <Input
                    disabled={loading}
                    name={'since'}
                    label={
                      'Anos de Experiência (informe o ano em que o consultor começou na área)'
                    }
                    mask={'9999'}
                    value={entity.since}
                    onChange={e => setEntityField('since', e.target.value)}
                    // required
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={6}>
                  <TextArea
                    name={'bio'}
                    label={'Bio'}
                    maxLength={2000}
                    placeholder={'Escreva aqui...'}
                    value={entity.bio}
                    onChange={e => setEntityField('bio', e.target.value)}
                    disabled={loading}
                    // required
                  />
                </Col>

                <Col sm={12} md={6}>
                  <ImageInput
                    name={'avatar'}
                    value={entity.avatar}
                    label={'Avatar'}
                    onChange={e => setEntityField('avatar', e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <TextArea
                    name={'experiences'}
                    label={
                      "Especialidades (para adicionar vários, separe-as por ' ; ')"
                    }
                    value={entity.experiences}
                    onChange={e =>
                      setEntityField('experiences', e.target.value)
                    }
                    maxLength={200}
                    rows='3'
                    disabled={loading}
                    // required
                  />
                </Col>

                <Col sm={12} md={6}>
                  <SlideInput
                    name={'allowWhatsapp'}
                    label={'Permitir alertas por WhatsApp aos meus viajantes'}
                    value={entity['allowWhatsapp']}
                    onChange={e =>
                      setEntityField(e.target.name, e.target.checked)
                    }
                  />
                </Col>

                <Col sm={12}>
                  <DivFlexRight>
                    <SaveButton disabled={loading} type={'submit'}>
                      Salvar
                    </SaveButton>
                  </DivFlexRight>
                </Col>
              </Row>
            </Form>
          </Container>
        </PanelBody>
      </Panel>
    </Container>
  )
}
