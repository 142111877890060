import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import LoadingContainer from '~/components/LoadingContainer'
import DefaultForm from '~/components/DefaultForm'
import { Container } from '~/styles/container'
import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import './style.scss'
import { Link } from 'react-router-dom'
import { LabelSection } from './styles'

export default function AgencyList() {
  const [countrySelected2, setCountrySelected2] = useState({
    label: 'Brazil',
    value: 'Brazil',
  })
  const [citiesSelected2, setCitiesSelected2] = useState(undefined)
  const [countrySelected, setCountrySelected] = useState('')
  const [citiesSelected, setCitiesSelected] = useState('')
  const [experience, setExperience] = useState([])
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [loading, setLoading] = useState(false)
  const [destiny, setDestiny] = useState([])
  const [country, setCountry] = useState([])
  const [travel, setTravel] = useState([])
  const [cities, setCities] = useState([])

  const { axiosApis } = GetAxios()

  useEffect(() => {
    requestApiCountries()
    getTravelTableData()
    requestApiXP()
    LoadDestiny()
  }, [countrySelected.value])

  useEffect(() => {
    requestApiCities()
  }, [])

  const requestApiCountries = async () => {
    const { data } = await axiosApis.options(
      `${process.env.REACT_APP_ENDPOINT_APIS}/city/countries`,
    )
    setCountry(data)
  }

  const requestApiCities = async () => {
    setLoading(true)
    await axiosApis
      .get(`${process.env.REACT_APP_ENDPOINT_APIS}/city/countries`, {
        params: { country: `${countrySelected.value}` },
      })
      .then(data =>
        setCities(
          data.data.reduce((acc, item) => {
            const obj = {
              label: item.name,
              value: item.name,
              id: item.id,
            }
            acc.push(obj)
            return acc
          }, []),
        ),
      )
    setLoading(false)
  }

  async function getTravelTableData() {
    const response = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travel`,
      {
        params: `${countrySelected.value}`,
      },
    )
    setTravel(response.data)
  }

  async function requestApiXP() {
    const result = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience`,
    )

    setExperience(result.data)
  }

  async function LoadDestiny() {
    // setLoading(true)

    const resDestinys = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperiencedestiny`,
    )

    setDestiny(resDestinys.data)
  }

  const onReset = async () => {
    setLoading2(false)
    setLoading3(true)
    setCitiesSelected2('')
    setCitiesSelected('')
    setCountrySelected2('')
    setCountrySelected('')
  }

  const onSearch = async () => {
    setLoading2(true)
    setLoading3(false)
    setCountrySelected2(countrySelected)
    requestApiCities()
    // setCitiesSelected2(
    //   citiesSelected2 != undefined && citiesSelected2 != ''
    //     ? citiesSelected
    //     : countrySelected,
    // )
    setCitiesSelected2(citiesSelected)
  }

  const filterPerDestiny = () => {
    const result = destiny.filter(value =>
      value.city.includes(
        citiesSelected2 != undefined && citiesSelected2 != ''
          ? citiesSelected2.value
          : countrySelected2.value,
      ),
    )
    return result
  }

  const filterPerXP = () => {
    const result = experience.filter(value =>
      value.city.includes(
        citiesSelected2 != undefined && citiesSelected2 != ''
          ? citiesSelected2.value
          : countrySelected2.value,
      ),
    )
    return result
  }

  const filterPerTrevel = () => {
    const result = travel.filter(value =>
      value.destiny.includes(
        citiesSelected2 != undefined && citiesSelected2 != ''
          ? citiesSelected2.value
          : countrySelected2.value,
      ),
    )
    return result
  }

  const labelSelected = label => {
    let init = `${label} Brazil`

    if (loading2) {
      if (label == 'Destinos em') {
        init = `Destinos em ${
          citiesSelected2.value == undefined
            ? countrySelected2.value
            : citiesSelected2.value
        }`
      }
      if (label == 'Experiências em') {
        init = `Experiências em ${
          citiesSelected2.value == undefined
            ? countrySelected2.value
            : citiesSelected2.value
        }`
      }
      if (label == 'Viagens para') {
        init = `Viagens para ${
          citiesSelected2.value == undefined
            ? countrySelected2.value
            : citiesSelected2.value
        }`
      }

      if (
        countrySelected2.value == undefined &&
        citiesSelected2.value == undefined
      ) {
        if (label == 'Destinos em') init = 'Destinos'
        if (label == 'Experiências em') init = 'Experiências'
        if (label == 'Viagens para') init = 'Viagens'
      }
    }

    if (loading3) {
      if (label == 'Destinos em') init = 'Destinos'
      if (label == 'Experiências em') init = 'Experiências'
      if (label == 'Viagens para') init = 'Viagens'
    }

    return init
  }

  return (
    <Container>
      <h1 className='page-header'>Portfólio de Cidades</h1>

      <Panel>
        <PanelHeader noButton title={'Filtro'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSearch}
            callbackReset={onReset}
            defaultValue={{
              country: countrySelected,
              cities: citiesSelected,
            }}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                options: country,
                name: 'country',
                label: 'País',
                placeholder: 'País',
                onChange: props => {
                  setCountrySelected(props.target.value)
                  setCitiesSelected2('')
                  setCitiesSelected('')
                  // SetDefaultValueCountries('')
                  // SetDefaultValueCities('')
                  // setCountrySelected2('')
                },
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'select',
                options: cities,
                name: 'cities',
                label: 'Cidade',
                placeholder: 'Cidade',
                onChange: props => {
                  setCitiesSelected(props.target.value)
                },
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'} />

        <PanelBody>
          <div className='DivSpans'>
            <div className='sections'>
              <LabelSection>{labelSelected('Destinos em')}</LabelSection>

              {loading ? (
                <LoadingContainer />
              ) : (
                filterPerDestiny().map((value, index) => (
                  <Link
                    to='/destinys/details'
                    state={{ id: value.id }}
                    key={index}
                    className='card'
                    style={{
                      backgroundColor: '#eaeaea',
                      padding: '8px',
                      borderRadius: '8px',
                      marginTop: '10px',
                    }}
                  >
                    <p className='field-title'>{value.title}</p>
                    <p
                      className='field-body'
                      dangerouslySetInnerHTML={{
                        __html: `${value.desc.substring(0, 50)}...`,
                      }}
                    ></p>
                  </Link>
                ))
              )}
            </div>

            <div className='sections'>
              <LabelSection>{labelSelected('Experiências em')}</LabelSection>

              {loading ? (
                <LoadingContainer />
              ) : (
                filterPerXP().map((value, index) => (
                  <Link
                    to='/experiences/details'
                    state={{ id: value.id }}
                    key={index}
                    className='card'
                    style={{
                      backgroundColor: '#eaeaea',
                      padding: '8px',
                      borderRadius: '8px',
                      marginTop: '10px',
                    }}
                  >
                    <p className='field-title'>{value.title}</p>
                    <p className='field-body'>{value.category}</p>
                    <p
                      className='field-body'
                      dangerouslySetInnerHTML={{
                        __html: `${value.desc.substring(0, 50)}...`,
                      }}
                    ></p>
                  </Link>
                ))
              )}
            </div>

            <div className='sections'>
              <LabelSection>{labelSelected('Viagens para')}</LabelSection>

              {loading ? (
                <LoadingContainer />
              ) : (
                filterPerTrevel().map((value, index) => (
                  <Link
                    to='/travels/details/presentation'
                    state={{ id: value.id }}
                    key={index}
                    className='card'
                    style={{
                      backgroundColor: '#eaeaea',
                      padding: '8px',
                      borderRadius: '8px',
                      marginTop: '10px',
                    }}
                  >
                    <p className='field-body'>{value.destiny}</p>
                    <p className='field-body'>{value.origin}</p>
                    <p className='field-body'>{value.traveler}</p>
                  </Link>
                ))
              )}
            </div>
          </div>
        </PanelBody>
      </Panel>
    </Container>
  )
}
