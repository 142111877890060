import IconButton from '~/components/IconButton'

export const inputsDefaultForm = tagOptions => {
  const inputs = [
    {
      type: 'text',
      name: 'name',
      label: 'Nome ou sobrenome',
      placeholder: 'Nome ou sobrenome',
      sm: 12,
      lg: 4,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      placeholder: 'E-mail',
      sm: 12,
      lg: 4,
    },
    {
      type: 'phone',
      name: 'phone',
      label: 'Telefone',
      mask: '(99) 99999-9999',
      sm: 12,
      lg: 4,
    },
    {
      type: 'select',
      name: 'order',
      label: 'Ordenar por:',
      isClearable: true,
      options: [
        {
          label: 'Ordem Alfabética',
          value: 'alf',
        },
      ],
      maxLength: 150,
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'tag',
      label: 'Tags',
      isClearable: true,
      options: tagOptions,
      isMulti: true,
      sm: 12,
      lg: 2,
    },
    {
      type: 'check',
      name: 'vip',
      label: 'Viajante VIP',
      sm: 12,
      lg: 2,
    },
  ]

  return inputs
}

export const inputsTable = ({
  user,
  showDetails,
  showDocuments,
  showInfo,
  deleteTraveler,
}) => {
  const inputs = [
    {
      Header: 'Nome Completo',
      accessor: 'fullName',
      width: 190,
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      width: 240,
    },
    {
      Header: 'Telefone',
      accessor: 'phone',
      width: 120,
    },
    {
      Header: 'VIP',
      accessor: 'vip',
      width: 80,
      Cell: props =>
        props.row.original.vip ? (
          <i className='fas fa-star' style={{ color: '#efb810' }} />
        ) : (
          ''
        ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 80,
      Cell: props => {
        if (props.row.original.status == false) return <span>Ativo</span>

        return <span>Desativado</span>
      },
    },
    {
      Header: 'Tags',
      accessor: 'tag',
      width: 200,
    },
    {
      Header: '',
      accessor: 'details',
      Cell: props => {
        return (
          <IconButton
            id={props.row.index}
            type={'details'}
            onClick={() => showDetails(props.row.original)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'docs',
      Cell: props => {
        return (
          <IconButton
            id={props.row.id}
            type={'file'}
            onClick={() => showDocuments(props.row.original)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'tags',
      Cell: props => {
        return (
          <IconButton
            id={props.row.id}
            iconOverride={'fas fa-solid fa-tags'}
            tooltipOverride={'Tags'}
            type={'tags'}
            onClick={() => showInfo(props.row.original)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'delete',
      Cell: props => {
        return (
          <IconButton
            id={props.row.id}
            iconOverride={'fas fa-solid fa-trash'}
            tooltipOverride={'Excluir'}
            type={'delete'}
            onClick={() => deleteTraveler(props.row.original)}
          />
        )
      },
      width: 20,
    },
    user.isOperator == true
      ? {
          Header: 'Agência',
          accessor: 'belongingAgency',
          width: 100,
        }
      : { Header: '', accessor: 'belongingA', width: 1 },
  ]

  return inputs
}

export const inputsDefaultFormDetails = (
  user,
  onSearchCEP,
  ddi,
  selectDdi,
  setSelectDdi,
) => {
  const inputs = [
    {
      empty: true,
      text: '<b>Dados pessoais</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'familyName',
      label: 'Nome de Preferência',
      required: true,
      maxLength: 150,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome Completo',
      required: true,
      maxLength: 150,
      sm: 12,
      lg: 3,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      maxLength: 150,
      sm: 12,
      lg: 3,
    },
    {
      type: 'select',
      name: 'ddi',
      label: 'DDI',
      options: ddi,
      placeholder: 'Selecione...',
      onChange: e => setSelectDdi(e.target.value),
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    selectDdi.value == '' || selectDdi.value == 55
      ? {
          type: 'phone',
          name: 'phone',
          label: 'Telefone',
          sm: 12,
          lg: 2,
        }
      : {
          type: 'text',
          name: 'phone',
          label: 'Telefone',
          mask: '999 99999999',
          sm: 12,
          lg: 2,
        },
    user == true
      ? {
          type: 'text',
          name: 'belongingAgency',
          label: 'Agência',
          required: true,
        }
      : { sm: 12 },
    {
      type: 'text',
      name: 'cpf',
      label: 'CPF/CNPJ',
      sm: 12,
      lg: 3,
    },
    {
      type: 'text',
      name: 'rg',
      label: 'RG/CNH',
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'date',
      name: 'birthDay',
      label: 'Aniversário',
    },
    {
      empty: true,
      text: '<b>Endereço</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'cep',
      label: 'CEP',
      mask: '99999-999',
      isClearable: true,
      required: false,
      searchButton: true,
      callbackSearch: onSearchCEP,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'bairro',
      label: 'Bairro',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'city',
      name: 'city',
      label: 'Cidade',
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      empty: true,
      text: '<b>Outras informações</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'username',
      label: 'Usuário',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'accessCode',
      label: 'Código de acesso',
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'profession',
      label: 'Profissão',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'textarea',
      name: 'perfil',
      label: 'Perfil do Viajante',
      sm: 12,
      lg: 6,
    },
    {
      type: 'check',
      name: 'vip',
      label: 'Viajante VIP',
      sm: 12,
      lg: 3,
    },
    {
      type: 'check',
      name: 'disabled',
      label: 'Desativar Viajante',
      sm: 12,
      lg: 3,
    },
  ]

  return inputs
}
