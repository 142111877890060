import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useEffect, useState } from 'react'
import {
  getDateDiff,
  getDateDiffLabel,
  getDateFormated,
  isPastDate,
} from '~/functions/Date'
import IconButton from '../IconButton'
import { Spinner } from 'reactstrap'
import { DivFlexRight, SpaceBetweenDiv } from '~/styles/divs'
import usePermissions from '~/hooks/usePermissions'

export default function NewKanban({
  title,
  data = [],
  loading,
  callbackOnMove,
  callbackOnAdd,
  callbackOnAddList,
  callbackOnDetails,
  callbackOnProposal,
  callbackOnTraveler,
  callbackOnTravel,
  callbackOnDelete,
  onEditList,
  onMoveList,
  highlightColor = () => {
    return '#198754'
  },
}) {
  const { isAdmin } = usePermissions()
  const [stages, setStages] = useState(data)
  const [lock, setLock] = useState(true)
  const altTitle = 'Início do contato'
  const altDateFormat = 'DD/MM/YYYY'
  const showAltDateLabel = true
  const descCaractersLimit = 50

  useEffect(() => {
    const newStages = getStages(data, !lock)

    setStages(newStages)
  }, [data])

  const onDragEnd = async droppedItem => {
    if (!droppedItem.destination) return

    try {
      var sourceStageChilds = [
        ...stages.find(x => x.code === droppedItem.source.droppableId).childs,
      ]

      const [droppedItemRemoved] = sourceStageChilds.splice(
        droppedItem.source.index,
        1,
      )

      const stagesAfterRemove = stages.map(e => {
        if (e.code === droppedItem.source.droppableId) {
          return {
            ...e,
            childs: sourceStageChilds,
          }
        }

        return e
      })

      var destinationStageChilds = [
        ...stagesAfterRemove.find(
          x => x.code === droppedItem.destination.droppableId,
        ).childs,
      ]

      destinationStageChilds.splice(
        droppedItem.destination.index,
        0,
        droppedItemRemoved,
      )

      const stagesAfterAdd = stagesAfterRemove.map(e => {
        if (e.code === droppedItem.destination.droppableId) {
          return {
            ...e,
            childs: destinationStageChilds,
          }
        }

        return e
      })

      setStages(stagesAfterAdd)

      await callbackOnMove({
        item: droppedItemRemoved,
        newStage: data.find(
          x => x.code === droppedItem.destination.droppableId,
        ),
        oldStage: data.find(x => x.code === droppedItem.source.droppableId),
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function onLock() {
    setStages(getStages(data, lock))

    setLock(state => !state)
  }

  function orderStage(a, b) {
    if (a.order > b.order) {
      return 1
    }

    if (a.order < b.order) {
      return -1
    }

    return 0
  }

  function getChilds(stage) {
    const newChilds = stage.childs.filter(x => !x.archived)

    stage.childs = newChilds

    return stage
  }

  function getStages(e, includeDeactivateds) {
    const sorted = e
      .filter(x => x.status || includeDeactivateds)
      .sort(orderStage)

    return sorted.map(getChilds)
  }

  return (
    <div style={{ position: 'relative', borderRadius: '8px' }}>
      <Container>
        {title && <Title className='page-header'>{title}</Title>}

        {isAdmin && (
          <DivFlexRight
            style={{
              gap: '8px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <b style={{ fontSize: '14px', paddingLeft: '10px' }}>
                Regras das cores dos cards
              </b>
              <IconButton
                id={`color-rule-green`}
                type={'info'}
                tooltipOverride={'Verde para datas futuras'}
                iconOverride={'fa fa-info-circle'}
                style={{ fontSize: '12px', color: '#008656' }}
              />
              <IconButton
                id={`color-rule-orange`}
                type={'info'}
                tooltipOverride={
                  'Amarelo para data atual ou card com data de próxima ação para o futuro, entretanto sem alteração de dados ou posição a mais de um dia'
                }
                iconOverride={'fa fa-info-circle'}
                style={{ fontSize: '12px', color: '#ff7f2a' }}
              />
              <IconButton
                id={`color-rule-red`}
                type={'info'}
                tooltipOverride={
                  'Vermelho para datas passadas ou card com data de próxima ação para o futuro, entretanto sem alteração de dados ou posição a mais de dois dias'
                }
                iconOverride={'fa fa-info-circle'}
                style={{ fontSize: '12px', color: '#e43848' }}
              />
              <IconButton
                id={`color-rule-grey`}
                type={'info'}
                tooltipOverride={
                  'Cinza para cards que não possuem uma data de proxima ação'
                }
                iconOverride={'fa fa-info-circle'}
                style={{ fontSize: '12px', color: '#9E9E9E' }}
              />
            </div>

            <div style={{ paddingRight: '10px' }}>
              {!lock && (
                <IconButton
                  id={`new-kanban-create-list`}
                  type={'create'}
                  tooltipOverride={'Criar nova lista'}
                  style={{ fontSize: '12px' }}
                  onClick={callbackOnAddList}
                />
              )}
              <IconButton
                id={`new-kanban-lock`}
                type={'edit'}
                tooltipOverride={'Editar quadro'}
                iconOverride={lock ? 'fas fa-lock' : 'fas fa-lock-open'}
                style={{ fontSize: '12px' }}
                onClick={onLock}
              />
            </div>
          </DivFlexRight>
        )}

        <Content>
          <DragDropContext onDragEnd={onDragEnd}>
            {stages.map((e, i) => {
              return (
                <Stage
                  key={i}
                  style={{
                    opacity: e.status ? 1 : 0.5,
                    backgroundColor: '#f7f7f7',
                  }}
                >
                  <StageTitle>
                    {!lock && (
                      <SpaceBetweenDiv>
                        {i !== 0 ? (
                          <IconButton
                            id={e.code + '-move-list-to-left'}
                            type={'edit'}
                            tooltipOverride={'Mover lista para esquerda'}
                            iconOverride={'fas fa-arrow-left'}
                            style={{ fontSize: '12px' }}
                            onClick={() => onMoveList(e.code, -1)}
                          />
                        ) : (
                          <span>&nbsp;&nbsp;&nbsp;</span>
                        )}

                        <b>Mover lista</b>

                        {i !== stages.length - 1 ? (
                          <IconButton
                            id={e.code + '-move-list-to-right'}
                            type={'edit'}
                            tooltipOverride={'Mover lista para direita'}
                            iconOverride={'fas fa-arrow-right'}
                            style={{ fontSize: '12px' }}
                            onClick={() => onMoveList(e.code, +1)}
                          />
                        ) : (
                          <span>&nbsp;&nbsp;&nbsp;</span>
                        )}
                      </SpaceBetweenDiv>
                    )}

                    <SpaceBetweenDiv>
                      <div>
                        <b style={{ wordBreak: 'break-all' }}>{e.title}</b>

                        {!lock && (
                          <IconButton
                            id={e.code + '-edit-list'}
                            type={'edit'}
                            tooltipOverride={'Editar lista'}
                            iconOverride={'fas fa-pen'}
                            style={{ fontSize: '12px' }}
                            onClick={() => onEditList(e)}
                          />
                        )}
                      </div>

                      {e.status && (
                        <IconButton
                          id={i}
                          type={'create'}
                          tooltipOverride={'Adicionar item'}
                          style={{ fontSize: '12px' }}
                          onClick={() => callbackOnAdd({ item: e })}
                        />
                      )}
                    </SpaceBetweenDiv>
                  </StageTitle>

                  <StageContent>
                    <Droppable
                      style={{ height: '100vh' }}
                      droppableId={`${e.code}`}
                    >
                      {provider => (
                        <div
                          style={{ height: '100vh' }}
                          // className={`${e.code}`}
                          {...provider.droppableProps}
                          ref={provider.innerRef}
                        >
                          {e.childs.map((x, y) => (
                            <Draggable
                              key={y}
                              draggableId={`${e.code}-${y}`}
                              index={y}
                            >
                              {providedD => (
                                <Item
                                  ref={providedD.innerRef}
                                  className={`${e.code}-${y}`}
                                  {...providedD.dragHandleProps}
                                  {...providedD.draggableProps}
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginBottom: '8px',
                                    }}
                                  >
                                    <b>
                                      {x.title} <br />
                                      <span style={{ color: '#ffae0a' }}>
                                        {x?.responsible}
                                      </span>
                                    </b>
                                  </div>

                                  {x.desc && (
                                    <span style={{ marginBottom: '8px' }}>
                                      {x.desc.substring(0, descCaractersLimit)}
                                      ...
                                    </span>
                                  )}

                                  <DateHighlight
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      padding: '4px',
                                      borderRadius: '4px',
                                      margin: 0,
                                      width: '100%',
                                      backgroundColor: highlightColor(x),
                                    }}
                                  >
                                    <span>
                                      {x.dateNextAction
                                        ? 'Data da próxima ação'
                                        : altTitle}
                                    </span>

                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        flexWrap: 'wrap',
                                        marginTop: '4px',
                                        marginBottom: '4px',
                                      }}
                                    >
                                      <b>
                                        {x.dateNextAction
                                          ? getDateFormated(
                                              x.dateNextAction,
                                              altDateFormat,
                                            )
                                          : getDateFormated(
                                              x.date,
                                              altDateFormat,
                                            )}
                                      </b>

                                      {!x.dateNextAction &&
                                        showAltDateLabel &&
                                        isPastDate(x.date) && (
                                          <span style={{ textAlign: 'right' }}>
                                            {getDateDiff(x.date).minutes > 1
                                              ? `há ${getDateDiffLabel(x.date)}`
                                              : 'agora'}
                                          </span>
                                        )}
                                    </div>

                                    <b>{x.descAlt}</b>
                                  </DateHighlight>

                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      flexDirection: 'row',
                                      alignItems: 'flex-end',
                                      justifyContent: 'flex-end',
                                      gap: '8px',
                                    }}
                                  >
                                    <IconButton
                                      id={`new-kanban-card-button-filed-${x.id}`}
                                      type={'share'}
                                      tooltipOverride={'Arquivar'}
                                      colorOverride={'#dc3545'}
                                      onClick={() => callbackOnDelete(x)}
                                    />

                                    {e.showProposalButton && (
                                      <IconButton
                                        id={`new-kanban-card-button-proposal-${x.id}`}
                                        type={'create'}
                                        tooltipOverride={'Criar proposta'}
                                        iconOverride={'fas fa-city'}
                                        style={{ fontSize: '12px' }}
                                        onClick={() => callbackOnProposal(x)}
                                      />
                                    )}

                                    {e.showTravelerButton &&
                                      x.showTravelerButton && (
                                        <IconButton
                                          id={`new-kanban-card-button-traveler-${x.id}`}
                                          type={'create'}
                                          tooltipOverride={'Criar viajante'}
                                          iconOverride={'far fa-id-card'}
                                          style={{ fontSize: '12px' }}
                                          onClick={() => callbackOnTraveler(x)}
                                        />
                                      )}

                                    {e.showTravelerButton &&
                                      !x.showTravelerButton && (
                                        <IconButton
                                          id={`new-kanban-card-button-travel-${x.id}`}
                                          type={'create'}
                                          tooltipOverride={'Criar viagem'}
                                          iconOverride={'fas fa-map-marked-alt'}
                                          style={{ fontSize: '12px' }}
                                          onClick={() => callbackOnTravel(x)}
                                        />
                                      )}

                                    <IconButton
                                      id={`new-kanban-card-button-details-${x.id}`}
                                      type={'details'}
                                      tooltipOverride={'Detalhes'}
                                      style={{ fontSize: '12px' }}
                                      onClick={() => callbackOnDetails(x)}
                                    />
                                  </div>
                                </Item>
                              )}
                            </Draggable>
                          ))}

                          {provider.placeholder}

                          {e.childs.length === 0 && (
                            <p style={{ margin: 0 }}>
                              <span>&nbsp;</span>
                            </p>
                          )}
                        </div>
                      )}
                    </Droppable>
                  </StageContent>
                </Stage>
              )
            })}
          </DragDropContext>
        </Content>
      </Container>

      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner color={'#ccc'} size={'md'} />
        </div>
      )}
    </div>
  )
}

const Container = styled.div`
  width: 100%;
`

const Title = styled.h1`
  margin-bottom: 0;
`

const Content = styled.ul`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 8px;
  overflow: auto;
  margin: 0 0 8px;
  padding: 8px;
  list-style: none;
`

const Stage = styled.li`
  max-width: 300px;
  min-width: 280px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 8px;
  border-radius: 8px;
  background-color: #eaeaea;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  overflow: auto;
  max-height: 100vh;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d3d3d3;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #bdbebd;
  }
`

const StageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  margin: 0 0 8px;
`

const StageContent = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
`

const Item = styled.li`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #eaeaea;

  &:hover {
    cursor: grab;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

// const StageAddButton = styled.button`
//   padding: 8px;
//   border-radius: 8px;
//   background-color: #fff;
//   border: none;
// `

// const ModalCloseButton = styled.button`
//   padding: 8px;
//   border-radius: 8px;
//   border: none;
// `

// const ProposalModalButton = styled.button`
//   padding: 8px;
//   border-radius: 8px;
//   border: none;
//   color: #1bc25b;
//   background-color: transparent;
// `

// const TravelerModalButton = styled.button`
//   padding: 8px;
//   border-radius: 8px;
//   border: none;
//   color: #1bc25b;
//   background-color: transparent;
// `

export const DeleteButton = styled.button`
  font-weight: bold;
  padding: 8px 10px;
  color: #ff7d70;
  background-color: transparent;
  border: none;
  font-size: 12px;

  &:hover {
    background-color: #00000011;
    text-decoration: none;
    border-radius: 4px;
  }
`

const DateHighlight = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.foregroundAlt};
`
