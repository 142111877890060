import { Form } from '@unform/web'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import {
  EditButton,
  // RegisterNewButton,
  SaveButton,
} from '~/components/Button/styles'
import { RegisterNewButton, DeleteButton } from './styles'
import Select from '~/components/Select'
import { GetAxios } from '~/services/api'
import { DivFlexCentered, DivFlexRight, SpaceBetweenDiv } from '~/styles/divs'
import { TravelDetailBlock } from './styles'

function PartnerBlock({ travel }) {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [partners, setPartners] = useState([])
  const [selectedPartner, setSelectedPartner] = useState()
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    let cancel = false

    getAgent(cancel)

    return () => {
      cancel = true
    }
  }, [])

  async function getAgent(cancel) {
    if (cancel) return

    try {
      setLoading(true)

      const { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/partner`,
      )

      setPartners(data)

      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)

      navigate('/travels')
    }
  }

  async function handleSubmitEdit() {
    try {
      if (!selectedPartner) {
        return toast.info('Selecione um parceiro(a)!')
      }

      setLoading(true)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/partner/${selectedPartner.value}/${travel.id}`,
      )

      toast.success('Parceiro adicionado com sucesso!')

      setSelectedPartner(undefined)

      setIsEditing(false)

      setLoading(false)

      navigate('/travels')

      navigate('/travels/details', { state: { id: travel.id } })
    } catch (error) {
      setLoading(false)

      return toast.error('Não foi possível editar o parceiro.')
    }
  }

  async function onDelete(id) {
    try {
      setLoading(true)

      if (window.confirm('Deseja realmente remover o parceiro?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/partner/${id}/${travel.id}`,
        )

        toast.success('Parceiro removido com sucesso!')

        setLoading(false)

        navigate('/travels')

        navigate('/travels/details', { state: { id: travel.id } })
      }

      return setLoading(false)
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível editar o consultor, por favor tente mais tarde.',
      )
    }
  }

  return (
    <div>
      <TravelDetailBlock style={{ fontSize: '14px' }}>
        <SpaceBetweenDiv>
          <h5>Parceiro</h5>

          {/* {travel.partners.length > 0 && !isEditing && isAdmin && (
            <EditButton
              style={{ fontSize: '12px' }}
              onClick={() => setIsEditing(true)}
              type='button'
            >
              Editar
            </EditButton>
          )} */}
        </SpaceBetweenDiv>

        {!loading && !isEditing && (
          <>
            {travel.partners.length > 0 && (
              <Row style={{ width: '100%' }}>
                <Col sm={12} lg={6}>
                  <span>
                    <b>Nome: </b> <br />
                    {travel.partners[0]?.name}
                  </span>
                </Col>

                <Col sm={12}>
                  <span>
                    <b>Descrição: </b> <br />
                    {travel.partners[0]?.desc}
                  </span>
                </Col>

                <Col sm={12}>
                  <DeleteButton onClick={() => onDelete(travel.partners[0].id)}>
                    Remover parceiro
                  </DeleteButton>
                </Col>
              </Row>
            )}

            {travel.partners.length === 0 && partners.length > 0 && (
              <DivFlexCentered>
                <RegisterNewButton onClick={() => setIsEditing(true)}>
                  Adicionar parceiro
                </RegisterNewButton>
              </DivFlexCentered>
            )}

            {travel.partners.length === 0 && partners.length === 0 && (
              <DivFlexCentered>
                <RegisterNewButton onClick={() => navigate('/partner')}>
                  Cadastro de parceiros
                </RegisterNewButton>
              </DivFlexCentered>
            )}
          </>
        )}

        {isEditing && (
          <Form
            ref={formRef}
            onSubmit={handleSubmitEdit}
            style={{ width: '100%' }}
          >
            <Row>
              <Col sm={12}>
                <Select
                  name={'agent'}
                  options={partners}
                  onChange={e => setSelectedPartner(e.target.value)}
                  placeholder={'Selecione...'}
                  disabled={loading}
                  value={selectedPartner}
                  isSearchable
                />
              </Col>

              <Col sm={12}>
                <DivFlexRight>
                  <EditButton
                    style={{ fontSize: '12px' }}
                    onClick={() => setIsEditing(false)}
                    type='button'
                  >
                    Cancelar
                  </EditButton>

                  <SaveButton disabled={loading} type='submit'>
                    Salvar
                  </SaveButton>
                </DivFlexRight>
              </Col>
            </Row>
          </Form>
        )}
      </TravelDetailBlock>
    </div>
  )
}

export default PartnerBlock
