import React from 'react'
import { Container } from '~/styles/container'
import PageTitle from '~/components/PageTitle'
import { Outlet, useLocation } from 'react-router-dom'

export default function DocumentsManagement() {
  const location = useLocation()
  return (
    <Container>
      <PageTitle title={'Documentos de ' + location.state.traveler.fullName} />

      <Outlet context={{ traveler: location.state.traveler }} />
    </Container>
  )
}
