import { useEffect, useState } from 'react'
import { Panel, PanelBody } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import { RegisterNewButton } from '~/components/Button/styles'
import { DivFlexRight } from '~/styles/divs'
import { useNavigate, useOutletContext } from 'react-router-dom'

export default function TravelersList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [travelersInTravel, setTravelersInTravel] = useState([])
  const [travelersOutTravel, setTravelersOutTravel] = useState([])
  const navigate = useNavigate()

  const { travel } = useOutletContext()

  useEffect(async () => {
    setLoading(true)

    const [responseTravelersInTravel, responseTravelersOutTravel] =
      await Promise.allSettled([
        requestApi('traveler/Travelers-in-Travel'),
        requestApi('traveler/Travelers-out-Travel'),
      ])

    setTravelersInTravel(responseTravelersInTravel?.value?.data)
    setTravelersOutTravel(responseTravelersOutTravel?.value?.data)

    setLoading(false)
  }, [])

  const requestApi = async path => {
    try {
      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/${path}/${travel.id}`,
      )

      return response
    } catch (err) {
      console.log(err)
    }
  }

  const requestTravelersOutTravel = async () => {
    try {
      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/Travelers-out-Travel/${travel.id}`,
      )

      setTravelersOutTravel(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const requestTravelersInTravel = async () => {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/Travelers-in-Travel/${travel.id}`,
      )

      setTravelersInTravel(response.data)
      await requestTravelersOutTravel()

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      <Panel>
        <PanelBody>
          <DivFlexRight>
            <RegisterNewButton
              type={'button'}
              onClick={() =>
                navigate('add', {
                  state: {
                    travelersOutTravel: travelersOutTravel,
                  },
                })
              }
            >
              Adicionar Viajante
            </RegisterNewButton>
          </DivFlexRight>

          <Table
            travelerList={travelersInTravel}
            setLoading={setLoading}
            loading={loading}
            travelId={travel.id}
            requestTravelersInTravel={requestTravelersInTravel}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
