import styled from 'styled-components'
import DivForHTML from '~/components/DivForHTML'
import { getDateDDMMM, getDateDDMMMFullName } from '~/functions/Date'

export default function FrontCover({
  travel,
  travelImage,
  partnerImage,
  agencyImage,
  nameAgency,
}) {
  return (
    <Container
      className={
        nameAgency.name == 'Wee Travel'
          ? 'a4-height no-break'
          : 'a4-height2 no-break'
      }
    >
      {nameAgency.name == 'Wee Travel' ? (
        <LogoWee>
          <img
            src={`data:image/*;base64,${agencyImage}`}
            alt='Logo da agência'
          />

          {partnerImage && (
            <img
              src={`data:image/*;base64,${partnerImage}`}
              alt='Logo do parceiro'
            />
          )}
        </LogoWee>
      ) : (
        <Logo>
          <img
            src={`data:image/*;base64,${agencyImage}`}
            alt='Logo da agência'
          />

          {partnerImage && (
            <img
              src={`data:image/*;base64,${partnerImage}`}
              alt='Logo do parceiro'
            />
          )}
        </Logo>
      )}

      <Banner>
        {travelImage && (
          <img
            src={`data:image/*;base64,${travelImage}`}
            alt='Imagem de capa da viagem'
          />
        )}
      </Banner>

      <TravelInfo style={{ maxWidth: '100%' }}>
        {nameAgency.name == 'Wee Travel' ? (
          <>
            <DateWee style={{ maxWidth: '100%' }}>
              {getDateDDMMM(travel.start)} a {getDateDDMMM(travel.end)}
            </DateWee>
            <TitleTravelWee style={{ maxWidth: '100%' }}>
              {travel.title}
            </TitleTravelWee>
          </>
        ) : (
          <div style={{ maxWidth: '100%' }}>
            <h3
              style={{ color: `${nameAgency.primaryColor}`, maxWidth: '100%' }}
            >
              {travel.title}
            </h3>

            <Date style={{ color: `${nameAgency.secondaryColor}` }}>
              {getDateDDMMMFullName(travel.start).toUpperCase()} A{' '}
              {getDateDDMMMFullName(travel.end).toUpperCase()} <br />
            </Date>
          </div>
        )}
      </TravelInfo>

      <DivForHTML text={travel.desc} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

const Date = styled.h5`
  /* color: ${props => props.theme.colors.secondary}; */
`

const DateWee = styled.h5`
  color: #d54a26;
`

const LogoWee = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    max-width: 180px;
    max-height: 110px;
  }
`

const Logo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;

  img {
    max-width: 180px;
    max-height: 110px;
  }
`

const Banner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
`

const TravelInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`
const TitleTravel = styled.h1`
  /* color: ${props => props.theme.colors.primary}; */
`

const TitleTravelWee = styled.h3`
  color: #d54a26;
`
