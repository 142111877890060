function searchInObject(obj, searchText) {
  searchText = searchText.toLowerCase()

  function recursiveSearch(currentObject) {
    for (let key in currentObject) {
      if (currentObject[key]) {
        let value = currentObject[key]

        if (typeof value === 'object' && value !== null) {
          if (recursiveSearch(value)) {
            return true
          }
        } else if (typeof value === 'string' || typeof value === 'number') {
          if (value.toString().toLowerCase().includes(searchText)) {
            return true
          }
        }
      }
    }

    return false
  }

  return recursiveSearch(obj)
}

export { searchInObject }
