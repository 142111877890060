import styled from 'styled-components'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'
import { getDateFormated } from '~/functions/Date'

export default function CreateVehicleRental({ toCreate, onClose }) {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [defaultValue] = useState({
    dateCheckIn: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
    dateCheckOut: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
  })

  async function onSubmit(data) {
    try {
      setLoading(true)

      const body = {
        ...data,
        checkin: `${data.dateCheckIn} ${data.timeCheckIn || '00:00'}`,
        checkout: `${data.dateCheckOut} ${data.timeCheckOut || '00:00'}`,
        travelId: travel.id,
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelVehicleRental`,
        body,
      )

      toast.success('Locação veicular criada com sucesso!')
      setLoading(false)
      onClose(true)
    } catch (error) {
      console.log(error)
      setLoading(false)
      toast.info('Não foi possível criar a locação veicular.')
    }
  }

  return (
    <Container>
      <DefaultForm
        defaultValue={defaultValue}
        callbackSubmit={onSubmit}
        callbackReset={() => {}}
        loading={loading}
        disabled={loading}
        inputs={[
          {
            type: 'text',
            name: 'originPurchase',
            label: 'Origem da Compra',
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'text',
            name: 'locator',
            label: 'Código Reserva/Localizador',
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'text',
            name: 'provider',
            label: 'Locadora/Fornecedor',
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'text',
            name: 'originAddress',
            label: 'Endereço de locação',
            required: true,
            sm: 8,
            md: 8,
            lg: 8,
            xl: 8,
          },
          {
            type: 'date',
            name: 'dateCheckIn',
            label: 'Data de locação',
            required: true,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
          },
          {
            type: 'time',
            name: 'timeCheckIn',
            label: 'Horário de locação',
            required: true,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
          },
          {
            type: 'text',
            name: 'destinyAddress',
            label: 'Endereço de devolução',
            required: true,
            sm: 8,
            md: 8,
            lg: 8,
            xl: 8,
          },
          {
            type: 'date',
            name: 'dateCheckOut',
            label: 'Data de locação',
            required: true,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
          },
          {
            type: 'time',
            name: 'timeCheckOut',
            label: 'Horário de locação',
            required: true,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Observação',
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div``
