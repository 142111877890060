export const getParticipantInputs = () => {
  return [
    {
      type: 'text',
      name: 'index',
      label: 'Posição de incrição',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'grade',
      label: 'Série',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'class',
      label: 'Turma',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome completo',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'email',
      label: 'E-mail',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefone',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'cpf',
      label: 'Cpf',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'rg',
      label: 'Rg',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'birth',
      label: 'Data de nascimento',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'fatherName',
      label: 'Nome do pai',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'fatherPhone',
      label: 'Telefone do pai',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'fatherEmail',
      label: 'E-mail do pai',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'motherName',
      label: 'Nome da mãe',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'motherPhone',
      label: 'Telefone da mãe',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'motherEmail',
      label: 'E-mail da mãe',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'emergencyName',
      label: 'Nome do contato de emergência',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'emergencyPhone',
      label: 'Telefone do contato de emergência',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'emergencyEmail',
      label: 'E-mail do contato de emergência',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'tshirtNumber',
      label: 'Número da camisa',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'responsibleKinship',
      label: 'Parentesco com responsável financeiro',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'medicalAllergy',
      label: 'Alergias',
      disabled: true,
      sm: 12,
    },
    {
      type: 'text',
      name: 'medicalMedication',
      label: 'Medicamentos de uso contínuo',
      disabled: true,
      sm: 12,
    },
    {
      type: 'text',
      name: 'medicalDiseasePhobia',
      label: 'Doença crônica/fobia ou necessidade especial',
      disabled: true,
      sm: 12,
    },
    {
      type: 'text',
      name: 'medicalFoodRestriction',
      label: 'Restrição alimentar ou algo que não aprecie',
      disabled: true,
      sm: 12,
    },
  ]
}

export const getResponsibleInputs = () => {
  return [
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome completo',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'email',
      label: 'E-mail',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefone',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'profession',
      label: 'Profissão',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'businessPhone',
      label: 'Telefone comercial',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'rg',
      label: 'Rg',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'cpf',
      label: 'Cpf',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'birth',
      label: 'Data de nascimento',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'paymentMethod',
      label: 'Forma de pagamento',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'installments',
      label: 'Quantidade de parcelas',
      disabled: true,
      sm: 12,
      lg: 4,
    },
  ]
}

export const getEventInputs = () => {
  return [
    {
      type: 'text',
      name: 'title',
      label: 'Título',
      disabled: true,
      sm: 12,
      lg: 6,
    },
    {
      type: 'text',
      name: 'destiny',
      label: 'Destino',
      disabled: true,
      sm: 12,
      lg: 6,
    },
    {
      type: 'text',
      name: 'end',
      label: 'Fim',
      disabled: true,
      sm: 12,
      lg: 6,
    },
    {
      type: 'text',
      name: 'start',
      label: 'Início',
      disabled: true,
      sm: 12,
      lg: 6,
    },
  ]
}

export const getSchoolInputs = () => {
  return [
    {
      type: 'text',
      name: 'name',
      label: 'Nome da escola',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefone',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'email',
      label: 'E-mail',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'site',
      label: 'Site',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'hood',
      label: 'Bairro',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'city',
      label: 'Cidade',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'state',
      label: 'Estado',
      disabled: true,
      sm: 12,
      lg: 4,
    },
  ]
}

export const getInstitutionInputs = () => {
  return [
    {
      type: 'text',
      name: 'type',
      label: 'Tipo de Instituição/Empresa',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefone',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'email',
      label: 'E-mail',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'site',
      label: 'Site',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'hood',
      label: 'Bairro',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'city',
      label: 'Cidade',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'state',
      label: 'Estado',
      disabled: true,
      sm: 12,
      lg: 4,
    },
  ]
}
