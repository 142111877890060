import { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import PageTitle from '~/components/PageTitle'
import SlideInput from '~/components/SlideInput'
import { TravelDetailBlock } from '~/pages/Travels/Details/styles'
import { GetAxios } from '~/services/api'
import { SpaceBetweenDiv } from '~/styles/divs'

export default function AutoNotifications({ travel }) {
  const { axiosApis } = GetAxios()
  const [status, setStatus] = useState(false)
  const [items, setItems] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      await loadStatus()
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function loadStatus() {
    try {
      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications/autonotifications/${travel.id}`,
      )

      setStatus(res.data.status)

      setItems(res.data.items)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleStatus(e) {
    try {
      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications/autonotifications/${travel.id}`,
        {
          label: e.target.name,
          value: e.target.checked,
        },
      )

      loadStatus()
    } catch (error) {
      console.log(error)
    }
  }

  async function handleStatusItem(e) {
    try {
      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications/autonotifications/${travel.id}/item`,
        {
          label: e.target.name,
          value: e.target.checked,
        },
      )

      loadStatus()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <SpaceBetweenDiv
        style={{
          flexWrap: 'wrap',
        }}
      >
        <div style={{ margin: '4px 0' }}>
          <PageTitle title={'Notificações Automáticas'} head={'h5'} noButton />
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '4px 0' }}>
          <SlideInput name={'status'} value={status} onChange={handleStatus} />
        </div>
      </SpaceBetweenDiv>

      <TravelDetailBlock style={{ opacity: status ? 1 : 0.5 }}>
        <Row>
          {items.map((e, i) => {
            return (
              <Col key={i} sm={12} lg={4} xl={3}>
                <SlideInput
                  name={e.key}
                  label={e.label}
                  value={e.value}
                  disabled={!status}
                  onChange={handleStatusItem}
                />
              </Col>
            )
          })}
        </Row>
      </TravelDetailBlock>
    </>
  )
}
