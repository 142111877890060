import { useLayoutEffect } from 'react'
import { Container, Content, DeleteButton, Modal, TopBar } from './style'

export default function ModalDefault({
  title,
  content,
  handleShow,
  scroll = 'auto',
  height = '',
  width = '90%',
  showCloseButton = true,
}) {
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'scroll'
    }
  }, [])

  return (
    <Container style={{ zIndex: '9999' }}>
      <Modal width={width} height={height} scroll={scroll}>
        <TopBar>
          <h5>{title}</h5>

          {showCloseButton ? (
            <DeleteButton onClick={() => handleShow()}>
              <i className={'fas fa-times'} />
            </DeleteButton>
          ) : (
            <></>
          )}
        </TopBar>
        <Content>{content}</Content>
      </Modal>
    </Container>
  )
}
