import styled from 'styled-components'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'
import { getDateFormated } from '~/functions/Date'

export default function CreateTicket({ toCreate, onClose }) {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [defaultValue] = useState({
    start: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
    end: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
  })

  const onValidate = async data => {
    if (data.timeStart && !data.timeEnd) {
      toast.info('Preencha o horário referente ao fim')
      return
    }

    if (!data.timeStart && data.timeEnd) {
      toast.info('Preencha o horário referente ao inicio')
      return
    }

    await onSubmit(data)
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const body = {
        ...data,
        city: { label: data.city?.label, value: data.city?.value },
        start: `${data.start} ${data.timeStart || '00:00'}`,
        end: `${data.end} ${data.timeEnd || '00:00'}`,
        travelId: travel.id,
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelTicket`,
        body,
      )

      toast.success('Ticket criado com sucesso!')
      setLoading(false)
      onClose(true)
    } catch (error) {
      console.log(error)
      setLoading(false)
      toast.info('Não foi possível criar o ticket.')
    }
  }

  return (
    <Container>
      <DefaultForm
        defaultValue={defaultValue}
        callbackSubmit={onSubmit}
        onValidate={onValidate}
        loading={loading}
        disabled={loading}
        inputs={[
          {
            type: 'text',
            name: 'title',
            label: 'Título',
            required: true,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'text',
            name: 'startAddress',
            label: 'Endereço',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
            maxLength: 200,
          },
          {
            type: 'city',
            name: 'city',
            label: 'Cidade',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'text',
            name: 'originPurchase',
            label: 'Fornecedor',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'date',
            name: 'start',
            label: 'Data do Início',
            row: '2',
            required: true,
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeStart',
            label: 'Hora do Início',
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'date',
            name: 'end',
            label: 'Data do Fim',
            required: true,
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeEnd',
            label: 'Hora do Fim',
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'html',
            name: 'desc',
            label: 'Descrição',
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          // {
          //   type: 'image',
          //   name: 'image',
          //   label: 'Imagem',
          //   sm: 12,
          //   md: 12,
          //   lg: 6,
          //   xl: 3,
          // },
          // {
          //   type: 'value',
          //   name: 'tips',
          //   label: 'Amenidades',
          //   placeholder: 'Descrição',
          //   sm: 12,
          //   md: 12,
          //   lg: 6,
          //   xl: 3,
          // },
        ]}
      />
    </Container>
  )
}

const Container = styled.div``
