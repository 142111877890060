import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import ReactTable from '~/components/Table'
import { CancelDeleteAction, EditAction } from '~/components/ActionsOfTables'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { DivFlexRight } from '~/styles/divs'
import { RegisterNewButton } from '~/components/Button/styles'
import IconButton from '~/components/IconButton'

export default function TravelInsuranceList() {
  const { axiosApis } = GetAxios()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const { travel } = useOutletContext()

  useEffect(() => {
    requestApiGet()
  }, [])

  async function requestApiGet() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelInsurance/all/${travel.id}`,
      )

      setData(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function handleRemove(data) {
    try {
      setLoading(true)

      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelInsurance/delete/${data.id}`,
      )

      await requestApiGet()

      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  async function handleDetails(data) {
    navigate('details', { state: { id: data.id } })
  }

  return (
    <>
      <DivFlexRight>
        <RegisterNewButton type={'button'} onClick={() => navigate('add')}>
          Adicionar seguro
        </RegisterNewButton>
      </DivFlexRight>

      <ReactTable
        columns={[
          {
            Header: 'Início',
            accessor: 'timeStart',
            width: 100,
          },
          {
            Header: 'Fim',
            accessor: 'timeEnd',
            width: 100,
          },
          {
            Header: 'Tipo de cobertura',
            accessor: 'typeCoverage',
            width: 200,
          },
          {
            Header: 'Número da apólice',
            accessor: 'numberPolicy',
            width: 200,
          },
          {
            Header: 'Nome do(a) assegurado(a)',
            accessor: 'nameOfTheInsured',
            width: 200,
          },
          {
            Header: '',
            accessor: 'details',
            Cell: props => {
              return (
                <IconButton
                  id={props.row.index}
                  type={'details'}
                  onClick={() => handleDetails(props.row.original)}
                />
              )
            },
            width: 20,
          },
          {
            Header: '',
            accessor: 'remove',
            Cell: props => {
              return (
                <IconButton
                  id={props.row.index}
                  type={'remove'}
                  onClick={() => handleRemove(props.row.original)}
                />
              )
            },
            width: 20,
          },
        ]}
        data={data}
        tableProps={{
          defaultPageSize: 10,
          hasFooter: false,
          hasPagination: true,
          loading: loading,
          resizable: true,
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px',
        }}
        tableLabels={{
          loadingText: 'Carregando...',
          noDataText: 'Sem registros',
          nextButtonText: 'Próximo',
          previousButtonText: 'Anterior',
          pageOfText: 'de',
          pageSizeSelectText: 'registros',
          pageText: 'Página',
        }}
      />
    </>
  )
}
