import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import styled from 'styled-components'

export const PanelStat = React.createContext()

function Panel({ theme, className, children }) {
  const toggleExpand = () => {
    setState({
      expand: !state.expand,
    })
  }

  const toggleRemove = () => {
    setState({
      remove: !state.remove,
    })
  }

  const toggleCollapse = () => {
    setState({
      collapse: !state.collapse,
    })
  }

  const toggleReload = () => {
    if (state.reload !== true) {
      setState({
        reload: true,
      })

      setTimeout(() => {
        setState({
          reload: false,
        })
      }, 2000)
    }
  }

  const [state, setState] = useState({
    expand: false,
    collapse: false,
    reload: false,
    remove: false,
    toggleExpand,
    toggleReload,
    toggleRemove,
    toggleCollapse,
  })

  return (
    <PanelStat.Provider value={state}>
      {!state.remove && (
        <div
          className={`panel ${theme && `panel-${theme}`} ${
            state.expand && 'panel-expand '
          }${state.reload && 'panel-loading '}${className || ''}`}
        >
          {children}
        </div>
      )}
    </PanelStat.Provider>
  )
}

Panel.defaultProps = {
  theme: '',
  className: '',
}

Panel.propTypes = {
  theme: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

function PanelHeader({ title, noButton, popover, children, background }) {
  return (
    <PanelHeaderDiv
      className='panel-heading'
      style={{ backgroundColor: background }}
    >
      <h2 className='panel-title mb-0'>
        {title && <b>{title}</b>}
        {popover && (
          <span className='ml-2'>
            <i className='fa fa-info-circle' id={`popover-${popover.id}`} />
            <UncontrolledPopover
              trigger='hover'
              placement='top'
              target={`popover-${popover.id}`}
            >
              <PopoverHeader>{popover.title}</PopoverHeader>
              <PopoverBody>{popover.description}</PopoverBody>
            </UncontrolledPopover>
          </span>
        )}
      </h2>

      {children && children}

      {!noButton && (
        <PanelStat.Consumer>
          {({ toggleExpand, toggleRemove, toggleCollapse }) => (
            <div className='panel-heading-btn'>
              <button
                type='button'
                className='btn btn-xs btn-icon btn-circle btn-success'
                onClick={toggleExpand}
              >
                <i className='fa fa-expand' />
              </button>
              &nbsp;&nbsp;
              <button
                type='button'
                className='btn btn-xs btn-icon btn-circle btn-warning'
                onClick={toggleCollapse}
              >
                <i className='fa fa-minus' />
              </button>
              &nbsp;&nbsp;
              <button
                type='button'
                className='btn btn-xs btn-icon btn-circle btn-danger'
                onClick={toggleRemove}
              >
                <i className='fa fa-times' />
              </button>
            </div>
          )}
        </PanelStat.Consumer>
      )}
    </PanelHeaderDiv>
  )
}

const PanelHeaderDiv = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.foregroundAlt};
`

PanelHeader.defaultProps = {
  noButton: false,
  popover: null,
  title: null,
}

PanelHeader.propTypes = {
  noButton: PropTypes.bool,
  title: PropTypes.string,
  popover: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
}

function PanelBody({ className, children, style }) {
  return (
    <PanelStat.Consumer>
      {({ collapse, reload }) => (
        <div
          className={`panel-body ${collapse && 'd-none '}${className}`}
          style={style}
        >
          {children}

          {reload && (
            <div className='panel-loader'>
              <span className='spinner-small' />
            </div>
          )}
        </div>
      )}
    </PanelStat.Consumer>
  )
}

PanelBody.defaultProps = {
  className: '',
}

PanelBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
}

function PanelFooter({ className, children }) {
  return <div className={`panel-footer ${className}`}>{children}</div>
}

PanelFooter.defaultProps = {
  className: '',
}

PanelFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
}

export { Panel, PanelHeader, PanelBody, PanelFooter }
