import { Panel, PanelBody } from '~/components/Panel'
import { Outlet, useOutletContext } from 'react-router-dom'
import { Container } from '~/styles/container'

export default function Insurance() {
  const { travel } = useOutletContext()

  return (
    <Container>
      <Panel>
        <PanelBody>
          <Outlet context={{ travel }} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
