import styled from 'styled-components'
import { Col } from 'reactstrap'

export const Container = styled.div`
  * {
    box-sizing: border-box;
  }

  [class^='col'],
  .row {
    padding: 0;
    margin: 0;
  }

  [class^='col'] {
    padding: 2px 8px;

    .row {
      [class^='col'] {
        padding: 0;
      }
    }
  }

  p {
    margin-bottom: 4px;
  }
`

export const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const LabelHighlight = styled.b`
  color: ${props => props.theme.colors.primary};
`
