import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import { useEffect, useState } from 'react'

export default function TravelersDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [travelers, setTravelers] = useState([])
  const [ddis, setDdis] = useState([])
  const [selectDdi, setSelectDdi] = useState({})

  useEffect(() => {
    requestApiToGetTraveler()
    getOptionsDDI()
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )

      setDdis(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  const requestApiToGetTraveler = async () => {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/${location.state.id}`,
      )

      setTravelers(response.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function onUpdate(data) {
    try {
      setLoading(true)
      const formData = new FormData()

      for (let key in data) {
        if (data[key] != null && data[key] != 'null') {
          formData.append(key, data[key])
        }
      }

      formData.append('id', location.state.userId)
      formData.set('birthDay', data.birthDay ?? '0001-01-01')
      if (data.city) formData.set('city', data.city?.label ?? undefined)
      if (data.city) formData.set('cityId', data.city?.value ?? undefined)
      formData.set('ddi', data.ddi?.value ?? 55)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
        formData,
      )

      toast.success('Viajante editado com sucesso!')

      setLoading(false)
      navigate(-1)
    } catch (error) {
      setLoading(false)
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  const onValidate = data => {
    if (!data.email && !data.phone) {
      toast.info('Informe email ou telefone.')

      return false
    }

    return true
  }

  return (
    <Container>
      <Panel>
        <PageTitle />
        <PanelBody>
          <Container>
            <DefaultForm
              onValidate={onValidate}
              callbackSubmit={onUpdate}
              defaultValue={travelers}
              loading={loading}
              inputs={[
                {
                  type: 'text',
                  name: 'familyName',
                  label: 'Nome de Preferência',
                  placeholder: 'Nome de Preferência',
                  required: true,
                  maxLength: 150,
                  sm: 12,
                  lg: 4,
                },
                {
                  type: 'text',
                  name: 'fullName',
                  label: 'Nome Completo',
                  placeholder: 'Nome Completo',
                  required: true,
                  maxLength: 150,
                  sm: 12,
                  lg: 4,
                },
                {
                  type: 'email',
                  name: 'email',
                  label: 'E-mail',
                  placeholder: 'E-mail',
                  maxLength: 150,
                  sm: 12,
                  lg: 4,
                },
                {
                  type: 'select',
                  name: 'ddi',
                  label: 'DDI',
                  options: ddis,
                  placeholder: 'Selecione...',
                  onChange: e => setSelectDdi(e.target.value),
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                selectDdi.value == 55
                  ? {
                      type: 'phone',
                      name: 'phone',
                      label: 'Telefone',
                      sm: 12,
                      lg: 2,
                    }
                  : {
                      type: 'text',
                      name: 'phone',
                      label: 'Telefone',
                      mask: '999 99999999',
                      sm: 12,
                      lg: 2,
                    },
                {
                  type: 'text',
                  name: 'rg',
                  label: 'RG/CNH',
                  placeholder: 'RG/CNH',
                  required: false,
                  sm: 12,
                  lg: 4,
                },
                {
                  type: 'text',
                  name: 'cpf',
                  label: 'CPF/CNPJ',
                  maxLength: 150,
                },
                {
                  type: 'date',
                  name: 'birthDay',
                  label: 'Aniversário',
                },
                {
                  type: 'text',
                  name: 'profession',
                  label: 'Profissão',
                  placeholder: 'Profissão',
                  required: false,
                },
                {
                  type: 'text',
                  name: 'address',
                  label: 'Endereço',
                  placeholder: 'Endereço',
                  required: false,
                },
                {
                  type: 'city',
                  name: 'city',
                  label: 'Cidade',
                  placeholder: 'Pesquise por uma cidade...',
                  required: false,
                },
                {
                  type: 'text',
                  name: 'username',
                  label: 'Usuário',
                  placeholder: 'Usuário',
                  disabled: true,
                },
                {
                  type: 'text',
                  name: 'accessCode',
                  label: 'Código de acesso',
                  placeholder: 'Código de acesso',
                },
                {
                  type: 'textarea',
                  name: 'perfil',
                  label: 'Perfil do Viajante',
                  placeholder: 'Perfil do Viajante',
                  required: false,
                },
                {
                  type: 'check',
                  name: 'vip',
                  label: 'Viajante VIP',
                },
                // {
                //   type: 'check',
                //   name: 'disabled',
                //   label: 'Desativar Viajante',
                // },
              ]}
            />
          </Container>
        </PanelBody>
      </Panel>
    </Container>
  )
}
