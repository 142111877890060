import { getDateFormated } from 'functions/Date'
import { CardFormat, CardContent, LinkDetails } from './style'

export function CardDetails({ travel, backgroundColor, type }) {
  return (
    <CardFormat backgroundColor={backgroundColor}>
      {travel.typeInfo == 'Travel' ? (
        <LinkDetails
          to={'/travels/details'}
          state={{ id: travel.id }}
          style={{ width: '100%' }}
        >
          <CardContent style={{ flex: '1' }}>
            <p className='title'>{travel.title}</p>

            {type === 'month' && (
              <>
                <p style={{ textAlign: 'left' }}>
                  {`${getDateFormated(
                    travel.start,
                    'DD [de] MMM',
                  )} à ${getDateFormated(travel.end, 'DD [de] MMM')}`}
                </p>
              </>
            )}

            <p>{travel.destiny}</p>

            {type === 'month' && (
              <>
                <p style={{ textAlign: 'right' }}>
                  {travel.traveler}
                  &nbsp;
                  {travel.vip && (
                    <i
                      className='fas fa-star'
                      style={{ fontSize: '18px', color: '#efb810' }}
                    />
                  )}
                </p>
              </>
            )}
          </CardContent>

          {type === 'day' && travel.typeInfo == 'Travel' && (
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <p className='title' style={{ textAlign: 'right' }}>
                {`${getDateFormated(
                  travel.start,
                  'DD [de] MMM',
                )} à ${getDateFormated(travel.end, 'DD [de] MMM')}`}
              </p>

              <p>
                {travel.traveler}
                &nbsp;
                {travel.vip && (
                  <i
                    className='fas fa-star'
                    style={{ fontSize: '18px', color: '#efb810' }}
                  />
                )}
              </p>
            </div>
          )}

          {type === 'day' && travel.typeInfo == 'Lembrete' && (
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <p className='title' style={{ textAlign: 'right' }}>
                {`${getDateFormated(travel.start, 'DD [de] MMM')}`}
              </p>
            </div>
          )}
        </LinkDetails>
      ) : (
        <LinkDetails to={'/reminders'} style={{ width: '100%' }}>
          <CardContent style={{ flex: '1' }}>
            <p className='title'>{travel.title}</p>

            {type === 'month' && (
              <>
                <p style={{ textAlign: 'left' }}>
                  {`${getDateFormated(travel.start, 'DD [de] MMM')}`}
                </p>
              </>
            )}
          </CardContent>

          {type === 'day' && (
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <p className='title' style={{ textAlign: 'right' }}>
                {`${getDateFormated(travel.start, 'DD [de] MMM')}`}
              </p>
            </div>
          )}
        </LinkDetails>
      )}
    </CardFormat>
  )
}
