import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`

export const LinkRegisterNew = styled(Link)`
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  padding: 0;
  font-weight: bold;
  text-decoration: none;
  color: #1bc25b;

  &:hover {
    color: #1bc25b;
    background-color: #00000011;
  }
`

export const LinkRegisterNewTraveler = styled(Link)`
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  padding: 0 8px;
  font-weight: bold;
  text-decoration: none;
  color: #4bf28b;

  &:hover {
    color: #7bffbb;
  }
`

export const LinkRegisterNewPanel = styled(Link)`
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  padding: 0 8px;
  font-weight: bold;
  text-decoration: none;
  color: #4bf28b;

  &:hover {
    color: #7bffbb;
  }
`

export const LinkEdit = styled(Link)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0px 12px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
    color: #333;
  }
`
export const LinkEditExperience = styled(Link)`
  display: flex;
  border: none;
  border-radius: 4px;
  padding: 8px 10px;
  margin-left: 5px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
    color: #333;
  }
`

export const LinkTravelDetails = styled(Link)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px 12px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  color: #333;

  &:hover {
    background-color: #00000011;
  }
`

export const ButtonToLinkRoadMap = styled.button`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px 12px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
  }
`

export const WppButton = styled.button`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px 12px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  background-color: transparent;
  color: #1bc25b;

  &:hover {
    color: #1bc25b;
    background-color: #00000011;
  }
`

export const CustomLink = styled(Link)`
  margin: 0;
  margin-right: 8px;
  padding: 6px 12px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  color: #ffffff;
  background-color: #0072c6;
`

export const LinkCreate = styled(Link)`
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 6px;
  font-weight: bold;
  text-decoration: none;
  color: #1bc25b;

  &:hover {
    color: #1bc25b;
    background-color: #00000011;
  }
`
export const LinkDelete = styled(Link)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 0px 12px;
  font-weight: bold;
  font-size: 13px;
  background-color: transparent;
  color: #ff7d70;

  &:hover {
    color: #ff7d70;
    background-color: #00000011;
    text-decoration: none;
  }
`
export const LinkCategories = styled(Link)`
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  padding: 8px 10px;
  margin-bottom: 6px;
  font-weight: bold;
  text-decoration: none;
  color: #1bc25b;

  &:hover {
    color: #1bc25b;
    background-color: #00000011;
  }
`
