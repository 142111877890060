import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import { getDateFormated } from '~/functions/Date'
import { useSelector } from 'react-redux'

export default function TagsAgencyDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const defaultValue = {
    ...location.state,
    tags: location.state.tag.tags,
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('id', location.state.tag.id)
      formData.append('tag', data.tags)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/tags`,
        formData,
      )

      toast.success('Tag editada com sucesso!')

      return navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  // async function onDelete() {
  //   if (window.confirm('Você tem certeza que deseja excluir esse viajante?')) {
  //     await axiosApis.delete(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/${location.state.traveler.id}`,
  //     )

  //     toast.success(`Viajante excluído com sucesso!`)

  //     return navigate(-1)
  //   }
  // }

  const toOperator = [
    {
      type: 'text',
      name: 'tags',
      label: 'Tag',
      placeholder: 'Tag',
      required: true,
      maxLength: 150,
      disabled: false,
    },
  ]

  const toAgency = [
    {
      type: 'text',
      name: 'tags',
      label: 'Tag',
      placeholder: 'Tag',
      required: true,
      maxLength: 150,
      disabled: false,
    },
  ]

  return (
    <Container>
      <PageTitle />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Container>
            <DefaultForm
              defaultValue={defaultValue}
              callbackSubmit={onSubmit}
              inputs={user.isOperator == true ? toOperator : toAgency}
            />
          </Container>
        </PanelBody>
      </Panel>
    </Container>
  )
}
