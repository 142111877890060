import { Spinner } from 'reactstrap'

function LoadingContainer({
  height = '100%',
  color = '#ccc',
  size = 'sm',
  label,
  ...rest
}) {
  return (
    <div
      style={{
        width: '100%',
        minHeight: height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      {...rest}
    >
      <Spinner color={color} size={size} />

      {label && <span>{label}</span>}
    </div>
  )
}

export default LoadingContainer
