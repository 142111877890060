import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import DefaultForm from '~/components/DefaultForm'
import { Container } from '~/styles/container'
import { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import PageTitle from '~/components/PageTitle'
import ReactTable from '~/components/Table'
import axios from 'axios'
import Tabs from './Tabs'
// import { GetAxios } from '~/services/api'

export default function ProposalAvailability() {
  // const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [airs, setAirs] = useState([])
  // const { axiosApis } = GetAxios()
  // const [integration, setIntegration] = useState({})

  // useEffect(() => {
  //   requestApiGetIntegration()
  // }, [])

  // const requestApiGetIntegration = async () => {
  //   try {
  //     setLoading(true)

  //     const response = await axiosApis.get(`/proposal`)

  //     setIntegration(response?.data)
  //     setLoading(false)
  //   } catch (err) {
  //     setLoading(false)
  //   }
  // }

  const onSearch = async data => {
    try {
      setLoading(true)

      try {
        const response = await axios.request({
          method: 'GET',
          baseURL: 'https://api.infotravel.com.br/api/v1',
          url: '/avail/flight',
          params: {
            ...data,
          },
          headers: {
            contentType: 'application/json',
            authorization: `Bearer ${localStorage.getItem('VEfKJmx7Wx9SkwW7')}`,
          },
        })

        const result = response?.data?.flightAvail?.reduce((acc, obj, i) => {
          const flight = obj?.routes[i]?.flights[i]

          let aux = {
            cia: flight.airline.name,
            origin: `(${flight.origin.code}) ${flight.origin.airportName} - ${flight.departure}`,
            destination: `(${flight.destination.code}) ${flight.destination.airportName} - ${flight.arrival}`,
            flightClass: flight.segments[i].class,
            price: flight.fares[i].price.currency,
          }

          acc.push(aux)
          return acc
        }, [])

        setAirs(result)
      } catch (err) {
        const response = await axios.request({
          method: 'GET',
          baseURL: `https://online.orinter.com.br/api/v1`,
          url: '/avail/flight',
          params: {
            ...data,
          },
          headers: {
            contentType: 'application/json',
            authorization: `Bearer ${localStorage.getItem('VEfKJmx7Wx9SkwW7')}`,
          },
        })

        const result = response?.data?.flightAvail?.reduce((acc, obj, i) => {
          const flight = obj?.routes[i]?.flights[i]

          let aux = {
            cia: flight.airline.name,
            origin: `(${flight.origin.code}) ${flight.origin.airportName} - ${flight.departure}`,
            destination: `(${flight.destination.code}) ${flight.destination.airportName} - ${flight.arrival}`,
            flightClass: flight.segments[i].class,
            price: flight.fares[i].price.currency,
          }

          acc.push(aux)
          return acc
        }, [])

        setAirs(result)
      }

      setLoading(false)
    } catch (err) {
      toast.info('Credenciais invalidas')
      setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle title='Disponibilidade' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <DefaultForm
            submitLabel={'Buscar'}
            callbackSubmit={onSearch}
            callbackReset={() => {}}
            loading={loading}
            inputs={[
              {
                type: 'date',
                name: 'start',
                label: 'Data de partida',
                sm: 2,
                lg: 2,
                xl: 2,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Data de chegada',
                sm: 2,
                lg: 2,
                xl: 2,
              },
              {
                type: 'text',
                name: 'origin',
                label: 'Iata do aeroporto de origem',
                sm: 2,
                lg: 2,
                xl: 2,
              },
              {
                type: 'text',
                name: 'destination',
                label: 'Iata do aeroporto de destino',
                sm: 2,
                lg: 2,
                xl: 2,
              },
              {
                type: 'text',
                name: 'baggage',
                label: 'Bagagem',
                sm: 2,
                lg: 2,
                xl: 2,
              },
              {
                type: 'select',
                name: 'flightClass',
                label: 'Classe do voo',
                options: [
                  { label: 'Qualquer', value: 'UNDEFINED' },
                  { label: 'Econômica', value: 'ECONOMIC' },
                  { label: 'Econômica premium', value: 'ECONOMIC_PREMIUM' },
                  { label: 'Executiva', value: 'EXECUTIVE' },
                  { label: 'Primeira classe', value: 'FIRST_CLASS' },
                  { label: 'Executiva premium', value: 'EXECUTIVE_PREMIUM' },
                  {
                    label: 'Primeira classe premium',
                    value: 'FIRST_CLASS_PREMIUM',
                  },
                  { label: 'Premium', value: 'PREMIUM' },
                ],
                sm: 2,
                lg: 2,
                xl: 2,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <Tabs />
        {/* <PanelHeader noButton></PanelHeader> */}
        <PanelBody>
          <ReactTable
            data={airs}
            columns={[
              {
                Header: 'Cia',
                accessor: 'cia',
                width: 75,
              },
              {
                Header: 'Origem',
                accessor: 'origin',
                width: 160,
              },
              {
                Header: 'Destino',
                accessor: 'destination',
                width: 175,
              },
              {
                Header: 'Classe do voo',
                accessor: 'flightClass',
                width: 120,
              },
              {
                Header: 'Preço',
                accessor: 'price',
                width: 120,
              },
              // {
              //   Header: 'Valor da proposta',
              //   accessor: 'fullValue',
              //   width: 140,
              //   Cell: props => {
              //     return FormatValueBRL(props.row.original.fullValue)
              //   },
              // },
              // {
              //   Header: 'Consultor',
              //   accessor: 'agent',
              //   width: 120,
              // },
              // {
              //   Header: '',
              //   accessor: 'detalhes',
              //   Cell: props => {
              //     return (
              //       <IconButton
              //         id={props.row.index}
              //         type={'details'}
              //         onClick={() => showDetails(props.row.original.id)}
              //       />
              //     )
              //   },
              //   width: 20,
              // },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
