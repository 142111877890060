import './styles.css'

export default function SlideInput({
  name,
  value = false,
  label = '',
  onChange = () => {},
  disabled,
}) {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <label>
          <label style={{ fontWeight: 600, margin: '2px 4px' }}>{label}</label>
          <div
            style={{
              height: '38px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className='switch'>
              <input
                type='checkbox'
                name={name}
                defaultChecked={value}
                checked={value}
                onChange={onChange}
                disabled={disabled}
              />

              <span className='slider round'></span>
            </div>
          </div>
        </label>
      </div>
    </div>
  )
}
