// import { useEffect, useRef, useState } from 'react'
// import WaveSurfer from 'wavesurfer.js'
// import IconButton from '~/components/IconButton'

export default function VoiceMessage({ content }) {
  // const [isPlaying, setIsPlaying] = useState(false)
  // const [audioMessge, setAudioMessage] = useState(null)
  // const waveForm = useRef(null)
  // const waveFormRef = useRef(null)
  // const [currentPlaybackTime, setCurrentPlaybackTime] = useState(0)
  // const [totalDuration, setTotalDuration] = useState(0)

  // useEffect(() => {
  //   if (waveForm.current == null) {
  //     waveForm.current = WaveSurfer.create({
  //       container: waveFormRef.current,
  //       waveColor: '#4F4A85',
  //       progressColor: '#383351',
  //       // url: '/audio.mp3',
  //       height: 30,
  //       cursorColor: '#7ae3c3',
  //       barWidth: 2,
  //       width: 100,
  //     })
  //     waveForm.current.on('finish', () => {
  //       setIsPlaying(false)
  //     })
  //   }
  //
  //   return () => {
  //     waveForm.current.destroy()
  //   }
  // }, [])

  // useEffect(() => {
  //   if (message?.media?.content) {
  //     const audioUrl = message.media.content
  //     // console.log('audioUrl ', audioUrl)
  //     const audio = new Audio(audioUrl)
  //     // console.log('audio ', audio)
  //
  //     var binary = window.atob(audioUrl)
  //     var buffer = new ArrayBuffer(binary.length)
  //     var bytes = new Uint8Array(buffer)
  //     for (var i = 0; i < buffer.byteLength; i++) {
  //       bytes[i] = binary.charCodeAt(i) & '0xFF'
  //     }
  //     console.log('buffer ', buffer)
  //
  //     setAudioMessage(audio)
  //     waveForm.current.load(audioUrl)
  //     waveForm.current.on('ready', () => {
  //       setTotalDuration(waveForm.current.getDuration())
  //     })
  //   }
  // }, [message])

  // useEffect(() => {
  //   if (audioMessge) {
  //     const updatePlaybackTime = () => {
  //       setCurrentPlaybackTime(audioMessge.currentTime)
  //     }
  //     audioMessge.addEventListener('timeupdate', updatePlaybackTime)
  //     return () => {
  //       audioMessge.removeEventListener('timeupdate', updatePlaybackTime)
  //     }
  //   }
  // }, [audioMessge])

  {
    /*
  const formatTime = time => {
    if (isNaN(time)) return '00:00'
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }
  const handlePlayAudio = () => {
    if (audioMessge) {
      waveForm.current.stop()
      waveForm.current.play()
      audioMessge.play()
      setIsPlaying(true)
    }
  }

  const handlePauseAudio = () => {
    waveForm.current.stop()
    audioMessge.pause()
    setIsPlaying(false)
  }
  */
  }

  return (
    <div>
      {/* <div>
        {!isPlaying && (
          <IconButton onClick={() => handlePlayAudio()} type='play' />
        )}
        {isPlaying && (
          <IconButton onClick={() => handlePauseAudio()} type='pauseCircle' />
        )}
      </div> */}
      <div style={{ position: 'relative' }}>
        {/* <div style={{ width: '0px', height: 0 }} ref={waveFormRef} /> */}

        <div style={{ width: '100%' }}>
          <span>
            <audio controls>
              <source
                // src={`data:${message.contentType};base64,${message.content}`}
                src={`data:audio/ogg;base64,${content}`}
              />
            </audio>
            {/* {formatTime(isPlaying ? currentPlaybackTime : totalDuration)} */}
          </span>
        </div>
      </div>
    </div>
  )
}
