import { useState, useRef } from 'react'
import { Row, Col } from 'reactstrap'
import { Form } from '@unform/web'
import { SearchButton, ResetButton } from '~/components/Button/styles'
import Input from '~/components/Input'
import { DivFlexRight } from '~/styles/divs'

export default function Filter({
  setCategories,
  categoriesOriginal,
  setLoading,
}) {
  const formRef = useRef()
  const [formData, setFormData] = useState({})
  const handleReset = () => setFormData({})

  async function handleSubmit() {
    setLoading(true)

    if (!formData.desc && !formData.code) {
      setCategories(categoriesOriginal)

      return setLoading(false)
    }

    const newList = categoriesOriginal.filter(item => {
      if (
        (formData.desc && formData.desc.length > 0
          ? item.desc.toLowerCase().includes(formData.desc.toLowerCase())
          : true) &&
        (formData.code && formData.code.length > 0
          ? item.code.toLowerCase().includes(formData.code.toLowerCase())
          : true)
      ) {
        return true
      }

      return false
    })

    setCategories(newList)

    return setLoading(false)
  }

  return (
    <Form ref={formRef} onReset={handleReset} onSubmit={handleSubmit}>
      <Row>
        <Col sm={12} lg={6}>
          <Input
            label={'Nome da Categoria'}
            name={'desc'}
            value={formData.desc}
            onChange={e =>
              setFormData({ ...formData, [e.target.name]: e.target.value })
            }
            maxLength={30}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <DivFlexRight>
            <ResetButton type='reset'>Limpar</ResetButton>

            <SearchButton type='submit'>Pesquisar</SearchButton>
          </DivFlexRight>
        </Col>
      </Row>
    </Form>
  )
}
