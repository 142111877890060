import React, { useState, useEffect } from 'react'
import PouchDB from 'pouchdb-browser'
import { Provider } from 'use-pouchdb'

export function SyncInstanceWrapper({ children }) {
  const remote = new PouchDB(
    `${process.env.REACT_APP_CHATCENTER_COUCH_URL}/couch/instance`,
    {
      auth: {
        username: process.env.REACT_APP_USER_ACCESS_API_CHATCENTER,
        password: process.env.REACT_APP_PASSWORD_ACCESS,
      },
    },
  )

  const [databaseInstances, setDatabaseInstances] = useState(remote)
  const [localDb, setLocalDb] = useState(new PouchDB('local'))
  useEffect(() => {
    const listener = dbName => {
      if (
        dbName ===
        `${process.env.REACT_APP_CHATCENTER_COUCH_URL}/couch/instance`
      ) {
        setDatabaseInstances(new PouchDB(remote))
      }
    }

    PouchDB.on('destroyed', listener)
    return () => {
      PouchDB.removeListener('destroyed', listener)
    }
  }, [])

  return (
    <Provider
      default='remote'
      databases={{
        local: localDb,
        remote: remote,
      }}
    >
      {children}
    </Provider>
  )
}
