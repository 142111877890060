import { createContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { EditButton } from '~/components/Button/styles'
import useKeyPress from '~/hooks/useKeyPress'

const ModalContext = createContext()

const DEFAULT_MODAL_PROPS = {
  show: false,
  title: 'Título do modal',
  desc: 'Descrição do modal',
  body: () => {
    return
  },
  width: 'auto',
  callback: () => {},
}

function ModalProvider({ children }) {
  const [modalProps, setModalProps] = useState(DEFAULT_MODAL_PROPS)
  const { keyPressed: escKeyPressed, removeListener } = useKeyPress('Escape')

  useEffect(() => {
    if (escKeyPressed) {
      hideModal()
    }

    return () => {
      hideModal()

      removeListener()
    }
  }, [escKeyPressed])

  useEffect(() => {
    return () => {
      setModalProps(DEFAULT_MODAL_PROPS)
    }
  }, [])

  const showModal = ({ title, desc, body, width, callback }) => {
    document.body.style.overflow = 'hidden'

    setModalProps({
      show: true,
      title,
      desc,
      body,
      width,
      callback,
    })
  }

  const hideModal = () => {
    if (modalProps.callback) modalProps.callback()

    document.body.style.overflow = 'auto'

    setModalProps(DEFAULT_MODAL_PROPS)
  }

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      <div style={{ position: 'relative', height: '100%' }}>
        {modalProps.show && (
          <ModalContainer>
            <ModalContent width={modalProps.width}>
              <ModalContentTitle>
                <div>
                  <h6>{modalProps.title}</h6>

                  {modalProps.desc && <span>{modalProps.desc}</span>}
                </div>

                <EditButton onClick={hideModal}>
                  <i className='fas fa-times' />
                </EditButton>
              </ModalContentTitle>

              <ModalContentBody>{modalProps.body()}</ModalContentBody>
            </ModalContent>
          </ModalContainer>
        )}

        {children}
      </div>
    </ModalContext.Provider>
  )
}

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1030;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  padding: 20vh 0px;
`

const ModalContent = styled.div`
  ${props => (props.width ? 'width: ' + props.width + ';' : '')}
  max-width: ${props => (props.width ? 'auto' : '80%')};
  min-width: ${props => (props.width ? 'auto' : '50%')};
  min-height: 20vh;
  background: #fff;
  border-radius: 6px 6px 4px 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    min-width: 90%;
  }
`

const ModalContentTitle = styled.div`
  width: 100%;
  background: #eaeaea;
  padding: 8px 8px 8px 16px;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h6 {
    margin: 0;
  }
`

const ModalContentBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
`

export { ModalContext, ModalProvider }
