import styled from 'styled-components'

export const IconBottom = styled.i`
  color: #caccce;
  padding: 0px 6px;
  font-size: 16px;
`
export const Keyboard = styled.form`
  width: 100%;
  display: flex;
  border: 1px solid #caccce;
  border-radius: 10px;
  flex-direction: column;
  margin-top: 8px;
`
export const InputTextArea = styled.textarea`
  height: 76px;
  margin: 6px 10px 10px 10px;
  resize: none;
  border-bottom: 1px solid #caccce;
  border: none;
  :focus {
    outline: none !important;
    border: none;
  }
`
export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  border-top: 1px solid #caccce;
`
export const Features = styled.div`
  display: flex;
`
export const MessagesContainer = styled.div`
  flex: 1;
  padding: 4px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #caccce;
  word-break: break-all;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  background: #eaeaea;
`

export const ContactText = styled.p`
  background: #e9e9e9;
  border-radius: 10px;
  padding: 10px;
`

export const LabelDocumentClip = styled.label`
  cursor: pointer;
`
