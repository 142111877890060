import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import Kanban from '~/components/Kanban'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import AddParticipantForm from './AddParticipantForm'
import DetailsParticipantForm from './DetailsParticipantForm'
import context from '~/context/createContext'
import IconButton from '~/components/IconButton'
// import { toast } from 'react-toastify'

export default function PedagogicalParticipantKanban() {
  const { axiosApis } = GetAxios()
  const [eventOptions, setEventOptions] = useState([])
  const [institutionOptions, setInstitutionOptions] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // const [modalData, setModalData] = useState({})
  const [modalType, setModalType] = useState('create')
  const [pageFilters, setPageFilters] = useState({})

  const { modalData, setModalData } = useContext(context)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const newEvents = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/event`,
        )

        setEventOptions(newEvents.data)

        const newInstitutions = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/institution`,
        )

        setInstitutionOptions(newInstitutions.data)

        await onSubmit()

        return setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = false

      setData([])
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        if (pageFilters) {
          await onSubmit()
        } else {
          setData([])
        }

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [pageFilters])

  async function callbackOnAdd({ item: stage }) {
    setModalType('create')

    setModalData({ stage })

    setShowModal(true)
  }

  async function callbackOnMove({ item, newStage, oldStage }) {
    try {
      if (newStage.label !== oldStage.label) {
        setLoading(true)

        await axiosApis.put(
          `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/stage`,
          {
            id: item.id,
            stage: newStage.key,
          },
        )

        await onSubmit()

        setTimeout(() => {
          setLoading(false)
        }, 500)
      }

      return true
    } catch (error) {
      setTimeout(() => setLoading(false), 1000)

      return false
    }
  }

  async function callbackOnDetails(item) {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/getfiles/${item.id}`,
      )

      setModalData({
        ...item,
        data1: res.data[0] ? { ...res.data[0] } : null,
        data2: res.data[1] ? { ...res.data[1] } : null,
      })

      setModalType('details')

      setShowModal(true)

      setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function callbackOnClose() {
    setModalData({})

    setShowModal(false)

    await onSubmit()
  }

  async function onClose() {
    setModalData({})

    setShowModal(false)

    await onSubmit()
  }

  async function callbackOnDelete(item) {
    try {
      if (
        window.confirm(
          'Deseja remover o card do Kanban? (Obs: Isso não excluirá a inscrição, somente o card)',
        )
      ) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/${item.id}`,
        )

        await onSubmit()
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function onSubmit() {
    const res = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban`,
      {
        params: {
          eventId: pageFilters.event ? pageFilters.event.value : undefined,
          institutionId: pageFilters.institution
            ? pageFilters.institution.value
            : undefined,
          fullName: pageFilters.fullName,
        },
      },
    )
    setData(res.data)
  }

  return (
    <Container>
      <h1 className='page-header'>
        Quadro de Inscrições
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Aqui poderá acompanhar todo o processo de cada inscrito por grupo. Facilitando e tendo toda a informação de forma fácil e ágil.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={setPageFilters}
            callbackReset={() => setPageFilters(undefined)}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                name: 'institution',
                label: 'Grupo',
                placeholder: 'Selecione...',
                options: institutionOptions,
                isClearable: true,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
              {
                type: 'select',
                name: 'event',
                label: 'Evento',
                placeholder: 'Selecione...',
                options: eventOptions,
                required: true,
                isClearable: true,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'fullName',
                label: 'Nome do viajante',
                placeholder: 'Nome do viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Kanban
            data={data}
            callbackOnMove={callbackOnMove}
            callbackOnAdd={callbackOnAdd}
            callbackOnDelete={callbackOnDelete}
            callbackOnDetails={callbackOnDetails}
            callbackOnClose={callbackOnClose}
            showModal={showModal}
            altTitle={'Data de inscrição'}
            altDateFormat={'DD/MM/YYYY HH:mm:ss'}
            showAltDateLabel={false}
            modalContent={
              modalType == 'create' ? (
                <AddParticipantForm
                  modalData={modalData}
                  onClose={onClose}
                  pageFilters={pageFilters}
                />
              ) : (
                <DetailsParticipantForm
                  modalData={modalData}
                  onClose={onClose}
                />
              )
            }
            modalTitle={
              modalData.stage
                ? `Adicionar Incrição em "${modalData.stage.label}"`
                : modalData.details
                ? modalData.details.title
                : ''
            }
            loading={loading}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

const Container = styled.div`
  width: 100;
`
