import { Input } from 'reactstrap'
import InputMessage from '../InputMessage'

export default function DropableInput({ onChange, rest }) {
  return (
    <div
      style={{
        textAlign: 'center',
        margin: '8px auto',
      }}
    >
      <Input
        size={'100'}
        addon={true}
        type='file'
        onChange={onChange}
        {...rest}
      />
      <InputMessage />
    </div>
  )
}
