import { LinkRegisterNewPanel } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import usePermissions from '~/hooks/usePermissions'
import { Container } from '~/styles/container'
import ReactTable from '~/components/Table'
import DefaultForm from '~/components/DefaultForm'
import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function List() {
  const { axiosApis } = GetAxios()
  const { manageExperiences } = usePermissions()
  const [experiences, setExperiences] = useState([])
  const [experiencesOriginal, setExperiencesOriginal] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getSource()
  }, [])

  async function showDetails(e) {
    navigate('/experiences/details', { state: { id: e } })
  }

  async function getSource() {
    try {
      setLoading(true)

      const resExperiences = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience`,
      )

      setExperiences(resExperiences.data)

      setExperiencesOriginal(resExperiences.data)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  async function onFilter(data) {
    setLoading(true)

    if (!data.category && !data.city && !data.title) {
      setExperiences(experiencesOriginal)

      return setTimeout(() => setLoading(false), 500)
    }

    const experiencesFiltered = experiencesOriginal.filter(e => {
      if (
        (data.category ? e.category === data.category.label : true) &&
        (data.city
          ? e.city.replace(' -', ',') === data.city?.label.replace(' -', ',')
          : true) &&
        (data.title
          ? e.title.toLowerCase().includes(data.title.toLowerCase())
          : true)
      ) {
        return true
      }

      return false
    })

    setExperiences(experiencesFiltered)

    setTimeout(() => setLoading(false), 500)
  }

  return (
    <Container>
      <h1 className='page-header'>
        Experiências
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'A Tela possui todos as Experiencias cadastradas pela Agencia, essa informação é única da agencia. Para facilitar temos integrações que trazem alguns dados para facilitar o cadastro.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader title='Filtros' noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onFilter}
            callbackReset={() => onFilter({})}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Experiência',
                sm: 12,
                lg: 4,
              },
              {
                type: 'experience-category',
                name: 'category',
                label: 'Categoria',
                sm: 12,
                lg: 4,
              },
              {
                type: 'city',
                name: 'city',
                placeholder: 'Pesquise por uma cidade...',
                label: 'Cidade',
                isClearable: true,
                sm: 12,
                lg: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title='Resultado da pesquisa'>
          {manageExperiences && (
            <LinkRegisterNewPanel to='categories'>
              Gerenciar Categorias
            </LinkRegisterNewPanel>
          )}

          <LinkRegisterNewPanel to='create'>
            Nova Experiência
          </LinkRegisterNewPanel>
        </PanelHeader>

        <PanelBody>
          <ReactTable
            columns={[
              {
                Header: 'Experiência',
                accessor: 'title',
                width: 300,
              },
              {
                Header: 'Categoria',
                accessor: 'category',
                width: 300,
              },
              {
                Header: 'Cidade',
                accessor: 'city',
                width: 300,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.index}
                      type={'details'}
                      onClick={() => showDetails(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            data={experiences}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
