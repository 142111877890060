import styled from 'styled-components'

export const Container = styled.div`
  width: 50%;
  margin: auto;
`

export const Title = styled.span`
  font-size: 30px;
  font-weight: 700;
`

export const SubTitle = styled.span`
  font-size: 15px;
  font-weight: 300;
`

export const P = styled.p`
  font-size: 30px;
  font-weight: 700;
  margin: auto;
`

export const SupportText = styled.div`
  font-size: 40px;
  font-weight: 700;
  margin: auto;
  text-align: center;

  p {
    font-size: 30px;
    font-weight: 500;
  }
`

export const CoverHeader = styled.div`
  background-color: #320138;
`

export const Header = styled.div`
  margin: auto;
  width: 50%;
  padding: 10px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (width <= 1000px) {
    width: 75%;
  }

  @media (width <= 600px) {
    width: 90%;
  }
`

export const DivOfButtons = styled.div`
  button {
    background-color: transparent;
    color: #fff;
    border: transparent;
    font-weight: 700;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    font-size: 14px;
  }

  .free-trial {
    background-color: #5bc849;
  }
`

export const ImageButton = styled.button`
  background-color: transparent;
  border: transparent;
`

export const Body = styled.div``

export const Footer = styled.div`
  width: 50%;
  margin: 0 auto;
  padding-bottom: 30px;

  display: flex;
  justify-content: space-between;
  align-items: start;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  button {
    background-color: transparent;
    border: transparent;
    /* width: 75px; */
    font-size: 25px;
    color: #d54a26;
    padding: 0;
  }

  @media (width <= 1000px) {
    width: 75%;
  }

  @media (width <= 600px) {
    width: 90%;
    padding: 0;
  }
`

export const Div = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
