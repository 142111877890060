import styled from 'styled-components'
import { getDateFull } from '~/functions/Date'
import { SpaceBetweenDiv } from '~/styles/divs'

function Header({ type, title, showToday }) {
  return (
    <SpaceBetweenDiv
      style={{
        flexWrap: 'wrap',
        marginBottom: '8px',
      }}
      type={type}
    >
      <h1 className='page-header'>
        {title} {type ? (type === 'month' ? ' - mês' : ' - dia') : ''}
      </h1>

      {showToday && <TodayLabel>Hoje é {getDateFull().full}</TodayLabel>}
    </SpaceBetweenDiv>
  )
}

const TodayLabel = styled.b`
  color: ${props => props.theme.colors.primary};
`

export default Header
