import { IobjectToInputTable } from '~/interfaces/index'

export function billingPerConsultant(
  formatValueBRL: (arg0: string) => any,
  showAll: boolean,
): IobjectToInputTable[] {
  const arr: IobjectToInputTable[] = [
    {
      Header: 'Franqueado',
      accessor: 'sublabel',
      width: 300,
    },
    {
      Header: 'Consultor',
      accessor: 'label',
      width: 300,
    },
    {
      Header: 'Quantidade de vendas',
      accessor: 'count',
      width: 150,
    },
    {
      Header: 'Faturamento',
      accessor: 'sum',
      width: 150,
      Cell: props => {
        return formatValueBRL(props.row.original.sum)
      },
    },
  ]

  const result =
    showAll == false ? arr : arr.filter(x => x.Header != 'Franqueado')

  return result
}

export function billingPerProduct(
  formatValueBRL: (arg0: string) => any,
  showAll: boolean,
): IobjectToInputTable[] {
  const arr: IobjectToInputTable[] = [
    {
      Header: 'Franqueado',
      accessor: 'sublabel',
      width: 300,
    },
    {
      Header: 'Produto',
      accessor: 'label',
      width: 300,
    },
    {
      Header: 'Quantidade de vendas',
      accessor: 'count',
      width: 150,
    },
    {
      Header: 'Faturamento',
      accessor: 'sum',
      width: 150,
      Cell: props => {
        return formatValueBRL(props.row.original.sum)
      },
    },
  ]

  const result =
    showAll == false ? arr : arr.filter(x => x.Header != 'Franqueado')

  return result
}

export function billingPerSupplier(
  formatValueBRL: (arg0: string) => any,
  showAll: boolean,
): IobjectToInputTable[] {
  const arr: IobjectToInputTable[] = [
    {
      Header: 'Franqueado',
      accessor: 'sublabel',
      width: 300,
    },
    {
      Header: 'Fornecedor',
      accessor: 'label',
      width: 300,
    },
    {
      Header: 'Quantidade de vendas',
      accessor: 'count',
      width: 150,
    },
    {
      Header: 'Faturamento',
      accessor: 'sum',
      width: 150,
      Cell: props => {
        return formatValueBRL(props.row.original.sum)
      },
    },
  ]

  const result =
    showAll == false ? arr : arr.filter(x => x.Header != 'Franqueado')

  return result
}
