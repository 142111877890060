import DefaultForm from '~/components/DefaultForm'
import {
  Container,
  CoverHeader,
  Div,
  Footer,
  Header,
  ImageButton,
} from './style'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { apiRequest } from '~/apiService'
import LoadingContainer from '~/components/LoadingContainer'

export default function ShortForm() {
  const params = useParams()
  const [image, setImage] = useState(null)
  const [loading, setLaoding] = useState(false)
  const [ddi, setDdi] = useState([{}])

  document.title = 'Plataforma CRM especializada em agências de viagens'

  useEffect(() => {
    getSource()
    getOptionsDDI()
  }, [])

  async function getSource() {
    const response = await apiRequest(
      'get',
      `/agent/token/${params.token}`,
      {},
      setLaoding,
    )

    if (response.error == false) setImage(response.data.image)
  }

  async function getOptionsDDI() {
    const response = await apiRequest('options', '/ddi')

    if (response.error == false) setDdi(response.data)
  }

  const requestApiPostToCreate = async data => {
    const body = {
      ...data,
      token: params.token,
      leadFormId: params.leadFormId,
      campaignId: params.campaignId,
    }

    const response = await apiRequest(
      'post',
      '/customer/insert-new-customer-from-short-form',
      body,
    )

    if (response.error == false) {
      toast.success('Cadastro realizado com sucesso!')
      setTimeout(() => window.location.reload(), 1000)
    }
  }

  return (
    <Div>
      {loading && <LoadingContainer />}
      {!loading && (
        <>
          <CoverHeader>
            <Header>
              <ImageButton>
                <img
                  src={`data:image/*;base64,${image}`}
                  alt='logo'
                  style={{ maxWidth: '150px' }}
                />
              </ImageButton>
            </Header>
          </CoverHeader>

          <Container>
            <DefaultForm
              callbackSubmit={requestApiPostToCreate}
              submitLabel='Enviar'
              inputs={[
                {
                  type: 'text',
                  name: 'fullName',
                  label: 'Nome completo',
                  max: 40,
                  // required: true,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'date',
                  name: 'birthdayDate',
                  label: 'Data de Nascimento',
                  // required: true,
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'text',
                  name: 'cpfCnpj',
                  label: 'CPF/CNPJ',
                  // required: true,
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'select',
                  name: 'ddi',
                  label: 'DDI',
                  options: ddi,
                  placeholder: 'Selecione...',
                  // required: true,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'text',
                  name: 'phone',
                  label: 'Telefone',
                  mask: '(99) 99999-9999',
                  // required: true,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'email',
                  name: 'email',
                  label: 'E-mail',
                  // required: true,
                  sm: 5,
                  md: 5,
                  lg: 5,
                  xl: 5,
                },
                {
                  type: 'text',
                  name: 'nacionality',
                  label: 'Nacionalidade',
                  max: 20,
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
              ]}
            />
          </Container>

          <Footer>
            <img
              src={`data:image/*;base64,${image}`}
              alt='logo'
              style={{ maxWidth: '150px' }}
            />
          </Footer>
        </>
      )}
    </Div>
  )
}
