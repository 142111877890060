import { Badge, Button, Col, Form, Input, InputGroup, Row } from 'reactstrap'
import styled from 'styled-components'
import { AvatarSoft } from '~/components/Header/styles'

export const ContactImage = styled(AvatarSoft)`
  @media (max-width: 767.5px) {
    width: 52px;
    height: 52px;
  }
`

export const Icon = styled.i`
  color: #777;
  padding: 0px 6px;
  font-size: 12px;
`

export const Contacts = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const AccordionTop = styled.div`
  border-bottom: 1px solid #caccce;
  height: 57px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eaeaea;
  border-radius: 10px 10px 0px 0px;
`

export const AccordionControll = styled.div`
  @media (max-width: 767.5px) {
    b {
      display: none;
    }
  }
`
export const Image = styled(Col)`
  text-align: center;
`

export const Content = styled(Col)``

export const Container = styled(Row)`
  padding: 6px !important;
  align-items: center;
  margin: 0px;
  border-bottom: 1px solid #caccce;
  cursor: pointer;
  @media (max-width: 767.5px) {
    border: none;
  }

  background: ${props => (!props.active ? 'transparent' : '#eaeaea')};
`

export const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: none;
  align-items: center;
  border-bottom: 1px solid #caccce;
  padding: 10px;

  @media (max-width: 767.5px) {
    display: none;
  }
`

export const SearchBarForm = styled(Form)`
  width: 100%;
  padding: 0;
  margin: 0;
`

export const InputSearchGroup = styled(InputGroup)``

export const InputSearch = styled(Input)`
  border: none;

  :disabled {
    opacity: 0.5;
    background: none;
  }
`

export const InputSearchButton = styled(Button)`
  border: none;
  font-weight: 700;
`

export const Message = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: lighter;
  margin-bottom: 0px;
`
export const ContentLeft = styled(Col)`
  @media (max-width: 767.5px) {
    display: none;
  }
`
export const ContentRight = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767.5px) {
    display: none;
  }
  gap: 2px;
`
export const Name = styled.b`
  margin: 0;
`
export const MinorMessagesQuantity = styled(Badge)`
  @media (max-width: 767.5px) {
    position: relative;
    width: 20px;
    margin: 0px 56px;
  }

  @media (min-width: 767.5px) {
    display: none;
  }
`
export const MinorConfirmViewIcon = styled.div`
  color: rgb(13 110 253);
  @media (max-width: 767.5px) {
    position: relative;
    width: 20px;
    margin: 0px 56px;
  }
  @media (min-width: 767.5px) {
    display: none;
  }
`
export const AvatarIcon = styled.i`
  font-size: 36px;
  color: #bdbfbf;
`

export const ContactListContainer = styled.div`
  flex: 1;
  overflow: auto;
  scrollbar-width: thin;
  overflow-x: hidden;
`
