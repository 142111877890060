export const add = (options = []) => {
  const input = [
    {
      label: '',
      type: 'travelerSelect',
      name: 'traveler',
      placeholder: 'Pesquise por uma viajante',
      isMulti: true,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
  ]

  return input
}

export const create = (ddi, selectDdi, setSelectDdi) => [
  {
    type: 'text',
    name: 'familyName',
    label: 'Nome de Preferência',
    required: true,
    maxLength: 150,
    sm: 12,
    lg: 4,
  },
  {
    type: 'text',
    name: 'fullName',
    label: 'Nome Completo',
    required: true,
    maxLength: 150,
    sm: 12,
    lg: 4,
  },
  {
    type: 'email',
    name: 'email',
    label: 'E-mail',
    maxLength: 150,
    sm: 12,
    lg: 4,
  },
  {
    type: 'select',
    name: 'ddi',
    label: 'DDI',
    options: ddi,
    placeholder: 'Selecione...',
    onChange: e => setSelectDdi(e.target.value),
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
  },
  selectDdi.value == '' || selectDdi.value == 55
    ? {
        type: 'phone',
        name: 'phone',
        label: 'Telefone',
        sm: 12,
        lg: 2,
      }
    : {
        type: 'text',
        name: 'phone',
        label: 'Telefone',
        mask: '999 99999999',
        sm: 12,
        lg: 2,
      },
  {
    type: 'text',
    name: 'rg',
    label: 'RG/CNH',
    required: false,
  },
  {
    type: 'text',
    name: 'cpf',
    label: 'CPF/CNPJ',
  },
  {
    type: 'date',
    name: 'birthday',
    label: 'Aniversário',
  },
  {
    type: 'text',
    name: 'profession',
    label: 'Profissão',
    required: false,
  },
  {
    type: 'text',
    name: 'address',
    label: 'Endereço',
    required: false,
  },
  {
    type: 'city',
    name: 'city',
    label: 'Cidade',
    isClearable: true,
    required: false,
  },
  {
    type: 'textarea',
    name: 'perfil',
    label: 'Perfil do Viajante',
    required: false,
  },
  {
    type: 'check',
    name: 'vip',
    label: 'Viajante VIP',
  },
]
