import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'

export default function EditTicket({ toEdit, onClose, onDelete }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState()
  const [defaultValue, setDefaultValue] = useState()

  useEffect(() => {
    requestApiGet()
  }, [])

  async function requestApiGet() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelTicket/${toEdit.entity.id}`,
      )

      setDefaultValue({
        ...data,
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }

  const onValidate = async data => {
    if (data.timeStart && !data.timeEnd) {
      toast.info('Preencha o horário referente ao fim')
      return
    }

    if (!data.timeStart && data.timeEnd) {
      toast.info('Preencha o horário referente ao inicio')
      return
    }

    await onSubmit(data)
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const body = {
        ...data,
        city: { label: data.city?.label, value: data.city?.value },
        start: `${data.start} ${data.timeStart || '00:00'}`,
        end: `${data.end} ${data.timeEnd || '00:00'}`,
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelTicket`,
        body,
      )

      toast.success('Ticket editado com sucesso!')
      setLoading(false)
      onClose(true)
    } catch (error) {
      console.log(error)
      setLoading(false)
      toast.info('Não foi possível editar o ticket.')
    }
  }

  async function onDeleteActivity() {
    setLoading(true)

    await onDelete({ id: toEdit.entity.id, type: toEdit.entity.type })
    await onClose(true)

    setLoading(false)
  }

  return (
    <Container>
      <DefaultForm
        callbackSubmit={onSubmit}
        callbackDelete={onDeleteActivity}
        onValidate={onValidate}
        loading={loading}
        disabled={loading}
        defaultValue={defaultValue}
        inputs={[
          {
            type: 'text',
            name: 'title',
            label: 'Título',
            required: true,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'text',
            name: 'startAddress',
            label: 'Endereço',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
            maxLength: 200,
          },
          {
            type: 'city',
            name: 'city',
            label: 'Cidade',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'text',
            name: 'originPurchase',
            label: 'Fornecedor',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'date',
            name: 'start',
            label: 'Data do Início',
            row: '2',
            required: true,
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeStart',
            label: 'Hora do Início',
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'date',
            name: 'end',
            label: 'Data do Fim',
            required: true,
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeEnd',
            label: 'Hora do Fim',
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'html',
            name: 'desc',
            label: 'Descrição',
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div``
