// import { Panel, PanelBody, PanelHeader } from '../Panel'
import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import Table from '../Table'
import { toast } from 'react-toastify'
import { Div, Label, DeleteButton, DownloadButton } from './style'
import DownloadFile from '~/functions/DownloadFile'

export default function TableOfFile({ label = 'Anexos', url }) {
  const { axiosApis } = GetAxios()
  const [docs, setDocs] = useState([]) // Falta colocar paginação na tabela que exibe as infos de docs
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    requestApiServiceGet()
  }, [])

  async function requestApiServiceGet() {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/${url}`,
      )

      setDocs(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const downloadDoc = async e => {
    try {
      DownloadFile(`data:application/octet-stream;base64,${e.file}`, e.fileName)

      toast.success('Arquivo baixado com sucesso!')
    } catch (error) {
      console.log(error)

      toast.info('Não foi possível baixar o arquivo.')
    }
  }

  async function requestApiServiceDocDelete(serviceDocId) {
    try {
      setLoading(true)

      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/service/doc/${serviceDocId}`,
      )

      await requestApiServiceGet()
      setLoading(false)
      toast.success('Status do serviço atualizado com sucesso!')
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Div>
      <Label>{label}</Label>
      {/* <Panel> */}
      {/* <PanelBody style={{ width: '100%' }}> */}
      <Table
        data={docs}
        loading={loading}
        columns={[
          {
            Header: 'Nome do arquivo',
            accessor: 'fileName',
            width: 300,
          },
          {
            Header: '',
            accessor: 'download',
            Cell: props => (
              <DownloadButton
                type='button'
                onClick={() => downloadDoc(props.row.original)}
              >
                Download
              </DownloadButton>
            ),
            width: 80,
          },
          {
            Header: '',
            accessor: 'remove',
            Cell: props => (
              <DeleteButton
                type='button'
                onClick={() =>
                  requestApiServiceDocDelete(props.row.original.id)
                }
              >
                Remove
              </DeleteButton>
            ),
            width: 80,
          },
        ]}
        tableProps={{
          defaultPageSize: 10,
          hasFooter: false,
          hasPagination: true,
          loading: loading,
          resizable: true,
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px',
        }}
        tableLabels={{
          loadingText: 'Carregando...',
          noDataText: 'Sem registros',
          nextButtonText: 'Próximo',
          previousButtonText: 'Anterior',
          pageOfText: 'de',
          pageSizeSelectText: 'registros',
          pageText: 'Página',
        }}
      />
      {/* </PanelBody> */}
      {/* </Panel> */}
    </Div>
  )
}
