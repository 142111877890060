export default function InputHtmlControls({
  editorState,
  toggleInlineStyle,
  toggleBlockType,
}) {
  const INLINE_STYLES = [
    { label: 'B', style: 'BOLD', icon: 'fas fa-bold' },
    { label: 'I', style: 'ITALIC', icon: 'fas fa-italic' },
    { label: 'U', style: 'UNDERLINE', icon: 'fas fa-underline' },
  ]

  const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'UL', style: 'unordered-list-item', icon: 'fas fa-list-ul' },
    { label: 'OL', style: 'ordered-list-item', icon: 'fas fa-list-ol' },
  ]

  const InputHtmlStyleToggle = ({ active, label, icon, onToggle, style }) => {
    return (
      <span
        onMouseDown={() => onToggle(style)}
        className={`RichEditor-styleButton${
          active ? ' RichEditor-activeButton' : ''
        }`}
      >
        {icon && <i className={icon}></i>}

        {!icon && label}
      </span>
    )
  }

  return (
    <>
      <div className='RichEditor-controls'>
        {INLINE_STYLES.map(type => (
          <InputHtmlStyleToggle
            key={type.label}
            active={editorState.getCurrentInlineStyle().has(type.style)}
            label={type.label}
            icon={type.icon}
            onToggle={toggleInlineStyle}
            style={type.style}
          />
        ))}

        {BLOCK_TYPES.map(type => (
          <InputHtmlStyleToggle
            key={type.label}
            active={
              type.style ===
              editorState
                .getCurrentContent()
                .getBlockForKey(editorState.getSelection().getStartKey())
                .getType()
            }
            label={type.label}
            icon={type.icon}
            onToggle={toggleBlockType}
            style={type.style}
          />
        ))}
      </div>
    </>
  )
}
