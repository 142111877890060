import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Col, Form, Row } from 'reactstrap'
import { RegisterNewButton } from '~/components/Button/styles'
import DefaultForm from '~/components/DefaultForm'
import IconButton from '~/components/IconButton'
import Input from '~/components/Input'
import LoadingContainer from '~/components/LoadingContainer'
import { GetAxios } from '~/services/api'
import { DivFull, SpaceBetweenDiv } from '~/styles/divs'

export default function ShowInfo() {
  const [listTravelers, setListTravelers] = useState([])
  const [listTravels, setListTravels] = useState([])
  const [travelLink, setTravelLink] = useState(false)
  const [loading, setLoading] = useState(false)
  const { axiosApis } = GetAxios()
  const [loadingLink, setLoadingLink] = useState(false)
  const [travelDetails, setTravelDetails] = useState()
  const [copyTravelLinkSuccessMessage, setCopyTravelLinkSuccessMessage] =
    useState()
  const [travelLink2, setTravelLink2] = useState()

  useEffect(() => {
    let cancel = false

    getTraveler(cancel)

    return () => {
      cancel = true
    }
  }, [])

  async function getTraveler(cancel) {
    if (cancel) return

    try {
      setLoading(true)

      const { data: dataTravelers } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
      )

      setListTravelers(dataTravelers)

      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  async function generateLink() {
    try {
      setLoadingLink(true)

      const { data } = await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship`,
        { travelId: travelDetails.value },
      )

      setTravelLink(data.link)
      // setTravelDetails()

      setLoadingLink(false)
    } catch (error) {
      console.log(error)

      toast.info(error.response.data)

      setTravelLink(undefined)

      setLoadingLink(false)
    }
  }

  async function copyTravelLink() {
    navigator.clipboard.writeText(travelLink)

    setCopyTravelLinkSuccessMessage('Link copiado!')

    setTimeout(() => {
      setCopyTravelLinkSuccessMessage(undefined)
    }, 2000)
  }

  async function redirectToTravelLink() {
    var win = window.open(travelLink, '_blank')
    win.focus()
  }

  async function getTravels(e) {
    try {
      setLoading(true)

      console.log(e)

      const { data: dataTravels } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/search/${e.value}`,
      )

      setListTravels(dataTravels)

      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  return (
    <DivFull style={{ height: '40vh' }}>
      <DefaultForm
        inputs={[
          // {
          //   type: 'select',
          //   label: 'Viajante',
          //   name: 'viajante',
          //   options: listTravelers,
          //   width: 120,
          //   onChange: e => getTravels(e.target.value),
          // },
          {
            type: 'travelerSelect',
            label: 'Viajante',
            name: 'viajante',
            placeholder: 'Pesquise por uma viajante',
            width: 120,
            onChange: e => getTravels(e.target.value),
          },
          {
            type: 'select',
            label: 'Viagem',
            name: 'viagem',
            placeholder: listTravels.length > 0 ? 'Selecione...' : '',
            options: listTravels,
            width: 120,
            onChange: e => setTravelDetails(e.target.value),
          },
        ]}
      />
      <div>
        {travelDetails && (
          <RegisterNewButton
            style={{ fontSize: '12px', height: '3vh' }}
            onClick={generateLink}
            disabled={loadingLink}
            type='button'
          >
            {!loadingLink && 'Gerar link para o viajante'}

            {loadingLink && <LoadingContainer size={'sm'} />}
          </RegisterNewButton>
        )}

        {travelLink && (
          <SpaceBetweenDiv>
            <Form ref={null} style={{ width: '100%' }}>
              <Row>
                <Col sm={12}>
                  <input
                    style={{
                      height: '38px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      width: '20%',
                    }}
                    type='text'
                    label='Link para o Viajante'
                    // successMessage={copyTravelLinkSuccessMessage}
                    name={'link'}
                    // disabled={true}
                    value={travelLink}
                    // searchButton={true}
                    // cancelButton={true}
                    // searchIcon={'fas fa-copy'}
                    // cancelIcon={'fas fa-external-link-alt'}
                    // callbackSearch={() => copyTravelLink()}
                    // callbackCancel={() => redirectToTravelLink()}
                  />
                  <IconButton
                    iconOverride={'fas fa-copy'}
                    tooltipOverride={'Copiar'}
                    onClick={() => copyTravelLink()}
                  />
                  <IconButton
                    iconOverride={'fas fa-external-link-alt'}
                    tooltipOverride={'Link'}
                    onClick={() => redirectToTravelLink()}
                  />
                </Col>
              </Row>
            </Form>
          </SpaceBetweenDiv>
        )}
      </div>
    </DivFull>
  )
}
