import { createContext } from 'react'

const context = createContext({
  loadingTravelers: false,
  entitys: [],
  showModal: false,
  airList: [],
  airsMock: [],
  hotelList: [],
  hotelsMock: [],
  globalFilters: {
    dateStart: undefined,
    dateEnd: undefined,
    date: undefined,
  },
})

export default context
