import { useState, useEffect } from 'react'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import BackofficeWarning from '../components/BackofficeWarning'

export default function IntegrationAgencyCreate() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [options, setOptions] = useState([])
  const [fields, setFields] = useState([])
  const [integration, setIntegration] = useState('')
  const [loading, setLoading] = useState(false)
  const [importOptions, setImportOptions] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const resCanCreate = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3/to-create`,
        )

        setOptions(resCanCreate.data)

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    getSource()
  }, [])

  async function onSubmitGetFields(data) {
    try {
      setLoading(true)

      const params = {
        integrationId: data.type.value,
      }

      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3/fields-tocreate`,
        { params },
      )

      setFields(res.data)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('integrationId', data.type.value)

      const { type, ...rest } = data

      let body = {}

      if (integration.includes('(Infotravel)')) {
        body = {
          ...rest,
          username: rest.username || '',
          password: rest.password || '',
          observation: rest.observation || '',
        }
      } else {
        body = {
          ...rest,
          observation: rest.observation || '',
        }
      }

      formData.append('values', JSON.stringify(body))

      if (importOptions) {
        if (importOptions.importBackofficeCustomers) {
          formData.append(
            'importBackofficeCustomers',
            !!importOptions.importBackofficeCustomers,
          )
        }

        if (importOptions.sunday) {
          formData.append('importBackofficeDays', 0)
        }

        if (importOptions.monday) {
          formData.append('importBackofficeDays', 1)
        }

        if (importOptions.tuesday) {
          formData.append('importBackofficeDays', 2)
        }

        if (importOptions.wednesday) {
          formData.append('importBackofficeDays', 3)
        }

        if (importOptions.thursday) {
          formData.append('importBackofficeDays', 4)
        }

        if (importOptions.friday) {
          formData.append('importBackofficeDays', 5)
        }

        if (importOptions.saturday) {
          formData.append('importBackofficeDays', 6)
        }
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3/validate`,
        formData,
      )

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3`,
        formData,
      )

      if (
        integration.includes('(Infotravel)') &&
        data.username &&
        data.password
      ) {
        try {
          const response = await axiosApis.post(
            `/integrationv3/validate/availability`,
            body,
          )

          localStorage.setItem('VEfKJmx7Wx9SkwW7', response?.data)
        } catch (err) {
          toast.info('Credencias para consulta de disponibilidade inválidas')
        }
      }

      toast.success('Credenciais validadas e cadastradas com sucesso.')

      setLoading(false)

      if (integration && integration.includes('(Wintour)')) {
        navigate('/sales-backoffice')
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      } else {
        navigate('/sales-backoffice')
      }

      return setLoading(false)
    }
  }

  return (
    <>
      <PageTitle title='Cadastrar nova integração' />

      {integration && integration.includes('(Wintour)') && (
        <BackofficeWarning />
      )}

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <>
            {integration && integration.includes('(Wintour)') && (
              <DefaultForm
                onChangeData={setImportOptions}
                inputs={[
                  {
                    type: 'check',
                    name: 'importBackofficeCustomers',
                    label: 'Importar novos clientes do Backoffice',
                    sm: 12,
                  },
                  {
                    empty: true,
                    text: '<h6>Selecionar dias em que os dados serão buscados:</h6>',
                    sm: 12,
                  },
                  {
                    type: 'check',
                    name: 'sunday',
                    label: 'Domingo',
                    sm: 1,
                  },
                  {
                    type: 'check',
                    name: 'monday',
                    label: 'Segunda',
                    sm: 1,
                  },
                  {
                    type: 'check',
                    name: 'tuesday',
                    label: 'Terça',
                    sm: 1,
                  },
                  {
                    type: 'check',
                    name: 'wednesday',
                    label: 'Quarta',
                    sm: 1,
                  },
                  {
                    type: 'check',
                    name: 'thursday',
                    label: 'Quinta',
                    sm: 1,
                  },
                  {
                    type: 'check',
                    name: 'friday',
                    label: 'Sexta',
                    sm: 1,
                  },
                  {
                    type: 'check',
                    name: 'saturday',
                    label: 'Sábado',
                    sm: 1,
                  },
                ]}
              />
            )}

            <DefaultForm
              loading={loading}
              disabled={loading}
              callbackSubmit={onSubmit}
              submitLabel={'Validar e salvar'}
              callbackReset={() => {}}
              inputs={[
                {
                  options: options,
                  type: 'select',
                  name: 'type',
                  label: 'Consolidadora',
                  placeholder: 'Selecione...',
                  required: true,
                  onChange: data => {
                    setIntegration(data.target.value.label)
                    onSubmitGetFields({
                      [data.target.name]: data.target.value,
                    })
                  },
                  sm: 12,
                },
              ].concat(
                fields.map(e => {
                  let required =
                    e.label == 'username' ||
                    e.label == 'password' ||
                    e.label == 'observation'
                      ? false
                      : true

                  if (integration.includes('(Wintour)')) {
                    required = false
                  }

                  return {
                    type: `${e.label == 'observation' ? 'textarea' : 'text'}`,
                    name: e.label,
                    label: `${
                      e.label == 'observation' ? 'Observação' : e.label
                    }`,
                    required: required,
                    sm: 12,
                  }
                }),
              )}
            />
          </>
        </PanelBody>
      </Panel>
    </>
  )
}
