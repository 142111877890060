import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import IconButton from '~/components/IconButton'
import { inputsDefaultForm } from './inputs'

export default function TravelersList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [traveler, setTraveler] = useState([])
  const [tagOptions, setTagOptions] = useState([])
  const [tableProps, setTableProps] = useState({
    page: 1,
    limit: 10,
    count: 0,
    filters: {},
  })

  useEffect(() => {
    onSubmit({}, 0, tableProps.limit)
    getOptionsTag()
  }, [])

  async function onSubmit(data, skip, take) {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
        {
          params: {
            fullName: data?.name ?? undefined,
            email: data?.email ?? undefined,
            phone: data?.phone ?? undefined,
            vip: data?.vip ?? false,
            skip: skip ?? 0,
            take: take ?? 10,
            orderByName: data?.order ? true : false,
            tag:
              String(data?.tag?.map(tag => tag.label)) == 'undefined'
                ? undefined
                : String(data?.tag?.map(tag => tag.label)),
          },
        },
      )

      console.log(data)

      setTableProps({
        ...tableProps,
        filters: data ?? {},
        page: response.data.page,
        count: response.data.count,
        limit: response.data.limit,
      })

      setTraveler(response.data.items)

      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  async function getOptionsTag() {
    try {
      setLoading(true)

      const response = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/tags`,
      )

      setTagOptions(response.data)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function onChangePage(e) {
    await onSubmit(
      tableProps.filters,
      tableProps.limit * (e - 1),
      tableProps.limit,
    )
  }

  async function onChangeLimit(e) {
    await onSubmit(tableProps.filters, 0, e)
  }

  return (
    <Container>
      <h1 className='page-header'>
        Viajantes
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Pesquisa de viajantes, editar e incluir documentos e criar ou vincular todo itinerário da viagem!'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader title={'Filtros'} noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={inputsDefaultForm(tagOptions)}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}>
          <LinkRegisterNewTraveler to='create'>
            Novo Viajante
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            travelerList={traveler}
            loading={loading}
            tableProps={tableProps}
            onChangePage={onChangePage}
            onChangeLimit={onChangeLimit}
            setTravelerList={setTraveler}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
