import { useState } from 'react'
import styled from 'styled-components'
import Controls from './Controls'
import FrontCover from './FrontCover'
import Main from './Main'

export default function Print({
  travel,
  onHandleCancelPrint,
  travelImage,
  partnerImage,
  agencyImage,
  nameAgency,
}) {
  const [isPrinting, setIsPrinting] = useState(false)

  const onHandlePrint = () => {
    setIsPrinting(true)

    setTimeout(() => {
      window.print()

      setIsPrinting(false)
    }, 500)
  }

  return (
    <Container>
      {!isPrinting && (
        <Controls
          onHandlePrint={onHandlePrint}
          onHandleBack={onHandleCancelPrint}
          nameAgency={nameAgency}
        />
      )}

      <Content>
        <FrontCover
          travel={travel}
          travelImage={travelImage}
          partnerImage={partnerImage}
          agencyImage={agencyImage}
          nameAgency={nameAgency}
        />

        <Main nameAgency={nameAgency} travel={travel} />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  * {
    box-sizing: border-box;
    font-family: ${props => props.theme.fontFamily};
  }

  width: 100%;
  min-width: 21cm;
  background-color: #ccc;
  color: #333;
  font-size: 19px;
  font-weight: 500;
  line-height: 1.4em;
  word-wrap: break-word;
  text-align: justify;

  @media print {
    @page {
      margin: 1.5cm 0;
    }

    body {
      margin: 2cm 0;
    }
  }

  .a4-height {
    min-height: 26.7cm;
    padding: 1.5cm 1.2cm;
  }

  .a4-height2 {
    min-height: 26.7cm;
    padding: 0cm 1.2cm;
  }

  .no-break {
    break-inside: avoid-page;
  }

  h1 {
    font-size: 2.2em;
    margin-bottom: 0.55em;
    font-weight: 700;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
    font-weight: 700;
  }

  h3 {
    font-size: 1.8em;
    margin-bottom: 0.45em;
    font-weight: 700;
  }

  h4 {
    font-size: 1.6em;
    margin-bottom: 0.4em;
    font-weight: 700;
  }

  h5 {
    font-size: 1.4em;
    margin-bottom: 0.35em;
    font-weight: 700;
  }

  h6 {
    font-size: 1.2em;
    margin-bottom: 0.3em;
    font-weight: 700;
  }

  b {
    font-weight: 700;
  }

  p {
    margin-bottom: 0;
  }
`

const Content = styled.div`
  background-color: #fff;
  margin: 0 auto;
  width: 21cm;
  font-size: 16px;
`
