export default function InputIcon({ icon, message }) {
  const inputIcon = icon ? icon : 'fa-image'
  const infoMessage = message ? message : 'Nenhuma imagem adicionada'
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <i style={{ fontSize: '50px' }} className={`fas ${inputIcon}`} />
      <p
        style={{
          fontSize: '18px',
        }}
      >
        {infoMessage}
      </p>
    </div>
  )
}
