/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import LoadingContainer from '../LoadingContainer'
import {
  getDateDiff,
  getDateDiffLabel,
  getDateFormated,
  isPastDate,
} from '~/functions/Date'

export default function Kanban({
  title,
  data = [],
  loading,
  callbackOnMove,
  callbackOnAdd,
  callbackOnDelete,
  callbackOnDetails,
  callbackOnClose,
  callbackOnProposal,
  callbackOnTraveler,
  showModal,
  modalContent,
  modalTitle,
  altTitle = 'Início do contato',
  altDateFormat = 'DD/MM/YYYY',
  showAltDateLabel = true,
  descCaractersLimit = 50,
  showX = true,
}) {
  const [stages, setStages] = useState(data)

  useEffect(() => setStages(data), [data])

  const onDragEnd = async droppedItem => {
    if (!droppedItem.destination) return

    try {
      var sourceStageChilds = [
        ...stages.find(x => x.key === droppedItem.source.droppableId).childs,
      ]

      const [droppedItemRemoved] = sourceStageChilds.splice(
        droppedItem.source.index,
        1,
      )

      const stagesAfterRemove = stages.map(e => {
        if (e.key === droppedItem.source.droppableId) {
          return {
            ...e,
            childs: sourceStageChilds,
          }
        }

        return e
      })

      var destinationStageChilds = [
        ...stagesAfterRemove.find(
          x => x.key === droppedItem.destination.droppableId,
        ).childs,
      ]

      destinationStageChilds.splice(
        droppedItem.destination.index,
        0,
        droppedItemRemoved,
      )

      const stagesAfterAdd = stagesAfterRemove.map(e => {
        if (e.key === droppedItem.destination.droppableId) {
          return {
            ...e,
            childs: destinationStageChilds,
          }
        }

        return e
      })

      setStages(stagesAfterAdd)

      await callbackOnMove({
        item: droppedItemRemoved,
        newStage: data.find(x => x.key === droppedItem.destination.droppableId),
        oldStage: data.find(x => x.key === droppedItem.source.droppableId),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Container>
        {title && <Title className='page-header'>{title}</Title>}

        <Content>
          <DragDropContext onDragEnd={onDragEnd}>
            {stages.map((e, i) => {
              return (
                <Stage key={i}>
                  <StageTitle>
                    <b>
                      {e.label} ({e.childs.length})
                    </b>

                    <StageAddButton
                      type={'button'}
                      onClick={() => callbackOnAdd({ item: e })}
                    >
                      <b>
                        <i className={'fas fa-plus'} />
                      </b>
                    </StageAddButton>
                  </StageTitle>

                  <StageContent>
                    <Droppable droppableId={`${e.key}`}>
                      {provider => (
                        <div
                          className={`${e.key}`}
                          {...provider.droppableProps}
                          ref={provider.innerRef}
                        >
                          {e.childs.map((x, y) => (
                            <Draggable
                              key={y}
                              draggableId={`${e.key}-${y}`}
                              index={y}
                            >
                              {providedD => (
                                <Item
                                  ref={providedD.innerRef}
                                  className={`${e.key}-${y}`}
                                  {...providedD.dragHandleProps}
                                  {...providedD.draggableProps}
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginBottom: '8px',
                                    }}
                                  >
                                    <b>{x.title}</b>

                                    {showX && (
                                      <i
                                        className='fas fa-times'
                                        onClick={() => callbackOnDelete(x)}
                                        style={{
                                          padding: '4px',
                                          cursor: 'pointer',
                                        }}
                                      />
                                    )}
                                  </div>

                                  {x.desc && (
                                    <span style={{ marginBottom: '8px' }}>
                                      {x.desc.substring(0, descCaractersLimit)}
                                      ...
                                    </span>
                                  )}

                                  {x.dateNextAction ? (
                                    <DateHighlight
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '4px',
                                        borderRadius: '4px',
                                        margin: 0,
                                        width: '100%',
                                        backgroundColor: x.colors,
                                      }}
                                    >
                                      <span>Data da próxima ação</span>

                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <b>
                                          {getDateFormated(
                                            x.dateNextAction,
                                            altDateFormat,
                                          )}
                                        </b>

                                        {showAltDateLabel &&
                                          isPastDate(x.dateNextAction) && (
                                            <span
                                              style={{ textAlign: 'right' }}
                                            >
                                              {getDateDiff(x.dateNextAction)
                                                .minutes > 1
                                                ? `há ${getDateDiffLabel(
                                                    x.dateNextAction,
                                                  )}`
                                                : 'agora'}
                                            </span>
                                          )}
                                      </div>

                                      <b>{x.descAlt}</b>
                                    </DateHighlight>
                                  ) : (
                                    x.date && (
                                      <DateHighlight
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          padding: '4px',
                                          borderRadius: '4px',
                                          margin: 0,
                                          width: '100%',
                                          backgroundColor: x.colors,
                                        }}
                                      >
                                        <span>{altTitle}</span>

                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <b>
                                            {getDateFormated(
                                              x.date,
                                              altDateFormat,
                                            )}
                                          </b>

                                          {showAltDateLabel &&
                                            isPastDate(x.date) && (
                                              <span
                                                style={{ textAlign: 'right' }}
                                              >
                                                {getDateDiff(x.date).minutes > 1
                                                  ? `há ${getDateDiffLabel(
                                                      x.date,
                                                    )}`
                                                  : 'agora'}
                                              </span>
                                            )}
                                        </div>

                                        <b>{x.descAlt}</b>
                                      </DateHighlight>
                                    )
                                  )}

                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: `${
                                        e.label == 'Qualificado' ||
                                        e.label == 'Venda Realizada'
                                          ? 'space-between'
                                          : 'flex-end'
                                      }`,
                                    }}
                                  >
                                    {e.label == 'Qualificado' && (
                                      <ProposalModalButton
                                        type='button'
                                        onClick={() => callbackOnProposal(x)}
                                      >
                                        <b>Proposta</b>
                                      </ProposalModalButton>
                                    )}

                                    {e.label == 'Venda Realizada' &&
                                      x.travelerId == 0 && (
                                        <TravelerModalButton
                                          type='button'
                                          onClick={() => callbackOnTraveler(x)}
                                        >
                                          {loading && (
                                            <div style={{ padding: '5px 0px 0px 15px', display: 'flex', justifyContent: 'center' }}>
                                              <LoadingContainer />
                                            </div>
                                          )}
                                          {!loading && <b>Viajante</b>}
                                        </TravelerModalButton>
                                      )}

                                    {e.label == 'Venda Realizada' &&
                                      x.travelerId != 0 && <p></p>}

                                    <ModalCloseButton
                                      type='button'
                                      onClick={() => callbackOnDetails(x)}
                                    >
                                      <b>Detalhes</b>
                                    </ModalCloseButton>
                                  </div>
                                </Item>
                              )}
                            </Draggable>
                          ))}

                          {provider.placeholder}

                          {e.childs.length === 0 && (
                            <span>Nenhum item adicionado</span>
                          )}
                        </div>
                      )}
                    </Droppable>
                  </StageContent>
                </Stage>
              )
            })}
          </DragDropContext>
        </Content>
      </Container>

      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner color={'#ccc'} size={'md'} />
        </div>
      )}

      {showModal && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 20,
              borderRadius: '8px',
              backgroundColor: '#fff',
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
              border: '1px solid #eee',
              width: '90%',
              minHeight: '120px',
              zIndex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
                background: 'rgba(0, 0, 0, 0.1)',
                borderRadius: '6px',
                padding: '8px',
              }}
            >
              <h5>{modalTitle}</h5>

              <DeleteButton onClick={callbackOnClose}>
                <i className={'fas fa-times'} />
              </DeleteButton>
            </div>
            <div
              style={{
                padding: '8px',
              }}
            >
              {modalContent}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const Container = styled.div`
  width: 100%;
`

const Title = styled.h1`
  margin-bottom: 0;
`

const Content = styled.ul`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 8px;
  overflow: auto;
  margin: 0 0 8px;
  padding: 8px;
  list-style: none;
`

const Stage = styled.li`
  min-width: 180px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 8px;
  border-radius: 8px;
  background-color: #eaeaea;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
`

const StageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 8px;
`

const StageContent = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
`

const Item = styled.li`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #eaeaea;

  &:hover {
    cursor: grab;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const StageAddButton = styled.button`
  padding: 8px;
  border-radius: 8px;
  background-color: #fff;
  border: none;
`

const ModalCloseButton = styled.button`
  padding: 8px;
  border-radius: 8px;
  border: none;
`

const ProposalModalButton = styled.button`
  padding: 8px;
  border-radius: 8px;
  border: none;
  color: #1bc25b;
  background-color: transparent;
`

const TravelerModalButton = styled.button`
  padding: 8px;
  border-radius: 8px;
  border: none;
  color: #1bc25b;
  background-color: transparent;
`

export const DeleteButton = styled.button`
  font-weight: bold;
  padding: 8px 10px;
  color: #ff7d70;
  background-color: transparent;
  border: none;
  font-size: 12px;

  &:hover {
    background-color: #00000011;
    text-decoration: none;
    border-radius: 4px;
  }
`

const DateHighlight = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.foregroundAlt};
`
