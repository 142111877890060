import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { useNavigate } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import { Container } from '~/styles/container'
import PageTitle from '~/components/PageTitle'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import { getDateFormated, getMoment } from '~/functions/Date'

export default function CreateEvent() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [dataOptions, setDataOptions] = useState([])

  useEffect(() => {
    requestApiOptions()
  }, [])

  const requestApiOptions = async () => {
    const { data } = await axiosApis.options(
      `${process.env.REACT_APP_ENDPOINT_APIS}/institution`,
    )

    setDataOptions(data)
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      if (data.image) formData.append('image', data.image)
      if (data.title) formData.append('title', data.title)
      if (data.destiny) formData.append('destiny', data.destiny)
      if (data.origin) formData.append('origin', data.origin)
      if (data.vacancies) formData.append('vacancies', data.vacancies)
      if (data.start) formData.append('start', data.start)
      if (data.end) formData.append('end', data.end)
      if (data.desc) formData.append('desc', data.desc)
      if (data.status) formData.append('status', data.status?.value)

      if (data.institutions) {
        data.institutions.map(e => formData.append('institutions', e.value))
      }

      if (data.type) {
        formData.append('type', data.type?.value)
      }

      if (data.periodInfo) formData.append('periodInfo', data.periodInfo)

      if (data.subscribeDateStart) {
        const subscribeStart = getMoment(
          data.subscribeDateStart + ' ' + data.subscribeTimeStart ?? '00:00',
        )

        formData.append(
          'subscribeStart',
          getDateFormated(subscribeStart, 'YYYY-MM-DD HH:mm'),
        )
      }

      if (data.subscribeDateEnd) {
        const subscribeEnd = getMoment(
          data.subscribeDateEnd + ' ' + data.subscribeTimeEnd ?? '23:59',
        )

        formData.append(
          'subscribeEnd',
          getDateFormated(subscribeEnd, 'YYYY-MM-DD HH:mm'),
        )
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/event`,
        formData,
      )

      toast.success('Evento criado com sucesso.')

      navigate(-1)

      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  async function onValidate(data) {
    if (
      (!data.subscribeDateStart && data.subscribeTimeStart) ||
      (!data.subscribeDateEnd && data.subscribeTimeEnd)
    ) {
      toast.info(
        'Revise os campos de Início e Fim das inscrições. Você pode deixar vazio, selecionar somente datas ou datas com horários.',
      )

      return false
    }

    return true
  }

  return (
    <Container>
      <PageTitle title='Cadastrar Novo Evento' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            onValidate={onValidate}
            loading={loading}
            inputs={[
              {
                type: 'image',
                name: 'image',
                label: 'Logo/Capa do evento',
                sm: 12,
              },
              {
                type: 'text',
                name: 'title',
                label: 'Nome do Evento',
                required: true,
                sm: 12,
                lg: 6,
              },
              {
                type: 'date',
                name: 'start',
                label: 'Início do evento',
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Fim do evento',
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'html',
                name: 'periodInfo',
                label: 'Informações sobre as datas, horários, grupos',
                sm: 12,
              },
              {
                type: 'text',
                name: 'origin',
                label: 'Origem',
                required: true,
                sm: 12,
                lg: 6,
              },
              {
                type: 'text',
                name: 'destiny',
                label: 'Destino',
                maxWidth: '300px',
                required: true,
                sm: 12,
                lg: 6,
              },
              {
                type: 'select',
                name: 'type',
                label: 'Tipo de evento',
                options: [
                  { label: 'Pedagógico', value: 'pedagogico' },
                  { label: 'Outros', value: 'outros' },
                  { label: 'Incentivo', value: 'incentivo' },
                ],
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'select',
                name: 'institutions',
                label: 'Grupos',
                isMulti: true,
                options: dataOptions,
                sm: 12,
                lg: 3,
              },
              {
                type: 'select',
                name: 'status',
                label: 'Status',
                options: [
                  { value: true, label: 'Ativo' },
                  { value: false, label: 'Inativo' },
                ],
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'number',
                name: 'vacancies',
                label: 'Número de Vagas',
                required: true,
                min: 0,
                sm: 12,
                lg: 3,
              },
              {
                type: 'date',
                name: 'subscribeDateStart',
                label: 'Início das incrições',
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'time',
                name: 'subscribeTimeStart',
                label: 'Horário de início das incrições',
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'date',
                name: 'subscribeDateEnd',
                label: 'Fim das incrições',
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'time',
                name: 'subscribeTimeEnd',
                label: 'Horário do fim das incrições',
                required: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'html',
                name: 'desc',
                label: 'Termos e Condições',
                sm: 12,
                lg: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
