import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
import useAxios from '~/hooks/useAxios'

export default function useHoldingConsolidatorServicesData({ filters }) {
  const { apiClient } = useAxios()
  const [loading, setLoading] = useState(false)
  const dashboardsPlaceholders = getDashboardPlaceholders(3)
  const [dashboards, setDashboards] = useState(dashboardsPlaceholders)
  const [chartData, setChartData] = useState()
  const [airTableData, setAirTableData] = useState([])
  const [hotelTableData, setHotelTableData] = useState([])
  const [busTableData, setBusTableData] = useState([])
  const [serviceTableData, setServiceTableData] = useState([])
  const [ticketTableData, setTicketTableData] = useState([])
  const [tourTableData, setTourTableData] = useState([])
  const [cruiseTableData, setCruiseTableData] = useState([])
  const [hotelPackageTableData, setHotelPackageTableData] = useState([])
  const [airHotelPackageTableData, setAirHotelPackageTableData] = useState([])

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const params = {
          branchs: filters?.branchs ? filters.branchs.map(x => x.value) : [],
          start: filters?.start ?? undefined,
          end: filters?.end ?? undefined,
        }

        const resDashboards = await apiClient.get(
          '/holdingconsolidator/services/dashboards',
          { params },
        )

        setDashboards(resDashboards.data)

        const resChartData = await apiClient.get(
          '/holdingconsolidator/services/chart',
          { params },
        )

        setChartData(resChartData.data)

        const resTableData = await apiClient.get(
          '/holdingconsolidator/services/table',
          { params },
        )

        setAirTableData(resTableData.data.airs)
        setHotelTableData(resTableData.data.hotels)
        setBusTableData(resTableData.data.bus)
        setServiceTableData(resTableData.data.services)
        setTicketTableData(resTableData.data.tickets)
        setTourTableData(resTableData.data.tours)
        setCruiseTableData(resTableData.data.cruises)
        setHotelPackageTableData(resTableData.data.hotelPackages)
        setAirHotelPackageTableData(resTableData.data.airHotelPackages)
      } catch (error) {
        toast.info('Dados não encontrados. Tente mais tarde!')
      } finally {
        setLoading(false)
      }
    }

    getSource()

    return () => {
      setDashboards(dashboardsPlaceholders)
      setChartData([])
      setAirTableData([])
      setHotelTableData([])
      setBusTableData([])
      setServiceTableData([])
      setTicketTableData([])
      setTourTableData([])
      setCruiseTableData([])
      setHotelPackageTableData([])
      setAirHotelPackageTableData([])
    }
  }, [filters])

  return {
    loading,
    dashboards,
    chartData,
    airTableData,
    hotelTableData,
    busTableData,
    serviceTableData,
    ticketTableData,
    tourTableData,
    cruiseTableData,
    hotelPackageTableData,
    airHotelPackageTableData,
  }
}
