import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useField } from '@unform/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {
  Label,
  Group,
  InputV,
  Disabled,
  Search,
  MessageError,
  MessageSuccess,
  Color,
  Buscar,
} from './styles'
import { FormatPhoneBR } from '~/functions/FormatPhone'

import { Spinner } from 'reactstrap'
import Tooltip from '~/components/Tooltip'

export default function Input({
  name,
  value,
  onChange,
  label,
  icon,
  disabledButton,
  searchButton,
  searchButtonLabel,
  searchButtonText,
  cancelButton,
  errorMessage,
  successMessage,
  colorSelected,
  isPhone,
  isPasswordForLogin,
  loading,
  callbackSearch = () => {},
  callbackCancel = () => {},
  callbackHidePassword = () => {},
  hidePass = true,
  searchIcon = 'fas fa-search',
  cancelIcon = 'fas fa-times',
  mask,
  infoText,
  ...rest
}) {
  const [disabled, setDisabled] = useState(true)
  const [searching, setSearching] = useState(true)
  const inputRef = useRef()
  const { fieldName, defaultValue, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  const handleDisabled = () => setDisabled(!disabled)

  const handleSearch = () => {
    setSearching(!searching)

    return callbackSearch({
      target: {
        name: fieldName,
        value: isPhone ? FormatPhoneBR(value) : value,
      },
    })
  }

  const handleCancel = () => callbackCancel()

  return (
    <Group>
      {label && (
        <Label htmlFor={fieldName}>
          {label}

          {icon && <i className={icon} />}

          {infoText && (
            <>
              &nbsp;
              <i
                id={'input-info-' + fieldName}
                className='fas fa-info-circle'
              />
              <Tooltip target={'input-info-' + fieldName} text={infoText} />
            </>
          )}
        </Label>
      )}

      {disabledButton && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputV
            id={fieldName}
            name={fieldName}
            className='form-control'
            ref={inputRef}
            disabled={disabledButton && disabled}
            value={isPhone ? FormatPhoneBR(value) : value}
            onChange={onChange}
            defaultValue={defaultValue}
            mask={mask}
            {...rest}
          />

          {disabledButton === true && (
            <Disabled onClick={handleDisabled} type='button'>
              {disabled && <i className='fas fa-lock' />}

              {!disabled && <i className='fas fa-lock-open' />}
            </Disabled>
          )}

          {searchButton === true && (
            <Search onClick={handleSearch} type='button'>
              {!loading && <i className={searchIcon} />}

              {loading && <Spinner color='#777' size='sm' />}
            </Search>
          )}

          {searchButtonLabel === true && (
            <Buscar
              onClick={handleSearch}
              type='button'
              style={{
                backgroundColor: '#000',
                color: '#fff',
              }}
            >
              {!loading && `${searchButtonText}`}

              {loading && <Spinner color='#777' size='sm' />}
            </Buscar>
          )}

          {cancelButton === true && (
            <Search onClick={handleCancel} type='button'>
              {!loading && <i className={cancelIcon} />}

              {loading && <Spinner color='#777' size='sm' />}
            </Search>
          )}

          {colorSelected && (
            <Color type='button' colorSelected={colorSelected} />
          )}
        </div>
      )}

      {!disabledButton && (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InputV
            id={fieldName}
            name={fieldName}
            className='form-control'
            ref={inputRef}
            disabled={disabledButton && disabled}
            value={isPhone ? FormatPhoneBR(value) : value}
            onChange={onChange}
            defaultValue={defaultValue}
            mask={mask}
            {...rest}
          />

          {disabledButton === true && (
            <Disabled onClick={handleDisabled} type='button'>
              {disabled && <i className='fas fa-lock' />}

              {!disabled && <i className='fas fa-lock-open' />}
            </Disabled>
          )}

          {searchButton === true && (
            <Search onClick={handleSearch} type='button'>
              {!loading && <i className={searchIcon} />}

              {loading && <Spinner color='#777' size='sm' />}
            </Search>
          )}

          {searchButtonLabel === true && (
            <Buscar
              onClick={handleSearch}
              type='button'
              style={{
                backgroundColor: '#000',
                color: '#fff',
              }}
            >
              {!loading && `${searchButtonText}`}

              {loading && <Spinner color='#777' size='sm' />}
            </Buscar>
          )}

          {cancelButton === true && (
            <Search onClick={handleCancel} type='button'>
              {!loading && <i className={cancelIcon} />}

              {loading && <Spinner color='#777' size='sm' />}
            </Search>
          )}

          {colorSelected && (
            <Color type='button' colorSelected={colorSelected} />
          )}

          {isPasswordForLogin && (
            <i
              onClick={callbackHidePassword}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                color: 'black',
                right: '8px',
              }}
            >
              <FontAwesomeIcon icon={hidePass ? faEyeSlash : faEye} />
            </i>
          )}
        </div>
      )}

      <MessageError>{errorMessage}</MessageError>

      <MessageSuccess>{successMessage}</MessageSuccess>
    </Group>
  )
}

Input.defaultProps = {
  label: null,
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  colorSelected: PropTypes.string,
  isPhone: PropTypes.bool,
}
