import { Outlet, Route } from 'react-router-dom'
import DefaultLayout from '~/pages/__layouts__/DefaultLayout'
import SalesBackofficeList from './List'

export default function SalesBackoffice() {
  return (
    <Route
      path='/sales-backoffice'
      element={
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      }
    >
      <Route index element={<SalesBackofficeList />} />
    </Route>
  )
}
