import ReactTable from '~/components/Table'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'

export default function TravelerTable({
  travelerList,
  loading,
  setLoading,
  travelId,
  requestTravelersInTravel,
}) {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()

  const DeleteTraveler = async travelerId => {
    try {
      setLoading(true)
      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/Travelers-in-Travel/${travelerId}/${travelId}`,
      )

      await requestTravelersInTravel()
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  async function changeMainTraveler(travelerId) {
    try {
      setLoading(true)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/${travelId}/traveler/${travelerId}`,
      )

      toast.success('Troca de viajante principal realizada com sucesso!')
      await requestTravelersInTravel()
    } catch (error) {
      setLoading(false)
      console.log(error)
      toast.error(
        'Não foi possível editar o viajante, por favor tente mais tarde.',
      )
    }
  }

  return (
    <ReactTable
      columns={[
        {
          Header: 'Nome Completo',
          accessor: 'name',
          width: 200,
        },
        {
          Header: 'E-mail',
          accessor: 'email',
          width: 250,
        },
        {
          Header: 'Telefone',
          accessor: 'phone',
          width: 120,
        },
        {
          Header: 'Ultimo acesso no app',
          accessor: 'lastAccessApp',
          width: 200,
        },
        {
          accessor: 'mTraveler',
          width: 20,
          Cell: props => {
            return (
              <>
                {props.row.original.mainTraveler ? (
                  <IconButton id={props.row.index} type={'mainTraveler'} />
                ) : (
                  <IconButton
                    id={props.row.index}
                    type={'noMainTraveler'}
                    onClick={() => changeMainTraveler(props.row.original.id)}
                  />
                )}
              </>
            )
          },
        },
        {
          Header: '',
          accessor: 'details',
          Cell: props => {
            return (
              <IconButton
                id={props.row.index}
                type={'details'}
                onClick={() =>
                  navigate('details', {
                    state: {
                      userId: props.row.original.userId,
                      id: props.row.original.id,
                    },
                  })
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <>
                {props.row.original.mainTraveler ? (
                  <IconButton
                    id={props.row.index}
                    type={'noRemove'}
                    tooltipOverride={
                      'O viajante principal não pode ser removido.'
                    }
                  />
                ) : (
                  <IconButton
                    id={props.row.index}
                    type={'remove'}
                    onClick={() => DeleteTraveler(props.row.original.id)}
                  />
                )}
              </>
            )
          },
          width: 20,
        },
      ]}
      data={travelerList}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
