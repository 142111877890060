import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { toast } from 'react-toastify'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'

export default function Details() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()

  useEffect(() => {
    getHotelDetails()
  }, [])

  const getHotelDetails = async () => {
    try {
      setLoading(true)

      const response = await axiosApis.get(`/hotelLibrary/${location.state.id}`)

      setData({
        ...response?.data,
        image1: response?.data?.image1?.file,
        image2: response?.data?.image2?.file,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmit = async data => {
    try {
      const formData = new FormData()

      formData.append('id', data.id)
      formData.append('name', data.name)
      formData.append('desc', data.desc != null ? data.desc : '')
      formData.append(
        'fullAddress',
        data.fullAddress != null ? data.fullAddress : '',
      )
      formData.append(
        'originPurchase',
        data.originPurchase != null ? data.originPurchase : '',
      )
      formData.append(
        'descCheckOut',
        data.descCheckOut != null ? data.descCheckOut : '',
      )
      formData.append(
        'importantInformation',
        data.importantInformation != null ? data.importantInformation : '',
      )
      formData.append('value', data.value != null ? data.value : 0)
      formData.append('image1', data.image1)
      formData.append('image2', data.image2)

      formData.append('city', data.city?.label)
      formData.append('cityId', data.city?.value)

      await axiosApis.put(`hotelLibrary`, formData)

      toast.success('Hotel editado com sucesso!')
      navigate('/library/hotel')
    } catch (error) {
      toast.error('Não foi possível editar o hotel, por favor tente mais tarde')
    }
  }

  const onDelete = async () => {
    if (window.confirm('Você tem certeza que deseja remover esse hotel?')) {
      try {
        await axiosApis.delete(`/hotelLibrary/${location.state.id}/${false}`)

        toast.success('Hotel removido com sucesso!')
        navigate('/library/hotel')
      } catch (error) {
        toast.info(
          'Não foi possível remover o hotel, por favor entre me contato com o suporte.',
        )
      }
    }
  }

  return (
    <div>
      <PageTitle title={data ? data.name : ''} />

      <Panel>
        <PanelHeader title={'Editar'} noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackDelete={onDelete}
            defaultValue={data}
            loading={loading}
            disabled={loading}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Nome',
                required: true,
                sm: 12,
                md: 12,
                lg: 3,
                xl: 3,
              },
              {
                type: 'city',
                name: 'city',
                label: 'Cidade',
                placeholder: 'Pesquise por uma cidade...',
                required: true,
                sm: 12,
                md: 12,
                lg: 2,
                xl: 2,
              },
              {
                type: 'text',
                name: 'fullAddress',
                label: 'Endereço',
                required: true,
                sm: 12,
                md: 12,
                lg: 3,
                xl: 3,
              },
              {
                type: 'number',
                name: 'value',
                label: 'Valor',
                min: 0,
                sm: 12,
                md: 12,
                lg: 2,
                xl: 2,
              },
              {
                type: 'text',
                name: 'originPurchase',
                label: 'Fornecedor',
                sm: 12,
                md: 12,
                lg: 2,
                xl: 2,
              },
              {
                type: 'textarea',
                name: 'desc',
                label: 'Descrição do check-in',
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'descCheckOut',
                label: 'Descrição do check-out',
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'importantInformation',
                label: 'Informações importantes',
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                type: 'image',
                name: 'image1',
                label: 'Imagem',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'image',
                name: 'image2',
                label: 'Imagem',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}
