import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
  return (
    <div className='error'>
      <div className='error-code m-b-10'>404</div>
      <div className='error-content'>
        <div className='error-message'>Nós não encontramos nada</div>
        <div className='error-desc m-b-30'>
          O que você está tentando acessar não existe. <br />
          Clique no botão abaixo para voltar a sua navegação.
        </div>
        <div>
          <Link to='/dashboard' className='btn btn-success p-l-20 p-r-20'>
            Voltar a navegação
          </Link>
        </div>
      </div>
    </div>
  )
}
