import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { GetAxios } from '~/services/api'
import moment from 'moment'
import Preview from './Components/Preview'
import LoadingContainer from '~/components/LoadingContainer'
import Download from './Components/Download'
import { getDateFormated } from '~/functions/Date'
import Buttons from './Components/Buttons'
import styled from 'styled-components'
import useTheme from '~/hooks/useTheme'

export default function ProposalPdf() {
  const params = useParams()
  const { axiosApis } = GetAxios()
  const [proposalDetails, setProposalDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [fullWhite, setFullWhite] = useState(false)
  const [hideCover, setHideCover] = useState(false)
  const [withoutPadding, setWithoutPadding] = useState(false)
  const [isMobile] = useState(() => {
    if (
      navigator.userAgent.match(/Android/i) != null ||
      navigator.userAgent.match(/iPhone/i) != null
    ) {
      return true
    } else {
      return false
    }
  })
  const [showPrint, setShowPrint] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const { updateFont } = useTheme()

  console.log(proposalDetails)

  useEffect(() => {
    getTravelDestinyFromRoute()
  }, [])

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault()

        setShowPrint(true)
        setShowButtons(true)
        setWithoutPadding(true)
        setHideCover(true)

        setTimeout(() => {
          window.print()
          setShowButtons(false)
          setHideCover(false)
          setWithoutPadding(false)

          console.log('entrou no false')
        }, 500)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  async function getTravelDestinyFromRoute() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/pdf/${params.token}`,
      )

      const proposalDateFormated = {
        ...data,
        dateInitial: moment(data.dateInitial).format('LL'),
        dateFinal: moment(data.dateFinal).format('LL'),
        createdAt: getDateFormated(data.createdAt, 'DD/MM/YYYY'),
      }

      setProposalDetails(proposalDateFormated)

      if (data.fontFamily && data.fontFamilyUrl) {
        await updateFont(data.fontFamily, data.fontFamilyUrl)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handlePrint = () => setShowPrint(!showPrint)

  const handleDownload = () => {
    setShowButtons(true)
    setFullWhite(true)
    setWithoutPadding(true)

    setTimeout(() => {
      window.print()
      setShowButtons(false)
      setFullWhite(false)
      setWithoutPadding(false)
    }, 500)
  }

  return (
    <Container>
      {loading && <LoadingContainer height='100vh' />}

      {!loading && (
        <div>
          {!showPrint && (
            <Preview
              id={proposalDetails?.id}
              createdAt={proposalDetails?.createdAt}
              agency={proposalDetails?.agencyImage}
              primaryColor={proposalDetails?.primaryColor}
              secondaryColor={proposalDetails?.secondaryColor}
              title={proposalDetails.title}
              dateInitial={proposalDetails.dateInitial}
              dateFinal={proposalDetails.dateFinal}
              image={proposalDetails.image}
              image2={proposalDetails.image2}
              description={proposalDetails.description}
              observation={proposalDetails.observation}
              included={proposalDetails.included}
              notIncluded={proposalDetails.notIncluded}
              alter={proposalDetails.alter}
              cancel={proposalDetails.cancel}
              reservation={proposalDetails.reservation}
              countryExi={proposalDetails.countryExi}
              allValues={proposalDetails.allValues}
              showTotalValue={proposalDetails.showTotalValue}
              dolar={proposalDetails.dolar}
              fullValue={proposalDetails.fullValue}
              proposalFlights={proposalDetails.proposalFlights}
              proposalNauticals={proposalDetails.proposalNauticals}
              proposalTrains={proposalDetails.proposalTrains}
              proposalTransfers={proposalDetails.proposalTransfers}
              proposalHotels={proposalDetails.proposalHotels}
              proposalActivitys={proposalDetails.proposalActivitys}
              proposalInsurances={proposalDetails.proposalInsurances}
              proposalDestinys={proposalDetails.proposalDestinys}
              proposalTickets={proposalDetails.proposalTickets}
              proposalVehicleRentals={proposalDetails.proposalVehicleRentals}
              proposalOtherServices={proposalDetails.proposalOtherServices}
              totalValue={proposalDetails.totalValue}
              agentName={proposalDetails.agentName}
              agentPhone={proposalDetails.agentPhone}
              agentEmail={proposalDetails.agentEmail}
              agencyPhone={proposalDetails.agencyPhone}
              isMobile={isMobile}
              handlePrint={handlePrint}
              handleDownload={handleDownload}
            />
          )}
          {showPrint && (
            <div>
              {!showButtons && (
                <Buttons
                  primaryColor={proposalDetails?.primaryColor}
                  handlePrint={handlePrint}
                  handleDownload={handleDownload}
                />
              )}
              <Download
                id={proposalDetails?.id}
                fullWhite={fullWhite}
                withoutPadding={withoutPadding}
                createdAt={proposalDetails?.createdAt}
                agency={proposalDetails?.agencyImage}
                primaryColor={proposalDetails?.primaryColor}
                secondaryColor={proposalDetails?.secondaryColor}
                title={proposalDetails.title}
                dateInitial={proposalDetails.dateInitial}
                dateFinal={proposalDetails.dateFinal}
                image={proposalDetails.image}
                image2={proposalDetails.image2}
                description={proposalDetails.description}
                observation={proposalDetails.observation}
                included={proposalDetails.included}
                notIncluded={proposalDetails.notIncluded}
                alter={proposalDetails.alter}
                cancel={proposalDetails.cancel}
                reservation={proposalDetails.reservation}
                countryExi={proposalDetails.countryExi}
                allValues={proposalDetails.allValues}
                showTotalValue={proposalDetails.showTotalValue}
                dolar={proposalDetails.dolar}
                fullValue={proposalDetails.fullValue}
                proposalFlights={proposalDetails.proposalFlights}
                proposalNauticals={proposalDetails.proposalNauticals}
                proposalTrains={proposalDetails.proposalTrains}
                proposalTransfers={proposalDetails.proposalTransfers}
                proposalHotels={proposalDetails.proposalHotels}
                proposalActivitys={proposalDetails.proposalActivitys}
                proposalInsurances={proposalDetails.proposalInsurances}
                proposalDestinys={proposalDetails.proposalDestinys}
                proposalOtherServices={proposalDetails.proposalOtherServices}
                proposalTickets={proposalDetails.proposalTickets}
                proposalVehicleRentals={proposalDetails.proposalVehicleRentals}
                totalValue={proposalDetails.totalValue}
                agentName={proposalDetails.agentName}
                agentPhone={proposalDetails.agentPhone}
                agentEmail={proposalDetails.agentEmail}
                agencyPhone={proposalDetails.agencyPhone}
                hideCover={hideCover}
              />
            </div>
          )}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  background: #ffffff;

  * {
    font-family: ${props => props.theme.fontFamily};
  }
`
