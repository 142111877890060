import styled from 'styled-components'

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #bdbfbf;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
`

export const TextAvatar = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 30px;
  color: #fff;
  margin-top: 15px;
`

export const TextEmail = styled.p`
  font-size: 13px;
  margin: 0px 0px 0px 0px;
  text-align: center;
`

export const TextUser = styled.p`
  font-size: 14px;
  margin: 5px 0px 0px 0px;
  text-align: center;
  font-weight: 600;
`

export const AgencyAdmin = styled.div`
  width: 60%;
  margin: 10px auto 0;
  padding: 4px 0;
  text-align: center;
  border: 1px solid #5f5f5f;
  border-radius: 8px;
  background: none;

  a,
  a:hover,
  a:focus {
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    color: #333;
  }
`
