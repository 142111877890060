import styled from 'styled-components'
import { Col } from 'reactstrap'

export const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const TravelDetailColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const TravelDetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`

export const TravelDetailBlock = styled.div`
  flex: 1;
  background-color: #f4f4f4;
  padding: 8px;
  width: 100%;
  height: 100%;
`
