import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { toast } from 'react-toastify'
import {
  getDateDiff,
  getDateFormated,
  getDateTimeFormated,
  getMoment,
} from '~/functions/Date'
import { RegisterNewButton2 } from '~/components/Button/styles'
import IconButton from '~/components/IconButton'

export default function ReportCheckInList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [filters, setFilters] = useState({
    consolidators: [],
    providers: [],
    issuers: [],
    saleTypes: [],
    states: [],
  })
  const [dataForExport, setDataForExport] = useState([])

  const [defaultValueFilters, setDefaultValueFilters] = useState({
    start: getDateFormated(getMoment(), 'YYYY-MM-DD'),
    end: getDateFormated(getMoment().add(+3, 'day'), 'YYYY-MM-DD'),
  })

  useEffect(() => {
    getSource()
  }, [])

  async function getSource() {
    try {
      setLoading(true)

      const responseFilters = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/sales/filters/issuers`,
      )

      setFilters(responseFilters.data)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/sales/checkin`,
        {
          params: defaultValueFilters,
        },
      )

      setData(response.data)

      const responseGetDataExport = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/sales/checkin/data-for-export`,
        {
          params: defaultValueFilters,
        },
      )

      setDataForExport(responseGetDataExport.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/sales/checkin`,
        {
          params: {
            start: data?.start,
            end: data?.end,
            locator: data?.locator,
            consolidator: data.consolidator
              ? data.consolidator.value
              : undefined,
            saleType: data.saleType ? data.saleType.value : undefined,
            provider: data.provider ? data.provider.value : undefined,
            issuer: data.issuer ? data.issuer.value : undefined,
            state: data.state ? data.state.value : undefined,
          },
        },
      )

      setData(response.data)

      const responseGetDataExport = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/sales/checkin/data-for-export`,
        {
          params: {
            start: data?.start,
            end: data?.end,
            locator: data?.locator,
            consolidator: data.consolidator
              ? data.consolidator.value
              : undefined,
            saleType: data.saleType ? data.saleType.value : undefined,
            provider: data.provider ? data.provider.value : undefined,
            issuer: data.issuer ? data.issuer.value : undefined,
            state: data.state ? data.state.value : undefined,
          },
        },
      )

      setDataForExport(responseGetDataExport.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const saveAsExcelFile = (buffer, fileName) => {
    const fileSaver = import('file-saver')

    fileSaver.then(module => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        let EXCEL_EXTENSION = '.xlsx'
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        })

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION,
        )
      }
    })
  }

  const exportExcel = () => {
    const xlsx = import('xlsx')

    xlsx.then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(dataForExport)
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      })

      saveAsExcelFile(excelBuffer, 'checkin')
    })
  }

  const onValidate = data => {
    if ((data.start && !data.end) || (!data.start && data.end)) {
      toast.info('Preencha os campos Data Inicial e Data Final.')

      return false
    }

    if (getMoment(data.start) > getMoment(data.end)) {
      toast.info('A Data Final precisa ser maior que a Data Inicial.')

      return false
    }

    const diff = getDateDiff(data.start, data.end)

    if (diff.days > 180) {
      toast.info('Informe um período de no máximo 6 meses.')

      return false
    }

    if (
      !data.start &&
      !data.end &&
      !data.locator &&
      !data.state &&
      !data.saleType &&
      !data.consolidator &&
      !data.provider &&
      !data.issuer
    ) {
      toast.info('Preencha os filtros!')

      return false
    }

    return true
  }

  return (
    <Container>
      <h1 className='page-header'>
        Relatório de Check In
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'A Tela apresenta todos os Checkins a serem realizados do dia de hoje até 3 dias, o objetivo é facilitar com as informações para a realização dos Checkin nas CIA aéreas, com base nas integrações caso a agencia não tenha integração é puxado as informações dos aéreos cadastrados.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={() => {
              setDefaultValueFilters(undefined)

              setData([])

              return
            }}
            submitLabel={'Pesquisar'}
            defaultValue={defaultValueFilters}
            disabled={loading}
            inputs={[
              {
                type: 'date',
                name: 'start',
                label: 'Data inicial',
                sm: 12,
                lg: 3,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Data final',
                sm: 12,
                lg: 3,
              },
              {
                type: 'text',
                name: 'locator',
                label: 'Localizador',
                placeholder: 'Localizador',
                sm: 12,
                lg: 3,
              },
              {
                type: 'select',
                name: 'issuer',
                label: 'Emissor/Consultor',
                placeholder: 'Emissor/Consultor',
                options: filters.issuers,
                isClearable: true,
                sm: 12,
                lg: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton>
          {dataForExport.length > 0 && (
            <RegisterNewButton2
              onClick={() => exportExcel()}
              disabled={loading}
            >
              Exportar
            </RegisterNewButton2>
          )}
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Embarque',
                accessor: 'checkIn',
                width: 150,
                Cell: props => {
                  if (!props.row.original.checkIn) {
                    return null
                  }

                  return getDateTimeFormated(
                    props.row.original.checkIn,
                    'DD/MM/YYYY HH:mm',
                  )
                },
              },
              {
                Header: 'Passageiros',
                accessor: 'pax',
                width: 200,
                Cell: props => {
                  return (
                    <>
                      <span>{props.row.original.pax}</span>

                      {props.row.original.paxCount > 1 && (
                        <>
                          <br />

                          <b>+ {props.row.original.paxCount}</b>
                        </>
                      )}
                    </>
                  )
                },
              },
              {
                Header: 'Consolidadora',
                accessor: 'consolidator',
                width: 110,
                Cell: props => {
                  return props.row.original.consolidator
                },
              },
              {
                Header: 'Localizador Cons.',
                accessor: 'locatorCons',
                width: 130,
              },
              {
                Header: 'Localizador',
                accessor: 'locator',
                width: 90,
              },
              {
                Header: 'Cia Aérea',
                accessor: 'airlineCode',
                width: 110,
              },
              {
                Header: 'Origem',
                accessor: 'departureAirportCode',
                width: 60,
              },
              {
                Header: 'Destino',
                accessor: 'arrivalAirportCode',
                width: 60,
              },
              {
                Header: 'Emissor/Consultor',
                accessor: 'issuerName',
                width: 200,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
