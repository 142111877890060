export const defaultTableProps = {
  resizable: true,
  hasPagination: true,
  hasFooter: false,
  defaultPageSize: 10,
  loading: false,
  noOverflow: false,
}

export const defaultTableLabels = {
  noDataText: 'Sem registros',
  loadingText: 'Carregando...',
  nextButtonText: 'Próximo',
  previousButtonText: 'Anterior',
  pageText: 'Página',
  pageOfText: 'de',
  pageSizeSelectText: 'registros',
}

export const defaultTableStyle = {
  fontSize: '13px',
  cellPadding: '4px',
}
