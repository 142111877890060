import PageTitle from '~/components/PageTitle'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import DivForHTML from '~/components/DivForHTML'
import styled from 'styled-components'

export default function Details() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [entityDetails, setEntityDetails] = useState()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/getbyid/${location.state?.code}`,
        )

        setEntityDetails(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        return navigate(-1)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <Container>
      <PageTitle title={entityDetails?.title ?? 'Experiência'} head={'h5'} />

      <div style={{ background: '#f4f4f4', padding: '8px' }}>
        {entityDetails && !loading && (
          <div>
            <Row style={{ width: '100%' }}>
              <Col sm={12} lg={6}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    marginBottom: '16px',
                  }}
                >
                  {entityDetails.city && (
                    <span style={{ margin: '0 4px 4px 0' }}>
                      Cidade:{' '}
                      <LabelHighlight>{entityDetails.city}</LabelHighlight>
                    </span>
                  )}

                  {entityDetails.flag && (
                    <span style={{ margin: '0 4px 4px 0' }}>
                      Tags:{' '}
                      <LabelHighlight>{entityDetails.flag}</LabelHighlight>
                    </span>
                  )}
                </div>

                <DivForHTML text={entityDetails.desc} />
              </Col>

              <Col sm={12} lg={6}>
                {entityDetails.image && (
                  <img
                    style={{
                      width: '100%',
                      borderRadius: '6px',
                      marginBottom: '16px',
                    }}
                    src={`data:image/*;base64,${entityDetails.image}`}
                    alt='imagem'
                    onError={event => (event.target.style.display = 'none')}
                  />
                )}

                {!entityDetails.image && (
                  <div
                    style={{
                      width: '100%',
                      fontSize: '18px',
                      textAlign: 'center',
                    }}
                  >
                    <i
                      style={{
                        fontSize: '50px',
                      }}
                      className='fas fa-image'
                    />
                    <p>Nenhuma imagem adicionada</p>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Container>
  )
}

const LabelHighlight = styled.b`
  color: ${props => props.theme.colors.primary};
`
