import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { toast } from 'react-toastify'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'

export default function Details() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [travelDestinyToEdit, setTravelDestinyToEdit] = useState()

  useEffect(() => {
    getTravelDestinyFromRoute()
  }, [])

  async function getTravelDestinyFromRoute() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperiencedestiny/${location.state.id}`,
      )

      setTravelDestinyToEdit({
        ...data,
        city: data.citys,
      })

      return setLoading(false)
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }

  async function onValidate(data) {
    if (data && data.city && data.city.length === 0) {
      toast.info('Preencha um campo Cidade.')

      return false
    }

    return true
  }

  async function handleSubmitEdit(data) {
    try {
      const formData = new FormData()

      formData.append('id', travelDestinyToEdit.id)
      formData.append('title', data.title)
      formData.append('desc', data.desc)

      const cityIds = data.city.reduce((acc, obj) => {
        acc.push(obj.value)

        return acc
      }, [])

      formData.append('cityId', cityIds.join(','))

      if (data.image) formData.append('image', data.image)
      if (data.flag) formData.append('flag', data.flag)
      if (data.tips) formData.append('tips', data.tips)

      if (data.entryAndHabit)
        formData.append('entryAndHabit', data.entryAndHabit)
      if (data.copyright) formData.append('copyright', data.copyright)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperiencedestiny`,
        formData,
      )

      toast.success('Destino editado com sucesso!')

      navigate(-1)
    } catch (error) {
      return toast.error(
        'Não foi possível editar o destino, por favor tente mais tarde',
      )
    }
  }

  async function handleDelete() {
    try {
      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperiencedestiny/${travelDestinyToEdit.id}`,
      )

      toast.success(`Destino excluído com sucesso!`)

      navigate('/destinys')
    } catch (error) {
      return toast.error(
        'Não foi possível excluir o destino, por favor tente mais tarde.',
      )
    }
  }

  const OnDeleteclick = () => {
    if (window.confirm('Você tem certeza que deseja excluir esse destino?')) {
      handleDelete()
    }
  }

  return (
    <div>
      <PageTitle title={travelDestinyToEdit ? travelDestinyToEdit.title : ''} />

      <Panel>
        <PanelHeader title={'Editar'} noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={handleSubmitEdit}
            onValidate={onValidate}
            callbackCancel={() => navigate(-1)}
            callbackDelete={OnDeleteclick}
            defaultValue={travelDestinyToEdit}
            loading={loading}
            disabled={loading}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Título',
                required: true,
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'city',
                name: 'city',
                label: 'Cidade',
                placeholder: 'Pesquise por uma cidade...',
                required: true,
                isMulti: true,
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'flag',
                label: 'Tags',
                required: false,
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'html',
                name: 'desc',
                label: 'Descrição',
                required: false,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'image',
                name: 'image',
                label: 'Imagem',
                required: false,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'html',
                name: 'entryAndHabit',
                label: 'Entrada no país e costumes',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'html',
                name: 'tips',
                label: 'Dicas e informações',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'check',
                name: 'copyright',
                label: 'Imagem autoral',
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}
