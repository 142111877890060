import { Navigate, Route, Routes } from 'react-router-dom'
import BaseRoutes from '~/routes/base'
import About from '~/pages/About'
import Home from './pages/Home'
import HoldingBackofficeProposals from './pages/Proposals'
import HoldingBackofficeSales from './pages/Sales'
import HoldingHeader from './components/Header'
import useHoldingBackofficeData from './dataHooks/useHoldingBackoffice'
import HoldingBackofficeCustomers from './pages/Customers'
import HoldingBackofficeLayout from '~/pages/__layouts__/HoldingBackofficeLayout'
import LoadingContainer from '~/components/LoadingContainer'

export default function HoldingBackofficeModule() {
  const {
    loading,
    branchs,
    branchsOptions,
    filters,
    changeFilters,
    resetFilters,
  } = useHoldingBackofficeData()

  const Header = ({ title }) => {
    return (
      <HoldingHeader
        title={title}
        filters={filters}
        onFilter={changeFilters}
        onReset={resetFilters}
        branchsOptions={branchsOptions}
      />
    )
  }

  return (
    <>
      {loading && <LoadingContainer size='sm' color='#ccc' height='100vh' />}

      {!loading && (
        <Routes>
          {BaseRoutes()}

          <Route
            path={'/home'}
            exact
            element={
              <HoldingBackofficeLayout>
                <Header title={'Home'} />

                <Home filters={filters} branchs={branchs} />
              </HoldingBackofficeLayout>
            }
          />

          <Route
            path={'/customers'}
            exact
            element={
              <HoldingBackofficeLayout>
                <Header title='Clientes' />

                <HoldingBackofficeCustomers filters={filters} />
              </HoldingBackofficeLayout>
            }
          />

          <Route
            path={'/sales'}
            exact
            element={
              <HoldingBackofficeLayout>
                <Header title='Vendas' />

                <HoldingBackofficeSales filters={filters} />
              </HoldingBackofficeLayout>
            }
          />

          <Route
            path={'/proposals'}
            exact
            element={
              <HoldingBackofficeLayout>
                <Header title='Propostas' />

                <HoldingBackofficeProposals filters={filters} />
              </HoldingBackofficeLayout>
            }
          />

          <Route
            path='/about'
            exact
            element={
              <HoldingBackofficeLayout>
                <About />
              </HoldingBackofficeLayout>
            }
          />

          <Route path='*' element={<Navigate to={'/home'} replace />} />
        </Routes>
      )}
    </>
  )
}
