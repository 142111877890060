import styled from 'styled-components'
import Divisor from '~/components/Divisor'
import { FormatValueBRL } from '~/functions/FormatValue'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsTable from '~/components/Analytics/Table'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import useHoldingBackofficeSalesData from '../../dataHooks/useHoldingBackofficeSales'
import PageTitle from '~/components/PageTitle'
import {
  billingPerConsultant,
  billingPerProduct,
  billingPerSupplier,
} from './inputs.ts'

export default function HoldingBackofficeSales({ filters, handleProfile }) {
  const {
    loading,
    dashboards,
    chartData,
    tableData,
    servicesDashboards,
    tableProductsData,
    tableProvidersData,
    tableProvidersByBranchData,
  } = useHoldingBackofficeSalesData({ filters, handleProfile })

  return (
    <Container>
      <AnalyticsDashboard data={dashboards} columnSizingPerRow={'33.33%'} />

      <Divisor margin='10px' />

      <AnalyticsContainer title='Vendas emitidas'>
        <AnalyticsLineChart data={chartData} currency />
      </AnalyticsContainer>

      <Divisor margin='10px' />

      <Content>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <i className='fas fa-angle-double-right'></i>
          <PageTitle title={'Tipos de serviço'} head='h6' noButton noMargin />
        </div>
      </Content>

      <Divisor margin='10px' />

      <AnalyticsDashboard
        data={servicesDashboards}
        columnSizingPerRow={'33.33%'}
      />

      <Divisor margin='10px' />

      <Content>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <i className='fas fa-angle-double-right'></i>
          <PageTitle
            title={'Detalhamento do faturamento'}
            head='h6'
            noButton
            noMargin
          />
        </div>
      </Content>

      <Divisor margin='10px' />

      <AnalyticsTable
        title='Faturamento por consultor'
        data={tableData}
        loading={loading}
        columns={billingPerConsultant(FormatValueBRL, handleProfile)}
      />

      <Divisor margin='10px' />

      <AnalyticsTable
        title='Faturamento por produto'
        data={tableProductsData}
        loading={loading}
        columns={billingPerProduct(FormatValueBRL, handleProfile)}
      />

      <Divisor margin='10px' />

      <AnalyticsTable
        title='Faturamento por fornecedor'
        data={tableProvidersData}
        loading={loading}
        columns={billingPerSupplier(FormatValueBRL, handleProfile)}
      />

      <Divisor margin='10px' />

      {handleProfile == false && (
        <AnalyticsTable
          title='Fornecedores por filial'
          data={tableProvidersByBranchData}
          loading={loading}
          columns={[
            {
              Header: 'Franqueado',
              accessor: 'sublabel',
              width: 300,
            },
            {
              Header: 'Quantidade de fornecedores',
              accessor: 'count',
              width: 300,
            },
            {
              Header: 'Faturamento',
              accessor: 'sum',
              width: 150,
              Cell: props => {
                return FormatValueBRL(props.row.original.sum)
              },
            },
          ]}
        />
      )}
    </Container>
  )
}

const Container = styled.div``

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px 8px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    gap: 16px;
  }
`
