import styled from 'styled-components'
import { getDateDDMMM, getDateDDMMMFullName } from '~/functions/Date'
import ItineraryList from '~/pages/TravelResume/components/ItineraryList'
import { Divider } from '~/styles/divs'
import BannerTitle from './BannerTitle'
import DocsList from './DocsList'
import TravelMapDefault from '~/components/TravelMapDefault'
import DivForHTML from '~/components/DivForHTML'
import DestinysList from '../../components/DestinysList'
// import ExperiencesList from '../../components/ExperiencesList'
// import RestaurantsList from '../../components/RestaurantsList'
import SkeletonLine from '~/components/Skeleton'
import SkeletonCardActivities from '../../components/SkeletonCardActivities'
import SkeletonTravelResume from '../../components/SkeletonTravelResume'

export default function Main({
  travel,
  travelImage,
  token,
  center,
  markers,
  routes,
  nameAgency,
  travelResume,
}) {
  return (
    <>
      <Container>
        {!travelImage && <SkeletonLine width={'100%'} height={'360px'} />}
        {travelImage && (
          <BannerTitle bg={`data:image/*;base64,${travelImage}`}>
            <TravelTitle>
              {!nameAgency && <SkeletonLine />}

              {nameAgency?.name == 'Wee Travel' ? (
                <div style={{ maxWidth: '100%' }}>
                  <DateWee style={{ maxWidth: '100%' }}>
                    {getDateDDMMM(travel.start)} a {getDateDDMMM(travel.end)}
                  </DateWee>
                  {!travel && <SkeletonLine />}
                  {travel && <h3>{travel.title}</h3>}
                </div>
              ) : (
                <div style={{ maxWidth: '100%' }}>
                  {!travel && <SkeletonLine count={3} />}
                  {travel && (
                    <>
                      <h1>{travel.title}</h1>

                      <Date>
                        {getDateDDMMMFullName(travel.start).toUpperCase()} A{' '}
                        {getDateDDMMMFullName(travel.end).toUpperCase()} <br />
                      </Date>
                    </>
                  )}
                </div>
              )}
            </TravelTitle>
          </BannerTitle>
        )}

        <Divider />

        {!travelResume && <SkeletonTravelResume />}
        {travelResume && <DivForHTML text={travelResume} />}

        <Divider />

        {(!markers || !travel || !token) && (
          <SkeletonLine height={'598px'} width={'100%'} />
        )}

        {(!nameAgency || !travel) && (
          <>
            <SkeletonCardActivities />
            <SkeletonCardActivities />
            <SkeletonCardActivities />
          </>
        )}

        <SectionMain
          style={{
            height: '600px',
          }}
        >
          {markers && travel && token && (
            <TravelMapDefault
              token={token}
              travel={travel}
              markers={markers}
              routes={routes}
              center={center}
            />
          )}
        </SectionMain>

        <Divider />

        <SectionMain style={{ border: 'none' }}>
          <ItineraryList nameAgency={nameAgency} travel={travel} />
        </SectionMain>

        {travel?.destinys.length > 0 && nameAgency && (
          <SectionMain>
            <DestinysList nameAgency={nameAgency} destinys={travel.destinys} />
          </SectionMain>
        )}

        {/* {travel.experiences.length > 0 && (
          <SectionMain>
            <ExperiencesList experiences={travel.experiences} />
          </SectionMain>
        )}

        {travel.restaurants.length > 0 && (
          <SectionMain>
            <RestaurantsList restaurants={travel.restaurants} />
          </SectionMain>
        )} */}

        {travel && (
          <SectionMain>
            <DocsList travelDocs={travel.travelResumeDocs} />
          </SectionMain>
        )}
      </Container>
    </>
  )
}

const Container = styled.div`
  max-width: 920px;
  height: 100%;
  margin: 32px auto;
  padding: 0 8px;
`

const SectionMain = styled.div`
  width: 100%;
`

const TravelTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 16px 16px 16px 48px;

  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
  }

  h2 {
    font-weight: 500;
  }
`

const Date = styled.h5`
  /* color: ${props => props.theme.colors.secondary}; */
`

const DateWee = styled.h5`
  /* color: #d54a26; */
`
