import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import LoadingContainer from '~/components/LoadingContainer'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import PreviewAppTheme from '~/components/PreviewAppTheme'
import { getBase64 } from '~/functions/File'
import { GetAxios } from '~/services/api'
import { ButtonDetails, ButtonWpp } from '~/styles/buttons/defaults'
import ModalDefault from '~/components/ModalDefault'
import ZChatQRCode from '~/components/QRCode'
import { SyncInstanceWrapper } from './SyncInstancesWrapper'
import { setAgencyPhone } from '~/store/root/slices/auth'

export default function Agency() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const dispatch = useDispatch()
  const [agency, setAgency] = useState()
  const [image, setImage] = useState()
  const [ddi, setDdi] = useState({})
  const [primaryColor, setPrimaryColor] = useState()
  const [secondaryColor, setSecondaryColor] = useState()
  const [loading, setLoading] = useState(false)
  const auth = useSelector(state => state.auth)
  const user = auth.user
  const [showModalAssociate, setShowModalAssociate] = useState(false)
  const [showModalTextSurvey, setShowModalTextSurvey] = useState(false)
  const [showModalRadar, setShowModalRadar] = useState(false)
  const [agencyId, setAgencyId] = useState()
  const [openQrCodeModal, setOpenQrCodeModal] = useState(false)

  const handleShowModalAssociate = () => {
    setShowModalAssociate(!showModalAssociate)
  }

  const handleShowModalTextSurvey = () => {
    setShowModalTextSurvey(!showModalTextSurvey)
  }

  const handleShowModalRadar = () => {
    setShowModalRadar(!showModalRadar)
  }

  const openModal = () => {
    setOpenQrCodeModal(!openQrCodeModal)
  }

  async function getSource() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/AllUsers`,
      )

      setAgencyId(data.id)
      setImage(data.image)
      setPrimaryColor(data.primaryColor)
      setSecondaryColor(data.secondaryColor)

      setAgency(data)

      setLoading(false)
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  useEffect(() => {
    getSource()
    getOptionsDDI()

    return () => {
      setAgency(undefined)
    }
  }, [])

  async function changeImage(e) {
    getBase64(e.target.value, setImage)
  }

  const SaveText = async data => {
    try {
      setLoading(true)

      const body = {
        HappyBirthDay: data.happyBirthDay,
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/textwpp/${agencyId}`,
        body,
      )

      handleShowModalAssociate()
      await getSource()
      toast.success('Dados salvos com sucesso!')
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.info(err?.response?.data)
      console.log(err)
    }
  }

  const SaveTextSurvey = async data => {
    try {
      setLoading(true)

      const body = {
        TextSurvey: data.textSurvey,
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/textSurvey/${agencyId}`,
        body,
      )

      handleShowModalTextSurvey()
      await getSource()
      toast.success('Dados salvos com sucesso!')
    } catch (err) {
      toast.info(err?.response?.data)
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const SaveTimeRadar = async data => {
    try {
      setLoading(true)

      const body = {
        time: data.type.value,
        messageRadar: data.message,
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/radar/${agencyId}`,
        body,
      )

      handleShowModalRadar()
      await getSource()
      toast.success('Dados salvos com sucesso!')
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.info(err?.response?.data)
      console.log(err)
    }
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()
      formData.append('image', data.image)
      formData.append('name', data.name)
      formData.append('email', data.email)
      formData.append('ddiValue', data.ddiValue.value)
      formData.append('phone', data.phone)
      formData.append('cnpj', data.cnpj)
      formData.append('primaryColor', data.primaryColor)
      formData.append('secondaryColor', data.secondaryColor)
      formData.append('promotionalText', data.promotionalText)
      formData.append('sendMessageForAll', data.sendMessageForAll)
      formData.append('viewAll', data.viewAll)
      if (data.about) formData.append('about', data.about)
      if (data.privacyPolicyLink)
        formData.append('privacyPolicyLink', data.privacyPolicyLink)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/admin`,
        formData,
      )

      dispatch(
        setAgencyPhone({
          agencyPhone: data.phone,
          agencyPhoneDDI: data.ddiValue?.value ?? 55,
        }),
      )
      toast.success('Agência editada com sucesso!')

      setTimeout(() => location.reload(), 1000)
    } catch (error) {
      toast.info('Não foi possível editar dados da Agência.')
    }
  }

  const agencyOrOperator = () => {
    const name = user.isOperator == true ? 'Minha Operadora' : 'Minha Agência'

    return name
  }

  return (
    <Container>
      <PageTitle title={`${agencyOrOperator()}`} noButton />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          {!loading && (
            <Row>
              <Col sm={12} xl={8}>
                <DefaultForm
                  defaultValue={agency}
                  callbackSubmit={onSubmit}
                  inputs={[
                    {
                      type: 'image',
                      name: 'image',
                      label: 'Logo',
                      placeholder: 'Logo',
                      required: true,
                      onChange: changeImage,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                      maxWidth: '300px',
                    },
                    {
                      type: 'text',
                      name: 'name',
                      label: 'Nome',
                      placeholder: 'Nome',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'email',
                      name: 'email',
                      label: 'E-mail',
                      placeholder: 'E-mail',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'select',
                      name: 'ddiValue',
                      label: 'DDI',
                      options: ddi,
                      placeholder: 'Selecione...',
                      required: true,
                      sm: 2,
                      md: 2,
                      lg: 2,
                      xl: 2,
                    },
                    {
                      type: 'text',
                      name: 'phone',
                      label: 'Telefone',
                      placeholder: 'Telefone',
                      required: true,
                      mask: '(99) 99999-9999',
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 2,
                    },
                    {
                      type: 'text',
                      name: 'cnpj',
                      label: 'CNPJ',
                      placeholder: 'CNPJ',
                      required: true,
                      mask: '99.999.999/9999-99',
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'color',
                      name: 'primaryColor',
                      label: 'Cor primária',
                      placeholder: 'Cor primária',
                      onChange: e => setPrimaryColor(e.target.value),
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'color',
                      name: 'secondaryColor',
                      label: 'Cor secundária',
                      placeholder: 'Cor secundária',
                      onChange: e => setSecondaryColor(e.target.value),
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'promotionalText',
                      label: 'Texto promocional',
                      placeholder: 'Texto promocional',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'text',
                      name: 'privacyPolicyLink',
                      label: 'Link para os Termos e Políticas de Privacidade',
                      placeholder:
                        'Link para os Termos e Políticas de Privacidade',
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'html',
                      name: 'about',
                      label: 'Sobre a agência',
                      placeholder: 'Sobre a agência',
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'check',
                      name: 'viewAll',
                      label: 'Mostrar todos os clientes',
                      sm: 3,
                      lg: 3,
                      xl: 3,
                    },
                    {
                      type: 'check',
                      name: 'sendMessageForAll',
                      label:
                        'Enviar mensagem para todos os consultores quando um novo cliente se cadastrar pelo link',
                      sm: 9,
                      lg: 9,
                      xl: 9,
                    },
                    {
                      empty: true,
                      text: '<sub>* O link para os <b>Termos e Políticas de Privacidade da Agência</b> e <b>Sobre a agência</b> <br>serão mostrados no menu <b>Sobre e mais</b> no aplicativo do seu viajante.</sub>',
                      sm: 12,
                    },
                  ]}
                />
              </Col>

              <Col sm={12} xl={4}>
                <b
                  style={{
                    margin: '2px 4px',
                    fontWeight: '600',
                    fontSize: '12px',
                  }}
                >
                  Preview do App
                </b>

                <PreviewAppTheme
                  image={image}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                />
              </Col>

              <Col sm={12} xl={12}>
                <div
                  style={{
                    padding: '8px 0',
                    minHeight: '39px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <ButtonDetails to={'/agents'}>Acessos</ButtonDetails>

                  <ButtonDetails to={`/integrationagency`}>
                    Integrações
                  </ButtonDetails>

                  <ButtonDetails to={`/partner`}>Parceiros</ButtonDetails>
                  <ButtonWpp
                    type='button'
                    onClick={() => {
                      handleShowModalAssociate()
                    }}
                  >
                    Texto de aniversário
                  </ButtonWpp>
                  <ButtonWpp
                    type='button'
                    onClick={() => {
                      handleShowModalTextSurvey()
                    }}
                  >
                    Texto P. de Satisfação
                  </ButtonWpp>
                  <ButtonWpp
                    type='button'
                    onClick={() => {
                      handleShowModalRadar()
                    }}
                  >
                    Radar
                  </ButtonWpp>

                  <ButtonDetails to={`/tagsagency`}>Tags</ButtonDetails>
                  <ButtonDetails to={`/motivation`}>Motivos</ButtonDetails>
                  <ButtonDetails to={`/observation`}>Observações</ButtonDetails>
                  <ButtonDetails to={`/campaign`}>Campanhas</ButtonDetails>
                  {user?.role == 'admin' && agency?.enableChatCenter && (
                    <ButtonWpp
                      data-cy='initialize-chat-button'
                      type='button'
                      onClick={() => openModal(true)}
                    >
                      Conectar ao Chatcenter
                    </ButtonWpp>
                  )}
                </div>
              </Col>
            </Row>
          )}

          {loading && <LoadingContainer size={'md'} height={'400px'} />}
        </PanelBody>
      </Panel>

      {showModalAssociate && (
        <ModalDefault
          title='Mensagem de aniversário'
          handleShow={handleShowModalAssociate}
          scroll='hide'
          content={
            <DefaultForm
              callbackSubmit={SaveText}
              defaultValue={{ happyBirthDay: agency.happyBirthDay }}
              inputs={[
                {
                  type: 'textarea',
                  name: 'happyBirthDay',
                  label: 'Texto de aniversário',
                  placeholder: 'Texto de aniversário',
                  required: true,
                  maxLength: 140,
                  sm: 12,
                  lg: 12,
                  md: 12,
                },
              ]}
            />
          }
        />
      )}

      {showModalTextSurvey && (
        <ModalDefault
          title='Texto Pesquisa de Satisfação'
          handleShow={handleShowModalTextSurvey}
          scroll='hide'
          content={
            <DefaultForm
              callbackSubmit={SaveTextSurvey}
              defaultValue={{ textSurvey: agency.textSurvey }}
              inputs={[
                {
                  type: 'textarea',
                  name: 'textSurvey',
                  label: 'Texto de Pesquisa de Satisfação',
                  placeholder: 'Texto de Pesquisa de Satisfação',
                  maxLength: 140,
                  sm: 12,
                  lg: 12,
                  md: 12,
                },
              ]}
            />
          }
        />
      )}

      {showModalRadar && (
        <ModalDefault
          title='Tempo do radar'
          handleShow={handleShowModalRadar}
          scroll='inherit'
          content={
            <DefaultForm
              callbackSubmit={SaveTimeRadar}
              defaultValue={{
                message: agency.messageRadar,
                type: {
                  label: agency.timeRadar + ' meses',
                  value: agency.timeRadar,
                },
              }}
              inputs={[
                {
                  type: 'textarea',
                  name: 'message',
                  label: 'Mensagem do radar',
                  maxLength: 300,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'select',
                  name: 'type',
                  label: 'Tempo',
                  options: [
                    {
                      label: '3 meses',
                      value: '3',
                    },
                    {
                      label: '6 meses',
                      value: '6',
                    },
                    {
                      label: '9 meses',
                      value: '9',
                    },
                    {
                      label: '12 meses',
                      value: '12',
                    },
                  ],
                  maxLength: 150,
                },
              ]}
            />
          }
        />
      )}
      {openQrCodeModal && (
        <ModalDefault
          handleShow={openModal}
          width='60%'
          content={
            <SyncInstanceWrapper>
              <ZChatQRCode user={user} handleShow={openModal} />
            </SyncInstanceWrapper>
          }
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
  }
  position: relative;
`
