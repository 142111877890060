import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FormGroup } from 'reactstrap'
import { useField } from '@unform/core'
import { getPhoneFormated } from '~/functions/Phone'
import InputMask from 'react-input-mask'

export default function PhoneInput({
  name,
  value,
  onChange,
  label,
  placeholder,
  icon,
  disabled,
  required,
  errorMessage,
  successMessage,
}) {
  const inputRef = useRef({})
  const { fieldName, defaultValue, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  const onChangeWrapper = e => {
    const inputText = e.target.value

    if (!inputText) {
      onChange(e)
    } else {
      e.target.value = getPhoneFormated(inputText)

      return onChange(e)
    }
  }

  return (
    <Group>
      {label && (
        <Label htmlFor={fieldName}>
          {label}

          {icon && <i className={icon} />}
        </Label>
      )}

      <StyledInput
        id={fieldName}
        ref={inputRef}
        name={fieldName}
        placeholder={placeholder}
        className='form-control'
        value={value}
        onChange={onChangeWrapper}
        defaultValue={defaultValue}
        disabled={disabled}
        required={required}
      />

      <MessageError>{errorMessage}</MessageError>

      <MessageSuccess>{successMessage}</MessageSuccess>
    </Group>
  )
}

export const Label = styled.label`
  margin: 2px 4px;
  font-weight: 600;
`

export const Group = styled(FormGroup)`
  width: 100%;
`

export const StyledInput = styled(InputMask)`
  height: 38px !important;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
`

export const MessageError = styled.p`
  color: #a00;
  font-weight: bold;
  font-size: 11px;
`

export const MessageSuccess = styled.p`
  color: #1bc25b;
  font-weight: bold;
  font-size: 11px;
`
