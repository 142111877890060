import styled from 'styled-components'

export const ConnectedMessageContainer = styled.div`
  margin-bottom: 4px;
`

export const ConnectedMessageContent = styled.div``

export const ConnectedMessageDocument = styled.div`
  width: 320px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const ConnectedMessageLoading = styled.div`
  width: 320px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ConnectedMessageImage = styled.div`
  width: 320px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  img {
    width: 100%;
    max-width: 420px;
    max-height: 600px;
    object-fit: cover;
    border-radius: 8px;
  }
`

export const ConnectedMessageSticker = styled.div`
  width: 180px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  img {
    width: 100%;
    max-width: 180px;
    max-height: 320px;
    object-fit: cover;
    border-radius: 8px;
  }
`

export const ConnectedMessageVideo = styled.div`
  width: 320px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  video {
    border-radius: 8px;
    width: 100%;
  }
`
