import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import DefaultForm from '~/components/DefaultForm'
import { Container } from '~/styles/container'
import PageTitle from '~/components/PageTitle'
import { proposalInputs } from './inputs'
import { apiRequest } from '~/apiService'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export default function ProposalCreate({
  fromFluxoDeAtendimento = false,
  customer = {},
  callback = () => {},
}) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onSubmit = async data => {
    const formData = new FormData()

    for (let key in data) {
      if (data[key] != null) formData.append(key, data[key])
    }

    formData.set('origin', data?.origin.label)
    formData.set('originId', data?.origin.value)

    if (fromFluxoDeAtendimento) {
      formData.append('clientId', customer?.id)
      formData.append('clientName', customer?.name)
    }

    const result = await apiRequest('post', 'proposal', formData, setLoading)

    if (fromFluxoDeAtendimento && result.error == false) {
      toast.success(result?.data?.message)
      callback()
    }

    if (fromFluxoDeAtendimento == false && result.error == false) {
      toast.success(result?.data?.message)

      navigate('/proposal/edit', {
        state: {
          ...data,
          fromCreatePage: true,
          id: result?.data?.content,
          proposalStatus: {
            label: 'Em preparação',
            value: 'Em preparação',
          },
        },
      })
    } else {
      toast.info(result?.erroMessage)
    }
  }

  return (
    <Container>
      {!fromFluxoDeAtendimento && <PageTitle title='Criação da Proposta' />}

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Criar Proposta'}
            disabled={loading}
            loading={loading}
            inputs={proposalInputs}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
