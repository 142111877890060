import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { LinkEdit, LinkCreate } from '~/components/Link/styles'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import SubTableOfTraveler from '~/components/SubTableOfTraveler'
import { getDateFormated } from '~/functions/Date'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function RelationshipTravelHistory() {
  const { axiosApis } = GetAxios()
  const user = useSelector(state => state.auth.user)
  const [loading, setLoading] = useState(false)
  const [travelers, setTravelers] = useState([])
  const navigate = useNavigate()

  async function showLancamento(e) {
    navigate('../../relationship-customers/enrollment', {
      state: { customers: e },
    })
  }

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      await onSubmit()
    }
    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    setLoading(true)
    console.log('data: ', data)
    const response = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/gettraveler`,
      { params: data },
    )

    setTravelers(response.data)

    return setLoading(false)
  }

  return (
    <Container>
      <h1 className='page-header'>
        Histórico de viagens
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Na tela está disponível todo o histórico de viagens, detalhe importante no perfil Agência a visualização será de todos os históricos dos viajantes da Agencia, para o Perfil consultor só irá mostrar os históricos das viagens criadas pelo Consultor.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'fullName',
                label: 'Viajante',
                placeholder: 'Viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'email',
                name: 'email',
                label: 'E-mail',
                placeholder: 'E-mail',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'phone',
                label: 'Telefone',
                placeholder: 'Telefone',
                mask: '(99) 99999-9999',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table
            columns={[
              {
                Header: () => null,
                id: 'expander',
                Cell: ({ row }) => (
                  <span {...row.getToggleRowExpandedProps()}>
                    <i
                      className={`
                  fas fa-caret-${row.isExpanded ? 'up' : 'down'}
                `}
                    />
                  </span>
                ),
                width: 40,
              },
              {
                Header: 'N° de viagens',
                accessor: 'count',
                width: 75,
              },
              {
                Header: 'Viajante',
                accessor: 'fullName',
                width: 200,
              },
              {
                Header: 'E-mail',
                accessor: 'email',
                width: 200,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 120,
              },
              {
                Header: 'Aniversário',
                accessor: 'birthdayDate',
                width: 120,
              },
              {
                Header: 'Data da Última Viagem',
                accessor: 'lastTravelDate',
                Cell: props => {
                  return getDateFormated(props.row.original.lastTravelDate)
                },
                width: 170,
              },
              {
                Header: 'Última Viagem',
                accessor: 'lastTravelDays',
                Cell: props => {
                  if (props.row.original.lastTravelDays < 0) {
                    return `Ocorreu há ${Math.abs(
                      props.row.original.lastTravelDays,
                    )} dias(s)`
                  } else {
                    return `Ocorrerá em  ${props.row.original.lastTravelDays} dia(s)`
                  }
                },
                width: 150,
              },
              // {
              //   Header: '',
              //   accessor: 'lancamento',
              //   Cell: props => {
              //     return (
              //       <div>
              //         {user.agencyName == 'Wee Travel' ? (
              //           // <LinkCreate
              //           //   to={'../../relationship-customers/enrollment'}
              //           //   state={{ customers: props.row.original }}
              //           // >
              //           //   Lançamento
              //           // </LinkCreate>
              //           <IconButton
              //             id={props.row.index}
              //             type={'lançamento'}
              //             tooltipOverride={'Lançamento'}
              //             iconOverride={'fas fa-hand-holding-usd'}
              //             onClick={() => showLancamento(props.row.original)}
              //           />
              //         ) : (
              //           <p></p>
              //         )}
              //       </div>
              //     )
              //   },
              //   width: 20,
              // },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
            subComponent={props => (
              <SubTableOfTraveler travelerId={props.row.original.id} />
            )}
            data={travelers}
            loading={loading}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
