import { Form } from '@unform/web'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import Select from '~/components/Select'
import { GetAxios } from '~/services/api'

export default function ExperiencesSelect({ name, onChange, defaultValue }) {
  const { axiosApis } = GetAxios()
  const [experienceOptions, setExperienceOptions] = useState([])
  const [experienceSelected, setExperienceSelected] = useState([])
  const formRef = useRef()
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const travelId = location.state?.experiencesSelect?.travel?.id

        const url = `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience`

        const { data } = await axiosApis.get(
          url + `${travelId ? `/getbytravelid/${travelId}` : ``}`,
        )

        if (travelId && data.length === 0) {
          toast.info(
            'Nenhuma Experiência disponível. Adicione um novo Destino à essa viagem e tente novamente.',
          )
        }

        const newOptions = data.map(item => {
          return {
            value: item.code,
            label: `${item.title}, ${item.city}, ${item.category}`,
            sublabel: item.title,
            category: item.category,
            city: item.city,
            type: item.type,
          }
        })

        setExperienceOptions(newOptions)

        let newExperiencesSelected = []

        if (defaultValue) {
          defaultValue.map(e => {
            newOptions.map(o => {
              if (e === o.value) {
                newExperiencesSelected.push(o)
              }
            })
          })
        }

        setExperienceSelected(newExperiencesSelected)

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }
    loadSource()

    return () => {
      cancel = true
    }
  }, [location])

  useEffect(() => {
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: experienceSelected.map(x => x.value),
        },
      })
    }
  }, [experienceSelected])

  useEffect(() => {
    if (!defaultValue) {
      setExperienceSelected([])
    }
  }, [defaultValue])

  async function handleSelect(e) {
    setExperienceSelected(e.target.value)
  }

  return (
    <Form ref={formRef} onSubmit={undefined}>
      <Select
        disabled={loading}
        isLoading={loading}
        name={name}
        label={'Experiências'}
        placeholder={'Selecione...'}
        options={experienceOptions}
        value={experienceSelected}
        onChange={handleSelect}
        isClearable
        isMulti
        formatOptionLabel={props => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <b>{props.sublabel}</b>

              <span>{props.city}...</span>

              <p>
                Categoria: <b>{props.category}</b>
              </p>
            </div>
          )
        }}
      />
    </Form>
  )
}
