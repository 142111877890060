import React, { useState, useEffect } from 'react'
import { getDateFormated } from 'functions/Date'
import {
  SpacingDiv,
  EventDiv,
  EventIndicatorStart,
  EventIndicatorEnd,
  DayNumber,
  Counter,
  Content,
} from './style'
import { getFirstWords } from '~/functions/String'

export function CalendarDay({ entitys, locale, date, type }) {
  const [items, setItems] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      let newItems = []

      entitys.map((item, i) => {
        if (
          getDateFormated(item.start, 'DD/MM/YYYY') ===
          getDateFormated(date, 'DD/MM/YYYY')
        ) {
          newItems.push({
            ...item,
            start: true,
          })
        }

        if (
          getDateFormated(item.end, 'DD/MM/YYYY') ===
          getDateFormated(date, 'DD/MM/YYYY')
        ) {
          newItems.push({
            ...item,
            start: false,
          })
        }
      })

      setItems(newItems)
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [entitys])

  return (
    <SpacingDiv type={type}>
      <DayNumber>{getDateFormated(date, 'D')}</DayNumber>

      <Content type={type}>
        {items.map((e, i) => {
          return (
            <EventDiv key={i} type={type}>
              {e.start && <EventIndicatorStart />}

              {!e.start && <EventIndicatorEnd />}

              {type !== 'day' && <span>{getFirstWords(e.title, 3)}</span>}

              {type !== 'day' && e.vip && (
                <i className='fas fa-star' style={{ color: '#efb810' }} />
              )}
            </EventDiv>
          )
        })}
      </Content>

      {items.length > 2 && <Counter type={type}>{items.length}</Counter>}
    </SpacingDiv>
  )
}
