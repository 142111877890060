import { useEffect, useState } from 'react'
import { Label, Group, InputV } from './styles'
import styled from 'styled-components'
import IconButton from '../IconButton'
import { getFile, newDownloadFile } from '~/functions/File'
import { toast } from 'react-toastify'
import InputMessage from '../InputMessage'
import { apiRequest } from '~/apiService'
import LoadingContainer from '../LoadingContainer'

export default function InputFileAsync({
  name,
  value,
  onChange,
  label,
  max = 1,
  ...rest
}) {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (value) {
      if (value.length !== files.length) {
        setFiles(value)
      }
    } else {
      setFiles([])
    }
  }, [value])

  useEffect(() => {
    onChange({
      target: {
        name: name,
        value: files,
      },
    })
  }, [files])

  async function onChangeInputFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3145728) {
        toast.info('Insira um arquivo com tamanho máximo de 3MB.')
      } else {
        const newFiles = [...files]

        newFiles.push(e.target.files[0])

        setFiles(newFiles)
      }
    } else {
      setFiles([])
    }
  }

  async function onDownload(e) {
    if (e.size) {
      newDownloadFile(e)
      return
    }

    const response = await apiRequest(
      'get',
      `agencycustomerkanban/card/file/${e.id}`,
      {},
      setLoading,
    )

    if (response.error) {
      toast.info(response.erroMessage)
    } else {
      const file = await getFile(
        response?.data?.content?.file,
        response?.data?.content?.fileName,
        response?.data?.content?.contentType,
      )

      newDownloadFile(file)
    }
  }

  const removeFileOfArray = index => {
    const filesRemaining = [...files]
    filesRemaining.splice(index, 1)
    setFiles(filesRemaining)
  }

  async function onRemove(file, index) {
    if (file.size) {
      removeFileOfArray(index)
      return
    }

    const response = await apiRequest(
      'delete',
      `agencycustomerkanban/card/file/${file.id}`,
      {},
      setLoading,
    )

    if (response.error) {
      toast.info(response.erroMessage)
    } else {
      removeFileOfArray(index)
      toast.success(response.data.message)
    }
  }

  return (
    <Group>
      {label && (
        <Label htmlFor={name}>
          {label}

          {max > 1 && <span>&nbsp;(máx. {max})</span>}
        </Label>
      )}

      {files.map((e, i) => (
        <FileContainer key={i}>
          {e.name}

          <div style={{ display: 'flex', gap: '8px' }}>
            {loading && <LoadingContainer />}

            {!loading && (
              <IconButton
                id={`input-file-download-file-${i}`}
                type={'download'}
                style={{ fontSize: '12px' }}
                onClick={() => onDownload(e)}
              />
            )}

            {!loading && (
              <IconButton
                id={`input-file-delete-file-${i}`}
                type={'trash'}
                tooltipOverride={'Remover'}
                style={{ fontSize: '12px' }}
                onClick={() => onRemove(e, i)}
              />
            )}
          </div>
        </FileContainer>
      ))}

      {files.length < max && (
        <>
          <InputV
            id={name}
            name={name}
            value={''}
            onChange={onChangeInputFile}
            className='form-control'
            type={'file'}
            {...rest}
          />
          <InputMessage />
        </>
      )}
    </Group>
  )
}

const FileContainer = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 8px;
`
