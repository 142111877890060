import styled from 'styled-components'

export default function MediaPreview({ title, desc, onClick, type }) {
  return (
    <Container type={type}>
      <ContentWrapper onClick={onClick}>
        <div>
          <span>
            {type === 'document' && (
              <>
                <i className='fas fa-file' />
                &nbsp;
                <span>Documento</span>
              </>
            )}

            {type === 'image' && (
              <>
                <i className='fas fa-image' />
                &nbsp;
                <span>Imagem</span>
              </>
            )}

            {type === 'sticker' && (
              <>
                <i className='fas fa-sticky' />
                &nbsp;
                <span>Figurinha</span>
              </>
            )}

            {type === 'audio' && (
              <>
                <i className='fas fa-volume-up' />
                &nbsp;
                <span>Áudio</span>
              </>
            )}

            {type === 'video' && (
              <>
                <i className='fas fa-video' />
                &nbsp;
                <span>video</span>
              </>
            )}
          </span>

          {title && <b>{title}</b>}

          {desc && <span>{desc}</span>}
        </div>

        <span>
          <i className='fas fa-eye' />
        </span>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: ${props => (props.type === 'sticker' ? '180px' : '320px')};
  max-width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  & > i {
    font-size: 18px;
  }
`

const ContentWrapper = styled.div`
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 16px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border-radius: 8px;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }

  div {
    display: flex;
    flex-direction: column;
  }
`
