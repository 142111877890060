import { Badge } from 'reactstrap'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 90px;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  background: ${props => (!props.active ? 'transparent' : '#eaeaea')};
`

// export const Container = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 1px solid #ccc;
//   cursor: pointer;
//   background: ${props => (!props.active ? 'transparent' : '#eaeaea')};

//   @media screen and (max-width: 1536px) {
//     min-height: 100px;
//   }
// `

export const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  //justify-content: center;
  //align-items: flex-start;
  //gap: 4px;
  //margin: 0;
  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
`

export const Details = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 8px;
  margin: 0;
`

export const DetailsText = styled.span`
  width: 246px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 1536px) {
    /* width: 132px; */
    max-width: 90%;
  }

  @media screen and (max-width: 1280px) {
    .wrapper {
      max-width: 1024px;
    }
  }
`

export const DetailsRead = styled.span``

export const Avatar = styled.div`
  color: #ccc;
  font-size: 38px;
`

export const Name = styled.span`
  font-weight: 600;
  width: 160px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NewMessageIcon = styled.i`
  color: ${props => props.theme.colors.primary};
  position: absolute;
  bottom: 22px;
  right: 5px;
`

export const Time = styled.p`
  font-size: 0.7em;
  margin-bottom: 0 !important;
  text-wrap: nowrap;
`

export const EndInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: end;
  justify-content: center;
`

export const Main = styled.div`
  display: flex;
  /* flex-wrap: nowrap; */
  flex-direction: column;
  margin: 0px 8px;
`
export const NewBadge = styled(Badge)`
  width: max-content;
  margin: 2px 0px;
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* text-align: end;
  justify-content: center; */

  .p-rating {
    margin-top: 2px;
    font-size: 8px;
  }

  .p-disabled {
    opacity: 1;
  }
`
// @media screen and (max-width: 1536px) {
//   .wrapper {
//     max-width: 1366px;
//   }
// }
// @media screen and (max-width: 1280px) {
//   .wrapper {
//     max-width: 1024px;
//   }
// }
// @media screen and (max-width: 1024px) {
//   .wrapper {
//     max-width: 768px;
//     padding-left: 40px;
//     padding-right: 40px;
//   }
// }
// @media screen and (max-width: 768px) {
//   .wrapper {
//     max-width: 640px;
//   }
// }
// @media screen and (max-width: 640px) {
//   .wrapper {
//     max-width: 475px;
//   }
// }

export const TagContainer = styled.div`
  text-align: start;
`
export const TagBadge = styled(Badge)`
  background-color: ${props => props.theme.colors.primary} !important;
  margin: 0px 0px 4px 4px;
  font-size: 14px;
`
export const AddBadge = styled(Badge)`
  background-color: whitesmoke !important;
  margin: 0px 0px 4px 4px;
  font-size: 14px;
  color: ${props => props.theme.colors.primary} !important;
  cursor: pointer;
`
