import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import useModal from '~/hooks/useModal'
import { GetAxios } from '~/services/api'
import { DivFull } from '~/styles/divs'

export default function ShowInfo({ traveler }) {
  const { axiosApis } = GetAxios()
  const [travelerTags, setTravelerTags] = useState([])
  const [agency, setAgency] = useState([])
  const [tagOptions, setTagOptions] = useState([])
  const { hideModal } = useModal()
  const navigate = useNavigate()

  useEffect(() => {
    getSource()
  }, [])

  async function getSource() {
    try {
      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/AllUsers`,
      )

      setAgency(data)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubGetInformationToSendMessageWppmit = async data => {
    try {
      const message = `Deseja enviar a mensagem?`

      if (!traveler.phone)
        toast.info(
          'O viajante em questão não possui o número de telefone cadastrado.',
        )

      if (traveler.phone && window.confirm(message)) {
        sendMessage(
          traveler.id,
          traveler.phone,
          traveler.fullName,
          data.message,
          traveler.ddiValue,
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  const sendMessage = async (id, phone, fullname, messageRadar, ddivalue) => {
    try {
      const body = {
        id,
        phone,
        fullname,
        messageRadar,
        ddivalue,
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/radar/send-message`,
        body,
      )
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <DivFull style={{ height: '40vh' }}>
      <DefaultForm
        callbackSubmit={onSubGetInformationToSendMessageWppmit}
        defaultValue={{
          message: agency.messageRadar,
        }}
        submitLabel={'Enviar'}
        inputs={[
          {
            type: 'textarea',
            name: 'message',
            label: 'Digite ou ajuste a mensagem para ser enviada ao viajante',
            required: true,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
        ]}
      />
    </DivFull>
  )
}
