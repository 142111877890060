import { Form } from '@unform/web'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'reactstrap'
import Input from '~/components/Input'
import TextArea from '~/components/TextArea'
import { toastWrapper } from '~/functions/ToastWrapper'
// import { toast } from 'react-toastify'
import { DivFlexLeft, DivFlexRight, SpaceBetweenDiv } from '~/styles/divs'
import { CancelButton, DeleteButton, SaveButton } from '../Button/styles'
import CitiesSelect from '../CitiesSelect'
import ExperiencesSelect from '../ExperiencesSelect'
import ExperiencesDayByDaySelect from '../ExperiencesDayByDaySelect'
import ImageInput from '../ImageInput'
import IncrementInput from '../IncrementInput'
import KeyValueInput from '../KeyValueInput'
import LoadingContainer from '../LoadingContainer'
import RadioInput from '../RadioInput'
import Select from '../Select'
import InputHtml from '../InputHtml'
import TravelDatesInput from '../TravelDatesInput'
import ValueInput from '../ValueInput'
import SlideInput from '../SlideInput'
import DivForHTML from '../DivForHTML'
import CategorySelect from '../CategorySelect'
import PhoneInput from '../PhoneInput'
import FileInput from '../FileInput'
import InputTreatedFile from '../InputTreatedFile'
import MultiFileInput from '../MultiFileInput'
import TableOfFile from '../TableOfFile'
import TableToList from '../TableToList'
import TagsSelect from '../TagsSelect'
import TravelerSelect from '../TravelerSelect'
import CustomerSelect from '../CustomerSelect'
import InputFile from '../InputFile'
import InputFileAsync from '../InputFileAsync'

export default function DefaultForm({
  inputs = [],
  onValidate = undefined,
  callbackDelete = undefined,
  callbackCancel = undefined,
  callbackReset = undefined,
  callbackSubmit = undefined,
  otherButton = undefined,
  onErrorMessage,
  onNotFoundMessage,
  onBadRequestMessage,
  defaultValue,
  fontSize = '14px',
  backgroundColor = 'transparent',
  color,
  borderRadius = '5px',
  deleteLabel = 'Excluir',
  cancelLabel = 'Cancelar',
  resetLabel = 'Limpar',
  submitLabel = 'Salvar',
  disabled = false,
  loading: loadingOverride,
  inline = false,
  onChangeData = () => {
    return
  },
}) {
  const formRef = useRef({})
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(defaultValue ?? {})
  const inputsToRender = inputs.filter(x => !!x).filter(x => !x.hide)
  // const [minDate, setMinDate] = useState('1900-01-01')

  useEffect(() => {
    if (defaultValue) {
      const newData = {
        ...data,
        ...defaultValue,
      }

      setData(newData)
    }
  }, [defaultValue])

  useEffect(() => {
    if (onChangeData) {
      const newOnChangeData = {
        ...data,
      }

      onChangeData(newOnChangeData)
    }
  }, [data])

  async function onChange(
    props,
    onChangeInput,
    inputToChange,
    formatDestinyDate,
  ) {
    if (onChangeInput) {
      onChangeInput(props)
    }
    let newDestinyDateValue = {}
    if (inputToChange && formatDestinyDate) {
      newDestinyDateValue = {
        [inputToChange]: formatDestinyDate(props.target.value),
      }
    }
    setData(state => ({
      ...state,
      ...newDestinyDateValue,
      [props.target.name]: props.target.value,
    }))
  }

  async function onDelete() {
    await loadSource(async () => {
      await callbackDelete(data)
    })
  }

  async function onCancel() {
    await loadSource(async () => {
      await callbackCancel(data)
    })
  }

  async function onReset() {
    await loadSource(async () => {
      setData({})

      await callbackReset(data)
    })
  }

  async function onSumbit() {
    await loadSource(async () => {
      let stop = false
      // let field = undefined

      inputsToRender.forEach(e => {
        if (e.required && !data[e.name] && !e.hide) {
          if (!stop) {
            // field = e.label

            stop = true
          }
        }
      })

      // if (stop) {
      //   toast.info(`Preencha o campo ${field}.`)

      //   return
      // }

      if (onValidate) {
        const valid = await onValidate(data)

        if (!valid) return
      }

      return await callbackSubmit(data)
    })
  }

  async function loadSource(callback, showToast = true) {
    try {
      setLoading(true)

      await callback()

      setLoading(false)
    } catch (error) {
      setLoading(false)

      if (showToast) {
        toastWrapper({
          error,
          onErrorMessage,
          onNotFoundMessage,
          onBadRequestMessage,
        })
      }
    }
  }

  const checkKeyDown = e => {
    if (e.key === 'Enter' && e?.target?.localName != 'textarea')
      e.preventDefault()
  }

  return (
    <Form
      ref={formRef}
      onReset={onReset}
      onSubmit={onSumbit}
      onKeyDown={checkKeyDown}
      style={{ width: '100%' }}
    >
      <Row>
        {data &&
          inputsToRender.map((e, i) => {
            return (
              <Col
                key={i}
                sm={e.sm ?? 12}
                md={e.md ?? e.sm ?? 12}
                lg={e.lg ?? e.md ?? e.sm ?? 4}
                xl={e.xl ?? e.lg ?? e.md ?? e.sm ?? 3}
              >
                {!e.empty && (
                  <>
                    {(e.type === 'text' ||
                      e.type === 'email' ||
                      e.type === 'password' ||
                      e.type === 'number' ||
                      e.type === 'datetime-local' ||
                      e.type === 'time' ||
                      e.type === 'color' ||
                      e.type === 'file' ||
                      e.type === 'month' ||
                      e.type === 'range' ||
                      e.type === 'search' ||
                      e.type === 'url' ||
                      e.type === 'week') && (
                      <Input
                        type={e.type}
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        value={data[e.name] ? data[e.name] : e.value}
                        onChange={props => onChange(props, e.onChange)}
                        required={e.required}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                        maxLength={e.maxLength}
                        searchButton={e.searchButton}
                        searchButtonLabel={e.searchButtonLabel}
                        searchButtonText={e.searchButtonText}
                        searchIcon={e.searchIcon}
                        // callbackSearch={e.callbackSearch}
                        callbackSearch={x => e.callbackSearch(x, data)}
                        style={e.style}
                        mask={e.mask}
                        max={e.max}
                        min={e.min}
                        step={'any'}
                        infoText={e.infoText}
                      />
                    )}

                    {e.type === 'inputFile' && (
                      <InputFile
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        value={data[e.name] ? data[e.name] : e.value}
                        onChange={props => onChange(props, e.onChange)}
                        required={e.required}
                        max={e.max}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                      />
                    )}

                    {e.type === 'inputFileAsync' && (
                      <InputFileAsync
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        value={data[e.name] ? data[e.name] : e.value}
                        onChange={props => onChange(props, e.onChange)}
                        required={e.required}
                        max={e.max}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                      />
                    )}

                    {e.type === 'treatedfile' && (
                      <InputTreatedFile
                        label={e.label}
                        value={data[e.name]}
                        local={e.local}
                      />
                    )}

                    {e.type === 'tableToList' && (
                      <TableToList
                        label={e.label}
                        value={data[e.name]}
                        columns={e.columns}
                        handle={e.handle}
                        nameButton={e.nameButton}
                        loading={e.loading}
                      />
                    )}

                    {e.type === 'tableOfFile' && (
                      <TableOfFile label={e.label} url={e.url} />
                    )}

                    {e.type === 'date' && (
                      <Input
                        type={e.type}
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        value={data[e.name]}
                        onChange={props => {
                          onChange(
                            props,
                            e.onChange,
                            e.inputToChange,
                            e.formatDestinyDate,
                          )
                        }}
                        required={e.required}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                        maxLength={e.maxLength}
                        searchButton={e.searchButton}
                        searchButtonLabel={e.searchButtonLabel}
                        searchButtonText={e.searchButtonText}
                        searchIcon={e.searchIcon}
                        // callbackSearch={e.callbackSearch}
                        callbackSearch={x => e.callbackSearch(x, data)}
                        style={e.style}
                        mask={e.mask}
                        max={'2040-01-01'}
                        min={e.minDate ?? '1900-01-01'}
                        infoText={e.infoText}
                      />
                    )}

                    {e.type === 'phone' && (
                      <PhoneInput
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        value={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        required={e.required}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                      />
                    )}

                    {e.type === 'textarea' && (
                      <TextArea
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        value={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        required={e.required}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                        maxLength={e.maxLength}
                        style={e.style}
                        rows={e.rows}
                      />
                    )}

                    {e.type === 'image' && (
                      <ImageInput
                        name={e.name}
                        label={e.label ?? '' + `${e.required ? ' * ' : ''}`}
                        value={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        disabled={
                          loading ||
                          loadingOverride ||
                          disabled ||
                          e.disabled ||
                          disabled
                        }
                        style={e.style}
                        maxWidth={e.maxWidth}
                      />
                    )}

                    {e.type === 'fileInput' && (
                      <FileInput
                        name={e.name}
                        label={e.label ?? '' + `${e.required ? ' * ' : ''}`}
                        value={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        disabled={
                          loading ||
                          loadingOverride ||
                          disabled ||
                          e.disabled ||
                          disabled
                        }
                        style={e.style}
                        maxWidth={e.maxWidth}
                      />
                    )}

                    {e.type === 'multiFileInput' && (
                      <MultiFileInput
                        name={e.name}
                        label={e.label ?? '' + `${e.required ? ' * ' : ''}`}
                        value={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        disabled={
                          loading ||
                          loadingOverride ||
                          disabled ||
                          e.disabled ||
                          disabled
                        }
                        style={e.style}
                        maxWidth={e.maxWidth}
                      />
                    )}

                    {e.type === 'html' && (
                      <InputHtml
                        name={e.name}
                        value={data[e.name]}
                        label={e.label}
                        onChange={props => onChange(props, e.onChange)}
                        disabled={
                          loading ||
                          loadingOverride ||
                          disabled ||
                          e.disabled ||
                          disabled
                        }
                        style={e.style}
                        maxLength={e.maxLength}
                        options={e.options}
                      />
                    )}

                    {e.type === 'keyvalue' && (
                      <KeyValueInput
                        name={e.name}
                        defaultValue={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        title={e.label ?? '' + `${e.required ? ' * ' : ''}`}
                        keyPlaceholder={e.placeholder}
                        valuePlaceholder={e.subPlaceholder2}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                      />
                    )}

                    {e.type === 'value' && (
                      <ValueInput
                        name={e.name}
                        defaultValue={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        title={e.label ?? '' + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                      />
                    )}

                    {e.type === 'experiences' && (
                      <ExperiencesSelect
                        name={e.name}
                        defaultValue={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                      />
                    )}

                    {e.type === 'experience-category' && (
                      <CategorySelect
                        name={e.name}
                        defaultValue={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        createButton={e.createButton}
                      />
                    )}

                    {e.type === 'experiences-daybyday' && (
                      <ExperiencesDayByDaySelect
                        name={e.name}
                        defaultValue={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                      />
                    )}

                    {e.type === 'city' && (
                      <CitiesSelect
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        defaultValue={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                        isClearable={e.isClearable}
                        isMulti={e.isMulti}
                      />
                    )}

                    {e.type === 'travelerSelect' && (
                      <TravelerSelect
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        defaultValue={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                        isClearable={e.isClearable}
                        isMulti={e.isMulti}
                      />
                    )}

                    {e.type === 'customerSelect' && (
                      <CustomerSelect
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        defaultValue={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                        isClearable={e.isClearable}
                        isMulti={e.isMulti}
                      />
                    )}

                    {e.type === 'tags' && (
                      <TagsSelect
                        name={e.name}
                        label={e.label + `${e.required ? ' * ' : ''}`}
                        placeholder={e.placeholder ?? ''}
                        defaultValue={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                        isClearable={e.isClearable}
                        isMulti={e.isMulti}
                      />
                    )}

                    {e.type === 'select' && (
                      <Select
                        label={
                          e.label
                            ? e.label + `${e.required ? ' * ' : ''}`
                            : undefined
                        }
                        name={e.name}
                        options={e.options}
                        value={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                        placeholder={e.placeholder}
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                        isSearchable={e.isSearchable}
                        isClearable={e.isClearable}
                        isMulti={e.isMulti}
                        required={e.required}
                        formatOptionLabel={e.formatOptionLabel ?? undefined}
                        inline={inline}
                      />
                    )}

                    {e.type === 'radio' && (
                      <RadioInput
                        label={e.label}
                        name={e.name}
                        options={e.options}
                        value={data[e.name]}
                        required={e.required}
                        onChange={props => onChange(props, e.onChange)}
                      />
                    )}

                    {e.type === 'increment' && (
                      <IncrementInput
                        name={e.name}
                        label={e.label}
                        value={data[e.name]}
                        onChange={props => onChange(props, e.onChange)}
                      />
                    )}

                    {e.type === 'button' && (
                      <SaveButton
                        type={'button'}
                        disabled={e.disabled}
                        onClick={e.onClick}
                        name={e.name}
                        label={e.label}
                        style={e.style}
                      >
                        {e.label}
                      </SaveButton>
                    )}

                    {e.type === 'traveldates' && (
                      <TravelDatesInput
                        name={e.name}
                        label={e.label}
                        value={data[e.name]}
                        defaultValue={
                          defaultValue ? defaultValue[e.name] : undefined
                        }
                        onChange={props => onChange(props, e.onChange)}
                        required={e.required}
                      />
                    )}

                    {e.type === 'check' && (
                      <SlideInput
                        name={e.name}
                        label={e.label}
                        value={data[e.name]}
                        onChange={props =>
                          onChange(
                            {
                              target: {
                                name: props.target.name,
                                value: props.target.checked,
                              },
                            },
                            e.onChange,
                          )
                        }
                        disabled={
                          loading || loadingOverride || disabled || e.disabled
                        }
                      />
                    )}
                  </>
                )}

                {e.empty && (
                  <div
                    style={{
                      height: e.text ? 'auto' : '39px',
                      width: '100%',
                      fontSize: '16px',
                      padding: '8px 0 8px 0',
                    }}
                  >
                    <DivForHTML text={e.text} />
                  </div>
                )}
              </Col>
            )
          })}

        {inline &&
          (callbackSubmit ||
            callbackCancel ||
            callbackReset ||
            otherButton ||
            callbackDelete) && (
            <Col>
              <DivFlexLeft style={{ paddingTop: '22px', marginBottom: '1rem' }}>
                {callbackCancel && (
                  <CancelButton
                    type={'button'}
                    onClick={onCancel}
                    disabled={loading || loadingOverride || disabled}
                    style={{
                      fontSize: `${fontSize}`,
                      height: '38px',
                      alignItems: 'center',
                      background: '#eee',
                    }}
                  >
                    {!loading && loadingOverride && `${cancelLabel}`}

                    {(loading || loadingOverride) && (
                      <LoadingContainer size={'sm'} />
                    )}
                  </CancelButton>
                )}

                {otherButton && (
                  <SaveButton
                    type={'button'}
                    // disabled={otherButton.disabled}
                    onClick={otherButton.onClick}
                    name={otherButton.name}
                    style={otherButton.style}
                    label={otherButton.label}
                  >
                    {!loading && !loadingOverride && `${otherButton.label}`}

                    {(loading || loadingOverride) && (
                      <LoadingContainer size={'sm'} />
                    )}
                  </SaveButton>
                )}

                {callbackReset && (
                  <CancelButton
                    type={'reset'}
                    disabled={!loading || loadingOverride || disabled}
                    style={{
                      fontSize: `${fontSize}`,
                      height: '38px',
                      alignItems: 'center',
                      background: '#eee',
                    }}
                  >
                    {!loading && !loadingOverride && `${resetLabel}`}

                    {(loading || loadingOverride) && (
                      <LoadingContainer size={'sm'} />
                    )}
                  </CancelButton>
                )}

                {callbackSubmit && (
                  <SaveButton
                    type={'submit'}
                    disabled={loading || disabled}
                    style={{
                      fontSize: `${fontSize}`,
                      backgroundColor: `${backgroundColor}`,
                      color: `${color}`,
                      borderRadius: `${borderRadius}`,
                    }}
                  >
                    {!loading && !loadingOverride && `${submitLabel}`}

                    {(loading || loadingOverride) && (
                      <LoadingContainer size={'sm'} />
                    )}
                  </SaveButton>
                )}

                {callbackDelete && (
                  <DeleteButton
                    type={'button'}
                    onClick={onDelete}
                    disabled={loading || disabled}
                    style={{
                      fontSize: `${fontSize}`,
                      height: '38px',
                      alignItems: 'center',
                      background: '#eee',
                    }}
                  >
                    {!loading && !loadingOverride && `${deleteLabel}`}

                    {(loading || loadingOverride) && (
                      <LoadingContainer size={'sm'} />
                    )}
                  </DeleteButton>
                )}
              </DivFlexLeft>
            </Col>
          )}
      </Row>

      {!inline && (
        <Row>
          <Col sm={12}>
            <SpaceBetweenDiv>
              {callbackDelete && (
                <DeleteButton
                  type={'button'}
                  onClick={onDelete}
                  disabled={loading || disabled}
                  style={{
                    fontSize: `${fontSize}`,
                  }}
                >
                  {!loading && !loadingOverride && `${deleteLabel}`}

                  {(loading || loadingOverride) && (
                    <LoadingContainer size={'sm'} />
                  )}
                </DeleteButton>
              )}

              {otherButton && (
                <SaveButton
                  type={'button'}
                  onClick={otherButton.onClick}
                  name={otherButton.name}
                  style={otherButton.style}
                  label={otherButton.label}
                >
                  {!loading && !loadingOverride && `${otherButton.label}`}

                  {(loading || loadingOverride) && (
                    <LoadingContainer size={'sm'} />
                  )}
                </SaveButton>
              )}

              <DivFlexRight>
                {callbackCancel && (
                  <CancelButton
                    type={'button'}
                    onClick={onCancel}
                    disabled={loading || disabled}
                    style={{
                      fontSize: `${fontSize}`,
                      borderRadius: `${borderRadius}`,
                    }}
                  >
                    {!loading && !loadingOverride && `${cancelLabel}`}

                    {(loading || loadingOverride) && (
                      <LoadingContainer size={'sm'} />
                    )}
                  </CancelButton>
                )}

                {callbackReset && (
                  <CancelButton
                    type={'reset'}
                    disabled={loading || disabled}
                    style={{
                      fontSize: `${fontSize}`,
                    }}
                  >
                    {!loading && !loadingOverride && `${resetLabel}`}

                    {(loading || loadingOverride) && (
                      <LoadingContainer size={'sm'} />
                    )}
                  </CancelButton>
                )}

                {callbackSubmit && (
                  <SaveButton
                    type={'submit'}
                    disabled={loading || disabled}
                    style={{
                      fontSize: `${fontSize}`,
                      backgroundColor: `${backgroundColor}`,
                      color: `${color}`,
                      borderRadius: `${borderRadius}`,
                    }}
                  >
                    {!loading && !loadingOverride && `${submitLabel}`}

                    {(loading || loadingOverride) && (
                      <LoadingContainer size={'sm'} />
                    )}
                  </SaveButton>
                )}
              </DivFlexRight>
            </SpaceBetweenDiv>
          </Col>
        </Row>
      )}
    </Form>
  )
}

DefaultForm.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      subPlaceholder2: PropTypes.string,
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number,
      maxLength: PropTypes.number,
      rows: PropTypes.number,
      empty: PropTypes.bool,
      mask: PropTypes.string,
      style: PropTypes.object,
      formatOptionLabel: PropTypes.func,
      maxWidth: PropTypes.string,
      searchButton: PropTypes.bool,
      searchButtonLabel: PropTypes.bool,
      searchButtonText: PropTypes.string,
      searchIcon: PropTypes.string,
      callbackSearch: PropTypes.func,
      createButton: PropTypes.bool,
      infoText: PropTypes.string,
    }),
  ),
  onValidate: PropTypes.func,
  callbackDelete: PropTypes.func,
  callbackCancel: PropTypes.func,
  callbackReset: PropTypes.func,
  callbackSubmit: PropTypes.func,
  onErrorMessage: PropTypes.string,
  onNotFoundMessage: PropTypes.string,
  onBadRequestMessage: PropTypes.string,
  defaultValue: PropTypes.object,
  submitLabel: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  onChangeData: PropTypes.func,
}
