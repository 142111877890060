function getStore() {
  const store = localStorage.getItem('persist:tripmee-traveler:root')

  return JSON.parse(store)
}

function getAuthStore() {
  return JSON.parse(getStore().auth)
}

export { getStore, getAuthStore }
