const KANBAN_LIST_FORMS_INPUT = [
  {
    type: 'text',
    name: 'title',
    label: 'Título da lista',
    required: true,
    max: 50,
    sm: 12,
    lg: 12,
  },
  {
    type: 'check',
    name: 'status',
    label: 'Mostrar lista',
    sm: 6,
    lg: 4,
  },
  {
    type: 'check',
    name: 'showTravelerButton',
    label: 'Mostrar botão de criar viajante nos cards',
    sm: 6,
    lg: 4,
  },
  {
    type: 'check',
    name: 'showProposalButton',
    label: 'Mostar botão de criar proposta nos cards',
    sm: 6,
    lg: 4,
  },
  {
    type: 'check',
    name: 'useAsInitContact',
    label: 'Usar essa coluna como inicio de contato',
    sm: 6,
    lg: 4,
  },
  {
    type: 'check',
    name: 'useAsConversionRate',
    label: 'Usar cards dessa lista para calcular Taxa de Conversão',
    sm: 6,
    lg: 4,
  },
  {
    type: 'check',
    name: 'useAsLossRate',
    label: 'Usar cards dessa lista para calcular Taxa de Perda',
    sm: 6,
    lg: 4,
  },
]

export { KANBAN_LIST_FORMS_INPUT }
