import { Form } from '@unform/web'
import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row, Spinner } from 'reactstrap'
import Logo from '~/components/imgs/logotripmee_parafundoroxo.png'
import Background from '~/components/imgs/senha_bg1.jpg'
import Input from '~/components/InputPassword'
import { axiosAuth } from '~/services/api'
import { Button, Container, Content, LinkBack } from '~/styles/password/styles'

export default function RequestRecoveryPassword(props) {
  const formRef = useRef()
  const navigate = useNavigate()
  const [password, setPass] = useState('')
  const [code, setCode] = useState('')
  const [confirmPassword, setPassConfirm] = useState('')
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  async function handleSubmit() {
    try {
      setLoading(true)

      if (!password || !confirmPassword) {
        setLoading(false)

        return toast.info('Você deve preencher todos os campos.')
      }

      if (password !== confirmPassword) {
        setLoading(false)

        return toast.info('As senhas não correspondem.')
      }

      if (
        password.length < 8 ||
        password.length > 12 ||
        !password.match(/\d/g) ||
        !password.match(/[a-z]/gi)
      ) {
        setLoading(false)

        return toast.info(
          'A senha deve possuir de 8 a 12 caracteres, contendo obrigatoriamente letras e números.',
        )
      }

      await axiosAuth.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/user/forgot-password/change`,
        {
          Email: location.state,
          Code: code,
          Password: password,
        },
      )

      setLoading(false)

      toast.success('Sua senha foi alterada com sucesso!')

      navigate('/')
    } catch (error) {
      setLoading(false)

      if (error && error.response) {
        return toast.info(error.response.data)
      }

      return toast.info(
        'Não foi possivel redefinir sua senha, tente novamente mais tarde.',
      )
    }
  }

  return (
    <Container>
      <img
        src={Background}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: '20%',
          position: 'absolute',
        }}
      />
      <Content>
        <img
          src={Logo}
          style={{
            maxWidth: '200px',
            marginBottom: '32px',
          }}
        />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row className='login-wrapper'>
            <Col sm={12} lg={6}>
              <h2>Alteração de senha</h2>

              <p>
                Para sua segurança, a senha deve <br />
                possuir de 8 a 12 caracteres, contendo <br /> obrigatoriamente
                letras e números.
              </p>
            </Col>
            <Col sm={12} lg={6}>
              <Input
                name='code'
                placeholder='Código de 6 dígitos'
                value={code}
                maxLength={6}
                onChange={e => setCode(e.target.value)}
              />
              <Input
                name='password'
                placeholder='Nova senha'
                type='password'
                value={password}
                onChange={e => setPass(e.target.value)}
              />
              <Input
                name='confirmPassword'
                placeholder='Confirme sua senha'
                type='password'
                value={confirmPassword}
                onChange={e => setPassConfirm(e.target.value)}
              />
              <Button className='button' type='submit' disabled={loading}>
                {loading ? <Spinner color='#FFFF' /> : 'Alterar senha'}
              </Button>
            </Col>
            <LinkBack className='back-link' to='/'>
              {'< Voltar'}
            </LinkBack>
          </Row>
        </Form>
      </Content>
    </Container>
  )
}
