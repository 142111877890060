import { createContext } from 'react'

const DEFAULT_VALUE = {
  pageContentClass: '',
  pageFloatSubMenu: '',
  pageFloatSubMenuOffset: '',
  pageFloatSubMenuTop: 'auto',
  pageFloatSubMenuLeft: 'auto',
  pageFloatSubMenuBottom: 'auto',
  pageFloatSubMenuLineTop: 'auto',
  pageFloatSubMenuLineBottom: 'auto',
  pageFloatSubMenuArrowTop: 'auto',
  pageFloatSubMenuArrowBottom: 'auto',
  pageHeader: true,
  pageSidebar: true,
  pageHeaderLanguageBar: true,
  pageContent: true,
  pageFooter: true,
  pageMobileTopMenu: true,
  pageRightSidebarToggled: true,
  pageheaderMegaMenu: false,
  hasScroll: false,
  pageSidebarWide: false,
  pageSidebarLight: false,
  pageSidebarMinify: false,
  pageSidebarToggled: false,
  pageSidebarTransparent: false,
  pageSidebarSearch: false,
  pageFloatSubMenuActive: false,
  pageContentFullHeight: false,
  pageContentFullWidth: false,
  pageContentInverseMode: false,
  pageTopMenu: false,
  pageTwoSidebar: false,
  pageRightSidebar: false,
  pageMobileRightSidebarToggled: false,
  handleSetPageHeader: () => {},
  handleSetPageHeaderLanguageBar: () => {},
  handleSetPageHeaderMegaMenu: () => {},
  handleSetPageSidebar: () => {},
  handleSetPageSidebarWide: () => {},
  handleSetPageSidebarLight: () => {},
  handleSetPageSidebarMinified: () => {},
  handleSetPageSidebarTransparent: () => {},
  handleSetPageSidebarSearch: () => {},
  handleSidebarOnMouseOut: () => {},
  handleSidebarOnMouseOver: () => {},
  handleFloatSubMenuOnMouseOver: () => {},
  handleFloatSubMenuOnMouseOut: () => {},
  handleFloatSubMenuClick: () => {},
  handleSetPageContent: () => {},
  handleSetPageContentClass: () => {},
  handleSetPageContentFullHeight: () => {},
  handleSetPageContentFullWidth: () => {},
  handleSetPageContentInverseMode: () => {},
  handleSetPageFooter: () => {},
  handleSetPageTopMenu: () => {},
  handleSetPageTwoSidebar: () => {},
  handleSetPageRightSidebar: () => {},
  handleSetBodyWhiteBg: value => {
    if (value === true) {
      document.body.classList.add('bg-white')
    } else {
      document.body.classList.remove('bg-white')
    }
  },
  handleSetPageBoxedLayout: value => {
    if (value === true) {
      document.body.classList.add('boxed-layout')
    } else {
      document.body.classList.remove('boxed-layout')
    }
  },
  toggleSidebarMinify: () => {},
  toggleMobileSidebar: () => {},
  toggleMobileTopMenu: () => {},
  toggleRightSidebar: () => {},
  toggleMobileRightSidebar: () => {},
}

const HoldingBackofficeContext = createContext(DEFAULT_VALUE)

export { DEFAULT_VALUE }

export default HoldingBackofficeContext
