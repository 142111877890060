import ItineraryList from '~/pages/TravelResume/components/ItineraryList'
// import DocsList from './DocsList'
import DestinysList from '../../components/DestinysList'
// import ExperiencesList from '../../components/ExperiencesList'
// import RestaurantsList from '../../components/RestaurantsList'

export default function Main({ travel, nameAgency }) {
  return (
    <div>
      <ItineraryList nameAgency={nameAgency} travel={travel} />

      {travel.destinys &&
        travel.destinys.length > 0 &&
        nameAgency.name === 'Wee Travel' && (
          <DestinysList nameAgency={nameAgency} destinys={travel.destinys} />
        )}

      {/* {travel.experiences && travel.experiences.length > 0 && (
        <ExperiencesList experiences={travel.experiences} />
      )}

      {travel.restaurants && travel.restaurants.length > 0 && (
        <RestaurantsList restaurants={travel.restaurants} />
      )} */}

      {/* {travel.travelResumeDocs && travel.travelResumeDocs.length > 0 && (
        <DocsList travelDocs={travel.travelResumeDocs} />
      )} */}
    </div>
  )
}
