import { useContext, useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody, PanelHeader } from '../Panel'
import Table from '../Table'
// import { toast } from 'react-toastify'
import { Div, TextAlignStart, ChangeStatusButton } from './style'
import { LinkRegisterNewTraveler } from '../Link/styles'
// import ModalDefault from '~/components/ModalDefault'
import context from '~/context/createContext'

export default function SubTableOfTraveler({ travelerId, travelId }) {
  const { axiosApis } = GetAxios()
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(false)
  const { showModalService, setShowModalService, setValueModal } =
    useContext(context)

  useEffect(() => {
    requestApiServiceGet()
  }, [showModalService])

  async function requestApiServiceGet() {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/service/${travelId}`,
      )

      setServices(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  // async function requestApiServicePut(serviceId) {
  //   try {
  //     console.log(serviceId)
  //     setLoading(true)

  //     await axiosApis.put(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/service/UpdateStatusOnly/${serviceId}`,
  //     )

  //     await requestApiServiceGet()
  //     setLoading(false)
  //     toast.success('Status do serviço atualizado com sucesso!')
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  return (
    <Div>
      <Panel>
        <TextAlignStart>
          <PanelHeader noButton title={'Serviços'}>
            <LinkRegisterNewTraveler
              to='/relationship-customers/enrollment-create'
              state={{ travelId, travelerId }}
            >
              Novo Serviço
            </LinkRegisterNewTraveler>
          </PanelHeader>
        </TextAlignStart>

        <PanelBody>
          <Table
            data={services}
            loading={loading}
            columns={[
              {
                Header: 'Tipo',
                accessor: 'type',
                width: 150,
              },
              {
                Header: 'Consultor responsável',
                accessor: 'agent',
                width: 300,
              },
              {
                Header: 'Data de cadastro',
                accessor: 'createdAt',
                width: 150,
              },
              {
                Header: 'Status',
                accessor: 'status',
                Cell: props => {
                  return props.row.original.status ? (
                    <i
                      className='fas fa-check-circle'
                      style={{ color: 'green' }}
                    ></i>
                  ) : (
                    <i className='fas fa-clock'></i>
                  )
                },
                width: 90,
              },
              // {
              //   Header: '',
              //   accessor: 'validate',
              //   Cell: props => {
              //     return (
              //       <ChangeStatusButton
              //         type='button'
              //         onClick={() =>
              //           requestApiServicePut(props.row.original.id)
              //         }
              //       >
              //         Mudar status
              //       </ChangeStatusButton>
              //     )
              //   },
              //   width: 150,
              // },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <ChangeStatusButton
                      type='button'
                      onClick={() => {
                        setValueModal(props.row.original)
                        // await requestApiServiceGet()
                        setShowModalService(!showModalService)
                      }}
                    >
                      Detalhes
                    </ChangeStatusButton>
                  )
                },
                width: 80,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Div>
  )
}
