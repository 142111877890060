import { useEffect, useState } from 'react'
import { LinkEdit } from '~/components/Link/styles'
import { toast } from 'react-toastify'
import ReactTable from '~/components/Table'
import { GetAxios } from '~/services/api'
import { WppButton } from '~/components/Link/styles'
import Tooltip from '~/components/Tooltip'
import { Rating } from 'primereact/rating'

export default function Table({ data, loading }) {
  const { axiosApis } = GetAxios()
  const [roles, setRoles] = useState([])
  const [agency, setAgency] = useState()

  const [validation, setValidation] = useState()

  useEffect(() => {
    let cancel = false

    getSource()

    async function loadSource() {
      if (cancel) return

      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
        )

        return setRoles(data)
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function getSource() {
    try {
      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/AllUsers`,
      )

      setValidation(response.data.happyBirthDay)

      setAgency(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function reportWpp(id, fone, name_viajante) {
    try {
      if (window.confirm('Deseja enviar a mensagem de aniversário?')) {
        const body = {
          id,
          fone,
          name_viajante,
        }

        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/quotation/happysend-wpp`,
          body,
        )
        toast.success('Mensagem enviada com sucesso!')
      }
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data)
        console.log(err.response)
      }
    }
  }

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'Viajante',
          accessor: 'name_viajante',
          width: 200,
        },
        {
          Header: 'Telefone',
          accessor: 'fone',
          width: 150,
        },
        {
          Header: 'E-mail',
          accessor: 'email',
          width: 250,
        },
        {
          Header: 'Aniversário',
          accessor: 'birthday',
          width: 150,
        },
        {
          Header: 'Classificação',
          accessor: '',
          width: 140,
          Cell: props => {
            return <Rating cancel={false} value={props.row.original.rating} />
          },
        },
        {
          Header: '',
          accessor: 'whatsappSend',
          Cell: props => {
            return (
              <div>
                {props.row.original.fone != 0 &&
                props.row.original.fone != null &&
                props.row.original.sendHappyMessage ? (
                  <div>
                    {!validation && (
                      <Tooltip
                        target={`wppbutton${props.row.original.id}`}
                        text='Habilite o botão adicionando um texto para envio de mensagens'
                      />
                    )}

                    <WppButton
                      id={`wppbutton${props.row.original.id}`}
                      type='button'
                      style={
                        !validation ? { color: '#aaa', cursor: 'default' } : {}
                      }
                      onClick={
                        validation
                          ? () =>
                              reportWpp(
                                props.row.original.id,
                                props.row.original.fone,
                                props.row.original.name_viajante,
                              )
                          : () => {}
                      }
                    >
                      Parabenizá-lo(a) por WhatsApp
                    </WppButton>
                  </div>
                ) : (
                  <b></b>
                )}
              </div>
            )
          },
          width: 160,
        },
      ]}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
