import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import DownloadFile from '~/functions/DownloadFile'
import { GetAxios } from '~/services/api'

export default function DownloadDoc() {
  const params = useParams()
  const { axiosAuth } = GetAxios()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data } = await axiosAuth.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs/download/${params.token}/${params.type}`,
        )

        DownloadFile(
          `data:application/octet-stream;base64,${data.data}`,
          data.filename,
        )

        toast.info('Aguarde enquanto o documento é baixado...')

        return setTimeout(() => {
          window.close()
        }, 5000)
      } catch (error) {
        if (error.response.status === 404) {
          return toast.info('Documento não encontrado!')
        }

        if (error.response.status === 401) {
          return toast.info(
            'Link expirou, solicite um novo link ao seu consultor!',
          )
        }

        toast.info(error.response.data ?? 'Link inválido!')

        return setTimeout(() => {
          window.close()
        }, 5000)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return <div />
}
