import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styledComponents from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import LoadingContainer from '~/components/LoadingContainer'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
// import { getMoment } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import './style.scss'

export default function RequestRefund() {
  const { axiosApis } = GetAxios()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [sended, setSended] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [agency, setAgency] = useState()
  const [file1, setFile1] = useState()
  const [file2, setFile2] = useState()
  const [file3, setFile3] = useState()
  const [file4, setFile4] = useState()

  document.title = 'Solicitação de Reembolso - Tripmee'

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/refund/check-token`,
          {
            params: {
              token: params.token,
            },
          },
        )

        setAgency(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    getSource()
  }, [])

  async function onValidate() {
    if (
      window.confirm(
        'Ao prosseguir com a solicitação de reembolso, você receberá um email de confirmação. Deseja continuar?',
      )
    ) {
      return true
    }

    return false
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('token', params.token)

      if (data.name) formData.append('name', data.name)
      if (data.email) formData.append('email', data.email)
      if (data.phone) formData.append('phone', data.phone)
      if (data.company) formData.append('company', data.company)
      if (data.state) formData.append('state', data.state)
      if (data.city) formData.append('city', data.city)

      if (data.bankData) formData.append('bankData', data.bankData)

      if (data.ticket1) formData.append('ticket1', data.ticket1)
      if (data.loc1) formData.append('loc1', data.loc1)
      if (data.pax1) formData.append('pax1', data.pax1)
      if (data.cia1) formData.append('cia1', data.cia1)
      if (data.reason1) formData.append('reason1', data.reason1)
      if (data.voluntary1) formData.append('voluntary1', data.voluntary1.value)
      if (data.type1) formData.append('type1', data.type1.value)

      if (data.ticket2) formData.append('ticket2', data.ticket2)
      if (data.loc2) formData.append('loc2', data.loc2)
      if (data.pax2) formData.append('pax2', data.pax2)
      if (data.cia2) formData.append('cia2', data.cia2)
      if (data.reason2) formData.append('reason2', data.reason2)
      if (data.voluntary2) formData.append('voluntary2', data.voluntary2.value)
      if (data.type2) formData.append('type2', data.type2.value)

      if (data.ticket3) formData.append('ticket3', data.ticket3)
      if (data.loc3) formData.append('loc3', data.loc3)
      if (data.pax3) formData.append('pax3', data.pax3)
      if (data.cia3) formData.append('cia3', data.cia3)
      if (data.reason3) formData.append('reason3', data.reason3)
      if (data.voluntary3) formData.append('voluntary3', data.voluntary3.value)
      if (data.type3) formData.append('type3', data.type3.value)

      if (data.ticket4) formData.append('ticket4', data.ticket4)
      if (data.loc4) formData.append('loc4', data.loc4)
      if (data.pax4) formData.append('pax4', data.pax4)
      if (data.cia4) formData.append('cia4', data.cia4)
      if (data.reason4) formData.append('reason4', data.reason4)
      if (data.voluntary4) formData.append('voluntary4', data.voluntary4.value)
      if (data.type4) formData.append('type4', data.type4.value)

      if (file1) formData.append('attachFile1', file1)
      if (file2) formData.append('attachFile2', file2)
      if (file3) formData.append('attachFile3', file3)
      if (file4) formData.append('attachFile4', file4)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/refund`,
        formData,
      )

      toast.success('Solicitação enviada com sucesso!')

      setSended(true)

      return setLoading(false)
    } catch (error) {
      toast.info('Não foi possível enviar a solicitação.')

      return setLoading(false)
    }
  }

  return (
    <Container style={{ padding: '50px' }}>
      {!cancel && !sended && agency && (
        <>
          <div className='titleImage'>
            <img
              style={{ maxWidth: '200px', paddingBottom: '10px' }}
              src={`data:image/jpeg;base64, ${agency.image}`}
            />
            <PageTitle
              title={'Solicitação de Reembolso'}
              noButton
              // centered={'center'}
            />
            <img
              style={{ maxWidth: '200px', opacity: 0 }}
              src={`data:image/jpeg;base64, ${agency.image}`}
            />
          </div>

          <Panel>
            <PanelHeader noButton background='#ccc' />

            <PanelBody>
              {!loading && !sended && (
                <DefaultForm
                  onValidate={onValidate}
                  callbackSubmit={onSubmit}
                  callbackCancel={() => {
                    if (window.confirm('Você deseja cancelar a solicitação?')) {
                      setCancel(true)
                    }
                  }}
                  submitLabel={'Solicitar Reembolso'}
                  fontSize='16px'
                  backgroundColor='#7f2ec2'
                  color='#fff'
                  borderRadius='10px'
                  inputs={[
                    {
                      empty: true,
                      text: 'Este formulário é somente para <b>novas solicitações</b> de reembolso.',
                      sm: 12,
                    },
                    {
                      type: 'text',
                      name: 'name',
                      label: 'Nome completo',
                      placeholder: 'Nome completo',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'email',
                      name: 'email',
                      label: 'E-mail',
                      placeholder: 'usuario@email.com',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'phone',
                      label: 'Telefone',
                      placeholder: 'Telefone',
                      mask: '(99) 9999-9999',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'company',
                      label: 'Empresa/Corp',
                      placeholder: 'Empresa/Corp',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'city',
                      label: 'Cidade',
                      placeholder: 'Cidade',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'state',
                      label: 'Estado',
                      placeholder: 'Estado',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      empty: true,
                      text: '<b>Informe os dados bancários</b>',
                      sm: 12,
                      lg: 12,
                    },
                    {
                      type: 'textarea',
                      name: 'bankData',
                      label: 'Dados bancários',
                      placeholder: 'Dados bancários',
                      required: true,
                      maxLength: 1000,
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: '<p><b>Preencha os dados (até 4 bilhetes).</b></p>No caso de involuntários, anexar cópia dos documentos no fim do formulário (atestado médico, óbito, waiver, etc.)',
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: '<b>Serviço 1</b>',
                      sm: 12,
                    },
                    {
                      type: 'select',
                      name: 'type1',
                      label: 'Tipo de Serviço',
                      placeholder: 'Selecione...',
                      options: [
                        { label: 'Bilhete Aéreo', value: 'Bilhete Aéreo' },
                        { label: 'Hotel', value: 'Hotel' },
                        {
                          label: 'Serviços (Tudo que não for bilhete ou hotel)',
                          value: 'Servico',
                        },
                      ],
                      required: true,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'loc1',
                      label: 'Localizador',
                      placeholder: 'Localizador',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'ticket1',
                      label: 'Bilhete',
                      placeholder: 'Bilhete',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'pax1',
                      label: 'Passageiro',
                      placeholder: 'Passageiro',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'cia1',
                      label: 'Cia / Hotel / Operadora',
                      placeholder: 'Cia / Hotel / Operadora',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'select',
                      name: 'voluntary1',
                      label: 'É Voluntário? ',
                      placeholder: 'Selecione...',
                      options: [
                        { label: 'Sim', value: 'Sim' },
                        { label: 'Não', value: 'Não' },
                      ],
                      required: true,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'textarea',
                      name: 'reason1',
                      label: 'Descreva o motivo',
                      placeholder: 'Descreva o motivo',
                      required: true,
                      maxLength: 1000,
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: '<b>Serviço 2</b>',
                      sm: 12,
                    },
                    {
                      type: 'select',
                      name: 'type2',
                      label: 'Tipo de Serviço',
                      placeholder: 'Selecione...',
                      options: [
                        { label: 'Bilhete Aéreo', value: 'Bilhete Aéreo' },
                        { label: 'Hotel', value: 'Hotel' },
                        {
                          label: 'Serviços (Tudo que não for bilhete ou hotel)',
                          value: 'Servico',
                        },
                      ],
                      required: false,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'loc2',
                      label: 'Localizador',
                      placeholder: 'Localizador',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'ticket2',
                      label: 'Bilhete',
                      placeholder: 'Bilhete',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'pax2',
                      label: 'Passageiro',
                      placeholder: 'Passageiro',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'cia2',
                      label: 'Cia / Hotel / Operadora',
                      placeholder: 'Cia / Hotel / Operadora',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'select',
                      name: 'voluntary2',
                      label: 'É Voluntário? ',
                      placeholder: 'Selecione...',
                      options: [
                        { label: 'Sim', value: 'Sim' },
                        { label: 'Não', value: 'Não' },
                      ],
                      required: false,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'textarea',
                      name: 'reason2',
                      label: 'Descreva o motivo',
                      placeholder: 'Descreva o motivo',
                      required: false,
                      maxLength: 1000,
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: '<b>Serviço 3</b>',
                      sm: 12,
                    },
                    {
                      type: 'select',
                      name: 'type3',
                      label: 'Tipo de Serviço',
                      placeholder: 'Selecione...',
                      options: [
                        { label: 'Bilhete Aéreo', value: 'Bilhete Aéreo' },
                        { label: 'Hotel', value: 'Hotel' },
                        {
                          label: 'Serviços (Tudo que não for bilhete ou hotel)',
                          value: 'Servico',
                        },
                      ],
                      required: false,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'loc3',
                      label: 'Localizador',
                      placeholder: 'Localizador',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'ticket3',
                      label: 'Bilhete',
                      placeholder: 'Bilhete',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'pax3',
                      label: 'Passageiro',
                      placeholder: 'Passageiro',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'cia3',
                      label: 'Cia / Hotel / Operadora',
                      placeholder: 'Cia / Hotel / Operadora',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'select',
                      name: 'voluntary3',
                      label: 'É Voluntário? ',
                      placeholder: 'Selecione...',
                      options: [
                        { label: 'Sim', value: 'Sim' },
                        { label: 'Não', value: 'Não' },
                      ],
                      required: false,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'textarea',
                      name: 'reason3',
                      label: 'Descreva o motivo',
                      placeholder: 'Descreva o motivo',
                      required: false,
                      maxLength: 1000,
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: '<b>Serviço 4</b>',
                      sm: 12,
                      lg: 12,
                    },
                    {
                      type: 'select',
                      name: 'type4',
                      label: 'Tipo de Serviço',
                      placeholder: 'Selecione...',
                      options: [
                        { label: 'Bilhete Aéreo', value: 'Bilhete Aéreo' },
                        { label: 'Hotel', value: 'Hotel' },
                        {
                          label: 'Serviços (Tudo que não for bilhete ou hotel)',
                          value: 'Servico',
                        },
                      ],
                      required: false,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'loc4',
                      label: 'Localizador',
                      placeholder: 'Localizador',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'ticket4',
                      label: 'Bilhete',
                      placeholder: 'Bilhete',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'pax4',
                      label: 'Passageiro',
                      placeholder: 'Passageiro',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'text',
                      name: 'cia4',
                      label: 'Cia / Hotel / Operadora',
                      placeholder: 'Cia / Hotel / Operadora',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'select',
                      name: 'voluntary4',
                      label: 'É Voluntário? ',
                      placeholder: 'Selecione...',
                      options: [
                        { label: 'Sim', value: 'Sim' },
                        { label: 'Não', value: 'Não' },
                      ],
                      required: false,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 4,
                    },
                    {
                      type: 'textarea',
                      name: 'reason4',
                      label: 'Descreva o motivo',
                      placeholder: 'Descreva o motivo',
                      required: false,
                      maxLength: 1000,
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: '<b>Anexar arquivos</b>',
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: 'São aceitos arquivos dos tipos Pdf, Jpg, Png, Word, Doc, Docx ou Gif, com tamanho de até 1 mb cada.',
                      sm: 12,
                    },
                    {
                      type: 'file',
                      name: 'attachFile1',
                      label: 'Arquivo 1',
                      onChange: e => setFile1(e.target.files[0]),
                      sm: 12,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'file',
                      name: 'attachFile2',
                      label: 'Arquivo 2',
                      onChange: e => setFile2(e.target.files[0]),
                      sm: 12,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'file',
                      name: 'attachFile3',
                      label: 'Arquivo 3',
                      onChange: e => setFile3(e.target.files[0]),
                      sm: 12,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'file',
                      name: 'attachFile4',
                      label: 'Arquivo 4',
                      onChange: e => setFile4(e.target.files[0]),
                      sm: 12,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      empty: true,
                      text: `
                      <b>Atenção as orientações:</b>

                      <p>- Para cálculos de reembolso, por gentileza contatar seu consultor;</p>

                      <p>- É obrigatório que você solicite ao seu consultor <b>o cancelamento da reserva antes de solicitar o reembolso</b>, algumas cias aéreas cobram NOSHOW ou suspendem o bilhete, em que não dá direito a reembolso.</p>

                      <p>- Para cias aéreas <b>GOL</b> e <b>AZUL</b>, é necessário o envio do localizador com o nome do passageiro, caso haja localizador dividido, mencionar no ato da solicitação no campo "Descrever Motivos".</p>

                      <p>- Se o prazo da solicitação de reembolso estiver próximo a expirar um (01) ano, contatar o departamento de reembolso, antes do envio do mesmo.</p>`,
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: '<b>Ao informar seus dados e clicar em "Solicitar Reembolso", você concorda com nossos <a href="/privacy-policy.pdf" target="_blank">Termos, Política de Privacidade e Política de Cookies.</a></b>',
                      sm: 12,
                    },
                  ]}
                />
              )}

              {loading && <LoadingContainer size={'md'} height={'400px'} />}
            </PanelBody>
          </Panel>

          <p style={{ textAlign: 'center' }}>
            <b>{agency.name}</b>
            <br></br>
            <b>{agency.phone}</b>
            <br></br>
            <b>{agency.email}</b>
          </p>
        </>
      )}

      {!loading && !agency && <p>Link inválido!</p>}

      <p style={{ textAlign: 'center' }}>
        {sended && (
          <b>Sua solicitação foi enviada. Você já pode fechar esta página.</b>
        )}

        {cancel && <b>Você já pode fechar essa página!</b>}
      </p>
    </Container>
  )
}

const Container = styledComponents.div`

`
