function FormatValueBRL(value) {
  return getLocaleString(value ?? 0, 'pt-BR', 'BRL')
}

function FormatValueUSD(value) {
  return getLocaleString(value ?? 0, 'en-US', 'USD')
}

function GetCurrencyValue(value) {
  if (value) return parseInt(value.replace(/\D/g, '')) / 100

  return 0
}

function FormatInputValue({
  value = '',
  maxValue = 18,
  onChangeCallback = () => {},
}) {
  if (value && value.length < maxValue) {
    var newValue = GetCurrencyValue(value)

    if (newValue > 0) return onChangeCallback(FormatValueBRL(newValue))
    else return onChangeCallback(FormatValueBRL(0))
  }

  if (!value) return onChangeCallback(FormatValueBRL(0))
}

function getLocaleString(value, locale = 'pt-BR', currency = 'BRL') {
  return value
    .toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .replace('R$', '')
}

export { FormatValueBRL, FormatValueUSD, GetCurrencyValue, FormatInputValue }
