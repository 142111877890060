import IconButton from '~/components/IconButton'

//campos filtro
export const inputsDefaultForm = tagOptions => {
  const inputs = [
    {
      type: 'text',
      name: 'name',
      label: 'Nome ou sobrenome',
      placeholder: 'Nome ou sobrenome',
      sm: 12,
      lg: 4,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      placeholder: 'E-mail',
      sm: 12,
      lg: 4,
    },
    {
      type: 'phone',
      name: 'phone',
      label: 'Telefone',
      mask: '(99) 99999-9999',
      sm: 12,
      lg: 4,
    },
    {
      type: 'select',
      name: 'order',
      label: 'Ordenar por:',
      isClearable: true,
      options: [
        {
          label: 'Ordem Alfabética',
          value: 'alf',
        },
      ],
      maxLength: 150,
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'tag',
      label: 'Tags',
      isClearable: true,
      options: tagOptions,
      isMulti: true,
      sm: 12,
      lg: 2,
    },
    {
      type: 'check',
      name: 'vip',
      label: 'Viajante VIP',
      sm: 12,
      lg: 2,
    },
  ]

  return inputs
}

//tabela
export const inputsTable = ({ user, showDetails, handleDelete }) => {
  const inputs = [
    {
      Header: 'Cod',
      accessor: 'value',
      width: 190,
    },
    {
      Header: 'Nome',
      accessor: 'label',
      width: 240,
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      width: 120,
    },
    {
      Header: 'Status',
      accessor: 'active',
      width: 80,
      Cell: props => {
        if (props.row.original.active) return <span>Ativo</span>

        return <span>Desativado</span>
      },
    },
    {
      Header: '',
      accessor: 'details',
      Cell: props => {
        return (
          <IconButton
            id={props.row.index}
            type={'details'}
            onClick={() => showDetails(props.row.original)}
            tooltipOverride={'Editar'}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'delete',
      Cell: props => {
        return (
          <IconButton
            id={props.row.index}
            type={'trash'}
            onClick={() => handleDelete(props.row.original)}
          />
        )
      },
      width: 20,
    },
  ]

  return inputs
}

//cadastro
export const inputsDefaultFormDetails = (
  itemCode,
  typeDescription,
  templates,
  setSelectTemplate,
) => {
  const inputs = [
    {
      empty: true,
      text: `<b>${typeDescription}</b>`,
      sm: 12,
    },
    {
      type: 'text',
      name: 'code',
      label: 'Código',
      required: true,
      maxLength: 150,
      sm: 12,
      lg: 2,
      disabled: !!itemCode,
    },
    {
      type: 'text',
      name: 'name',
      label: 'Nome',
      required: true,
      maxLength: 150,
      sm: 12,
      lg: 3,
    },
    {
      type: 'text',
      name: 'description',
      label: 'Descrição',
      maxLength: 150,
      sm: 12,
      lg: 3,
    },
    {
      type: 'check',
      name: 'active',
      label: 'Ativo',
      sm: 12,
      lg: 1,
    },
  ]

  if (templates)
    inputs.push({
      type: 'select',
      name: 'template',
      label: 'Template',
      options: templates,
      placeholder: 'Selecione...',
      onChange: e => setSelectTemplate(e.target.value),
      sm: 3,
      md: 3,
      lg: 3,
      xl: 3,
    })

  return inputs
}
