import { useEffect, useState } from 'react'
import { LinkEdit } from '~/components/Link/styles'
import ReactTable from '~/components/Table'
import { getDateFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function Table({ data, loading }) {
  const { axiosApis } = GetAxios()
  const [roles, setRoles] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
        )

        return setRoles(data)
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function showDocuments(e) {
    navigate('/travelers/documents', { state: { traveler: e } })
  }

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'Viajante',
          accessor: 'fullName',
          width: 200,
        },
        {
          Header: 'Documento',
          accessor: 'title',
          width: 200,
        },
        {
          Header: 'Data de Expiração',
          accessor: 'expiration',
          width: 150,
          Cell: props => {
            return props.row.original.expiration
              ? getDateFormated(props.row.original.expiration, 'DD/MM/YYYY')
              : ''
          },
        },
        {
          Header: 'Qte de Dias',
          accessor: 'remainingDays',
          Cell: props => {
            if (props.row.original.remainingDays == 0) {
              return `Expira hoje!`
            }
            if (props.row.original.remainingDays < 0) {
              return `Expirou há ${Math.abs(
                props.row.original.remainingDays,
              )} dia(s)`
            }
            return props.row.original.remainingDays ==
              'Documento sem data de validade'
              ? 'Documento sem data de validade'
              : `Expira em  ${props.row.original.remainingDays} dia(s)`
          },
          width: 150,
        },
        {
          Header: 'Contato do Viajante',
          accessor: 'phone',
          width: 200,
        },
        {
          Header: '',
          accessor: 'docs',
          Cell: props => {
            return (
              // <LinkEdit
              //   to={'/travelers/documents'}
              //   state={{ traveler: props.row.original }}
              // >
              //   Ver Documentos
              // </LinkEdit>
              <IconButton
                id={props.row.index}
                type={'file'}
                tooltipOverride={'Ver Arquivo'}
                onClick={() => showDocuments(props.row.original)}
              />
            )
          },
          width: 20,
        },
      ]}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
