import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { EditButton } from '~/components/Button/styles'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { getDateDiffLabel, getDateFormated, getMoment } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import { Avatar } from '~/styles/divs'
import Edit from './Edit'
import { LabelHighlight, RightCol } from './styles'

export default function AgentDetails() {
  const navigate = useNavigate()
  const location = useLocation()
  const [agent, setAgent] = useState(location.state)
  const [isEditing, setIsEditing] = useState(false)
  const { axiosApis } = GetAxios()
  const [profile, setProfile] = useState('')

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
        )

        data.map(item => {
          if (item.value === agent.role) {
            setProfile(item.label)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onCancel() {
    setIsEditing(false)
  }

  async function onDelete() {
    setIsEditing(false)

    navigate(-1)
  }

  return (
    <Container>
      <PageTitle title={agent ? agent.familyName : ''} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          {agent ? (
            <Container>
              {!isEditing && (
                <>
                  <Row>
                    <Col sm={12} md={6} lg={4} xl={3}>
                      <LabelHighlight>
                        Agência {agent.agency.name}
                      </LabelHighlight>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Nome de Preferência</b>

                      <p>{agent.familyName}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Nome Completo</b>

                      <p>{agent.fullName}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>E-mail</b>

                      <p>{agent.email}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Telefone</b>

                      <p>{agent.phone}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>CPF</b>

                      {agent.cpf && <p>{agent.cpf}</p>}
                      {!agent.cpf && <p>Não preenchido</p>}
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Perfil</b>

                      <p>{profile}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Profissão</b>

                      <p>{agent.title}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Anos de Experiência</b>

                      <p>
                        {getDateDiffLabel(
                          getDateFormated(agent.since, 'YYYY-MM-DD HH:mm:ss'),
                          getMoment(),
                        )}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Especialidades</b>

                      <ul type='none'>
                        {agent.experiences.map((item, i) => {
                          return (
                            <li
                              key={i}
                              style={{
                                backgroundColor: 'none',
                                marginRight: '4px',
                              }}
                            >
                              <LabelHighlight>{item}</LabelHighlight>
                            </li>
                          )
                        })}
                      </ul>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Bio</b>

                      <div
                        style={{
                          whiteSpace: 'pre-wrap',
                          maxHeight: '200px',
                          overflow: 'auto',
                        }}
                      >
                        {agent.bio}
                      </div>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Avatar</b>

                      <Avatar
                        style={{
                          width: '160px',
                          height: '160px',
                          backgroundSize: 'cover',
                          backgroundImage: `url('data:image/*;base64,${agent.avatar}')`,
                        }}
                      />
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Status</b>

                      <p>{agent.textDisabled}</p>
                    </Col>
                  </Row>
                </>
              )}

              {isEditing && (
                <Edit data={agent} onCancel={onCancel} onDelete={onDelete} />
              )}

              {!isEditing && (
                <Row>
                  <RightCol>
                    <EditButton
                      type='button'
                      onClick={() => setIsEditing(true)}
                    >
                      Editar
                    </EditButton>
                  </RightCol>
                </Row>
              )}
            </Container>
          ) : (
            ''
          )}
        </PanelBody>
      </Panel>
    </Container>
  )
}
