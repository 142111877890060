import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function TravelersCreate() {
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const [defaultValue, setDefaultValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [ddi, setDdi] = useState({})
  const [selectDdi, setSelectDdi] = useState({})

  useEffect(() => {
    getOptionsDDI()
    setSelectDdi({
      label: '',
      value: '',
    })
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  async function onValidate(data) {
    if (!data.email && !data.phone) {
      toast.info('Informe email ou telefone.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('familyName', data.familyName)
      formData.append('fullName', data.fullName)
      if (data.email) formData.append('email', data.email)
      if (data.rg) formData.append('rg', data.rg)
      if (data.profession) formData.append('profession', data.profession)
      if (data.address) formData.append('address', data.address)
      if (data.city) {
        formData.append('city', data.city?.label)
        formData.append('cityId', data.city?.value)
      }
      if (data.state) formData.append('state', data.state)
      if (data.country) formData.append('country', data.country)
      if (data.phone) formData.append('phone', data.phone)
      if (data.ddi) formData.append('ddi', data.ddi.value)
      if (data.bairro) formData.append('bairro', data.bairro)
      if (data.cep) formData.append('cep', data.cep)
      if (data.cpf) formData.append('cpf', data.cpf)
      formData.append('vip', data.vip ? true : false)
      formData.append('birthday', data.birthday ?? '0001-01-01')
      if (data.perfil) {
        formData.append('perfil', data.perfil)
      }
      if (user.isOperator) {
        formData.append('belongingAgency', data.belongingAgency ?? '')
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
        formData,
      )

      toast.success('Viajante criado com sucesso!')

      return navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  async function onSearchCEP(e, oldData = {}) {
    try {
      setLoading(true)

      var { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/cep/${e.target.value}`,
      )

      if (!data.cep) {
        toast.info('CEP não encontrado!')

        return setLoading(false)
      }

      setDefaultValue({
        ...oldData,
        cep: data.cep,
        address: data.logradouro,
        bairro: data.bairro,
        city: data.localidade,
      })

      return setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }

      return setLoading(false)
    }
  }

  const toOperator = [
    {
      type: 'text',
      name: 'familyName',
      label: 'Nome de Preferência',
      required: true,
      maxLength: 150,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome Completo',
      required: true,
      maxLength: 150,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      maxLength: 150,
    },
    {
      type: 'phone',
      name: 'phone',
      label: 'Telefone',
    },
    {
      type: 'text',
      name: 'belongingAgency',
      label: 'Agência',
    },
    {
      type: 'text',
      name: 'rg',
      label: 'RG/CNH',
      required: false,
    },
    {
      type: 'text',
      name: 'cpf',
      label: 'CPF/CNPJ',
    },
    {
      type: 'date',
      name: 'birthday',
      label: 'Aniversário',
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      required: false,
    },
    {
      type: 'text',
      name: 'bairro',
      label: 'Bairro',
      isClearable: true,
      required: false,
    },
    {
      type: 'city',
      name: 'city',
      label: 'Cidades',
      isClearable: true,
      required: false,
    },
    {
      type: 'text',
      name: 'cep',
      label: 'CEP',
      mask: '99999-999',
      isClearable: true,
      required: false,
    },
    {
      type: 'text',
      name: 'profession',
      label: 'Profissão',
      required: false,
    },
    {
      type: 'textarea',
      name: 'perfil',
      label: 'Perfil do Viajante',
      required: false,
    },
    {
      type: 'check',
      name: 'vip',
      label: 'Viajante VIP',
    },
  ]

  const toAgency = [
    {
      empty: true,
      text: '<b>Dados pessoais</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'familyName',
      label: 'Nome de Preferência',
      required: true,
      maxLength: 150,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome Completo',
      required: true,
      maxLength: 150,
      sm: 12,
      lg: 3,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      maxLength: 150,
      sm: 12,
      lg: 3,
    },
    {
      type: 'select',
      name: 'ddi',
      label: 'DDI',
      options: ddi,
      placeholder: 'Selecione...',
      onChange: e => setSelectDdi(e.target.value),
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    selectDdi.value == '' || selectDdi.value == 55
      ? {
          type: 'phone',
          name: 'phone',
          label: 'Telefone',
          sm: 12,
          lg: 2,
        }
      : {
          type: 'text',
          name: 'phone',
          label: 'Telefone',
          mask: '999 99999999',
          sm: 12,
          lg: 2,
        },
    {
      type: 'text',
      name: 'cpf',
      label: 'CPF/CNPJ',
      sm: 12,
      lg: 3,
    },
    {
      type: 'text',
      name: 'rg',
      label: 'RG/CNH',
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'date',
      name: 'birthday',
      label: 'Aniversário',
      sm: 12,
      lg: 3,
    },
    {
      empty: true,
      text: '<b>Endereço</b>',
      sm: 12,
    },
    {
      type: 'text',
      name: 'cep',
      label: 'CEP',
      mask: '99999-999',
      isClearable: true,
      required: false,
      searchButton: true,
      callbackSearch: onSearchCEP,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      required: false,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'bairro',
      label: 'Bairro',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'city',
      name: 'city',
      label: 'Cidade',
      placeholder: 'Pesquise por uma cidade',
      isClearable: true,
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      empty: true,
      text: '<b>Outras informações</b>',
      sm: 12,
    },
    {
      type: 'textarea',
      name: 'perfil',
      label: 'Perfil do Viajante',
      required: false,
      sm: 12,
      lg: 6,
    },
    {
      type: 'text',
      name: 'profession',
      label: 'Profissão',
      required: false,
      sm: 12,
      lg: 3,
    },
    {
      type: 'check',
      name: 'vip',
      label: 'Viajante VIP',
      sm: 12,
      lg: 3,
    },
  ]

  return (
    <Container>
      <PageTitle title={'Viajantes'} />

      <Panel>
        <PanelHeader title={'Cadastrar Novo Viajante'} noButton />

        <PanelBody>
          <DefaultForm
            onValidate={onValidate}
            callbackSubmit={onSubmit}
            callbackReset={() => {
              return
            }}
            loading={loading}
            defaultValue={defaultValue}
            inputs={user.isOperator == true ? toOperator : toAgency}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
