import { getDateFormated, getMoment } from './Date'

function getTravelsByDay(travels, date) {
  const res = travels.filter(
    x =>
      x.typeInfo == 'Travel' &&
      (getDateFormated(x.start, 'YYYY/MM/DD') ===
        getDateFormated(date, 'YYYY/MM/DD') ||
        getDateFormated(x.end, 'YYYY/MM/DD') ===
          getDateFormated(date, 'YYYY/MM/DD')),
  )

  return res
}

function getRemindersByDay(travels, date) {
  const res = travels.filter(
    x =>
      x.typeInfo == 'Lembrete' &&
      getDateFormated(x.start, 'YYYY/MM/DD') ===
        getDateFormated(date, 'YYYY/MM/DD'),
  )

  return res
}

function getTravelsByMonth(travels, date) {
  const res = travels.filter(
    x =>
      // x.typeInfo == 'Travel'
      //   ?
      getDateFormated(x.start, 'YYYY/MM') ===
        getDateFormated(date, 'YYYY/MM') ||
      getDateFormated(x.end, 'YYYY/MM') === getDateFormated(date, 'YYYY/MM'),
    // : getDateFormated(x.start, 'YYYY/MM') ===
    //   getDateFormated(date, 'YYYY/MM'),
  )
  return res
}

function getLembreteByMonth(travels, date) {
  const res = travels.filter(
    x =>
      getDateFormated(x.start, 'YYYY/MM') === getDateFormated(date, 'YYYY/MM'),
  )

  return res
}

function getTravelsByYear(travels, date) {
  const res = travels.filter(
    x =>
      getDateFormated(x.start, 'YYYY') === getDateFormated(date, 'YYYY') ||
      getDateFormated(x.end, 'YYYY') === getDateFormated(date, 'YYYY'),
  )

  return res
}

function getActuallyTravels(travels) {
  const res = travels.filter(x => {
    if (x.typeInfo === 'Travel') {
      const start = getMoment(getDateFormated(x.start, 'YYYY-MM-DD'))
      const end = getMoment(getDateFormated(x.end, 'YYYY-MM-DD'))
      const today = getMoment(getDateFormated(getMoment(), 'YYYY-MM-DD'))

      if (start <= today && end >= today) {
        return true
      }

      return false
    } else {
      const start = getDateFormated(x.start, 'YYYY-MM-DD')
      const today = getDateFormated(getMoment(), 'YYYY-MM-DD')

      if (start === today) {
        return true
      }

      return false
    }
  })

  return res
}

function getActuallyLembrete(travels) {
  const res = travels.filter(x => {
    const start = getMoment(getDateFormated(x.start, 'YYYY-MM-DD'))
    const today = getMoment(getDateFormated(getMoment(), 'YYYY-MM-DD'))

    if (start <= today) {
      return true
    }

    return false
  })

  return res
}

function getNextTravels(travels) {
  const res = travels.filter(x => {
    if (x.typeInfo === 'Travel') {
      const start = getMoment(x.start, 'YYYY-MM-DD')
      const today = getMoment(getDateFormated(getMoment(), 'YYYY-MM-DD'))

      if (start >= today) {
        return true
      }
      return false
    } else {
      const start = getMoment(x.start, 'YYYY-MM-DD')
      const today = getMoment(getDateFormated(getMoment(), 'YYYY-MM-DD'))

      if (start > today) {
        return true
      }
      return false
    }
  })

  return res
}

function getPastTravels(travels) {
  const res = travels.filter(x => {
    if (x.typeInfo === 'Travel') {
      const end = getMoment(x.end, 'YYYY-MM-DD')
      const today = getMoment(getDateFormated(getMoment(), 'YYYY-MM-DD'))

      if (end <= today) {
        return true
      }

      return false
    } else {
      const start = getMoment(x.start, 'YYYY-MM-DD')
      const today = getMoment(getDateFormated(getMoment(), 'YYYY-MM-DD'))

      if (start < today) {
        return true
      }
      return false
    }
  })

  return res
}

export {
  getTravelsByDay,
  getTravelsByMonth,
  getActuallyLembrete,
  getLembreteByMonth,
  getTravelsByYear,
  getActuallyTravels,
  getNextTravels,
  getPastTravels,
  getRemindersByDay,
}
