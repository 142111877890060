import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import Divisor from '~/components/Divisor'
import PageTitle from '~/components/PageTitle'
import useSalesReleasesForm from '../../hooks/useSalesReleasesForm'
import {
  SalesReleaseFormComponent,
  SalesReleaseFormContainer,
  SalesReleaseFormValuesTable,
} from '../../styles'
import SalesReleasesFormInput from '../SalesReleasesFormInput'

export default function SalesReleasesTransferForm({
  onChange,
  defaultValue = [],
  internalOptionData = {},
}) {
  const { handleSubmit, getValues, register, reset, setValue, watch } =
    useSalesReleasesForm()
  const [list, setList] = useState(defaultValue ?? [])

  useEffect(() => {
    if (!list || list.length == 0) {
      setList([{}])
    }

    if (list[0]) {
      setValue('arrivalAirport', list[0].arrivalAirport)
      setValue('company', list[0].company)
      setValue('arrivalHotel', list[0].arrivalHotel)
      setValue('departureHotel', list[0].departureHotel)
      setValue('flightDepartureAirport', list[0].flightDepartureAirport)
      setValue('flightDeparturecompany', list[0].flightDeparturecompany)
    }

    return () => {
      setList([])
    }
  }, [])

  async function onSubmit(data) {
    const newList = [data]
    let formattedData = formatData(newList)

    setList(newList)

    onChange(newList, formattedData)
  }

  function formatData(data) {
    return data
      .map(
        item => `TRF ${item.arrivalAirport.label} / ${item.arrivalHotel.label}
${item.company.value} ${item.flightNumber} ${item.arrivalDate} ${item.arrivalTime}
TRF ${item.departureHotel.label}/${item.flightDepartureAirport.label}; ${item.checkoutDate} ${item.checkoutTime}
${item.flightDeparturecompany.value} ${item.flightDepartureNumber} ${item.flightDepartureDate} ${item.flightDepartureTime}`,
      )
      .join('\n')
  }

  return (
    <SalesReleaseFormContainer>
      <SalesReleaseFormComponent onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <Row>
            <PageTitle
              title='Aeroporto/hotel: Dados do vôo'
              head='h6'
              noButton
            />

            <SalesReleasesFormInput
              label='Aer. Chegada'
              name='arrivalAirport'
              type='text'
              register={register}
              setValue={setValue}
              defaultValue={list[0]?.arrivalAirport}
              size='sm'
              sm={6}
              md={6}
              lg={3}
              xl={3}
              selectOptions={internalOptionData?.airports ?? []}
            />

            <SalesReleasesFormInput
              label='Cia'
              name='company'
              type='text'
              register={register}
              setValue={setValue}
              defaultValue={list[0]?.company}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
              selectOptions={internalOptionData?.suppliers ?? []}
            />
            <SalesReleasesFormInput
              label='Nr Vôo'
              name='flightNumber'
              type='text'
              register={register}
              defaultValue={list[0]?.flightNumber}
              size='sm'
              sm={6}
              md={6}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Dt. chegada'
              name='arrivalDate'
              type='date'
              register={register}
              defaultValue={list[0]?.arrivalDate}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Hr chegada'
              name='arrivalTime'
              type='time'
              register={register}
              defaultValue={list[0]?.arrivalTime}
              size='sm'
              sm={6}
              md={6}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Hotel'
              name='arrivalHotel'
              type='text'
              register={register}
              setValue={setValue}
              defaultValue={list[0]?.arrivalHotel}
              size='sm'
              sm={6}
              md={6}
              lg={3}
              xl={3}
              selectOptions={internalOptionData?.suppliers ?? []}
            />
          </Row>

          <Row>
            <PageTitle
              title='Hotel/aeroporto: Apanhar passageiros'
              head='h6'
              noButton
            />

            <SalesReleasesFormInput
              label='Hotel'
              name='departureHotel'
              type='text'
              register={register}
              setValue={setValue}
              defaultValue={list[0]?.departureHotel}
              size='sm'
              sm={6}
              md={6}
              lg={3}
              xl={3}
              selectOptions={internalOptionData?.suppliers ?? []}
            />

            <SalesReleasesFormInput
              label='Data'
              name='checkoutDate'
              type='date'
              register={register}
              defaultValue={list[0]?.checkoutDate}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Hora'
              name='checkoutTime'
              type='time'
              register={register}
              defaultValue={list[0]?.checkoutTime}
              size='sm'
              sm={6}
              md={6}
              lg={1}
              xl={1}
            />
          </Row>

          <Row>
            <PageTitle title='Dados do vôo' head='h6' noButton />

            <SalesReleasesFormInput
              label='Aer. Partida'
              name='flightDepartureAirport'
              type='text'
              register={register}
              setValue={setValue}
              defaultValue={list[0]?.flightDepartureAirport}
              size='sm'
              sm={6}
              md={6}
              lg={3}
              xl={3}
              selectOptions={internalOptionData?.airports ?? []}
            />

            <SalesReleasesFormInput
              label='Cia'
              name='flightDeparturecompany'
              type='text'
              register={register}
              setValue={setValue}
              defaultValue={list[0]?.flightDeparturecompany}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
              selectOptions={internalOptionData?.suppliers ?? []}
            />

            <SalesReleasesFormInput
              label='Nr Vôo'
              name='flightDepartureNumber'
              type='text'
              register={register}
              defaultValue={list[0]?.flightDepartureNumber}
              size='sm'
              sm={6}
              md={6}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Dt. Partida'
              name='flightDepartureDate'
              type='date'
              register={register}
              defaultValue={list[0]?.flightDepartureDate}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Hr Oartuda'
              name='flightDepartureTime'
              type='time'
              register={register}
              defaultValue={list[0]?.flightDepartureTime}
              size='sm'
              sm={6}
              md={6}
              lg={1}
              xl={1}
            />
          </Row>

          <Row>
            <Col sm={12} md={12} lg={12} xl={12} className='submit-area'>
              <Button
                size={'sm'}
                type='button'
                color='none'
                onClick={() => reset()}
              >
                Limpar
              </Button>

              <Button size={'sm'} type='submit' color='primary'>
                Adicionar
              </Button>
            </Col>
          </Row>
        </fieldset>
      </SalesReleaseFormComponent>
    </SalesReleaseFormContainer>
  )
}
