import { useNavigate } from 'react-router-dom'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import { toast } from 'react-toastify'
import { GetAxios } from '~/services/api'
import PageTitle from '~/components/PageTitle'
import DefaultForm from '~/components/DefaultForm'

export default function Create() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()

  async function onValidate(data) {
    if (data && data.city && data.city.length === 0) {
      toast.info('Preencha um campo Cidade.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('title', data.title)
      formData.append('desc', data.desc)

      const cityIds = data.city.reduce((acc, obj) => {
        acc.push(obj.value)

        return acc
      }, [])

      formData.append('cityId', cityIds.join(','))

      if (data.flag) formData.append('flag', data.flag ?? '')
      if (data.image) formData.append('image', data.image)
      if (data.tips) formData.append('tips', data.tips)
      if (data.entryAndHabit)
        formData.append('entryAndHabit', data.entryAndHabit)
      if (data.copyright) formData.append('copyright', data.copyright)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperiencedestiny`,
        formData,
      )

      toast.success('Destino criado com sucesso!')

      navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        return toast.info(error.response.data)
      }

      return toast.info('Não foi possível criar destino!')
    }
  }

  return (
    <Container>
      <PageTitle title={'Destinos'} />

      <Panel>
        <PanelHeader title={'Cadastrar Novo Destino'} noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={() => {
              return
            }}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Título',
                required: true,
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'city',
                name: 'city',
                label: 'Cidade',
                placeholder: 'Pesquise por uma cidade...',
                required: true,
                isMulti: true,
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'flag',
                label: 'Tags',
                required: false,
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'html',
                name: 'desc',
                label: 'Descrição',
                required: false,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'image',
                name: 'image',
                label: 'Imagem',
                required: false,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'html',
                name: 'entryAndHabit',
                label: 'Entrada no país e costumes',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'html',
                name: 'tips',
                label: 'Dicas e informações úteis',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'check',
                name: 'copyright',
                label: 'Imagem autoral',
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
