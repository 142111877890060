import { toast } from 'react-toastify'
import { getDateDiff, getMoment } from '~/functions/Date'

export const checkValidRange = (start, end, diffDays, diffMonths) => {
  if ((start && !end) || (!start && end)) {
    toast.info('Preencha os campos "Data inicial" e "Data final".')

    return false
  }

  if (getMoment(start) > getMoment(end)) {
    toast.info('A "Data final" precisa ser maior que a "Data inicial".')

    return false
  }

  if (diffDays) {
    const diff = getDateDiff(start, end)

    if (diff.days > diffDays && diffDays !== 0) {
      toast.info(
        `Informe um período de no máximo ${diffDays} ${
          diffMonths === 1 ? 'dia' : 'dias'
        } para "Data inicial e final".`,
      )

      return false
    }
  }

  if (diffMonths) {
    const diff = getDateDiff(start, end)

    if (diff.months > diffMonths && diffMonths !== 0) {
      toast.info(
        `Informe um período de no máximo ${diffMonths} ${
          diffMonths === 1 ? 'mês' : 'meses'
        } para "Data inicial e final".`,
      )

      return false
    }
  }

  return true
}
