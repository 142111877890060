import styled from 'styled-components'
import { FormGroup } from 'reactstrap'

export const Label = styled.label`
  margin: 2px 4px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  width: 100%;

  i {
    padding: 2px 4px;
  }
`

export const Group = styled(FormGroup)`
  /* padding: -5px; */
  margin-bottom: 10px;
  width: 100%;
`

export const TextAreaV = styled.textarea`
  height: ${props => (props.rows ? 'auto' : '220px !important')};
  border: 1px solid rgb(204, 204, 204);
`

export const MessageSuccess = styled.p`
  color: #1bc25b;
  font-weight: bold;
  font-size: 11px;
`
