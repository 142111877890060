import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'

export default function RelationshipDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()

  async function onUpdate(data) {
    try {
      const formData = new FormData()

      formData.append('payerName', data.payerName)
      formData.append('values', data.values)
      formData.append('paymentDesc', data.paymentDesc)
      formData.append('supplierAccountability', data.supplierAccountability)
      formData.append('saleDetails', data.saleDetails)
      formData.append('type', data.type.value)
      formData.append('dateStart', data.dateStart)
      formData.append('dateEnd', data.dateEnd)
      formData.append('destiny', data.destiny)
      formData.append('description', data.description)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/service/update/${location.state.service.id}`,
        formData,
      )

      toast.success('Serviço editado com sucesso!')

      navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  // async function onDelete() {
  //   if (window.confirm('Você tem certeza que deseja excluir esse cliente?')) {
  //     await axiosApis.delete(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/${location.state.service.id}`,
  //     )

  //     toast.success(`Cliente excluído com sucesso!`)

  //     return navigate(-1)
  //   }
  // }

  // async function onValidate(data) {
  //   if (!data.email && !data.phone) {
  //     toast.info('Informe email ou telefone.')

  //     return false
  //   }

  //   return true
  // }

  return (
    <Container>
      <PageTitle title={'Detalhes do serviço'} />

      <Panel>
        <PanelHeader noButton />
        <PanelBody>
          <DefaultForm
            // onValidate={onValidate}
            defaultValue={location.state.service}
            callbackSubmit={onUpdate}
            callbackReset={() => {
              return
            }}
            inputs={[
              {
                type: 'text',
                name: 'payerName',
                label: 'Nome do pagante',
                placeholder: 'Nome do pagante',
                required: true,
                maxLength: 150,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                type: 'textarea',
                name: 'paymentDesc',
                label:
                  'Descrição da forma de pagamento (conforme fechado com cliente)',
                placeholder:
                  'Descrição da forma de pagamento (conforme fechado com cliente)',
                // required: true,
                // maxLength: 150,
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'supplierAccountability',
                label: 'Prestação de Contas do Fornecedor (Valores)',
                placeholder: 'Prestação de Contas do Fornecedor (Valores)',
                // required: true,
                // maxLength: 150,
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
              {
                type: 'select',
                name: 'type',
                label: 'Tipo',
                placeholder: `${location.state.service.type}`,
                options: [
                  {
                    label: 'Ticket aéreo',
                    value: 'Ticket aéreo',
                  },
                  {
                    label: 'Hotel',
                    value: 'Hotel',
                  },
                  {
                    label: 'Pacote',
                    value: 'Pacote',
                  },
                  {
                    label: 'Cruzeiro',
                    value: 'Cruzeiro',
                  },
                  {
                    label: 'Serviços',
                    value: 'Serviços',
                  },
                  {
                    label: 'Seguro',
                    value: 'Seguro',
                  },
                  {
                    label: 'Locação de carro',
                    value: 'Locação de carro',
                  },
                  {
                    label: 'Rodoviário',
                    value: 'Rodoviário',
                  },
                  {
                    label: 'Ingresso',
                    value: 'Ingresso',
                  },
                  {
                    label: 'Visto',
                    value: 'Visto',
                  },
                  {
                    label: 'Trem',
                    value: 'Trem',
                  },
                  {
                    label: 'Transfer',
                    value: 'Transfer',
                  },
                ],
                required: true,
                maxLength: 150,
              },
              {
                type: 'text',
                name: 'destiny',
                label: 'Destino principal',
                placeholder: 'Destino principal',
                required: true,
                maxLength: 150,
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Data do Início',
                required: true,
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Data do Fim',
              },
              {
                type: 'textarea',
                name: 'description',
                label: 'Descrição',
                lg: 6,
              },
              {
                type: 'tableOfFile',
                url: `service/doc/${location.state.service.id}`,
                label: 'Anexo (voucher)',
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
