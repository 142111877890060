import styled from 'styled-components'

export default function AnalyticsContainer({ title = 'Card', children }) {
  return (
    <Container>
      <Header>
        <span>{title}</span>
      </Header>

      <Content>{children}</Content>
    </Container>
  )
}

const Container = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.1em;
`

const Header = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 8px;

  span {
    font-weight: 500;
  }
`

const Content = styled.div`
  padding: 8px;
`
