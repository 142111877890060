//? Oq está faltando
//! add validação pra aceitar somento pdf de no maximo 2MB

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { EditButton } from '~/components/Button/styles'
import DefaultForm from '~/components/DefaultForm'
// import context from '~/context/createContext'
import { GetAxios } from '~/services/api'

export default function DetailsParticipantForm({ modalData, onClose }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const defaultData = modalData ? { ...modalData } : {}
  const [showFileInput1, setShowFileInput1] = useState(
    modalData?.data1?.file == null ? true : false,
  )
  const [showFileInput2, setShowFileInput2] = useState(
    modalData?.data2?.file == null ? true : false,
  )
  const [fileDocument1, setFileDocument1] = useState(null)
  const [fileDocument2, setFileDocument2] = useState(null)

  // const {
  //   showFileInput1,
  //   setShowFileInput1,
  //   showFileInput2,
  //   setShowFileInput2,
  // } = useContext(context)

  const navigate = useNavigate()

  useEffect(() => {
    setShowFileInput1(modalData?.data1?.file == null ? true : false)
    setShowFileInput2(modalData?.data2?.file == null ? true : false)
  }, [modalData])

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('id', data.id)
      formData.append('desc', data.desc)
      formData.append('file1', fileDocument1)
      formData.append('file2', fileDocument2)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban`,
        formData,
      )

      await onClose()

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function onClickDetails() {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/get-participant-id/${defaultData.id}`,
      )

      setLoading(false)

      return navigate('/pedagogical-event/details', {
        state: {
          id: res.data,
        },
      })
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  return (
    <Container>
      <EditButton onClick={onClickDetails}>Ver todos os Dados</EditButton>

      <br></br>

      <DefaultForm
        defaultValue={defaultData}
        callbackSubmit={onSubmit}
        disabled={loading}
        inputs={[
          showFileInput1
            ? {
                type: 'file',
                name: 'file1',
                label: 'Anexo 1',
                onChange: e => setFileDocument1(e.target.files[0]),
                sm: 12,
                md: 6,
              }
            : {
                type: 'treatedfile',
                name: 'data1',
                label: 'Anexo 1',
                local: 'QuadroDeInscricoes',
                sm: 12,
                md: 6,
              },
          showFileInput2
            ? {
                type: 'file',
                name: 'file2',
                label: 'Anexo 2',
                onChange: e => setFileDocument2(e.target.files[0]),
                sm: 12,
                md: 6,
              }
            : {
                type: 'treatedfile',
                name: 'data2',
                label: 'Anexo 2',
                local: 'QuadroDeInscricoes',
                sm: 12,
                md: 6,
              },
          {
            type: 'textarea',
            name: 'desc',
            label: 'Observações',
            placeholder: 'Digite aqui...',
            sm: 12,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100;
`
