import axios from 'axios'
import { useSelector } from 'react-redux'

const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_AUTH,
  timeout: 40000,
})

const axiosApis = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_APIS,
  timeout: 40000,
})

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_AVAIL,
  timeout: 40000,
})

const GetAxios = () => {
  const controllerApis = new AbortController()

  const controllerAuth = new AbortController()

  const accessToken = useSelector(state => state.auth.accessToken)

  axiosAuth.defaults.headers.Authorization = `Bearer ${accessToken}`

  axiosApis.defaults.headers.Authorization = `Bearer ${accessToken}`

  axiosAuth.defaults.headers.ContentType = `application/json`

  axiosApis.defaults.headers.ContentType = `application/json`

  instance.defaults.headers.Authorization = `Bearer 6ad57933-6b26-4057-a7aa-40cc378feac8`

  instance.defaults.headers.ContentType = `application/json`

  return {
    instance,
    axiosAuth,
    axiosApis,
    controllerApis,
    controllerAuth,
  }
}

export { axiosAuth, axiosApis, GetAxios }
