import { useEffect, useState } from 'react'
import { Form } from 'reactstrap'
import styled from 'styled-components'
import { GetAxios } from '~/services/api'
import Select from '../Select'
import { RegisterNewButton } from '~/components/Button/styles'
import Tooltip from '../Tooltip'
import AddCategoryModal from './AddCategoryModal'
import usePermissions from '~/hooks/usePermissions'

export default function CategorySelect({
  name,
  defaultValue,
  onChange = () => {
    return
  },
  createButton = true,
}) {
  const { axiosApis } = GetAxios()
  const { manageExperiences } = usePermissions()
  const [selected, setSelected] = useState()
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    loadCategories({})

    return () => {
      setOptions([])
    }
  }, [])

  useEffect(() => {
    if (!defaultValue) {
      setSelected(undefined)
    }
  }, [defaultValue])

  const onSelect = e => {
    setSelected(e.target.value)

    onChange({
      target: {
        name: name,
        value: e.target.value,
      },
    })
  }

  async function loadCategories({ desc }) {
    try {
      setLoading(true)

      const { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/getexperiencecategory`,
      )

      setOptions(data)

      if (desc) {
        const result = data.find(x => x.label == desc)

        onSelect({
          target: {
            name: name,
            value: result,
          },
        })
      }

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function callback(desc) {
    setShowModal(false)

    // console.log(desc)

    await loadCategories({ desc })
  }

  async function callbackCancel() {
    setShowModal(false)
  }

  // console.log('cheogu')

  return (
    <Container>
      <Form ref={undefined} onSubmit={undefined}>
        <Select
          disabled={loading}
          isLoading={loading}
          name={name}
          label={'Categoria'}
          placeholder={'Selecione...'}
          options={options}
          value={selected}
          onChange={onSelect}
          isClearable
        />
      </Form>

      {manageExperiences && createButton && (
        <RegisterNewButton
          type='button'
          style={{ margin: 0 }}
          onClick={() => setShowModal(true)}
          disabled={loading}
        >
          <i id='button-add-category' className='fas fa-plus' />

          <Tooltip
            target='button-add-category'
            text='Adicionar nova Categoria'
          />
        </RegisterNewButton>
      )}

      {showModal && (
        <AddCategoryModal callback={callback} callbackCancel={callbackCancel} />
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;

  form {
    width: 100%;
  }

  & > button {
    font-size: 1rem;
    padding: 8px;
  }
`
