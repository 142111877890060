import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import PageTitle from '~/components/PageTitle'
import { useLocation } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { LinkEditExperience } from '~/components/Link/styles'
import { ColInputs } from './styles'

export default function Details() {
  const location = useLocation()

  return (
    <div>
      <PageTitle title={location.state.category.desc} className='page-header' />

      <Panel>
        <PanelHeader title={'Detalhes'} noButton />

        <PanelBody>
          <div style={{ background: '#f4f4f4', padding: '8px' }}>
            <div>
              <Row style={{ width: '100%' }}>
                <Col sm={12} lg={6}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      marginBottom: '16px',
                    }}
                  >
                    <span style={{ margin: '0 4px 4px 0' }}>
                      Nome da Categoria:{' '}
                      <b
                        style={{
                          color: '#7f2ec2',
                        }}
                      >{`${location.state.category.desc}`}</b>
                    </span>
                  </div>
                </Col>

                <Col sm={12} lg={6}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      marginBottom: '16px',
                    }}
                  ></div>
                </Col>
              </Row>

              {!location.state.category.shared && (
                <Row>
                  <ColInputs>
                    <LinkEditExperience
                      to='/experiences/categories/edit'
                      state={{ category: location.state.category }}
                    >
                      Editar
                    </LinkEditExperience>
                  </ColInputs>
                </Row>
              )}
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  )
}
