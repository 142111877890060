import styled from 'styled-components'

export const CreateAction = styled.button`
  font-size: ${props => props.fz ?? '11px'};
  border: none;
  background-color: #000000;
  border-radius: 4px;
  color: white;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;
  border: 1px solid #fff;
`

export const EditAction = styled.button`
  font-size: 11px;
  margin: 0 2px;
  border: none;
  border-radius: 4px;
  font-weight: 700;
  text-decoration: none;
  color: #333;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
    text-decoration: none;
  }
`

export const DeleteAction = styled.button`
  font-size: 11px;
  background-color: #fbba37;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;
`

export const CancelDeleteAction = styled.button`
  font-size: 11px;
  margin: 0 8px;
  border: none;
  border-radius: 4px;
  font-weight: 700;
  text-decoration: none;
  color: #ff7d70;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
    text-decoration: none;
  }
`

export const CancelAction = styled.button`
  font-size: 11px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;
`

export const CloseAction = styled.button`
  font-size: 11px;
  background-color: transparent;
  border: none;
  color: #777;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;

  &:hover {
    background-color: #00000011;
  }
`

export const SubModulesAction = styled.button`
  font-size: 11px;
  background-color: red;
`
