import styled from 'styled-components'

export const Div = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
`

export const TextAlignStart = styled.div`
  text-align: start;
`
export const ChangeStatusButton = styled.button`
  display: flex;
  border: transparent;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0px 12px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
    color: #333;
  }
`
