// import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function ServiceCreate() {
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()
  const location = useLocation()

  // async function onValidate(data) {
  //   if (!data.email && !data.phone) {
  //     toast.info('Informe email ou telefone.')

  //     return false
  //   }

  //   return true
  // }

  async function onSubmit(data) {
    try {
      const formData = new FormData()
      if (data.payerName) {
        formData.append('payerName', data.payerName)
      }
      // formData.append('values', data.values)
      if (data.paymentDesc) {
        formData.append('paymentDesc', data.paymentDesc)
      }
      if (data.supplierAccountability) {
        formData.append('supplierAccountability', data.supplierAccountability)
      }
      // formData.append('saleDetails', data.saleDetails)
      if (data.type.value) {
        formData.append('type', data.type.value)
      }
      formData.append('dateStart', data.dateStart)
      formData.append('dateEnd', data.dateEnd)
      if (data.destiny) {
        formData.append('destiny', data.destiny)
      }
      if (data.description) {
        formData.append('description', data.description)
      }

      for (let i = 0; i <= data.serviceDoc?.length; i++) {
        formData.append('serviceDoc', data.serviceDoc[i])
      }

      formData.append('travelId', location.state.travelId)
      formData.append('travelerId', location.state.travelerId)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/service`,
        formData,
      )

      toast.success('Serviço criado com sucesso!')

      return navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  return (
    <Container>
      <PageTitle title={'Cadastrar novo serviço'} />

      <Panel>
        <PanelHeader noButton />
        <PanelBody>
          <DefaultForm
            // onValidate={onValidate}
            callbackSubmit={onSubmit}
            callbackReset={() => {
              return
            }}
            inputs={[
              // {
              //   empty: true,
              //   text: `<b>1. Dados do Serviço</b>`,
              //   sm: 12,
              // },
              {
                type: 'text',
                name: 'payerName',
                label: 'Nome do pagante',
                placeholder: 'Nome do pagante',
                required: true,
                maxLength: 150,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              // {
              //   type: 'text',
              //   name: 'values',
              //   label: 'Valores',
              //   placeholder: 'Valores',
              //   required: true,
              //   maxLength: 150,
              //   sm: 6,
              //   md: 6,
              //   lg: 6,
              //   xl: 6,
              // },
              {
                type: 'textarea',
                name: 'paymentDesc',
                label:
                  'Descrição da forma de pagamento (conforme fechado com cliente)',
                placeholder:
                  'Descrição da forma de pagamento (conforme fechado com cliente)',
                // required: true,
                // maxLength: 150,
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'supplierAccountability',
                label: 'Prestação de Contas do Fornecedor (Valores)',
                placeholder: 'Prestação de Contas do Fornecedor (Valores)',
                // required: true,
                // maxLength: 150,
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
              // {
              //   type: 'textarea',
              //   name: 'saleDetails',
              //   label: 'Detalhes do Produto/Venda (Voucher)',
              //   placeholder: 'Detalhes do Produto/Venda (Voucher)',
              //   // required: true,
              //   // maxLength: 150,
              // },
              {
                type: 'select',
                name: 'type',
                label: 'Tipo',
                options: [
                  {
                    label: 'Ticket aéreo',
                    value: 'Ticket aéreo',
                  },
                  {
                    label: 'Hotel',
                    value: 'Hotel',
                  },
                  {
                    label: 'Pacote',
                    value: 'Pacote',
                  },
                  {
                    label: 'Cruzeiro',
                    value: 'Cruzeiro',
                  },
                  {
                    label: 'Serviços',
                    value: 'Serviços',
                  },
                  {
                    label: 'Seguro',
                    value: 'Seguro',
                  },
                  {
                    label: 'Locação de carro',
                    value: 'Locação de carro',
                  },
                  {
                    label: 'Rodoviário',
                    value: 'Rodoviário',
                  },
                  {
                    label: 'Ingresso',
                    value: 'Ingresso',
                  },
                  {
                    label: 'Visto',
                    value: 'Visto',
                  },
                  {
                    label: 'Trem',
                    value: 'Trem',
                  },
                  {
                    label: 'Transfer',
                    value: 'Transfer',
                  },
                ],
                required: true,
                maxLength: 150,
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Data do Início',
                // required: true,
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Data do Fim',
                // required: true,
              },
              {
                type: 'text',
                name: 'destiny',
                label: 'Destino da viagem - principal',
                placeholder: 'Destino principal',
                required: true,
                maxLength: 150,
              },
              {
                type: 'textarea',
                name: 'description',
                label: 'Descrição',
                placeholder: 'Descrição',
                lg: 6,
              },
              {
                type: 'multiFileInput',
                name: 'serviceDoc',
                label: 'Anexos',
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
