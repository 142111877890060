import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  ul.nav.nav-tabs > .nav-item > .nav-link {
    padding: 4px 8px;
    cursor: pointer;
  }

  .nav-tabs + .tab-content {
    margin: 8px 0px 0px;
    padding: 0px;
  }

  .submit-area {
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
  }
`
