import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { Form } from '@unform/web'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody } from '~/components/Panel'
import { toast } from 'react-toastify'
import Input from '~/components/Input'
import PageTitle from '~/components/PageTitle'
import {
  ResetButton,
  SaveButton,
  DeleteButton,
} from '~/components/Button/styles'
import { Container, RightCol, LeftCol } from './styles'
import Select from '~/components/Select'
import LoadingContainer from '~/components/LoadingContainer'

export default function EditDocument() {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [travelers, setTravelers] = useState([])
  const typeOptions = [
    {
      label: 'Viagem',
      value: 'travel',
    },
    {
      label: 'Viajante',
      value: 'traveler',
    },
  ]
  const [documentToEdit, setDocumentToEdit] = useState({
    id: location.state.traveler.id,
    title: location.state.traveler.title,
    desc: location.state.traveler.desc,
    type: location.state.traveler.type,
    oldType: typeOptions.find(x => x.label === location.state.traveler.type)
      .value,
    expiresIn: location.state.traveler.expiresIn,
    userId: location.state.traveler.userId,
    travelId: location.state.traveler.travelId,
  })

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
        )

        setTravelers(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function EditSubmit() {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('id', documentToEdit.id)
      formData.append('title', documentToEdit.title)
      formData.append('desc', documentToEdit.desc)
      formData.append('expiresIn', documentToEdit.expiresIn ?? '0001-01-01')
      formData.append(
        'type',
        typeOptions.find(x => x.label === documentToEdit.type).value,
      )
      formData.append('oldType', documentToEdit.oldType)
      formData.append('userId', documentToEdit.userId)
      formData.append('travelId', travel.id)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs`,
        formData,
      )

      setLoading(false)

      toast.success('Documento editado com sucesso!')

      navigate(-1)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível editar o documento.')
    }
  }

  async function onDelete() {
    try {
      setLoading(true)

      if (
        !window.confirm('Você tem certeza que deseja excluir esse documento?')
      ) {
        return setLoading(false)
      }

      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs/deletedoc/${
          documentToEdit.id
        }/${typeOptions.find(x => x.label === documentToEdit.type).value}`,
      )

      setLoading(false)

      toast.success(`Documento excluído com sucesso!`)

      navigate(-1)
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível excluir o documento, por favor tente mais tarde.',
      )
    }
  }

  return (
    <Panel>
      <PanelBody>
        <Container>
          <PageTitle title={'Editar Documento'} head={'h5'} />

          <Form ref={formRef} onSubmit={EditSubmit}>
            <Row>
              <Col sm={12} md={12} lg={6} xl={6}>
                <Input
                  disabled={false}
                  name={'title'}
                  label={'Título *'}
                  value={documentToEdit.title}
                  maxLength={100}
                  onChange={e =>
                    setDocumentToEdit({
                      ...documentToEdit,
                      title: e.target.value,
                    })
                  }
                  required
                />
              </Col>

              <Col sm={12} md={12} lg={6} xl={6}>
                <Input
                  disabled={false}
                  name={'desc'}
                  label={'Descrição'}
                  value={documentToEdit.desc}
                  maxLength={100}
                  onChange={e =>
                    setDocumentToEdit({
                      ...documentToEdit,
                      desc: e.target.value,
                    })
                  }
                />
              </Col>

              <Col sm={12} md={12} lg={4} xl={4}>
                <Select
                  name={'type'}
                  label={'Tipo de Documento'}
                  placeholder={'Selecione...'}
                  options={typeOptions}
                  value={typeOptions.find(x => x.label === documentToEdit.type)}
                  onChange={e => {
                    setDocumentToEdit({
                      ...documentToEdit,
                      type: e.target.value.label,
                    })
                  }}
                  isSearchable
                />
              </Col>

              {documentToEdit.type === 'Viajante' && (
                <>
                  <Col sm={12} md={12} lg={4} xl={4}>
                    <Select
                      label={'Viajante'}
                      name={'travelers'}
                      options={travelers}
                      placeholder={'Selecione...'}
                      value={travelers.find(
                        x => x.value === documentToEdit.userId.toString(),
                      )}
                      onChange={e => {
                        setDocumentToEdit({
                          ...documentToEdit,
                          userId: e.target.value.value,
                        })
                      }}
                      isSearchable
                    />
                  </Col>

                  <Col sm={12} md={12} lg={4} xl={4}>
                    <Input
                      disabled={false}
                      name={'expiresIn'}
                      type={'date'}
                      label={'Data de Expiração'}
                      value={documentToEdit.expiresIn}
                      onChange={e =>
                        setDocumentToEdit({
                          ...documentToEdit,
                          expiresIn: e.target.value,
                        })
                      }
                    />
                  </Col>
                </>
              )}
            </Row>

            <Row>
              <LeftCol>
                <DeleteButton
                  onClick={onDelete}
                  disabled={loading}
                  type='button'
                >
                  {loading ? <LoadingContainer size={'sm'} /> : 'Excluir'}
                </DeleteButton>
              </LeftCol>

              <RightCol>
                <ResetButton
                  onClick={() => navigate(-1)}
                  disabled={loading}
                  type='reset'
                >
                  {loading ? <LoadingContainer size={'sm'} /> : 'Cancelar'}
                </ResetButton>

                <SaveButton disabled={loading} type='submit'>
                  {loading ? <LoadingContainer size={'sm'} /> : 'Salvar'}
                </SaveButton>
              </RightCol>
            </Row>
          </Form>
        </Container>
      </PanelBody>
    </Panel>
  )
}
