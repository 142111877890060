import { toast } from 'react-toastify'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import { getMoment } from '~/functions/Date'

export default function HoldingFilters({
  defaultValue,
  onSubmit,
  onReset,
  branchsOptions,
}) {
  async function onValidate(data) {
    if (data && data.start && !data.end) {
      toast.info('Informe a data final.')

      return false
    }

    if (data && !data.start && data.end) {
      toast.info('Informe a data inicial.')

      return false
    }

    if (data && data.start && data.end) {
      const startDate = getMoment(data.start)
      const endDate = getMoment(data.end)

      if (startDate > endDate) {
        toast.info('A data de início precisa ser menor que o fim.')

        return false
      }
    }

    return true
  }

  return (
    <Container>
      <DefaultForm
        callbackSubmit={onSubmit}
        callbackReset={onReset}
        onValidate={onValidate}
        defaultValue={defaultValue}
        submitLabel={'Pesquisar'}
        inputs={[
          {
            type: 'select',
            name: 'branchs',
            label: 'Franqueados',
            isMulti: true,
            options: branchsOptions,
            sm: 12,
          },
          {
            type: 'date',
            name: 'start',
            label: 'Data de início',
            sm: 12,
            lg: 6,
          },
          {
            type: 'date',
            name: 'end',
            label: 'Data de fim',
            sm: 12,
            lg: 6,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
