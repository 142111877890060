import { Badge } from 'reactstrap'
import styled from 'styled-components'

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
  color: #212529;

  .p-rating {
    margin-bottom: 8px;
    .p-rating-icon {
      font-size: 1.3rem;
    }
  }

  .p {
    font-size: 1rem;
  }

  .i {
    font-size: 68px;
  }
`
export const CustomerInfo = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid red;
`

export const TagContainer = styled.div`
  text-align: center;
`

export const TagBadge = styled(Badge)`
  background-color: ${props => props.theme.colors.primary} !important;
  margin: 4px 4px 0px 4px;
  font-size: 14px;
`

export const AddBadge = styled(Badge)`
  background-color: whitesmoke !important;
  margin: 4px 0px 0px 0px;
  font-size: 14px;
  color: ${props => props.theme.colors.primary} !important;
  cursor: pointer;
`
export const TextHeading = styled.h4`
  word-wrap: break-word;
  width: auto;
  text-align: center;
  margin: 0;
`

export const TextEmail = styled.h4`
  padding-top: 8px;
  word-wrap: break-word;
  width: auto;
  text-align: center;
  font-size: 14px;
  @media screen and (max-width: 1536px) {
    font-size: 12px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 10px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 8px;
  }
  @media screen and (max-width: 768px) {
    font-size: 6px;
  }
  @media screen and (max-width: 640px) {
    font-size: 4px;
  }
`
