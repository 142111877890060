import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '~/styles/theme'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth ?? '1120px'};
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.padding ?? '48px 96px'};
  margin: 0 8px;
  background: ${props => props.bg ?? 'rgba(127, 46, 194, 0.7)'};
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border: 0;

  .login-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h2 {
      margin: 0;
      padding: 0;
      font-size: 32px;
      color: #fff;
      font-weight: bold;
    }

    p {
      font-size: 18px;
      color: #fff;
      line-height: 32px;
      margin: 16px 0;
    }
    a {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 18px;
      text-decoration: none;
      font-weight: bold;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.8;
      }

      svg {
        margin-right: 8px;
      }
    }
  }
  @media (max-width: 1080px) {
    padding: 32px 32px;
  }

  form {
    width: 100%;

    input {
      background-color: transparent;
      color: #fff;
      &:focus {
        color: #fff;
        background-color: transparent;
        border-bottom: 1px solid #fff;
        border-color: none;
        outline: 0;
        box-shadow: none;
      }
    }
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  background: ${colors.FLAME};
  border: 0;
  border-radius: 8px;
  background: rgb(213, 76, 53);
  color: #fff;
  font-weight: 700;
  margin: auto;
  margin-top: 20px;
  padding: 8px 32px;
  text-decoration: none;
  font-size: 18px;
  transition: filter 0.2s;
  justify-content: center;
  opacity: ${props => (props.disabled ? 0.65 : 1)};
`

export const LinkBack = styled(Link)`
  color: #fff;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    color: #fff;
  }
  @media (max-width: 957px) {
    position: absolute;
    right: 0px;
    top: 4px;
  }
`
