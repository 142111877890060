// import { useLocation, useNavigate, Link } from 'react-router-dom'
// import PageTitle from '~/components/PageTitle'
// import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
// import { Container } from './styles'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import context from '~/context/createContext'

export default function Enrollment() {
  const { axiosApis } = GetAxios()
  const { valueModal } = useContext(context)

  async function onUpdate(data) {
    try {
      const formData = new FormData()

      formData.append('payerName', data.payerName)
      formData.append('values', data.values)
      formData.append('paymentDesc', data.paymentDesc)
      formData.append('supplierAccountability', data.supplierAccountability)
      formData.append('saleDetails', data.saleDetails)
      formData.append(
        'type',
        data.type.value == undefined ? valueModal.type : data.type.value,
      )
      formData.append('dateStart', data.dateStart)
      formData.append('dateEnd', data.dateEnd)
      formData.append('destiny', data.destiny)
      formData.append('description', data.description)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/service/update/${valueModal.id}`,
        formData,
      )

      // for (let i = 0; i <= data.serviceDoc?.length; i++) {
      //   formData.append('serviceDoc', data.serviceDoc[i])
      // }

      toast.success('Serviço editado com sucesso!')
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  // async function onDelete() {
  //   if (window.confirm('Você tem certeza que deseja excluir esse cliente?')) {
  //     await axiosApis.delete(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/${location.state.service.id}`,
  //     )

  //     toast.success(`Cliente excluído com sucesso!`)

  //     return navigate(-1)
  //   }
  // }

  // async function onValidate(data) {
  //   if (!data.email && !data.phone) {
  //     toast.info('Informe email ou telefone.')

  //     return false
  //   }

  //   return true
  // }

  // export default function Tabs()

  return (
    <DefaultForm
      defaultValue={valueModal}
      callbackSubmit={onUpdate}
      callbackReset={() => {
        return
      }}
      inputs={[
        {
          type: 'text',
          name: 'payerName',
          label: 'Nome do pagante',
          placeholder: 'Nome do pagante',
          required: true,
          maxLength: 150,
          sm: 12,
          md: 12,
          lg: 12,
          xl: 12,
        },
        // {
        //   type: 'text',
        //   name: 'values',
        //   label: 'Valores',
        //   placeholder: 'Valores',
        //   required: true,
        //   maxLength: 150,
        //   sm: 6,
        //   md: 6,
        //   lg: 6,
        //   xl: 6,
        // },
        {
          type: 'textarea',
          name: 'paymentDesc',
          label:
            'Descrição da forma de pagamento (conforme fechado com cliente)',
          placeholder:
            'Descrição da forma de pagamento (conforme fechado com cliente)',
          // required: true,
          // maxLength: 150,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
        {
          type: 'textarea',
          name: 'supplierAccountability',
          label: 'Prestação de Contas do Fornecedor (Valores)',
          placeholder: 'Prestação de Contas do Fornecedor (Valores)',
          // required: true,
          // maxLength: 150,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
        // {
        //   type: 'textarea',
        //   name: 'saleDetails',
        //   label: 'Detalhes do Produto/Venda (Voucher)',
        //   placeholder: 'Detalhes do Produto/Venda (Voucher)',
        //   // required: true,
        //   // maxLength: 150,
        // },
        {
          type: 'select',
          name: 'type',
          label: 'Tipo',
          placeholder: `${valueModal.type}`,
          options: [
            {
              label: 'Ticket aéreo',
              value: 'Ticket aéreo',
            },
            {
              label: 'Hotel',
              value: 'Hotel',
            },
            {
              label: 'Pacote',
              value: 'Pacote',
            },
            {
              label: 'Cruzeiro',
              value: 'Cruzeiro',
            },
            {
              label: 'Serviços',
              value: 'Serviços',
            },
            {
              label: 'Seguro',
              value: 'Seguro',
            },
            {
              label: 'Locação de carro',
              value: 'Locação de carro',
            },
            {
              label: 'Rodoviário',
              value: 'Rodoviário',
            },
            {
              label: 'Ingresso',
              value: 'Ingresso',
            },
            {
              label: 'Visto',
              value: 'Visto',
            },
            {
              label: 'Trem',
              value: 'Trem',
            },
            {
              label: 'Transfer',
              value: 'Transfer',
            },
          ],
          required: true,
          maxLength: 150,
        },
        {
          type: 'date',
          name: 'dateStart',
          label: 'Data do Início',
          // required: true,
        },
        {
          type: 'date',
          name: 'dateEnd',
          label: 'Data do Fim',
          // required: true,
        },
        {
          type: 'text',
          name: 'destiny',
          label: 'Destino da viagem - principal',
          placeholder: 'Destino principal',
          required: true,
          maxLength: 150,
        },
        {
          type: 'textarea',
          name: 'description',
          label: 'Descrição',
          lg: 6,
        },
        {
          type: 'tableOfFile',
          url: `service/doc/${valueModal.id}`,
          label: 'Anexos (voucher)',
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
      ]}
    />
  )
}
