import { useEffect, useState } from 'react'
import {
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  convertFromHTML,
  DefaultDraftBlockRenderMap,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import DivForHTML from '../DivForHTML'
import InputHtmlControls from './components/InputHtmlControls'
import getSafeBodyFromHTML from '~/utils/getSafeBodyFromHTML'
import Select from '../Select'

export default function InputHtml({
  name,
  value,
  onChange,
  label,
  disabled = false,
  maxLength = 8000,
  options = [],
}) {
  const [textValue, setTextValue] = useState(value)
  const [caractersCount, setCaractersCount] = useState(0)
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(
          substituteBreakLinesForBrHtmlTag(textValue) ?? '',
          getSafeBodyFromHTML,
          createBlockRenderMapForLineBreaks(),
        ),
      ),
    ),
  )

  useEffect(() => {
    setTextValue(value)
  }, [value])

  useEffect(() => {
    if (textValue !== stateToHTML(editorState.getCurrentContent())) {
      const blockRenderMap = createBlockRenderMapForLineBreaks()
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              substituteBreakLinesForBrHtmlTag(textValue) ?? '',
              getSafeBodyFromHTML,
              blockRenderMap,
            ),
          ),
        ),
      )
    }
  }, [textValue])

  function substituteBreakLinesForBrHtmlTag(text) {
    if (!text) return text
    const regexSubstitutions = [{ regex: /\\n/g, substitution: '<br>' }]
    let resultString = text
    for (const { regex, substitution } of regexSubstitutions) {
      resultString = resultString.replace(regex, substitution)
    }
    return resultString
  }

  useEffect(() => {
    const newCaractersCount = editorState
      .getCurrentContent()
      .getPlainText('').length

    setCaractersCount(newCaractersCount)

    const newValue = stateToHTML(editorState.getCurrentContent())

    if (newValue !== textValue) {
      onChange({
        target: {
          name: name,
          value: newValue,
        },
      })
    }
  }, [editorState])

  function createBlockRenderMapForLineBreaks() {
    return DefaultDraftBlockRenderMap.set('br', {
      element: 'br',
    })
  }

  function toggleInlineStyle(e) {
    const newState = RichUtils.toggleInlineStyle(editorState, e)

    setEditorState(newState)
  }

  function toggleBlockType(e) {
    const newState = RichUtils.toggleBlockType(editorState, e)

    setEditorState(newState)
  }

  return (
    <div style={{ marginBottom: '16px' }}>
      <label style={{ margin: '2px 4px' }}>
        <b style={{ fontSize: '12px', fontWeight: '600' }}>
          {label} ({caractersCount}/{maxLength})
        </b>
      </label>

      {!disabled && (
        <div className='RichEditor-root'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <InputHtmlControls
              editorState={editorState}
              toggleInlineStyle={toggleInlineStyle}
              toggleBlockType={toggleBlockType}
            />

            {options.length > 0 && (
              <Select
                label={''}
                name={'select'}
                options={options}
                onChange={e => setTextValue(value + e.target.value.value)}
                placeholder={'Selecione uma observação'}
                isSearchable={true}
                isClearable={true}
              />
            )}
          </div>

          <div className={'RichEditor-editor'}>
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              handleBeforeInput={() => {
                const currentContentLength = editorState
                  .getCurrentContent()
                  .getPlainText('').length

                if (currentContentLength >= maxLength) {
                  return true
                }

                return false
              }}
              handlePastedText={text => {
                const currentContentLength = editorState
                  .getCurrentContent()
                  .getPlainText('').length

                if (currentContentLength > maxLength - text.length) {
                  return true
                }

                return false
              }}
              handleReturn={() => {
                const currentContent = editorState.getCurrentContent()
                const currentContentLength =
                  currentContent.getPlainText('').length

                if (currentContentLength >= maxLength) {
                  return true
                }

                return false
              }}
            />
          </div>
        </div>
      )}

      {disabled && textValue && <DivForHTML text={textValue} />}
    </div>
  )
}
