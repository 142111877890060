import SkeletonLine from '~/components/Skeleton'
import * as S from '../styles'

export default function SkeletonContacts() {
  const SkeletonContact = ({ ...rest }) => (
    <S.Container {...rest}>
      <S.ContactImage
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url('')`,
        }}
      />
      <S.ContentLeft md={8}>
        <SkeletonLine width={100} />
        <S.Message>
          <SkeletonLine width={200} />
        </S.Message>
      </S.ContentLeft>
      <S.ContentRight md={2}></S.ContentRight>
    </S.Container>
  )

  return (
    <>
      <S.AccordionTop>
        <SkeletonLine width={200} />
      </S.AccordionTop>
      <SkeletonContact data-cy='contact-skeleton-1' />
      <SkeletonContact data-cy='contact-skeleton-2' />
      <SkeletonContact data-cy='contact-skeleton-3' />
      <SkeletonContact data-cy='contact-skeleton-4' />
      <SkeletonContact data-cy='contact-skeleton-5' />
      <SkeletonContact data-cy='contact-skeleton-6' />
      <SkeletonContact data-cy='contact-skeleton-7' />
    </>
  )
}
