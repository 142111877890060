import { Table } from 'reactstrap'
import styled from 'styled-components'

export const SalesReleaseFormContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;

  .row {
    margin: 0px;
    padding: 0px;

    [class^='col'] {
      margin: 0px;
      padding: 2px;
    }
  }
`

export const SalesReleaseSelectRWindow = styled.div`
  .select__menu {
    overflow: auto;
    width: 300px;
    z-index: 3;
  }

  .select__menu > div > div {
    width: max-content !important;
  }

  .select__option {
    white-space: nowrap;
    display: inline-table;
  }

  .select__control {
    min-height: 36px !important;
    height: 36px !important;
  }

  width: 100%;
`

export const SalesReleaseFormComponent = styled.form`
  .form-label {
    width: 100%;
    font-size: 0.85em;
    font-weight: 600;
    margin: 0;
    padding: 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .form-group {
    margin: 0 0 8px !important;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .form-control,
  .form-select {
    height: 36px;
    padding: 4px;
    font-size: 1em;
    font-weight: 500;
    border: 1px solid #ccc;

    i {
      font-size: 1.1em;
      background: red;
    }
  }

  textarea.form-control {
    min-height: 135px;
    max-height: 270px;
    padding: 4px;
    font-size: 1em;
    font-weight: 500;
  }

  .btn-secondary,
  .btn-none {
    font-size: 1.1em;
    min-width: 36px;
    height: 36px;
    padding: 0 4px;
  }

  ul.nav.nav-tabs > .nav-item > .nav-link {
    padding: 4px 8px;
  }

  .nav-tabs + .tab-content {
    margin: 8px 0px 0px;
    padding: 0px;
  }

  .submit-area {
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
  }

  .right-top-floating-button {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    font-size: 1em;
    cursor: pointer;
  }
`

export const SalesReleaseFormValuesTable = styled(Table)`
  font-weight: 600;
  font-size: 0.85em;
  text-align: center;
  vertical-align: middle;

  th {
    font-weight: 600;
    vertical-align: middle;
    padding: 4px 8px;
  }

  thead > tr > th {
    border-bottom: none !important;
  }

  tbody {
    border-top: none !important;
  }
`
