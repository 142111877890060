import styled from 'styled-components'
import Divisor from '~/components/Divisor'
import { FormatValueBRL } from '~/functions/FormatValue'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsTable from '~/components/Analytics/Table'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import useHoldingConsolidatorSalesData from '../../dataHooks/useHoldingConsolidatorSales'

export default function HoldingConsolidatorSales({ filters }) {
  const { loading, dashboards, chartData, tableData } =
    useHoldingConsolidatorSalesData({ filters })

  return (
    <Container>
      <AnalyticsDashboard data={dashboards} />

      <Divisor margin='10px' />

      <AnalyticsContainer title='Vendas emitidas'>
        <AnalyticsLineChart data={chartData} currency />
      </AnalyticsContainer>

      <Divisor margin='10px' />

      <AnalyticsTable
        title='Faturamento por consultor'
        data={tableData}
        loading={loading}
        columns={[
          {
            Header: 'Franqueado',
            accessor: 'branch',
            width: 300,
          },
          {
            Header: 'Consultor',
            accessor: 'issuerName',
            width: 300,
          },
          {
            Header: 'Quantidade de vendas',
            accessor: 'count',
            width: 150,
          },
          {
            Header: 'Faturamento',
            accessor: 'ammount',
            width: 150,
            Cell: props => {
              return FormatValueBRL(props.row.original.ammount)
            },
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div``
