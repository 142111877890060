import { useEffect, useState } from 'react'
import styled from 'styled-components'
import SelectInput from '../Select'

const options = {
  incrementType: [
    {
      label: 'no dia',
      value: 0,
    },
    {
      label: 'antes',
      value: -1,
    },
    {
      label: 'depois',
      value: +1,
    },
  ],
  dateType: [
    {
      label: 'começo',
      value: 'Start',
    },
    {
      label: 'fim',
      value: 'End',
    },
  ],
  date: [
    {
      label: 'viagem',
      value: 'Travel',
    },
  ],
  interval: [
    {
      label: 'dias',
      value: 'days',
    },
  ],
}

export default function TravelDatesInput({
  label,
  name,
  value,
  defaultValue,
  onChange,
  required,
}) {
  const [state, setState] = useState({
    incrementType: undefined,
    dateType: undefined,
    date: undefined,
    increment: 1,
    interval: options.interval[0],
  })

  useEffect(() => {
    if (defaultValue) {
      var incrementType = undefined
      var dateType = undefined
      var date = undefined
      var increment =
        defaultValue.increment < 0
          ? defaultValue.increment * -1
          : defaultValue.increment
      var interval = options.interval[0]

      if (defaultValue.increment === 0) {
        incrementType = options.incrementType.find(x => x.value === 0)
      } else if (defaultValue.increment < 0) {
        incrementType = options.incrementType.find(x => x.value === -1)
      } else if (defaultValue.increment > 0) {
        incrementType = options.incrementType.find(x => x.value === +1)
      }

      options.dateType.map(e => {
        if (defaultValue.baseDate.includes(e.value)) {
          dateType = e
        }
      })

      options.date.map(e => {
        if (defaultValue.baseDate.includes(e.value)) {
          date = e
        }
      })

      setState({
        incrementType,
        dateType,
        date,
        increment,
        interval,
      })
    }
  }, [])

  useEffect(() => {
    var increment = state.increment ? Number(state.increment) : 0
    const baseDate =
      state.dateType && state.date
        ? `${state.date.value}${state.dateType.value}`
        : undefined

    if (state.incrementType) {
      increment = increment * state.incrementType.value
    }

    onChange({
      target: {
        name,
        value: {
          increment,
          baseDate,
        },
      },
    })
  }, [state])

  const onChangeValue = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Container>
      <label>{`${label} ${required ? '*' : ''}`}</label>

      <Group>
        <Select
          name={'incrementType'}
          placeholder={'Selecione...'}
          options={options.incrementType}
          value={state.incrementType}
          onChange={onChangeValue}
        />

        <Span className='text'>do</Span>

        <Select
          name={'dateType'}
          placeholder={'Selecione...'}
          options={options.dateType}
          value={state.dateType}
          onChange={onChangeValue}
        />

        <Span className='text'>da</Span>

        <Select
          name={'date'}
          placeholder={'Selecione...'}
          options={options.date}
          value={state.date}
          onChange={onChangeValue}
        />

        {state.incrementType && state.incrementType.value !== 0 && (
          <>
            <Span className='text'>-</Span>

            <Input
              name={'increment'}
              type={'number'}
              style={{ width: '60px' }}
              min={1}
              max={30}
              maxLength={2}
              value={state.increment}
              onChange={e => {
                if (e.target.value < 1 || e.target.value > 30) {
                  e.target.value = 1
                }

                onChangeValue(e)
              }}
            />

            <Select
              name={'interval'}
              placeholder={'Selecione...'}
              options={options.interval}
              value={state.interval}
              onChange={onChangeValue}
            />
          </>
        )}
      </Group>
    </Container>
  )
}

const Container = styled.div`
  padding: 0;
  margin: 0;

  select {
    height: 38px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    min-width: 100px;
  }

  label {
    font-weight: 600;
    font-size: 12px;
    margin: 2px 4px;
  }
`

const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`

const Span = styled.div`
  height: 38px;
  padding: 0 4px;
`

const Select = styled(SelectInput)`
  min-width: 100px;
`

const Input = styled.input`
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 1rem;
`
