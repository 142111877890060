import { Form } from '@unform/web'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { EditButton, SaveButton } from '~/components/Button/styles'
import Select from '~/components/Select'
import usePermissions from '~/hooks/usePermissions'
import { GetAxios } from '~/services/api'
import { DivFlexRight, SpaceBetweenDiv } from '~/styles/divs'
import { TravelDetailBlock } from './styles'

function AgentBlock({ travel }) {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { isAdmin } = usePermissions()
  const [agents, setAgents] = useState([])
  const [selectedAgent, setSelectedAgent] = useState()
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    let cancel = false

    getAgent(cancel)

    return () => {
      cancel = true
    }
  }, [])

  async function getAgent(cancel) {
    if (cancel) return

    try {
      setLoading(true)

      const { data: dataAgents } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
      )

      setAgents(dataAgents)

      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)

      return navigate('/travels')
    }
  }

  async function handleSubmitEdit() {
    try {
      if (!selectedAgent) {
        return toast.info('Selecione um novo consultor!')
      }

      setLoading(true)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/${travel.id}/agent/${selectedAgent.value}`,
      )

      toast.success('Consultor editado com sucesso!')

      setSelectedAgent(undefined)

      setIsEditing(false)

      setLoading(false)

      navigate('/travels')

      navigate('/travels/details', { state: { id: travel.id } })
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível editar o consultor, por favor tente mais tarde.',
      )
    }
  }

  return (
    <div>
      <TravelDetailBlock style={{ fontSize: '14px' }}>
        <SpaceBetweenDiv>
          <h5>Consultor</h5>

          {!isEditing && isAdmin && (
            <EditButton
              style={{ fontSize: '12px' }}
              onClick={() => setIsEditing(true)}
              type='button'
            >
              <i className='fas fa-edit'></i>
            </EditButton>
          )}

          {isEditing && isAdmin && (
            <EditButton
              style={{ fontSize: '12px' }}
              onClick={() => setIsEditing(false)}
              type='button'
            >
              <i className='fas fa-times'></i>
            </EditButton>
          )}
        </SpaceBetweenDiv>

        {!isEditing && (
          <Row>
            <Col sm={12} lg={6}>
              <span>
                <b>Nome: </b> <br />
                {travel.agents[0]?.fullName}
              </span>
            </Col>

            {/* <Col sm={12}>
              <span>
                <b>Telefone: </b> <br />
                {travel.agents[0]?.phone}
              </span>
            </Col>

            <Col sm={12}>
              <span
                style={{
                  wordWrap: 'break-word',
                }}
              >
                <b>E-mail: </b> <br />
                {travel.agents[0]?.email}
              </span>
            </Col> */}
          </Row>
        )}

        {isEditing && (
          <Form
            ref={formRef}
            onSubmit={handleSubmitEdit}
            style={{ width: '100%' }}
          >
            <Row>
              <Col sm={12}>
                <Select
                  label={'Nome'}
                  name={'agent'}
                  options={agents}
                  onChange={e => setSelectedAgent(e.target.value)}
                  placeholder={'Selecione...'}
                  disabled={loading}
                  value={selectedAgent}
                  isSearchable
                />
              </Col>

              <Col sm={12}>
                <DivFlexRight>
                  <SaveButton disabled={loading} type='submit'>
                    Salvar
                  </SaveButton>
                </DivFlexRight>
              </Col>
            </Row>
          </Form>
        )}
      </TravelDetailBlock>
    </div>
  )
}

export default AgentBlock
