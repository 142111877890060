import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import { EditButton } from '~/components/Button/styles'
import { Container } from './styles'
import { getBlob } from '~/functions/File'
import DefaultForm from '~/components/DefaultForm'
import { SpaceBetweenDiv } from '~/styles/divs'
import LoadingContainer from '~/components/LoadingContainer'
import { Panel, PanelBody } from '~/components/Panel'

export default function Presentation() {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [isEditing, setIsEditing] = useState(false)
  const [bannerDesc, setBannerDesc] = useState()
  const [bannerDescAux, setBannerDescAux] = useState()
  const inputs = [
    {
      type: 'image',
      name: 'image',
      label: 'Capa da viagem',
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      style: {
        width: '100%',
      },
    },
    {
      type: 'html',
      label: 'Texto do PDF',
      name: 'desc',
      sm: 12,
      md: 12,
      lg: 6,
      xl: 6,
      maxLength: 10000,
      style: {
        width: '100%',
        wordBreak: 'break-word',
      },
    },
    {
      type: 'html',
      label: 'Texto do App',
      name: 'descApp',
      sm: 12,
      md: 12,
      lg: 6,
      xl: 6,
      maxLength: 10000,
      style: {
        width: '100%',
        wordBreak: 'break-word',
      },
    },
  ]

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelitinerary/banner-desc/${travel.id}`,
        )

        var newImage = undefined

        if (data.image) {
          newImage = await getBlob(data.image)
        }

        setBannerDesc({
          image: newImage,
          desc: data.desc,
          descApp: data.descApp,
        })
        setBannerDescAux({
          image: newImage,
          desc: data.desc,
          descApp: data.descApp,
        })

        setLoading(false)
      } catch (error) {
        setBannerDesc({})
        setBannerDescAux({})

        setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('image', data.image)
      formData.append('desc', data.desc)
      formData.append('descApp', data.descApp)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelitinerary/banner-desc/${travel.id}`,
        formData,
      )

      setBannerDesc({
        desc: data.desc,
        image: data.image,
        descApp: data.descApp,
      })

      setBannerDescAux({
        desc: data.desc,
        image: data.image,
        descApp: data.descApp,
      })

      toast.success('Alterado com sucesso!')

      setIsEditing(false)
    } catch (error) {
      toast.error(error.response.data)

      console.log(error)
    }
  }

  async function onEdit() {
    setBannerDescAux({
      ...bannerDesc,
    })

    setIsEditing(true)
  }

  async function onCancel() {
    setBannerDesc({
      ...bannerDescAux,
    })

    setIsEditing(false)
  }

  return (
    <Panel>
      <PanelBody>
        <Container
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px',
            backgroundColor: '#f4f4f4',
          }}
        >
          <SpaceBetweenDiv>
            <span></span>

            {!isEditing && (
              <EditButton type='button' onClick={onEdit}>
                Editar
              </EditButton>
            )}

            {isEditing && (
              <EditButton type='button' onClick={onCancel}>
                Cancelar
              </EditButton>
            )}
          </SpaceBetweenDiv>

          <br />

          {!loading && bannerDesc && (
            <div style={{ wordBreak: 'break-word' }}>
              <DefaultForm
                defaultValue={bannerDesc}
                callbackSubmit={!isEditing ? undefined : onSubmit}
                inputs={inputs}
                disabled={!isEditing}
              />
            </div>
          )}

          {loading && <LoadingContainer height='240px' />}
        </Container>
      </PanelBody>
    </Panel>
  )
}
