import { useState, useEffect } from 'react'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { Container } from './styles'
import { getDateFormated } from '~/functions/Date'

export default function Edit({ toEdit, onClose, onDelete }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [loadingSearchAirport, setLoadingSearchAirport] = useState(false)

  const [defaultValue, setDefaultValue] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelair/${toEdit.entity.id}`,
        )

        setDefaultValue({
          ...data,
          departure: getDateFormated(data.departure, 'YYYY-MM-DD'),
          arrival: getDateFormated(data.arrival, 'YYYY-MM-DD'),
          timeDeparture: getDateFormated(data.departure, 'HH:mm'),
          timeArrival: getDateFormated(data.arrival, 'HH:mm'),
        })

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    getSource()
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      for (let key in data) {
        if (data[key] != null) {
          formData.append(key, data[key])
        }
      }

      formData.delete('timeDeparture')
      formData.delete('timeArrival')

      formData.set('departure', data.departure + ' ' + data.timeDeparture)
      formData.set('arrival', data.arrival + ' ' + data.timeArrival)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelair`,
        formData,
      )

      setLoading(false)

      toast.success('Aéreo editado com sucesso!')

      onClose(true)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível editar o aéreo.')
    }
  }

  async function onDeleteAir() {
    setLoading(true)

    await onDelete({ id: toEdit.entity.id, type: toEdit.entity.type })

    await onClose(true)

    setLoading(false)
  }

  async function onChangeIata(e) {
    if (!e.target.value) {
      return
    }

    try {
      setLoadingSearchAirport(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelair/search-airport`,
        {
          params: {
            airportIata: e.target.value,
          },
        },
      )

      if (!res.data) {
        setLoadingSearchAirport(false)

        return toast.info('Aeroporto não encontrado.')
      }

      if (e.target.name === 'originIata') {
        setDefaultValue({
          originIata: res.data.id,
          airportOrigin: res.data.name,
          origin: res.data.city,
        })
      }

      if (e.target.name === 'destinyIata') {
        setDefaultValue({
          destinyIata: res.data.id,
          airportDestiny: res.data.name,
          destiny: res.data.city,
        })
      }

      setLoadingSearchAirport(false)
    } catch (error) {
      setLoadingSearchAirport(false)

      return toast.info('Aeroporto não encontrado.')
    }
  }

  return (
    <Container>
      <DefaultForm
        callbackSubmit={onSubmit}
        cancelLabel={'Voltar'}
        defaultValue={defaultValue}
        loading={loading || loadingSearchAirport}
        disabled={loadingSearchAirport}
        callbackDelete={onDeleteAir}
        inputs={[
          {
            type: 'text',
            name: 'ciaIata',
            maxLength: 5,
            label: 'Código cia aérea',
            sm: 3,
            md: 3,
            lg: 2,
            xl: 2,
          },
          {
            type: 'text',
            name: 'cia',
            maxLength: 100,
            label: 'Nome cia aérea',
            sm: 6,
            md: 6,
            lg: 3,
            xl: 3,
          },
          {
            type: 'number',
            name: 'flight',
            label: 'Número do Voo',
            sm: 3,
            md: 3,
            lg: 2,
            xl: 2,
          },
          {
            type: 'text',
            name: 'seat',
            maxLength: 100,
            label: 'Assento',
            sm: 3,
            md: 3,
            lg: 2,
            xl: 2,
          },
          {
            type: 'text',
            name: 'locator',
            maxLength: 100,
            label: 'Bilhete/Localizador',
            sm: 3,
            md: 3,
            lg: 3,
            xl: 3,
          },
          {
            type: 'text',
            name: 'originPurchase',
            maxLength: 100,
            label: 'Fornecedor',
            sm: 6,
            md: 6,
            lg: 6,
            xl: 6,
          },
          {
            type: 'value',
            name: 'tips',
            label: 'Amenidades',
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
          },
          {
            empty: true,
            sm: 12,
            text: '<h6>Embarque</h6>',
          },
          {
            type: 'text',
            name: 'originIata',
            label: 'IATA',
            callbackSearch: onChangeIata,
            searchButton: true,
            sm: 3,
            md: 3,
            lg: 2,
            xl: 2,
          },
          {
            type: 'text',
            name: 'airportOrigin',
            label: 'Nome do aeroporto',
            maxLength: 50,
            sm: 5,
            md: 5,
            lg: 3,
            xl: 3,
          },
          {
            type: 'text',
            name: 'origin',
            label: 'Cidade',
            maxLength: 50,
            sm: 4,
            md: 4,
            lg: 3,
            xl: 3,
          },
          {
            type: 'date',
            name: 'departure',
            label: 'Data',
            required: true,
            sm: 6,
            md: 6,
            lg: 2,
            xl: 2,
          },
          {
            type: 'time',
            name: 'timeDeparture',
            label: 'Horário',
            required: true,
            sm: 6,
            md: 6,
            lg: 2,
            xl: 2,
          },
          {
            empty: true,
            sm: 12,
            text: '<h6>Desembarque</h6>',
          },
          {
            type: 'text',
            name: 'destinyIata',
            label: 'IATA',
            callbackSearch: onChangeIata,
            searchButton: true,
            sm: 3,
            md: 3,
            lg: 2,
            xl: 2,
          },
          {
            type: 'text',
            name: 'airportDestiny',
            label: 'Nome do aeroporto',
            maxLength: 50,
            sm: 5,
            md: 5,
            lg: 3,
            xl: 3,
          },
          {
            type: 'text',
            name: 'destiny',
            label: 'Cidade',
            maxLength: 50,
            sm: 4,
            md: 4,
            lg: 3,
            xl: 3,
          },
          {
            type: 'date',
            name: 'arrival',
            label: 'Data',
            required: true,
            sm: 6,
            md: 6,
            lg: 2,
            xl: 2,
          },
          {
            type: 'time',
            name: 'timeArrival',
            label: 'Horário',
            required: true,
            sm: 6,
            md: 6,
            lg: 2,
            xl: 2,
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Observação',
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
        ]}
      />
    </Container>
  )
}
