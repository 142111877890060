import { useState, useRef } from 'react'
import { Form } from '@unform/web'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import { Col, Row } from 'reactstrap'
import Background from '~/components/imgs/senha_bg2.jpg'
import Logo from '~/components/imgs/logotripmee_parafundoroxo.png'

import { Container, Content, Button, LinkBack } from '~/styles/password/styles'

import Input from '~/components/InputPassword'

import { axiosAuth } from '~/services/api'

import { useNavigate } from 'react-router-dom'

export default function RequestRecoveryPassword() {
  const formRef = useRef(null)

  const [loading, setLoading] = useState(false)

  const [emailForm, setEmailForm] = useState('')

  const navigate = useNavigate()

  async function handleSubmit() {
    try {
      setLoading(true)

      const { data } = await axiosAuth.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/user/forgot-password`,
        { email: emailForm },
      )

      setLoading(false)
      toast.success(data)
      navigate(`/forgot-password-code`, { state: emailForm })
    } catch (error) {
      setLoading(false)
      toast.info(error.response.data)
    }
  }

  return (
    <Container>
      <img
        src={Background}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: '20%',
          position: 'absolute',
        }}
      />
      <Content>
        <img
          src={Logo}
          style={{
            maxWidth: '200px',
            marginBottom: '32px',
          }}
        />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row className='login-wrapper'>
            <Col sm={12} lg={6}>
              <h2>Esqueceu sua senha?</h2>

              <p>
                Informe seu e-mail para receber um código de redefinição de
                senha.
              </p>
            </Col>
            <Col sm={12} lg={6}>
              <Input
                name='email'
                placeholder='Seu e-mail'
                type='email'
                maxLength={50}
                onChange={e => setEmailForm(e.target.value)}
                value={emailForm}
                required
              />

              <Button className='button' type='submit' disabled={loading}>
                {loading ? <Spinner color='#FFFF' /> : 'Alterar senha'}
              </Button>
            </Col>
            <LinkBack to='/'>
              {/* <FiArrowLeft size={16} color="#afa9b5" /> */}
              {'< Voltar'}
            </LinkBack>
          </Row>
        </Form>
      </Content>
    </Container>
  )
}
