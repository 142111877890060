import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const DefaultButton = styled.button`
  display: flex;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  padding: 8px 10px;
  margin-left: 5px;
`

export const ButtonLink = styled(Link)`
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  padding: 5px;
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  margin: 5px;

  &:hover {
    background-color: #00000011;
  }
`

export const SaveButton = styled(DefaultButton)`
  font-weight: bold;
  background-color: transparent;
  color: ${props => (props.color ? props.color : props.theme.colors.primary)};

  &:hover {
    background-color: #00000011;
  }
`

export const ResetButton = styled(DefaultButton)`
  background-color: transparent;
  font-weight: bold;
  color: #333;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
  }
`

export const SearchButton = styled(DefaultButton)`
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  background-color: transparent;

  &:hover {
    background-color: #00000011;
  }
`

export const RegisterNewButton = styled(DefaultButton)`
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  padding: 8px 0px;
  font-weight: bold;
  text-decoration: none;
  color: #1bc25b;
  background-color: transparent;

  &:hover {
    color: #1bc25b;
    background-color: #00000011;
  }
`

export const RegisterNewButton2 = styled.button`
  display: flex;
  justify-content: center;
  border: none;
  color: #4bf28b;
  background-color: ${props => props.theme.colors.primary};
  font-weight: bold;
  height: 100%;

  &:hover {
    color: #7bffbb;
    text-decoration: none;
  }
`

export const RegisterNewCategoryButton = styled(DefaultButton)`
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  padding: 0 8px;
  font-weight: bold;
  color: #4bf28b;
  background-color: transparent;

  &:hover {
    color: #7bffbb;
    background-color: #00000011;
    text-decoration: none;
  }
`

export const EditButton = styled(DefaultButton)`
  font-weight: bold;
  color: #333;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
    text-decoration: none;
  }
`

export const DeleteButton = styled(DefaultButton)`
  font-weight: bold;
  color: #ff7d70;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
    text-decoration: none;
  }
`

export const ComeBackButton = styled(DefaultButton)`
  font-weight: bold;
  color: #ffffff;
  background-color: #25c2ba;
`

export const CancelButton = styled(DefaultButton)`
  font-weight: bold;
  border: none;
  color: #333;
  background-color: transparent;

  &:hover {
    background-color: #00000011;
  }
`
