import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { LinkEdit } from '~/components/Link/styles'
import { getDateFormated } from '~/functions/Date'
import { toast } from 'react-toastify'
import SendToKanbanButton from '../SendToKanbanButton'
import { RegisterNewButton2 } from '~/components/Button/styles'

export default function SchoolList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [eventOptions, setEventOptions] = useState([])
  const [data, setData] = useState([])
  const [defaultValue, setDefaultValue] = useState({})

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const response = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/event/manage/events`,
        )

        setEventOptions(response.data)

        if (response.data.length === 1) {
          await onSubmit({
            event: response.data[0],
          })
        }

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onValidate(data) {
    if (!data || !data.event) {
      toast.info('Selecione uma escola.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      setDefaultValue(data)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/event/manage/participants`,
        {
          params: {
            fullName: data.fullName ?? '',
            eventId: data.event ? data.event.value : undefined,
          },
        },
      )

      setData(response.data)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  const exporData = async () => {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/event/manage/participants/data-for-export`,
        {
          params: {
            fullName: defaultValue.fullName ?? '',
            eventId: defaultValue.event ? defaultValue.event.value : undefined,
          },
        },
      )

      setTimeout(() => {
        exportExcel(response.data)

        setLoading(false)

        toast.success('Arquivo salvo com sucesso!')
      }, 500)

      return
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  const exportExcel = dataToExport => {
    const xlsx = import('xlsx')

    xlsx.then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExport)
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      })

      saveAsExcelFile(excelBuffer, 'participants_details')
    })
  }

  const saveAsExcelFile = (buffer, fileName) => {
    const fileSaver = import('file-saver')

    fileSaver.then(module => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        let EXCEL_EXTENSION = '.xlsx'
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        })

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION,
        )
      }
    })
  }

  return (
    <Container>
      <h1 className='page-header'>Viagens Pedagógicas</h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            defaultValue={defaultValue}
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                name: 'event',
                label: 'Viagem Pedagógica',
                placeholder: 'Selecione...',
                options: eventOptions,
                required: true,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'fullName',
                label: 'Nome do viajante',
                placeholder: 'Nome do viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'school',
                label: 'Nome da Escola',
                placeholder: 'Nome da Escola',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundClip: '#7f2ec2',
          }}
        >
          <PanelHeader title={'Resultado da Pesquisa'} noButton />

          <RegisterNewButton2 onClick={exporData} disabled={loading}>
            EXPORTAR
          </RegisterNewButton2>
        </div>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Posição de incrição',
                accessor: 'id',
                Cell: props => <span>{props.row.original.index}º</span>,
                width: 150,
              },
              {
                Header: 'Data de Inscrição',
                accessor: 'createdAt',
                Cell: props =>
                  getDateFormated(
                    props.row.original.createdAt,
                    'DD/MM/YYYY HH:mm',
                  ),
                width: 150,
              },
              {
                Header: 'Viajante',
                accessor: 'fullName',
                width: 200,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 150,
              },
              {
                Header: '',
                accessor: 'sendToKanban',
                Cell: props => {
                  return <SendToKanbanButton data={props.row.original} />
                },
                width: 200,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <LinkEdit
                      to='details'
                      state={{ id: props.row.original.id }}
                    >
                      Detalhes
                    </LinkEdit>
                  )
                },
                width: 80,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
