import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container, NewTravel } from './styles'
import { getDateFormated } from '~/functions/Date'
import { useSelector } from 'react-redux'
import Table from './Table'
import Divisor from '~/components/Divisor'
import { useContext, useEffect, useState } from 'react'
import { toOperator, toAgency } from './inputs'
import ModalDefault from '~/components/ModalDefault'
import ContentModal from './ContentModal/Travel'
// import RelationshipDetailsModal from '~/pages/RelationshipCustomers/ContactEnrollment/Details/TelaLancamento/index.js'
import context from '~/context/createContext'
import Enrollment from './ContentModal/Enrollment'
import CheckIn from './ContentModal/Checkin'
import Material from './ContentModal/Material'

export default function RelationshipDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  // const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const [loading, setLoading] = useState(false)
  const [travels, setTravels] = useState([])
  const [showModalTravel, setShowModalTravel] = useState(false)
  const [customerId] = useState(location.state.customers.id)
  const {
    showModalService,
    setShowModalService,
    showModalCheckIn,
    setShowModalCheckIn,
    showModalMaterial,
    setShowModalMaterial,
  } = useContext(context)

  useEffect(() => {
    requestApiGettravelsCustomer()
  }, [showModalMaterial, showModalCheckIn])

  async function requestApiGettravelsCustomer() {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/GetTravelsFromCustomer/${customerId}`,
      )

      const result = response.data.reduce((acc, value) => {
        const aux = {
          ...value,
          start: getDateFormated(value.start, 'DD/MM/yyyy HH:mm'),
          end: getDateFormated(value.end, 'DD/MM/yyyy HH:mm'),
        }

        acc.push(aux)
        return acc
      }, [])

      setTravels(result)

      return setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      // toast.info(`${error.response.data}`)
    }
  }

  const defaultValue = {
    fullName: location.state.customers.fullName,
    email: location.state.customers.email,
    phone: location.state.customers.phone,
    cpfCnpj: location.state.customers.cpfCnpj,
    rg: location.state.customers.rg,
    profession: location.state.customers.profession,
    address: location.state.customers.address,
    city: location.state.customers.city,
    state: location.state.customers.state,
    country: location.state.customers.country,
    avatar: location.state.customers.avatar,
    belongingAgency: location.state.customers.belongingAgency,
    birthdayDate: getDateFormated(
      location.state.customers.birthdayDate ?? undefined,
      'YYYY-MM-DD',
    ),
  }

  // async function onValidate(data) {
  //   if (!data.email && !data.phone) {
  //     toast.info('Informe email ou telefone.')

  //     return false
  //   }

  //   return true
  // }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('id', location.state.customers.id)
      formData.append('fullName', data.fullName)
      formData.append('email', data.email ?? '')
      formData.append('phone', data.phone ?? '')
      formData.append('cpfCnpj', data.cpfCnpj ?? '')
      formData.append('rg', data.rg ?? '')
      formData.append('birthdayDate', data.birthdayDate ?? '')
      formData.append('profession', data.profession ?? '')
      formData.append('address', data.address ?? '')
      formData.append('city', data.city ?? '')
      formData.append('state', data.state ?? '')
      formData.append('country', data.country ?? '')
      formData.append('avatar', data.avatar ?? '')
      if (data.belongingAgency) {
        formData.append('belongingAgency', data.belongingAgency ?? '')
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/edit`,
        formData,
      )

      toast.success('Cliente editado com sucesso!')

      // return navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  const handleShowModalTravel = () => {
    setShowModalTravel(!showModalTravel)
  }

  const handleShowModalService = () => {
    setShowModalService(!showModalService)
  }

  const handleShowModalCheckIn = () => {
    setShowModalCheckIn(!showModalCheckIn)
  }

  const handleShowModalMaterial = () => {
    setShowModalMaterial(!showModalMaterial)
  }

  // async function onDelete() {
  //   if (window.confirm('Você tem certeza que deseja excluir esse cliente?')) {
  //     await axiosApis.delete(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/${location.state.customers.id}`,
  //     )

  //     toast.success(`Cliente excluído com sucesso!`)

  //     return navigate(-1)
  //   }
  // }\

  return (
    <Container>
      <PageTitle title={`Novo lançamento para ${defaultValue.fullName}`} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Container>
            <DefaultForm
              // onValidate={onValidate}
              callbackSubmit={onSubmit}
              // callbackDelete={onDelete}
              defaultValue={defaultValue}
              inputs={user.isOperator == true ? toOperator : toAgency}
            />
          </Container>
        </PanelBody>
      </Panel>

      <Divisor />

      {showModalTravel && (
        <ModalDefault
          title='Nova viagem'
          content={
            <ContentModal
              customerId={customerId}
              requestApiGettravelsCustomer={requestApiGettravelsCustomer}
              handleShow={handleShowModalTravel}
            />
          }
          handleShow={handleShowModalTravel}
        />
      )}

      {showModalService && (
        <ModalDefault
          title='Destalhes do serviço'
          content={<Enrollment />}
          handleShow={handleShowModalService}
        />
      )}

      {showModalCheckIn && (
        <ModalDefault
          title='CheckIn'
          content={<CheckIn />}
          handleShow={handleShowModalCheckIn}
        />
      )}

      {showModalMaterial && (
        <ModalDefault
          title='Entrega de material'
          content={<Material />}
          handleShow={handleShowModalMaterial}
        />
      )}

      <Panel>
        <PanelHeader noButton title={'Viagens'}>
          <NewTravel onClick={() => setShowModalTravel(!showModalTravel)}>
            Nova viagem
          </NewTravel>
        </PanelHeader>
        <PanelBody>
          <Table data={travels} loading={loading} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
