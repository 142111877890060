import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { useNavigate } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import { Container } from '~/styles/container'
import PageTitle from '~/components/PageTitle'
import { useState } from 'react'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'

export default function CreateInstitution() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      if (data.image) formData.append('image', data.image)
      if (data.name) formData.append('name', data.name)
      if (data.address) formData.append('address', data.address)
      if (data.phone) formData.append('phone', data.phone)
      if (data.email) formData.append('email', data.email)
      if (data.site) formData.append('site', data.site)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/institution`,
        formData,
      )

      toast.success('Grupo cadastrado com sucesso!')

      navigate(-1)

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle title='Cadastrar novo grupo' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            loading={loading}
            inputs={[
              {
                type: 'image',
                name: 'image',
                label: 'Logo/Capa do Grupo',
                placeholder: 'Logo/Capa do Grupo',
                maxWidth: '200px',
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                type: 'text',
                name: 'name',
                label: 'Nome do Grupo',
                placeholder: 'Nome do Grupo',
                maxWidth: '300px',
                required: true,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              // {
              //   type: 'text',
              //   name: 'address',
              //   label: 'Endereço',
              //   placeholder: 'Endereço',
              //   maxWidth: '300px',
              //   sm: 12,
              //   lg: 6,
              // },
              {
                type: 'text',
                name: 'site',
                label: 'Site',
                placeholder: 'Site',
                maxWidth: '300px',
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                type: 'text',
                name: 'phone',
                label: 'Telefone do Representante',
                placeholder: 'Telefone do Representante',
                mask: '(99) 99999-9999',
                maxWidth: '300px',
                sm: 12,
                lg: 6,
              },
              {
                type: 'email',
                name: 'email',
                label: 'E-mail do Representante',
                placeholder: 'E-mail do Representante',
                maxWidth: '300px',
                sm: 12,
                lg: 6,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
