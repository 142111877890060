import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import Divisor from '~/components/Divisor'
import useSalesReleasesForm from '../../hooks/useSalesReleasesForm'
import {
  SalesReleaseFormComponent,
  SalesReleaseFormContainer,
  SalesReleaseFormValuesTable,
} from '../../styles'
import SalesReleasesFormInput from '../SalesReleasesFormInput'

export default function SalesReleasesAirForm({
  onChange,
  defaultValue = [],
  internalOptionData = {},
}) {
  const { handleSubmit, getValues, register, reset, setValue, watch } =
    useSalesReleasesForm()
  const [list, setList] = useState(defaultValue ?? [])

  useEffect(() => {
    return () => {
      setList([])
    }
  }, [])

  function formatData(data) {
    return data
      .map(
        item =>
          `${item.origin.value}/${item.destiny.value} ${item.cia.value}   ${item.flight} ${item.departureDate} ${item.departureTime} ${item.arrivalTime}`,
      )
      .join('\n')
  }

  async function onSubmit(data) {
    const newList = [...list, data]
    let formattedData = formatData(newList)

    setList(newList)

    onChange(newList, formattedData)
  }

  async function onDelete(e) {
    const newList = list.filter(x => x !== e)
    let formattedData = formatData(newList)

    setList(newList)

    onChange(newList, formattedData)
  }

  return (
    <SalesReleaseFormContainer>
      <SalesReleaseFormComponent onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <Row>
            <SalesReleasesFormInput
              label='Cia'
              name='cia'
              type='text'
              register={register}
              setValue={setValue}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
              selectOptions={internalOptionData?.suppliers ?? []}
            />

            <SalesReleasesFormInput
              label='Voo'
              name='flight'
              type='text'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='De'
              name='origin'
              type='text'
              register={register}
              setValue={setValue}
              size='sm'
              selectOptions={internalOptionData?.airports ?? []}
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Para'
              name='destiny'
              type='text'
              register={register}
              setValue={setValue}
              size='sm'
              selectOptions={internalOptionData?.airports ?? []}
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Data partida'
              name='departureDate'
              type='date'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Hora partida'
              name='departureTime'
              type='time'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Data chegada'
              name='arrivalDate'
              type='date'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Hora chegada'
              name='arrivalTime'
              type='time'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Classe'
              name='classDesc'
              type='text'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='Base tarifária'
              name='fareBase'
              type='text'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='TKT designator'
              name='tktDesignator'
              type='text'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />

            <SalesReleasesFormInput
              label='CO2 Kg'
              name='co2Kg'
              type='text'
              register={register}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={1}
            />
          </Row>

          <Row>
            <Col sm={12} md={12} lg={12} xl={12} className='submit-area'>
              <Button
                size={'sm'}
                type='button'
                color='none'
                onClick={() => reset()}
              >
                Limpar
              </Button>

              <Button size={'sm'} type='submit' color='primary'>
                Adicionar
              </Button>
            </Col>
          </Row>

          <Divisor margin='8px 0' border />

          <SalesReleaseFormValuesTable
            bordered
            hover
            responsive
            striped
            size='sm'
          >
            <thead>
              <tr>
                <th>Cia</th>
                <th>Voo</th>
                <th>De</th>
                <th>Para</th>
                <th>Data partida</th>
                <th>Hora partida</th>
                <th>Data chegada</th>
                <th>Hora chegada</th>
                <th>Classe</th>
                <th>Base tarifária</th>
                <th>TKT designator</th>
                <th>CO2 Kg</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {list.map((e, i) => (
                <tr key={i}>
                  <td>{e.cia?.value}</td>
                  <td>{e.flight}</td>
                  <td>{e.origin?.value}</td>
                  <td>{e.destiny?.value}</td>
                  <td>{e.departureDate}</td>
                  <td>{e.departureTime}</td>
                  <td>{e.arrivalDate}</td>
                  <td>{e.arrivalTime}</td>
                  <td>{e.classDesc}</td>
                  <td>{e.fareBase}</td>
                  <td>{e.tktDesignator}</td>
                  <td>{e.co2Kg}</td>
                  <td>
                    <Button size='sm' color='none' onClick={() => onDelete(e)}>
                      <i className='fas fa-trash' />
                    </Button>
                  </td>
                </tr>
              ))}

              {list.length === 0 && (
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              )}
            </tbody>
          </SalesReleaseFormValuesTable>
        </fieldset>
      </SalesReleaseFormComponent>
    </SalesReleaseFormContainer>
  )
}
