import { useEffect, useMemo, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { toast } from 'react-toastify'
import { getDateDiff, getDateFormated, getMoment } from '~/functions/Date'
import { FormatValueBRL } from '~/functions/FormatValue'
import PageTitle from '~/components/PageTitle'

export default function SalesByIssuerList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [filters, setFilters] = useState({
    consolidators: [],
    issuers: [],
  })

  const [defaultValueFilters, setDefaultValueFilters] = useState({
    start: getDateFormated(getMoment().add(-1, 'month'), 'YYYY-MM-DD'),
    end: getDateFormated(getMoment(), 'YYYY-MM-DD'),
  })

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const responseFilters = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/sales/filters`,
        )

        setFilters({
          issuers: responseFilters.data.issuers,
          consolidators: responseFilters.data.consolidators.map(x => ({
            label: x.label,
            value: x.value,
          })),
        })

        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/sales/byissuer`,
          {
            params: defaultValueFilters,
          },
        )

        setData(response.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    getSource()
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/sales/byissuer`,
        {
          params: {
            start: data?.start,
            end: data?.end,
            integrationId: data.consolidator ? data.consolidator.value : 0,
            issuer: data.issuer ? data.issuer.value : undefined,
          },
        },
      )

      setData(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onValidate = data => {
    if ((data.start && !data.end) || (!data.start && data.end)) {
      toast.info('Preencha os campos Data Inicial e Data Final.')

      return false
    }

    if (getMoment(data.start) > getMoment(data.end)) {
      toast.info('A Data Final precisa ser maior que a Data Inicial.')

      return false
    }

    const diff = getDateDiff(data.start, data.end)

    if (diff.days > 180) {
      toast.info('Informe um período de no máximo 6 meses.')

      return false
    }

    if (!data.start && !data.end && !data.consolidator && !data.issuer) {
      toast.info('Preencha os filtros!')

      return false
    }

    return true
  }

  return (
    <Container>
      <PageTitle title={'Vendas por Consultor'} head={'h1'} />

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={() => {
              setDefaultValueFilters(undefined)

              setData([])

              return
            }}
            submitLabel={'Pesquisar'}
            defaultValue={defaultValueFilters}
            disabled={loading}
            inputs={[
              {
                type: 'date',
                name: 'start',
                label: 'Data inicial',
                sm: 12,
                lg: 3,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Data final',
                sm: 12,
                lg: 3,
              },
              {
                type: 'select',
                name: 'consolidator',
                label: 'Consolidadora',
                placeholder: 'Consolidadora',
                options: filters.consolidators,
                isClearable: true,
                sm: 12,
                lg: 3,
              },
              {
                type: 'select',
                name: 'issuer',
                label: 'Consultor',
                placeholder: 'Consultor',
                options: filters.issuers,
                isClearable: true,
                sm: 12,
                lg: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton />

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            tableProps={{
              hasFooter: true,
            }}
            columns={[
              {
                Header: 'Nome',
                accessor: 'issuerName',
                width: 200,
              },
              {
                Header: 'Qtde.',
                accessor: 'issuesCount',
                width: 80,
                Footer: info => {
                  const count = useMemo(
                    () =>
                      info.rows.reduce(
                        (sum, row) => sum + row.values.issuesCount,
                        0,
                      ),
                    [info.rows],
                  )

                  return <span>{count}</span>
                },
              },
              {
                Header: 'Tarifa',
                accessor: 'fare',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.fare)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.fare, 0),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Taxa',
                accessor: 'tax',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.tax)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.tax, 0),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Markup',
                accessor: 'mkp',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.mkp)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.mkp, 0),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'DU',
                accessor: 'du',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.du)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.du, 0),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'RAV',
                accessor: 'rav',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.rav)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.rav, 0),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Fee',
                accessor: 'fee',
                width: 80,
                Cell: props => {
                  return FormatValueBRL(props.row.original.fee)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.fee, 0),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Seguro',
                accessor: 'insuranceRate',
                width: 70,
                Cell: props => {
                  return FormatValueBRL(props.row.original.insuranceRate)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce(
                        (sum, row) => sum + row.values.insuranceRate,
                        0,
                      ),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Outros',
                accessor: 'other',
                width: 70,
                Cell: props => {
                  return FormatValueBRL(props.row.original.other)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.other, 0),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
              {
                Header: 'Total',
                accessor: 'total',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.total)
                },
                Footer: info => {
                  const sum = useMemo(
                    () =>
                      info.rows.reduce((sum, row) => sum + row.values.total, 0),
                    [info.rows],
                  )

                  return <span>{FormatValueBRL(sum)}</span>
                },
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
