import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import { useSelector } from 'react-redux'
import { inputsDefaultFormDetails } from '../List/inputs'
import { useEffect, useState, useRef } from 'react'

export default function ItemDetails({
  itemCode,
  typeDsc,
  typeId,
  reloadListCallback,
}) {
  const { axiosApis } = GetAxios()
  const user = useSelector(state => state.auth.user)
  const [loading, setLoading] = useState(false)
  const [selectTemplate, setSelectTemplate] = useState()
  const [defaultValue, setDefaultValue] = useState({})
  const tiposRoteiro = useRef([
    { label: 'Aéreo', value: 1 },
    { label: 'Hotel', value: 2 },
    { label: 'Locação de veículos', value: 3 },
    { label: 'Transfer Aer./Hotel/Aer.', value: 5 },
    { label: 'Pacotes', value: 6 },
    { label: 'Outros serviços', value: 7 },
    { label: 'Outros (sem máscara)', value: 4 },
  ])

  useEffect(async () => {
    await getItemData()
  }, [])

  async function getItemData() {
    let data = {}
    let additionalInfoObj = {}

    try {
      setLoading(true)
      console.log(itemCode, typeDsc, typeId)
      if (itemCode) {
        const response = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/salesrelease/generaldata?type=${typeId}&code=${itemCode}`,
        )

        if (response.status === 200) {
          data = response.data[0]
          console.log(response.data[0])
          additionalInfoObj = JSON.parse(data.additionalInfo)
          setDefaultValue({
            code: data.value,
            name: data.label,
            description: data.description,
            template: additionalInfoObj?.template,
            active: data.active,
          })
        }
      } else {
        setDefaultValue({})
      }
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  async function onValidate(data) {
    if (!data.code || !data.name) {
      toast.info('Informe código e o nome.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      const formData = {}

      formData.code = data.code
      formData.name = data.name
      formData.description = data.description
      formData.additionalInfo = selectTemplate
        ? JSON.stringify({ template: selectTemplate })
        : null
      formData.active = data.active
      formData.generalDataTypeId = typeId

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesrelease/savegeneraldata`,
        formData,
      )

      await reloadListCallback()
      toast.success(
        `${typeDsc} ${itemCode ? 'editado' : 'cadastrado'} com sucesso!`,
      )
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  return (
    <Container>
      <PageTitle title={(itemCode ? 'Editar' : 'Cadastrar') + ' ' + typeDsc} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Container>
            <DefaultForm
              onValidate={onValidate}
              resetLabel='Salvar'
              callbackSubmit={onSubmit}
              loading={loading}
              defaultValue={defaultValue}
              inputs={inputsDefaultFormDetails(
                itemCode,
                typeDsc,
                typeId == 1 ? tiposRoteiro.current : null,
                setSelectTemplate,
              )}
            />
          </Container>
        </PanelBody>
      </Panel>
    </Container>
  )
}
