import ReactTable from '~/components/Table'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { GetAxios } from '~/services/api'
import { DeleteButton } from '~/components/Button/styles'

export default function GlobalNotificationsTable({ notifications, loading }) {
  const location = useLocation()
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()

  async function handleDelete(data) {
    if (window.confirm('Tem certeza que deseja excluir essa notificação?')) {
      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/globalnotifications/${data.id}`,
      )

      toast.success('Notificação excluida com sucesso!')

      navigate(-1)
    }
  }
  return (
    <ReactTable
      columns={[
        {
          Header: 'Título',
          accessor: 'title',
          width: 200,
        },
        {
          Header: 'Descrição',
          accessor: 'desc',
          width: 200,
        },
        {
          Header: 'Autor',
          accessor: 'author',
          width: 300,
        },
      ]}
      data={notifications}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
