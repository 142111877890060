import storage from 'redux-persist/lib/storage'

const config = {
  key: 'tripmee-traveler:root',
  version: 1,
  storage,
  // Adicionar reducers na blacklist caso não queira que ele persista o estado
  // blacklist: [
  //   'page',
  //   'chatReducer',
  //   'conversationReducer',
  //   'messageReducer',
  //   'contactReducer',
  // ],
}

export default config
