export const DEFAULT_TABLE_VALUES_SUM = {
  fareSum: 0,
  checkInTaxSum: 0,
  duRavSum: 0,
  otherTaxSum: 0,
  feeSum: 0,
  markupSum: 0,
  cashInvoicePaymentTotal: 0,
  cardProviderTotal: 0,
  agencyCardTotal: 0,
  total: 0,
}
