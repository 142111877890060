import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import { Container } from './styles'
import DefaultForm from '~/components/DefaultForm'
import { getDateFormated } from '~/functions/Date'
import LoadingContainer from '~/components/LoadingContainer'

export default function Create({ toCreate, onClose }) {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)

  async function handleSubmit(data) {
    try {
      setLoading(true)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveltrain`,
        {
          cia: data.cia,
          ciaIata: data.ciaIata,
          seat: data.seat,
          locator: data.locator,
          flight: data.flight,
          originPurchase: data.originPurchase,

          origin: data.origin,
          originIata: data.originIata,
          departure: data.departure + ' ' + data.timeDeparture,
          airportOrigin: data.airportOrigin,

          destiny: data.destiny,
          destinyIata: data.destinyIata,
          arrival: data.arrival + ' ' + data.timeArrival,
          airportDestiny: data.airportDestiny,
          description: data.description,

          tips: data.tips,
          travelId: travel.id,
        },
      )

      toast.success('Trem criado com sucesso!')

      setLoading(false)

      onClose(true)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível criar o Trem.')
    }
  }

  async function onCancel() {
    await onClose()
  }

  return (
    <Container>
      {loading && <LoadingContainer color='#ccc' size='lg' height='200px' />}

      {!loading && (
        <>
          {loading && <LoadingContainer />}

          <DefaultForm
            callbackSubmit={handleSubmit}
            callbackCancel={onCancel}
            cancelLabel={'Limpar'}
            defaultValue={{
              departure: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
              arrival: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
              timeDeparture: getDateFormated(toCreate.date, '00:00'),
              timeArrival: getDateFormated(toCreate.date, '00:00'),
            }}
            loading={loading}
            disabled={loading}
            inputs={[
              {
                type: 'text',
                name: 'cia',
                maxLength: 100,
                label: 'Cia ferroviária',
                placeholder: 'Cia ferroviária',
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'ciaIata',
                maxLength: 5,
                label: 'Código cia ferroviária',
                placeholder: 'Código cia ferroviária',
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'locator',
                maxLength: 100,
                label: 'Bilhete/Localizador',
                placeholder: 'Bilhete/Localizador',
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'originPurchase',
                maxLength: 100,
                label: 'Fornecedor',
                placeholder: 'Fornecedor',
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'seat',
                maxLength: 100,
                label: 'Assento',
                placeholder: 'Assento',
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'number',
                name: 'flight',
                label: 'Número do trem',
                placeholder: 'Número do trem',
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'origin',
                label: 'Cidade de origem',
                placeholder: 'Cidade de origem',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'text',
                name: 'airportOrigin',
                label: 'Estação da cidade de origem',
                placeholder: 'Estação da cidade de origem',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'date',
                name: 'departure',
                label: 'Data da partida',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'time',
                name: 'timeDeparture',
                label: 'Horário da partida',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'text',
                name: 'destiny',
                label: 'Cidade de destino',
                placeholder: 'Cidade de destino',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'text',
                name: 'airportDestiny',
                label: 'Estação da cidade de destino',
                placeholder: 'Estação da cidade de destino',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'date',
                name: 'arrival',
                label: 'Data da chegada',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'time',
                name: 'timeArrival',
                label: 'Horário da chegada',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'value',
                name: 'tips',
                label: 'Amenidades',
                placeholder: 'Descrição',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'description',
                label: 'Observação',
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
            ]}
          />
        </>
      )}
    </Container>
  )
}
