import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonLine = ({ count, height, width, rest }) => {
  return (
    <Skeleton
      count={count ? count : 1}
      height={height}
      width={width}
      {...rest}
    />
  )
}

export default SkeletonLine
