import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function CreatePush() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [travelsOptions, setTravelsOptions] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data: dataTravels } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travel`,
        )

        setTravelsOptions(dataTravels)
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  const handleSubmit = async data => {
    try {
      const formData = new FormData()

      formData.append('title', data.title)
      formData.append('desc', data.desc)
      formData.append('travelId', data.travel.value)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications/push`,
        formData,
      )

      toast.success('Notificação enviada com sucesso!')

      navigate(-1)
    } catch (error) {
      if (error && error.response) {
        return toast.info(error.response.data)
      }

      return toast.error(
        'Não foi possível realizar a solicitação, tente novamente mais tarde.',
      )
    }
  }

  return (
    <Container>
      <PageTitle title={'Enviar Notificação Agora'} head={'h1'} />
      <Panel>
        <PanelHeader noButton></PanelHeader>
        <PanelBody>
          <DefaultForm
            callbackReset={() => {}}
            callbackSubmit={handleSubmit}
            inputs={[
              {
                type: 'select',
                name: 'travel',
                placeholder: 'Selecione uma viagem...',
                label: 'Viagem',
                required: true,
                options: travelsOptions,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                name: 'title',
                type: 'text',
                label: 'Título',
                required: true,
                maxLength: 50,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                name: 'desc',
                type: 'textarea',
                label: 'Descrição',
                required: true,
                maxLength: 200,
                rows: 4,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
