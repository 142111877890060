import { useEffect } from 'react'
import { LinkRegisterNewPanel } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import usePermissions from '~/hooks/usePermissions'
import { Container } from '~/styles/container'
import { SpaceBetweenDiv } from '~/styles/divs'
import { useState } from 'react'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import Table from '~/components/Table'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function HotelList() {
  const { manageDestinys } = usePermissions()
  const { axiosApis } = GetAxios()
  const [hotels, setHotels] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    onSubmit()
  }, [])

  async function onFilter() {
    onSubmit()
  }

  const onSubmit = async data => {
    try {
      setLoading(true)

      const response = await axiosApis.get(`/hotelLibrary`, {
        params: {
          name: data?.name,
          fullAddress: data?.fullAddress,
          city: data?.city?.value,
        },
      })

      setHotels(response.data)

      setLoading(false)
    } catch (error) {
      toast.info('Nenhum resultado encontrado.')
      setLoading(false)
    }
  }

  const onDelete = async hotelId => {
    if (window.confirm('Você tem certeza que deseja remover esse hotel?')) {
      try {
        setLoading(true)

        const response = await axiosApis.delete(
          `/hotelLibrary/${hotelId}/${true}`,
        )

        setHotels(response.data)

        toast.success('Hotel removido com sucesso!')
        setLoading(false)
      } catch (error) {
        toast.info(
          'Não foi possível remover o hotel, por favor entre me contato com o suporte.',
        )
        setLoading(false)
      }
    }
  }

  const onDetails = hotelId => {
    navigate('details', { state: { id: hotelId } })
  }

  return (
    <Container>
      <h1 className='page-header'>
        Hotéis
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'A Tela possui todos os hotéis cadastrados pela agência, essa informação é única da agÊncia e pode ser utilizada na criação de propostas e viagens.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader title='Filtros' noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={onFilter}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Nome do Hotel',
                sm: 12,
                lg: 4,
              },
              {
                type: 'text',
                name: 'fullAddress',
                label: 'Endereço',
                sm: 12,
                lg: 4,
              },
              {
                type: 'city',
                name: 'city',
                label: 'Cidade',
                placeholder: 'Pesquise por uma cidade...',
                sm: 12,
                lg: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton>
          <SpaceBetweenDiv>
            <b>Resultado da pesquisa</b>

            {manageDestinys && (
              <LinkRegisterNewPanel to='create'>
                Novo Hotel
              </LinkRegisterNewPanel>
            )}
          </SpaceBetweenDiv>
        </PanelHeader>

        <PanelBody>
          <Table
            columns={[
              {
                Header: 'Nome do hotel',
                accessor: 'name',
                width: 300,
              },
              {
                Header: 'Endereço',
                accessor: 'fullAddress',
                width: 300,
              },
              {
                Header: 'Cidade',
                accessor: 'city',
                width: 100,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.original.id}
                      type={'detail'}
                      onClick={() => onDetails(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'delete',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.original.id}
                      type={'trash'}
                      onClick={() => onDelete(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            data={hotels}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
