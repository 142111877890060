import { LinkEdit } from '~/components/Link/styles'
import ReactTable from '~/components/Table'

export default function NotificationsTable({ notifications, loading }) {
  return (
    <ReactTable
      columns={[
        {
          Header: 'Notificação',
          accessor: 'title',
          width: 200,
        },
        {
          Header: 'Viagem',
          accessor: 'travel',
          width: 200,
        },
        {
          Header: 'Descrição',
          accessor: 'desc',
          width: 200,
        },
        {
          Header: 'Autor',
          accessor: 'author',
          width: 150,
        },

        {
          Header: 'Tipo',
          accessor: 'baseDate',
          width: 100,
          Cell: props => {
            const scheduled = !!props.row.original.baseDate

            return scheduled ? <span>Agendada</span> : <span>Manual</span>
          },
        },
        {
          Header: '',
          accessor: 'id',
          width: 100,
          Cell: props => {
            const scheduled = !!props.row.original.baseDate

            return scheduled ? (
              <LinkEdit
                to={'edit'}
                state={{ notification: props.row.original }}
              >
                Reagendar
              </LinkEdit>
            ) : (
              ''
            )
          },
        },
      ]}
      data={notifications}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
