import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import AnalyticsTable from '~/components/Analytics/Table'
import Divisor from '~/components/Divisor'
import useHoldingBackofficeCustomersData from '../../dataHooks/useHoldingBackofficeCustomers'
import HeaderInPage from '../../components/HeaderInPage'

export default function HoldingBackofficeCustomers({ filters, handleProfile }) {
  const {
    loading,
    dashboards,
    dashboardsFluxoDeAtendimento,
    tableData,
    canceledProspectionsChart,
    leadContactFormChart,
  } = useHoldingBackofficeCustomersData({
    filters,
    handleProfile,
  })

  const inputsRender = () => {
    const inputs = [
      // {
      //   Header: 'Filial',
      //   accessor: 'branch',
      //   width: 300,
      // },
      {
        Header: 'Destino',
        accessor: 'state',
        width: 300,
      },
      {
        Header: 'Quantidade',
        accessor: 'count',
        width: 300,
      },
    ]

    // const result = handleProfile
    //   ? inputs.filter(input => input.Header != 'Filial')
    //   : inputs

    // return result
    return inputs
  }

  return (
    <Container>
      <AnalyticsDashboard data={dashboards} />

      <Divisor margin='5px' />

      <HeaderInPage title='Fluxo de atendimento' />

      <Divisor margin='5px' />

      <AnalyticsDashboard
        data={dashboardsFluxoDeAtendimento}
        columnSizingPerRow={'33.33%'}
      />

      <Divisor margin='5px' />

      <HeaderInPage title='Leads' />

      <Divisor margin='5px' />

      <Row>
        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Leads perdidos'>
            <AnalyticsLineChart data={canceledProspectionsChart} />
          </AnalyticsContainer>
        </Col>

        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Formas de contato'>
            <AnalyticsLineChart data={leadContactFormChart} />
          </AnalyticsContainer>
        </Col>
      </Row>

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Por onde meus clientes passaram'
        data={tableData}
        loading={loading}
        columns={inputsRender()}
      />
    </Container>
  )
}

const Container = styled.div``
