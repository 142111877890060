import styled from 'styled-components'
import DivForHTML from '~/components/DivForHTML'
import { getDateDDMMM, getTimeFormated } from '~/functions/Date'
import { getFirstWords } from '~/functions/String'

export default function RoadMapItem({ data, index, nameAgency }) {
  // const styleObj = `
  // border: 1px solid black;
  // background-color: #f00;

  // th {
  //   color: #fff
  // }
  // `

  // let style = document.createElement('style')
  // style.type = 'text/css'
  // style.innerHTML = `.cssClass { ${data.desc} }`
  // document.getElementsByTagName('head')[0].appendChild(style)

  // const { axiosApis } = GetAxios()
  // const [image, setImage] = useState()

  // useEffect(() => {
  //   let cancel = false

  //   async function loadSource() {
  //     if (cancel) return

  //     try {
  //       const res = await axiosApis.get(
  //         `${process.env.REACT_APP_ENDPOINT_APIS}/travelresume/daybyday/image/${data.id}`,
  //       )

  //       setImage(res.data.image)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }

  //   loadSource()

  //   return () => {
  //     cancel = true
  //   }
  // }, [])

  return (
    <>
      {nameAgency.name == 'Wee Travel' ? (
        <IconWee className='fas fa-walking' />
      ) : (
        <Icon
          style={{ color: `${nameAgency.primaryColor}` }}
          className='fas fa-walking'
        />
      )}

      <DateTimeLabel>
        {/* {data.start === data.end ? data.turn : getTimeFormated(data.start)} */}
        {(data.start || data.end) && (
          <>
            {data.start === data.end && (
              <>
                {data.turn && ` ${data.turn}`}
                {!data.turn &&
                  data.start &&
                  ` ${getTimeFormated(data.start, 'HH:mm')}`}
              </>
            )}
            {data.start !== data.end && data.turn && ` ${data.turn}`}
            {data.start !== data.end &&
              !data.turn &&
              ` ${getTimeFormated(data.start, 'HH:mm')} ⇝ ${getTimeFormated(
                data.end,
                'HH:mm',
              )}`}
          </>
        )}
        {data.type === 'activity' && !data.start && !data.end && (
          <>{` ${data.turn} `}</>
        )}
      </DateTimeLabel>

      {data.pickUp != '0001-01-01T00:00:00' && (
        <p>
          PickUp: <b>{getTimeFormated(data.pickUp)}</b>
        </p>
      )}

      <DivForHTML
        text={getFirstWords(data.desc, 2000)}
        imgSrc={data.image ? `data:image/*;base64,${data.image}` : undefined}
        imgAlt={
          data.image
            ? `Imagem da atividade ${index + 1} do dia ${getDateDDMMM(
                data.start,
              )}`
            : undefined
        }
      />
      {/* <div
        className='cssClass'
        dangerouslySetInnerHTML={{
          __html: `
<table>
<tr>
<th>Nome</th>
</tr>
<tr>
<td>Abacate2</td>
</tr>
</table>
          `,
        }}
      ></div> */}

      {data.startAddress != null && (
        <p>
          Endereço: <b>{data.startAddress}</b>
        </p>
      )}
    </>
  )
}

const DateTimeLabel = styled.h5`
  color: #777;
`

const Icon = styled.i`
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  font-size: 28px;
  text-align: center;
  /* color: ${props => props.theme.colors.primary}; */
`

const IconWee = styled.i`
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  font-size: 28px;
  text-align: center;
  color: #d54a26;
`
