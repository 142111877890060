import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import { useOutletContext } from 'react-router-dom'
import PageTitle from '~/components/PageTitle'
import LoadingContainer from '~/components/LoadingContainer'
import { Container } from '~/styles/container'
import { getDateFormated, getMoment } from '~/functions/Date'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { toast } from 'react-toastify'
import { DefaultUl } from '~/styles/ul'
import Day from './components/Day'
import Modal from './components/Modal'
import CreateActivity from './Activity/Create'
import EditActivity from './Activity/Edit'
import CreateTicket from './Ticket/Create'
import EditTicket from './Ticket/Edit'
import CreateVehicleRental from './VehicleRental/Create'
import EditVehicleRental from './VehicleRental/Edit'
import CreateAir from './Air/Create'
import CreateTrain from './train/Create'
import CreateNautical from './nautical/Create'
import CreateHotel from './Hotel/Create'
import CreateCar from './Car/Create'
import CreateOther from './Other/Create'
import EditAir from './Air/Edit'
import EditTrain from './train/Edit'
import EditNautical from './nautical/Edit'
import EditHotel from './Hotel/Edit'
import EditCar from './Car/Edit'
import EditOther from './Other/Edit'
import {
  // DivFlex,
  DivFlexLeft,
  // DivFlexRight,
  SpaceBetweenDiv,
} from '~/styles/divs'
import DefaultForm from '~/components/DefaultForm'
import Tooltip from '~/components/Tooltip'
import { Panel, PanelBody } from '~/components/Panel'
import PanelFilters from './components/PanelFilters'
import { Col, Row } from 'reactstrap'
import { EditButton } from '~/components/Button/styles'

const pagePropsDefault = {
  menu: undefined,
  modal: undefined,
  toCreate: undefined,
  toEdit: undefined,
}

const sidebarPropsDefault = {
  open: false,
}

export default function DaybyDayList() {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(false)
  const [days, setDays] = useState([])
  const [daysOriginal, setDaysOriginal] = useState([])
  const [filterOptions, setFilterOptions] = useState([])
  const [filterSelected, setFilterSelected] = useState([])
  const [sidebarData, setSidebarData] = useState([])
  const [pageProps, setPageProps] = useState(pagePropsDefault)
  const [sidebarProps, setSidebarProps] = useState(sidebarPropsDefault)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)
        setLoadingSource(true)

        await getDaybyDays()

        setLoading(false)
        setLoadingSource(false)
      } catch (error) {
        setLoadingSource(false)

        setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [travel])

  async function getDaybyDays() {
    const { data: dataDaybyday } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/activity/${travel.id}`,
    )

    const { data: dataAir } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/air/${travel.id}`,
    )

    const { data: dataTrain } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/train/${travel.id}`,
    )

    const { data: dataNautical } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/nautical/${travel.id}`,
    )

    const { data: dataHotel } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/hotel/${travel.id}`,
    )

    const { data: dataCar } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/car/${travel.id}`,
    )

    const { data: dataOther } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/other/${travel.id}`,
    )

    const { data: dataTicket } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/ticket/${travel.id}`,
    )

    const { data: dataVehicleRental } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/vehicleRental/${travel.id}`,
    )

    const data = [
      ...dataDaybyday,
      ...dataAir,
      ...dataTrain,
      ...dataNautical,
      ...dataHotel,
      ...dataCar,
      ...dataOther,
      ...dataTicket,
      ...dataVehicleRental,
    ]

    const beforeStartDate = getMoment(travel.start).add(-1, 'days')

    const afterEndDate = getMoment(travel.end).add(1, 'days')

    const startDate = getMoment(travel.start)

    const endDate = getMoment(travel.end)

    const res = []

    var beforeTravel = data.filter(x => getMoment(x.start) < startDate)

    res.push({
      id: getDateFormated(beforeStartDate, 'YYYYMMDD'),
      title: 'Pré-viagem',
      date: beforeStartDate.toString(),
      childs: beforeTravel,
    })

    do {
      const newChilds = []

      data.forEach(e => {
        if (getDateFormated(e.start) == getDateFormated(startDate)) {
          newChilds.push(e)
        }
      })

      res.push({
        id: getDateFormated(startDate, 'YYYYMMDD'),
        title: `${getDateFormated(startDate, 'DD/MM/YYYY')}`,
        date: startDate.toString(),
        childs: newChilds,
      })

      startDate.add(1, 'days')
    } while (startDate <= endDate)

    var afterTravel = data.filter(x => getMoment(x.start) >= afterEndDate)

    res.push({
      id: getDateFormated(afterEndDate, 'YYYYMMDD'),
      title: 'Pós-viagem',
      date: afterEndDate.toString(),
      childs: afterTravel,
    })

    setFilterOptions(
      res.map(x => {
        return {
          label: x.title,
          value: x.id,
        }
      }),
    )

    const resReordered = await reorderList(res)

    setDaysOriginal(resReordered)
    setDays(resReordered)
  }

  const addActivity = e => {
    setPageProps({
      menu: undefined,
      modal: 'create-' + e.type,
      toCreate: {
        date: e.date,
      },
      toEdit: undefined,
    })
  }

  const editActivity = e => {
    setPageProps({
      menu: undefined,
      modal: 'edit-' + e.type,
      toCreate: undefined,
      toEdit: {
        date: e.date,
        entity: e.entity,
      },
    })
  }

  const closeModal = reload => {
    setPageProps(pagePropsDefault)

    async function getSource() {
      try {
        setLoadingSource(true)

        await getDaybyDays()

        setLoadingSource(false)
      } catch (error) {
        setLoadingSource(false)
      }
    }

    if (reload) {
      getSource()
    }
  }

  async function deleteItem(e) {
    let deleted = false

    if (e.type === 'activity') {
      if (window.confirm('Deseja remover a atividade?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/deletedaybyday/${e.id}`,
        )

        toast.success(`Atividade removida com sucesso!`)

        deleted = true
      }
    }

    if (e.type === 'air') {
      if (window.confirm('Deseja remover o aéreo?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelair/${e.id}`,
        )

        toast.success(`Aéreo removido com sucesso!`)

        deleted = true
      }
    }

    if (e.type === 'train') {
      if (window.confirm('Deseja remover o Trem?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveltrain/${e.id}`,
        )

        toast.success(`Trem removido com sucesso!`)

        deleted = true
      }
    }

    if (e.type === 'nautical') {
      if (window.confirm('Deseja remover o Trem?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelnautical/${e.id}`,
        )

        toast.success(`Trem removido com sucesso!`)

        deleted = true
      }
    }

    if (e.type === 'hotel') {
      if (window.confirm('Deseja remover o hotel?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelhotel/${e.id}`,
        )

        toast.success(`Hotel removido com sucesso!`)

        deleted = true
      }
    }

    if (e.type === 'car') {
      if (window.confirm('Deseja remover o transfer/locação?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelcar/${e.id}`,
        )

        toast.success(`Transfer/Locação removido com sucesso!`)

        deleted = true
      }
    }

    if (e.type === 'other') {
      if (window.confirm('Deseja remover o serviço?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelitem/${e.id}`,
        )

        toast.success(`Serviço removido com sucesso!`)

        deleted = true
      }
    }

    if (e.type === 'ticket') {
      if (window.confirm('Deseja remover o Ingresso?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelticket/${e.id}`,
        )

        toast.success(`Ingresso removido com sucesso!`)

        deleted = true
      }
    }

    if (e.type === 'vehicleRental') {
      if (window.confirm('Deseja remover a Locação veicular?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelvehicleRental/${e.id}`,
        )

        toast.success(`Locação veicular removida com sucesso!`)

        deleted = true
      }
    }

    if (deleted) {
      let item = undefined

      const listOriginal = daysOriginal.map(x => x)
      const list = days.map(x => {
        const finded = x.childs.find(
          y => y.id.toString() === e.id.toString() && y.type === e.type,
        )

        if (finded) {
          item = finded
        }

        return x
      })

      const listOriginalWithoutItem = await removeItemFromList(
        listOriginal,
        item,
      )
      const listWithoutItem = await removeItemFromList(list, item)

      setDays(listWithoutItem)
      setDaysOriginal(listOriginalWithoutItem)
    }
  }

  async function onDragEndDaybyday(e) {
    const destination = e.destination.droppableId.split('-')[3]
    const destinationId = e.destination.droppableId.split('-')[2]
    const source = e.source.droppableId.split('-')[3]

    if (destination === source) return

    const id = e.draggableId.split('-')[2]
    const type = e.draggableId.split('-')[0]
    let item = undefined

    const list = days.map(x => {
      const finded = x.childs.find(
        y => y.id.toString() === id && y.type === type,
      )

      if (finded) {
        item = finded
      }

      return x
    })

    const listOriginal = daysOriginal.map(x => x)

    if (item) {
      const listWithoutItem = await removeItemFromList(list, item)

      const listOriginalWithoutItem = await removeItemFromList(
        listOriginal,
        item,
      )

      const newItem = await updateItemDate(item, destination)

      const listWithItem = await addItemToList(
        listWithoutItem,
        destinationId,
        newItem,
      )

      const listOriginalWithItem = await addItemToList(
        listOriginalWithoutItem,
        destinationId,
        newItem,
      )

      const reorderedAndFilteredList = await reorderList(
        await filterList(listWithItem),
      )

      const reorderedListOriginal = await reorderList(listOriginalWithItem)

      setDays(reorderedAndFilteredList)
      setDaysOriginal(reorderedListOriginal)

      await updateItemSource(newItem)
    }
  }

  async function removeItemFromList(list, item) {
    const res = list.map(x => {
      return {
        ...x,
        childs: x.childs.filter(y => y != item),
      }
    })

    return res
  }

  async function addItemToList(list, id, item) {
    const res = list.map((x, i) => {
      const newChilds = [...x.childs]

      if (i.toString() === id.toString()) {
        newChilds.push(item)
      }

      return {
        ...x,
        childs: newChilds,
      }
    })

    return res
  }

  async function reorderList(list) {
    list.forEach(x => {
      x.childs.sort((a, b) => {
        if (getMoment(a.start) < getMoment(b.start)) {
          return -1
        }

        if (getMoment(a.start) > getMoment(b.start)) {
          return 1
        }

        return 0
      })
    })

    return list
  }

  async function filterList(list) {
    if (filterSelected.length === 0) {
      return list
    } else {
      return list.filter(x =>
        filterSelected.some(
          y => getDateFormated(y.value) === getDateFormated(x.date),
        ),
      )
    }
  }

  async function updateItemDate(item, destination) {
    const destinationDate = getMoment(destination)
    const destinationDateEnd =
      item.type === 'activity' || getMoment(item.end) < destinationDate
        ? destinationDate
        : item.end

    const newItemToMoveStart = `${getDateFormated(
      destinationDate,
      'YYYY-MM-DD',
    )} ${getDateFormated(item.start, 'HH:mm')}`

    const newItemToMoveEnd = `${getDateFormated(
      destinationDateEnd,
      'YYYY-MM-DD',
    )} ${getDateFormated(item.end, 'HH:mm')}`

    return {
      ...item,
      start: newItemToMoveStart,
      end: newItemToMoveEnd,
    }
  }

  async function updateItemSource(item) {
    try {
      const formData = new FormData()

      formData.append('id', item.id)
      formData.append('start', item.start)
      formData.append('end', item.end)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelItinerary/edit${item.type}date`,
        formData,
      )
    } catch (error) {
      console.log(error)
    }
  }

  async function filterDays(e) {
    const newFilterSelected = e.target.value

    setFilterSelected(newFilterSelected)

    if (newFilterSelected.length === 0) {
      const newDays = daysOriginal.map(x => x)

      return setDays(newDays)
    }

    const newDays = daysOriginal.filter(x =>
      newFilterSelected.some(y => y.value === x.id),
    )

    setDays(newDays)
  }

  return (
    <Panel>
      <PanelBody>
        <Container style={{ position: 'relative' }}>
          {pageProps.modal === 'create-activity' && pageProps.toCreate && (
            <Modal
              title={`Adicionar Atividade - ${getDateFormated(
                pageProps.toCreate?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <CreateActivity
                toCreate={pageProps.toCreate}
                onClose={closeModal}
              />
            </Modal>
          )}
          {pageProps.modal === 'edit-activity' && pageProps.toEdit && (
            <Modal
              title={`Detalhes da Atividade - ${getDateFormated(
                pageProps.toEdit?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <EditActivity
                toEdit={pageProps.toEdit}
                onClose={closeModal}
                onDelete={deleteItem}
              />
            </Modal>
          )}
          {pageProps.modal === 'create-ticket' && pageProps.toCreate && (
            <Modal
              title={`Adicionar Ingresso - ${getDateFormated(
                pageProps.toCreate?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <CreateTicket
                toCreate={pageProps.toCreate}
                onClose={closeModal}
              />
            </Modal>
          )}
          {pageProps.modal === 'edit-ticket' && pageProps.toEdit && (
            <Modal
              title={`Detalhes do Ingresso - ${getDateFormated(
                pageProps.toEdit?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <EditTicket
                toEdit={pageProps.toEdit}
                onClose={closeModal}
                onDelete={deleteItem}
              />
            </Modal>
          )}
          {pageProps.modal === 'create-vehicleRental' && pageProps.toCreate && (
            <Modal
              title={`Adicionar Locação Veicular - ${getDateFormated(
                pageProps.toCreate?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <CreateVehicleRental
                toCreate={pageProps.toCreate}
                onClose={closeModal}
              />
            </Modal>
          )}
          {pageProps.modal === 'edit-vehicleRental' && pageProps.toEdit && (
            <Modal
              title={`Detalhes da Locação Veicular  - ${getDateFormated(
                pageProps.toEdit?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <EditVehicleRental
                toEdit={pageProps.toEdit}
                onClose={closeModal}
                onDelete={deleteItem}
              />
            </Modal>
          )}
          {pageProps.modal === 'create-air' && pageProps.toCreate && (
            <Modal
              title={`Adicionar Aéreo - ${getDateFormated(
                pageProps.toCreate?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <CreateAir toCreate={pageProps.toCreate} onClose={closeModal} />
            </Modal>
          )}
          {pageProps.modal === 'edit-air' && pageProps.toEdit && (
            <Modal
              title={`Detalhes do Aéreo - ${getDateFormated(
                pageProps.toEdit?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <EditAir
                toEdit={pageProps.toEdit}
                onClose={closeModal}
                onDelete={deleteItem}
              />
            </Modal>
          )}
          {pageProps.modal === 'create-train' && pageProps.toCreate && (
            <Modal
              title={`Adicionar Trem - ${getDateFormated(
                pageProps.toCreate?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <CreateTrain toCreate={pageProps.toCreate} onClose={closeModal} />
            </Modal>
          )}
          {pageProps.modal === 'edit-train' && pageProps.toEdit && (
            <Modal
              title={`Detalhes do Trem - ${getDateFormated(
                pageProps.toEdit?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <EditTrain
                toEdit={pageProps.toEdit}
                onClose={closeModal}
                onDelete={deleteItem}
              />
            </Modal>
          )}
          {pageProps.modal === 'create-nautical' && pageProps.toCreate && (
            <Modal
              title={`Adicionar Náutico - ${getDateFormated(
                pageProps.toCreate?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <CreateNautical
                toCreate={pageProps.toCreate}
                onClose={closeModal}
              />
            </Modal>
          )}
          {pageProps.modal === 'edit-nautical' && pageProps.toEdit && (
            <Modal
              title={`Detalhes do Náutico - ${getDateFormated(
                pageProps.toEdit?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <EditNautical
                toEdit={pageProps.toEdit}
                onClose={closeModal}
                onDelete={deleteItem}
              />
            </Modal>
          )}
          {pageProps.modal === 'create-car' && pageProps.toCreate && (
            <Modal
              title={`Adicionar Transfer/Locação - ${getDateFormated(
                pageProps.toCreate?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <CreateCar toCreate={pageProps.toCreate} onClose={closeModal} />
            </Modal>
          )}
          {pageProps.modal === 'edit-car' && pageProps.toEdit && (
            <Modal
              title={`Detalhes do Transfer/Locação - ${getDateFormated(
                pageProps.toEdit?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <EditCar
                toEdit={pageProps.toEdit}
                onClose={closeModal}
                onDelete={deleteItem}
              />
            </Modal>
          )}
          {pageProps.modal === 'create-hotel' && pageProps.toCreate && (
            <Modal
              title={`Adicionar Hotel - ${getDateFormated(
                pageProps.toCreate?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <CreateHotel toCreate={pageProps.toCreate} onClose={closeModal} />
            </Modal>
          )}
          {pageProps.modal === 'edit-hotel' && pageProps.toEdit && (
            <Modal
              title={`Detalhes do Hotel - ${getDateFormated(
                pageProps.toEdit?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <EditHotel
                toEdit={pageProps.toEdit}
                onClose={closeModal}
                onDelete={deleteItem}
              />
            </Modal>
          )}
          {pageProps.modal === 'create-other' && pageProps.toCreate && (
            <Modal
              title={`Adicionar Serviço - ${getDateFormated(
                pageProps.toCreate?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <CreateOther toCreate={pageProps.toCreate} onClose={closeModal} />
            </Modal>
          )}
          {pageProps.modal === 'edit-other' && pageProps.toEdit && (
            <Modal
              title={`Detalhes do Serviço - ${getDateFormated(
                pageProps.toEdit?.date,
                'DD/MM/YYYY',
              )}`}
              onClose={closeModal}
            >
              <EditOther
                toEdit={pageProps.toEdit}
                onClose={closeModal}
                onDelete={deleteItem}
              />
            </Modal>
          )}

          <DragDropContext onDragEnd={onDragEndDaybyday}>
            {!loading && (
              <Row style={{ maxHeight: '100vh' }}>
                <Col
                  sm={12}
                  lg={sidebarProps.open ? 7 : 12}
                  style={{ position: 'relative' }}
                >
                  {loadingSource && (
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.2)',
                        zIndex: '999',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <LoadingContainer color='#ccc' height='300px' size='lg' />
                    </div>
                  )}

                  <SpaceBetweenDiv style={{ marginBottom: '8px' }}>
                    <SpaceBetweenDiv>
                      <DivFlexLeft>
                        <PageTitle title={'Day by Day'} head='h5' noButton />
                        &nbsp;
                        <Tooltip
                          target={'daybyday-title'}
                          text='Os cards são ordenados automaticamente pelo horário. Cards com "dia todo" são atividades sem horário ou com horários iguais'
                        />
                        <i
                          className='fas fa-info-circle'
                          id={'daybyday-title'}
                        />
                      </DivFlexLeft>

                      <div style={{ minWidth: '250px' }}>
                        <DefaultForm
                          inline
                          loading={loading}
                          inputs={[
                            {
                              type: 'select',
                              name: 'days',
                              label: '',
                              placeholder: 'Mostrar somente os dias...',
                              options: filterOptions,
                              onChange: filterDays,
                              isMulti: true,
                              sm: 12,
                            },
                          ]}
                        />
                      </div>
                    </SpaceBetweenDiv>

                    {false && !sidebarProps.open && (
                      <EditButton
                        style={{
                          whiteSpace: 'nowrap',
                          alignItems: 'center',
                        }}
                        onClick={() =>
                          setSidebarProps(state => ({
                            ...state,
                            open: true,
                          }))
                        }
                      >
                        <i className='fas fa-angle-double-left' />
                        &nbsp;
                        <span>Abrir painel</span>
                      </EditButton>
                    )}
                  </SpaceBetweenDiv>

                  <DefaultUl>
                    {days.map((e, i) => {
                      return (
                        <Day
                          key={i}
                          item={e}
                          index={i}
                          menuOpenned={pageProps.menu}
                          setMenuOpenned={e => {
                            setPageProps(state => ({
                              ...state,
                              menu: e,
                            }))
                          }}
                          add={addActivity}
                          details={editActivity}
                          remove={deleteItem}
                        />
                      )
                    })}
                  </DefaultUl>
                </Col>

                {sidebarProps.open && (
                  <Col sm={12} lg={5}>
                    <SpaceBetweenDiv
                      style={{ minHeight: '46px', marginBottom: '8px' }}
                    >
                      <PageTitle title={'Painel'} head='h5' noButton />

                      {sidebarProps.open && (
                        <EditButton
                          style={{
                            whiteSpace: 'nowrap',
                            alignItems: 'center',
                          }}
                          onClick={() =>
                            setSidebarProps(state => ({
                              ...state,
                              open: false,
                            }))
                          }
                        >
                          <i className='fas fa-times' />
                        </EditButton>
                      )}
                    </SpaceBetweenDiv>

                    <PanelFilters data={sidebarData} setData={setSidebarData} />

                    {sidebarData.length > 0 && (
                      <Droppable droppableId={'daybyday-headerbar-search'}>
                        {provided => (
                          <ul
                            ref={provided.innerRef}
                            style={{
                              display: 'flex',
                              width: '100%',
                              height: '80vh',
                              background: 'red',
                              padding: '8px',
                              margin: 0,
                              overflow: 'auto',
                            }}
                          >
                            {sidebarData.map((e, i) => {
                              return (
                                <Draggable
                                  key={i}
                                  index={i}
                                  draggableId={'sidebar-search-' + e.placeId}
                                >
                                  {provided => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                        marginBottom: '8px',
                                        paddding: '8px',
                                        boxShadow: '0 0 10px #000',
                                      }}
                                    >
                                      <span>lkjsalkjfdslkajfsdlkj</span>
                                    </li>
                                  )}
                                </Draggable>
                              )
                            })}

                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    )}
                  </Col>
                )}
              </Row>
            )}

            {loading && (
              <LoadingContainer color='#ccc' height='300px' size='lg' />
            )}
          </DragDropContext>
        </Container>
      </PanelBody>
    </Panel>
  )
}
