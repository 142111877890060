import axios from 'axios'
import { toast } from 'react-toastify'

export async function apiRequest(verb, route, dto, setLoading = () => {}) {
  const accessToken = JSON.parse(
    JSON.parse(localStorage.getItem('persist:tripmee-traveler:root')).auth,
  ).accessToken

  const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_APIS,
    timeout: 40000,
    headers: { Authorization: `Bearer ${accessToken}` },
  })

  try {
    setLoading(true)

    let request

    switch (verb) {
      case 'get':
        request = await apiInstance.get(`${route}`, { params: dto })
        break
      case 'post':
        request = await apiInstance.post(`${route}`, dto)
        break
      case 'put':
        request = await apiInstance.put(`${route}`, dto)
        break
      case 'patch':
        request = await apiInstance.patch(`${route}`, dto)
        break
      case 'delete':
        request = await apiInstance.delete(`${route}`, dto)
        break
      case 'options':
        request = await apiInstance.options(`${route}`, dto)
        break
    }

    const result = {
      data: request.data,
      error: false,
    }

    setLoading(false)

    return result
  } catch (error) {
    setLoading(false)

    const result = {
      data: error.response.data,
      error: true,
      erroMessage:
        'Um erro inesperado ocorreu, por favor entrar em contato com o suporte.',
    }

    if (error.response.data) toast.info(result.data)
    else toast.info(result.erroMessage)

    return result
  }
}
