import { useState } from 'react'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import '../style.scss'
import { useParams } from 'react-router-dom'
import { getDateFormated } from '~/functions/Date'

export default function MarriageForm({ data }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [eventSource] = useState(data)
  const [value, setValue] = useState({
    address: '',
    phone: '',
    fullName: '',
    email: '',
    agentName: '',
  })

  const params = useParams()

  document.title = 'Ficha de Inscrição'

  async function onSubmit(data) {
    try {
      setLoading(true)

      if (!data.phone && !data.email) {
        toast.info('É obrigatório o preenchimento do campo email ou telefone.')
      }

      const studentGuardian = {
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        businessPhone: '',
        profession: '',
        rg: '',
        cpf: '',
        birth: '01/01/0001',
        address: '',
        hood: '',
        city: '',
        state: '',
        country: '',
        paymentMethod: '',
        installments: '',
      }

      const students = [
        {
          fullName: data.nome,
          phone: data.phone,
          email: data.email,
          address: data.address,
          agentName: data.agentName,
          // firstName: 'firstName',
          // lastName: 'lastName',
          // cpf: '00000',
          // rg: '00000',
          birth: '01/01/0001',
          // fatherName: '00000',
          // fatherPhone: '00000',
          // fatherEmail: 'fatherEmail',
          // motherName: '00000',
          // motherPhone: '00000',
          // motherEmail: 'motherEmail',
          // emergencyName: '00000',
          // emergencyPhone: '00000',
          // emergencyEmail: '00000',
          // grade: '00000',
          // class: '00000',
          // tshirtNumber: '00000',
          // medicalAllergy: '00000',
          // medicalMedication: '00000',
          // medicalDiseasePhobia: '00000',
          // medicalFoodRestriction: '00000',
          // responsibleKinship: '00000',
          // weight: 1,
          // shoeNumber: '00000',
          // hood: 'hood',
          // city: 'city',
          // state: 'state',
          // country: 'country',
        },
      ]

      const body = {
        token: params.token,
        responsible: studentGuardian,
        participants: students,
      }

      if (window.confirm('Você deseja enviar sua inscrição?')) {
        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/eventSubscription`,
          body,
        )

        toast.success('Inscrição enviada com sucesso!')

        // setSended(true)
      }

      setLoading(false)
      // window.location.reload()
      setValue({
        address: '',
        phone: '',
        nome: '',
        email: '',
        agentName: '',
      })
    } catch (error) {
      toast.info(
        'Não foi possível enviar sua inscrição. Tente Novamente em instantes.',
      )

      console.log(error)

      setLoading(false)
    }
  }

  // const onValidate = data => {
  //   if (data.nome == undefined) {
  //     toast.info(
  //       'Por favor verifique o campo referente ao nome do(a) participante.',
  //     )
  //   } else if (data.endereco == undefined) {
  //     toast.info(
  //       'Por favor verifique o campo referente ao endereço do participante',
  //     )
  //   } else if (data.email == undefined) {
  //     toast.info(
  //       `Por favor verifique o campo referente ao e-email do participante`,
  //     )
  //   } else if (data.telefone == undefined) {
  //     toast.info(
  //       `Por favor verifique o campo referente ao telefone do participante`,
  //     )
  //   } else {
  //     onSubmit(data)
  //   }
  // }

  // const getNamesInstitutions = () => {
  //   return eventSource.institutions.map((value, i) => (
  //     <div key={i}>
  //       <span>{value.name}</span>
  //     </div>
  //   ))
  // }

  const getImagesInstitutions = () => {
    return eventSource?.institutions?.map((value, i) => (
      <div key={i}>
        <img
          style={{ maxWidth: '100px' }}
          src={`data:image/*;base64,${value?.image}`}
        />
      </div>
    ))
  }

  return (
    <div style={{ padding: '50px' }}>
      {eventSource && (
        <>
          <div className='titleImage'>
            <img src={`data:image/*;base64,${eventSource?.agency?.image}`} />

            <h1 className='page-header'>Participante</h1>

            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <div style={{ width: '100px', height: '100px' }}>
                {getImagesInstitutions()}
              </div>
              <img
                style={{ width: '100px', height: '100px' }}
                src={`data:image/*;base64,${eventSource?.image}`}
              />
            </div>
          </div>

          {/* <div className='titleImageMobile'>
            <div className='title'>
              <h1 className='page-header'>Ficha de Inscrição</h1>
              <h1 className='page-header'>Viagens Pedagógicas</h1>
            </div>

            <div>
              <img src={`data:image/*;base64,${eventSource?.agency?.image}`} />
              <div>{getImagesInstitutions()}</div>
            </div>
          </div> */}

          <Panel>
            <PanelHeader
              noButton
              background={`${eventSource?.agency?.primaryColor}`}
            />

            <PanelBody style={{ padding: '15px' }}>
              <ul className='event-info'>
                <li className='event-info-field'>
                  <b>Evento</b>

                  <span>{eventSource?.name}</span>
                </li>

                {/* <li className='event-info-field'>
                  <b>Instituição/Empresa</b>
                  {getNamesInstitutions()}
                </li> */}

                <li className='event-info-field'>
                  <b>Início</b>

                  <span>{getDateFormated(eventSource?.start)}</span>
                </li>

                <li className='event-info-field'>
                  <b>Fim</b>

                  <span>{getDateFormated(eventSource?.end)}</span>
                </li>
              </ul>

              <DefaultForm
                callbackSubmit={onSubmit}
                defaultValue={value}
                // onValidate={onValidate}
                disabled={loading}
                // callbackCancel={() => {
                //   if (window.confirm('Você deseja cancelar a inscrição?')) {
                //     setCancel(true)
                //   }
                // }}
                // callbackReset={onReset}
                submitLabel={'Enviar Inscrição'}
                fontSize='16px'
                backgroundColor={`${eventSource?.agency?.primaryColor}`}
                color='#fff'
                borderRadius='10px'
                inputs={[
                  {
                    empty: true,
                    text: '<b>Dados do(a) Participante</b>',
                    sm: 12,
                  },
                  {
                    type: 'text',
                    name: 'nome',
                    label: 'Nome completo',
                    required: true,
                    maxLength: 100,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    type: 'text',
                    name: 'address',
                    label: 'Endereço',
                    // required: true,
                    maxLength: 250,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    type: 'text',
                    name: 'phone',
                    label: 'Telefone',
                    mask: '(99) 99999-9999',
                    // required: true,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    type: 'email',
                    name: 'email',
                    label: 'E-mail',
                    // required: true,
                    maxLength: 100,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    type: 'text',
                    name: 'agentName',
                    label:
                      'Nome do(a) consultor que está realizando a inscrição',
                    required: true,
                    maxLength: 100,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    empty: true,
                    text: '<b>Termos e Condições</b>',
                    sm: 12,
                    lg: 12,
                  },
                  {
                    empty: true,
                    text: `${eventSource?.desc ? eventSource?.desc : ''}`,
                    sm: 12,
                    lg: 12,
                  },
                ]}
              />
            </PanelBody>
          </Panel>
        </>
      )}

      {/* {!loading && !eventSource && (
        <p>As Inscrições começam em 02 de março às 09:00</p>
      )} */}

      {/* <p style={{ textAlign: 'center' }}>
        {sended && (
          <b>Sua inscrição foi enviada. Você já pode fechar esta página.</b>
        )}

        {cancel && <b>Você já pode fechar essa página!</b>}
      </p> */}
    </div>
  )
}
