import { useEffect, useState } from 'react'
// import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
// import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import { getDateFormated, getMoment } from '~/functions/Date'
import IconButton from '~/components/IconButton'

export default function ReportServices() {
  const { axiosApis } = GetAxios()
  // const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  // const [agentsOriginal, setAgentsOriginal] = useState([])
  const [agents, setAgents] = useState([])
  // const [filters, setFilters] = useState({})
  const [defaultValueForm, setDefaultValueForm] = useState({
    dateStart: getDateFormated(getMoment().add(-1, 'month'), 'YYYY-MM-DD'),
    dateEnd: getDateFormated(getMoment(), 'YYYY-MM-DD'),
  })

  // const defaultValueForm = {
  //   dateStart: getDateFormated(getMoment().add(-1, 'month'), 'YYYY-MM-DD'),
  //   dateEnd: getDateFormated(getMoment(), 'YYYY-MM-DD'),
  // }

  useEffect(async () => {
    await onSubmit()
  }, [])

  async function onSubmit(data) {
    setLoading(true)

    const params = {
      ...data,
      type: data && data.type ? data.type.value : undefined,
    }

    const response = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/quotation/report/services`,
      { params },
    )

    setAgents(response.data)

    setLoading(false)
  }

  return (
    <Container>
      <h1 className='page-header'>
        Serviços mais utilizados
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Na tela de Serviços mais utilizados, terá disponível todos serviços mais utilizados pela agencia organizados por Tipo de Serviço.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            defaultValue={defaultValueForm}
            inputs={[
              {
                type: 'text',
                name: 'city',
                label: 'Destino',
                placeholder: 'Destino',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'select',
                name: 'type',
                label: 'Tipo de serviço',
                placeholder: 'Selecione...',
                options: [
                  { value: 'aereo', label: 'Aéreo' },
                  { value: 'hotel', label: 'Hotel' },
                  { value: 'car', label: 'Transfer' },
                  { value: 'trem', label: 'Trem' },
                  { value: 'nauthic', label: 'Náutico' },
                  { value: 'ticket', label: 'Ingresso' },
                  { value: 'vehicleRental', label: 'Locação veicular' },
                  { value: 'other', label: 'Outros' },
                ],
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Data Inicio',
                placeholder: '',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Data Final',
                placeholder: '',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table data={agents} loading={loading} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
