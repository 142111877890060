export const rules = {
  isMaster: ['master'],
  isAdmin: ['admin'],
  isAgent: ['agent'],
  agencies: {
    view: ['master'],
    manage: ['master'],
    menu: {
      id: 0,
      path: '/agencies',
      icon: 'fas fa-hotel',
      title: 'Agências',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  city: {
    view: ['master'],
    manage: ['master'],
    menu: {
      id: 1,
      path: '/cities',
      icon: 'fas fa-city',
      title: 'Cidades',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  proposal: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 1,
      path: '/proposal',
      icon: 'fas fa-city',
      title: 'Propostas',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  globalnotification: {
    view: ['master'],
    manage: ['master'],
    menu: {
      id: 16,
      path: '/global-notifications',
      icon: 'fas fa-bell',
      title: 'Notificações Globais',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  IntegrationRequest: {
    view: ['master'],
    manage: ['master'],
    menu: {
      id: 16,
      path: '/IntegrationRequestToMaster',
      icon: 'fas fa-handshake',
      title: 'Solicitações de Parceria',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  home: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 2,
      path: '/schedule',
      icon: 'fas fa-home',
      title: 'Home',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  sales: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 2,
      path: '#',
      icon: 'fas fa-business-time',
      title: 'Vendas',
      exact: false,
      childs: [],
      menuSubMenu: [
        {
          subMenu: {
            id: 100,
            path: '/sales',
            icon: '',
            title: 'Vendas Consolidadas',
            exact: true,
            menuSubMenu: [],
            childs: [],
            view: ['admin', 'agent'],
            manage: ['admin', 'agent'],
          },
        },
        {
          subMenu: {
            id: 101,
            path: '/sales-backoffice',
            icon: '',
            title: 'Vendas Backoffice',
            exact: true,
            menuSubMenu: [],
            childs: [],
            view: ['admin', 'agent'],
            manage: ['admin', 'agent'],
          },
        },
      ],
    },
  },
  agents: {
    view: ['master', 'admin'],
    manage: ['master', 'admin'],
    menu: {
      id: 3,
      path: '/agents',
      icon: 'fas fa-user-tie',
      title: 'Agentes de Viagens',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  travelers: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 4,
      path: '/travelers',
      icon: 'far fa-id-card',
      title: 'Viajantes',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  partner: {
    view: ['admin'],
    manage: ['admin'],
    menu: {
      id: 4,
      path: '/partner',
      icon: 'fas fa-handshake',
      title: 'Parceiros',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  travels: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 5,
      path: '/travels',
      icon: 'fas fa-suitcase-rolling',
      title: 'Viagens',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  experiences: {
    view: ['admin', 'agent', 'library'],
    manage: ['admin', 'agent', 'library'],
    menu: {
      id: 6,
      path: '/experiences',
      icon: 'fas fa-heart',
      title: 'Experiências',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  destinys: {
    view: ['admin', 'agent', 'library'],
    manage: ['admin', 'agent', 'library'],
    menu: {
      id: 7,
      path: '/destinys',
      icon: 'fas fa-map-marker-alt',
      title: 'Destinos',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  notificationstravel: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 9,
      path: '/notifications',
      icon: 'fas fa-bell',
      title: 'Notificações de Viagens',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  notifications: {
    view: ['admin'],
    manage: ['admin'],
    menu: {
      id: 10,
      path: '/general-notifications',
      icon: 'fas fa-bullhorn',
      title: 'Notificações Gerais',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  refund: {
    view: ['admin', 'agent'],
    manage: ['admin'],
    menu: {
      id: 11,
      path: '/refund',
      icon: 'fas fa-hand-holding-usd',
      title: 'Pedidos de Reembolso',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  lancamentos: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 20,
      path: '/relationship-customers/enrollment-list',
      icon: 'fas fa-hand-holding-usd',
      title: 'Lançamentos',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  radar: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 30,
      path: '/radar',
      icon: 'fas fa-crosshairs',
      title: 'Radar',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  portfolioOfCities: {
    view: ['admin'],
    manage: ['admin'],
    menu: {
      id: 12,
      path: '/portfolio-of-cities',
      icon: 'fas fa-city',
      title: 'Portfólio de Cidades',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  relationship: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 13,
      path: '#',
      icon: 'fas fa-wallet',
      title: 'Clientes',
      exact: false,
      menuSubMenu: [
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 0,
            path: '/relationship-customers',
            icon: '',
            title: 'Lista de Clientes',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 1,
            path: '/relationship-pipeline',
            icon: '',
            title: 'Fluxo de Atendimentos',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 2,
            path: '/relationship-contact-registered',
            icon: '',
            title: 'Histórico de Atendimentos',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 4,
            path: '/report-aniversariantes',
            icon: '',
            title: 'Aniversariantes',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 5,
            path: '/import-customer',
            icon: '',
            title: 'Importação de Clientes',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        // {
        //   subMenu: {
        //     view: ['admin', 'agent'],
        //     id: 0,
        //     path: '/relationship-customers/enrollment-list',
        //     icon: '',
        //     title: 'Lançamentos',
        //     exact: true,
        //     menuSubMenu: [],
        //     childs: [],
        //   },
        // },
      ],
      childs: [],
    },
  },
  strategy: {
    view: ['admin', 'agent'],
    manage: ['admin', 'agent'],
    menu: {
      id: 14,
      path: '#',
      icon: 'fas fa-chart-bar',
      title: 'Relatórios',
      exact: false,
      menuSubMenu: [
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 1,
            path: '/report-hotelembarque',
            icon: '',
            title: 'Embarques',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 2,
            path: '/report-documents',
            icon: '',
            title: 'Documentos Expirados',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 3,
            path: '/report-hotelcheckin',
            icon: '',
            title: 'Hospedagens',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        // {
        //   subMenu: {
        //     view: ['admin'],
        //     id: 4,
        //     path: '/report-ranking-agent',
        //     icon: '',
        //     title: 'Ranking de consultores de Viagens',
        //     exact: true,
        //     menuSubMenu: [],
        //     childs: [],
        //   },
        // },
        // {
        //   subMenu: {
        //     view: ['admin', 'agent'],
        //     id: 5,
        //     path: '/report-travels',
        //     icon: '',
        //     title: 'Viagens por Viajante',
        //     exact: true,
        //     menuSubMenu: [],
        //     childs: [],
        //   },
        // },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 6,
            path: '/report-checkin',
            icon: '',
            title: 'Check In',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
      ],
      childs: [],
    },
  },
  pedagogicalEvent: {
    view: ['admin', 'agent'],
    manage: ['admin'],
    menu: {
      id: 16,
      path: '#',
      icon: 'fas fa-users',
      title: 'Grupos de Viagens',
      exact: false,
      menuSubMenu: [
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 0,
            path: '/pedagogical-event/list/institution',
            icon: '',
            title: 'Grupos',
            exact: false,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 1,
            path: '/pedagogical-event/list/event',
            icon: '',
            title: 'Eventos',
            exact: false,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 2,
            path: '/pedagogical-event',
            icon: '',
            title: 'Lista de Inscrições',
            exact: false,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 2,
            path: '/pedagogical-event/kanban',
            icon: '',
            title: 'Quadro de Inscrições',
            exact: false,
            menuSubMenu: [],
            childs: [],
          },
        },
      ],
      childs: [],
    },
  },
  travelManager: {
    view: ['admin'],
    manage: ['admin'],
    menu: {
      id: 18,
      path: '#',
      icon: 'fas fa-map-marked-alt',
      title: 'Viagens',
      exact: false,
      menuSubMenu: [
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 0,
            path: '/travels',
            title: 'Itinerários',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        // {
        //   subMenu: {
        //     view: ['admin', 'agent'],
        //     id: 0,
        //     path: '/group-travel',
        //     title: 'Itinerários em Grupos',
        //     exact: true,
        //     menuSubMenu: [],
        //     childs: [],
        //   },
        // },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 0,
            path: '/notifications',
            title: 'Notificações de Viagens',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 3,
            path: '/relationship-travel-history',
            icon: '',
            title: 'Histórico de Viagens',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
      ],
      childs: [],
    },
  },
  libraryManager: {
    view: ['admin', 'agent', 'library'],
    manage: ['admin'],
    menu: {
      id: 18,
      path: '#',
      icon: 'fas fa-book',
      title: 'Biblioteca',
      exact: false,
      menuSubMenu: [
        {
          subMenu: {
            view: ['admin', 'agent', 'library'],
            id: 1,
            path: '/destinys',
            title: 'Destinos',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent', 'library'],
            id: 2,
            path: '/experiences',
            title: 'Experiências',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent', 'library'],
            id: 3,
            path: '/library/hotel',
            title: 'Hotéis',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
      ],
      childs: [],
    },
  },
  afterSales: {
    view: ['admin', 'agent'],
    manage: ['admin'],
    menu: {
      id: 18,
      path: '#',
      icon: 'fas fa-book',
      title: 'Pós Venda',
      exact: false,
      menuSubMenu: [
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 1,
            path: '/relationship-search',
            icon: '',
            title: 'Pesquisas de Satisfação',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 2,
            path: '/report-services',
            icon: '',
            title: 'Serviços mais Utilizados',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
        {
          subMenu: {
            view: ['admin', 'agent'],
            id: 3,
            path: '/refund',
            icon: '',
            title: 'Pedidos de Reembolso',
            exact: true,
            menuSubMenu: [],
            childs: [],
          },
        },
      ],
      childs: [],
    },
  },
}
