import { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import Divisor from '~/components/Divisor'
import { GetAxios } from '~/services/api'
import Tabs from './Tabs'

export default function PanelFilters({ data = [], setData }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('library')

  async function onClickTab(e) {
    setActiveTab(e.value)
  }

  async function onSubmitSearchSuggestions(data) {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/get-suggestions`,
        {
          params: {
            cityId: data.city.value,
            title: data.title,
          },
        },
      )

      setLoading(false)

      setData(res.data)
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Nenhuma sugestão encontrada, tente preencher os dados manualmente.',
      )
    }
  }

  return (
    <Container>
      <Tabs active={activeTab} onClick={onClickTab} />

      {activeTab === 'search' && (
        <>
          <DefaultForm
            callbackSubmit={onSubmitSearchSuggestions}
            callbackReset={() => {
              setData([])

              return
            }}
            submitLabel='Pesquisar'
            cancelLabel='Preencher dados manualmente'
            loading={loading}
            disabled={loading}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Lugar ou categoria',
                required: true,
                sm: 12,
                lg: 6,
              },
              {
                type: 'city',
                name: 'city',
                label: 'Cidade',
                placeholder: 'Pesquise a cidade...',
                required: true,
                sm: 12,
                lg: 6,
              },
            ]}
          />

          <Divisor margin={'8px'} border />

          {data.length === 0 && (
            <p>
              <b>
                Pesquise acima pelo nome do local, restaurantes, praias, etc...
              </b>
            </p>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
