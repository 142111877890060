import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import { Panel, PanelHeader, PanelBody } from '~/components/Panel'
import { Container } from '~/styles/container'
import PageTitle from '~/components/PageTitle'
import usePermissions from '~/hooks/usePermissions'
import { getDateFormated, getMoment } from '~/functions/Date'
import DefaultForm from '~/components/DefaultForm'
import { DetailBlock } from '~/styles/divs'
import LoadingContainer from '~/components/LoadingContainer'
import useModal from '~/hooks/useModal'

export default function Create({
  showPageTitle = true,
  showPanelHeader = true,
  fromServiceFlow = false,
  traveler = null,
}) {
  const { axiosApis } = GetAxios()
  const { isAdmin } = usePermissions()
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate()
  const location = useLocation()

  const defaultTravel = {
    start: location.state?.start && location.state?.start,
    end:
      location.state?.start &&
      getDateFormated(
        getMoment(location.state?.start).add(7, 'days'),
        'YYYY-MM-DD',
      ),
    travelers: traveler != null && [{ ...traveler }],
    origin: undefined,
  }

  const { hideModal } = useModal()

  const [loading, setLoading] = useState(false)
  const [loadingTravel, setLoadingTravel] = useState(false)
  const [travel, setTravel] = useState(defaultTravel)
  const [agents, setAgents] = useState([])
  const [travels, setTravels] = useState([])

  useEffect(async () => {
    const [agents, travels] = await Promise.allSettled([
      requestApi('agent'),
      requestApi('travel'),
    ])

    setAgents(agents?.value?.data)
    setTravels(travels?.value?.data)
  }, [])

  const requestApi = async path => {
    try {
      setLoading(true)

      const response = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/${path}`,
      )

      setLoading(false)

      return response
    } catch (err) {
      console.log(err)
    }
  }

  async function onSelectTravel(e) {
    try {
      if (e.target.value === null) {
        setLoadingTravel(true)

        setTravel(defaultTravel)

        setTimeout(() => {
          setLoadingTravel(false)
        }, 500)

        return
      }

      setLoadingTravel(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/model/${e.target.value.value}`,
      )

      setTravel({
        id: res.data.id,
        title: res.data.title,
        desc: res.data.desc,
        origin: {
          label: res.data.origin,
          value: res.data.originId,
        },
        start: location?.state?.start
          ? location?.state?.start
          : getDateFormated(res?.data?.start, 'YYYY-MM-DD'),
        end: location?.state?.end
          ? getDateFormated(
              getMoment(location?.state?.end).add(7, 'days'),
              'YYYY-MM-DD',
            )
          : getDateFormated(res?.data?.end, 'YYYY-MM-DD'),
      })

      setLoadingTravel(false)
    } catch (error) {
      setLoadingTravel(false)
    }
  }

  async function onSelectTraveler(e) {
    if (!travel?.origin) {
      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/getCity/${
          e.target?.value[e.target?.value?.length - 1]?.value
        }`,
      )

      if (data) {
        setTravel(oldState => ({
          ...oldState,
          travelers: [...e.target.value],
          origin: {
            ...data,
          },
        }))
      } else {
        setTravel(oldState => ({
          ...oldState,
          travelers: [...e.target.value],
        }))
      }
    } else {
      setTravel(oldState => ({
        ...oldState,
        travelers: [...e.target.value],
      }))
    }
  }

  async function onSubmit() {
    try {
      if (getMoment(travel.start) > getMoment(travel.end)) {
        toast.info('O início da viagem não pode ser depois que o fim!')
      }

      setLoadingTravel(true)

      const travelers = travel.travelers

      travelers == false && delete travel.travelers

      const response = await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel`,
        travel,
      )

      toast.success('Viagem criada com sucesso!')

      setLoadingTravel(false)

      fromServiceFlow && hideModal()

      !fromServiceFlow &&
        navigate('/travels/details/presentation', {
          state: { id: response.data.id },
        })
    } catch (error) {
      setLoadingTravel(false)

      console.log(error)
    }
  }

  async function onReset() {
    setLoadingTravel(true)

    setTravel(defaultTravel)

    setTimeout(() => {
      setLoadingTravel(false)
    }, 500)
  }

  return (
    <Container>
      {showPageTitle && <PageTitle title={'Criar itinerário'} />}

      <Panel>
        {showPanelHeader && <PanelHeader noButton />}

        <PanelBody>
          {!fromServiceFlow && loading && (
            <LoadingContainer color={'#ccc'} size={'lg'} height={'300px'} />
          )}

          {!loading && (
            <>
              <DetailBlock style={{ marginBottom: '16px' }}>
                <DefaultForm
                  loading={loading}
                  disabled={loading}
                  inputs={[
                    {
                      type: 'select',
                      name: 'travel',
                      label: 'Para COPIAR de uma viagem EXISTENTE, selecione',
                      placeholder: 'Selecione...',
                      isSearchable: true,
                      isClearable: true,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                      options: travels,
                      onChange: onSelectTravel,
                    },
                  ]}
                />
              </DetailBlock>

              <DefaultForm
                callbackSubmit={onSubmit}
                callbackReset={onReset}
                defaultValue={travel}
                disabled={loadingTravel}
                loading={loadingTravel}
                inputs={[
                  {
                    type: 'text',
                    name: 'title',
                    label: 'Título da Viagem',
                    required: true,
                    sm: isAdmin ? 3 : 6,
                    md: isAdmin ? 3 : 6,
                    lg: isAdmin ? 3 : 6,
                    xl: isAdmin ? 3 : 6,
                    onChange: props => {
                      setTravel(oldState => ({
                        ...oldState,
                        title: props.target.value,
                      }))
                    },
                  },
                  {
                    type: 'select',
                    name: 'agent',
                    label: 'Consultor',
                    placeholder: 'Selecione...',
                    isSearchable: true,
                    isClearable: true,
                    required: true,
                    sm: 3,
                    md: 3,
                    lg: 3,
                    xl: 3,
                    options: agents,
                    hide: !isAdmin,
                    onChange: props => {
                      setTravel(oldState => ({
                        ...oldState,
                        agent: props.target.value,
                      }))
                    },
                  },
                  {
                    type: 'city',
                    name: 'origin',
                    label: 'Cidade de Origem',
                    placeholder: 'Pesquise por uma cidade...',
                    required: true,
                    onChange: props => {
                      setTravel(oldState => ({
                        ...oldState,
                        origin: props.target.value,
                      }))
                    },
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                  },
                  {
                    type: 'date',
                    name: 'start',
                    label: 'Ida',
                    required: true,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    onChange: props => {
                      setTravel(oldState => ({
                        ...oldState,
                        start: props.target.value,
                      }))
                    },
                  },
                  {
                    type: 'date',
                    name: 'end',
                    label: 'Volta',
                    // required: true,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    onChange: props => {
                      setTravel(oldState => ({
                        ...oldState,
                        end: props.target.value,
                      }))
                    },
                  },
                  user.isOperator && {
                    type: 'text',
                    name: 'belongingAgency',
                    label: 'Agência',
                    placeholder: 'Agência',
                    required: true,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                    onChange: props => {
                      setTravel(oldState => ({
                        ...oldState,
                        belongingAgency: props.target.value,
                      }))
                    },
                  },
                  {
                    type: 'travelerSelect',
                    name: 'travelers',
                    label: 'Viajantes',
                    placeholder: 'Pesquise por uma viajante',
                    isMulti: true,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                    onChange: onSelectTraveler,
                  },
                  {
                    type: 'textarea',
                    name: 'desc',
                    label: 'Observações (visível somente para o consultor)',
                    placeholder: 'Escreva aqui...',
                    rows: 4,
                    maxLength: 1000,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                    onChange: props => {
                      setTravel(oldState => ({
                        ...oldState,
                        desc: props.target.value,
                      }))
                    },
                  },
                ]}
              />
            </>
          )}
        </PanelBody>
      </Panel>
    </Container>
  )
}
