import { useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import { toast } from 'react-toastify'
import LoadingContainer from '~/components/LoadingContainer'
import { CancelButton, SaveButton } from '~/components/Button/styles'
import Table from '~/components/Table'
import DownloadFile from '~/functions/DownloadFile'
import { useNavigate } from 'react-router-dom'

export default function ImportCustomer() {
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [customers, setCustomers] = useState([])
  const [file, setFile] = useState({})

  const onSubmitFile = async () => {
    try {
      setLoading(true)
      const formData = new FormData()

      formData.append('file', file)

      const { data } = await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/import`,
        formData,
      )

      console.log('passou da pesquisa')

      if (data.length == 0) {
        toast.info(
          'Clientes já cadastrados, ou a planilha não atende as regras anteriores.',
        )
        setFile({})
        setCustomers([])
        setShowModal(true)
        setLoading(false)
        return
      }

      console.log(data)
      setCustomers(data)
      setShowModal(true)
      setLoading(false)
      return
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const handleTable = () => setShowModal(!showModal)

  const hideTable = () => {
    setShowModal(false)
    setCustomers([])
    setFile({})
  }

  const onSubmitListCustomers = async () => {
    try {
      setLoading(true)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/import/insert`,
        customers,
      )

      setLoading(false)
      handleTable()
      toast.success('Importação de clientes concluída com sucesso!')
      navigate('/relationship-customers')
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const onDownloadFile = async () => {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/import`,
      )

      DownloadFile(
        `data:application/octet-stream;base64,${data}`,
        'Platilha_De_Importacao_Tripmee.xlsx',
      )

      setLoading(false)
      toast.success('Download realizado com sucesso!')
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <Container>
      <h1 className='page-header'>Importação de clientes</h1>

      <Panel>
        <PanelHeader title='Planilha modelo - Excel' noButton />
        <PanelBody>
          <DefaultForm
            callbackSubmit={onDownloadFile}
            submitLabel={'Baixar'}
            disabled={loading}
            inputs={[
              {
                type: 'text',
                name: 'text',
                value: 'Platilha_De_Importacao_Tripmee.xlsx',
                label: '',
                disabled: true,
                sm: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title='Regras IMPORTANTES' noButton />
        <PanelBody>
          <ul
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            <li>O cliente obrigatoriamente deve ter nome completo.</li>

            <li>
              Se o cliente tiver email ou telefone, os mesmos não podem estar
              duplicados nem cadastrados na plataforma.
            </li>

            <li>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  color: '#700',
                }}
              >
                ** Todos os clientes inseridos na tabela que não seguirem essas
                regras serão ignorados! **
              </p>
            </li>
          </ul>
        </PanelBody>
      </Panel>

      {!showModal && (
        <Panel>
          <PanelHeader
            title='Importe a planilha preenchida com seus clientes'
            noButton
          />
          <PanelBody>
            <DefaultForm
              callbackSubmit={onSubmitFile}
              submitLabel={'Importar'}
              disabled={loading}
              inputs={[
                {
                  type: 'file',
                  name: 'file',
                  label: 'Selecione o arquivo de planilha',
                  onChange: props => setFile(props.target.files[0]),
                  sm: 12,
                },
              ]}
            />
          </PanelBody>
        </Panel>
      )}

      {showModal && (
        <Panel>
          <PanelHeader title='Clientes prontos para salvar' noButton />
          <PanelBody>
            <Table
              columns={[
                {
                  Header: 'Nome Completo',
                  accessor: 'fullName',
                  width: 200,
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  width: 150,
                },
                {
                  Header: 'Telefone',
                  accessor: 'phone',
                  width: 150,
                },
                {
                  Header: 'CPF/CNPJ',
                  accessor: 'cpf',
                  width: 120,
                },
                {
                  Header: 'Rg',
                  accessor: 'rg',
                  width: 120,
                },
                {
                  Header: 'Aniversário',
                  accessor: 'birthday',
                  width: 100,
                },
                {
                  Header: 'Profissão',
                  accessor: 'profession',
                  width: 100,
                },
                {
                  Header: 'Cep',
                  accessor: 'zipCode',
                  width: 100,
                },
                {
                  Header: 'Endereço',
                  accessor: 'address',
                  width: 100,
                },
                {
                  Header: 'Cidade',
                  accessor: 'city',
                  width: 100,
                },
                {
                  Header: 'Bairro',
                  accessor: 'neighborhood',
                  width: 100,
                },
                {
                  Header: 'Fidelidade',
                  accessor: 'loyalty',
                  width: 100,
                },
                {
                  Header: 'Origem',
                  accessor: 'origin',
                  width: 100,
                },
                {
                  Header: 'Nome de Contato',
                  accessor: 'contactName',
                  width: 150,
                },
                {
                  Header: 'Telefone de Contato',
                  accessor: 'contactPhone',
                  width: 150,
                },
                {
                  Header: 'Email de Contato',
                  accessor: 'contactEmail',
                  width: 150,
                },
              ]}
              tableProps={{
                defaultPageSize: 10,
                hasFooter: false,
                hasPagination: true,
                loading: loading,
                resizable: true,
              }}
              tableStyle={{
                fontSize: '13px',
                cellPadding: '6px 4px',
              }}
              tableLabels={{
                loadingText: 'Carregando...',
                noDataText: 'Sem registros',
                nextButtonText: 'Próximo',
                previousButtonText: 'Anterior',
                pageOfText: 'de',
                pageSizeSelectText: 'registros',
                pageText: 'Página',
              }}
              data={customers}
              loading={loading}
            />
          </PanelBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              fontSize: '14px',
              padding: '8px',
            }}
          >
            <CancelButton type='button' onClick={hideTable}>
              {loading ? <LoadingContainer /> : 'Tentar novamente'}
            </CancelButton>

            <SaveButton
              type='button'
              onClick={() => onSubmitListCustomers()}
              disabled={customers.length === 0}
              style={{ opacity: customers.length === 0 ? '0.5' : '1' }}
            >
              {loading ? <LoadingContainer /> : 'Confirmar'}
            </SaveButton>
          </div>
        </Panel>
      )}
    </Container>
  )
}
