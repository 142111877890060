import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import { useLocation } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { toast } from 'react-toastify'
import { LinkEdit } from '~/components/Link/styles'

export default function RefundList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [data, setData] = useState([])

  useEffect(() => {
    requestGetApi()
  }, [])

  const resetFields = () => {}

  const requestGetApi = async () => {
    setLoading(true)
    const response = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/IntegrationRequest/agency/${location.state.id}`,
    )

    setData(response.data)
    setLoading(false)
  }

  const requestPostApi = async data => {
    setLoading(true)

    const formData = new FormData()

    if (data.nameRequestedAgency)
      formData.append('nameRequestedAgency', data.nameRequestedAgency)

    if (data.desc) formData.append('desc', data.desc)

    formData.append('idRequestingAgency', location.state.id)

    await axiosApis.post(
      `${process.env.REACT_APP_ENDPOINT_APIS}/IntegrationRequest`,
      formData,
    )

    toast.success('Sua solicitação de parceria foi envia com sucesso!')
    requestGetApi()
    setLoading(false)
  }

  return (
    <Container>
      <h1 className='page-header'>Solicitação de Experiências</h1>

      <Panel>
        <PanelHeader noButton title={'Faça a sua solicitação'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={requestPostApi}
            callbackReset={resetFields}
            submitLabel={'Enviar'}
            inputs={[
              {
                type: 'text',
                name: 'nameRequestedAgency',
                label:
                  'Qual Agência gostaria de Vincular as Experiências de Viagens?',
                sm: 12,
              },
              {
                type: 'textarea',
                name: 'desc',
                label: 'Deseja Complementar ou justificar a solicitação?',
                sm: 12,
                style: {
                  maxHeight: '100px',
                },
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Tabela de Solicitações'} noButton />

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Agência Requerida',
                accessor: 'nameRequestedAgency',
                width: 150,
              },
              {
                Header: 'Status',
                accessor: 'situation',
                width: 150,
              },
              {
                Header: 'Complemento / Justificativa',
                accessor: 'desc',
                width: 300,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <LinkEdit
                      to='details'
                      state={{ id: props.row.original.id }}
                    >
                      Detalhes
                    </LinkEdit>
                  )
                },
                width: 80,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
