import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useField } from '@unform/core'
import { Label, Group, TextAreaV, MessageSuccess } from './styles'

export default function TextAreaWrapper({
  name,
  label,
  icon,
  clickIcon,
  value,
  disabled,
  onChange,
  successMessage,
  ...rest
}) {
  const textAreaRef = useRef()
  const { fieldName, defaultValue, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <Group>
      {label && (
        <Label htmlFor={fieldName}>
          {label}{' '}
          {icon && (
            <i
              className={icon}
              onClick={clickIcon}
              style={{ cursor: clickIcon ? 'pointer' : 'default' }}
            />
          )}
        </Label>
      )}

      <TextAreaV
        id={fieldName}
        name={fieldName}
        className='form-control'
        ref={textAreaRef}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        {...rest}
      />

      <MessageSuccess>{successMessage}</MessageSuccess>
    </Group>
  )
}

TextAreaWrapper.defaultProps = {
  label: null,
}

TextAreaWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}
