import DefaultForm from '~/components/DefaultForm'
import {
  Body,
  Container,
  CoverHeader,
  Div,
  Footer,
  Header,
  ImageButton,
} from './style'
import { GetAxios } from '~/services/api'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

export default function longForm() {
  const params = useParams()
  const { axiosApis } = GetAxios()
  const [step, setStep] = useState(0)
  const [agencyName, setAgencyName] = useState()
  const [name, setName] = useState()
  const [cpf, setCpf] = useState()
  const [email, setEmail] = useState()
  const [nacionality, setNacionality] = useState()
  const [cep, setCep] = useState()
  const [address, setAddress] = useState()
  const [n, setN] = useState()
  const [compl, setCompl] = useState()
  const [neighborhood, setNeighborhood] = useState()
  const [county, setCounty] = useState()
  const [uf, setUf] = useState()
  const [company, setCompany] = useState()
  const [brazilianPassport, setBrazilianPassport] = useState()
  const [brazilianPassportQuant, setBrazilianPassportQuant] = useState()
  const [foreignPassport, setForeignPassport] = useState()
  const [foreignPassportQuant, setForeignPassportQuant] = useState()
  const [americanVisa, setAmericanVisa] = useState()
  const [americanVisaQuant, setAmericanVisaQuant] = useState()
  const [yellowFever, setYellowFever] = useState()
  const [yellowFeverQuant, setYellowFeverQuant] = useState()
  const [preference, setPreference] = useState()
  const [commercialPhone, setCommercialPhone] = useState()
  const [phone, setPhone] = useState()
  const [image, setImage] = useState(null)
  const [fileRG, setFileRG] = useState(null)
  const [fileVisa, setFileVisa] = useState(null)
  const [filePassaportBrazilian, setFilePassaportBrazilian] = useState(null)
  const [fileYellowFever, setFileYellowFever] = useState(null)
  const [fileforeignPassport, setFileforeignPassport] = useState(null)
  const [ddi, setDdi] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/64b58220-69f3-11ee-8c99-0242ac120002/access`,
        )

        document.title = 'Plataforma CRM especializada em agências de viagens'

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agent/token/${params.token}`,
        )

        setImage(data.image)
      } catch (error) {
        console.log(error)
      }
    }

    getSource()
    getOptionsDDI()
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  const requestApiPostToCreate = async data => {
    try {
      const formData = new FormData()

      formData.append('token', params.token)

      formData.append('fullName', data.fullName)
      formData.append('cpfCnpj', data.cpfCnpj)
      formData.append('birthdayDate', data.birthdayDate)
      formData.append('email', data.email)
      formData.append('nacionality', data.nacionality)
      formData.append('zipCode', data.zipCode)
      formData.append('address', data.address)
      formData.append('n', data.n)
      formData.append('compl', data.compl)
      formData.append('neighborhood', data.neighborhood)
      formData.append('city', data.city)
      formData.append('state', data.uf)
      formData.append('company', data.company)
      if (data.ddi) {
        formData.append('ddi', data.ddi.value ?? '')
      }
      formData.append(
        'brazilianPassport',
        data.brazilianPassport.value == 'sim' ? true : false,
      )
      formData.append('brazilianPassportQuant', data.brazilianPassportQuant)
      formData.append(
        'foreignPassport',
        data.foreignPassport.value == 'sim' ? true : false,
      )
      formData.append('foreignPassportQuant', data.foreignPassportQuant)
      formData.append(
        'americanVisa',
        data.americanVisa.value == 'sim' ? true : false,
      )
      formData.append('americanVisaQuant', data.americanVisaQuant)
      formData.append(
        'yellowFever',
        data.yellowFever.value == 'sim' ? true : false,
      )
      formData.append('yellowFeverQuant', data.yellowFeverQuant)
      formData.append('fileRG', fileRG)
      formData.append('filePassaportBrazilian', filePassaportBrazilian)
      formData.append('fileForeignPassport', fileforeignPassport)
      formData.append('fileVisa', fileVisa)
      formData.append('fileYellowFever', fileYellowFever)
      formData.append('preference', data.preference)
      formData.append('commercialPhone', data.commercialPhone)
      formData.append('phone', data.phone)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/customer`,
        formData,
      )

      toast.success('Cadastro realizado com sucesso! ')
    } catch (err) {
      toast.info(err.response.data)
      console.log(err)
    }
  }

  return (
    <Div>
      <CoverHeader>
        <Header>
          <ImageButton>
            <img
              src={`data:image/*;base64,${image}`}
              alt='logo'
              style={{ maxWidth: '150px' }}
            />
          </ImageButton>
        </Header>
      </CoverHeader>

      <Container>
        <>
          <Body id='form'>
            <h3>Formulário de Cliente</h3>
            <DefaultForm
              callbackSubmit={requestApiPostToCreate}
              submitLabel='Enviar'
              backgroundColor='#D54A26'
              color='#fff'
              inputs={[
                {
                  empty: true,
                  text: '<h5>Dados Pessoais<h5>',
                  sm: 12,
                },
                {
                  type: 'text',
                  name: 'fullName',
                  label: 'Nome Completo (conforme documento oficial)',
                  max: 40,
                  required: true,
                  onChange: e => setAgencyName(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'date',
                  name: 'birthdayDate',
                  label: 'Data de Nascimento',
                  required: true,
                  onChange: e => setName(e.target.value),
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'text',
                  name: 'cpfCnpj',
                  label: 'CPF/CNPJ',
                  onChange: e => setCpf(e.target.value),
                  required: true,
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'select',
                  name: 'ddi',
                  label: 'DDI',
                  options: ddi,
                  placeholder: 'Selecione...',
                  required: true,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'text',
                  name: 'phone',
                  label: 'Telefone',
                  mask: '(99) 99999-9999',
                  required: true,
                  onChange: e => setPhone(e.target.value),
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'email',
                  name: 'email',
                  label: 'E-mail',
                  required: true,
                  onChange: e => setEmail(e.target.value),
                  sm: 5,
                  md: 5,
                  lg: 5,
                  xl: 5,
                },
                {
                  type: 'text',
                  name: 'nacionality',
                  label: 'Nacionalidade',
                  max: 20,
                  required: true,
                  onChange: e => setNacionality(e.target.value),
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                {
                  empty: true,
                  text: '<br><h5>Endereço<h5>',
                  sm: 12,
                },
                {
                  type: 'text',
                  name: 'zipCode',
                  label: 'CEP',
                  mask: '99.999.-999',
                  required: true,
                  onChange: e => setCep(e.target.value),
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'text',
                  name: 'address',
                  label: 'Endereço',
                  max: 40,
                  required: true,
                  onChange: e => setAddress(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'text',
                  name: 'n',
                  label: 'N°',
                  required: true,
                  onChange: e => setN(e.target.value),
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1,
                },
                {
                  type: 'text',
                  name: 'compl',
                  label: 'Compl.',
                  max: 20,
                  onChange: e => setCompl(e.target.value),
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'text',
                  name: 'neighborhood',
                  label: 'Bairro',
                  max: 30,
                  required: true,
                  onChange: e => setNeighborhood(e.target.value),
                  sm: 5,
                  md: 5,
                  lg: 5,
                  xl: 5,
                },
                {
                  type: 'text',
                  name: 'city',
                  label: 'Município',
                  max: 30,
                  required: true,
                  onChange: e => setCounty(e.target.value),
                  sm: 5,
                  md: 5,
                  lg: 5,
                  xl: 5,
                },
                {
                  type: 'text',
                  name: 'uf',
                  label: 'UF',
                  max: 20,
                  required: true,
                  onChange: e => setUf(e.target.value),
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'text',
                  name: 'company',
                  label: 'Nome da empresa para qual trabalha:',
                  max: 50,
                  onChange: e => setCompany(e.target.value),
                  sm: 8,
                  md: 8,
                  lg: 8,
                  xl: 8,
                },
                {
                  type: 'text',
                  name: 'commercialPhone',
                  label: 'Telefone Comercial',
                  onChange: e => setCommercialPhone(e.target.value),
                  sm: 4,
                  md: 4,
                  lg: 4,
                  xl: 4,
                },
                {
                  empty: true,
                  text: '<br><h5>Sobre Documentos</h5>',
                  sm: 6,
                },
                {
                  empty: true,
                  text: '<br><h5>Indique o nome e o n° dos cartões fidelidade que possui.</h5>',
                  sm: 6,
                },
                {
                  type: 'select',
                  name: 'brazilianPassport',
                  label: 'Passaporte Brasileiro? ',
                  required: true,
                  placeholder: 'Selecione...',
                  options: [
                    {
                      label: 'Sim',
                      value: 'sim',
                    },
                    {
                      label: 'Não',
                      value: 'Não',
                    },
                  ],
                  onChange: e => setBrazilianPassport(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'text',
                  name: 'brazilianPassportQuant',
                  label: '         ',
                  onChange: e => setBrazilianPassportQuant(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'select',
                  name: 'foreignPassport',
                  label: 'Passaporte Estrangeiro? ',
                  required: true,
                  placeholder: 'Selecione...',
                  options: [
                    {
                      label: 'Sim',
                      value: 'sim',
                    },
                    {
                      label: 'Não',
                      value: 'Não',
                    },
                  ],
                  onChange: e => setForeignPassport(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'text',
                  name: 'foreignPassportQuant',
                  label: '      ',
                  onChange: e => setForeignPassportQuant(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'select',
                  name: 'americanVisa',
                  label: 'Visto Americano? ',
                  required: true,
                  placeholder: 'Selecione...',
                  options: [
                    {
                      label: 'Sim',
                      value: 'sim',
                    },
                    {
                      label: 'Não',
                      value: 'Não',
                    },
                  ],
                  onChange: e => setAmericanVisa(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'text',
                  name: 'americanVisaQuant',
                  label: '          ',
                  onChange: e => setAmericanVisaQuant(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'select',
                  name: 'yellowFever',
                  label: 'Vacina da Febre Amarela? ',
                  required: true,
                  placeholder: 'Selecione...',
                  options: [
                    {
                      label: 'Sim',
                      value: 'sim',
                    },
                    {
                      label: 'Não',
                      value: 'Não',
                    },
                  ],
                  onChange: e => setYellowFever(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'text',
                  name: 'yellowFeverQuant',
                  label: '          ',
                  onChange: e => setYellowFeverQuant(e.target.value),
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'text',
                  name: 'preference',
                  label: 'Tem preferencia de cia Aérea e de lugar no voo? ',
                  required: true,
                  onChange: e => setPreference(e.target.value),
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12,
                },
                {
                  empty: true,
                  text: 'Anexe os seguintes documentos (sempre da página que consta os dados):',
                  sm: 12,
                },
                {
                  type: 'file',
                  name: 'fileRG',
                  label: 'CNH ou RG',
                  onChange: e => setFileRG(e.target.files[0]),
                  required: true,
                  sm: 6,
                },
                {
                  type: 'file',
                  name: 'fileVisa',
                  label: 'Visto Americano',
                  onChange: e => setFileVisa(e.target.files[0]),
                  sm: 6,
                },
                {
                  type: 'file',
                  name: 'filePassaportBrazilian',
                  label: 'Passaporte brasileiro validado',
                  onChange: e => setFilePassaportBrazilian(e.target.files[0]),
                  sm: 6,
                },
                {
                  type: 'file',
                  name: 'fileYellowFever',
                  label: 'Vacina de febre amarela',
                  onChange: e => setFileYellowFever(e.target.files[0]),
                  sm: 6,
                },
                {
                  type: 'file',
                  name: 'fileforeignPassport',
                  label: 'Passaporte estrangeiro valido (se houver)',
                  onChange: e => setFileforeignPassport(e.target.files[0]),
                  sm: 6,
                },
              ]}
            />
          </Body>
          <h5>Exemplo de envio de documentos</h5>
          <img
            src={'/images/worldtour/visto_exemple.jpg'}
            alt='logo'
            style={{ maxWidth: '310px' }}
          />
        </>
      </Container>
      <br></br>

      <br></br>

      <Footer>
        <div>
          <div>
            <img
              src={`data:image/*;base64,${image}`}
              alt='logo'
              style={{ maxWidth: '150px' }}
            />
          </div>
        </div>
      </Footer>
    </Div>
  )
}
