import ReactTable from '~/components/Table'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function ReminderTable({
  reminders = [],
  loading = false,
  isDone = () => {},
}) {
  const navigate = useNavigate()
  return (
    <ReactTable
      columns={[
        {
          Header: '',
          accessor: 'reminderDone',
          width: 20,
          Cell: props => (
            <IconButton
              tooltipEnable={false}
              id={props.row.index}
              type={'user'}
              tooltipOverride={
                props.row.original.reminderDone
                  ? 'Desmarcar'
                  : 'Marcar como feito'
              }
              iconOverride={
                props.row.original.reminderDone
                  ? 'fas fa-check-circle'
                  : 'fas fa-clock'
              }
              colorOverride={props.row.original.reminderDone ? 'green' : '#333'}
              onClick={() => isDone(props.row.original.id)}
            />
          ),
        },
        {
          Header: 'Titulo',
          accessor: 'title',
          width: 200,
        },
        {
          Header: 'Data de envio',
          accessor: 'shootingData',
          width: 100,
        },
        {
          Header: 'Criador',
          accessor: 'agent',
          width: 200,
        },
        {
          Header: '',
          accessor: 'Details',
          width: 20,
          Cell: props => (
            <IconButton
              tooltipEnable={false}
              id={props.row.index}
              type={'details'}
              onClick={() => {
                localStorage.setItem('Q937p@qC', false)
                navigate('/reminders/details', {
                  state: { id: props.row.original.id },
                })
              }}
            />
          ),
        },
      ]}
      data={reminders}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
