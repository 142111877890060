import { DirectionsRenderer, DirectionsService } from '@react-google-maps/api'
import { useState } from 'react'

export default function MapDefaultDirections({ route }) {
  const [directions, setDirections] = useState()

  const callback = (result, status) => {
    if (status === 'OK') {
      if (result.routes && result.routes.length > 0) {
        setDirections(result)
      }
    }
  }

  return (
    <>
      {!directions &&
        route.fromData?.startAddress &&
        route.toData?.startAddress && (
          <DirectionsService
            options={{
              origin: route.fromData?.startAddress,
              destination: route.toData?.startAddress,
              travelMode: 'DRIVING',
            }}
            callback={callback}
          />
        )}

      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{ markerOptions: { visible: false } }}
        />
      )}
    </>
  )
}
