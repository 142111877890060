import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import Table from '~/components/Table'
import { toast } from 'react-toastify'
import { getDateDiff, getDateFormated, getMoment } from '~/functions/Date'
import styled from 'styled-components'
import { FormatValueBRL } from '~/functions/FormatValue'
import SalesBackofficeHotelList from '../components/SalesBackofficeHotelList'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
const DEFAULT_FILTERS = {
  start: getDateFormated(getMoment().startOf('month'), 'YYYY-MM-DD'),
  end: getDateFormated(getMoment().endOf('month'), 'YYYY-MM-DD'),
}

export default function SalesBackofficeList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [data, setData] = useState([])
  const [defaultFilters, setDefaultFilters] = useState(DEFAULT_FILTERS)
  const [filtersOptions, setFiltersOptions] = useState({
    consolidators: [],
    issuers: [],
    branchs: [],
  })
  const [hasAPin, setHasAPin] = useState(false)

  useEffect(() => {
    onSubmit()

    getFilters()

    getAPin()

    return () => {
      setLoading(false)

      setData([])

      setDefaultFilters(DEFAULT_FILTERS)
    }
  }, [])

  async function getFilters() {
    try {
      setLoadingFilters(true)

      const resConsolidators = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesbackoffice/consolidators`,
      )

      const resIssuers = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesbackoffice/issuers`,
      )

      const resBranchs = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesbackoffice/branchs`,
      )

      setFiltersOptions({
        consolidators: resConsolidators.data,
        issuers: resIssuers.data,
        branchs: resBranchs.data,
      })

      setLoadingFilters(false)
    } catch (error) {
      setLoadingFilters(false)
    }
  }

  async function getAPin() {
    try {
      setLoadingFilters(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/integrationsales/hasapin`,
      )

      setHasAPin(!!res?.data?.hasAPin)

      setLoadingFilters(false)
    } catch (error) {
      setLoadingFilters(false)
    }
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const params = data
        ? {
            start: data.start,
            end: data.end,
            sale: data.sale,
            locator: data.locator,
            product: data.product?.value,
            providerCode: data.providerCode,
            provider: data.provider,
            customerCode: data.customerCode,
            customer: data.customer,
            consolidator: data.consolidator?.value ?? undefined,
            issuer: data.issuer?.value ?? undefined,
            branch: data.branch?.value ?? undefined,
          }
        : { ...defaultFilters }

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/salesbackoffice`,
        {
          params,
        },
      )

      setData(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)

      if (error && error.response.data) {
        return toast.info(error.response.data)
      }

      return toast.info(
        'Não foi possível realizar a solicitação, tente mais tarde!',
      )
    }
  }

  const onValidate = data => {
    if ((data.start && !data.end) || (!data.start && data.end)) {
      toast.info('Preencha os campos Data Inicial e Data Final.')

      return false
    }

    if (getMoment(data.start) > getMoment(data.end)) {
      toast.info('A Data Final precisa ser maior que a Data Inicial.')

      return false
    }

    const diff = getDateDiff(data.start, data.end)

    if (diff.days > 31) {
      toast.info('Informe um período de no máximo 31 dias.')

      return false
    }

    if (
      !data.start &&
      !data.end &&
      !data.sale &&
      !data.locator &&
      !data.cia &&
      !data.product &&
      !data.customerCode &&
      !data.customer &&
      !data.providerCode &&
      !data.provider &&
      !data.consolidator &&
      !data.issuer &&
      (filtersOptions.branch.length > 0 ? !data.branchs : false)
    ) {
      toast.info('Preencha os filtros!')

      return false
    }

    return true
  }

  return (
    <Container>
      <h1 className='page-header'>Vendas Backoffice</h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            disabled={loading}
            submitLabel={'Pesquisar'}
            onValidate={onValidate}
            callbackSubmit={onSubmit}
            callbackReset={() => {
              setData([])

              setDefaultFilters(DEFAULT_FILTERS)

              onSubmit()

              return
            }}
            defaultValue={defaultFilters}
            loading={loading || loadingFilters}
            inputs={[
              {
                type: 'date',
                name: 'start',
                label: 'Emissões de',
                sm: 12,
                lg: 3,
                xl: 2,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Emissões até',
                sm: 12,
                lg: 3,
                xl: 2,
              },
              {
                type: 'text',
                name: 'sale',
                label: 'Venda',
                sm: 12,
                lg: 3,
                xl: 2,
              },
              {
                type: 'text',
                name: 'locator',
                label: 'Localizador',
                sm: 12,
                lg: 3,
                xl: 2,
              },
              {
                type: 'select',
                name: 'product',
                label: 'Tipo de produto',
                options: [
                  { label: 'Aéreo', value: 'TKT' },
                  { label: 'Visto', value: 'VISTO' },
                  { label: 'Hotel', value: 'HTL' },
                  { label: 'Carro', value: 'CAR' },
                  { label: 'Seguro Viagem', value: 'SEG' },
                  { label: 'Pacote', value: 'PCT' },
                  { label: 'Outros Serviços', value: 'OUT' },
                  { label: 'Transfer AERP./HOTEL/AEROP.', value: 'TRF' },
                  { label: 'Rodoviário', value: 'ROD' },
                  { label: 'Coworking', value: 'COW' },
                  { label: 'Transporte', value: 'TRP' },
                  { label: 'Serviços Aéreos Específicos', value: 'SAE' },
                  { label: 'Outros', value: 'OUT' },
                ],
                isClearable: true,
                sm: 12,
                lg: 3,
                xl: 2,
              },
              {
                type: 'select',
                name: 'consolidator',
                label: 'Consolidadora',
                options: filtersOptions.consolidators,
                isClearable: true,
                sm: 12,
                lg: 3,
                xl: 2,
              },
              {
                type: 'select',
                name: 'issuer',
                label: 'Consultor',
                options: filtersOptions.issuers,
                isClearable: true,
                sm: 12,
                lg: 3,
                xl: 2,
              },
              filtersOptions.branchs.length > 1
                ? {
                    type: 'select',
                    name: 'branch',
                    label: 'Filial',
                    options: filtersOptions.branchs,
                    isClearable: true,
                    sm: 12,
                    lg: 3,
                    xl: 2,
                  }
                : null,
              {
                type: 'text',
                name: 'providerCode',
                label: 'Código do fornecedor',
                sm: 12,
                lg: 3,
                xl: 2,
              },
              {
                type: 'text',
                name: 'provider',
                label: 'Nome do Fornecedor',
                sm: 12,
                lg: 3,
                xl: 2,
              },
              {
                type: 'text',
                name: 'customerCode',
                label: 'Código do cliente',
                sm: 12,
                lg: 3,
                xl: 2,
              },
              {
                type: 'text',
                name: 'customer',
                label: 'Nome do cliente',
                sm: 12,
                lg: 3,
                xl: 2,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton>
          <b>
            Dados atualizados em {getDateFormated(getMoment(), 'DD/MM/YYYY')} às
            5:00 da manhã &nbsp; &nbsp;
          </b>

          {hasAPin && (
            <LinkRegisterNewTraveler to={'/sales-releases'}>
              Lançamentos
            </LinkRegisterNewTraveler>
          )}

          <LinkRegisterNewTraveler to={'/sales-backoffice-by-issuer'}>
            Vendas por Consultor
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                id: 'expander',
                Header: () => null,
                Cell: ({ row }) => {
                  if (row.original.produto !== 'Hotel') {
                    return null
                  }

                  return (
                    <span {...row.getToggleRowExpandedProps()}>
                      <i
                        className={`fas fa-caret-${
                          row.isExpanded ? 'up' : 'down'
                        }`}
                      />
                    </span>
                  )
                },
                width: 40,
              },
              {
                Header: 'Venda',
                accessor: 'id',
                width: 80,
              },
              {
                Header: 'Consolidadora',
                accessor: 'consolidadora',
                width: 100,
              },
              {
                Header: 'Consultor',
                accessor: 'issuer',
                width: 100,
              },
              {
                Header: 'Filial',
                accessor: 'branch',
                width: 100,
                hide: filtersOptions.branchs.length <= 1,
              },
              {
                Header: 'Emissão',
                accessor: 'dataEmissao',
                width: 100,
              },
              {
                Header: 'Produto',
                accessor: 'produto',
                width: 120,
              },
              {
                Header: 'Fornecedor',
                accessor: 'fornecedor',
                width: 120,
                Cell: props => {
                  return (
                    <span>
                      {props.row.original.codFornecedor && (
                        <>
                          <b>({props.row.original.codFornecedor})</b>

                          <br />
                        </>
                      )}

                      {props.row.original.fornecedor}
                    </span>
                  )
                },
              },
              {
                Header: 'Cliente',
                accessor: 'cliente',
                width: 150,
                Cell: props => {
                  return (
                    <span>
                      {props.row.original.codCliente && (
                        <>
                          <b>({props.row.original.codCliente})</b>

                          <br />
                        </>
                      )}

                      {props.row.original.cliente}
                    </span>
                  )
                },
              },
              {
                Header: 'Data Viagem',
                accessor: 'dataViagem',
                width: 100,
              },
              {
                Header: 'Localizador',
                accessor: 'localizador',
                width: 100,
              },
              {
                Header: 'Tipo de viagem',
                accessor: 'tipViagem',
                width: 120,
                Cell: props => {
                  let value = ''

                  switch (props.row.original.tipViagem) {
                    case 'I':
                      value = 'Internacional'
                      break
                    case 'D':
                      value = 'Doméstico'
                      break
                    default:
                      break
                  }

                  return <span>{value}</span>
                },
              },
              {
                Header: 'Trechos',
                accessor: 'trecho',
                width: 150,
              },
              {
                Header: 'Cidade Origem',
                accessor: 'cidadeOri',
                width: 120,
              },
              {
                Header: 'Cidade Destino',
                accessor: 'cidadeDest',
                width: 120,
              },
              {
                Header: 'Forma Pagamento',
                accessor: 'forma',
                width: 150,
              },
              {
                Header: 'Qtd. Diária',
                accessor: 'quantidade',
                width: 80,
              },
              {
                Header: 'Tarifa',
                accessor: 'totalTarifa',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.totalTarifa)
                },
              },
              {
                Header: 'Taxas',
                accessor: 'taxas',
                info: 'Taxas + Outras Taxas',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.taxas)
                },
              },
              {
                Header: 'DU',
                accessor: 'totalDu',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.totalDu)
                },
              },
              {
                Header: 'Fee',
                accessor: 'totalFee',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.totalFee)
                },
              },
              {
                Header: 'Total',
                accessor: 'total',
                info: 'Tarifa + Taxas + Outras Taxas + DU + Fee',
                width: 100,
                Cell: props => {
                  return FormatValueBRL(props.row.original.total)
                },
              },
            ]}
            subComponent={props => (
              <SalesBackofficeHotelList order={props.row.original.id} />
            )}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

export const Container = styled.div`
  * {
    box-sizing: border-box;
  }

  [class^='col'],
  .row {
    padding: 0;
    margin: 0;
  }

  [class^='col'] {
    padding: 2px 8px;

    .row {
      [class^='col'] {
        padding: 0;
      }
    }
  }

  p {
    margin-bottom: 4px;
  }
`
//
// REMOVED FIELDS OF Table
//
// {
//   Header: 'Empresa',
//   accessor: 'cliente',
//   width: 150,
// },
// {
//   Header: 'Dias Antecedência',
//   accessor: 'datant',
//   width: 150,
// },
// {
//   Header: 'Centro de Custo',
//   accessor: 'centro',
//   width: 150,
// },
// {
//   Header: 'Solicitante',
//   accessor: 'solicitante',
//   width: 150,
// },
// {
//   Header: 'Motivo',
//   accessor: 'motivo',
//   width: 150,
// },
// {
//   Header: 'Departamento',
//   accessor: 'departamento',
//   width: 150,
// },
// {
//   Header: 'Nº Fatura',
//   accessor: 'fatura',
//   width: 150,
// },
// {
//   Header: 'Data Emissão Fatura',
//   accessor: 'dataEmissaoFat',
//   width: 150,
// },
// {
//   Header: 'N° NF',
//   accessor: 'nf',
//   width: 150,
// },
// {
//   Header: 'Data Emissão NF',
//   accessor: 'dataEmissaoNF',
//   width: 150,
// },
// {
//   Header: 'OS',
//   accessor: 'numOS',
//   width: 150,
// },
// {
//   Header: 'Aprovador',
//   accessor: 'aprovador',
//   width: 150,
// },
// {
//   Header: 'Menor Tarifa',
//   accessor: 'mef',
//   width: 150,
// },
// {
//   Header: 'Maior Tarifa',
//   accessor: 'maf',
//   width: 150,
// },
// {
//   Header: 'Data OS',
//   accessor: 'dataOS',
//   width: 150,
// },
