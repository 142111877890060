import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import LoadingContainer from '~/components/LoadingContainer'
import BackofficeWarning from '../components/BackofficeWarning'

export default function IntegrationAgencyCreate() {
  const navigate = useNavigate()
  const location = useLocation()
  const { axiosApis } = GetAxios()
  const [fields, setFields] = useState([])
  const [defaultValue, setDefaultValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [config, setConfig] = useState({})
  const [configDefaultValue, setConfigDefaultValue] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const params = {
          integrationId: location.state.integration.integrationId,
        }

        const resConfig = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3/backoffice-config`,
        )

        const resFields = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3/fields`,
          { params },
        )

        const newDefaultValue = {}

        resFields.data.forEach(x => {
          newDefaultValue[x.label] = x.value
        })

        setDefaultValue(newDefaultValue)

        setFields(resFields.data)

        const resConfigDataDays = resConfig.data.importBackofficeDays ?? []

        const newConfig = {
          importBackofficeCustomers: resConfig.data.importBackofficeCustomers,
          sunday: resConfigDataDays.includes(0),
          monday: resConfigDataDays.includes(1),
          tuesday: resConfigDataDays.includes(2),
          wednesday: resConfigDataDays.includes(3),
          thursday: resConfigDataDays.includes(4),
          friday: resConfigDataDays.includes(5),
          saturday: resConfigDataDays.includes(6),
        }

        setConfigDefaultValue(newConfig)

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    getSource()
  }, [])

  async function onSubmit(data) {
    try {
      setLoadingForm(true)

      const formData = new FormData()

      formData.append('integrationId', location.state.integration.integrationId)

      formData.append('values', JSON.stringify(data))

      if (config) {
        if (config.importBackofficeCustomers) {
          formData.append(
            'importBackofficeCustomers',
            !!config.importBackofficeCustomers,
          )
        }

        if (config.sunday) {
          formData.append('importBackofficeDays', 0)
        }

        if (config.monday) {
          formData.append('importBackofficeDays', 1)
        }

        if (config.tuesday) {
          formData.append('importBackofficeDays', 2)
        }

        if (config.wednesday) {
          formData.append('importBackofficeDays', 3)
        }

        if (config.thursday) {
          formData.append('importBackofficeDays', 4)
        }

        if (config.friday) {
          formData.append('importBackofficeDays', 5)
        }

        if (config.saturday) {
          formData.append('importBackofficeDays', 6)
        }
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3/validate`,
        formData,
      )

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3`,
        formData,
      )

      if (data.username && data.password) {
        try {
          const response = await axiosApis.post(
            `/integrationv3/validate/availability`,
            data,
          )

          localStorage.setItem('VEfKJmx7Wx9SkwW7', response?.data)
        } catch (err) {
          toast.info('Credencias para consulta de disponibilidade inválidas')
        }
      } else {
        localStorage.setItem('VEfKJmx7Wx9SkwW7', null)
      }

      setLoadingForm(false)

      toast.success('Credenciais validadas e atualizadas com sucesso.')

      if (
        location.state.integration &&
        location.state.integration.consolidator &&
        location.state.integration.consolidator.includes('Wintour')
      ) {
        navigate('/sales-backoffice')
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      } else {
        navigate('/sales-backoffice')
      }

      return setLoadingForm(false)
    }
  }

  async function onDelete() {
    try {
      setLoadingForm(true)

      if (window.confirm('Deseja realmente remover a integração?')) {
        const params = {
          integrationId: location.state.integration.integrationId,
        }

        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3`,
          { params },
        )

        setLoadingForm(false)

        toast.success('Removido com sucesso.')

        navigate('/integrationagency')

        setTimeout(() => window.location.reload(), 500)

        return
      }

      setLoadingForm(false)
    } catch (error) {
      console.log(error)

      toast.error('Não foi possível remover a integração.')

      return setLoadingForm(false)
    }
  }

  return (
    <div>
      <PageTitle
        title={`Integrações - ${location.state.integration.consolidator}`}
      />

      {location.state.integration.consolidator &&
        location.state.integration.consolidator.includes('Wintour') && (
          <BackofficeWarning />
        )}

      <Panel>
        <PanelHeader noButton title='Detalhes' />

        <PanelBody>
          {!loading && (
            <>
              {location.state.integration.consolidator &&
                location.state.integration.consolidator.includes('Wintour') && (
                  <DefaultForm
                    onChangeData={setConfig}
                    defaultValue={configDefaultValue}
                    inputs={[
                      {
                        type: 'check',
                        name: 'importBackofficeCustomers',
                        label: 'Importar novos clientes do Backoffice',
                        sm: 12,
                      },
                      {
                        empty: true,
                        text: '<h6>Selecionar dias em que os dados serão buscados:</h6>',
                        sm: 12,
                      },
                      {
                        type: 'check',
                        name: 'sunday',
                        label: 'Domingo',
                        sm: 1,
                      },
                      {
                        type: 'check',
                        name: 'monday',
                        label: 'Segunda',
                        sm: 1,
                      },
                      {
                        type: 'check',
                        name: 'tuesday',
                        label: 'Terça',
                        sm: 1,
                      },
                      {
                        type: 'check',
                        name: 'wednesday',
                        label: 'Quarta',
                        sm: 1,
                      },
                      {
                        type: 'check',
                        name: 'thursday',
                        label: 'Quinta',
                        sm: 1,
                      },
                      {
                        type: 'check',
                        name: 'friday',
                        label: 'Sexta',
                        sm: 1,
                      },
                      {
                        type: 'check',
                        name: 'saturday',
                        label: 'Sábado',
                        sm: 1,
                      },
                    ]}
                  />
                )}

              <DefaultForm
                loading={loadingForm}
                callbackSubmit={onSubmit}
                callbackDelete={onDelete}
                defaultValue={defaultValue}
                inputs={fields.map(e => {
                  let required =
                    e.label == 'username' ||
                    e.label == 'password' ||
                    e.label == 'observation'
                      ? false
                      : true

                  if (
                    location.state.integration.consolidator.includes('Wintour')
                  ) {
                    required = false
                  }

                  return {
                    type: `${e.label == 'observation' ? 'textarea' : 'text'}`,
                    name: e.label,
                    label: `${
                      e.label == 'observation' ? 'Observação' : e.label
                    }`,
                    required: required,
                    sm: 12,
                  }
                })}
              />
            </>
          )}

          {loading && <LoadingContainer size={'md'} height={'400px'} />}
        </PanelBody>
      </Panel>
    </div>
  )
}
