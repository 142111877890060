import React from 'react'

export default function Divisor({
  margin = '16px 0',
  border = false,
  vertical = false,
}) {
  return (
    <div
      style={{
        height: '1px',
        width: vertical ? '16px' : '100%',
        borderBottom: border ? '1px solid #ccc' : 'none',
        margin: vertical ? '0 ' + margin : margin,
      }}
    />
  )
}
