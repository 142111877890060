import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import PageTitle from '~/components/PageTitle'
import { useSelector } from 'react-redux'
// import Table from '~/components/Table'
// import { LinkEdit, LinkRegisterNewTraveler } from '~/components/Link/styles'

export default function TravelGroupsList() {
  const { axiosApis } = GetAxios()
  // const [loading, setLoading] = useState(false)
  // const [data, setData] = useState([])
  const [agents, setAgents] = useState([])
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    requestApiAgentOption()
  }, [])

  const requestApiAgentOption = async () => {
    try {
      const { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
      )

      setAgents(data)
    } catch (err) {
      toast.info(err.response.data)
      setAgents([])
    }
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('title', data.title)
      formData.append('agent', data.agent.value)
      formData.append('passengers', data.passengers)
      formData.append('origin', data.origin.value)
      formData.append('destiny', data.destiny.value)
      formData.append('start', data.start)
      formData.append('end', data.end)
      formData.append('desc', data.desc)
      formData.append('traveler', 0)
      formData.append('travel', '0')
      if (user.isOperator) {
        formData.append('belongingAgency', data.belongingAgency ?? '')
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/GroupTravel`,
        formData,
      )

      // const result = await axiosApis.post(
      //   `${process.env.REACT_APP_ENDPOINT_APIS}/travel/GroupTravel`,
      //   {
      //     formData,
      //   },
      // )

      toast.success('Viagem criada com sucesso')
      // navigate('/travels/details/presentation', {
      //   state: { id: result.data.id },
      // })
      navigate(-1)
    } catch (err) {
      toast.info(err.response.data)
    }
  }

  return (
    <Container>
      <PageTitle title={'Criar Viagem Em Grupo'} />

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Salvar'}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Título da Viagem',
                required: true,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                type: 'select',
                name: 'agent',
                label: 'Consultor',
                placeholder: 'Selecione...',
                isSearchable: true,
                isClearable: true,
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
                options: agents,
              },
              {
                type: 'number',
                name: 'passengers',
                label: 'Quantidade de Viajantes',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'city',
                name: 'origin',
                label: 'Cidade de Origem',
                placeholder: 'Pesquise por uma cidade...',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'city',
                name: 'destiny',
                label: 'Cidade de Destino',
                placeholder: 'Pesquise por uma cidade...',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'date',
                name: 'start',
                label: 'Ida',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Volta',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              user.isOperator && {
                type: 'text',
                name: 'belongingAgency',
                label: 'Agência',
                placeholder: 'Agência',
                required: true,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'desc',
                label: 'Observações (visível somente para o consultor)',
                placeholder: 'Escreva aqui...',
                rows: 4,
                maxLength: 1000,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
