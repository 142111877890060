import { ModalContext } from '~/context/modal'
const { useContext } = require('react')

const useModal = () => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('useModal must be used within a ModalContext')
  }

  return context
}

export default useModal
