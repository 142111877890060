import { useEffect, useState } from 'react'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import Divisor from '~/components/Divisor'
import { searchInObject } from '~/functions/Search'
import useSalesReleasesForm from '../../hooks/useSalesReleasesForm'
import SalesReleasesFormInput from '../SalesReleasesFormInput'

export default function SalesReleaseFormSelectItem({
  items = [],
  callback = e => console.log(e),
  renderItem,
  max = 1,
  required = false,
  pageLimit = 10,
  newRegisterItemModel,
}) {
  const { handleSubmit, register } = useSalesReleasesForm()
  const [itemsToRender, setItemsToRender] = useState(items)
  const [itemsPage, setItemsPage] = useState([])
  const [itemsOriginal, setItemsOriginal] = useState(items)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [itemsSelected, setItemsSelected] = useState([])
  const [page, setPage] = useState(0)

  let timeoutSearch

  useEffect(() => {
    return () => {
      clearTimeout(timeoutSearch)
      setItemsToRender([])
      setItemsOriginal([])
      setItemsPage([])
      setLoadingSearch(false)
      setItemsSelected([])
      setPage(1)
    }
  }, [])

  useEffect(() => {
    const newItemsPage = itemsToRender.slice(0, pageLimit)

    setItemsPage(newItemsPage)
  }, [itemsToRender])

  async function onFilter(e) {
    if (e?.filter) {
      clearTimeout(timeoutSearch)

      timeoutSearch = setTimeout(() => {
        setLoadingSearch(true)

        const searchText = e.filter

        const newItems = itemsOriginal.filter(x =>
          searchInObject(x, searchText),
        )

        setItemsToRender(newItems)

        setLoadingSearch(false)
      }, 1000)
    } else {
      clearTimeout(timeoutSearch)

      setLoadingSearch(false)

      setItemsToRender(itemsOriginal)
    }
  }

  async function onSelect(e) {
    if (max === 1) {
      setItemsSelected([e])
    }

    if (itemsSelected.includes(e)) {
      const newItems = itemsSelected.filter(x => x !== e)

      setItemsSelected(newItems)
    } else {
      if (itemsSelected.length >= max) {
        return
      }

      const newItems = [...itemsSelected, e]

      setItemsSelected(newItems)
    }
  }

  async function onSubmit() {
    callback(itemsSelected)
  }

  async function changePage(newPage) {
    const newItemsPage = itemsToRender.slice(
      newPage * pageLimit,
      newPage * pageLimit + pageLimit,
    )

    setItemsPage(newItemsPage)

    setPage(newPage)
  }

  async function onSubmitChangePage(e) {
    console.log(e)
  }

  return (
    <Container>
      {newRegisterItemModel && (
        <a href='#' onClick={newRegisterItemModel}>
          Cadastrar Novo
        </a>
      )}
      {itemsOriginal.length > 0 && (
        <DefaultForm
          inline
          loading={loadingSearch}
          onChangeData={onFilter}
          inputs={[
            {
              type: 'text',
              label: 'Filtrar',
              name: 'filter',
              sm: 12,
            },
          ]}
        />
      )}

      {max > 1 && (
        <ItemsSelectedControl>
          <span>{itemsSelected.length} selecionado(s)</span>

          <button
            onClick={() => setItemsSelected([])}
            hidden={itemsSelected.length === 0}
          >
            Limpar
          </button>
        </ItemsSelectedControl>
      )}

      <List>
        {itemsPage.map((e, i) => {
          return (
            <Item
              key={i}
              onClick={() => onSelect(e)}
              active={itemsSelected.includes(e)}
              disabled={
                !itemsSelected.includes(e) &&
                itemsSelected.length >= max &&
                max !== 1
              }
            >
              {renderItem && <b>{renderItem(e)}</b>}

              {!renderItem && (
                <>
                  <span>{e.label}</span>

                  <span>{e.value}</span>
                </>
              )}
            </Item>
          )
        })}

        {itemsOriginal.length === 0 && (
          <>
            <Divisor />

            <b>Nenhum item encontrado</b>
          </>
        )}
      </List>

      {itemsToRender.length > pageLimit && (
        <ItemsSelectedControl>
          <button onClick={() => changePage(page - 1)} disabled={page <= 0}>
            <i className='fas fa-arrow-left' />
          </button>

          <div id='pagination-control'>
            <span>Página</span>

            <form onSubmit={handleSubmit(onSubmitChangePage)}>
              <fieldset disabled={false}>
                <SalesReleasesFormInput
                  name='page'
                  type='number'
                  register={register}
                  value={page + 1}
                  onChange={e => {
                    const pageValue = e.target?.value

                    if (
                      !pageValue ||
                      pageValue < 1 ||
                      pageValue > itemsToRender.length / pageLimit + 1
                    ) {
                      changePage(pageValue ? pageValue - 1 : 1)

                      e.target.value = pageValue ?? 1

                      return e
                    }

                    changePage(pageValue - 1)

                    return e
                  }}
                  min={1}
                  max={itemsToRender.length / pageLimit + 1}
                  size='sm'
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                />
              </fieldset>
            </form>

            <span>
              de&nbsp;
              {Math.floor(itemsToRender.length / pageLimit + 1)}
            </span>
          </div>

          <button
            onClick={() => changePage(page + 1)}
            disabled={page >= itemsToRender.length / pageLimit - 1}
          >
            <i className='fas fa-arrow-right' />
          </button>
        </ItemsSelectedControl>
      )}

      <DefaultForm
        disabled={required && itemsSelected.length === 0}
        callbackSubmit={onSubmit}
        inputs={[]}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;

  .mb-3 {
    margin: 0 !important;
  }

  #pagination-control {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 8px;

    .mb-3 {
      margin: 0 !important;
    }
  }
`

const List = styled.ul`
  max-height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 0;
  list-style: none;
  overflow: auto;
  scrollbar-width: thin;
`

const Item = styled.li`
  width: 100%;
  margin: 0px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border-bottom: 1px solid #ccc;
  background: ${props =>
    props.active ? props.theme.colors.primary : 'transparent'};
  color: ${props =>
    props.active ? props.theme.colors.foregroundAlt : 'inherit'};
  opacity: ${props => (props.disabled ? '0.7' : '1')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

const ItemsSelectedControl = styled.div`
  font-weight: 600;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 8px;

  button {
    font-weight: 600;
    background: none;
    border: none;
  }
`
