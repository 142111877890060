import SkeletonLine from '~/components/Skeleton'
import * as S from '../styles'

export default function SkeletonBottom() {
  return (
    <S.Keyboard>
      <S.InputTextArea></S.InputTextArea>
      <S.Footer>
        <S.Features style={{ gap: '8px' }}>
          <SkeletonLine width={20} height={20} />
          <SkeletonLine width={20} height={20} />
          <SkeletonLine width={20} height={20} />
        </S.Features>

        <SkeletonLine width={100} height={20} />
      </S.Footer>
    </S.Keyboard>
  )
}
