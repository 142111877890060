import DefaultForm from '~/components/DefaultForm'
import {
  Body,
  Container,
  CoverHeader,
  Div,
  Footer,
  Header,
  SubTitle,
  Title,
  ImageButton,
  SubTitleM,
  Step1,
} from './style'
import { GetAxios } from '~/services/api'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export default function FreeTrialRdsin() {
  const { axiosApis } = GetAxios()
  const [step, setStep] = useState(0)
  const [agencyName, setAgencyName] = useState()
  const [name, setName] = useState()
  const [cpf, setCpf] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [whatSearching, setWhatsSearching] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/64b58220-69f3-11ee-8c99-0242ac120002/access`,
        )

        document.title = 'Plataforma CRM especializada em agências de viagens'
      } catch (error) {
        console.log(error)
      }
    }

    getSource()
  }, [])

  const requestApiPostToCreate = async data => {
    try {
      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/64b58220-69f3-11ee-8c99-0242ac120002`,
        data,
      )

      setStep(1)
    } catch (err) {
      toast.info(err.response.data)
      console.log(err)
    }
  }

  const requestApiPostToValidate = async data => {
    try {
      const body = {
        token: data.token,
        agencyName: agencyName,
        name: name,
        cpf: cpf,
        email: email,
        phone: phone,
        whatSearching: whatSearching,
        campaign: 'rdsin',
        freeTrialDays: 15,
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/64b58220-69f3-11ee-8c99-0242ac120002/e292d401-7e9e-49c7-8323-42cfa358dbaf`,
        body,
      )

      setStep(2)

      setTimeout(
        () =>
          window.open(
            'https://instagram.com/tripmeeapp?igshid=MmU2YjMzNjRlOQ==',
          ),
        5000,
      )
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Div>
      <CoverHeader>
        <Header>
          <ImageButton
            onClick={() => window.open('https://www.tripmee.com.br/')}
          >
            <img
              src={'/images/logotripmee_parafundobranco.png'}
              alt='logo'
              style={{ maxWidth: '150px' }}
            />
          </ImageButton>

          <a type='button' href='#form'>
            Teste Grátis
          </a>
        </Header>
      </CoverHeader>

      <Container>
        {step == 0 && (
          <>
            <Step1>
              <div id='side-text'>
                <h3>
                  Preencha o formulário para criar a conta da sua Agência de
                  Viagem na plataforma.
                </h3>

                <ul>
                  <li>
                    <h5>Você receberá:</h5>
                  </li>
                  <li>
                    <i className='fas fa-check-circle' /> Otimização do processo
                    da sua agência.
                  </li>
                  <li>
                    <i className='fas fa-check-circle' /> Eficiência na
                    Satistação do Cliente.
                  </li>
                  <li>
                    <i className='fas fa-check-circle' /> Aumento das vendas.
                  </li>
                  <li>
                    <i className='fas fa-check-circle' /> Fortalecimento do
                    poder de recompra.
                  </li>
                </ul>
              </div>

              <div id='iframe-video'>
                <div style={{ position: 'relative', padding: '56.25% 0 0 0' }}>
                  <iframe
                    src='https://player.vimeo.com/video/914272650?h=c8f6d96b1f&autoplay=1'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    frameBorder='0'
                  ></iframe>

                  <script src='https://player.vimeo.com/api/player.js'></script>
                </div>
              </div>
            </Step1>

            <Body id='form'>
              <DefaultForm
                callbackSubmit={requestApiPostToCreate}
                submitLabel='Garanta seu acesso'
                backgroundColor='#D54A26'
                color='#fff'
                inputs={[
                  {
                    type: 'text',
                    name: 'agencyName',
                    label: 'Nome da agência',
                    onChange: e => setAgencyName(e.target.value),
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    type: 'text',
                    name: 'name',
                    label: 'Nome completo',
                    required: true,
                    onChange: e => setName(e.target.value),
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    type: 'text',
                    name: 'CPF',
                    label: 'CPF/CNPJ',
                    onChange: e => setCpf(e.target.value),
                    required: true,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    type: 'text',
                    name: 'phone',
                    label: 'WhatsApp',
                    mask: '(99) 99999-9999',
                    required: true,
                    onChange: e => setPhone(e.target.value),
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    type: 'email',
                    name: 'email',
                    label: 'E-mail',
                    required: true,
                    onChange: e => setEmail(e.target.value),
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                  {
                    type: 'select',
                    name: 'whatSearching',
                    label: 'O que você busca ao participar do TripMee?',
                    required: true,
                    placeholder: 'Selecione...',
                    options: [
                      {
                        label: 'Organizar o processo da minha agência.',
                        value: 'Organizar o processo da minha agência.',
                      },
                      {
                        label: 'Entender o que é a jornada do Cliente.',
                        value: 'Entender o que é a jornada do Cliente',
                      },
                      {
                        label: 'Controle de clientes qualificados.',
                        value: 'Controle de clientes qualificados',
                      },
                      {
                        label: 'Gerenciar minhas vendas.',
                        value: 'Gerenciar minhas vendas',
                      },
                      {
                        label: 'Gestão de pós venda.',
                        value: 'Gestão de pós venda',
                      },
                      {
                        label:
                          'Aumentar a possibilidade de recompra no próximo ano.',
                        value:
                          'Aumentar a possibilidade de recompra no próximo ano',
                      },
                      {
                        label: 'Aumentar as Vendas.',
                        value: 'Aumentar as Vendas',
                      },
                      { label: 'Outros', value: 'Outros' },
                    ],
                    onChange: e => setWhatsSearching(e.target.value),
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                  },
                ]}
              />

              <SubTitle>
                Essas são algumas das agências que confiam no Tripmee
              </SubTitle>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '32px',
                  flexWrap: 'wrap',
                  marginBottom: '32px',
                }}
              >
                <img
                  src={'/images/free-trial/worldtour.png'}
                  alt='logo'
                  style={{ maxWidth: '100px' }}
                />

                <img
                  src={'/images/free-trial/mistertravel.png'}
                  alt='logo'
                  style={{ maxWidth: '100px' }}
                />

                <img
                  src={'/images/free-trial/mercely.png'}
                  alt='logo'
                  style={{ maxWidth: '120px' }}
                />

                <img
                  src={'/images/free-trial/viaerea.png'}
                  alt='logo'
                  style={{ maxWidth: '100px' }}
                />
              </div>
            </Body>
          </>
        )}

        {step == 1 && (
          <>
            <Title>
              Insira o código que enviamos via WhatsApp para receber o seu
              acesso de 15 dias!
            </Title>

            <Body>
              <DefaultForm
                callbackSubmit={requestApiPostToValidate}
                // callbackCancel={() => {}}
                submitLabel='Enviar'
                cancelLabel='Limpar'
                backgroundColor='#D54A26'
                color='#fff'
                inline={true}
                inputs={[
                  {
                    type: 'text',
                    name: 'token',
                    label: 'Código',
                    required: true,
                    max: 6,
                    xs: 8,
                    sm: 8,
                    md: 8,
                    lg: 8,
                    xl: 4,
                  },
                ]}
              />
            </Body>
          </>
        )}

        {step == 2 && (
          <>
            <Title>Bem vindo a Tripmee!</Title>

            <SubTitleM>
              Em instantes você receberá seu acesso. <br />
              Qualquer dúvida entre em contato com os nossos consultores!
            </SubTitleM>

            <SubTitleM>
              Vamos juntos organizar os processos da sua agência <br /> e
              garantir que seu cliente tenha uma ótima jornada com você!
            </SubTitleM>
          </>
        )}
      </Container>

      <Footer>
        <div>
          <div>
            <img
              src={'/images/logotripmee_parafundobranco.png'}
              alt='logo'
              style={{ maxWidth: '150px' }}
            />
          </div>

          <div>
            <span>
              <b>Contato</b> <br />
              comercial@tripmee.com.br
            </span>
          </div>
        </div>

        <button
          type='button'
          onClick={() =>
            window.open(
              'https://instagram.com/tripmeeapp?igshid=MmU2YjMzNjRlOQ==',
            )
          }
        >
          <i className='fab fa-instagram'></i>
        </button>
      </Footer>
    </Div>
  )
}
