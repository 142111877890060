import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import { Container } from './styles'
import DefaultForm from '~/components/DefaultForm'
import { getDateFormated } from '~/functions/Date'
import styled from 'styled-components'
import LoadingContainer from '~/components/LoadingContainer'

export default function Create({ toCreate, onClose }) {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)

  async function handleSubmit(data) {
    try {
      setLoading(true)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelnautical`,
        {
          cia: data.cia,
          cabin: data.cabin,
          locator: data.locator,
          flight: data.flight,
          originPurchase: data.originPurchase,

          origin: data.origin,
          originIata: data.originIata,
          departure: data.departure + ' ' + data.timeDeparture,
          seaportOrigin: data.seaportOrigin,

          destiny: data.destiny,
          description: data.description,
          destinyIata: data.destinyIata,
          arrival: data.arrival + ' ' + data.timeArrival,
          seaportDestiny: data.seaportDestiny,

          vesselName: data.vesselName,
          type: data.type.value,

          tips: data.tips,
          travelId: travel.id,
        },
      )

      toast.success('Náutico criado com sucesso!')

      setLoading(false)

      onClose(true)
    } catch (error) {
      setLoading(false)

      toast.info(
        'Não foi possível criar o náutico, por favor selecione o tipo.',
      )
    }
  }

  async function onCancel() {
    await onClose()
  }

  return (
    <Container>
      {loading && <LoadingContainer color='#ccc' size='lg' height='200px' />}

      {!loading && (
        <DefaultForm
          callbackSubmit={handleSubmit}
          callbackCancel={onCancel}
          cancelLabel={'Voltar'}
          defaultValue={{
            departure: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
            arrival: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
            timeDeparture: getDateFormated(toCreate.date, '00:00'),
            timeArrival: getDateFormated(toCreate.date, '00:00'),
          }}
          loading={loading}
          disabled={loading}
          inputs={[
            {
              type: 'text',
              name: 'cia',
              maxLength: 100,
              label: 'Cia náutica',
              placeholder: 'Cia náutica',
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'text',
              name: 'vesselName',
              maxLength: 100,
              label: 'Nome da embarcação',
              placeholder: 'Nome da embarcação',
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'text',
              name: 'locator',
              maxLength: 100,
              label: 'Bilhete/Localizador',
              placeholder: 'Bilhete/Localizador',
              sm: 12,
              md: 12,
              lg: 4,
              xl: 4,
            },
            {
              type: 'number',
              name: 'flight',
              label: 'Número do náutico',
              placeholder: 'Número do trem',
              sm: 12,
              md: 12,
              lg: 4,
              xl: 4,
            },
            {
              type: 'text',
              name: 'cabin',
              maxLength: 100,
              label: 'Cabine',
              placeholder: 'Cabine',
              sm: 12,
              md: 12,
              lg: 4,
              xl: 4,
            },
            {
              type: 'text',
              name: 'originPurchase',
              maxLength: 100,
              label: 'Fornecedor',
              placeholder: 'Fornecedor',
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'select',
              name: 'type',
              maxLength: 100,
              label: 'Tipo do náutico',
              options: [
                { label: 'Cruzeiro', value: 'Cruzeiro' },
                { label: 'Iate', value: 'Iate' },
                { label: 'Bote', value: 'Bote' },
                { label: 'Escuna', value: 'Escuna' },
                { label: 'Jangada', value: 'Jangada' },
                { label: 'Veleiro', value: 'Veleiro' },
                { label: 'Outros', value: 'Outros' },
              ],
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'text',
              name: 'origin',
              label: 'Cidade de origem',
              placeholder: 'Cidade de origem',
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'text',
              name: 'seaportOrigin',
              label: 'Porto de origem',
              placeholder: 'Porto de origem',
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'date',
              name: 'departure',
              label: 'Data da partida',
              required: true,
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'time',
              name: 'timeDeparture',
              label: 'Horário da partida',
              required: true,
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'text',
              name: 'destiny',
              label: 'Cidade de destino',
              placeholder: 'Cidade de destino',
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'text',
              name: 'seaportDestiny',
              label: 'Porto de destino',
              placeholder: 'Porto de destino',
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            // {
            //   type: 'text',
            //   name: 'destinyIata',
            //   label: 'IATA Destino',
            //   placeholder: 'IATA Destino',
            //   sm: 12,
            //   md: 12,
            //   lg: 4,
            //   xl: 4,
            // },
            {
              type: 'date',
              name: 'arrival',
              label: 'Data da chegada',
              required: true,
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'time',
              name: 'timeArrival',
              label: 'Horário da chegada',
              required: true,
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'textarea',
              name: 'description',
              label: 'Descrição',
              placeholder: 'Descrição',
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
          ]}
        />
      )}
    </Container>
  )
}

const AirsList = styled.ul`
  width: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`

const AirsItem = styled.li`
  margin: 0 8px 8px 0;
  padding: 8px;
  border-radius: 8px;
  background-color: #eee;
  color: #333;
  list-style-type: none;
`

const AirsItemHeader = styled.div`
  width: 100%;
  margin: 0 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

  span {
    font-size: 1rem;
  }

  > * {
    &:first-child {
      display: flex;
      flex-direction: column;
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`

const AirsItemContent = styled.ul`
  width: 100%;
  margin: 0 0 8px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 8px;

    > * {
      &:last-child {
        font-weight: bold;
      }
    }
  }
`
