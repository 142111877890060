import { Panel, PanelBody, PanelHeader } from '../Panel'
import Table from '../Table'
import { getDateFormated } from '~/functions/Date'

export default function SubTableOfSearch({ data }) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '8px',
      }}
    >
      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Table
            data={data}
            columns={[
              {
                Header: 'Viagem',
                accessor: 'travel',
                width: 120,
              },
              {
                Header: 'Consultor',
                accessor: 'agent',
                width: 120,
              },
              {
                Header: 'Data da Resposta',
                accessor: 'createdAt',
                Cell: props => {
                  return getDateFormated(props.row.original.createdAt)
                },
                width: 120,
              },
              {
                Header: 'Nota do Destino',
                accessor: 'destinyRate',
                width: 120,
              },
              {
                Header: 'Nota do Hotel',
                accessor: 'hotelRate',
                width: 120,
              },
              {
                Header: 'Nota dos Passeios, Serviços...',
                accessor: 'transferRate',
                width: 200,
              },
              {
                Header: 'Nota de Guias, Cordialidades...',
                accessor: 'tipsRate',
                width: 200,
              },
              {
                Header: 'Nota do consultor',
                accessor: 'agentRate',
                width: 120,
              },
              {
                Header: 'Nota da Agência',
                accessor: 'agencyRate',
                width: 120,
              },
              {
                Header: 'Ponto alto da viagem?',
                accessor: 'travelComment',
                width: 200,
              },
              {
                Header: 'Feedback',
                accessor: 'feedback',
                width: 140,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: false,
              resizable: true,
              noOverflow: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}
