import React, { Suspense, useState } from 'react'
import { Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as S from './styles'
import { sendDocument, sendImage, sendTextMessage } from '~/services/chat'
import LoadingContainer from '~/components/LoadingContainer'
import RecordingAudio from './RecordingAudio'
import ChatConversation from './ChatConversation'

const Header = React.lazy(() => import('./Header'))
const Emoji = React.lazy(() => import('./Emoji'))

export default function Chat({ auth, scroll, currentConversation, contacts }) {
  const agentName = auth.user.name
  const dispatch = useDispatch()
  const agencyPhone = `${
    auth.user.agencyPhoneDDI ?? ''
  }${auth.user.agencyPhone.replace(/[^0-9]/g, '')}`
  const [formValue, setFormValue] = useState('')
  const [openEmojiWindow, setOpenEmojiWindow] = useState(false)
  const [recordAudio, setRecordAudio] = useState(false)
  const currentMessages = useSelector(state => state.messageReducer.messages)
  const currentContactName =
    contacts.find(c => c.phone == currentConversation?.phoneNumber)?.name ||
    currentConversation?.phoneNumber
  const currentContactPerfilImage =
    contacts.find(c => c.phone == currentConversation?.phoneNumber)?.photo || ''
  const sendMessage = async e => {
    e.preventDefault()

    if (!formValue || !currentConversation?.phoneNumber) {
      return
    }

    const messageWithAgentName = `${makeMessageWithUserName(
      auth.user.name,
    )}:\n${formValue}`

    await sendTextMessage(
      messageWithAgentName,
      currentConversation.phoneNumber,
      agencyPhone,
      auth.tenant,
    )

    setFormValue('')
  }

  const makeMessageWithUserName = name => {
    return `*${name}*`
  }

  const submitFromWhenEnterIsPressed = e => {
    if (e.key == 'Enter' && !e.shiftKey) {
      sendMessage(e)
    }

    if (e.key == 'Enter') return
  }

  const handleSelect = data => {
    setFormValue(`${formValue}${data.native}`)
  }

  return (
    <>
      {currentConversation && (
        <Header
          currentContactName={currentContactName}
          currentContactPerfilImage={currentContactPerfilImage}
          userName={agentName ?? ''}
        />
      )}

      {!currentConversation && (
        <Header
          currentContactName={''}
          currentContactPerfilImage={''}
          userName={''}
        />
      )}

      <S.MessagesContainer>
        <ChatConversation
          scroll={scroll}
          userNumber={agencyPhone}
          currentConversationMessages={currentMessages}
          dispatch={dispatch}
        />
      </S.MessagesContainer>

      {openEmojiWindow && (
        <Suspense fallback={<LoadingContainer />}>
          <Emoji
            handleSelect={handleSelect}
            close={() => setOpenEmojiWindow(!openEmojiWindow)}
          />
        </Suspense>
      )}

      <S.Keyboard onSubmit={sendMessage}>
        {!recordAudio && (
          <S.InputTextArea
            onChange={e => setFormValue(e.target.value)}
            onKeyDown={e => submitFromWhenEnterIsPressed(e)}
            value={formValue}
            data-cy='input-message'
            placeholder='Mensagem'
          ></S.InputTextArea>
        )}

        {recordAudio && (
          <RecordingAudio
            sendMessage={sendMessage}
            hide={() => setRecordAudio(false)}
            to={currentConversation?.phoneNumber}
            from={agencyPhone}
            key={auth.tenant}
          />
        )}

        <S.Footer>
          <S.Features>
            <S.IconBottom
              style={{ cursor: 'pointer' }}
              onClick={() => setOpenEmojiWindow(!openEmojiWindow)}
              className='far fa-smile'
            ></S.IconBottom>

            <S.LabelDocumentClip
              data-cy='document-chat-button'
              htmlFor='document'
            >
              <S.IconBottom className='fas fa-paperclip'></S.IconBottom>
            </S.LabelDocumentClip>

            <input
              type='file'
              id='document'
              accept='application/pdf'
              data-cy='document-input'
              onChange={e => {
                if (e?.target?.value) {
                  const document = e.target.files[0]
                  sendDocument(
                    document,
                    currentConversation.phoneNumber,
                    agencyPhone,
                    auth.tenant,
                  )
                }
              }}
              hidden
            />

            <S.LabelDocumentClip data-cy='image-chat-button' htmlFor='image'>
              <S.IconBottom className='fas fa-camera'></S.IconBottom>
            </S.LabelDocumentClip>

            <input
              type='file'
              id='image'
              accept='image/*'
              data-cy='image-input'
              onChange={e => {
                if (e?.target?.value) {
                  const image = e.target.files[0]
                  sendImage(
                    image,
                    currentConversation.phoneNumber,
                    agencyPhone,
                    auth.tenant,
                  )
                }
              }}
              hidden
            />

            {/*
            <S.IconBottom
              data-cy='recording-audio-chat-button'
              // style={{ cursor: 'pointer' }}
              // TODO ajustar envio primeiro antes de liberar
              // onClick={() => setRecordAudio(true)}
              className='fas fa-microphone'
            ></S.IconBottom>
            <S.IconBottom className='fab fa-gratipay'></S.IconBottom>
            <S.IconBottom className='fas fa-signature'></S.IconBottom>
            <S.IconBottom className='far fa-sticky-note'></S.IconBottom>
            */}
          </S.Features>

          <Button
            type='submit'
            color='primary'
            style={{ fontSize: '12px' }}
            size='sm'
          >
            Enviar <i className='far fa-paper-plane'></i>
          </Button>
        </S.Footer>
      </S.Keyboard>
    </>
  )
}
