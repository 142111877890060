import { useSelector } from 'react-redux'
import ReactTable from '~/components/Table'
import useModal from '~/hooks/useModal'
import { inputsTable } from '../inputs'
import ItemDetails from '../../Details'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'

const itemsTable = ({
  itemsList,
  selectedType,
  loading,
  reloadListCallback,
  tableProps,
  onChangePage,
  onChangeLimit,
  setLoading,
}) => {
  const user = useSelector(state => state.auth.user)
  const { showModal, hideModal } = useModal()
  const { axiosApis } = GetAxios()

  async function showDetails(e) {
    showModal({
      title: 'Editar',
      body: () => (
        <ItemDetails
          itemCode={e.value}
          typeDsc={selectedType.description}
          typeId={e.generalDataTypeId}
          reloadListCallback={reloadListCallback}
        />
      ),
    })
  }

  async function handleDelete(e) {
    try {
      if (confirm('Deseja remover?')) {
        const response = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/salesrelease/deleteItem?type=${e.generalDataTypeId}&code=${e.value}`,
        )

        await reloadListCallback()
        toast.success(`Item removido com sucesso!`)
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  return (
    <ReactTable
      columns={inputsTable({ user, showDetails, handleDelete })}
      data={itemsList}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}

export default itemsTable
