import styled from 'styled-components'

export default function Buttons({ handlePrint, handleDownload, primaryColor }) {
  return (
    <Container>
      <ButtonNoBackground type='button' onClick={() => handlePrint()}>
        CANCELAR
      </ButtonNoBackground>
      <Button
        primaryColor={primaryColor}
        type='button'
        onClick={() => handleDownload()}
      >
        FAZER DOWNLOAD
      </Button>
    </Container>
  )
}

const Container = styled.div`
  width: 21cm;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  margin: 0 auto;
  font-size: 0.6em;
`

const ButtonNoBackground = styled.button`
  color: #777;
  background-color: transparent;
  font-weight: 700;
  padding: 8px;
  border: none;
  border-radius: 8px;
  font-size: 12px;
`

const Button = styled.button`
  background-color: ${props => props.primaryColor};
  color: #fff;
  font-weight: 700;
  padding: 8px;
  border: none;
  border-radius: 8px;
  font-size: 12px;
`
