import { useEffect, useState } from 'react'
import { axiosApis } from '~/services/api'

export const UseContact = () => {
  const [openContactForm, setOpenContactForm] = useState(false)
  const [leadStatusOptions, setLeadStatusOptions] = useState([])
  const [leadFormOptions, setLeadFormOptions] = useState([])
  const [motivationOptions, setMotivationOptions] = useState([])
  const [campaignOptions, setCampaignOptions] = useState([])
  const [observationOptions, setObservationOptions] = useState([])
  const [agentOptions, setAgentOptions] = useState([])

  function callbackOnClose() {
    setOpenContactForm(false)
  }

  async function callbackOnSave() {
    setOpenContactForm(false)
    await loadInformations()
  }

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      await loadInformations()
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function loadInformations() {
    try {
      const agent = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
      )

      setAgentOptions(agent.data)

      await getOptions()

      const leadFormResponse = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/customer/leadform`,
      )

      setLeadFormOptions(leadFormResponse.data)

      const motivation = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/motivation`,
      )

      setMotivationOptions(motivation.data)

      const observation = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/observation`,
      )

      setObservationOptions(
        observation.data.length > 0
          ? [{ label: 'Selecione...', value: '' }, ...observation.data]
          : observation.data,
      )

      const campaign = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/campaign`,
      )

      setCampaignOptions(campaign.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function getOptions() {
    try {
      const leadStatusResponse = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban`,
      )

      setLeadStatusOptions(leadStatusResponse.data)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    openContactForm,
    callbackOnClose,
    callbackOnSave,
    agentOptions,
    observationOptions,
    leadFormOptions,
    leadStatusOptions,
    motivationOptions,
    setOpenContactForm,
    campaignOptions,
  }
}
