import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
import { apiRequest } from '~/apiService'

export default function useHoldingBackofficeCustomersData({
  filters,
  handleProfile,
}) {
  const [loading, setLoading] = useState(false)
  const dashboardsPlaceholders = getDashboardPlaceholders(6)
  const [dashboards, setDashboards] = useState([])
  const [dashboardsFluxoDeAtendimento, setDashboardsFluxoDeAtendimento] =
    useState([])
  const [tableData, setTableData] = useState([])
  const [canceledProspectionsChart, setCanceledProspectionsChart] =
    useState(undefined)
  const [leadContactFormChart, setLeadContactFormChart] = useState(undefined)

  const params = {
    branchs: filters?.branchs ? filters.branchs.map(x => x.value) : [],
    start: filters?.start ?? undefined,
    end: filters?.end ?? undefined,
    requestFromAgency: handleProfile,
  }

  useEffect(() => {
    getData()
  }, [filters])

  async function getData() {
    try {
      setLoading(true)

      const [
        dashboards,
        dashboardsFluxoDeAtendimento,
        canceledProspectionsChart,
        tableData,
        leadContactFormChart,
      ] = await Promise.allSettled([
        apiRequest('get', 'holdingbackoffice/customers/dashboards', params),
        apiRequest(
          'get',
          'holdingbackoffice/customers-fluxo-de-atendimento/dashboards',
          params,
        ),
        apiRequest(
          'get',
          'holdingbackoffice/customers/chart/canceled-prospections',
          params,
        ),
        apiRequest('get', 'holdingbackoffice/customers/table', params),
        apiRequest(
          'get',
          'holdingbackoffice/customers/chart/lead-contact-form',
          params,
        ),
      ])

      setDashboards(dashboards?.value?.data)
      setDashboardsFluxoDeAtendimento(dashboardsFluxoDeAtendimento?.value?.data)
      setCanceledProspectionsChart(canceledProspectionsChart?.value?.data)
      setTableData(tableData?.value?.data)
      setLeadContactFormChart(leadContactFormChart?.value?.data)
    } catch (error) {
      toast.info('Dados não encontrados. Tente mais tarde!')
      setLoading(false)
      setDashboards(dashboardsPlaceholders)
      setTableData([])
      setCanceledProspectionsChart(undefined)
      setLeadContactFormChart(undefined)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    dashboards,
    dashboardsFluxoDeAtendimento,
    tableData,
    canceledProspectionsChart,
    leadContactFormChart,
  }
}

// import { useEffect, useState } from 'react'
// import { toast } from 'react-toastify'
// import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
// import useAxios from '~/hooks/useAxios'

// export default function useHoldingBackofficeCustomersData({
//   filters,
//   handleProfile,
// }) {
//   const { apiClient } = useAxios()
//   const [loading, setLoading] = useState(false)
//   const dashboardsPlaceholders = getDashboardPlaceholders(6)
//   const [dashboards, setDashboards] = useState([])
//   const [dashboardsFluxoDeAtendimento, setDashboardsFluxoDeAtendimento] =
//     useState([])
//   const [tableData, setTableData] = useState([])
//   const [canceledProspectionsChart, setCanceledProspectionsChart] = useState()
//   const [leadContactFormChart, setLeadContactFormChart] = useState()

//   useEffect(() => {
//     async function getSource() {
//       try {
//         setLoading(true)

//         const params = {
//           branchs: filters?.branchs ? filters.branchs.map(x => x.value) : [],
//           start: filters?.start ?? undefined,
//           end: filters?.end ?? undefined,
//           requestFromAgency: handleProfile,
//         }

//         const dashboards = await apiClient.get(
//           '/holdingbackoffice/customers/dashboards',
//           { params },
//         )

//         setDashboards(dashboards.data)

//         const dashboardsFluxoDeAtendimento = await apiClient.get(
//           '/holdingbackoffice/customers-fluxo-de-atendimento/dashboards',
//           { params },
//         )

//         setDashboardsFluxoDeAtendimento(dashboardsFluxoDeAtendimento.data)

//         const resCanceledProspectionsChart = await apiClient.get(
//           '/holdingbackoffice/customers/chart/canceled-prospections',
//           { params },
//         )

//         setCanceledProspectionsChart(resCanceledProspectionsChart.data)

//         const resTableData = await apiClient.get(
//           '/holdingbackoffice/customers/table',
//           { params },
//         )

//         setTableData(resTableData.data)

//         const resLeadContactForm = await apiClient.get(
//           '/holdingbackoffice/customers/chart/lead-contact-form',
//           { params },
//         )

//         setLeadContactFormChart(resLeadContactForm.data)
//       } catch (error) {
//         toast.info('Dados não encontrados. Tente mais tarde!')
//       } finally {
//         setLoading(false)
//       }
//     }

//     getSource()

//     return () => {
//       setLoading(false)
//       setDashboards(dashboardsPlaceholders)
//       setTableData([])
//       setCanceledProspectionsChart(undefined)
//       setLeadContactFormChart(undefined)
//     }
//   }, [filters])

//   return {
//     loading,
//     dashboards,
//     dashboardsFluxoDeAtendimento,
//     tableData,
//     canceledProspectionsChart,
//     leadContactFormChart,
//   }
// }
