import { Rating } from 'primereact/rating'
import * as S from './styles'
import { axiosApis } from '~/services/api'
import { toast } from 'react-toastify'
import SkeletonLine from '~/components/Skeleton'
import useModal from '~/hooks/useModal'
import ShowInfo from '~/pages/RelationshipCustomers/List/showInfo'

export default function Header({
  currentConversation,
  contacts,
  customer,
  getAllCustomerData,
  loadingCustomer,
}) {
  const tags = customer?.tag?.split(',') || []

  const { showModal } = useModal()

  async function updateRating(data) {
    try {
      const formData = new FormData()

      formData.append('id', customer.id)
      formData.append('rating', data)
      formData.append('ratingChangeOnly', true)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/edit/rating`,
        formData,
      )
      if (customer?.phone) getAllCustomerData(customer.phone)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  async function openTagsModal(traveler) {
    showModal({
      title: 'Tags',
      body: () => (
        <ShowInfo
          callback={() => getAllCustomerData(customer.phone)}
          traveler={traveler}
          isFromChat={true}
        />
      ),
      width: '30%',
    })
  }

  return (
    <>
      {currentConversation?.phoneNumber.search('group') < 0 && (
        <div>
          <S.CustomerInfo>
            {!contacts.find(
              c => c.phone == currentConversation?.phoneNumber,
            ) ? (
              <i
                style={{ fontSize: '120px', color: '#bdbfbf' }}
                className='fas fa-user-circle'
              />
            ) : (
              <img
                src={`data:image/*;base64,${
                  contacts.find(
                    c => c.phone == currentConversation?.phoneNumber,
                  )?.photo
                }`}
                style={{
                  width: '120px',
                  height: '120px',
                  borderRadius: '50%',
                  marginBottom: '10px',
                }}
              />
            )}
            <S.TextHeading>
              {contacts.find(c => c.phone == currentConversation?.phoneNumber)
                ?.name ||
                customer?.fullName ||
                ''}
            </S.TextHeading>
            {loadingCustomer && !customer && (
              <>
                <SkeletonLine width={100} />
                <SkeletonLine width={100} />
                <SkeletonLine width={160} height={20} />
              </>
            )}
            {!loadingCustomer && customer && (
              <S.CustomerInfo>
                <Rating
                  cancel={false}
                  value={customer?.rating}
                  onChange={e => updateRating(e.value)}
                />
                <S.TagContainer>
                  {tags.map(tag => (
                    <S.TagBadge key={tag}>{tag}</S.TagBadge>
                  ))}
                  <S.AddBadge onClick={() => openTagsModal(customer)}>
                    <i className='fas fa-plus' />
                  </S.AddBadge>
                </S.TagContainer>
                <S.TextEmail>{customer?.email || ''}</S.TextEmail>
              </S.CustomerInfo>
            )}
          </S.CustomerInfo>
        </div>
      )}
    </>
  )
}
