import React from 'react'

export default function FileResponsive({
  src,
  width = '100%',
  maxWidth = '500px',
  children,
}) {
  return (
    <div
      style={{
        width: width,
        maxWidth: maxWidth,
        textAlign: 'center',
        margin: '8px auto',
        borderRadius: '6px',
        border: '1px solid #eaeaea',
      }}
    >
      {src && src.length > 0 && (
        <img
          src={src}
          alt={'Imagem'}
          style={{
            width: '100%',
            maxHeight: '500px',
            objectFit: 'cover',
            borderRadius: '6px',
          }}
        />
      )}

      {!src && <>{children}</>}
    </div>
  )
}
