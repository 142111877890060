import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #fdfdfd !important;
  box-shadow: 0px 0px 8px #aaa;
  min-height: 50px;
  padding: 8px 16px;
`

export const LinkHome = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7f2ec2;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
    color: #7f2ec2;
  }
`

export const LinkHomeBadge = styled.span`
  color: #333;
  border-radius: 3px;
  font-size: 0.7rem;
  font-weight: 600;
`

export const LinkHomeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    img {
      height: 36px;
    }
  }
`

export const LinkMenu = styled(Link)`
  color: #333;
  font-weight: 600;
  font-size: 0.8rem;
  text-decoration: none;
  align-items: center;
  padding: 4px;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: #333;
    opacity: 0.8;
  }

  &.active {
    color: #333;
    background-color: #ccc;
    border-radius: 5px;
  }
`

export const ButtonMenu = styled.button`
  color: #333;
  font-weight: 600;
  font-size: 0.8rem;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 4px;
  margin: 0;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
`

export const SpanNavbarToggle = styled.span`
  background: #333;
`
