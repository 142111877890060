import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { LinkEdit, LinkRegisterNewTraveler } from '~/components/Link/styles'
import PageTitle from '~/components/PageTitle'

export default function PartnerList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/partner`,
        )

        setData(response.data)

        return setLoading(false)
      } catch (error) {
        return console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <Container>
      <PageTitle title='Parceiros' />

      {/* <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[]}
          />
        </PanelBody>
      </Panel> */}

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton>
          <LinkRegisterNewTraveler to='create'>
            Novo parceiro
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Nome',
                accessor: 'name',
                width: 200,
              },
              {
                Header: 'Descrição',
                accessor: 'desc',
                width: 300,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <LinkEdit
                      to='details'
                      state={{ id: props.row.original.id }}
                    >
                      Detalhes
                    </LinkEdit>
                  )
                },
                width: 80,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
