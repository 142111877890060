import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { LinkEdit, LinkRegisterNewPanel } from '~/components/Link/styles'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function InstitutionList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [nameFilter, setNameFilter] = useState('')
  const navigate = useNavigate()

  async function showDetails(e) {
    navigate('/pedagogical-event/list/institution/details', {
      state: { id: e },
    })
  }

  useEffect(() => {
    getApiInstitution()
  }, [])

  const getApiInstitution = async () => {
    try {
      setLoading(true)
      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/institution`,
      )

      setData(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(true)
    }
  }

  async function onSubmit(e) {
    if (e.name) {
      setNameFilter(e.name)
    }
  }

  return (
    <Container>
      <h1 className='page-header'>
        Grupos
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Tela de grupos, centraliza todas as informações do grupo de viagens. Podendo organizar por eventos para facilitar a localização dos grupos. Para facilitar as informações dos grupos de viagens é disponibilizado um link para inscrição com o sentido de evitar vários e-mails com documentos podendo ter o risco de perder essas informações.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {
              setNameFilter('')
            }}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Grupo',
                placeholder: 'Grupo',
                required: true,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton>
          <LinkRegisterNewPanel to='/pedagogical-event/create/institution'>
            Cadastrar Grupo
          </LinkRegisterNewPanel>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data.filter(e =>
              e.name
                .toLowerCase()
                .replaceAll('á', 'a')
                .replaceAll('é', 'e')
                .replaceAll('í', 'i')
                .replaceAll('ó', 'o')
                .replaceAll('ú', 'ó')
                .replaceAll('â', 'a')
                .replaceAll('ê', 'e')
                .replaceAll('ô', 'o')
                .replaceAll('ã', 'a')
                .replaceAll('õ', 'o')
                .replaceAll('ç', 'c')
                .includes(
                  nameFilter
                    .toLowerCase()
                    .replaceAll('á', 'a')
                    .replaceAll('é', 'e')
                    .replaceAll('í', 'i')
                    .replaceAll('ó', 'o')
                    .replaceAll('ú', 'ó')
                    .replaceAll('â', 'a')
                    .replaceAll('ê', 'e')
                    .replaceAll('ô', 'o')
                    .replaceAll('ã', 'a')
                    .replaceAll('õ', 'o')
                    .replaceAll('ç', 'c'),
                ),
            )}
            loading={loading}
            columns={[
              {
                Header: 'Nome do Grupo',
                accessor: 'name',
                width: 200,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 150,
              },
              {
                Header: 'E-mail',
                accessor: 'email',
                width: 200,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    // <LinkEdit
                    //   to='details'
                    //   state={{ id: props.row.original.id }}
                    // >
                    //   Detalhes
                    // </LinkEdit>
                    <IconButton
                      id={props.row.index}
                      type={'details'}
                      onClick={() => showDetails(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
