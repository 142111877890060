import { createContext, useState } from 'react'
import LoadingContainer from '~/components/LoadingContainer'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { useEffect } from 'react'
import { GetAxios } from '~/services/api'

const ThemeContext = createContext()

const DEFAULT_THEME = {
  fontFamily: 'Open Sans',
  colors: {
    primary: '#7f2ec2',
    secondary: '#d54c35',
    foreground: '#212121',
    background: '#eaeaea',
    foregroundAlt: '#ffffff',
  },
}

const ThemeContextWrapper = ({ children }) => {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(true)
  const [theme, setTheme] = useState(DEFAULT_THEME)

  useEffect(() => {
    async function getSource() {
      try {
        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/theme`,
        )

        setTheme({
          ...DEFAULT_THEME,
          colors: {
            ...DEFAULT_THEME.colors,
            primary: data.colors.primary,
            secondary: data.colors.secondary,
          },
        })

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    getSource()

    return () => {
      setLoading(true)
      setTheme(DEFAULT_THEME)
    }
  }, [])

  async function updateFont(name, url) {
    const agencyFontFace = new FontFace(name, `url(${url})`)

    await agencyFontFace.load()

    document.fonts.add(agencyFontFace)

    document.body.classList.add('fonts-loaded')

    setTheme(state => ({
      ...state,
      fontFamily: name,
    }))
  }

  return (
    <ThemeContext.Provider value={{ loading, updateFont }}>
      <StyledComponentsThemeProvider theme={theme}>
        {!loading && <>{children}</>}

        {loading && <LoadingContainer size='sm' color='#ccc' />}
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextWrapper }
