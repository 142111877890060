import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { sendMoments } from '~/constants/travelNotifications'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function Create() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()

  const handleSubmit = async data => {
    try {
      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/generalnotifications`,
        {
          title: data.title,
          desc: data.desc,
          increment: data.whenSend.increment,
          baseDate: data.whenSend.baseDate,
        },
      )

      toast.success('Notificação criada com sucesso!')

      navigate(-1)
    } catch (error) {
      toast.error(
        'Não foi possível criar a notificação, tente novamente mais tarde.',
      )

      console.log(error)
    }
  }

  const onValidate = async data => {
    if (data.whenSend && data.whenSend.baseDate) {
      return true
    }

    toast.info('Selecione "Quando enviar?"...')

    return false
  }

  return (
    <Container>
      <PageTitle title={'Agendar notificação geral'} />
      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={handleSubmit}
            onValidate={onValidate}
            inputs={[
              {
                name: 'whenSend',
                type: 'traveldates',
                label: 'Quando enviar?',
                required: true,
                options: sendMoments,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                name: 'title',
                type: 'text',
                label: 'Título',
                required: true,
                maxLength: 50,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                name: 'desc',
                type: 'textarea',
                label: 'Descrição',
                required: true,
                maxLength: 200,
                rows: 4,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
