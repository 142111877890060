import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import ReactTable from '~/components/Table'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { DivFlexRight } from '~/styles/divs'
import { RegisterNewButton } from '~/components/Button/styles'
import { getDateFormated } from '~/functions/Date'
import IconButton from '~/components/IconButton'

export default function List() {
  const { axiosApis } = GetAxios()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const { travel } = useOutletContext()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      await getEntitys()
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function getEntitys() {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldestiny/${travel.id}`,
      )

      setData(res.data)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function handleRemove(e) {
    try {
      setLoading(true)

      if (window.confirm('Deseja remover esse destino da viagem?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveldestiny/${travel.id}/${e.travelExperienceId}/${e.start}`,
        )

        await getEntitys()
      }

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function handleDetails(e) {
    navigate('details', { state: e })
  }

  return (
    <>
      <DivFlexRight>
        <RegisterNewButton type={'button'} onClick={() => navigate('add')}>
          Adicionar destino
        </RegisterNewButton>
      </DivFlexRight>

      <ReactTable
        columns={[
          {
            Header: 'Título',
            accessor: 'title',
            width: 200,
          },
          {
            Header: 'Cidades',
            accessor: 'city',
            width: 300,
          },
          {
            Header: 'Início',
            accessor: 'start',
            Cell: props => {
              return getDateFormated(props.row.original.start)
            },
          },
          {
            Header: 'Fim',
            accessor: 'end',
            Cell: props => {
              return getDateFormated(props.row.original.end)
            },
          },
          {
            Header: '',
            accessor: 'details',
            Cell: props => {
              return (
                <IconButton
                  id={props.row.index}
                  type={'details'}
                  onClick={() => handleDetails(props.row.original)}
                />
              )
            },
            width: 20,
          },
          {
            Header: '',
            accessor: 'remove',
            Cell: props => {
              return (
                <IconButton
                  id={props.row.index}
                  type={'remove'}
                  onClick={() => handleRemove(props.row.original)}
                />
              )
            },
            width: 80,
          },
        ]}
        data={data}
        tableProps={{
          defaultPageSize: 10,
          hasFooter: false,
          hasPagination: true,
          loading: loading,
          resizable: true,
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px',
        }}
        tableLabels={{
          loadingText: 'Carregando...',
          noDataText: 'Sem registros',
          nextButtonText: 'Próximo',
          previousButtonText: 'Anterior',
          pageOfText: 'de',
          pageSizeSelectText: 'registros',
          pageText: 'Página',
        }}
      />
    </>
  )
}
