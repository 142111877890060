import { useContext, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import context from '~/context/createContext'
import {
  getDateDiffLabel,
  getDateFormated,
  getDateTimeFormated,
  getMoment,
} from '~/functions/Date'
import usePermissions from '~/hooks/usePermissions'
import { GetAxios } from '~/services/api'
import { useSelector } from 'react-redux'
import { Container } from './style'
import { EditButton } from '~/components/Button/styles'
import { DeleteButton } from '~/components/Button/styles'
import { right } from '@popperjs/core'

export default function FormContactForm({
  data,
  callback,
  agentOptions,
  motivationOptions,
  observationOptions,
  leadStatusOptions,
  leadFormOptions,
  campaignOptions,
  isWhatsapp = false,
  successMessage = 'Contato salvo com sucesso!',
}) {
  const { axiosApis } = GetAxios()
  const [newContactFields, setNewContactFields] = useState(false)
  const [defaultValue, setDefaultData] = useState()
  const [customer, setCustomer] = useState()
  const [desc, setDesc] = useState()
  const [files, setFiles] = useState(data?.details?.files)
  const [obs, setObs] = useState()
  const [history, setHistory] = useState([])
  const { isAdmin } = usePermissions()
  const ddi = useRef({})
  const [selectDdi, setSelectDdi] = useState({})
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const [showModalNovaAtividade, setShowModalNovaAtividade] = useState(false)
  const [activitiesList, setActivitiesList] = useState([])

  const { agency } = useContext(context)

  const getDdiFromNumber = number => {
    return number.substring(0, 2)
  }

  const getCurrentNumberWithoutDdi = number => {
    return number.substring(2)
  }

  useEffect(() => {
    async function loadSource() {
      await getOptionsDDI()
      if (data) {
        if (data.chatCenterDetails) {
          let phoneNumber =
            data?.chatCenterDetails?.currentConversation?.phoneNumber
          let _ddi = getDdiFromNumber(phoneNumber ?? '')
          setDefaultData({
            title: 'Atendimento via chatcenter',
            contactDate: getDateFormated(getMoment(), 'YYYY-MM-DD'),
            contactTime: getDateFormated(getMoment(), 'HH:mm'),
            ddi:
              ddi.current?.find === undefined
                ? ''
                : ddi.current.find(d => d.value == _ddi),
            agent: agentOptions.find(x => x.userId === user.id),
            email: data?.chatCenterDetails?.customer?.email,
            phone: getCurrentNumberWithoutDdi(phoneNumber),
            activities: activitiesList,
          })
        }

        if (data.stage) {
          setDefaultData({
            ...defaultValue,
            newContact: false,
            contactDate: getDateFormated(getMoment(), 'YYYY-MM-DD'),
            contactTime: getDateFormated(getMoment(), 'HH:mm'),
            leadStatus: leadStatusOptions.find(
              x => x.label === data.stage.title,
            ),
            activities: activitiesList,
          })
        }

        if (data.details) {
          setDesc(data.details.desc)

          setCustomer({
            id: data.details.customerId,
            fullName: data.details.fullName,
            email: data.details.email,
            phone: data.details.phone,
          })

          setDefaultData({
            leadStatus: leadStatusOptions.find(
              x => x.label === data.details.statusLead,
            ),
            leadForm: leadFormOptions.find(
              x => x.label === data.details.contactForm,
            ),
            contactDate: getDateFormated(
              getMoment(data.details.contactDate),
              'YYYY-MM-DD',
            ),
            contactTime: getDateFormated(
              getMoment(data.details.contactDate),
              'HH:mm',
            ),
            agent: agentOptions.find(
              x => x.value === data.details.agentId.toString(),
            ),
            nextAction: data.details.nextAction,
            dateNextAction: data.details.dateNextAction
              ? getDateFormated(
                  getMoment(data.details.dateNextAction),
                  'YYYY-MM-DD',
                )
              : undefined,
            files: files,
            desc: data.details.desc,
            motivations: data?.details?.motivations,
            campaign: data?.details?.campaign,
            title: data.details.title,
            prospection: data.details.prospection,
            activities: activitiesList,
          })

          setHistory(data.details.history)
          setActivitiesList(data.details.activities ?? [])
        }
      }
      if (isWhatsapp && data.chatCenterDetails) {
        const contactForm = leadFormOptions.find(x => x.value == '6')

        setDefaultData(oldState => {
          return {
            ...oldState,
            files: files,
            leadForm: contactForm,
            newContact: data.chatCenterDetails?.customer == null ? true : false,
            prospection:
              data.chatCenterDetails?.customer == null ? true : false,
          }
        })
      }
    }

    loadSource()
    setSelectDdi({
      label: '',
      value: '',
    })
  }, [data])

  useEffect(() => {
    async function loadSource() {
      if (data.chatCenterDetails) {
        const customer = data.chatCenterDetails.customer
        var response = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/customer/select-filter?label=${customer.fullName}`,
        )
        if (response) {
          setDefaultData(oldState => {
            return {
              ...oldState,
              customer: response.data.find(
                details => details.value == customer.id.toString(),
              ),
            }
          })
        }
      }
    }
    loadSource()
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      ddi.current = res.data
      // setDdi(res.data)
      if (isWhatsapp && !data.chatCenterDetails.customer) {
        const currentChatNumber = data.chatCenterDetails.currentChat[0].from
        const currentChatNumberWithouDdi =
          getCurrentNumberWithoutDdi(currentChatNumber)
        const currentDdi =
          data.chatCenterDetails?.customer == null
            ? res.data.find(d => d.value == getDdiFromNumber(currentChatNumber))
            : ''
        setDefaultData(oldState => {
          return {
            ...oldState,
            ddi: currentDdi,
            phone: currentChatNumberWithouDdi,
            name: data.chatCenterDetails?.name,
          }
        })
      }
    } catch (error) {
      return
    }
  }

  async function onValidate(dataToValidate) {
    if (dataToValidate.newContact) {
      if (!dataToValidate.email && !dataToValidate.phone) {
        toast.info('Informe email ou telefone.')

        return false
      }
    }

    if (dataToValidate.nextAction && !dataToValidate.dateNextAction) {
      toast.info(
        'É necessária preencher o campo Data de próxima tarefa quando Próxima tarefa estiver preenchido.',
      )

      return false
    }

    if (!dataToValidate.nextAction && dataToValidate.dateNextAction) {
      toast.info(
        'É necessária preencher o campo Próxima tarefa quando Data de próxima tarefa estiver preenchido.',
      )

      return false
    }

    return true
  }

  async function onSubmitActivity(dataToSave) {
    setShowModalNovaAtividade(false)

    setActivitiesList(acts => [
      ...acts,
      {
        description: dataToSave.description,
        activityDate:
          dataToSave.activityDate + ' ' + dataToSave.activityTime + ':00',
      },
    ])
  }

  const firstRender = useRef(true)
  useEffect(() => {
    if (!firstRender.current) {
      console.log('ollllaaa')
      setDefaultData(oldState => {
        return {
          ...oldState,
          files: files,
          activities: activitiesList,
        }
      })
    }

    firstRender.current = false
  }, [activitiesList])

  const removeItens = (item, state = [], setState) => {
    if (confirm('Deseja remover a atividade?'))
      setState(state.filter(e => item != e))
  }

  async function onSubmit(dataToSave) {
    try {
      const motivationsId = dataToSave.motivations?.map(x => x.value)
      const formData = new FormData()

      formData.append('leadFormId', dataToSave.leadForm.value)
      if (!dataToSave?.leadStatus?.value) {
        toast.error('O campo Status é obrigatório.')
        return
      }

      formData.append('leadStatusId', dataToSave.leadStatus.value)

      const contactDate = `${dataToSave.contactDate} ${dataToSave.contactTime}`
      formData.append('contactDate', contactDate)

      if (dataToSave.agent) {
        formData.append('agentId', dataToSave.agent.value)
      }

      if (dataToSave.motivations) {
        formData.append('motivations', JSON.stringify(motivationsId))
      }

      if (dataToSave.campaign) {
        formData.append(
          'campaign',
          dataToSave.campaign.value != null
            ? JSON.stringify([dataToSave?.campaign?.value])
            : null,
        )
      }

      if (dataToSave.nextAction) {
        formData.append('nextAction', dataToSave.nextAction)
      }

      if (dataToSave.dateNextAction) {
        formData.append('dateNextAction', dataToSave.dateNextAction)
      }

      if (dataToSave.files) {
        dataToSave.files.map(file => formData.append('files', file))
      }

      if (dataToSave.desc) {
        formData.append('desc', dataToSave.desc)
      }

      if (dataToSave.title) {
        formData.append('title', dataToSave.title)
      }

      if (dataToSave.prospection) {
        formData.append('prospection', dataToSave.prospection)
      }

      if (dataToSave.activities) {
        formData.append('activities', JSON.stringify(dataToSave.activities))
      }

      if (data.stage || data.chatCenterDetails) {
        if (newContactFields) {
          if (dataToSave.name) formData.append('name', dataToSave.name)
          if (dataToSave.birthday)
            formData.append('birthday', dataToSave.birthday)
          if (dataToSave.phone) formData.append('phone', dataToSave.phone)
          if (dataToSave.email) formData.append('email', dataToSave.email)
          if (dataToSave.ddi) formData.append('ddi', dataToSave.ddi.value)
        } else {
          formData.append('customerId', dataToSave.customer.value)
        }

        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/card`,
          formData,
        )

        toast.success(successMessage)
      }

      if (data.details) {
        formData.append('id', data.details.id)

        await axiosApis.put(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/card`,
          formData,
        )
      }

      if (data.stage && dataToSave.nextAction && dataToSave.dateNextAction) {
        toast.success('Tarefa criada com sucesso')
      }

      await callback()
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  async function callbackOnDelete() {
    try {
      if (window.confirm('Deseja arquivar o contato?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/card/${data.details.id}`,
        )

        await callback()
      }
    } catch (error) {
      toast.info('Ocorreu um erro inesperado, por favor tente mais tarde')
    }
  }

  async function onChangeData(e) {
    setNewContactFields(!!e.newContact)
  }

  const redirectToWpp = phone => {
    window.open(
      `https://wa.me/55${phone
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', '')}`,
    )
  }

  const updateFiles = (files = []) => {
    setFiles(files)
  }

  return (
    <>
      <Container>
        <DefaultForm
          callbackSubmit={onSubmit}
          callbackDelete={data.details ? callbackOnDelete : undefined}
          otherButton={
            customer &&
            customer.phone && {
              label: agency?.enableChatCenter ? 'Chatcenter' : 'Whatsapp',
              disabled: customer?.phone ? true : false,
              onClick: () =>
                agency?.enableChatCenter
                  ? navigate('/chat')
                  : redirectToWpp(customer?.phone),
              name: agency?.enableChatCenter ? 'chatcenter' : 'whatsapp',
              style: {
                color: '#1CCB31',
                margin: '0 5px',
                fontSize: '14px',
              },
            }
          }
          callbackReset={() => {
            return
          }}
          onValidate={onValidate}
          defaultValue={defaultValue}
          onChangeData={onChangeData}
          deleteLabel={'Arquivar'}
          inputs={[
            {
              type: 'text',
              name: 'title',
              label: 'Título do card',
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    title: x.target.value,
                  }
                })
              },
              min: 3,
              max: 50,
              sm: 12,
            },
            {
              type: 'check',
              name: 'newContact',
              label: 'Novo cliente',
              hide: customer,
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    newContact: x.target.value,
                  }
                })
              },
              sm: 12,
              lg: 1,
              disabled: isWhatsapp && data?.chatCenterDetails?.customer,
            },
            !newContactFields && !customer
              ? {
                  type: 'customerSelect',
                  name: 'customer',
                  label: 'Cliente',
                  placeholder: 'Pesquise por um cliente',
                  required: true,
                  onChange: x => {
                    setDefaultData(oldState => {
                      return {
                        ...oldState,
                        files: files,
                        customer: x.target.value,
                      }
                    })
                  },
                  sm: 12,
                  lg: 11,
                  disabled: isWhatsapp && data?.chatCenterDetails?.customer,
                }
              : customer
              ? { sm: 2 }
              : {
                  type: 'text',
                  name: 'name',
                  label: 'Nome do Cliente',
                  maxLength: 200,
                  required: true,
                  onChange: x => {
                    setDefaultData(oldState => {
                      return {
                        ...oldState,
                        files: files,
                        name: x.target.value,
                      }
                    })
                  },
                  sm: 12,
                  lg: 3,
                },
            !newContactFields || customer
              ? { sm: 2 }
              : {
                  type: 'select',
                  name: 'ddi',
                  label: 'DDI',
                  options: ddi.current,
                  placeholder: 'Selecione...',
                  onChange: x => {
                    setSelectDdi(x.target.value)
                    setDefaultData(oldState => {
                      return {
                        ...oldState,
                        files: files,
                        ddi: x.target.value,
                      }
                    })
                  },
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
            !newContactFields || customer
              ? { sm: 2 }
              : selectDdi.value == '' || selectDdi.value == 55
              ? {
                  type: 'phone',
                  name: 'phone',
                  label: 'Telefone',
                  onChange: x => {
                    setDefaultData(oldState => {
                      return {
                        ...oldState,
                        files: files,
                        phone: x.target.value,
                      }
                    })
                  },
                  sm: 12,
                  lg: 2,
                }
              : {
                  type: 'text',
                  name: 'phone',
                  label: 'Telefone',
                  mask: '999 99999999',
                  onChange: x => {
                    setDefaultData(oldState => {
                      return {
                        ...oldState,
                        files: files,
                        phone: x.target.value,
                      }
                    })
                  },
                  sm: 12,
                  lg: 2,
                },
            !newContactFields || customer
              ? { sm: 2 }
              : {
                  type: 'email',
                  name: 'email',
                  label: 'Email',
                  maxLength: 200,
                  required: false,
                  onChange: x => {
                    setDefaultData(oldState => {
                      return {
                        ...oldState,
                        files: files,
                        email: x.target.value,
                      }
                    })
                  },
                  sm: 12,
                  lg: 2,
                },
            // !newContactFields || customer ? { sm: 2 } : { sm: 2 },
            !newContactFields || customer
              ? { sm: 2 }
              : {
                  type: 'date',
                  name: 'birthday',
                  label: 'Aniversário',
                  required: false,
                  onChange: x => {
                    setDefaultData(oldState => {
                      return {
                        ...oldState,
                        files: files,
                        birthday: x.target.value,
                      }
                    })
                  },
                  sm: 12,
                  lg: 2,
                },
            !newContactFields || customer ? { sm: 6 } : { sm: 6 },
            !newContactFields || customer ? { sm: 6 } : { sm: 6 },
            {
              type: 'check',
              name: 'prospection',
              label: 'Lead',
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    prospection: x.target.value,
                  }
                })
              },
              sm: 12,
              lg: 1,
            },
            {
              type: 'select',
              name: 'leadStatus',
              label: 'Status',
              options: leadStatusOptions,
              placeholder: 'Selecione...',
              required: true,
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    leadStatus: x.target.value,
                  }
                })
              },
              sm: 12,
              lg: 2,
            },
            {
              type: 'select',
              name: 'leadForm',
              label: 'Forma de Contato',
              options: leadFormOptions,
              placeholder: 'Selecione...',
              required: true,
              disabled: isWhatsapp ? true : false,
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    leadForm: x.target.value,
                  }
                })
              },
              sm: 12,
              lg: 2,
            },
            {
              type: 'date',
              name: 'contactDate',
              label: 'Data do Contato',
              required: true,
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    contactDate: x.target.value,
                  }
                })
              },
              sm: 12,
              lg: 2,
            },
            {
              type: 'time',
              name: 'contactTime',
              label: 'Hora do Contato',
              required: true,
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    contactTime: x.target.value,
                  }
                })
              },
              sm: 12,
              lg: 2,
            },
            {
              type: 'select',
              name: 'agent',
              label: 'Responsável',
              options: agentOptions,
              placeholder: 'Selecione...',
              required: true,
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    agent: x.target.value,
                  }
                })
              },
              sm: 12,
              lg: 3,
            },
            {
              type: 'text',
              name: 'nextAction',
              label: 'Próxima tarefa',
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    nextAction: x.target.value,
                  }
                })
              },
              sm: 12,
              lg: 4,
            },
            {
              type: 'date',
              name: 'dateNextAction',
              label: 'Data da próxima tarefa',
              onChange: x => {
                setDefaultData(oldState => {
                  return {
                    ...oldState,
                    files: files,
                    dateNextAction: x.target.value,
                  }
                })
              },
              sm: 12,
              lg: 2,
            },
            {
              type: 'select',
              name: 'motivations',
              label: 'Motivo',
              options: motivationOptions,
              placeholder: 'Selecione...',
              isMulti: true,
              onChange: motivation => {
                defaultValue.motivations = [...motivation.target.value]
              },
              sm: 12,
              lg: 2,
            },
            {
              type: 'select',
              name: 'observation',
              label: 'Observação',
              options: observationOptions,
              placeholder: 'Selecione...',
              onChange: observation => {
                const formattedObservation =
                  observation.target.value.value.replace(/\n/g, '<br>')
                setObs(formattedObservation)
                if (observation.target.value.label == 'Selecione...') {
                  const aux = desc
                  const regex = new RegExp(`<[a-z]>${obs}</[a-z]>`)

                  if (aux.match(regex) != null) {
                    const reverseDesc = aux.split('').reverse().join('')
                    const applyReplaceRegexInDescReversed = reverseDesc.replace(
                      aux.match(regex)[0].split('').reverse().join(''),
                      '',
                    )
                    const normalizeDesc = applyReplaceRegexInDescReversed
                      .split('')
                      .reverse()
                      .join('')

                    setDefaultData(oldState => {
                      return {
                        ...oldState,
                        files: files,
                        desc: normalizeDesc,
                      }
                    })
                  }
                } else {
                  setDefaultData(oldState => {
                    return {
                      ...oldState,
                      files: files,
                      desc: `${desc} \n\t\n ${formattedObservation}`,
                    }
                  })
                }
              },
              sm: 12,
              lg: 2,
            },
            {
              type: 'select',
              name: 'campaign',
              label: 'Campanha',
              options: campaignOptions,
              placeholder: 'Selecione...',
              onChange: campaign => {
                defaultValue.campaign = [campaign.target.value]
              },
              sm: 12,
              lg: 2,
            },
            {
              type: 'html',
              name: 'desc',
              label: 'Observações',
              maxLength: 2000,
              onChange: x => setDesc(x.target.value),
              sm: 6,
              lg: 6,
            },
            {
              type: 'tableToList',
              label: 'Histórico de Atividades',
              name: 'activities',
              sm: 6,
              lg: 6,
              nameButton: 'Nova Atividade',
              handle: () => {
                setShowModalNovaAtividade(true)
              },
              columns: [
                {
                  Header: 'Atividade',
                  accessor: 'description',
                },
                {
                  Header: 'Data/Hora',
                  accessor: 'activityDate',
                  width: 125,
                  Cell: props => {
                    if (props.row.original.activityDate) {
                      return getDateFormated(
                        props.row.original.activityDate,
                        'DD/MM/YYYY HH:mm:ss',
                      )
                    }

                    return ''
                  },
                },
                {
                  Header: 'Criador',
                  accessor: 'creator',
                  Cell: props => {
                    if (props.row.original.user) {
                      return props.row.original.user.fullName
                    }

                    return user?.fullName
                  },
                },
                {
                  Header: '',
                  accessor: 'remove',
                  width: 20,
                  Cell: props => {
                    return (
                      <DeleteButton
                        type='button'
                        className='fas fa-trash'
                        onClick={() => {
                          removeItens(
                            props.row.original,
                            activitiesList,
                            setActivitiesList,
                          )
                        }}
                      ></DeleteButton>
                    )
                  },
                },
              ],
            },
            {
              type: 'inputFileAsync',
              name: 'files',
              label: 'Anexos',
              onChange: x => updateFiles([...x.target.value]),
              // setDefaultData(oldState => {
              //   return {
              //     ...oldState,
              //     files: [ ...x.target.value],
              //   }
              // })
              max: 20,
              sm: 12,
              lg: isAdmin ? 12 : 6,
            },
          ]}
        />
      </Container>

      {showModalNovaAtividade && (
        <ModalContainer>
          <ModalContent>
            <ModalContentTitle>
              <div>
                <h6>Nova Atividade</h6>
              </div>

              <EditButton onClick={() => setShowModalNovaAtividade(false)}>
                <i className='fas fa-times' />
              </EditButton>
            </ModalContentTitle>

            <ModalContentBody>
              <DefaultForm
                callbackSubmit={onSubmitActivity}
                callbackReset={() => {
                  return
                }}
                inputs={[
                  {
                    type: 'text',
                    name: 'description',
                    label: 'Atividade',
                    required: true,
                    min: 3,
                    max: 50,
                    sm: 12,
                  },
                  {
                    type: 'date',
                    name: 'activityDate',
                    label: 'Data',
                    required: true,
                    sm: 12,
                    lg: 5,
                  },
                  {
                    type: 'time',
                    name: 'activityTime',
                    label: 'Hora',
                    required: true,
                    onChange: x => {
                      setDefaultData(oldState => {
                        return {
                          ...oldState,
                          files: files,
                          contactTime: x.target.value,
                        }
                      })
                    },
                    sm: 12,
                    lg: 5,
                  },
                ]}
              ></DefaultForm>
            </ModalContentBody>
          </ModalContent>
        </ModalContainer>
      )}

      {history.length > 0 && (
        <HistoryContainer>
          <p>
            <b>Histórico de Movimentação</b>
          </p>

          <div style={{ overflow: 'auto', maxHeight: '70vh' }}>
            {history.map((e, i) => (
              <p key={i} style={{ marginBottom: '24px' }}>
                <b>{e.author}</b>
                <span>&nbsp;{e.desc}</span>

                <br />

                <sub>
                  <b>{getDateTimeFormated(e.date)}</b> - há{' '}
                  {getDateDiffLabel(e.date, getMoment())}
                </sub>
              </p>
            ))}
          </div>
        </HistoryContainer>
      )}
    </>
  )
}

const HistoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 16px;
  font-size: 1.2em;
  opacity: 0.7;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;

  p {
    margin: 0;
  }
`
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1030;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  padding: 20vh 0px;
`

const ModalContent = styled.div`
  ${props => (props.width ? 'width: ' + props.width + ';' : '')}
  max-width: ${props => (props.width ? 'auto' : '80%')};
  min-width: ${props => (props.width ? 'auto' : '50%')};
  min-height: 20vh;
  background: #fff;
  border-radius: 6px 6px 4px 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    min-width: 90%;
  }
`

const ModalContentTitle = styled.div`
  width: 100%;
  background: #eaeaea;
  padding: 8px 8px 8px 16px;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h6 {
    margin: 0;
  }
`

const ModalContentBody = styled.div`
  flex: 1;
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
`
