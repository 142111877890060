import axios from 'axios'
import { APIS_BASE_URL } from '../envs'
import { getAuthStore } from '../store'

const getApiClient = () => {
  const auth = getAuthStore()

  const apiClient = axios.create({
    baseURL: APIS_BASE_URL,
    timeout: 40000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.accessToken}`,
    },
  })

  return apiClient
}

export { getApiClient }
