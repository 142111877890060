import styled from 'styled-components'
import PageTitle from '~/components/PageTitle'
import { SpaceBetweenDiv } from '~/styles/divs'
import { DeleteButton } from '~/components/Button/styles'

export default function Modal({ title, onClose, children }) {
  return (
    <Container>
      <Content>
        <Title>
          <PageTitle title={title} head={'h6'} noButton />

          <DeleteButton onClick={() => onClose(false)}>
            <i className='fas fa-times' />
          </DeleteButton>
        </Title>

        <div style={{ padding: '8px' }}>{children}</div>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 99;
  padding: 16px;
  background: rgba(0, 0, 0, 0.1);
`

const Content = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px #ccc;
`

const Title = styled(SpaceBetweenDiv)`
  padding: 8px;
  background: #eaeaea;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`
