import moment from 'moment'
import { useState } from 'react'
import Calendar from 'react-calendar'
import { getDateFormated } from '~/functions/Date'

import { Container } from './styles'

export default function CalendarDefault({
  renderDay = defaultRenderDay,
  view = 'month',
  locale = 'pt-BR',
  onClickDay = () => {},
  onChangeMonth = () => {},
}) {
  const [value, setValue] = useState(new Date())

  return (
    <Container>
      <Calendar
        value={value}
        onChange={setValue}
        locale={locale}
        view={view}
        onActiveStartDateChange={e => onChangeMonth(moment(e.activeStartDate))}
        onClickDay={e => onClickDay(moment(e))}
        formatDay={renderDay}
        tileDisabled={props =>
          getDateFormated(props.date, 'M') !==
          getDateFormated(props.activeStartDate, 'M')
        }
        navigationLabel={props => {
          return (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <span style={{ padding: '2px 4px' }}>
                {getDateFormated(props.date, 'MMM')}
              </span>

              <span style={{ padding: '2px 4px' }}>
                {getDateFormated(props.date, 'YYYY')}
              </span>
            </div>
          )
        }}
      />
    </Container>
  )
}

const defaultRenderDay = (_, day) => getDateFormated(day, 'D')
