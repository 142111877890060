import { useEffect, useState } from 'react'
import { getDateDiff, getMoment } from '~/functions/Date'
import ItineraryListItem from './ItineraryListItem'
const DATE_FORMAT = 'YYYY-MM-DD'

export default function ItineraryList({ travel, nameAgency }) {
  const [list, setList] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      let newList = []
      let date = getMoment(getMoment(travel.start, DATE_FORMAT))
      const totalDays = getDateDiff(travel.start, travel.end).days + 1

      for (let i = 0; i < totalDays; i++) {
        const roadMaps = travel.travelResumeItineraryRoadMaps.filter(
          x =>
            getMoment(x.start).format(DATE_FORMAT) === date.format(DATE_FORMAT),
        )

        const airs = travel.travelResumeAirs.filter(
          x =>
            getMoment(x.departure).format(DATE_FORMAT) ===
            date.format(DATE_FORMAT),
          //   ||
          // getMoment(x.arrival).format(DATE_FORMAT) ===
          //   date.format(DATE_FORMAT),
        )

        const hotels = travel.travelResumeHotels.filter(
          x =>
            getMoment(x.checkIn).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT) ||
            getMoment(x.checkOut).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT),
        )

        const cars = travel.travelResumeCars.filter(
          x =>
            getMoment(x.checkIn).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT) ||
            getMoment(x.checkOut).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT),
        )

        const generics = travel.travelResumeItems.filter(
          x =>
            getMoment(x.start).format(DATE_FORMAT) === date.format(DATE_FORMAT),
        )

        const trains = travel.travelResumeTrains.filter(
          x =>
            getMoment(x.departure).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT) ||
            getMoment(x.arrival).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT),
        )

        const nautical = travel.travelResumeNauticals.filter(
          x =>
            getMoment(x.departure).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT) ||
            getMoment(x.arrival).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT),
        )

        const vehicleRentals = travel.travelResumeVehicleRentals.filter(
          x =>
            getMoment(x.checkIn).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT) ||
            getMoment(x.checkOut).format(DATE_FORMAT) ===
              date.format(DATE_FORMAT),
        )

        const tickets = travel.travelResumeTickets.filter(
          x =>
            getMoment(x.start).format(DATE_FORMAT) === date.format(DATE_FORMAT),
        )

        if (
          roadMaps.length > 0 ||
          airs.length > 0 ||
          trains.length > 0 ||
          hotels.length > 0 ||
          cars.length > 0 ||
          nautical.length > 0 ||
          vehicleRentals.length > 0 ||
          tickets.length > 0 ||
          generics.length > 0
        ) {
          const listItem = {
            order: i + 1,
            date: getMoment(date),
            roadMaps,
            airs,
            hotels,
            cars,
            trains,
            generics,
            nautical,
            vehicleRentals,
            tickets,
          }

          newList.push(listItem)
        }

        date = getMoment(date.add(1, 'days'))
      }

      setList(newList)
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [travel])

  return (
    <div>
      {list.map((item, i) => {
        return (
          <ItineraryListItem
            key={i}
            index={i}
            item={item}
            dateFormat={DATE_FORMAT}
            showTitle={i === 0}
            nameAgency={nameAgency}
          />
        )
      })}
    </div>
  )
}
