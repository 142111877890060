import { useEffect, useState } from 'react'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import carIcon from '~/icons/car.png'
import hotelIcon from '~/icons/hotel.png'
import mapIcon from '~/icons/map.png'
import otherIcon from '~/icons/other.png'
import starIcon from '~/icons/star.png'
import walkingIcon from '~/icons/walking.png'

export default function MapDefaultFilters({
  filter,
  clean,
  getDates,
  hide,
  otherFilter,
}) {
  const [active, setActive] = useState()
  const [itemsLeft, setItemsLeft] = useState([
    { type: 'map', icon: mapIcon, label: 'Cidades', active: false },
    { type: 'star', icon: starIcon, label: 'Experiências', active: false },
    { type: 'hotel', icon: hotelIcon, label: 'Hospedagens', active: false },
    { type: 'car', icon: carIcon, label: 'Transfers/Locações', active: false },
    { type: 'other', icon: otherIcon, label: 'Outros Serviços', active: false },
    {
      type: 'walking',
      icon: walkingIcon,
      label: 'Atividades',
      active: false,
    },
  ])
  const [itemsTop, setItemsTop] = useState([])
  const [itemsTopValue, setItemsTopValue] = useState({
    day: { label: 'Todos', value: 'all' },
  })

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      const newDates = await getDates()

      const newItemsTop = []

      newItemsTop.push({
        label: 'Todos',
        value: 'all',
      })

      newDates.map(e => {
        newItemsTop.push({
          label: `${e.label} (${e.value})`,
          value: e.value,
        })
      })

      setItemsTop(newItemsTop)
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [getDates])

  async function onClickItem(x) {
    if (active === x.type) {
      const newItems = []

      itemsLeft.map(e => {
        newItems.push({
          ...e,
          active: false,
        })
      })

      setItemsLeft(newItems)

      setActive(undefined)

      clean()
    } else {
      const newItems = []

      itemsLeft.map(e => {
        newItems.push({
          ...e,
          active: x.type === e.type ? true : false,
        })
      })

      setItemsLeft(newItems)

      filter(x.type)

      setActive(x.type)
    }

    setItemsTopValue({
      day: { label: 'Todos', value: 'all' },
    })
  }

  async function onSubmitTopItem(e) {
    setItemsTopValue({
      day: e.target.value,
    })

    if (e.target.value.value === 'all') {
      clean()
    } else {
      otherFilter(e.target.value.value)
    }

    const newItems = []

    itemsLeft.map(e => {
      newItems.push({
        ...e,
        active: false,
      })
    })

    setItemsLeft(newItems)

    setActive(undefined)
  }

  return (
    <>
      {hide && (
        <>
          <ContainerLeft style={{ flexDirection: 'column' }}>
            <ItemsLeft>
              <ItemLeft style={{ cursor: 'default', fontWeight: 'bold' }}>
                Filtros
              </ItemLeft>

              {itemsLeft.map((x, i) => (
                <ItemLeft
                  key={i}
                  onClick={() => onClickItem(x)}
                  active={x.active}
                >
                  <img src={x.icon} alt={x.label} />
                </ItemLeft>
              ))}
            </ItemsLeft>
          </ContainerLeft>

          <ContainerTop>
            <ItemsTop>
              <ItemTop style={{ cursor: 'default', fontWeight: 'bold' }}>
                Dias
              </ItemTop>

              <li style={{ minWidth: '30%', maxWidth: '50%' }}>
                <div style={{ width: '100%' }}>
                  <DefaultForm
                    defaultValue={itemsTopValue}
                    inputs={[
                      {
                        name: 'day',
                        type: 'select',
                        options: itemsTop,
                        sm: 12,
                        onChange: onSubmitTopItem,
                      },
                    ]}
                  />
                </div>
              </li>
            </ItemsTop>
          </ContainerTop>
        </>
      )}
    </>
  )
}

const ContainerLeft = styled.div`
  height: 70%;
  max-height: 80%;
  position: absolute;
  left: 11px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  overflow: auto;
  scroll-snap-type: proximity;
`
const ContainerTop = styled.div`
  max-width: 80%;
  position: absolute;
  top: 11px;
  left: 0;
  right: 0;
  margin: 0 auto;
  scroll-snap-type: proximity;
`

const ItemsLeft = styled.ul`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
`
const ItemsTop = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  list-style: none;
`

const ItemLeft = styled.li`
  margin: 0 0 4px 0;
  padding: 8px;
  border-radius: 3px;
  background-color: ${props => (props.active ? '#7f2ec2' : '#fff')};
  box-shadow: 0 0 4px #aaa;
  cursor: pointer;

  img {
    width: 22px;
    height: 22px;
    filter: ${props => (props.active ? 'brightness(1)' : 'brightness(0.1)')};
  }
`

const ItemTop = styled.li`
  margin: 0 4px 0 0;
  padding: 8px;
  border-radius: 3px;
  background-color: ${props => (props.active ? '#7f2ec2' : '#fff')};
  box-shadow: 0 0 4px #aaa;
  cursor: pointer;

  img {
    width: 22px;
    height: 22px;
    filter: ${props => (props.active ? 'brightness(1)' : 'brightness(0.1)')};
  }
`
