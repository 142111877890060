import { phoneNumber } from '~/utils/supportPhone'
import {
  CoverHeader,
  Div,
  DivOfButtons,
  Footer,
  Header,
  ImageButton,
  SupportText,
} from './style'

export default function Support() {
  return (
    <Div>
      <div>
        <CoverHeader>
          <Header>
            <ImageButton
              onClick={() => window.open('https://www.tripmee.com.br/')}
            >
              <img
                src={'/images/logotripmee_parafundobranco.png'}
                alt='logo'
                style={{ maxWidth: '150px' }}
              />
            </ImageButton>

            <DivOfButtons>
              <button
                type='button'
                onClick={() => window.open('https://agente.tripmee.com.br/')}
              >
                Login
              </button>
              <button
                className='free-trial'
                type='button'
                onClick={() =>
                  window.open('https://agente.tripmee.com.br/free-trial')
                }
              >
                Teste por 10 dias
              </button>
            </DivOfButtons>
          </Header>
        </CoverHeader>
      </div>

      <SupportText>
        Precisa de ajuda?
        <p>
          Sempre que precisar, é só nos chamar no
          <a
            href={`https://api.whatsapp.com/send?phone=${phoneNumber(
              'support',
            )}`}
          >
            WhatsApp
          </a>
        </p>
      </SupportText>

      <Footer>
        <div>
          <img
            src={'/images/logotripmee_parafundobranco.png'}
            alt='logo'
            style={{ maxWidth: '150px' }}
          />

          <div>
            <span>
              <b>Contato</b> <br />
              contato@tripmee.com.br
            </span>
          </div>
        </div>

        <button
          type='button'
          onClick={() =>
            window.open(
              'https://instagram.com/tripmeeapp?igshid=MmU2YjMzNjRlOQ==',
            )
          }
        >
          <i className='fab fa-instagram'></i>
        </button>
      </Footer>
    </Div>
  )
}
