/* eslint-disable prettier/prettier */
import { useState } from 'react'
import { toast } from 'react-toastify'
import styledComponents from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import '../style.scss'
import { useParams } from 'react-router-dom'
import { getDateFormated } from '~/functions/Date'
import DivForHTML from '~/components/DivForHTML'

export default function PedagogicalForm({ data }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [sended, setSended] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [eventSource] = useState(data)
  const [paymentMethod, setPaymentMethod] = useState(false)
  const [installments, setInstallments] = useState(undefined)
  const [aluno2, setAluno2] = useState(false)
  const [aluno3, setAluno3] = useState(false)
  const [aluno4, setAluno4] = useState(false)
  const [aluno5, setAluno5] = useState(false)

  const params = useParams()

  document.title = 'Ficha de Inscrição'

  async function onSubmit(data) {
    try {
      setLoading(true)

      const studentGuardian = {
        fullName: data.nomeRF,
        firstName: 'firstName',
        lastName: 'lastName',
        email: data.email,
        phone: data.telefoneRF,
        businessPhone: data.telefoneComercialRF,
        profession: data.profissao,
        rg: data.rg,
        cpf: data.cpf,
        birth: data.dataDeNascimentoRF,
        address: data.enderecoRF,
        hood: 'hood',
        city: 'city',
        state: 'state',
        country: 'country',
        paymentMethod: data.formaDePagamento.value,
        installments:
          installments.value == 'A vista' ? '1' : data.numeroDeParcelas.value,
      }

      const students = [
        {
          fullName: data.nomeAluno,
          firstName: 'firstName',
          lastName: 'lastName',
          cpf: data.alunoCpf,
          rg: data.alunoRg,
          phone: data.telefoneAluno,
          email: data.emailAluno,
          birth: data.dataDeNascimento,
          fatherName: data.nomePai,
          fatherPhone: data.telefonePai,
          fatherEmail: 'fatherEmail',
          motherName: data.nomeMae,
          motherPhone: data.telefoneMae,
          motherEmail: 'motherEmail',
          emergencyName: data.nomeDoContatoDeEmergencia,
          emergencyPhone: data.emailDoContatoDeEmergencia,
          emergencyEmail: data.telefoneDoContatoDeEmergencia,
          grade: data.serie,
          class: data.turma,
          tshirtNumber: data.tamanhoDaBlusa.value,
          medicalAllergy: data.alergia,
          medicalMedication: data.medicacaoContinua,
          medicalDiseasePhobia: data.doencaOuNecessidadeEspecial,
          medicalFoodRestriction: data.restriçãoAlimentar,
          responsibleKinship: data.parentesco,
          weight: data.peso,
          shoeNumber: data.numeroDoPe,
          planaSub: data.planaSub ? data.planaSub.value : undefined,
          address: 'address',
          hood: 'hood',
          city: 'city',
          state: 'state',
          country: 'country',
        },
      ]

      if (aluno2) {
        students.push({
          fullName: data.nomeAluno2,
          firstName: 'firstName',
          lastName: 'lastName',
          cpf: data.alunoCpf2,
          rg: data.alunoRg2,
          phone: data.telefoneAluno2,
          email: data.emailAluno2,
          birth: data.dataDeNascimento2,
          fatherName: data.nomePai2,
          fatherPhone: data.telefonePai2,
          fatherEmail: 'fatherEmail',
          motherName: data.nomeMae2,
          motherPhone: data.telefoneMae2,
          motherEmail: 'motherEmail',
          emergencyName: data.nomeDoContatoDeEmergencia2,
          emergencyPhone: data.emailDoContatoDeEmergencia2,
          emergencyEmail: data.telefoneDoContatoDeEmergencia2,
          grade: data.serie2,
          class: data.turma2,
          tshirtNumber: data.tamanhoDaBlusa2.value,
          medicalAllergy: data.alergia2,
          medicalMedication: data.medicacaoContinua2,
          medicalDiseasePhobia: data.doencaOuNecessidadeEspecial2,
          medicalFoodRestriction: data.restriçãoAlimentar2,
          responsibleKinship: data.parentesco2,
          weight: data.peso2,
          shoeNumber: data.numeroDoPe2,
          planaSub: data.planaSub2 ? data.planaSub2.value : undefined,
          address: 'address',
          hood: 'hood',
          city: 'city',
          state: 'state',
          country: 'country',
        })
      }

      if (aluno3) {
        students.push({
          fullName: data.nomeAluno3,
          firstName: 'firstName',
          lastName: 'lastName',
          cpf: data.alunoCpf3,
          rg: data.alunoRg3,
          phone: data.telefoneAluno3,
          email: data.emailAluno3,
          birth: data.dataDeNascimento3,
          fatherName: data.nomePai3,
          fatherPhone: data.telefonePai3,
          fatherEmail: 'fatherEmail',
          motherName: data.nomeMae3,
          motherPhone: data.telefoneMae3,
          motherEmail: 'motherEmail',
          emergencyName: data.nomeDoContatoDeEmergencia3,
          emergencyPhone: data.emailDoContatoDeEmergencia3,
          emergencyEmail: data.telefoneDoContatoDeEmergencia3,
          grade: data.serie3,
          class: data.turma3,
          tshirtNumber: data.tamanhoDaBlusa3.value,
          medicalAllergy: data.alergia3,
          medicalMedication: data.medicacaoContinua3,
          medicalDiseasePhobia: data.doencaOuNecessidadeEspecial3,
          medicalFoodRestriction: data.restriçãoAlimentar3,
          responsibleKinship: data.parentesco3,
          weight: data.peso3,
          shoeNumber: data.numeroDoPe3,
          planaSub: data.planaSub3 ? data.planaSub3.value : undefined,
          address: 'address',
          hood: 'hood',
          city: 'city',
          state: 'state',
          country: 'country',
        })
      }

      if (aluno4) {
        students.push({
          fullName: data.nomeAluno4,
          firstName: 'firstName',
          lastName: 'lastName',
          cpf: data.alunoCpf4,
          rg: data.alunoRg4,
          phone: data.telefoneAluno4,
          email: data.emailAluno4,
          birth: data.dataDeNascimento4,
          fatherName: data.nomePai4,
          fatherPhone: data.telefonePai4,
          fatherEmail: 'fatherEmail',
          motherName: data.nomeMae4,
          motherPhone: data.telefoneMae4,
          motherEmail: 'motherEmail',
          emergencyName: data.nomeDoContatoDeEmergencia4,
          emergencyPhone: data.emailDoContatoDeEmergencia4,
          emergencyEmail: data.telefoneDoContatoDeEmergencia4,
          grade: data.serie4,
          class: data.turma4,
          tshirtNumber: data.tamanhoDaBlusa4.value,
          medicalAllergy: data.alergia4,
          medicalMedication: data.medicacaoContinua4,
          medicalDiseasePhobia: data.doencaOuNecessidadeEspecial4,
          medicalFoodRestriction: data.restriçãoAlimentar4,
          responsibleKinship: data.parentesco4,
          weight: data.peso4,
          shoeNumber: data.numeroDoPe4,
          planaSub: data.planaSub4 ? data.planaSub4.value : undefined,
          address: 'address',
          hood: 'hood',
          city: 'city',
          state: 'state',
          country: 'country',
        })
      }

      if (aluno5) {
        students.push({
          fullName: data.nomeAluno5,
          firstName: 'firstName',
          lastName: 'lastName',
          cpf: data.alunoCpf5,
          rg: data.alunoRg5,
          phone: data.telefoneAluno5,
          email: data.emailAluno5,
          birth: data.dataDeNascimento5,
          fatherName: data.nomePai5,
          fatherPhone: data.telefonePai5,
          fatherEmail: 'fatherEmail',
          motherName: data.nomeMae5,
          motherPhone: data.telefoneMae5,
          motherEmail: 'motherEmail',
          emergencyName: data.nomeDoContatoDeEmergencia5,
          emergencyPhone: data.emailDoContatoDeEmergencia5,
          emergencyEmail: data.telefoneDoContatoDeEmergencia5,
          grade: data.serie5,
          class: data.turma5,
          tshirtNumber: data.tamanhoDaBlusa5.value,
          medicalAllergy: data.alergia5,
          medicalMedication: data.medicacaoContinua5,
          medicalDiseasePhobia: data.doencaOuNecessidadeEspecial5,
          medicalFoodRestriction: data.restriçãoAlimentar5,
          responsibleKinship: data.parentesco5,
          weight: data.peso5,
          shoeNumber: data.numeroDoPe5,
          planaSub: data.planaSub5 ? data.planaSub5.value : undefined,
          address: 'address',
          hood: 'hood',
          city: 'city',
          state: 'state',
          country: 'country',
        })
      }

      const body = {
        token: params.token,
        responsible: studentGuardian,
        participants: students,
        // exception: data.error,
      }

      if (window.confirm('Você deseja enviar sua inscrição?')) {
        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/eventSubscription`,
          body,
        )

        toast.success('Inscrição enviada com sucesso!')

        setSended(true)
      }

      setLoading(false)
    } catch (error) {
      toast.info(
        'Não foi possível enviar sua inscrição. Tente Novamente em instantes.',
      )

      console.log(error)

      setLoading(false)
    }
  }

  const onValidate = data => {
    if (data.formaDePagamento == undefined) {
      toast.info('Por favor verifique o campo referente a Forma de Pagamento.')
    } else if (installments && data.numeroDeParcelas == undefined) {
      toast.info('Por favor verifique o campo referente ao Número de parcelas')
    } else if (data.tamanhoDaBlusa == undefined) {
      toast.info(
        'Por favor verifique o campo referente ao tamanho da blusa do(a) aluno(a)',
      )
    } else if (aluno2 && data.tamanhoDaBlusa2 == undefined) {
      toast.info(
        `Por favor verifique o campo referente ao tamanho da blusa do(a) aluno(a) ${2}`,
      )
    } else if (aluno3 && data.tamanhoDaBlusa3 == undefined) {
      toast.info(
        `Por favor verifique o campo referente ao tamanho da blusa do(a) aluno(a) ${
          aluno2 ? 3 : 2
        }`,
      )
    } else if (aluno4 && data.tamanhoDaBlusa4 == undefined) {
      toast.info(
        `Por favor verifique o campo referente ao tamanho da blusa do(a) aluno(a) ${
          aluno2 && aluno3 ? 4 : aluno2 || aluno3 ? 3 : 2
        }`,
      )
    } else if (aluno5 && data.tamanhoDaBlusa5 == undefined) {
      toast.info(
        `Por favor verifique o campo referente ao tamanho da blusa do(a) aluno(a) ${
          (aluno2 ? 1 : 0) + (aluno3 ? 1 : 0) + (aluno4 ? 1 : 0) + 2
        }`,
      )
    } else if (
      installments.value == 'A vista' &&
      data.numeroDeParcelas.value != '1'
    ) {
      toast.info(
        `O método de pagamento 'A vista' requer que a quantidade de parcelas seja igual a 1. Por favor verifique o campo referente ao número de parcelas`,
      )
    } else {
      onSubmit(data)
    }
  } // installments.value == 'A vista' ? '1' : data.numeroDeParcelas.value,

  const addNewAluno = () => {
    if (aluno2 == false) {
      setAluno2(true)
      return
    }
    if (aluno3 == false) {
      setAluno3(true)
      return
    }
    if (aluno4 == false) {
      setAluno4(true)
      return
    }
    if (aluno5 == false) {
      setAluno5(true)
      return
    }
  }

  const numberInstallments = () => {
    if (installments.value == 'A vista') {
      return [{ label: '1', value: '1' }]
    }

    if (installments.value == 'Boleto') {
      return [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
      ]
    }

    // if (installments.value == 'Cartão de Credito') {
    //   return [
    //     { label: '1', value: '1' },
    //     { label: '2', value: '2' },
    //     { label: '3', value: '3' },
    //     { label: '4', value: '4' },
    //     { label: '5', value: '5' },
    //     { label: '6', value: '6' },
    //   ]
    // }
  }

  const getNamesInstitutions = () => {
    return eventSource.institutions.map((value, i) => (
      <div key={i}>
        <span>{value.name}</span>
      </div>
    ))
  }

  const getImagesInstitutions = () => {
    return eventSource.institutions.map((value, i) => (
      <div key={i}>
        <img
          style={{ maxWidth: '100px' }}
          src={`data:image/*;base64,${value.image}`}
        />
      </div>
    ))
  }

  return (
    <Container style={{ padding: '50px' }}>
      {!cancel && !sended && eventSource && (
        <>
          <div className='titleImage'>
            <img src={`data:image/*;base64,${eventSource.agency.image}`} />

            <h1 className='page-header'>
              Ficha de Inscrição - Viagens Pedagógicas
            </h1>

            <div style={{ maxWidth: '100px' }}>{getImagesInstitutions()}</div>
          </div>

          <div className='titleImageMobile'>
            <div className='title'>
              <h1 className='page-header'>Ficha de Inscrição</h1>
              <h1 className='page-header'>Viagens Pedagógicas</h1>
            </div>

            <div>
              <img src={`data:image/*;base64,${eventSource.agency.image}`} />

              <div>{getImagesInstitutions()}</div>
            </div>
          </div>

          <Panel>
            <PanelHeader noButton background='#ccc' />

            <PanelBody style={{ padding: '15px' }}>
              <ul className='event-info'>
                <li className='event-info-field'>
                  <b>Viagem</b>

                  <span>{eventSource.name}</span>
                </li>

                <li className='event-info-field'>
                  <b>Instituição/Empresa</b>
                  {getNamesInstitutions()}
                </li>

                {eventSource.start && eventSource.end && (
                  <li className='event-info-field'>
                    <b>Período do evento</b>

                    <span>
                      <b>{getDateFormated(eventSource.start, 'll')}</b>
                      &nbsp;à&nbsp;
                      <b>{getDateFormated(eventSource.end, 'll')}</b>
                    </span>
                  </li>
                )}

                {eventSource.periodInfo &&
                  eventSource.periodInfo !== '<p><br></p>' && (
                    <li className='event-info-field'>
                      <b>Informações sobre datas, horários, grupos</b>

                      <DivForHTML text={eventSource.periodInfo} />
                    </li>
                  )}
              </ul>

              {!sended && (
                <DefaultForm
                  callbackSubmit={onSubmit}
                  onValidate={onValidate}
                  disabled={loading}
                  callbackCancel={() => {
                    if (window.confirm('Você deseja cancelar a inscrição?')) {
                      setCancel(true)
                    }
                  }}
                  submitLabel={'Enviar Inscrição'}
                  fontSize='16px'
                  backgroundColor='#7f2ec2'
                  color='#fff'
                  borderRadius='10px'
                  inputs={[
                    {
                      empty: true,
                      text: '<b>**ATENÇÃO: É de suma importância que nenhuma das informações solicitadas no formulário abaixo sejam preenchidas de forma abreviada.</b>',
                      sm: 12,
                      lg: 12,
                    },
                    {
                      empty: true,
                      text: '<b>Dados do Responsável Financeiro</b>',
                      sm: 12,
                      lg: 12,
                    },
                    {
                      type: 'text',
                      name: 'nomeRF',
                      label: 'Nome Completo',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'date',
                      name: 'dataDeNascimentoRF',
                      label: 'Data de Nascimento',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'telefoneRF',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'telefoneComercialRF',
                      label: 'Tel Comercial',
                      mask: '(99) 99999-9999',
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'email',
                      name: 'email',
                      label: 'E-mail',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'profissao',
                      label: 'Profissão',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'cpf',
                      label: 'CPF (somente números)',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'rg',
                      label: 'RG',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'enderecoRF',
                      label: 'Endereço Completo',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'select',
                      name: 'formaDePagamento',
                      label: 'Forma de Pagamento',
                      options: [
                        { label: 'A vista', value: 'A vista' },
                        {
                          label: 'Boleto',
                          value: 'Boleto',
                        },
                        // {
                        //   label: 'Cartão de Credito',
                        //   value: 'Cartão de Credito',
                        // },
                      ],
                      required: true,
                      onChange: e => {
                        setPaymentMethod(true)
                        setInstallments(e.target.value)
                      },
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    paymentMethod && {
                      type: 'select',
                      name: 'numeroDeParcelas',
                      label: 'Número de Parcelas',
                      options: numberInstallments(),
                      required: paymentMethod ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      empty: true,
                      text: '<div style="margin: 8px 0px; width: 100%; border-bottom: 1px solid #555555; height: 1px;"></div>',
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: '<b>Aluno(a)</b>',
                      sm: 12,
                    },
                    {
                      empty: true,
                      text: '<span>Dados do aluno(a)</span>',
                      sm: 12,
                      lg: 12,
                    },
                    {
                      type: 'text',
                      name: 'nomeAluno',
                      label: 'Nome completo',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'text',
                      name: 'alunoCpf',
                      label: 'CPF (somente números)',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'alunoRg',
                      label: 'RG',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'telefoneAluno',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'email',
                      name: 'emailAluno',
                      label: 'E-mail',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'serie',
                      label: 'Série',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'turma',
                      label: 'Turma',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'nomePai',
                      label: 'Nome do Pai',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'telefonePai',
                      label: 'Telefone do Pai',
                      mask: '(99) 99999-9999',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'nomeMae',
                      label: 'Nome da Mãe',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'telefoneMae',
                      label: 'Telefone da Mãe',
                      mask: '(99) 99999-9999',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'parentesco',
                      label: 'Parentesco com o Responsável Financeiro',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'date',
                      name: 'dataDeNascimento',
                      label: 'Data de Nascimento',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'select',
                      name: 'tamanhoDaBlusa',
                      label: 'Tamanho da blusa (Infantil)',
                      options: [
                        { label: 'P (58 x 74 cm)', value: 'P' },
                        { label: 'M (59.5 x 75.5 cm)', value: 'M' },
                        { label: 'G (62 x 77 cm)', value: 'G' },
                      ],
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'number',
                      name: 'peso',
                      label: 'Peso (em quilos)',
                      required: true,
                      min: 0,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'numeroDoPe',
                      label: 'Número do Pé',
                      required: true,
                      mask: '99',
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    eventSource.agency.name === 'Wee Travel' && {
                      type: 'select',
                      name: 'planaSub',
                      label:
                        'O(a) aluno(a) irá participar da experiência do PlanaSub? Valor adicional R$ 375,00 por pessoa.',
                      required: true,
                      options: [
                        { label: 'Sim', value: 'Sim' },
                        { label: 'Não', value: 'Não' },
                      ],
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      empty: true,
                      text: '<span>Contato de Emergência</sp>',
                      sm: 12,
                      lg: 12,
                    },
                    {
                      type: 'text',
                      name: 'nomeDoContatoDeEmergencia',
                      label: 'Nome completo',
                      required: true,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'email',
                      name: 'emailDoContatoDeEmergencia',
                      label: 'E-mail',
                      required: true,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      type: 'text',
                      name: 'telefoneDoContatoDeEmergencia',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    {
                      empty: true,
                      text: '<span>Informações Médicas</sp>',
                      sm: 12,
                      lg: 12,
                    },
                    {
                      type: 'text',
                      name: 'alergia',
                      label:
                        'É portador de alguma alergia? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'text',
                      name: 'doencaOuNecessidadeEspecial',
                      label:
                        'É portador de doença crônica/fobia ou necessidade especial? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'text',
                      name: 'restriçãoAlimentar',
                      label:
                        'Possui alguma restrição alimentar ou algo que não aprecie? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'text',
                      name: 'medicacaoContinua',
                      label:
                        'Utiliza algum medicamento de forma contínua? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno2 && {
                      empty: true,
                      text: '<div style="margin: 8px 0px; width: 100%; border-bottom: 1px solid #555555; height: 1px;"></div>',
                      sm: 12,
                    },
                    aluno2 && {
                      empty: true,
                      text: `<b>Aluno(a) ${2}</b>`,
                      sm: 12,
                      lg: 12,
                    },
                    aluno2 && {
                      type: 'button',
                      onClick: () => setAluno2(false),
                      label: 'Remover aluno(a)',
                      style: {
                        color: 'red',
                        fontSize: '14px',
                        padding: '0',
                        backgroundColor: 'transparent',
                      },
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno2 && {
                      empty: true,
                      text: '<span>Dados do aluno(a)</span>',
                      sm: 12,
                      lg: 12,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'nomeAluno2',
                      label: 'Nome completo',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'alunoCpf2',
                      label: 'CPF (somente números)',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'alunoRg2',
                      label: 'RG',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'telefoneAluno2',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: aluno2 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'email',
                      name: 'emailAluno2',
                      label: 'E-mail',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'date',
                      name: 'dataDeNascimento2',
                      label: 'Data De Nascimento',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'serie2',
                      label: 'Série',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'turma2',
                      label: 'Turma',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'nomePai2',
                      label: 'Nome do Pai',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'telefonePai2',
                      label: 'Telefone do Pai',
                      mask: '(99) 99999-9999',
                      required: aluno2 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'nomeMae2',
                      label: 'Nome da Mãe',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'telefoneMae2',
                      label: 'Telefone da Mãe',
                      mask: '(99) 99999-9999',
                      required: aluno2 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'parentesco2',
                      label: 'Parentesco com o Responsável Financeiro',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'select',
                      name: 'tamanhoDaBlusa2',
                      label: 'Tamanho da blusa (Infantil)',
                      options: [
                        { label: 'P (58 x 74 cm)', value: 'P' },
                        { label: 'M (59.5 x 75.5 cm)', value: 'M' },
                        { label: 'G (62 x 77 cm)', value: 'G' },
                      ],
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'number',
                      name: 'peso2',
                      label: 'Peso (em quilos)',
                      required: aluno2 ? true : false,
                      min: 0,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'numeroDoPe2',
                      label: 'Número do Pé',
                      required: aluno2 ? true : false,
                      mask: '99',
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 &&
                      eventSource.agency.name === 'Wee Travel' && {
                        type: 'select',
                        name: 'planaSub2',
                        label:
                          'O(a) aluno(a) irá participar da experiência do PlanaSub? Valor adicional R$ 375,00 por pessoa.',
                        required: true,
                        options: [
                          { label: 'Sim', value: 'Sim' },
                          { label: 'Não', value: 'Não' },
                        ],
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                      },
                    aluno2 && {
                      empty: true,
                      text: '<span>Contato de Emergência</span>',
                      sm: 12,
                      lg: 12,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'nomeDoContatoDeEmergencia2',
                      label: 'Nome completo',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno2 && {
                      type: 'email',
                      name: 'emailDoContatoDeEmergencia2',
                      label: 'E-mail',
                      required: aluno2 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'telefoneDoContatoDeEmergencia2',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: aluno2 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno2 && {
                      empty: true,
                      text: '<span>Informações Médicas</span>',
                      sm: 12,
                      lg: 12,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'alergia2',
                      label:
                        'É portador de alguma alergia? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'doencaOuNecessidadeEspecial2',
                      label:
                        'É portador de doença crônica/fobia ou necessidade especial? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'restriçãoAlimentar2',
                      label:
                        'Possui alguma restrição alimentar ou algo que não aprecie? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno2 && {
                      type: 'text',
                      name: 'medicacaoContinua2',
                      label:
                        'Utiliza algum medicamento de forma contínua? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno3 && {
                      empty: true,
                      text: '<div style="margin: 8px 0px; width: 100%; border-bottom: 1px solid #555555; height: 1px;"></div>',
                      sm: 12,
                    },
                    aluno3 && {
                      empty: true,
                      text: `<b>Aluno(a) ${aluno2 ? 3 : 2}</b>`,
                      sm: 12,
                    },
                    aluno3 && {
                      type: 'button',
                      onClick: () => setAluno3(false),
                      label: 'Remover aluno(a)',
                      style: {
                        color: 'red',
                        fontSize: '14px',
                        padding: '0',
                        backgroundColor: 'transparent',
                      },
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'nomeAluno3',
                      label: 'Nome completo',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'alunoCpf3',
                      label: 'CPF (somente números)',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'alunoRg3',
                      label: 'RG',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'telefoneAluno3',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: aluno3 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'email',
                      name: 'emailAluno3',
                      label: 'E-mail',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'date',
                      name: 'dataDeNascimento3',
                      label: 'Data de Nascimento',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'serie3',
                      label: 'Série',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'turma3',
                      label: 'Turma',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'nomePai3',
                      label: 'Nome do Pai',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'telefonePai3',
                      label: 'Telefone do Pai',
                      mask: '(99) 99999-9999',
                      required: aluno3 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'nomeMae3',
                      label: 'Nome da Mãe',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'telefoneMae3',
                      label: 'Telefone da Mãe',
                      mask: '(99) 99999-9999',
                      required: aluno3 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'parentesco3',
                      label: 'Parentesco com o Responsável Financeiro',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'select',
                      name: 'tamanhoDaBlusa3',
                      label: 'Tamanho da blusa (Infantil)',
                      options: [
                        { label: 'P (58 x 74 cm)', value: 'P' },
                        { label: 'M (59.5 x 75.5 cm)', value: 'M' },
                        { label: 'G (62 x 77 cm)', value: 'G' },
                      ],
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'number',
                      name: 'peso3',
                      label: 'Peso (em quilos)',
                      required: aluno3 ? true : false,
                      min: 0,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'numeroDoPe3',
                      label: 'Número do Pé',
                      required: aluno3 ? true : false,
                      mask: '99',
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 &&
                      eventSource.agency.name === 'Wee Travel' && {
                        type: 'select',
                        name: 'planaSub3',
                        label:
                          'O(a) aluno(a) irá participar da experiência do PlanaSub? Valor adicional R$ 375,00 por pessoa.',
                        required: true,
                        options: [
                          { label: 'Sim', value: 'Sim' },
                          { label: 'Não', value: 'Não' },
                        ],
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                      },
                    aluno3 && {
                      empty: true,
                      text: '<span>Contato de Emergência</span>',
                      sm: 12,
                      lg: 12,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'nomeDoContatoDeEmergencia3',
                      label: 'Nome completo',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno3 && {
                      type: 'email',
                      name: 'emailDoContatoDeEmergencia3',
                      label: 'E-mail',
                      required: aluno3 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'telefoneDoContatoDeEmergencia3',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: aluno3 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno3 && {
                      empty: true,
                      text: '<span>Informações Médicas</span>',
                      sm: 12,
                      lg: 12,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'alergia3',
                      label:
                        'É portador de alguma alergia? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'doencaOuNecessidadeEspecial3',
                      label:
                        'É portador de doença crônica/fobia ou necessidade especial? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'restriçãoAlimentar3',
                      label:
                        'Possui alguma restrição alimentar ou algo que não aprecie? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno3 && {
                      type: 'text',
                      name: 'medicacaoContinua3',
                      label:
                        'Utiliza algum medicamento de forma contínua? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno4 && {
                      empty: true,
                      text: '<div style="margin: 8px 0px; width: 100%; border-bottom: 1px solid #555555; height: 1px;"></div>',
                      sm: 12,
                    },
                    aluno4 && {
                      empty: true,
                      text: `<b>Aluno(a) ${
                        aluno2 && aluno3 ? '4' : aluno2 || aluno3 ? '3' : '2'
                      }</b>`,
                      sm: 12,
                    },
                    aluno4 && {
                      type: 'button',
                      onClick: () => setAluno4(false),
                      label: 'Remover aluno(a)',
                      style: {
                        color: 'red',
                        fontSize: '14px',
                        padding: '0',
                        backgroundColor: 'transparent',
                      },
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'nomeAluno4',
                      label: 'Nome completo',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'alunoCpf4',
                      label: 'CPF (somente números)',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'alunoRg4',
                      label: 'RG',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'telefoneAluno4',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: aluno4 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'email',
                      name: 'emailAluno4',
                      label: 'E-mail',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'date',
                      name: 'dataDeNascimento4',
                      label: 'Data de Nascimento',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'serie4',
                      label: 'Série',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'turma4',
                      label: 'Turma',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'nomePai4',
                      label: 'Nome do Pai',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'telefonePai4',
                      label: 'Telefone do Pai',
                      mask: '(99) 99999-9999',
                      required: aluno4 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'nomeMae4',
                      label: 'Nome da Mãe',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'telefoneMae4',
                      label: 'Telefone da Mãe',
                      mask: '(99) 99999-9999',
                      required: aluno4 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'parentesco4',
                      label: 'Parentesco com o Responsável Financeiro',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'select',
                      name: 'tamanhoDaBlusa4',
                      label: 'Tamanho da blusa (Infantil)',
                      options: [
                        { label: 'P (58 x 74 cm)', value: 'P' },
                        { label: 'M (59.5 x 75.5 cm)', value: 'M' },
                        { label: 'G (62 x 77 cm)', value: 'G' },
                      ],
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'number',
                      name: 'peso4',
                      label: 'Peso (em quilos)',
                      required: aluno4 ? true : false,
                      min: 0,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'numeroDoPe4',
                      label: 'Número do Pé',
                      required: aluno4 ? true : false,
                      mask: '99',
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 &&
                      eventSource.agency.name === 'Wee Travel' && {
                        type: 'select',
                        name: 'planaSub4',
                        label:
                          'O(a) aluno(a) irá participar da experiência do PlanaSub? Valor adicional R$ 375,00 por pessoa.',
                        required: true,
                        options: [
                          { label: 'Sim', value: 'Sim' },
                          { label: 'Não', value: 'Não' },
                        ],
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                      },
                    aluno4 && {
                      empty: true,
                      text: '<span>Contato de Emergência</span>',
                      sm: 12,
                      lg: 12,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'nomeDoContatoDeEmergencia4',
                      label: 'Nome completo',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno4 && {
                      type: 'email',
                      name: 'emailDoContatoDeEmergencia4',
                      label: 'E-mail',
                      required: aluno4 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'telefoneDoContatoDeEmergencia4',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: aluno4 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno4 && {
                      empty: true,
                      text: '<span>Informações Médicas</span>',
                      sm: 12,
                      lg: 12,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'alergia4',
                      label:
                        'É portador de alguma alergia? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'doencaOuNecessidadeEspecial4',
                      label:
                        'É portador de doença crônica/fobia ou necessidade especial? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'restriçãoAlimentar4',
                      label:
                        'Possui alguma restrição alimentar ou algo que não aprecie? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno4 && {
                      type: 'text',
                      name: 'medicacaoContinua4',
                      label:
                        'Utiliza algum medicamento de forma contínua? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno5 && {
                      empty: true,
                      text: '<div style="margin: 8px 0px; width: 100%; border-bottom: 1px solid #555555; height: 1px;"></div>',
                      sm: 12,
                    },
                    aluno5 && {
                      empty: true,
                      text: `<b>Aluno(a) ${
                        (aluno2 ? 1 : 0) +
                        (aluno3 ? 1 : 0) +
                        (aluno4 ? 1 : 0) +
                        2
                      }</b>`,
                      sm: 12,
                    },
                    aluno5 && {
                      type: 'button',
                      onClick: () => setAluno5(false),
                      label: 'Remover aluno(a)',
                      style: {
                        color: 'red',
                        fontSize: '14px',
                        padding: '0',
                        backgroundColor: 'transparent',
                      },
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'nomeAluno5',
                      label: 'Nome completo',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'alunoCpf5',
                      label: 'CPF (somente números)',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'alunoRg5',
                      label: 'RG',
                      required: true,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'telefoneAluno5',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: aluno5 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'email',
                      name: 'emailAluno5',
                      label: 'E-mail',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'date',
                      name: 'dataDeNascimento5',
                      label: 'Data de Nascimento',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'serie5',
                      label: 'Série',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'turma5',
                      label: 'Turma',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'nomePai5',
                      label: 'Nome do Pai',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'telefonePai5',
                      label: 'Telefone do Pai',
                      mask: '(99) 99999-9999',
                      required: aluno5 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'nomeMae5',
                      label: 'Nome da Mãe',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'telefoneMae5',
                      label: 'Telefone da Mãe',
                      mask: '(99) 99999-9999',
                      required: aluno5 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'parentesco5',
                      label: 'Parentesco com o Responsável Financeiro',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'select',
                      name: 'tamanhoDaBlusa5',
                      label: 'Tamanho da blusa (Infantil)',
                      options: [
                        { label: 'P (58 x 74 cm)', value: 'P' },
                        { label: 'M (59.5 x 75.5 cm)', value: 'M' },
                        { label: 'G (62 x 77 cm)', value: 'G' },
                      ],
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'number',
                      name: 'peso5',
                      label: 'Peso (em quilos)',
                      required: aluno5 ? true : false,
                      min: 0,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'numeroDoPe5',
                      label: 'Número do Pé',
                      required: aluno5 ? true : false,
                      mask: '99',
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 &&
                      eventSource.agency.name === 'Wee Travel' && {
                        type: 'select',
                        name: 'planaSub5',
                        label:
                          'O(a) aluno(a) irá participar da experiência do PlanaSub? Valor adicional R$ 375,00 por pessoa.',
                        required: true,
                        options: [
                          { label: 'Sim', value: 'Sim' },
                          { label: 'Não', value: 'Não' },
                        ],
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                      },
                    aluno5 && {
                      empty: true,
                      text: '<span>Contato de Emergência</span>',
                      sm: 12,
                      lg: 12,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'nomeDoContatoDeEmergencia5',
                      label: 'Nome completo',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno5 && {
                      type: 'email',
                      name: 'emailDoContatoDeEmergencia5',
                      label: 'E-mail',
                      required: aluno5 ? true : false,
                      maxLength: 100,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'telefoneDoContatoDeEmergencia5',
                      label: 'Telefone',
                      mask: '(99) 99999-9999',
                      required: aluno5 ? true : false,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      xl: 6,
                    },
                    aluno5 && {
                      empty: true,
                      text: '<span>Informações Médicas</span>',
                      sm: 12,
                      lg: 12,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'alergia5',
                      label:
                        'É portador de alguma alergia? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'doencaOuNecessidadeEspecial5',
                      label:
                        'É portador de doença crônica/fobia ou necessidade especial? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'restriçãoAlimentar5',
                      label:
                        'Possui alguma restrição alimentar ou algo que não aprecie? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    aluno5 && {
                      type: 'text',
                      name: 'medicacaoContinua5',
                      label:
                        'Utiliza algum medicamento de forma contínua? Se sim, qual? E qual o procedimento a ser adotado?',
                      required: false,
                      maxLength: 100,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      type: 'button',
                      onClick: () => addNewAluno(),
                      label: '+ Adicionar novo(a) aluno(a)',
                      style: {
                        color: `${
                          aluno2 && aluno3 && aluno4 && aluno5
                            ? '#cccccc'
                            : '#7f2ec2'
                        }`,
                        backgroundColor: `${
                          aluno2 && aluno3 && aluno4 && aluno5
                            ? 'transparent'
                            : null
                        }`,
                        borderRadius: '8px',
                        fontSize: '14px',
                        padding: '10px',
                      },
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    {
                      empty: true,
                      text: `${eventSource.desc ? eventSource.desc : ''}`,
                      sm: 12,
                      lg: 12,
                    },
                  ]}
                />
              )}
            </PanelBody>
          </Panel>
        </>
      )}

      {!loading && (
        <>
          {sended && (
            <p style={{ textAlign: 'center' }}>
              <b>Sua inscrição foi enviada. Você já pode fechar esta página.</b>
            </p>
          )}

          {cancel && (
            <p style={{ textAlign: 'center' }}>
              <b>Você já pode fechar essa página!</b>
            </p>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styledComponents.div`

`
