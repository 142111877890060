import PageTitle from '~/components/PageTitle'
import { getDateFormated } from '~/functions/Date'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import { DefaultLi, DefaultLiChilds, DefaultLiChildsItem } from '~/styles/ul'
import { SpaceBetweenDiv, DivFlexRight } from '~/styles/divs'
import { EditButton, DeleteButton } from '~/components/Button/styles'
// import DivForHTML from '~/components/DivForHTML'
import { getIconClassName } from '~/icons'

export default function Day({
  item,
  index,
  menuOpenned,
  setMenuOpenned,
  add,
  details,
  remove,
}) {
  const id = `daybyday-day-${index}`
  const drappableId = `daybyday-droppable-${index}-${item.id}`
  const buttonsIds = [
    { type: 'activity', label: 'Atividade' },
    { type: 'air', label: 'Aéreo' },
    { type: 'train', label: 'Trem' },
    { type: 'nautical', label: 'Náutico' },
    { type: 'hotel', label: 'Hotel' },
    { type: 'car', label: 'Transfer' },
    { type: 'vehicleRental', label: 'Locação veicular' },
    { type: 'ticket', label: 'Ingresso' },
    { type: 'other', label: 'Outros serviços' },
  ]
  let closeMenuTimeout

  const toggleMenu = () => {
    if (id == menuOpenned) {
      setMenuOpenned(undefined)
    } else {
      setMenuOpenned(id)
    }
  }

  const handleMenu = e => {
    if (e) {
      clearTimeout(closeMenuTimeout)
    } else {
      closeMenuTimeout = setTimeout(() => {
        setMenuOpenned(undefined)
      }, 1000)
    }
  }

  async function onAdd(e) {
    add({
      date: item.date,
      type: e.type,
    })
  }

  async function onDetails(e) {
    details({
      date: item.date,
      type: e.type,
      entity: e.entity,
    })
  }

  return (
    <DefaultLi style={{ width: '100%' }}>
      <SpaceBetweenDiv style={{ marginBottom: '8px' }}>
        <PageTitle title={item.title} head={'h6'} noButton />

        <EditButton id={id}>
          <i className='fas fa-ellipsis-v' />

          <Popover
            placement='left'
            target={id}
            isOpen={menuOpenned === id}
            toggle={toggleMenu}
          >
            <PopoverHeader>Adicionar</PopoverHeader>

            <PopoverBody style={{ padding: '8px' }}>
              {buttonsIds.map((e, i) => (
                <EditButton
                  key={i}
                  onMouseEnter={() => handleMenu(true)}
                  onMouseOut={() => handleMenu(false)}
                  style={{
                    position: 'relative',
                    margin: 0,
                    minWidth: '180px',
                    alignItems: 'center',
                    paddingLeft: '22px',
                  }}
                  onClick={() => onAdd({ type: e.type })}
                >
                  <i
                    className={getIconClassName(e.type)}
                    style={{ position: 'absolute', left: '4px' }}
                  />
                  &nbsp;
                  {e.label}
                </EditButton>
              ))}
            </PopoverBody>
          </Popover>
        </EditButton>
      </SpaceBetweenDiv>

      <Droppable droppableId={drappableId} direction='horizontal'>
        {provided => (
          <DefaultLiChilds
            ref={provided.innerRef}
            style={{
              minWidth: '50vh',
              minHeight: '120px',
            }}
          >
            {item.childs.map((x, y) => {
              const iconClassName = getIconClassName(x.type)
              const draggableId = `${x.type}-draggable-${x.id}`

              return (
                <Draggable key={y} index={y} draggableId={draggableId}>
                  {provided => (
                    <DefaultLiChildsItem
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div>
                        <SpaceBetweenDiv
                          style={{ marginBottom: '8px', fontSize: '16px' }}
                        >
                          <b>
                            <i className={iconClassName} />

                            {x.type === 'hotel' &&
                              x.desc &&
                              ` ${getDateFormated(x.start, 'HH:mm')} - ${
                                x.desc.length <= 15
                                  ? x.desc
                                  : `${x.desc.substring(0, 15).trim()}...`
                              }`}

                            {x.type === 'other' &&
                              x.desc &&
                              ((getDateFormated(x.start, 'HH:mm') != '00:00' &&
                                getDateFormated(x.end, 'HH:mm') != '00:00') ||
                                (getDateFormated(x.start, 'HH:mm') == '00:00' &&
                                  getDateFormated(x.end, 'HH:mm') != '00:00') ||
                                (getDateFormated(x.start, 'HH:mm') != '00:00' &&
                                  getDateFormated(x.end, 'HH:mm') ==
                                    '00:00')) &&
                              ` ${getDateFormated(x.start, 'HH:mm')} - ${
                                x.desc.length <= 15
                                  ? x.desc
                                  : `${x.desc.substring(0, 15).trim()}...`
                              }`}

                            {x.type === 'other' &&
                              x.desc &&
                              getDateFormated(x.start, 'HH:mm') == '00:00' &&
                              getDateFormated(x.end, 'HH:mm') == '00:00' &&
                              ` ${
                                x.desc.length <= 15
                                  ? x.desc
                                  : `${x.desc.substring(0, 15).trim()}...`
                              }`}

                            {x.type === 'ticket' &&
                              ` ${
                                getDateFormated(x.start, 'HH:mm') == '00:00'
                                  ? 'Horário livre'
                                  : getDateFormated(x.start, 'HH:mm')
                              }  - ${x.title}`}

                            {x.type === 'air' &&
                              x.desc &&
                              ` ${x.desc
                                .replaceAll('()', '')
                                .replace('-', '⇝')}`}

                            {/* {x.type === 'train' &&
                            ` ${x.desc
                              .replaceAll('()', '')
                              .replace('-', '⇝')}` + console.log(x)} */}

                            {x.type === 'train' &&
                              x.startAddress &&
                              x.endAddress &&
                              ` ${
                                x.startAddress.length <= 13
                                  ? x.startAddress
                                  : `${x.startAddress
                                      .substring(0, 12)
                                      .trim()}...`
                              } ⇝ ${
                                x.endAddress.length <= 13
                                  ? x.endAddress
                                  : `${x.endAddress.substring(0, 12).trim()}...`
                              }`}

                            {x.type === 'nautical' &&
                              x.desc &&
                              ` ${x.desc
                                .replaceAll('()', '')
                                .replace('-', '⇝')}`}

                            {x.type === 'car' &&
                              x.originAddress &&
                              x.destinyAddress &&
                              ` ${
                                x.originAddress.length <= 13
                                  ? x.originAddress
                                  : `${x.originAddress
                                      .substring(0, 12)
                                      .trim()}...`
                              } ⇝ ${
                                x.destinyAddress.length <= 13
                                  ? x.destinyAddress
                                  : `${x.destinyAddress
                                      .substring(0, 12)
                                      .trim()}...`
                              }`}

                            {x.type === 'vehicleRental' && ` ${x.desc}`}

                            <span>
                              {x.type === 'activity' && (x.start || x.end) && (
                                <span>
                                  {x.start === x.end && (
                                    <span>
                                      {x.turn && ` ${x.turn}`}
                                      {!x.turn &&
                                        x.start &&
                                        ` ${getDateFormated(x.start, 'HH:mm')}`}
                                    </span>
                                  )}
                                  {x.start !== x.end &&
                                    !x.turn &&
                                    ` ${getDateFormated(
                                      x.start,
                                      'HH:mm',
                                    )} ⇝ ${getDateFormated(x.end, 'HH:mm')}`}
                                  {x.start !== x.end && x.turn && ` ${x.turn}`}
                                </span>
                              )}
                              {x.type === 'activity' && !x.start && !x.end && (
                                <span>{` ${x.turn} `}</span>
                              )}
                            </span>
                          </b>

                          <DeleteButton
                            onClick={() => remove({ type: x.type, id: x.id })}
                          >
                            <i className='fas fa-times' />
                          </DeleteButton>
                        </SpaceBetweenDiv>

                        {/* <DivForHTML text={`${x.desc.substring(0, 100)}`} /> */}
                        {x.type === 'air' && (
                          <>
                            <p>
                              Embarque:{' '}
                              {getDateFormated(x.start, 'HH:mm DD/MM/yyyy')}
                            </p>
                            <p>
                              Desembarque:{' '}
                              {getDateFormated(x.end, 'HH:mm DD/MM/yyyy')}
                            </p>
                          </>
                        )}

                        {x.type === 'hotel' && x.end != '0001-01-01T00:00:00' && (
                          <>
                            <p>
                              Check-in:{' '}
                              {getDateFormated(x.start, 'HH:mm DD/MM/yyyy')}
                            </p>
                            <p>
                              Check-out:{' '}
                              {getDateFormated(x.end, 'HH:mm DD/MM/yyyy')}
                            </p>
                          </>
                        )}

                        {x.type === 'activity' && (
                          <p style={{ overflowWrap: 'break-word' }}>
                            {x.desc
                              .replaceAll(/(<([^>]+)>)/gi, '')
                              .replaceAll(/<.|</g, '')
                              .replaceAll(/&nbsp;/g, '')
                              .substring(0, 100)
                              .replaceAll('/', '')}
                          </p>
                        )}

                        {x.type === 'ticket' && (
                          <div>
                            {x.desc && (
                              <p style={{ overflowWrap: 'break-word' }}>
                                {x.desc
                                  .replaceAll(/(<([^>]+)>)/gi, '')
                                  .replaceAll(/<.|</g, '')
                                  .replaceAll(/&nbsp;/g, '')
                                  .substring(0, 100)
                                  .replaceAll('/', '')}
                              </p>
                            )}
                          </div>
                        )}

                        {x.type === 'car' && (
                          <>
                            <p>
                              Partida:{' '}
                              {getDateFormated(x.start, 'HH:mm DD/MM/yyyy')}
                            </p>
                            <p>
                              Chegada:{' '}
                              {getDateFormated(x.end, 'HH:mm DD/MM/yyyy')}
                            </p>
                          </>
                        )}

                        {x.type === 'vehicleRental' && (
                          <>
                            <p>Locação: {x.originAddress.substring(0, 50)}</p>
                            <p>
                              Devolução: {x.destinyAddress.substring(0, 50)}
                            </p>
                          </>
                        )}

                        {x.type === 'train' && (
                          <>
                            <p>
                              Partida:{' '}
                              {getDateFormated(x.start, 'HH:mm DD/MM/yyyy')}
                            </p>
                            <p>
                              Chegada:{' '}
                              {getDateFormated(x.end, 'HH:mm DD/MM/yyyy')}
                            </p>
                          </>
                        )}

                        {x.type === 'nautical' && (
                          <>
                            <p>
                              Partida:{' '}
                              {getDateFormated(x.start, 'HH:mm DD/MM/yyyy')}
                            </p>
                            <p>
                              Chegada:{' '}
                              {getDateFormated(x.end, 'HH:mm DD/MM/yyyy')}
                            </p>
                          </>
                        )}

                        {x.type === 'other' &&
                          ((getDateFormated(x.start, 'HH:mm') != '00:00' &&
                            getDateFormated(x.end, 'HH:mm') != '00:00') ||
                            (getDateFormated(x.start, 'HH:mm') == '00:00' &&
                              getDateFormated(x.end, 'HH:mm') != '00:00') ||
                            (getDateFormated(x.start, 'HH:mm') != '00:00' &&
                              getDateFormated(x.end, 'HH:mm') == '00:00')) && (
                            <>
                              <p>
                                Partida:{' '}
                                {getDateFormated(x.start, 'HH:mm DD/MM/yyyy')}
                              </p>
                              <p>
                                Chegada:{' '}
                                {getDateFormated(x.end, 'HH:mm DD/MM/yyyy')}
                              </p>
                            </>
                          )}

                        {x.type === 'other' &&
                          getDateFormated(x.start, 'HH:mm') == '00:00' &&
                          getDateFormated(x.end, 'HH:mm') == '00:00' && (
                            <>
                              <p>
                                Partida:{' '}
                                {getDateFormated(x.start, 'DD/MM/yyyy')}
                              </p>
                              <p>
                                Chegada: {getDateFormated(x.end, 'DD/MM/yyyy')}
                              </p>
                            </>
                          )}
                      </div>

                      <DivFlexRight>
                        <EditButton
                          onClick={() => onDetails({ type: x.type, entity: x })}
                        >
                          Detalhes
                        </EditButton>
                      </DivFlexRight>
                    </DefaultLiChildsItem>
                  )}
                </Draggable>
              )
            })}

            {provided.placeholder}
          </DefaultLiChilds>
        )}
      </Droppable>
    </DefaultLi>
  )
}
