import { Accordion, Button } from 'reactstrap'
import styled from 'styled-components'

export const InfomationAccordion = styled(Accordion)`
  padding-bottom: 10px;
`

export const Container = styled.div`
  padding: 0;
`

export const Content = styled.div`
  padding: 10px;
`

export const ServiceButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: cener;
  min-height: 57px;
  width: 100%;
  background: #eaeaea;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`

export const ServiceButton = styled(Button)`
  color: #555;
  background: none;
  border: none;
  font-size: 1.2em;

  &:hover {
    color: #555;
    background: rgba(0, 0, 0, 0.1);
  }
`

export const Avatar = styled.div`
  color: #ccc;
  font-size: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
`
