import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import Divisor from '~/components/Divisor'
import PageTitle from '~/components/PageTitle'
import useSalesReleasesForm from '../../hooks/useSalesReleasesForm'
import {
  SalesReleaseFormComponent,
  SalesReleaseFormContainer,
  SalesReleaseFormValuesTable,
} from '../../styles'
import SalesReleasesFormInput from '../SalesReleasesFormInput'

export default function SalesReleasesTravelPackageForm({
  onChange,
  defaultValue = [],
  internalOptionData = {},
}) {
  const { handleSubmit, getValues, register, reset, setValue, watch } =
    useSalesReleasesForm()
  const [list, setList] = useState(defaultValue ?? [])

  useEffect(() => {
    if (!list || list.length == 0) {
      setList([{}])
    }

    if (list[0]) {
      setValue('packageWithdrawalCity', list[0].packageWithdrawalCity)
    }

    return () => {
      setList([])
    }
  }, [])

  async function onSubmit(data) {
    const newList = [data]
    let formattedData = formatData(newList)

    setList(newList)

    onChange(newList, formattedData)
  }

  function formatData(data) {
    return data.map(item => `${item.otherServicesDescription}`).join('\n')
  }

  return (
    <SalesReleaseFormContainer>
      <SalesReleaseFormComponent onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <Row>
            <SalesReleasesFormInput
              label='Dt Início serviços'
              name='dtStartService'
              type={'date'}
              register={register}
              defaultValue={list[0]?.dtStartService}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Dt Fim serviços'
              name='dtEndService'
              type={'date'}
              register={register}
              defaultValue={list[0]?.dtEndService}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              label='Destino Principal'
              name='packageWithdrawalCity'
              type='text'
              register={register}
              setValue={setValue}
              defaultValue={list[0]?.packageWithdrawalCity}
              size='sm'
              sm={6}
              md={6}
              lg={2}
              xl={8}
              selectOptions={internalOptionData?.cities ?? []}
            />

            <SalesReleasesFormInput
              label='Descrição dos outros serviços do pacote'
              name='otherServicesDescription'
              type='textarea'
              register={register}
              defaultValue={list[0]?.otherServicesDescription}
              size='sm'
              sm={12}
              md={12}
              lg={12}
              xl={12}
            />
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12} className='submit-area'>
              <Button
                size={'sm'}
                type='button'
                color='none'
                onClick={() => reset()}
              >
                Limpar
              </Button>

              <Button size={'sm'} type='submit' color='primary'>
                Adicionar
              </Button>
            </Col>
          </Row>
        </fieldset>
      </SalesReleaseFormComponent>
    </SalesReleaseFormContainer>
  )
}
