import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'

export default function Tabs() {
  const location = useLocation()

  const tabs = [{ label: 'Aéreo', path: '' }]

  return (
    <TabList
      type='none'
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        padding: 0,
        margin: 0,
        overflow: 'auto',
        width: '100%',
      }}
    >
      {tabs.map((e, i) => {
        return (
          <TabListItem
            key={i}
            active={location.pathname.includes(e.path)}
            style={{
              fontSize: '1rem',
              textAlign: 'center',
              padding: '0.5rem',
              marginRight: '1rem',
              borderRadius: '6px 6px 0 0',
              textDecoration: 'none',
              whiteSpace: 'nowrap',
              active: location.pathname.includes(e.path),
            }}
            to={location.pathname.includes(e.path) ? '#' : e.path}
          >
            {e.label}
          </TabListItem>
        )
      })}
    </TabList>
  )
}

const TabList = styled.div`
  border-bottom: 6px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.foregroundAlt};
`

const TabListItem = styled(Link)`
  background-color: ${props =>
    props.active === true ? props.theme.colors.primary : 'transparent'};
  color: ${props =>
    props.active === true
      ? props.theme.colors.foregroundAlt
      : props.theme.colors.foreground};
  &:hover {
    color: ${props =>
      props.active === true
        ? props.theme.colors.foregroundAlt
        : props.theme.colors.foreground};
  }
`
