import UsedNamedMessage from '~/pages/CustomerServiceChat/Chat/Hooks/useNamedMessage'
import * as S from './styles'
import moment from 'moment'
import { Badge } from 'reactstrap'
import { Rating } from 'primereact/rating'

export default function ContactConversation({
  conversation,
  onChangeCurrentConversation,
  currentConversation,
  contacts,
  clientData,
  setActive,
}) {
  const { setUserMessageTextWithUserName } = UsedNamedMessage()
  const currentContact = contacts.find(c => c.phone == conversation.phoneNumber)

  const tags = clientData?.tag?.split(',') || []

  const formatTimestamp = message => {
    if (!message) return message

    const begin = moment(moment(message).format('YYYY MM DD').split(' '))
    const end = moment(moment().format('YYYY MM DD').split(' '))

    // Calcula a diferença em dias
    const dias = end.diff(begin, 'days')
    if (dias >= 1 && dias <= 7) {
      return moment(message).fromNow()
    }
    // Calcula a diferença em semanas
    const semanas = end.diff(begin, 'weeks')
    if (semanas >= 1) {
      return moment(message).format('DD/MM/YYYY')
    }
    return moment(message).format('HH:mm')
  }

  return (
    <S.Container
      onClick={() => {
        onChangeCurrentConversation(conversation)
        // Volta para a aba de todas as mensagens
        setActive(1)
      }}
      active={currentConversation === conversation}
    >
      <S.LeftContent>
        <S.Avatar>
          {currentContact && (
            <img
              src={`data:image/*;base64,${currentContact.photo}`}
              style={{ width: '48px', height: '48px', borderRadius: '50%' }}
            />
          )}

          {!currentContact && (
            <i style={{ fontSize: '48px' }} className='fas fa-user-circle' />
          )}
        </S.Avatar>

        <S.Main>
          {currentContact?.name && <S.Name>{currentContact.name}</S.Name>}

          {!currentContact?.name && (
            <S.Name>{conversation.phoneNumber ?? conversation.from}</S.Name>
          )}
          {clientData && (
            <Rating cancel={false} value={clientData.rating} disabled={true} />
          )}
          <S.TagContainer>
            {tags.map(tag => (
              <S.TagBadge key={tag}>{tag}</S.TagBadge>
            ))}
          </S.TagContainer>
          {conversation.to !== 'me' && (
            <>
              {conversation.read == true && (
                <S.DetailsRead>
                  <span>
                    <i
                      style={{ color: 'rgb(13 110 253)' }}
                      className='fas fa-check-double'
                    ></i>
                  </span>
                </S.DetailsRead>
              )}

              {conversation?.read == false && conversation?.delivered && (
                <S.DetailsRead>
                  <span>
                    <i
                      style={{ color: 'grey' }}
                      className='fas fa-check-double'
                    ></i>
                  </span>
                </S.DetailsRead>
              )}

              {!conversation?.read &&
                !conversation?.delivered &&
                conversation?.sent && (
                  <S.DetailsRead>
                    <span>
                      <i style={{ color: 'grey' }} className='fas fa-check'></i>
                    </span>
                  </S.DetailsRead>
                )}
            </>
          )}
          {conversation && (
            <S.Details>
              <S.DetailsText>
                {conversation.to !== 'me' && <>Você: </>}

                {conversation.type == 'text' && (
                  <>
                    {setUserMessageTextWithUserName(
                      conversation?.text?.body ?? '',
                      true,
                    )}
                  </>
                )}

                {conversation.type == 'image' && (
                  <>
                    <i className='far fa-image'></i> Imagem
                  </>
                )}

                {conversation.type == 'audio' && (
                  <>
                    <i className='fas fa-microphone'></i> Áudio
                  </>
                )}

                {conversation.type == 'sticker' && (
                  <>
                    <i className='fas fa-sticky-note'></i> Figurinha
                  </>
                )}

                {conversation.type == 'video' && (
                  <>
                    <i className='fas fa-film'></i> Vídeo
                  </>
                )}

                {conversation.type == 'document' && (
                  <>
                    <i className='fas fa-file'></i> Arquivo
                  </>
                )}
              </S.DetailsText>
            </S.Details>
          )}
          {conversation?.newMessage && (
            <S.NewBadge color='success' pill>
              Novo
            </S.NewBadge>
          )}
        </S.Main>
      </S.LeftContent>

      <S.EndInformation>
        <S.Time>{formatTimestamp(conversation?.timestamp)}</S.Time>

        {conversation.newMessage && conversation.to == 'me' && (
          <S.NewMessageIcon className='fas fa-exclamation-circle'></S.NewMessageIcon>
        )}
      </S.EndInformation>
    </S.Container>
  )
}
