import FilterContext from '~/context/createContext'
import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'

const GLOBAL_FILTERS = {
  dateStart: undefined,
  dateEnd: undefined,
  date: undefined,
}

const FilterContextWrapper = ({ children }) => {
  const { axiosApis } = GetAxios()
  const [showSalesStats, setShowSalesStats] = useState(false)
  const [handleProfile, setHandleProfile] = useState(false)
  const [loadingTravelers, setLoadingTravelers] = useState(false)
  const [entitys, setEntitys] = useState([])
  const [showModal, setShowModal] = useState(true)
  const [modalData, setModalData] = useState({})
  const [showModalService, setShowModalService] = useState(false)
  const [showModalCheckIn, setShowModalCheckIn] = useState(false)
  const [showModalMaterial, setShowModalMaterial] = useState(false)
  const [valueModal, setValueModal] = useState({})
  const [showFileInput1, setShowFileInput1] = useState(null)
  const [showFileInput2, setShowFileInput2] = useState(null)
  const [globalFilters, setGlobalFilters] = useState(GLOBAL_FILTERS)
  const resetGlobalFilters = () => setGlobalFilters(GLOBAL_FILTERS)
  const [airList, setAirList] = useState([])
  const [hotelList, setHotelList] = useState([])
  const [agency, setAgency] = useState({})

  useEffect(() => {
    getWintourIntegration()
    return () => {
      setLoadingTravelers(false)
      setEntitys([])
      setShowModal(true)
      setAirList([])
      setHotelList([])
      setGlobalFilters(GLOBAL_FILTERS)
      setModalData({})
      setShowModalService(false)
      setShowModalCheckIn(false)
      setShowModalMaterial(false)
      setValueModal({})
      setShowFileInput1(null)
      setShowFileInput2(null)
    }
  }, [])

  const getWintourIntegration = async () => {
    const { data } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3`,
    )
    setShowSalesStats(data?.some(x => x.integrationType == 4))

    const { data: AgencyData } = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/agency/AllUsers`,
    )

    setAgency(AgencyData)
  }

  return (
    <FilterContext.Provider
      value={{
        loadingTravelers,
        setLoadingTravelers,
        entitys,
        setEntitys,
        globalFilters,
        setGlobalFilters,
        resetGlobalFilters,
        showModal,
        setShowModal,
        airList,
        setAirList,
        // airsMock,
        hotelList,
        setHotelList,
        // hotelsMock,
        modalData,
        setModalData,
        showFileInput1,
        setShowFileInput1,
        showFileInput2,
        setShowFileInput2,
        showModalService,
        setShowModalService,
        showModalCheckIn,
        setShowModalCheckIn,
        showModalMaterial,
        setShowModalMaterial,
        valueModal,
        setValueModal,
        handleProfile,
        setHandleProfile,
        showSalesStats,
        setShowSalesStats,
        agency,
        setAgency,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export { FilterContext, FilterContextWrapper }
