import styled from 'styled-components'
import LoadingContainer from '~/components/LoadingContainer'
import { FormatValueBRL } from '~/functions/FormatValue'

function AnalyticsDashboard({ data = [], columnSizingPerRow = '50%' }) {
  return (
    <Container>
      {data.map((e, i) => {
        return (
          <Item key={i} columnSizingPerRow={columnSizingPerRow}>
            <ItemContent>
              {e.loading && (
                <>
                  <LoadingContainer />
                </>
              )}

              {!e.loading && (
                <>
                  <span className='analytics-dashboard-label'>{e.label}</span>

                  <div className='analytics-dashboard-values'>
                    <span className='analytics-dashboard-value'>
                      {e.currency ? FormatValueBRL(e.value) : e.value}
                    </span>

                    <span className='analytics-dashboard-sublabel'>
                      {e.subLabel}
                    </span>
                  </div>
                </>
              )}
            </ItemContent>
          </Item>
        )
      })}
    </Container>
  )
}

const getDashboardPlaceholders = count => {
  const res = []

  for (let i = 0; i < count; i++) {
    res.push({
      loading: true,
    })
  }

  return res
}

export { getDashboardPlaceholders }

export default AnalyticsDashboard

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.1em;
`

const Item = styled.div`
  padding: 4px;
  width: ${props => props.columnSizingPerRow};

  @media screen and (max-width: 920px) {
    width: 100%;
  }
`

const ItemContent = styled.div`
  background: #fdfdfd;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 120px;

  .analytics-dashboard-label {
    font-weight: normal;
    border-bottom: 1px solid #ccc;
    width: 100%;
    text-align: left;
    padding: 8px;
    margin: 0;
    font-weight: 500;
  }

  .analytics-dashboard-values {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 8px;
  }

  .analytics-dashboard-value {
    font-size: 1.6em;
    font-weight: bold;
  }

  .analytics-dashboard-sublabel {
    font-size: 1em;
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`
