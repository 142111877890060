import styled from 'styled-components'
import logo from '~/components/imgs/logotripmee_parafundobranco.png'

const { REACT_APP_APP_VERSION } = process.env

export default function Footer() {
  return (
    <Container>
      <Logo src={logo} alt='Tripmee' />

      <Version>v{REACT_APP_APP_VERSION}</Version>

      <b style={{ textAlign: 'center' }}>&copy; {new Date().getFullYear()}</b>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  height: 32px;
`

const Version = styled.span`
  background-color: none;
  font-weight: 500;
`
