import PageTitle from '~/components/PageTitle'
import { Capsule, Content } from './style'

export default function HeaderInPage({ title }) {
  return (
    <Capsule>
      <Content>
        <i className='fas fa-angle-double-right'></i>
        <PageTitle title={title} head='h6' noButton noMargin />
      </Content>
    </Capsule>
  )
}
