import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { EditButton } from '~/components/Button/styles'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { getDateDiffLabel, getDateFormated, getMoment } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import { Avatar } from '~/styles/divs'
import Edit from './Edit'
import { RightCol } from './styles'

export default function ReportServicesDetails() {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [agent, setAgent] = useState(location.state)
  const [agentNew, setAgentNew] = useState()
  const [isEditing, setIsEditing] = useState(false)
  const { axiosApis } = GetAxios()
  const [profile, setProfile] = useState('')

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
        )

        data.map(item => {
          if (item.value === agent.role) {
            setProfile(item.label)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onCancel(data) {
    setIsEditing(false)

    if (data) {
      setAgent(data)
    }
  }

  async function onDelete() {
    setIsEditing(false)

    navigate(-1)
  }

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agent/${agent.id}`,
        )

        setAgentNew(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <Container>
      <PageTitle title={agentNew ? agentNew.familyName : ''} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          {agentNew ? (
            <Container>
              {!isEditing && (
                <>
                  <Row>
                    <Col
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                      style={{ color: '#7f2ec3' }}
                    >
                      <b>Agência {agentNew.agency.name}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Nome de Preferência</b>

                      <p>{agentNew.familyName}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Nome Completo</b>

                      <p>{agentNew.fullName}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>E-mail</b>

                      <p>{agentNew.email}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Telefone</b>

                      <p>{agentNew.phone}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>CPF</b>

                      {agentNew.cpf && <p>{agentNew.cpf}</p>}
                      {!agentNew.cpf && <p>Não preenchido</p>}
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Perfil</b>

                      <p>{profile}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Profissão</b>

                      <p>{agentNew.title}</p>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Anos de Experiência</b>

                      <p>
                        {getDateDiffLabel(
                          getDateFormated(
                            agentNew.since,
                            'YYYY-MM-DD HH:mm:ss',
                          ),
                          getMoment(),
                        )}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Especialidades</b>

                      <ul type='none'>
                        {agentNew.experiences.map((item, i) => {
                          return (
                            <li
                              key={i}
                              style={{
                                backgroundColor: 'none',
                                marginRight: '4px',
                              }}
                            >
                              <b style={{ color: '#7f2ec3' }}>{item}</b>
                            </li>
                          )
                        })}
                      </ul>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Bio</b>

                      <div
                        style={{
                          whiteSpace: 'pre-wrap',
                          maxHeight: '200px',
                          overflow: 'auto',
                        }}
                      >
                        {agentNew.bio}
                      </div>
                    </Col>

                    <Col sm={12} md={6} lg={4} xl={3}>
                      <b>Avatar</b>

                      <Avatar
                        style={{
                          width: '160px',
                          height: '160px',
                          backgroundSize: 'cover',
                          backgroundImage: `url('data:image/*;base64,${agentNew.avatar}')`,
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {isEditing && (
                <Edit data={agentNew} onCancel={onCancel} onDelete={onDelete} />
              )}

              {!isEditing && (
                <Row>
                  <RightCol>
                    <EditButton
                      type='button'
                      onClick={() => setIsEditing(true)}
                    >
                      Editar
                    </EditButton>
                  </RightCol>
                </Row>
              )}
            </Container>
          ) : (
            ''
          )}
        </PanelBody>
      </Panel>
    </Container>
  )
}
