export const toOperator = [
  {
    type: 'text',
    name: 'fullName',
    label: 'Nome Completo',
    placeholder: 'Nome Completo',
    required: true,
    maxLength: 150,
  },
  {
    type: 'email',
    name: 'email',
    label: 'E-mail',
    placeholder: 'E-mail',
    disabled: false,
  },
  {
    type: 'phone',
    name: 'phone',
    label: 'Telefone',
    placeholder: 'Telefone',
  },
  {
    type: 'text',
    name: 'cpfCnpj',
    label: 'CPF/CNPJ',
    placeholder: 'CPF/CNPJ',
    required: false,
  },
  {
    type: 'text',
    name: 'rg',
    label: 'RG/CNH',
    placeholder: 'RG/CNH',
    required: false,
  },
  {
    type: 'date',
    name: 'birthdayDate',
    label: 'Aniversário',
    required: false,
  },
  {
    type: 'text',
    name: 'profession',
    label: 'Profissão',
    placeholder: 'Profissão',
    required: false,
  },
  {
    type: 'text',
    name: 'address',
    label: 'Endereço',
    placeholder: 'Endereço',
    required: false,
  },
  {
    type: 'text',
    name: 'city',
    label: 'Cidade',
    placeholder: 'Cidade',
    required: false,
  },
  {
    type: 'text',
    name: 'state',
    label: 'Estado',
    placeholder: 'Estado',
    required: false,
  },
  {
    type: 'text',
    name: 'country',
    label: 'País',
    placeholder: 'País',
    required: false,
  },
  {
    type: 'text',
    name: 'belongingAgency',
    label: 'Agência',
    placeholder: 'Agência',
    // disabled: true,
  },
]

export const toAgency = [
  {
    type: 'text',
    name: 'fullName',
    label: 'Nome Completo',
    placeholder: 'Nome Completo',
    required: true,
    maxLength: 150,
  },
  {
    type: 'email',
    name: 'email',
    label: 'E-mail',
    placeholder: 'E-mail',
    disabled: false,
  },
  {
    type: 'phone',
    name: 'phone',
    label: 'Telefone',
    placeholder: 'Telefone',
  },
  {
    type: 'text',
    name: 'cpfCnpj',
    label: 'CPF/CNPJ',
    placeholder: 'CPF/CNPJ',
    required: false,
  },
  {
    type: 'text',
    name: 'rg',
    label: 'RG/CNH',
    placeholder: 'RG/CNH',
    required: false,
  },
  {
    type: 'date',
    name: 'birthdayDate',
    label: 'Aniversário',
    required: false,
  },
  {
    type: 'text',
    name: 'profession',
    label: 'Profissão',
    placeholder: 'Profissão',
    required: false,
  },
  {
    type: 'text',
    name: 'address',
    label: 'Endereço',
    placeholder: 'Endereço',
    required: false,
  },
  {
    type: 'text',
    name: 'city',
    label: 'Cidade',
    placeholder: 'Cidade',
    required: false,
  },
  {
    type: 'text',
    name: 'state',
    label: 'Estado',
    placeholder: 'Estado',
    required: false,
  },
  {
    type: 'text',
    name: 'country',
    label: 'País',
    placeholder: 'País',
    required: false,
  },
]
