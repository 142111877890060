import { Form } from '@unform/web'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { ResetButton, SearchButton } from '~/components/Button/styles'
import Input from '~/components/Input'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import { RightCol } from './styles'
import IconButton from '~/components/IconButton'
import Table from './Table'
import usePermissions from '~/hooks/usePermissions'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import Select from '~/components/Select'
// import DaybydaySuggestionsAI from '~/components/AI/DaybydaySuggestionsAI'

function TravelManagement() {
  const { axiosApis } = GetAxios()
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [travels, setTravels] = useState([])
  const permissions = usePermissions()

  const initialState = {
    travelData: {
      title: '',
      traveler: '',
      originDestiny: '',
      start: '',
      end: '',
      agent: '',
      order: '',
    },
  }

  const [pageProps, setPageProps] = useState(initialState)

  const setInputField = (fieldName, value) => {
    const newData = {
      ...pageProps,
      travelData: {
        ...pageProps.travelData,
        [fieldName]: value,
      },
    }
    setPageProps(newData)
  }

  const resetForm = () => {
    setPageProps(initialState)
  }

  useEffect(() => {
    getTravelTableData()
  }, [])

  async function getTravelTableData() {
    try {
      setLoading(true)

      if (pageProps != undefined) {
        const result = {
          ...pageProps.travelData,
          order:
            pageProps.travelData.order != null
              ? pageProps.travelData.order.value
              : null,
        }

        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travel`,
          {
            params: result,
          },
        )
        setTravels(response.data)
      } else {
        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travel`,
          {
            params: pageProps.travelData,
          },
        )
        setTravels(response.data)
      }

      return setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)

      return toast.info('Nenhum resultado encontrado')
    }
  }

  async function handleSubmitDelete(id) {
    try {
      setLoading(true)

      if (window.confirm('Você tem certeza que deseja excluir essa viagem?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travel/${id}`,
        )

        toast.success(`Viagem excluída com sucesso!`)

        setLoading(false)
        getTravelTableData()
      }
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Não foi possível excluir a viagem, por favor tente mais tarde.',
      )
    }
  }

  return (
    <Container>
      <h1 className='page-header'>
        Itinerários
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Tela com opções de visualização de todos os Itinerários, no perfil Agência a visualização será de todas os Itinerários criados, para o Perfil consultor só irá mostrar as viagens criadas pelo consultor. Outras opções disponíveis , são a Edição e Visualização do Roteiro Digital.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <Form ref={formRef} onSubmit={() => {}}>
            <Row>
              <Col sm={12} md={6} lg={4} xl={3}>
                <Input
                  disabled={false}
                  name={'traveler'}
                  label={'Viajante principal'}
                  value={pageProps.travelData.traveler}
                  maxLength={50}
                  onChange={e => setInputField('traveler', e.target.value)}
                />
              </Col>

              <Col sm={12} md={6} lg={4} xl={3}>
                <Input
                  disabled={false}
                  name={'title'}
                  label={'Itinerário'}
                  value={pageProps.travelData.title}
                  maxLength={30}
                  onChange={e => setInputField('title', e.target.value)}
                />
              </Col>

              <Col sm={12} md={6} lg={4} xl={3}>
                <Input
                  disabled={false}
                  name={'start'}
                  label={'Início'}
                  type={'date'}
                  value={pageProps.travelData.start}
                  onChange={e => setInputField('start', e.target.value)}
                />
              </Col>

              <Col sm={12} md={6} lg={4} xl={3}>
                <Input
                  disabled={false}
                  name={'end'}
                  label={'Fim'}
                  type={'date'}
                  value={pageProps.travelData.end}
                  onChange={e => setInputField('end', e.target.value)}
                />
              </Col>

              {permissions.isAdmin && (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <Input
                    disabled={false}
                    name={'agent'}
                    label={'Consultor'}
                    value={pageProps.travelData.agent}
                    maxLength={50}
                    onChange={e => setInputField('agent', e.target.value)}
                  />
                </Col>
              )}

              <Col sm={12} md={6} lg={4} xl={3}>
                <Select
                  disabled={false}
                  name={'order'}
                  isClearable={true}
                  options={[
                    { label: 'Inicio da viagem', value: 'ini' },
                    { label: 'Fim da viagem', value: 'fim' },
                  ]}
                  label={'Ordenar por:'}
                  placeholder={'Selecione...'}
                  value={pageProps.travelData.order}
                  onChange={e => setInputField('order', e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <RightCol>
                <ResetButton onClick={resetForm} type='reset'>
                  Limpar
                </ResetButton>

                <SearchButton onClick={getTravelTableData}>
                  Pesquisar
                </SearchButton>
              </RightCol>
            </Row>
          </Form>
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}>
          <LinkRegisterNewTraveler to={'/travels/create'}>
            Novo Itinerário
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            travelList={travels}
            loading={loading}
            handleSubmitDelete={handleSubmitDelete}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

export default TravelManagement
