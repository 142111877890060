import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import { GetAxios } from '~/services/api'
import { useLocation, useNavigate } from 'react-router-dom'
import PageTitle from '~/components/PageTitle'
import { toast } from 'react-toastify'

export default function ReminderDetails() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [traveler, setTraveler] = useState([])
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    getReminder()
  }, [])

  const getReminder = async () => {
    try {
      setLoading(true)
      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/reminder/${location.state.id}`,
      )
      setLoading(false)
      setTraveler(response.data)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const UpdateReminder = async data => {
    try {
      setLoading(true)
      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/reminder/${location.state.id}`,
        data,
      )
      setLoading(false)
      navigate(-1)
    } catch (err) {
      setLoading(false)
      toast.info(err?.response.data)
      console.log(err)
    }
  }

  const deleteReminder = async data => {
    try {
      setLoading(true)
      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/reminder/${location.state.id}`,
        data,
      )
      setLoading(false)
      navigate(-1)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <Container>
      <PageTitle title={'Detalhes do Tarefa'} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={UpdateReminder}
            callbackDelete={deleteReminder}
            loading={loading}
            defaultValue={traveler}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Titulo',
                sm: 8,
                md: 8,
                lg: 8,
                xl: 8,
              },
              {
                type: 'date',
                name: 'shootingData',
                label: 'Data de envio',
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'textarea',
                name: 'description',
                label: 'Descrição',
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
