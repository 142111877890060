function getSaleType(x) {
  let icon = 'fas fa-shopping-bag'
  let info = 'Venda'

  switch (x) {
    case 'Air':
      icon = 'fas fa-plane'
      info = 'Aéreo'
      break
    case 'Bus':
      icon = 'fas fa-bus'
      info = 'Ônibus'
      break
    case 'Hotel':
      icon = 'fas fa-hotel'
      info = 'Hotel'
      break
    case 'Ticket':
      icon = 'fas fa-ticket-alt'
      info = 'Bilhete'
      break
    case 'Service':
      icon = 'fas fa-cube'
      info = 'Serviço'
      break
    case 'AirHotelPackage':
      icon = 'fas fa-cubes'
      info = 'Pacote(Aéreo e Hotel)'
      break
    case 'HotelPackage':
      icon = 'fas fa-cubes'
      info = 'Pacote(Hotel)'
      break
    case 'Tour':
      icon = 'fas fa-route'
      info = 'Tour'
      break
    case 'Cruise':
      icon = 'fas fa-anchor'
      info = 'Cruzeiro'
      break
    case 'Train':
      icon = 'fas fa-train'
      info = 'Trem'
      break
    case 'ServiceBusPackage':
      icon = 'fas fa-cubes'
      info = 'Pacote(Serviço + Ônibus)'
      break
    case 'HotelBusPackage':
      icon = 'fas fa-cubes'
      info = 'Pacote(Hotel + Ônibus)'
      break
    default:
      icon = 'fas fa-shopping-bag'
      info = 'Venda'
      break
  }

  return {
    icon,
    info,
  }
}

function getSaleState(x) {
  let name = 'Emitido'

  switch (x) {
    case 'New':
      name = 'Novo'
      break
    case 'Issued':
      name = 'Emitido'
      break
    case 'Reserved':
      name = 'Reservado'
      break
    case 'Issuing':
      name = 'Em emissão'
      break
    case 'Canceled':
      name = 'Cancelado'
      break
    case 'OnRequest':
      name = 'Em requisição'
      break
    case 'PreSale':
      name = 'Pré-venda'
      break
    case 'TechicalError':
      name = 'Erro técnico'
      break
    case 'Rejected':
      name = 'Rejeitado'
      break
    case 'Quote':
      name = 'Em cotação'
      break
    case 'Pending':
      name = 'Pendente'
      break
    default:
      name = 'Vazio'
      break
  }

  return name
}

function getSalePaxGender(x) {
  let name = 'Outro'

  switch (x) {
    case 'Male':
      name = 'Masculino'
      break
    case 'Female':
      name = 'Feminino'
      break
    case 'Other':
      name = 'Outro'
      break
    default:
      break
  }

  return name
}

function getSalePaxType(x) {
  let name = 'Adulto'

  switch (x) {
    case 'Adult':
      name = 'Adulto'
      break
    case 'Teen':
      name = 'Adolescente'
      break
    case 'Child':
      name = 'Criança'
      break
    case 'Infant':
      name = 'Infantil'
      break
    default:
      break
  }

  return name
}

function getSalePaxDocumentType(x) {
  let name = 'Documento'

  switch (x) {
    case 'Cpf':
      name = 'CPF'
      break
    default:
      break
  }

  return name
}

function getSaleValueType(x) {
  let name = 'Tipo de valores'

  switch (x) {
    case 'Sale':
      name = 'Venda'
      break
    default:
      break
  }

  return name
}

function getSaleValueInfo(x) {
  let name = 'Valor'

  switch (x) {
    case 'Total':
      name = 'Total'
      break
    case 'Fare':
      name = 'Tarifa'
      break
    case 'FareBase':
      name = 'Tarifa base'
      break
    case 'Tax':
      name = 'Taxa'
      break
    case 'Du':
      name = 'DU'
      break
    case 'Rav':
      name = 'RAV'
      break
    case 'Mkp':
      name = 'Markup'
      break
    case 'Fee':
      name = 'FEE'
      break
    case 'InsuranceRate':
      name = 'Taxa de seguro'
      break
    case 'Other':
      name = 'Outros'
      break
    default:
      break
  }

  return name
}

function getSaleId(id) {
  let res = ''

  if (id && id.includes('-@-')) {
    res = id.split('-@-')[0]
  }

  return res
}

function splitSaleValues(text) {
  if (!text) {
    return null
  }

  const values = text.split('-@-')

  return (
    <>
      {values.map((e, i) => {
        return (
          <>
            <p key={i} style={{ padding: '4px 0 0' }}>
              {e}
            </p>
          </>
        )
      })}
    </>
  )
}

function getSaleValuesJoined(text) {
  if (!text) {
    return null
  }

  const values = text.split('-@-')

  return values.join(', ')
}

export {
  getSaleType,
  getSaleState,
  getSalePaxType,
  getSalePaxDocumentType,
  getSalePaxGender,
  getSaleValueInfo,
  getSaleValueType,
  getSaleId,
  splitSaleValues,
  getSaleValuesJoined,
}
