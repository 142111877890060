import Chart from 'chart.js/auto'
import { Line } from 'react-chartjs-2'
import styled from 'styled-components'
import LoadingContainer from '~/components/LoadingContainer'
import { FormatValueBRL } from '~/functions/FormatValue'

export default function AnalyticsLineChart({ data, currency = false }) {
  return (
    <Container>
      {!data && <LoadingContainer height='400px' />}

      {data && (
        <Line
          data={data}
          options={{
            plugins: {
              tooltip: {
                callbacks: {
                  label: e => {
                    let label = e.dataset.label || ''

                    if (label) {
                      label += ': '
                    }

                    if (e.parsed.y) {
                      label += currency
                        ? FormatValueBRL(e.parsed.y)
                        : e.parsed.y
                    }

                    return label
                  },
                },
              },
            },
          }}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
