import { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import LoadingContainer from '~/components/LoadingContainer'
import PageTitle from '~/components/PageTitle'
import { GetAxios } from '~/services/api'
import { SimpleList, SimpleListItem } from '~/styles/ul'
import { getDateFormated, getDateTimeFormated } from '~/functions/Date'
import { FormatValueBRL } from '~/functions/FormatValue'
import { getSalePaxType, getSaleValueInfo } from '~/functions/Sales'
import Table from '~/components/Table'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'

export default function ReportCheckInDetails({ data }) {
  const [loading, setLoading] = useState(false)
  const { axiosApis } = GetAxios()
  const [sale, setSale] = useState()
  const [values, setValues] = useState([])
  const [valuesColumns, setValuesColumns] = useState([])

  useEffect(() => {
    async function onSubmit() {
      try {
        setLoading(true)

        const res = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/sales/${data.id}`,
        )

        const newValues = []

        const newValuesColumns = []

        const newValue = {}

        res.data.values.forEach(e => {
          newValue[e.info] = e.ammount

          newValuesColumns.push({
            Header: getSaleValueInfo(e.info),
            accessor: e.info,
            width: 120,
            Cell: props => {
              return FormatValueBRL(props.row.original[e.info])
            },
          })
        })

        newValues.push(newValue)

        setValues(newValues)

        setValuesColumns(newValuesColumns)

        setSale(res.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    onSubmit()
  }, [])

  return (
    <div style={{ width: '100%' }}>
      {loading && (
        <div style={{ width: '100%' }}>
          <LoadingContainer size='lg' height='300px' />
        </div>
      )}

      {!loading && sale && (
        <>
          {sale.paxs.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title={'Passageiros'} />

                <PanelBody>
                  <Table
                    data={sale.paxs}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Primeiro nome',
                        accessor: 'firstName',
                        width: 150,
                      },
                      {
                        Header: 'Sobrenome',
                        accessor: 'surname',
                        width: 150,
                      },
                      {
                        Header: 'E-mail',
                        accessor: 'email',
                        width: 250,
                      },
                      {
                        Header: 'Telefone',
                        accessor: 'phone',
                        width: 120,
                      },
                      {
                        Header: 'Tipo',
                        accessor: 'type',
                        width: 120,
                        Cell: props => {
                          return getSalePaxType(props.row.original.type)
                        },
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.airs.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Aéreos' />

                <PanelBody>
                  <Table
                    data={sale.airs}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Embarque',
                        accessor: 'departureDate',
                        width: 150,
                        Cell: props => {
                          return getDateTimeFormated(
                            props.row.original.departureDate,
                          )
                        },
                      },
                      {
                        Header: 'Desembarque',
                        accessor: 'arrivalDate',
                        width: 150,
                        Cell: props => {
                          return getDateTimeFormated(
                            props.row.original.arrivalDate,
                          )
                        },
                      },
                      {
                        Header: 'Aeroporto de embarque',
                        accessor: 'departureAirportCode',
                        width: 170,
                      },
                      {
                        Header: 'Aeroporto de desembarque',
                        accessor: 'arrivalAirportCode',
                        width: 170,
                      },
                      {
                        Header: 'Voo',
                        accessor: 'flight',
                        width: 120,
                        Cell: props => {
                          return `${props.row.original.airlineCode} ${props.row.original.flight}`
                        },
                      },
                      {
                        Header: 'Localizador',
                        accessor: 'locator',
                        width: 100,
                      },
                      {
                        Header: 'Classe',
                        accessor: 'serviceClass',
                        width: 100,
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.buses.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Ônibus' />

                <PanelBody>
                  <Table
                    data={sale.buses}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Embarque',
                        accessor: 'departureDate',
                        width: 100,
                        Cell: props => {
                          return getDateTimeFormated(
                            props.row.original.departureDate,
                          )
                        },
                      },
                      {
                        Header: 'Desembarque',
                        accessor: 'arrivalDate',
                        width: 100,
                        Cell: props => {
                          return getDateTimeFormated(
                            props.row.original.arrivalDate,
                          )
                        },
                      },
                      {
                        Header: 'Origem',
                        accessor: 'departure',
                        width: 170,
                      },
                      {
                        Header: 'Destino',
                        accessor: 'arrival',
                        width: 170,
                      },
                      {
                        Header: 'Empresa',
                        accessor: 'company',
                        width: 170,
                        Cell: props => {
                          return (
                            <div>
                              <span>{props.row.original.company}</span>

                              <br />

                              {props.row.original.companyImageUrl && (
                                <img
                                  src={props.row.original.companyImageUrl}
                                  style={{ height: '24px' }}
                                />
                              )}
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Tipo',
                        accessor: 'type',
                        width: 120,
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.values.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Valores' />

                <PanelBody>
                  <Table
                    data={values}
                    loading={loading}
                    columns={valuesColumns}
                  />
                </PanelBody>
              </Panel>
            </>
          )}
        </>
      )}
    </div>
  )
}
