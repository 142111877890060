import { useState, useEffect } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { toast } from 'react-toastify'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import SubTableOfRadar from '~/components/SubTableOfRadar'
import { getDateFormated } from '~/functions/Date'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'
import useModal from '~/hooks/useModal'
import ShowInfo from './showInfo'

export default function RadarList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [travelers, setTravelers] = useState([])
  const navigate = useNavigate()
  const [agentOptions, setAgentOptions] = useState([])
  const { showModal } = useModal()

  async function showDetails(e) {
    navigate('/travels/details', { state: { id: e } })
  }
  async function showTravelers(e) {
    navigate('/radar/details', { state: { traveler: e } })
  }

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      await onSubmit(0)
    }
    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    setLoading(true)

    const response = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/radar/gettraveler`,
      {
        params: {
          agent: data.agent?.value,
        },
      },
    )

    setTravelers(response.data)

    return setLoading(false)
  }

  const onDelete = async data => {
    try {
      setLoading(true)

      if (window.confirm('Deseja retirar esse viajante do radar?')) {
        await axiosApis.put(
          `${process.env.REACT_APP_ENDPOINT_APIS}/radar/traveler/${data.id}`,
        )
        await onSubmit(0)
        toast.success('Viajante retirado com sucesso!')
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  async function showInfo(traveler) {
    showModal({
      title: 'Mensagem para o Viajante',
      body: () => <ShowInfo traveler={traveler} />,
      width: '30%',
    })
  }

  // async function reportWpp(id, fone, name_viajante) {
  //   try {
  //     if (window.confirm(')) {
  //       const body = {
  //         id,
  //         fone,
  //         name_viajante,
  //       }

  //       await axiosApis.post(
  //         `${process.env.REACT_APP_ENDPOINT_APIS}/quotation/happysend-wpp`,
  //         body,
  //       )
  //       toast.success('Mensagem enviada com sucesso!')
  //     }
  //   } catch (err) {
  //     if (err.response && err.response.data) {
  //       toast.info(err.response.data)
  //       console.log(err.response)
  //     }
  //   }
  // }

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const agent = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
        )

        setAgentOptions(agent.data)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <Container>
      <h1 className='page-header'>
        Radar
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Um alerta inteligente que permite o consultor saber que está chegando a hora das próximas férias de cada cliente. Clique para ver um vídeo explicativo.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>
      <Panel>
        <PanelHeader noButton title={'Filtros'}></PanelHeader>

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            submitLabel={'Pesquisar'}
            disabled={loading}
            inputs={[
              {
                type: 'select',
                name: 'agent',
                label: 'Consultor',
                placeholder: 'Selecione...',
                options: agentOptions,
                isClearable: true,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table
            columns={[
              {
                Header: () => null,
                id: 'expander',
                Cell: ({ row }) => (
                  <span {...row.getToggleRowExpandedProps()}>
                    <i
                      className={`
                  fas fa-caret-${row.isExpanded ? 'up' : 'down'}
                `}
                    />
                  </span>
                ),
                width: 40,
              },
              {
                Header: 'Viajante',
                accessor: 'fullName',
                width: 250,
              },
              {
                Header: 'Aniversário',
                accessor: 'birthdayDate',
                width: 120,
                Cell: props => {
                  return getDateFormated(props.row.original.birthdayDate)
                },
              },
              {
                Header: 'Data da Última Viagem',
                accessor: 'lastTravelDate',
                Cell: props => {
                  if (props.row.original.lastTravelDate != null) {
                    return getDateFormated(props.row.original.lastTravelDate)
                  } else {
                    return 'Ainda não viajou'
                  }
                },
                width: 170,
              },
              {
                Header: 'Última Viagem',
                accessor: 'lastTravelDays',
                Cell: props => {
                  if (props.row.original.lastTravelDays < 0) {
                    return `Ocorreu há ${Math.abs(
                      props.row.original.lastTravelDays,
                    )} dias(s)`
                  } else if (props.row.original.lastTravelDays > 0) {
                    return `Ocorrerá em  ${props.row.original.lastTravelDays} dia(s)`
                  } else {
                    return `Ainda não viajou`
                  }
                },
                width: 170,
              },
              {
                Header: '',
                accessor: 'detalhes-viagem',
                Cell: props => {
                  if (props.row.original.lastTravelDate != null) {
                    return (
                      <IconButton
                        id={'expand' + props.row.index}
                        type={'details'}
                        tooltipOverride={'Detalhes da última viagem'}
                        onClick={() => showDetails(props.row.original.travelId)}
                      />
                    )
                  } else {
                    return (
                      <IconButton
                        id={'expand' + props.row.index}
                        type={'details'}
                        tooltipOverride={'Detalhes da última viagem'}
                      />
                    )
                  }
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <IconButton
                      id={'details' + props.row.index}
                      type={'user'}
                      tooltipOverride={'Perfil do Viajante'}
                      onClick={() => showTravelers(props.row.original)}
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'whatsappSend',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.index}
                      type={'wpp'}
                      iconOverride={'fab fa-whatsapp'}
                      tooltipOverride={'Enviar mensagem para o viajante'}
                      onClick={() => showInfo(props.row.original)}
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'remove',
                Cell: props => {
                  return (
                    <IconButton
                      id={'remove' + props.row.index}
                      type={'trash'}
                      tooltipOverride={'Remover Viajante do Radar'}
                      onClick={() => onDelete(props.row.original)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
            subComponent={props => (
              <SubTableOfRadar travelerId={props.row.original.id} />
            )}
            data={travelers}
            loading={loading}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
