import SkeletonLine from '~/components/Skeleton'
import * as S from './styles'

export default function InformationSkeleton() {
  return (
    <S.Container>
      <S.ContainerTop disabled>
        <SkeletonLine width={'64px'} height={'27px'} />

        <SkeletonLine width={'64px'} height={'27px'} />

        <SkeletonLine width={'64px'} height={'27px'} />
      </S.ContainerTop>

      <S.Header>
        <i
          style={{ fontSize: '120px', color: '#bdbfbf' }}
          className='fas fa-user-circle'
        />
      </S.Header>

      <S.Content>
        <SkeletonLine
          data-cy='info-contact-name-skeleton'
          height={48}
          width={'100%'}
        />

        <SkeletonLine
          data-cy='info-contact-email-skeleton'
          height={48}
          width={'100%'}
        />
      </S.Content>
    </S.Container>
  )
}
