import { useEffect } from 'react'
import { FormGroup, Input, Label, Row, Table } from 'reactstrap'
import styled from 'styled-components'
import { getDateFormated } from '~/functions/Date'
import * as S from './styles'

export default function TravelerHistory({ traveler, index }) {
  return (
    <S.HistoryContainer
      style={{ background: `${index % 2 === 0 ? '' : '#f5f5f5'}` }}
    >
      <Table striped>
        <tbody>
          <tr>
            <th scope='row'>Itinerário</th>
            <td>{traveler?.title}</td>
          </tr>
          <tr>
            <th scope='row'>Origem</th>
            <td>{traveler?.origem}</td>
          </tr>
          <tr>
            <th scope='row'>Destino</th>
            <td>{traveler?.destino}</td>
          </tr>
          <tr>
            <th scope='row'>Início</th>
            <td>{traveler?.start ? getDateFormated(traveler.start) : ''}</td>
          </tr>
          <tr>
            <th scope='row'>Fim</th>
            <td>{traveler?.end ? getDateFormated(traveler.end) : ''}</td>
          </tr>
          <tr>
            <th scope='row'>Nome do consultor</th>
            <td>{traveler?.nameAgent}</td>
          </tr>
        </tbody>
      </Table>
    </S.HistoryContainer>
  )
}
