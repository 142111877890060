import { useSelector } from 'react-redux'
import ReactTable from '~/components/Table'
import { useNavigate } from 'react-router-dom'
import useModal from '~/hooks/useModal'
import ShowInfo from '../showInfo'
import { inputsTable } from '../inputs'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'

const TravelerTable = ({
  travelerList,
  loading,
  tableProps,
  onChangePage,
  onChangeLimit,
  setTravelerList,
}) => {
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate()
  const { showModal } = useModal()
  const { axiosApis } = GetAxios()

  async function showDetails(e) {
    navigate('/travelers/details', {
      state: { traveler: { travelerId: e.travelerId, userId: e.id } },
    })
  }
  async function showDocuments(e) {
    navigate('/travelers/documents', { state: { traveler: e } })
  }
  async function showInfo(traveler) {
    showModal({
      title: 'Tags',
      body: () => <ShowInfo traveler={traveler} />,
      width: '30%',
    })
  }

  async function deleteTraveler(e) {
    let newTravelerList = [...travelerList]
    if (!confirm('Deseja excluir o viajante?')) {
      return
    }
    await axiosApis.delete(
      `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/${e.id}`,
    )

    setTravelerList(newTravelerList.filter(t => t.id !== e.id))
    toast.success('Viajante excluído com sucesso!')
  }

  return (
    <ReactTable
      columns={inputsTable({
        user,
        showDetails,
        showDocuments,
        showInfo,
        deleteTraveler,
      })}
      data={travelerList}
      count={tableProps.count}
      actualPage={tableProps.page}
      limit={tableProps.limit}
      onChangePage={onChangePage}
      onChangeLimit={onChangeLimit}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}

export default TravelerTable
