export const rules = {
  home: {
    menu: {
      id: 1,
      path: '/home',
      icon: 'fas fa-home',
      title: 'Home',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  customers: {
    menu: {
      id: 2,
      path: '/customers',
      icon: 'fas fa-wallet',
      title: 'Clientes',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  sales: {
    menu: {
      id: 3,
      path: '/sales',
      icon: 'fas fa-business-time',
      title: 'Vendas',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  services: {
    menu: {
      id: 4,
      path: '/services',
      icon: 'fas fa-concierge-bell',
      title: 'Tipo de Serviços',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
  proposals: {
    menu: {
      id: 5,
      path: '/proposals',
      icon: 'fas fa-city',
      title: 'Propostas',
      exact: true,
      menuSubMenu: [],
      childs: [],
    },
  },
}
