import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import { getDateFormated } from '~/functions/Date'
import { useSelector } from 'react-redux'

export default function RadarDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const defaultValue = {
    ...location.state.traveler,
    rg: location.state.traveler.rg,
    profession: location.state.traveler.profession,
    address: location.state.traveler.address,
    city: location.state.traveler.city,
    state: location.state.traveler.state,
    country: location.state.traveler.country,
    belongingAgency: location.state.traveler.belongingAgency,
    birthdayDate: getDateFormated(
      location.state.traveler.birthdayDate ?? undefined,
      'YYYY-MM-DD',
    ),
    perfil: location.state.traveler.perfil,
  }

  async function onValidate(data) {
    if (!data.email && !data.phone) {
      toast.info('Informe email ou telefone.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('id', location.state.traveler.id)
      formData.append('familyName', data.familyName)
      formData.append('fullName', data.fullName)
      formData.append('email', data.email ?? '')
      formData.append('phone', data.phone ?? '')
      formData.append('rg', data.rg ?? '')
      formData.append('profession', data.profession ?? '')
      formData.append('address', data.address ?? '')
      formData.append('city', data.city ?? '')
      formData.append('state', data.state ?? '')
      formData.append('country', data.country ?? '')
      formData.append('cpf', data.cpf ?? '')
      formData.append('birthDay', data.birthDay ?? '0001-01-01')
      formData.append('vip', data.vip ? true : false)
      formData.append('username', data.username ?? '')
      formData.append('accessCode', data.accessCode ?? '')
      formData.append('belongingAgency', data.belongingAgency ?? '')
      if (data.perfil) {
        formData.append('perfil', data.perfil)
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
        formData,
      )

      toast.success('Viajante editado com sucesso!')

      return navigate(-1)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  // async function onDelete() {
  //   if (window.confirm('Você tem certeza que deseja excluir esse viajante?')) {
  //     await axiosApis.delete(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/${location.state.traveler.id}`,
  //     )

  //     toast.success(`Viajante excluído com sucesso!`)

  //     return navigate(-1)
  //   }
  // }

  const toOperator = [
    {
      type: 'text',
      name: 'familyName',
      label: 'Nome de Preferência',
      placeholder: 'Nome de Preferência',
      required: true,
      maxLength: 150,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome Completo',
      placeholder: 'Nome Completo',
      required: true,
      maxLength: 150,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      placeholder: 'E-mail',
      maxLength: 150,
    },
    {
      type: 'phone',
      name: 'phone',
      label: 'Telefone',
      placeholder: 'Telefone',
    },
    {
      type: 'text',
      name: 'belongingAgency',
      label: 'Agência',
      placeholder: 'Agência',
      required: true,
    },
    {
      type: 'text',
      name: 'rg',
      label: 'RG/CNH',
      placeholder: 'RG/CNH',
      required: false,
    },
    {
      type: 'text',
      name: 'cpf',
      label: 'CPF/CNPJ',
      placeholder: 'CPF/CNPJ',
      maxLength: 150,
    },
    {
      type: 'date',
      name: 'birthdayDate',
      label: 'Aniversário',
    },
    {
      type: 'text',
      name: 'profession',
      label: 'Profissão',
      placeholder: 'Profissão',
      required: false,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      placeholder: 'Endereço',
      required: false,
    },
    {
      type: 'text',
      name: 'city',
      label: 'Cidade',
      placeholder: 'Cidade',
      required: false,
    },
    {
      type: 'text',
      name: 'state',
      label: 'Estado',
      placeholder: 'Estado',
      required: false,
    },
    {
      type: 'text',
      name: 'country',
      label: 'País',
      placeholder: 'País',
      required: false,
    },
    {
      type: 'text',
      name: 'username',
      label: 'Usuário',
      placeholder: 'Usuário',
      disabled: true,
    },
    {
      type: 'text',
      name: 'accessCode',
      label: 'Código de acesso',
      placeholder: 'Código de acesso',
    },
    {
      type: 'textarea',
      name: 'perfil',
      label: 'Perfil do Viajante',
      placeholder: 'Perfil do Viajante',
      required: false,
    },
    {
      type: 'check',
      name: 'vip',
      label: 'Viajante VIP',
    },
  ]

  const toAgency = [
    {
      type: 'text',
      name: 'familyName',
      label: 'Nome de Preferência',
      placeholder: 'Nome de Preferência',
      required: true,
      maxLength: 150,
      disabled: true,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome Completo',
      placeholder: 'Nome Completo',
      required: true,
      maxLength: 150,
      disabled: true,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      placeholder: 'E-mail',
      maxLength: 150,
      disabled: true,
    },
    {
      type: 'phone',
      name: 'phone',
      label: 'Telefone',
      placeholder: 'Telefone',
      disabled: true,
    },
    {
      type: 'text',
      name: 'rg',
      label: 'RG/CNH',
      placeholder: 'RG/CNH',
      required: false,
      disabled: true,
    },
    {
      type: 'text',
      name: 'cpf',
      label: 'CPF/CNPJ',
      maxLength: 150,
      disabled: true,
    },
    {
      type: 'date',
      name: 'birthdayDate',
      label: 'Aniversário',
      disabled: true,
    },
    {
      type: 'text',
      name: 'profession',
      label: 'Profissão',
      placeholder: 'Profissão',
      required: false,
      disabled: true,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      placeholder: 'Endereço',
      required: false,
      disabled: true,
    },
    {
      type: 'text',
      name: 'city',
      label: 'Cidade',
      placeholder: 'Cidade',
      required: false,
      disabled: true,
    },
    {
      type: 'text',
      name: 'state',
      label: 'Estado',
      placeholder: 'Estado',
      required: false,
      disabled: true,
    },
    {
      type: 'text',
      name: 'country',
      label: 'País',
      placeholder: 'País',
      required: false,
      disabled: true,
    },
    {
      type: 'textarea',
      name: 'perfil',
      label: 'Perfil do Viajante',
      placeholder: 'Perfil do Viajante',
      required: false,
      disabled: true,
    },
  ]

  return (
    <Container>
      <PageTitle title={defaultValue.familyName ?? defaultValue.fullName} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Container>
            <DefaultForm
              onValidate={onValidate}
              defaultValue={defaultValue}
              inputs={user.isOperator == true ? toOperator : toAgency}
            />
          </Container>
        </PanelBody>
      </Panel>
    </Container>
  )
}
