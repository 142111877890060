import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'

export default function ReportRankingAgentes() {
  const { axiosApis } = GetAxios()
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [agentsOriginal, setAgentsOriginal] = useState([])
  const [agents, setAgents] = useState([])
  const [filters, setFilters] = useState({})

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      await onSubmit()
    }
    loadSource()
    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    setLoading(true)

    const response = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/quotation/report/ranking-agent`,
      { params: data },
    )
    setAgents(response.data)

    setAgentsOriginal(response.data)

    return setLoading(false)
  }

  const setInputField = (fieldName, value) => {
    setFilters({
      ...filters,
      [fieldName]: value,
    })
  }

  async function resetForm() {
    setFilters({})
  }

  async function submitForm() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agent/filter`,
        {
          params: filters,
        },
      )

      setAgents(data)

      return setLoading(false)
    } catch (error) {
      toast.info('Nenhum resultado encontrado.')

      return setLoading(false)
    }
  }

  async function onSearch(data) {
    if (data.agency || data.name || data.email) {
      setLoading(true)

      const newAgencys = agentsOriginal.filter(
        x =>
          (data.agency &&
            x.agency.name
              ?.toLowerCase()
              .includes(data.agency?.toLowerCase())) ||
          (data.name &&
            x.fullName?.toLowerCase().includes(data.name?.toLowerCase())) ||
          (data.email &&
            x.email?.toLowerCase().includes(data.email?.toLowerCase())),
      )

      setTimeout(() => {
        setAgents(newAgencys)

        setLoading(false)
      }, [500])
    }
  }

  async function onReset() {
    setLoading(true)

    setTimeout(() => {
      setAgents(agentsOriginal)

      setLoading(false)
    }, [500])
  }

  return (
    <Container>
      <h1 className='page-header'>Ranking de consultores de Viagens</h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'Nome_agent',
                label: 'Nome do consultor',
                placeholder: 'Nome do consultor',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'start',
                label: 'Data Inicial da Última Viagem',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Data Final da Última Viagem',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table data={agents} loading={loading} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
