import styled from 'styled-components'
import { Col } from 'reactstrap'

export const Container = styled.div`
  * {
    box-sizing: border-box;
  }

  p {
    margin-bottom: 4px;
  }
`

export const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const ColInputs = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding: 2px 8px;
`
