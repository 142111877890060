import { createGlobalStyle, keyframes } from 'styled-components'

import 'react-toastify/dist/ReactToastify.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'bootstrap-social/bootstrap-social.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'primereact/resources/themes/saga-orange/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

import '~/scss/styles.scss'
import '~/scss/plugins/plugins.css'

const loading = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export default createGlobalStyle`
  body {
    min-width: 320px;
  }

  * {
    font-family: 'Open Sans', sans-serif;
  }

  .loading {
    animation: ${loading} .6s linear infinite;
  }

  .disabled {
    cursor: not-allowed;
  }

  .RichEditor-root {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Open Sans', serif;
    font-size: 14px;
    padding: 10px;
  }

  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }

  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    padding: 0px !important;
    margin: 0px !important;
    margin-top: 8px !important;
  }

  .RichEditor-editor .public-DraftEditor-content {
    overflow: auto;
    height: 220px;
  }

  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }

  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Open Sans', 'Hoefler Text', 'Georgia', serif, sans-serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }

  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }

  .RichEditor-controls {
    font-family: 'Open Sans', 'Helvetica', serif, sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #6d7378;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #7f2ec2;
  }
`
