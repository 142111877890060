import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import LoadingContainer from '~/components/LoadingContainer'
import Dashboard from './Dashboard'
import NotFound from './NotFound'
import Print from './Print'
import useTheme from '~/hooks/useTheme'

export default function TravelResume() {
  const { axiosApis } = GetAxios()
  const params = useParams()
  const [travel, setTravel] = useState()
  const [travelImage, setTravelImage] = useState()
  const [partnerImage, setPartnerImage] = useState()
  const [agencyImage, setAgencyImage] = useState()
  const [loading, setLoading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [message, setMessage] = useState(
    'Algo deu errado! Tente novamente mais tarde.',
  )
  const [travelResume, setTravelResume] = useState()
  const [routes, setRoutes] = useState([])
  const [markers, setMarkers] = useState([])
  const [center, setCenter] = useState()
  const { updateFont } = useTheme()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        document.title = 'Roteiro do Viajante - Tripmee'

        setLoading(true)

        if (params.token) {
          const { data } = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/travelresume/${params.token}`,
          )

          setTravelResume(data.desc)

          const newDaybydays = []

          for (let i = 0; i < data.travelResumeItineraryRoadMaps.length; i++) {
            let image = null

            try {
              const responseImage = await axiosApis.get(
                `${process.env.REACT_APP_ENDPOINT_APIS}/travelresume/daybyday/image/${data.travelResumeItineraryRoadMaps[i].id}`,
              )

              image = responseImage.data.image
            } catch (e) {
              console.log(e)
            }

            const newDaybyday = {
              ...data.travelResumeItineraryRoadMaps[i],
              image: image,
            }

            newDaybydays.push(newDaybyday)
          }

          setTravel({
            ...data,
            travelResumeItineraryRoadMaps: newDaybydays,
          })

          if (data.agency.fontFamily && data.agency.fontFamilyUrl) {
            await updateFont(data.agency.fontFamily, data.agency.fontFamilyUrl)
          }
        }

        return setLoading(false)
      } catch (error) {
        if (error.response?.data) setMessage(error.response?.data)

        return setLoading(false)
      }
    }

    loadSource()
    // requestApiGetAgency()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        if (params.token) {
          const res = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/travelresume/travel/image/${params.token}`,
          )

          setTravelImage(res.data.image)
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        if (params.token) {
          const res = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/travelresume/partner/image/${params.token}`,
          )

          setPartnerImage(res.data.image)
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        if (params.token) {
          const res = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/travelresume/agency/image/${params.token}`,
          )

          setAgencyImage(res.data.image)
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        if (params.token) {
          const { data } = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/travelmap/markers/${params.token}`,
          )

          setMarkers(data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        if (params.token) {
          const { data } = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/travelmap/center/${params.token}`,
          )

          setCenter(data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        if (params.token) {
          const { data } = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/travelmap/routes/${params.token}`,
          )

          setRoutes(data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return function cleanup() {
      cancel = true

      setRoutes([])
    }
  }, [])

  const onHandlePrint = () => {
    setSpinnerLoading(true)
    setTimeout(() => {
      setIsPrinting(true)
      setSpinnerLoading(false)
    }, 2000)
  }

  const onHandleCancelPrint = () => setIsPrinting(false)

  return (
    <>
      {!loading && !travel && <NotFound message={message} />}
      <>
        {isPrinting && (
          <Print
            travel={travel}
            onHandleCancelPrint={onHandleCancelPrint}
            travelImage={travelImage}
            partnerImage={partnerImage}
            agencyImage={agencyImage}
            nameAgency={travel.agency}
          />
        )}
        {!isPrinting && (
          <Dashboard
            nameAgency={travel?.agency}
            travel={travel}
            showPrint={spinnerLoading}
            onHandlePrint={onHandlePrint}
            travelImage={travelImage}
            partnerImage={partnerImage}
            agencyImage={agencyImage}
            token={params.token}
            markers={markers}
            center={center}
            routes={routes}
            travelResume={travelResume}
          />
        )}
      </>
    </>
  )
}
