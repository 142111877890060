import SkeletonLine from '~/components/Skeleton'
import { Message } from '../../Contacts/styles'
import DefaultMessage from '../Messages/DefaultMessage'
import styled from 'styled-components'
import { SkeletonTheme } from 'react-loading-skeleton'

export default function SkeletonMessages() {
  return (
    <SkeletonTheme baseColor='#c8c8c8' highlightColor='#c0c0c0'>
      <MessageSkeletonContainer toMe={true}>
        <Message>
          <SkeletonLine height={20} width={200} />
        </Message>
      </MessageSkeletonContainer>

      <MessageSkeletonContainer>
        <Message>
          <SkeletonLine height={20} width={200} />
        </Message>
      </MessageSkeletonContainer>

      <MessageSkeletonContainer toMe={true}>
        <Message>
          <SkeletonLine height={20} width={400} />
        </Message>
      </MessageSkeletonContainer>

      <MessageSkeletonContainer>
        <Message>
          <SkeletonLine height={20} width={400} />
        </Message>
      </MessageSkeletonContainer>

      <MessageSkeletonContainer toMe={true}>
        <Message>
          <SkeletonLine height={20} width={200} />
        </Message>
      </MessageSkeletonContainer>

      <MessageSkeletonContainer>
        <Message>
          <SkeletonLine height={20} width={400} />
        </Message>
      </MessageSkeletonContainer>
    </SkeletonTheme>
  )
}

export const MessageSkeletonContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.toMe ? 'flex-end' : 'flex-start')};
  margin-bottom: 15px;
`
