import Table from '~/components/Table'
import AnalyticsContainer from '../Container'

export default function AnalyticsTable({
  title = 'Tabela',
  data = [],
  columns = [],
  loading = false,
}) {
  return (
    <AnalyticsContainer title={title}>
      <Table data={data} columns={columns} loading={loading} />
    </AnalyticsContainer>
  )
}
