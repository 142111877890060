import { Outlet, Route } from 'react-router-dom'
import DefaultLayout from '~/pages/__layouts__/DefaultLayout'
import SalesList from './List'

export default function SalesPage() {
  return (
    <Route
      path='/sales'
      element={
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      }
    >
      <Route index element={<SalesList />} />
    </Route>
  )
}
