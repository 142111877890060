import ReactTable from '~/components/Table'
import { useNavigate } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { useSelector } from 'react-redux'
import Inputs from './inputs'
import useModal from '~/hooks/useModal'
import ShowLink from '../showLink'
import { useState } from 'react'
import { toast } from 'react-toastify'

const Table = ({ travelList, loading, handleSubmitDelete }) => {
  const { axiosApis } = GetAxios()
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate()
  const { showModal, hideModal } = useModal()
  const [loadingLink, setLoadingLink] = useState(false)
  const [travelLink, setTravelLink] = useState('www.google.com')

  async function generateLink(travelId) {
    try {
      const { data } = await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelresume`,
        { travelId: travelId },
      )

      window.open(data.link)
    } catch (error) {
      console.log(error)
    }
  }

  async function showLinkModal(e) {
    showModal({
      title: 'Link de pesquisa',
      body: () => <ShowLink travelId={e} />,
      width: '45%',
    })
  }

  async function showDetails(e) {
    navigate('/travels/details', { state: { id: e } })
  }

  return (
    <ReactTable
      columns={Inputs(
        user.isOperator,
        generateLink,
        showDetails,
        handleSubmitDelete,
        showLinkModal,
      )}
      data={travelList}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}

export default Table
