import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { Form } from '@unform/web'
import { GetAxios } from '~/services/api'
import { Panel, PanelHeader, PanelBody } from '~/components/Panel'
import { toast } from 'react-toastify'
import Input from '~/components/Input'
import { ResetButton, SaveButton } from '~/components/Button/styles'
import { Container, RightCol } from './styles'
import Select from '~/components/Select'

export default function EditDocument() {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState({ value: '', label: '' })
  const [travelers, setTravelers] = useState([])

  const initialState = {
    title: '',
    desc: '',
    expiresIn: '',
  }

  const [documentToEdit, setDocumentToEdit] = useState(initialState)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
        )

        setTravelers(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function EditSubmit() {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('title', documentToEdit.title)
      formData.append('desc', documentToEdit.desc)
      formData.append('type', documentToEdit.type)
      formData.append('expiresIn', documentToEdit.expiresIn)
      // formData.append('travelid', travel.id)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveldocs/editdoc/${location.state.traveler.id}`,
        formData,
      )
      setLoading(false)

      toast.success('Documento editado com sucesso!')

      navigate(-1)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível editar o documento.')
    }
  }

  return (
    <Panel>
      <PanelHeader title={'Editar Documento'} noButton />
      <PanelBody>
        <Container>
          <Form ref={formRef} onSubmit={EditSubmit}>
            <Row>
              <Col sm={12} md={12} lg={6} xl={6}>
                <Input
                  disabled={false}
                  name={'title'}
                  label={'Título'}
                  value={documentToEdit.title}
                  maxLength={100}
                  onChange={e =>
                    setDocumentToEdit({
                      ...documentToEdit,
                      title: e.target.value,
                    })
                  }
                />
              </Col>

              <Col sm={12} md={12} lg={6} xl={6}>
                <Input
                  disabled={false}
                  name={'desc'}
                  label={'Descrição'}
                  value={documentToEdit.desc}
                  maxLength={100}
                  onChange={e =>
                    setDocumentToEdit({
                      ...documentToEdit,
                      desc: e.target.value,
                    })
                  }
                />
              </Col>

              <Col sm={12} md={12} lg={4} xl={4}>
                <Select
                  label={'Tipo de Documento'}
                  name={'type'}
                  options={[
                    {
                      label: 'Viagem',
                      value: 'travel',
                    },
                    {
                      label: 'Viajante',
                      value: 'traveler',
                    },
                  ]}
                  onChange={e => setSelectedType(e.target.value)}
                  value={selectedType}
                  placeholder={'Selecione...'}
                  isSearchable
                />
              </Col>

              {selectedType.value === 'traveler' && (
                <>
                  <Col sm={12} md={12} lg={4} xl={4}>
                    <Select
                      label={'Viajante'}
                      name={'travelers'}
                      options={travelers}
                      placeholder={'Selecione...'}
                      onChange={e =>
                        setDocumentToEdit('travelers', e.target.value)
                      }
                      isSearchable
                    />
                  </Col>

                  <Col sm={12} md={12} lg={4} xl={4}>
                    <Input
                      disabled={false}
                      name={'expiration'}
                      label={'Data de ExpiresIn'}
                      value={documentToEdit.expiresIn}
                      onChange={e =>
                        setDocumentToEdit('expiresIn', e.target.value)
                      }
                    />
                  </Col>
                </>
              )}
              <Col sm={12} md={12} lg={12} xl={12}>
                <Input
                  name={'fileDocument'}
                  label={'Arquivo *'}
                  type='file'
                  onChange={e => setDocumentToEdit(e.target.files[0])}
                  disabled={false}
                  accept='image/*, application/pdf'
                  required
                />
              </Col>
            </Row>
            <Row>
              {/* <LeftCol>
                <DeleteButton
                  onClick={() => OnDeleteclick(documentToEdit.id)}
                  type='button'
                >
                  Excluir
                </DeleteButton>
              </LeftCol> */}
              <RightCol>
                <ResetButton onClick={() => navigate(-1)} type='reset'>
                  Cancelar
                </ResetButton>
                <SaveButton disabled={loading} type='submit'>
                  Salvar
                </SaveButton>
              </RightCol>
            </Row>
          </Form>
        </Container>
      </PanelBody>
    </Panel>
  )
}
