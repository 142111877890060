import ReactTable from '~/components/Table'
import { LinkEdit } from '~/components/Link/styles'

export default function Table({ categories, loading }) {
  return (
    <ReactTable
      columns={[
        {
          Header: 'Nome da Categoria',
          accessor: 'desc',
          width: 250,
        },
        {
          Header: '',
          accessor: 'detalhes-categoria',
          Cell: props => {
            return (
              <LinkEdit to={'details'} state={{ category: props.row.original }}>
                Detalhes
              </LinkEdit>
            )
          },
          width: 80,
        },
      ]}
      data={categories}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
