import styled from 'styled-components'
import { Col, Row } from 'reactstrap'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import SaleReleaseForm from '../components/SaleReleaseForm'

export default function SalesReleaseCreate() {
  return (
    <Container>
      <PageTitle head='h1' title={'Novo lançamento'} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <SaleReleaseForm />
        </PanelBody>
      </Panel>
    </Container>
  )
}

const Container = styled.div``

export const SectionTitle = styled.h6`
  border-bottom: 1px solid #cecece;
`

export const CorporativeRow = styled(Row)`
  border-bottom: 1px solid #cecece;
`

export const IssuingCol = styled(Col)``
export const PromoterCol = styled(Col)``
export const ManagerCol = styled(Col)``
export const ComissionRow = styled(Row)``
export const FooterRow = styled(Row)``
