import { useEffect, useState } from 'react'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import Kanban from '~/components/Kanban'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import AddParticipantForm from './AddParticipantForm'
import DetailsParticipantForm from './DetailsParticipantForm'

export default function ParticipantKanban() {
  const { axiosApis } = GetAxios()
  const [data, setData] = useState([])
  const [dataOriginal, setDataOriginal] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [modalType, setModalType] = useState('create')

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const newData = await getSource()

        setData(newData)

        setDataOriginal(newData)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = false

      setData([])
    }
  }, [])

  async function getSource() {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban`,
      )

      setLoading(false)

      return res.data
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }

  async function callbackOnAdd({ item: stage }) {
    setModalType('create')

    setModalData({ stage })

    setShowModal(true)
  }

  async function callbackOnMove({ item, newStage, oldStage }) {
    try {
      if (newStage.label !== oldStage.label) {
        setLoading(true)

        await axiosApis.put(
          `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/stage`,
          {
            id: item.id,
            stage: newStage.key,
          },
        )

        const newData = await getSource()

        setDataOriginal(newData)

        setTimeout(() => {
          setLoading(false)
        }, 500)
      }

      return true
    } catch (error) {
      setTimeout(() => setLoading(false), 1000)

      return false
    }
  }

  async function callbackOnDetails(item) {
    try {
      setLoading(true)

      setModalData({
        details: item,
      })

      setModalType('details')

      setShowModal(true)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function callbackOnClose() {
    setModalData({})

    setShowModal(false)

    const newData = await getSource()

    setData(newData)

    setDataOriginal(newData)
  }

  async function onClose() {
    setModalData({})

    setShowModal(false)

    const newData = await getSource()

    setData(newData)

    setDataOriginal(newData)
  }

  async function callbackOnDelete(item) {
    try {
      if (
        window.confirm(
          'Deseja remover o card do Kanban? (Obs: Isso não excluirá a inscrição, somente o card)',
        )
      ) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/${item.id}`,
        )
      }

      const newData = await getSource()

      setData(newData)

      setDataOriginal(newData)
    } catch (error) {
      console.log(error)
    }
  }

  function onSubmit(e) {
    try {
      setLoading(true)

      let newData = [...dataOriginal]

      if (e && e.fullName) {
        newData = newData.reduce((acc, value) => {
          const obj = {
            key: value.key,
            label: value.label,
            childs: value.childs.filter(value2 =>
              value2.title
                .toLowerCase()
                .replaceAll('á', 'a')
                .replaceAll('é', 'e')
                .replaceAll('í', 'i')
                .replaceAll('ó', 'o')
                .replaceAll('ú', 'ó')
                .replaceAll('â', 'a')
                .replaceAll('ê', 'e')
                .replaceAll('ô', 'o')
                .replaceAll('ã', 'a')
                .replaceAll('õ', 'o')
                .replaceAll('ç', 'c')
                .includes(
                  e.fullName
                    .toLowerCase()
                    .replaceAll('á', 'a')
                    .replaceAll('é', 'e')
                    .replaceAll('í', 'i')
                    .replaceAll('ó', 'o')
                    .replaceAll('ú', 'ó')
                    .replaceAll('â', 'a')
                    .replaceAll('ê', 'e')
                    .replaceAll('ô', 'o')
                    .replaceAll('ã', 'a')
                    .replaceAll('õ', 'o')
                    .replaceAll('ç', 'c'),
                ),
            ),
          }

          acc.push(obj)

          return acc
        }, [])
      }

      setData(newData)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle
        noButton
        title={'Viagens Pedagógicas - Quadro de Inscrições'}
      />

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={async () => {
              const newData = await getSource()

              setData(newData)

              setDataOriginal(newData)
            }}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                name: 'event',
                label: 'Viagem Pedagógica',
                placeholder: 'Selecione...',
                options: [
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                ],
                required: true,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'fullName',
                label: 'Nome do viajante',
                placeholder: 'Nome do viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'school',
                label: 'Nome da Escola',
                placeholder: 'Nome da Escola',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Kanban
            data={data}
            callbackOnMove={callbackOnMove}
            callbackOnAdd={callbackOnAdd}
            callbackOnDelete={callbackOnDelete}
            callbackOnDetails={callbackOnDetails}
            callbackOnClose={callbackOnClose}
            showModal={showModal}
            altTitle={'Data de inscrição'}
            altDateFormat={'DD/MM/YYYY HH:mm:ss'}
            showAltDateLabel={false}
            modalContent={
              modalType == 'create' ? (
                <AddParticipantForm modalData={modalData} onClose={onClose} />
              ) : (
                <DetailsParticipantForm
                  modalData={modalData}
                  onClose={onClose}
                />
              )
            }
            modalTitle={
              modalData.stage
                ? `Adicionar Incrição em "${modalData.stage.label}"`
                : modalData.details
                ? modalData.details.title
                : ''
            }
            loading={loading}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

const Container = styled.div`
  width: 100;
`
