import { useState } from 'react'
import { Tooltip } from 'reactstrap'

export default function WrapperTooltip({ target, text, ...rest }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <Tooltip isOpen={tooltipOpen} target={target} toggle={toggle} {...rest}>
      {text}
    </Tooltip>
  )
}
