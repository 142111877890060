import { useState, useEffect, useContext } from 'react'
import context from '~/context/createContext'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import { toast } from 'react-toastify'
import Table from './Table'
import { useLocation } from 'react-router-dom'
import IconButton from '~/components/IconButton'

export default function ReportDocuments() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [traveler, setTraveler] = useState([])
  const [pageFilters, setPageFilters] = useState({})
  const location = useLocation()

  const { globalFilters, resetGlobalFilters } = useContext(context)

  const onValidate = data => {
    if (data.dateEnd) {
      if (data.dateStart) {
        return true
      } else {
        toast.info('Prencha a data Inical')
        return false
      }
    }

    if (data.dateStart) {
      if (data.dateEnd) {
        return true
      } else {
        toast.info('Prencha a data final')
        return false
      }
    }

    return true
  }

  useEffect(() => {
    if (location.state?.preset) {
      setPageFilters({
        dateStart: globalFilters.dateStart,
        dateEnd: globalFilters.dateEnd,
      })
    } else {
      resetGlobalFilters()
      setPageFilters({
        dateStart: undefined,
        dateEnd: undefined,
      })
    }
  }, [globalFilters])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      if (location.state?.preset) {
        setPageFilters({
          dateStart: globalFilters.dateStart,
          dateEnd: globalFilters.dateEnd,
        })
        await onSubmit({
          dateStart: globalFilters.dateStart,
          dateEnd: globalFilters.dateEnd,
        })
      } else {
        resetGlobalFilters()
        setPageFilters({
          dateStart: undefined,
          dateEnd: undefined,
        })
        await onSubmit({
          dateStart: undefined,
          dateEnd: undefined,
        })
      }
    }
    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    setLoading(true)

    const formData = new FormData()

    if (data) {
      if (data.name_viajante) {
        formData.append('name_viajante', data.name_viajante)
      }

      if (data.dateStart && data.dateEnd) {
        formData.append('dateStart', data.dateStart)
        formData.append('dateEnd', data.dateEnd)
      }
    }

    const response = await axiosApis.get(
      `${process.env.REACT_APP_ENDPOINT_APIS}/userdoc/userswithdocsabouttoexpiret/`,
      { params: data },
    )

    setTraveler(response.data)
    setLoading(false)
  }

  return (
    <Container>
      <h1 className='page-header'>
        Documentos Expirados
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'A Tela fornece as informações de todos os documentos que estão com data perto de expiração.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            onValidate={onValidate}
            callbackSubmit={onSubmit}
            callbackReset={() => resetGlobalFilters()}
            defaultValue={pageFilters}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'name_viajante',
                label: 'Nome do Viajante',
                placeholder: 'Nome do Viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Data Inicial',
                placeholder: '',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Data Final',
                placeholder: '',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table data={traveler} loading={loading} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
