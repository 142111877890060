import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { RegisterNewButton2 } from '~/components/Button/styles'
import DefaultForm from '~/components/DefaultForm'
import IconButton from '~/components/IconButton'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import ReactTable from '~/components/Table'
import { getDateFormated } from '~/functions/Date'
import { axiosApis } from '~/services/api'

export default function ShowDependents({ id }) {
  const [dependents, setDependents] = useState([])
  const [loading, setLoading] = useState(false)
  const [render, setRender] = useState('list')
  const [dependentDataEdit, setDependentDataEdit] = useState({})

  useEffect(() => {
    getDependents()
  }, [])

  const getDependents = async () => {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(`customer/get-dependents/${id}`)

      setDependents(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onDelete = async dependentId => {
    try {
      setLoading(true)

      const body = {
        id: id,
        dependentId: dependentId,
        returnRemainingItens: true,
      }

      const { data } = await axiosApis.put(`customer/remove-dependents`, body)

      toast.success('Dependente removido com sucesso.')

      setDependents(data)

      setRender('list')

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onUpdate = async data => {
    try {
      setLoading(true)

      const formData = new FormData()

      if (data?.id) formData.append('id', data.id)

      if (data?.fullName) formData.append('fullName', data.fullName)

      if (data?.email) formData.append('email', data.email)

      if (data?.phone) formData.append('phone', data?.phone)

      if (data?.birthday != 'Data inválida' && data?.birthday)
        formData.append('birthdayDate', data.birthday)

      if (data?.degreeOfKinship?.label)
        formData.append('degreeOfKinship', data?.degreeOfKinship?.label)

      if (data?.passportNumber)
        formData.append('passportNumber', data?.passportNumber)

      if (data?.visaNumber) formData.append('visaNumber', data?.visaNumber)

      if (data?.visaType) formData.append('visaType', data?.visaType)

      if (data?.cpfCnpj) formData.append('cpfCnpj', data?.cpfCnpj)

      await axiosApis.put(`/relationship/edit`, formData)

      await getDependents()

      toast.success('Dependente atualizado com sucesso.')

      setDependentDataEdit({})

      setRender('list')

      setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)

        return setLoading(false)
      }

      return setLoading(false)
    }
  }

  const onSubmit = async data => {
    try {
      setLoading(true)

      const body = {
        ...data,
        customerId: id,
        degreeOfKinship: data?.degreeOfKinship?.label,
      }

      const response = await axiosApis.post(`customer/create-dependent`, body)

      setDependents(response.data)

      toast.success('Dependente criado com sucesso.')

      setRender('list')

      setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)

        return setLoading(false)
      }

      setLoading(false)
    }
  }

  const onAdd = async data => {
    try {
      setLoading(true)

      const body = {
        id: id,
        dependentsId: data?.dependents?.map(dependent => dependent.value),
      }

      await axiosApis.put(`customer/add-dependents`, body)

      await getDependents()
      toast.success('Dependente adicionado com sucesso.')
      setRender('list')
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const dependentsScreens = {
    list: (
      <Panel>
        <PanelHeader noButton>
          <div>
            <RegisterNewButton2 onClick={() => setRender('search')}>
              Adicionar dependente
            </RegisterNewButton2>
          </div>
        </PanelHeader>

        <PanelBody>
          <ReactTable
            data={dependents}
            columns={[
              {
                Header: 'Nome do dependente',
                accessor: 'fullName',
                width: 150,
              },
              {
                Header: 'E-mail',
                accessor: 'email',
                width: 175,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 125,
              },
              {
                Header: 'Data de nascimento',
                accessor: 'birthday',
                width: 130,
              },
              {
                Header: 'Cpf',
                accessor: 'cpfCnpj',
                width: 125,
              },
              {
                Header: 'Parentesco',
                accessor: 'degreeOfKinship',
                width: 125,
              },
              {
                Header: 'N° do passaporte',
                accessor: 'passportNumber',
                width: 125,
              },
              {
                Header: 'N° do visto',
                accessor: 'visaNumber',
                width: 125,
              },
              {
                Header: 'Tipo do visto',
                accessor: 'visaType',
                width: 100,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.original.id}
                      type={'detail'}
                      onClick={() => {
                        const dateSplit =
                          props?.row?.original?.birthday?.split('/')
                        const newDate = `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`
                        setRender('edit')
                        setDependentDataEdit({
                          ...props.row.original,
                          degreeOfKinship: {
                            label: props.row.original.degreeOfKinship,
                            value: props.row.original.degreeOfKinship,
                          },
                          birthday: getDateFormated(newDate, 'YYYY-MM-DD'),
                        })
                      }}
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'delete',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.original.id}
                      type={'trash'}
                      onClick={() => onDelete(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    ),
    search: (
      <DefaultForm
        callbackSubmit={onAdd}
        callbackCancel={() => setRender('list')}
        callbackReset={() => setRender('create')}
        resetLabel='Criar dependente manualmente'
        cancelLabel='Voltar a lista de dependentes'
        submitLabel='Adicionar'
        inputs={[
          {
            type: 'customerSelect',
            name: 'dependents',
            label: 'Dependents',
            placeholder: 'Selecione um cliente',
            isMulti: true,
            sm: 12,
            lg: 12,
          },
        ]}
      />
    ),
    create: (
      <DefaultForm
        callbackSubmit={onSubmit}
        callbackReset={() => {}}
        callbackCancel={() => setRender('list')}
        cancelLabel='Voltar'
        resetLabel='Limpar'
        submitLabel='Criar'
        inputs={[
          {
            type: 'text',
            name: 'fullName',
            label: 'Nome',
            sm: 12,
            lg: 4,
          },
          {
            type: 'email',
            name: 'email',
            label: 'E-mail',
            sm: 12,
            lg: 2,
          },
          {
            type: 'phone',
            name: 'phone',
            label: 'Telefone',
            sm: 12,
            lg: 2,
          },
          {
            type: 'date',
            name: 'birthday',
            label: 'Data de nascimento',
            sm: 12,
            lg: 2,
          },
          {
            type: 'select',
            name: 'degreeOfKinship',
            label: 'Grau de parentesco',
            options: [
              { label: 'Avô', value: 'Avô' },
              { label: 'Avó', value: 'Avó' },
              { label: 'Neto(a)', value: 'Neto(a)' },
              { label: 'Pai', value: 'Pai' },
              { label: 'Mãe', value: 'Mãe' },
              { label: 'Filho(a)', value: 'Filho(a)' },
              { label: 'Irmão', value: 'Irmão' },
              { label: 'Irmã', value: 'Irmã' },
              { label: 'Tio(a)', value: 'Tio(a)' },
              { label: 'Sobrinho(a)', value: 'Sobrinho(a)' },
              { label: 'Primo(a)', value: 'Primo(a)' },
              { label: 'Cônjuge(a)', value: 'Cônjuge(a)' },
            ],
            sm: 12,
            lg: 2,
          },
          {
            type: 'text',
            name: 'cpfCnpj',
            label: 'Cpf',
            sm: 12,
            lg: 2,
          },
          {
            type: 'text',
            name: 'passportNumber',
            label: 'Número do passaporte',
            sm: 12,
            lg: 4,
          },
          {
            type: 'text',
            name: 'visaNumber',
            label: 'Número do visto',
            sm: 12,
            lg: 4,
          },
          {
            type: 'text',
            name: 'visaType',
            label: 'Tipo do visto',
            sm: 12,
            lg: 2,
          },
        ]}
      />
    ),
    edit: (
      <DefaultForm
        callbackSubmit={onUpdate}
        callbackCancel={() => {
          setRender('list')
          setDependentDataEdit({})
        }}
        submitLabel='Salvar'
        cancelLabel='Voltar'
        defaultValue={dependentDataEdit}
        inputs={[
          {
            type: 'text',
            name: 'fullName',
            label: 'Nome',
            sm: 12,
            lg: 4,
          },
          {
            type: 'email',
            name: 'email',
            label: 'E-mail',
            sm: 12,
            lg: 2,
          },
          {
            type: 'phone',
            name: 'phone',
            label: 'Telefone',
            sm: 12,
            lg: 2,
          },
          {
            type: 'date',
            name: 'birthday',
            label: 'Data de nascimento',
            sm: 12,
            lg: 2,
          },
          {
            type: 'select',
            name: 'degreeOfKinship',
            label: 'Grau de parentesco',
            options: [
              { label: 'Avô', value: 'Avô' },
              { label: 'Avó', value: 'Avó' },
              { label: 'Neto(a)', value: 'Neto(a)' },
              { label: 'Pai', value: 'Pai' },
              { label: 'Mãe', value: 'Mãe' },
              { label: 'Filho(a)', value: 'Filho(a)' },
              { label: 'Irmão', value: 'Irmão' },
              { label: 'Irmã', value: 'Irmã' },
              { label: 'Tio(a)', value: 'Tio(a)' },
              { label: 'Sobrinho(a)', value: 'Sobrinho(a)' },
              { label: 'Primo(a)', value: 'Primo(a)' },
              { label: 'Cônjuge(a)', value: 'Cônjuge(a)' },
            ],
            sm: 12,
            lg: 2,
          },
          {
            type: 'text',
            name: 'cpfCnpj',
            label: 'Cpf',
            sm: 12,
            lg: 2,
          },
          {
            type: 'text',
            name: 'passportNumber',
            label: 'Número do passaporte',
            sm: 12,
            lg: 4,
          },
          {
            type: 'text',
            name: 'visaNumber',
            label: 'Número do visto',
            sm: 12,
            lg: 4,
          },
          {
            type: 'text',
            name: 'visaType',
            label: 'Tipo do visto',
            sm: 12,
            lg: 2,
          },
        ]}
      />
    ),
  }

  return <div style={{ width: '100%' }}>{dependentsScreens[render]}</div>
}
