import styled from 'styled-components'
import DestinysItem from './DestinysListItem'

export default function DestinysList({ destinys, nameAgency }) {
  return (
    <div className='a4-height no-break day-section'>
      {nameAgency.name === 'Wee Travel' && (
        <DestinysTitleWee>Destinos</DestinysTitleWee>
      )}
      {nameAgency.name !== 'Wee Travel' && (
        <DestinysTitle style={{ color: `${nameAgency.secondaryColor}` }}>
          Destinos
        </DestinysTitle>
      )}

      {destinys.map((e, i) => {
        return (
          <>
            <DestinysItem nameAgency={nameAgency} key={i} destiny={e} />
          </>
        )
      })}
    </div>
  )
}

const DestinysTitleWee = styled.h3`
  color: #d54a26;
  padding-left: 40px;
`

const DestinysTitle = styled.h3`
  /* color: ${props => props.theme.colors.secondary}; */
  padding-left: 40px;
`
