import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'
import context from '~/context/createContext'
import LoadingContainer from '~/components/LoadingContainer'

export default function CheckIn() {
  const { axiosApis } = GetAxios()
  const { valueModal, showModalCheckIn, setShowModalCheckIn } =
    useContext(context)
  const [loading, setLoading] = useState(false)
  const [travelers, setTravelers] = useState([])

  useEffect(() => {
    requestApiGetAllTravelers()
  }, [])

  const requestApiGetAllTravelers = async () => {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/to/select`,
      )

      setTravelers(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  async function onUpdate(data) {
    try {
      const formData = new FormData()

      for (let i = 0; i < data.nameTraveler.length; i++) {
        formData.append('nameTraveler', data.nameTraveler[i].label)
      }
      formData.append('seat', data.seat)
      formData.append('milesProgram', data.milesProgram)

      formData.append('checkIn', true)
      formData.append('material', false)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/updateCheckInAndMaterialTravel/${valueModal.id}`,
        formData,
      )

      toast.success('Dados salvos com sucesso!')
      setShowModalCheckIn(!showModalCheckIn)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  return (
    <div>
      {loading && <LoadingContainer />}
      {!loading && (
        <DefaultForm
          // onValidate={onValidate}
          // defaultValue={location.state.service}
          callbackSubmit={onUpdate}
          defaultValue={valueModal}
          callbackReset={() => {
            return
          }}
          inputs={[
            {
              type: 'select',
              name: 'nameTraveler',
              label: 'Viajantes',
              options: travelers,
              isMulti: true,
              // placeholder: 'Nomes dos viajantes',
              // required: true,
              // maxLength: 150,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            {
              type: 'text',
              name: 'seat',
              label: 'Preferência de assento',
              placeholder: 'Preferência de assento',
              // required: true,
              maxLength: 150,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            {
              type: 'text',
              name: 'milesProgram',
              label: 'Programa de Milhagem',
              placeholder: 'Programa de Milhagem',
              // required: true,
              maxLength: 150,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
          ]}
        />
      )}
    </div>
  )
}
