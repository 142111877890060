import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { getDateFormated } from '~/functions/Date'
import { toast } from 'react-toastify'
import IconButton from '~/components/IconButton'
import RelationshipContactRegisteredContacts from './Contacts'

export default function RelationshipContactRegistered() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [leadStatusOptions, setLeadStatusOptions] = useState([])
  const [leadFormOptions, setLeadFormOptions] = useState([])
  const [agentOptions, setAgentOptions] = useState([])
  const [campaignOptions, setCampaignOptions] = useState([
    { label: 'Nenhum', value: null },
  ])

  useEffect(() => {
    loadSource()
  }, [])

  async function loadSource() {
    try {
      setLoading(true)

      const leadStatusResponse = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban`,
      )

      setLeadStatusOptions(leadStatusResponse.data)

      const leadFormResponse = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/customer/leadform`,
      )

      setLeadFormOptions(leadFormResponse.data)

      const agent = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
      )

      setAgentOptions(agent.data)

      const campaigns = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/campaign`,
      )

      setCampaignOptions(oldState => [...oldState, ...campaigns.data])

      await onSubmit()

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      let params = {}

      if (data) {
        params = {
          customerId: data?.customer ? data?.customer.value : undefined,
          statusLead: data?.statusLead ? data?.statusLead.label : undefined,
          contactForm: data?.contactForm ? data?.contactForm.label : undefined,
          agentId: data?.agent ? data?.agent.value : undefined,
          dateStart: data?.dateStart,
          dateEnd: data?.dateEnd,
          campaign: data?.campaign?.value ?? 0,
        }
      }

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/customerhistory`,
        { params },
      )

      setData(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function unarchiveClient(e) {
    try {
      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/customerhistory/unarchive/${e.id}`,
      )

      toast.success('Contato adicionado ao Fluxo de Atendimento.')

      await onSubmit()
    } catch (error) {
      toast.info('Não foi possível desarquivar o contato.')
    }
  }

  const onValidate = data => {
    if (data.dateEnd) {
      if (data.dateStart) {
        return true
      } else {
        toast.info('Preencha a data Inicial')
        return false
      }
    }

    if (data.dateStart) {
      if (data.dateEnd) {
        return true
      } else {
        toast.info('Preencha a data final')
        return false
      }
    }

    return true
  }

  return (
    <Container>
      <h1 className='page-header'>
        Histórico de Atendimentos
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Contem toda a trajetória do cliente no fluxo de atendimento detalhadamente.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                name: 'agent',
                label: 'Consultor',
                placeholder: 'Selecione...',
                options: agentOptions,
                isClearable: true,
                sm: 12,
                lg: 2,
              },
              {
                type: 'customerSelect',
                name: 'customer',
                label: 'Cliente',
                placeholder: 'Selecione...',
                isClearable: true,
                sm: 12,
                lg: 2,
              },
              {
                type: 'select',
                name: 'contactForm',
                label: 'Forma de contato',
                placeholder: 'Selecione...',
                options: leadFormOptions,
                isClearable: true,
                sm: 12,
                lg: 2,
              },
              {
                type: 'select',
                name: 'statusLead',
                label: 'Status do Lead',
                placeholder: 'Selecione...',
                options: leadStatusOptions,
                isClearable: true,
                sm: 12,
                lg: 2,
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Inicio',
                sm: 12,
                lg: 2,
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Fim',
                sm: 12,
                lg: 2,
              },
              {
                type: 'select',
                name: 'campaign',
                label: 'Campanha',
                options: campaignOptions,
                placeholder: 'Selecione...',
                isClearable: true,
                sm: 12,
                lg: 2,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table
            data={data}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            columns={[
              {
                Header: () => null,
                id: 'expander',
                Cell: ({ row }) => (
                  <span {...row.getToggleRowExpandedProps()}>
                    <i
                      className={`
            fas fa-caret-${row.isExpanded ? 'up' : 'down'}
          `}
                    />
                  </span>
                ),
                width: 40,
              },
              {
                Header: 'Cliente',
                accessor: 'fullName',
                width: 150,
              },
              {
                Header: 'E-mail',
                accessor: 'email',
                width: 170,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 100,
              },
              {
                Header: 'Consultor',
                accessor: 'agent',
                width: 120,
              },
              {
                Header: 'Forma de contato',
                accessor: 'contactForm',
                width: 120,
              },
              {
                Header: 'Fluxo de Atendimento',
                accessor: 'status',
                width: 230,
                Cell: props => {
                  const archived =
                    props.row.original.oculted && props.row.original.datearquiv

                  if (archived) {
                    return (
                      <div>
                        <b>
                          Arquivado &nbsp;
                          <IconButton
                            type={'share'}
                            tooltipOverride={
                              'Desarquivar e colocar no Fluxo de Atendimento'
                            }
                            onClick={() => unarchiveClient(props.row.original)}
                          />
                        </b>

                        <br />

                        <span>
                          {getDateFormated(
                            props.row.original.datearquiv,
                            'DD/MM/YYYY HH:mm',
                          )}
                        </span>
                      </div>
                    )
                  }

                  return (
                    <div style={{ margin: '8px 0' }}>
                      <b>{props.row.original.status}</b>

                      <br />

                      {props.row.original.dateNextAction && (
                        <>
                          <span>
                            {`Próxima ação em: ${getDateFormated(
                              props.row.original.dateNextAction,
                              'DD/MM/YYYY',
                            )}`}
                          </span>

                          {props.row.original.nextAction && (
                            <>
                              <br />

                              <span>{props.row.original.nextAction}</span>
                            </>
                          )}
                        </>
                      )}

                      {!props.row.original.dateNextAction && (
                        <span>
                          {`Último contato em: ${getDateFormated(
                            props.row.original.contactDate,
                            'DD/MM/YYYY',
                          )}`}
                        </span>
                      )}
                    </div>
                  )
                },
              },
              {
                Header: 'Motivo',
                accessor: 'motivation',
                width: 200,
              },
            ]}
            subComponent={props => (
              <RelationshipContactRegisteredContacts
                customer={props.row.original}
              />
            )}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
