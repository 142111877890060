import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { RegisterNewButton } from '~/components/Button/styles'
import DefaultForm from '~/components/DefaultForm'
import DivForHTML from '~/components/DivForHTML'
import Divisor from '~/components/Divisor'
import LoadingContainer from '~/components/LoadingContainer'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import { SpaceBetweenDiv } from '~/styles/divs'
import { DefaultLi, DefaultUl } from '~/styles/ul'

export default function Create() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [suggestionData, setSuggestionData] = useState()

  useEffect(() => {
    async function getExperienceCategory() {
      try {
        const res = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/getexperiencecategorytocreate`,
        )

        setCategoriesOptions(res.data)
      } catch (error) {
        return toast.error(
          'Não foi possível obter o categoria das experiências.',
        )
      }
    }

    getExperienceCategory()
  }, [])

  async function onValidate(data) {
    if (!data.city || !data.city.value) {
      toast.info('Selecione uma cidade.')

      return false
    }

    if (!data.category || !data.category.value) {
      toast.info('Selecione uma categoria.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('title', data.title)
      formData.append('desc', data.desc)
      formData.append('city', data.city.label ?? '')
      formData.append('cityId', data.city.value ?? '')
      formData.append('categoryId', data.category.value)
      formData.append('links', data.links ?? '[]')
      formData.append('image', data.image)

      if (data.flag) {
        formData.append('flag', data.flag)
      }

      if (data.address) {
        formData.append('address', data.address)
      }

      if (data.lat) {
        formData.append('lat', data.lat)
      }

      if (data.lng) {
        formData.append('lng', data.lng)
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience`,
        formData,
      )

      toast.success('Experiência criado com sucesso!')

      navigate(-1)
      setShowForm(false)

      setSuggestions([])

      setSuggestionData({})
    } catch (error) {
      console.log(error)
      return toast.info(
        `${
          error.response.data ||
          'Não foi possível criar experiência. Tente novamente!'
        } `,
      )
    }
  }

  async function onValidateSearchSuggestions(data) {
    if (!data.title || !data.city) {
      toast.info('Os campos cidade e nome do local são obrigatórios!')

      return false
    }

    return true
  }

  async function onSubmitSearchSuggestions(data) {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/get-suggestions`,
        {
          params: {
            cityId: data.city.value,
            title: data.title,
          },
        },
      )

      setLoading(false)

      setSuggestions(res.data)
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Nenhuma sugestão encontrada, tente preencher os dados manualmente.',
      )
    }
  }

  async function onCancelSearchSuggestions() {
    setShowForm(true)

    setSuggestionData({})
  }

  async function onSubmitSelectSuggestion(data) {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/get-suggestion-details/${data.placeId}`,
      )

      setSuggestionData({
        title: res.data.title,
        desc: res.data.desc,
        address: res.data.address,
        lat: res.data.lat,
        lng: res.data.lng,
        type: res.data.type,
        image: res.data.image,
        city: {
          label: data.city,
          value: data.cityId,
        },
        links: res.data.links ? JSON.stringify(res.data.links) : '[]',
      })

      setShowForm(true)

      setLoading(false)
    } catch (error) {
      setLoading(false)

      return toast.error(
        'Nenhuma sugestão encontrada, tente preencher os dados manualmente.',
      )
    }
  }

  return (
    <Container>
      <PageTitle
        title={'Experiências'}
        callbackOverride={showForm ? () => setShowForm(false) : undefined}
      />

      {/* {!showForm && ( */}
      <Panel>
        <PanelHeader noButton title='Cadastrar experiência' />

        <PanelBody>
          {showForm ? (
            <DefaultForm
              callbackSubmit={onSubmit}
              callbackReset={() => {
                return
              }}
              onValidate={onValidate}
              defaultValue={suggestionData ?? {}}
              cancelLabel={'Voltar'}
              resetLabel={'Limpar'}
              callbackCancel={() => {
                setSuggestionData({})

                setShowForm(false)
              }}
              inputs={[
                {
                  type: 'text',
                  name: 'title',
                  label: 'Título',
                  required: true,
                  sm: 12,
                  md: 12,
                  lg: 6,
                  xl: 6,
                  maxLength: 200,
                },
                {
                  type: 'city',
                  name: 'city',
                  label: 'Cidade',
                  placeholder: 'Pesquise por uma cidade...',
                  required: true,
                  sm: 12,
                  md: 12,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'select',
                  name: 'category',
                  label: 'Categoria',
                  placeholder: 'Selecione',
                  options: categoriesOptions,
                  required: true,
                  sm: 12,
                  md: 12,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'text',
                  name: 'flag',
                  label: 'Tags',
                  sm: 12,
                  md: 12,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'image',
                  name: 'image',
                  label: 'Imagem',
                  required: true,
                  sm: 12,
                  md: 12,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'keyvalue',
                  name: 'links',
                  label: 'Links úteis',
                  placeholder: 'Descrição',
                  subPlaceholder2: 'Link',
                  sm: 12,
                  md: 12,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'text',
                  name: 'address',
                  label: 'Endereço',
                  required: false,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12,
                  maxLength: 200,
                },
                {
                  type: 'html',
                  name: 'desc',
                  label: 'Descrição',
                  required: true,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12,
                },
              ]}
            />
          ) : (
            <>
              <DefaultForm
                callbackSubmit={onSubmitSearchSuggestions}
                callbackCancel={onCancelSearchSuggestions}
                onValidate={onValidateSearchSuggestions}
                callbackReset={() => {
                  setSuggestions([])

                  return
                }}
                submitLabel='Pesquisar'
                cancelLabel='Preencher dados manualmente'
                inputs={[
                  {
                    type: 'text',
                    name: 'title',
                    label:
                      'Pesquisar por: nome do lugar, restaurante, praia, etc.',
                    required: true,
                    sm: 12,
                    lg: 6,
                  },
                  {
                    type: 'city',
                    name: 'city',
                    label: 'Cidade',
                    placeholder: 'Pesquise por uma cidade...',
                    required: true,
                    sm: 12,
                    lg: 6,
                  },
                ]}
              />

              <Divisor border />

              {!loading && (
                <DefaultUl>
                  {suggestions.map((e, i) => {
                    return (
                      <DefaultLi key={i} style={{ height: '120px' }}>
                        <SpaceBetweenDiv
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            minHeight: '100%',
                          }}
                        >
                          <SpaceBetweenDiv>
                            <b>{e.title.toUpperCase().substring(0, 40)}...</b>

                            <RegisterNewButton
                              onClick={() => onSubmitSelectSuggestion(e)}
                              disabled={loading}
                            >
                              Escolher
                            </RegisterNewButton>
                          </SpaceBetweenDiv>

                          {e.desc && <DivForHTML text={e.desc} />}

                          {e.address && (
                            <p>
                              <b>Endereço: </b> &nbsp;
                              <span>{e.address}</span>
                            </p>
                          )}
                        </SpaceBetweenDiv>
                      </DefaultLi>
                    )
                  })}

                  {suggestions.length === 0 && (
                    <b style={{ padding: '8px' }}>
                      Pesquise acima por nome de lugares, restaurantes, praias,
                      etc.
                    </b>
                  )}
                </DefaultUl>
              )}
            </>
          )}

          {loading && (
            <LoadingContainer label={'Carregando...'} height={'200px'} />
          )}
        </PanelBody>
      </Panel>
    </Container>
  )
}
