import PropTypes from 'prop-types'
import { ModalProvider } from '~/context/modal'

export default function AuthLayout({ children }) {
  return (
    <>
      <ModalProvider>{children}</ModalProvider>
    </>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
}
