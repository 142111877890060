import { useEffect, useState, useContext } from 'react'
import context from '~/context/createContext'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import { useLocation } from 'react-router-dom'
import IconButton from '~/components/IconButton'

export default function ReportHotelCheckIn() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [hotels, setHotels] = useState([])
  const [pageFilters, setPageFilters] = useState({})
  const location = useLocation()

  const { globalFilters, resetGlobalFilters } = useContext(context)

  useEffect(() => {
    if (location.state?.preset) {
      setPageFilters({
        dateStart: globalFilters.dateStart,
        dateEnd: globalFilters.dateEnd,
      })
    } else {
      resetGlobalFilters()
      setPageFilters({
        dateStart: undefined,
        dateEnd: undefined,
      })
    }
  }, [globalFilters])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      if (location.state?.preset) {
        setPageFilters({
          dateStart: globalFilters.dateStart,
          dateEnd: globalFilters.dateEnd,
        })
        await onSubmit({
          dateStart: globalFilters.dateStart,
          dateEnd: globalFilters.dateEnd,
        })
      } else {
        resetGlobalFilters()
        setPageFilters({
          dateStart: undefined,
          dateEnd: undefined,
        })
        await onSubmit({
          dateStart: undefined,
          dateEnd: undefined,
        })
      }
    }
    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      if (data) {
        if (data.Nome_hotel) {
          formData.append('Nome_hotel', data.Nome_hotel)
        }
        if (data.name_viajante) {
          formData.append('name_viajante', data.name_viajante)
        }

        if (data.name_agent) {
          formData.append('name_agent', data.name_agent)
        }

        if (data.dateStart && data.dateEnd) {
          formData.append('dateStart', data.dateStart)
          formData.append('dateEnd', data.dateEnd)
        }
      }

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/quotation/report/hotel-checkin`,
        { params: data },
      )

      setHotels(response.data)

      return setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const onValidate = data => {
    if (data.dateEnd) {
      if (data.dateStart) {
        return true
      } else {
        toast.info('Preencha a data Inical')
        return false
      }
    }

    if (data.dateStart) {
      if (data.dateEnd) {
        return true
      } else {
        toast.info('Preencha a data final')
        return false
      }
    }

    return true
  }

  return (
    <Container>
      <h1 className='page-header'>
        Hospedagens
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'A Tela apresenta todas as hospedagens cadastradas nas viagens.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            onValidate={onValidate}
            callbackSubmit={onSubmit}
            defaultValue={pageFilters}
            callbackReset={() => resetGlobalFilters()}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'Nome_hotel',
                label: 'Nome do Hotel',
                placeholder: 'Nome do Hotel',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'name_viajante',
                label: 'Nome do Viajante',
                placeholder: 'Nome do Viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Data Inicial',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'Data Final',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'name_agent',
                label: 'Consultor',
                placeholder: 'Consultor',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table data={hotels} loading={loading} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
