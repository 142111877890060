'use strict'

const invariant = require('invariant')

export default function getSafeBodyFromHTML(html) {
  let doc
  let root = null
  if (!isOldInternetExplorer()) {
    doc = document.implementation.createHTMLDocument('foo')
    invariant(doc.documentElement, 'Missing doc.documentElement')
    doc.documentElement.innerHTML = html
    root = doc.getElementsByTagName('body')[0]
  }
  return root
}

// Verificar se a versão do Internet Explorer é menor que 9
function isOldInternetExplorer() {
  var ua = window.navigator.userAgent
  var msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // Internet Explorer 10 e inferior
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) < 9
  }
  var trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // Internet Explorer 11
    var rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) < 9
  }
  // Outro navegador ou versão do Internet Explorer 9 ou superior
  return false
}
