import styled from 'styled-components'

export default function SegmentNavigation({
  active,
  setActive,
  hasNewMessage,
}) {
  return (
    <>
      <Container>
        <NavItem
          active={active === 1 ? true : false}
          onClick={() => setActive(1)}
        >
          <Icon className='fas fa-list'></Icon>
        </NavItem>
        <NavItem
          active={active === 2 ? true : false}
          onClick={() => setActive(2)}
        >
          <Icon className='fas fa-hourglass-half'></Icon>
          {hasNewMessage && (
            <EndInformation>
              <NewMessageIcon className='fas fa-exclamation-circle'></NewMessageIcon>
            </EndInformation>
          )}
        </NavItem>
      </Container>
    </>
  )
}

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #caccce;
`
export const NavItem = styled.div`
  flex: 1;
  text-align: center;
  padding: 8px 0px;
  border-bottom: ${props =>
    props.active === true ? `3px solid ${props.theme.colors.primary}` : ''};
`
export const Icon = styled.i`
  color: #777777;
  font-size: 22px;
`
export const NewMessageIcon = styled.i`
  color: ${props => props.theme.colors.primary};
  position: absolute;
  bottom: 16px;
  background: white;
  border-radius: 12px;
`

export const Time = styled.p`
  font-size: 0.7em;
  margin-bottom: 0 !important;
`

export const EndInformation = styled.p`
  position: relative;
`
