import { useState } from 'react'

export default function UseAccordion() {
  const [openHistory, setOpenHistory] = useState('2')
  const [openService, setOpenService] = useState('3')

  const toggleHistory = id => {
    if (openHistory === id) {
      setOpenHistory()
    } else {
      setOpenHistory(id)
    }
  }

  const toggleService = id => {
    if (openService === id) {
      setOpenService()
    } else {
      setOpenService(id)
    }
  }
  return {
    toggleHistory,
    toggleService,
    openHistory,
    openService,
  }
}
