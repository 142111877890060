import { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import DivForHTML from '~/components/DivForHTML'
import LoadingContainer from '~/components/LoadingContainer'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import {
  getDateDiffLabel,
  getDateTimeFormated,
  getMoment,
} from '~/functions/Date'
import { GetAxios } from '~/services/api'

export default function RelationshipContactRegisteredContacts({ customer }) {
  const { axiosApis } = GetAxios()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/customerhistory/${customer.id}`,
        )

        setData(response.data)

        setLoading(false)
      } catch (error) {
        console.log(error)

        setLoading(false)
      }
    }

    getSource()
  }, [customer])

  const RenderContact = ({ contact }) => {
    return (
      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Row style={{ textAlign: 'left' }}>
            {contact.desc && contact.desc !== '<p><br></p>' && (
              <Col sm={12}>
                <DivForHTML text={contact.desc} />
              </Col>
            )}

            {contact.contactDate && (
              <Col sm={12} lg={3}>
                <b>Data do contato</b>

                <br />

                {getDateTimeFormated(contact.contactDate)}
              </Col>
            )}

            {contact.contactForm && (
              <Col sm={12} lg={3}>
                <b>Forma de contato</b>

                <br />

                {contact.contactForm}
              </Col>
            )}

            {contact.dateNextAction && (
              <Col sm={12} lg={3}>
                <b>Data de próxima ação</b>

                <br />

                {getDateTimeFormated(contact.dateNextAction)}
              </Col>
            )}

            {contact.nextAction && (
              <Col sm={12} lg={3}>
                <b>Próxima ação</b>

                <br />

                {contact.nextAction}
              </Col>
            )}

            {(contact.fileName1 || contact.fileName2) && (
              <>
                {contact.fileName1 && (
                  <Col sm={12} lg={6}>
                    <b>Arquivo 1</b>

                    <br />

                    <p>{contact.fileName1}</p>
                  </Col>
                )}

                {contact.fileName2 && (
                  <Col sm={13} lg={6}>
                    <b>Arquivo 2</b>

                    <br />

                    <p>{contact.fileName2}</p>
                  </Col>
                )}
              </>
            )}

            <Col sm={12}></Col>

            {contact.history.length > 0 && (
              <Col
                sm={12}
                lg={12}
                style={{
                  padding: '8px 10px',
                  opacity: 0.7,
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              >
                <p>
                  <b>Histórico</b>
                </p>

                <br />

                <div style={{ overflow: 'auto', maxHeight: '70vh' }}>
                  {contact.history.map((e, i) => (
                    <p key={i} style={{ marginBottom: '24px' }}>
                      <b>{e.author}</b>
                      <span>&nbsp;{e.desc}</span>

                      <br />

                      <sub>
                        <b>{getDateTimeFormated(e.date)}</b> - há{' '}
                        {getDateDiffLabel(e.date, getMoment())}
                      </sub>
                    </p>
                  ))}
                </div>
              </Col>
            )}
          </Row>
        </PanelBody>
      </Panel>
    )
  }

  return (
    <Container>
      {loading && <LoadingContainer height='300px' />}

      {!loading && data.map((e, i) => <RenderContact key={i} contact={e} />)}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
