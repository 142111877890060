import calendarPinIcon from './calendar-pin.png'
import calendarIcon from './calendar.png'
import carPinIcon from './car-pin.png'
import carIcon from './car.png'
import filePinIcon from './file-pin.png'
import fileIcon from './file.png'
import googleIcon from './google.png'
import heartPinIcon from './heart-pin.png'
import heartIcon from './heart.png'
import hotelPinIcon from './hotel-pin.png'
import hotelIcon from './hotel.png'
import mapPinIcon from './map-pin.png'
import mapIcon from './map.png'
import markerAltPinIcon from './marker-alt-pin.png'
import markerPinIcon from './marker-pin.png'
import otherPinIcon from './other-pin.png'
import otherIcon from './other.png'
import planePinIcon from './plane-pin.png'
import planeIcon from './plane.png'
import starPinIcon from './star-pin.png'
import walkingPinIcon from './walking-pin.png'
import walkingIcon from './walking.png'
import restaurantPinIcon from './restaurant-pin.png'
import restaurantIcon from './restaurant.png'

const allIcons = {
  calendarPinIcon,
  calendarIcon,
  carPinIcon,
  carIcon,
  filePinIcon,
  fileIcon,
  googleIcon,
  heartPinIcon,
  heartIcon,
  hotelPinIcon,
  hotelIcon,
  mapPinIcon,
  mapIcon,
  markerAltPinIcon,
  markerPinIcon,
  otherPinIcon,
  otherIcon,
  planePinIcon,
  planeIcon,
  starPinIcon,
  walkingPinIcon,
  walkingIcon,
  restaurantIcon,
  restaurantPinIcon,
}

function getIcon(name) {
  return allIcons[name + 'Icon']
}

function getPinIcon(name) {
  return allIcons[name + 'PinIcon']
}

function getIconClassName(type) {
  let name = 'fas fa-walking'

  switch (type) {
    case 'activity':
      name = 'fas fa-walking'
      break
    case 'air':
      name = 'fas fa-plane'
      break
    case 'hotel':
      name = 'fas fa-hotel'
      break
    case 'car':
      name = 'fas fa-car'
      break
    case 'other':
      name = 'fas fa-shopping-bag'
      break
    case 'train':
      name = 'fas fa-train'
      break
    case 'nautical':
      name = 'fas fa-anchor'
      break
    case 'vehicleRental':
      name = 'fas fa-car-side'
      break
    case 'ticket':
      name = 'fas fa-ticket-alt'
      break
  }

  return name
}

export { getIcon, getPinIcon, getIconClassName }

export default allIcons
