import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import { GetAxios } from '~/services/api'
import { useNavigate } from 'react-router-dom'
import PageTitle from '~/components/PageTitle'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function ReminderCreate({
  onlyForm = false,
  redirect = true,
  showSuccess = false,
  callback,
}) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const defaultValue = {
    shootingData: location.state?.shootingData,
  }

  const createReminder = async data => {
    try {
      setLoading(true)
      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/reminder`,
        data,
      )
      setLoading(false)

      if (redirect) {
        navigate(-1)
      }

      if (callback) {
        callback()
      }

      if (showSuccess) {
        toast.success('Lembrete criado com sucesso!')
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  if (onlyForm) {
    return (
      <DefaultForm
        callbackSubmit={createReminder}
        loading={loading}
        defaultValue={defaultValue}
        inputs={[
          {
            type: 'text',
            name: 'title',
            label: 'Titulo',
            sm: 8,
            md: 8,
            lg: 8,
            xl: 8,
          },
          {
            type: 'date',
            name: 'shootingData',
            label: 'Data de envio',
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Descrição',
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
        ]}
      />
    )
  }

  return (
    <Container>
      <PageTitle title={'Criar Tarefa'} />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={createReminder}
            loading={loading}
            defaultValue={defaultValue}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Titulo',
                sm: 8,
                md: 8,
                lg: 8,
                xl: 8,
              },
              {
                type: 'date',
                name: 'shootingData',
                label: 'Data de envio',
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },
              {
                type: 'textarea',
                name: 'description',
                label: 'Descrição',
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
