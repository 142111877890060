import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
// import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
import { apiRequest } from '~/apiService'

export default function useHoldingBackofficeSalesData({
  filters,
  handleProfile,
}) {
  const [loading, setLoading] = useState(false)
  // const dashboardsPlaceholders = getDashboardPlaceholders(3)
  const [dashboards, setDashboards] = useState([])
  const [chartData, setChartData] = useState()
  const [tableData, setTableData] = useState([])
  const [servicesDashboards, setServicesDashboards] = useState([])
  const [tableProductsData, setTableProductsData] = useState([])
  const [tableProvidersData, setTableProvidersData] = useState([])
  const [tableProvidersByBranchData, setTableProvidersByBranchData] = useState(
    [],
  )

  const params = {
    branchs: filters?.branchs ? filters.branchs.map(x => x.value) : [],
    start: filters?.start ?? undefined,
    end: filters?.end ?? undefined,
    requestFromAgency: handleProfile,
  }

  useEffect(() => {
    getSource()
  }, [filters])

  async function getSource() {
    try {
      setLoading(true)

      const [
        dashboards,
        servicesDashboards,
        chartData,
        tableData,
        serviceTableProductsData,
        serviceTableProvidersData,
        serviceTableProvidersByBranchData,
      ] = await Promise.allSettled([
        apiRequest('get', 'holdingbackoffice/sales/dashboards', params),
        apiRequest('get', 'holdingbackoffice/services/dashboards', params),
        apiRequest('get', 'holdingbackoffice/sales/chart', params),
        apiRequest('get', 'holdingbackoffice/sales/table', params),
        apiRequest('get', 'holdingbackoffice/services/table/products', params),
        apiRequest('get', 'holdingbackoffice/services/table/providers', params),
        apiRequest(
          'get',
          'holdingbackoffice/services/table/providers-by-branch',
          params,
        ),
      ])

      setDashboards(dashboards?.value?.data)
      setServicesDashboards(servicesDashboards?.value?.data)
      setChartData(chartData?.value?.data)
      setTableData(tableData?.value?.data)
      setTableProductsData(serviceTableProductsData?.value?.data)
      setTableProvidersData(serviceTableProvidersData?.value?.data)
      setTableProvidersByBranchData(
        serviceTableProvidersByBranchData?.value?.data,
      )
    } catch (error) {
      toast.info('Dados não encontrados. Tente mais tarde!')
      setLoading(false)
      setDashboards([])
      setChartData([])
      setTableData([])
      setServicesDashboards([])
      setTableProductsData([])
      setTableProvidersData([])
      setTableProvidersByBranchData([])
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    dashboards,
    chartData,
    tableData,
    servicesDashboards,
    tableProductsData,
    tableProvidersData,
    tableProvidersByBranchData,
  }
}

// import { useEffect, useState } from 'react'
// import { toast } from 'react-toastify'
// // import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
// import useAxios from '~/hooks/useAxios'

// export default function useHoldingBackofficeSalesData({
//   filters,
//   handleProfile,
// }) {
//   const { apiClient } = useAxios()
//   const [loading, setLoading] = useState(false)
//   // const dashboardsPlaceholders = getDashboardPlaceholders(3)
//   const [dashboards, setDashboards] = useState([])
//   const [chartData, setChartData] = useState()
//   const [tableData, setTableData] = useState([])
//   const [servicesDashboards, setServicesDashboards] = useState([])
//   const [tableProductsData, setTableProductsData] = useState([])
//   const [tableProvidersData, setTableProvidersData] = useState([])
//   const [tableProvidersByBranchData, setTableProvidersByBranchData] = useState(
//     [],
//   )

//   useEffect(() => {
//     async function getSource() {
//       try {
//         setLoading(true)

//         const params = {
//           branchs: filters?.branchs ? filters.branchs.map(x => x.value) : [],
//           start: filters?.start ?? undefined,
//           end: filters?.end ?? undefined,
//           requestFromAgency: handleProfile,
//         }

//         const resDashboards = await apiClient.get(
//           '/holdingbackoffice/sales/dashboards',
//           { params },
//         )

//         setDashboards(resDashboards.data)

//         const resServicesDashboards = await apiClient.get(
//           '/holdingbackoffice/services/dashboards',
//           { params },
//         )

//         setServicesDashboards(resServicesDashboards.data)

//         const resChartData = await apiClient.get(
//           '/holdingbackoffice/sales/chart',
//           { params },
//         )

//         setChartData(resChartData.data)

//         const resTableData = await apiClient.get(
//           '/holdingbackoffice/sales/table',
//           { params },
//         )

//         setTableData(resTableData.data)

//         const resServiceTableProductsData = await apiClient.get(
//           '/holdingbackoffice/services/table/products',
//           { params },
//         )

//         setTableProductsData(resServiceTableProductsData.data)

//         const resServiceTableProvidersData = await apiClient.get(
//           '/holdingbackoffice/services/table/providers',
//           { params },
//         )

//         setTableProvidersData(resServiceTableProvidersData.data)

//         const resServiceTableProvidersByBranchData = await apiClient.get(
//           '/holdingbackoffice/services/table/providers-by-branch',
//           { params },
//         )

//         setTableProvidersByBranchData(resServiceTableProvidersByBranchData.data)
//       } catch (error) {
//         toast.info('Dados não encontrados. Tente mais tarde!')
//       } finally {
//         setLoading(false)
//       }
//     }

//     getSource()

//     return () => {
//       setLoading(false)
//       // setDashboards(dashboardsPlaceholders)
//       setChartData([])
//       setTableData([])
//       // setServicesDashboards(dashboardsPlaceholders)
//       setTableProductsData([])
//       setTableProvidersData([])
//       setTableProvidersByBranchData([])
//     }
//   }, [filters])

//   return {
//     loading,
//     dashboards,
//     chartData,
//     tableData,
//     servicesDashboards,
//     tableProductsData,
//     tableProvidersData,
//     tableProvidersByBranchData,
//   }
// }
