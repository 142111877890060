import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
import useAxios from '~/hooks/useAxios'

export default function useHoldingConsolidatorSalesData({ filters }) {
  const { apiClient } = useAxios()
  const [loading, setLoading] = useState(false)
  const dashboardsPlaceholders = getDashboardPlaceholders(3)
  const [dashboards, setDashboards] = useState(dashboardsPlaceholders)
  const [chartData, setChartData] = useState()
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const params = {
          branchs: filters?.branchs ? filters.branchs.map(x => x.value) : [],
          start: filters?.start ?? undefined,
          end: filters?.end ?? undefined,
        }

        const resDashboards = await apiClient.get(
          '/holdingconsolidator/sales/dashboards',
          { params },
        )

        setDashboards(resDashboards.data)

        const resChartData = await apiClient.get(
          '/holdingconsolidator/sales/chart',
          { params },
        )

        setChartData(resChartData.data)

        const resTableData = await apiClient.get(
          '/holdingconsolidator/sales/table',
          { params },
        )

        setTableData(resTableData.data)
      } catch (error) {
        toast.info('Dados não encontrados. Tente mais tarde!')
      } finally {
        setLoading(false)
      }
    }

    getSource()

    return () => {
      setDashboards(dashboardsPlaceholders)
      setChartData([])
      setTableData([])
    }
  }, [filters])

  return {
    loading,
    dashboards,
    chartData,
    tableData,
  }
}
