import styled from 'styled-components'
import { Col } from 'reactstrap'

export const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #bdbfbf;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
`
