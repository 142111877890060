import DefaultForm from '~/components/DefaultForm'
import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import LoadingContainer from '~/components/LoadingContainer'
import usePermissions from '~/hooks/usePermissions'
import { getMoment } from '~/functions/Date'
import { toast } from 'react-toastify'

export default function ContentModal({
  customerId,
  requestApiGettravelsCustomer,
  handleShow,
}) {
  const [agents, setAgents] = useState('')
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const { isAdmin } = usePermissions()
  // const [file, setFile] = useState()

  useEffect(() => {
    requestApiOptionsAgente()
  }, [])

  async function requestApiOptionsAgente() {
    try {
      setLoading(true)

      const { data: dataAgents } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
      )

      setAgents(dataAgents)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      // toast.info(`${error.response.data}`)
    }
  }

  const onSubmit = async data => {
    try {
      if (
        isAdmin &&
        (data.agent.value == undefined || data.agent.value == null)
      ) {
        toast.info('Adicione o consultor da viagens!')
      }
      if (data.origin.value == undefined || data.origin.value == null) {
        toast.info('Adicione a origem da viagens!')
      }
      if (getMoment(data.start) > getMoment(data.end)) {
        toast.info('O início da viagem não pode ser depois que o fim!')
      }

      setLoading(true)

      // const body = {
      //   ...data,
      //   agentId: isAdmin ? data.agent.value : 0,
      //   customerId,
      //   origin: data.origin.value,
      //   destiny: data.destiny.value,
      // }

      const formData = new FormData()

      formData.append('agentId', isAdmin ? data.agent.value : 0)
      formData.append('title', data.title)
      formData.append('origin', data.origin.label)
      formData.append('originId', data.origin.value)
      formData.append('start', data.start)
      formData.append('end', data.end)
      formData.append('promoter', data.promoter)
      formData.append('percentage', data.percentage)
      formData.append('customerId', customerId)

      for (let i = 0; i <= data.travelDocs?.length; i++) {
        formData.append('travelDocs', data.travelDocs[i])
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/createTravelFromCustomer`,
        formData,
      )

      await requestApiGettravelsCustomer()
      setLoading(false)
      handleShow()
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <div>
      {/* <input type='file' id='fileInput' multiple /> */}
      {loading && <LoadingContainer />}
      {!loading && (
        <DefaultForm
          // onValidate={onValidate}
          callbackSubmit={onSubmit}
          callbackReset={() => {
            return
          }}
          inputs={[
            {
              empty: true,
              text: `<b>1. Dados da viagem</b>`,
              sm: 12,
            },
            {
              type: 'text',
              name: 'title',
              label: 'Título da Viagem',
              placeholder: 'Título da Viagem',
              required: true,
              sm: isAdmin ? 6 : 12,
              md: isAdmin ? 6 : 12,
              lg: isAdmin ? 6 : 12,
              xl: isAdmin ? 6 : 12,
            },
            {
              type: 'select',
              name: 'agent',
              label: 'Consultor',
              placeholder: 'Selecione...',
              isSearchable: true,
              isClearable: true,
              required: true,
              sm: 6,
              md: 6,
              lg: 6,
              xl: 6,
              options: agents,
              hide: !isAdmin,
            },
            // {
            //   type: 'select',
            //   name: 'traveler',
            //   label: 'Viajante',
            //   placeholder: 'Selecione...',
            //   isSearchable: true,
            //   isClearable: true,
            //   required: true,
            //   sm: 12,
            //   md: 12,
            //   lg: 12,
            //   xl: 12,
            //   options: travelers,
            //   hide: traveler,
            // },
            // {
            //   type: 'number',
            //   name: 'passengers',
            //   label: 'Quantidade de Viajantes',
            //   required: true,
            //   sm: 12,
            //   md: 12,
            //   lg: 6,
            //   xl: 6,
            // },
            {
              type: 'city',
              name: 'origin',
              label: 'Cidade de Origem',
              placeholder: 'Pesquise por uma cidade...',
              required: true,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            // {
            //   type: 'city',
            //   name: 'destiny',
            //   label: 'Cidade de Destino',
            //   placeholder: 'Pesquise por uma cidade...',
            //   required: true,
            //   sm: 12,
            //   md: 12,
            //   lg: 6,
            //   xl: 6,
            // },
            {
              type: 'date',
              name: 'start',
              label: 'Ida',
              required: true,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            {
              type: 'date',
              name: 'end',
              label: 'Volta',
              required: true,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            {
              empty: true,
              text: `<b>2. Dados da venda</b>`,
              sm: 12,
            },
            {
              type: 'text',
              name: 'promoter',
              label: 'Promotor',
              placeholder: 'Promotor',
              required: true,
              sm: 6,
              md: 6,
              lg: 6,
              xl: 6,
            },
            {
              type: 'text',
              name: 'percentage',
              label: 'Porcentagem',
              placeholder: 'Porcentagem',
              required: true,
              sm: 6,
              md: 6,
              lg: 6,
              xl: 6,
            },
            {
              empty: true,
              text: `<b>3. Documentos de Identificação</b>`,
              sm: 12,
            },
            {
              type: 'multiFileInput',
              name: 'travelDocs',
              label: 'Arquivos',
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
          ]}
        />
      )}
    </div>
  )
}

// 1. dados da viagem
// 2. dados da venda

// adicionar possibilidade de add documentos na nova tela de lançamentos para o cliente

// viagem, ida, volta, destino, consultor e promotor
