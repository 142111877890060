import { useEffect, useState } from 'react'
import MapDefault from '~/components/MapDefault'

function TravelMapDefault({ token, travel, markers, center, routes }) {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    setTimeout(() => setLoading(false), 300)
  }, [markers, center, routes])

  return (
    <>
      {!loading && (
        <MapDefault
          markers={markers}
          routes={routes}
          center={center}
          token={token}
          travel={travel}
        />
      )}
    </>
  )
}

export default TravelMapDefault
