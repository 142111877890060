import styled from 'styled-components'

export const DivGlobal = styled.div`
  background-color: white;
`

export const Container = styled.div`
  width: 75%;
  margin: auto;
  padding-top: 1.5cm;
  background-color: white;

  @media (width <= 1000px) {
    width: 95%;
    margin: auto;
    padding-top: 1cm;
  }
`

export const Register = styled.p`
  padding-bottom: 0.25cm;
  font-size: 15px;
`

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5cm;
`

export const Button = styled.button`
  background-color: ${props => props.primaryColor};
  height: 50px;
  color: #fff;
  font-weight: 700;
  padding: 8px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
`

export const TopTitle = styled.div`
  color: #b79974;
  text-align: center;

  h1 {
    font-weight: bold;
  }
`

export const TitleActivity = styled.h5`
  color: #b79974;
  margin-bottom: 25px;
`

export const Hr = styled.div`
  border-top: 1px solid grey;
  border-bottom: 1px solid white;
`

export const HrColor = styled.div`
  margin-top: 25px;
  border-top: 1px solid #b79974;
  border-bottom: 1px solid white;
`

export const DivEntity = styled.div`
  padding-bottom: 1.5cm;
  font-family: 'Roboto';
  text-align: center;

  a {
    color: grey;
  }

  i {
    color: #b79974;
  }

  p {
    color: #b79974;
    font-size: 20px;
    text-align: center;
    width: 100%;
    font-weight: bold;
  }

  h2 {
    color: ${props => props.primaryColor};
    margin: auto 0;
  }
  h6 {
    color: black;

    @media (width <= 1000px) {
      margin: 0;
    }
  }
`

export const Image = styled.img`
  /* width: 100%; */
  max-width: 100%;
  padding-bottom: 1.5cm;
  min-width: 45%;
  flex: 1;
`

export const AgencyImage = styled.img`
  width: 200px;
  padding-bottom: 1.5cm;
`

export const Box = styled.div`
  background-color: ${props => props.secondaryColor};
  width: 30px;
  height: 20px;
`

export const DivActivity = styled.div`
  @media (width <= 1000px) {
    padding: 0.25cm;
  }
`

export const DivActivityDesc = styled.h6`
  text-align: justify;
  white-space: pre-line;
  color: grey !important;
`

export const EncapsuleBoxTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`

export const Table = styled.table`
  font-size: 16px;
  width: ${props => (props.widthCustom ? '75%' : '100%')};
  color: #b79974;
  /* margin: 0 1.25cm; */

  @media (width <= 1000px) {
    margin: 0 0.25cm;
  }

  tr {
    border-bottom: 1px solid #b79974;
    /* border-top: 1px solid black; */
    padding: 20px 10px;

    th {
      border-bottom: 1px solid #b79974;
      background-color: white;
      /* width: 250px; */
      text-align: center;
      vertical-align: middle;
      font-weight: 600;
    }

    td {
      /* width: 250px; */
      border-bottom: 1px solid #b79974;
      text-align: center;
      color: black;
      width: 150px;
      vertical-align: middle;
      padding: 5px;
    }
  }
`

export const Tr = styled.tr`
  border-bottom: 1px solid #b79974;
  padding: 20px 10px;
`

export const Th = styled.th`
  border-bottom: 1px solid #b79974;
  background-color: ${props => props.primaryColor};
  text-align: center;
  vertical-align: middle;
`

export const Td = styled.td`
  border-bottom: 1px solid #b79974;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
`

export const ImportantInformation = styled.div`
  /* margin: 0 1.25cm; */
  font-size: 15px;
  text-align: left;
  color: GREY !important;

  p {
    color: grey !important;
    text-align: left !important;
    font-size: 15px;
    display: inline !important;
    font-weight: 400 !important;
  }
`

export const ImportantInformationObservation = styled.div`
  /* margin: 0 1.25cm; */
  font-size: 14px;
  text-align: left;
  color: grey !important;

  p {
    color: grey !important;
    text-align: left !important;
    font-size: 15px;
    font-weight: 400 !important;
  }
`

export const AgentInformation = styled.div`
  font-size: 15px;
  text-align: right;
`

export const TotalValueLine = styled.tr`
  font-weight: bold;

  td {
    text-align: center;
  }
`
