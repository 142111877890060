import styled from 'styled-components'
import { Col } from 'reactstrap'

export const Container = styled.div`
  * {
    box-sizing: border-box;
  }

  position: relative;

  [class^='col'],
  .row {
    padding: 0;
    margin: 0;
  }

  [class^='col'] {
    padding: 2px 8px;

    .row {
      [class^='col'] {
        padding: 0;
      }
    }
  }

  p {
    margin-bottom: 4px;
  }
`

export const NewTravel = styled.button`
  display: flex;
  border: transparent;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 0 8px;
  font-weight: bold;
  text-decoration: none;
  color: #4bf28b;

  &:hover {
    color: #7bffbb;
  }
`

export const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`
