import { useState, useRef, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { Form } from '@unform/web'
import { GetAxios } from '~/services/api'
import { Panel, PanelHeader, PanelBody } from '~/components/Panel'
import { toast } from 'react-toastify'
import Input from '~/components/Input'
import { ResetButton, SaveButton } from '~/components/Button/styles'
import { Container, RightCol } from './styles'
import { getDateFormated } from '~/functions/Date'

export default function CreateDocument() {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const { traveler } = useOutletContext()
  const navigate = useNavigate()
  const [fileDocument, setFileDocument] = useState()
  const [loading, setLoading] = useState(false)
  const [travelers, setTravelers] = useState([])

  const initialState = {
    documentData: {
      title: '',
      desc: '',
      expiresIn: '',
      fileDocument: undefined,
    },
  }

  const [pageProps, setPageProps] = useState(initialState)

  const setInputField = (fieldName, value) => {
    const newData = {
      ...pageProps,
      documentData: {
        ...pageProps.documentData,
        [fieldName]: value,
      },
    }

    setPageProps(newData)
  }

  const resetForm = () => {
    setPageProps(initialState)
  }

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
        )

        setTravelers(data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function handleSubmit() {
    try {
      setLoading(true)

      const formData = new FormData()

      if (fileDocument && fileDocument.size > 3145728) {
        setLoading(false)
        return toast.info('Insira um arquivo com tamanho máximo de 3 MB')
      } else {
        const docType = fileDocument.type.split('/')
        formData.append('data', fileDocument ?? [])
        formData.append('filename', fileDocument.name ?? [])
        formData.append('contenttype', fileDocument.type ?? [])
        formData.append('doctype', docType[1])
      }
      formData.append('title', pageProps.documentData.title)
      formData.append('desc', pageProps.documentData.desc)
      formData.append('userid', traveler.id)
      if (pageProps.documentData.expiresIn) {
        formData.append(
          'expiresIn',
          getDateFormated(pageProps.documentData.expiresIn, 'YYYY-MM-DD'),
        )
      }

      if (traveler.fromCustomer) {
        await axiosApis.post(
          `traveldocs/add-document-in-traveler-from-customer`,
          formData,
        )
      } else {
        await axiosApis.post(`traveldocs/inserttraveler`, formData)
      }

      setLoading(false)

      toast.success('Documento criado com sucesso!')

      navigate(-1)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível criar o documento.')
    }
  }

  return (
    <Panel>
      <PanelHeader title={'Cadastrar Novo Documento'} noButton />
      <PanelBody>
        <Container>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} md={12} lg={6} xl={6}>
                <Input
                  disabled={false}
                  name={'title'}
                  label={'Título *'}
                  value={pageProps.documentData.title}
                  maxLength={100}
                  onChange={e => setInputField('title', e.target.value)}
                  required
                />
              </Col>

              <Col sm={12} md={12} lg={6} xl={6}>
                <Input
                  disabled={false}
                  name={'desc'}
                  label={'Descrição'}
                  maxLength={100}
                  value={pageProps.documentData.desc}
                  onChange={e => setInputField('desc', e.target.value)}
                />
              </Col>

              <Col sm={12} md={12} lg={6} xl={6}>
                <Input
                  name={'fileDocument'}
                  label={'Arquivo *'}
                  type='file'
                  onChange={e => {
                    setFileDocument(e.target.files[0])
                  }}
                  disabled={false}
                  accept='image/*, application/pdf'
                  required
                />
              </Col>
              <Col sm={12} md={12} lg={6} xl={6}>
                <Input
                  disabled={false}
                  name={'expiresIn'}
                  type={'date'}
                  label={'Data de Expiração'}
                  value={pageProps.documentData.expiresIn}
                  onChange={e => setInputField('expiresIn', e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <RightCol>
                <ResetButton onClick={resetForm} type='reset'>
                  Limpar
                </ResetButton>
                <SaveButton disabled={loading} type='submit'>
                  Salvar
                </SaveButton>
              </RightCol>
            </Row>
          </Form>
        </Container>
      </PanelBody>
    </Panel>
  )
}
