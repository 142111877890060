/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import {
  DefaultLi,
  DefaultLiLabel,
  DefaultLiTitle,
  DefaultUl,
} from '~/styles/ul'
import DivForHTML from '~/components/DivForHTML'
import {
  DeleteButton,
  EditButton,
  RegisterNewButton,
  SaveButton,
} from '~/components/Button/styles'
import { SpaceBetweenDiv } from '~/styles/divs'
import { toast } from 'react-toastify'
import LoadingContainer from '~/components/LoadingContainer'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Spinner } from 'reactstrap'
import Divisor from '~/components/Divisor'
import Tooltip from '~/components/Tooltip'

export default function ExperienceAdd() {
  const location = useLocation()
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [isTips, setIsTips] = useState(false)
  const [tips, setTips] = useState([])
  const [tipsSelectedsCache, setTipsSelectedsCache] = useState([])
  const [optionsDestinys, setOptionsDestinys] = useState([])
  const [tipsLabel, setTipsLabel] = useState()
  const [loadingLabel, setLoadingLabel] = useState()
  const [showForm, setShowForm] = useState(false)

  location.state = {
    ...location.state,
    experiencesSelect: {
      travel: {
        id: travel.id,
      },
    },
  }

  useEffect(() => {
    getDestinys()
    getTips()
  }, [])

  async function getDestinys() {
    try {
      const { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/get-travelexperience-destinys/${travel.id}`,
      )

      setOptionsDestinys(data)
    } catch (error) {
      console.log(error)
    }
  }

  async function getTips() {
    try {
      setLoading(true)

      setLoadingLabel('Carregando sugestões...')

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/get-tips/${travel.id}`,
      )

      setTips(data)

      setTipsLabel(
        `Sugestões baseadas nos Destinos`,
      )

      setIsTips(true)

      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  async function onSubmit() {
    try {
      setLoadingSubmit(true)

      setLoadingLabel('Adicionando experiências...')

      let message = 'Deseja adicionar:\n\n'

      tipsSelectedsCache.forEach(x => {
        message += x.title + '\n'
      })

      message += '\nà viagem?'

      if (window.confirm(message)) {
        for (let index = 0; index < tipsSelectedsCache.length; index++) {
          const element = tipsSelectedsCache[index]

          await axiosApis.post(
            `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/save-tips/${travel.id}/${element.id}/${element.type}`,
          )
        }

        toast.success('Experiências adicionadas com sucesso.')

        navigate(-1)
      }

      setLoadingSubmit(false)
    } catch (error) {
      console.log(error)

      setLoadingSubmit(false)
    }
  }

  async function onSubmitManually(data) {
    try {
      const formData = new FormData()

      formData.append('title', data.title)
      formData.append('desc', data.desc)
      formData.append('city', data.city.label ?? '')
      formData.append('cityId', data.city.value ?? '')
      formData.append('categoryId', data.category.value)
      formData.append('links', data.links ?? '[]')
      formData.append('image', data.image)

      if (data.flag) {
        formData.append('flag', data.flag)
      }

      if (data.address) {
        formData.append('address', data.address)
      }

      if (data.lat) {
        formData.append('lat', data.lat)
      }

      if (data.lng) {
        formData.append('lng', data.lng)
      }

      let message = `Deseja criar e adicionar: \n\n`
      message += `${data.title}\n\n`
      message += `á viagem?`

      if (window.confirm(message)) {
        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/AddTableExperience/${travel.id}`,
          formData,
        )

        toast.success('Experiência criado e adicionada com sucesso!')
        setShowForm(false)
        navigate(-1)
      }
    } catch (error) {
      console.log(error)
      return toast.info(
        `${error.response.data ||
        'Não foi possível criar experiência. Tente novamente!'
        } `,
      )
    }
  }

  async function onValidate(data) {
    if (!data.title && !data.category && !data.destiny) {
      toast.info('Pesquise por título, categoria ou destino da experiência.')

      return false
    }

    return true
  }

  async function onValidateManually(data) {
    if (
      !data.title &&
      !data.category.value &&
      !data.city.label &&
      !data.city.value
    ) {
      toast.info(
        'Os campos referentes a título, categoria e cidade são obrigatórios.',
      )

      return false
    }

    return true
  }

  async function onSearch(data) {
    try {
      setLoading(true)

      setLoadingLabel('Carregando resultados...')

      const res = await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/get-experience`,
        {
          travelId: travel.id,
          title: data.title ? data.title : undefined,
          categoryId: data.category ? data.category.value : undefined,
          destinyId: data.destiny ? data.destiny.value : undefined,
        },
      )

      const newTips = []

      res.data.forEach(x => {
        if (!tipsSelectedsCache.find(y => y.id == x.id)) {
          newTips.push(x)
        }
      })

      setTips(newTips)

      setTipsLabel('Resultados da pesquisa')

      setIsTips(false)

      if (newTips.length === 0) {
        toast.info('Nenhum resultado encontrado com esses filtros.')
      }

      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  async function onSelectItem(e) {
    const newList = tips.filter(x => x.id !== e.id)

    setTips(newList)

    setTipsSelectedsCache([...tipsSelectedsCache, e])
  }

  async function onRemoveItem(e) {
    const newList = tipsSelectedsCache.filter(x => x.id !== e.id)

    setTipsSelectedsCache(newList)

    setTips([...tips, e])
  }

  async function onCancelSearchSuggestions() {
    setShowForm(true)
  }

  return (
    <>
      <PageTitle
        head='h5'
        title={
          showForm ? 'Criar e adicionar na viagem' : 'Adicionar experiências'
        }
      />

      {showForm ? (
        <DefaultForm
          callbackSubmit={onSubmitManually}
          callbackReset={() => {
            return
          }}
          onValidate={onValidateManually}
          cancelLabel={'Voltar'}
          resetLabel={'Limpar'}
          callbackCancel={() => {
            setShowForm(false)
          }}
          inputs={[
            {
              type: 'text',
              name: 'title',
              label: 'Título',
              required: true,
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
              maxLength: 200,
            },
            {
              type: 'city',
              name: 'city',
              label: 'Cidade',
              placeholder: 'Pesquise por uma cidade...',
              required: true,
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'experience-category',
              name: 'category',
              label: 'Categoria',
              placeholder: 'Selecione',
              required: true,
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'text',
              name: 'flag',
              label: 'Tags',
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'image',
              name: 'image',
              label: 'Imagem',
              required: true,
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'keyvalue',
              name: 'links',
              label: 'Links úteis',
              placeholder: 'Descrição',
              subPlaceholder2: 'Link',
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            },
            {
              type: 'text',
              name: 'address',
              label: 'Endereço',
              required: false,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
              maxLength: 200,
            },
            {
              type: 'html',
              name: 'desc',
              label: 'Descrição',
              required: true,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
          ]}
        />
      ) : (
        <DefaultForm
          callbackSubmit={onSearch}
          callbackCancel={onCancelSearchSuggestions}
          callbackReset={() => {
            setTips([])
          }}
          onValidate={onValidate}
          disabled={loadingSubmit || loading}
          submitLabel={'Pesquisar'}
          cancelLabel={loading ? '' : 'Preencher dados manualmente'}
          resetLabel={'Limpar filtros'}
          inputs={[
            {
              type: 'text',
              name: 'title',
              label: 'Pesquise por uma experiência',
              sm: 12,
              lg: 4,
            },
            {
              type: 'experience-category',
              name: 'category',
              label: 'Categoria',
              sm: 12,
              lg: 4,
            },
            {
              type: 'select',
              name: 'destiny',
              label: 'Destino',
              options: optionsDestinys,
              isClearable: true,
              sm: 12,
              lg: 4,
            },
          ]}
        />
      )}

      {!showForm && <Divisor border />}

      {loading && !showForm && (
        <LoadingContainer height='220px' label={loadingLabel} />
      )}

      {!loading && !showForm && (
        <Container>
          <SpaceBetweenDiv>
            <PageTitle title={tipsLabel} head={'h6'} noButton />

            <RegisterNewButton
              onClick={getTips}
              disabled={loading || loadingSubmit}
            >
              Novas sugestões
            </RegisterNewButton>
          </SpaceBetweenDiv>

          <Divisor margin='8px' />

          <DefaultUl style={{ minHeight: '30vh' }}>
            {tips.map((e, i) => {
              return (
                <DefaultLi key={i}>
                  <SpaceBetweenDiv
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      minHeight: '100%',
                    }}
                  >
                    <SpaceBetweenDiv>
                      <DefaultLiTitle>
                        {e.title.toUpperCase().substring(0, 40)}
                      </DefaultLiTitle>

                      {!loadingSubmit && !loading && (
                        <RegisterNewButton
                          onClick={() => onSelectItem(e)}
                          id={'experience-add-' + i}
                        >
                          <i className='fas fa-plus' />
                        </RegisterNewButton>
                      )}

                      <Tooltip
                        target={'experience-add-' + i}
                        text='Adicionar experiência'
                      />
                    </SpaceBetweenDiv>

                    <DivForHTML
                      text={e.desc ? e.desc.substring(0, 150) + '...' : ''}
                    />

                    <div>
                      <p style={{ marginBottom: '0' }}>
                        <DefaultLiLabel>Categoria:</DefaultLiLabel>&nbsp;{' '}
                        {e.categoryDesc}
                      </p>
                      <p style={{ marginBottom: '0' }}>
                        <DefaultLiLabel>Cidade:</DefaultLiLabel>&nbsp; {e.city}
                      </p>
                    </div>
                  </SpaceBetweenDiv>
                </DefaultLi>
              )
            })}

            {tips.length === 0 && <span>Nenhum resultado encontrado</span>}
          </DefaultUl>
        </Container>
      )}

      {!showForm && <Divisor margin='8px' />}

      {!showForm && (
        <Container>
          <SpaceBetweenDiv>
            <PageTitle title={'Experiências selecionados'} head={'h6'} noButton />{' '}
          </SpaceBetweenDiv>

          <Divisor margin='8px' />

          <DefaultUl style={{ minHeight: '30vh' }}>
            {tipsSelectedsCache.length === 0 && (
              <p>
                Nenhum item selecionado. Adicione sugestões acima ou pesquise
                por uma experiência.
              </p>
            )}

            {tipsSelectedsCache.map((e, i) => {
              return (
                <DefaultLi key={i}>
                  <SpaceBetweenDiv
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      minHeight: '100%',
                    }}
                  >
                    <SpaceBetweenDiv>
                      <DefaultLiTitle>{e.title.toUpperCase()}</DefaultLiTitle>

                      {!loadingSubmit && !loading && (
                        <DeleteButton
                          onClick={() => onRemoveItem(e)}
                          id={'experience-remove-' + i}
                        >
                          <i className='fas fa-times' />
                        </DeleteButton>
                      )}

                      <Tooltip
                        target={'experience-remove-' + i}
                        text='Remover experiência'
                      />
                    </SpaceBetweenDiv>

                    <DivForHTML
                      text={e.desc ? e.desc.substring(0, 150) + '...' : ''}
                    />

                    <div>
                      <p style={{ marginBottom: '0' }}>
                        <DefaultLiLabel>Categoria:</DefaultLiLabel>&nbsp;{' '}
                        {e.categoryDesc}
                      </p>
                      <p style={{ marginBottom: '0' }}>
                        <DefaultLiLabel>Cidade:</DefaultLiLabel>&nbsp; {e.city}
                      </p>
                    </div>
                  </SpaceBetweenDiv>
                </DefaultLi>
              )
            })}
          </DefaultUl>

          <Divisor margin='8px' />
        </Container>
      )}

      {!showForm && (
        <SpaceBetweenDiv>
          <EditButton onClick={() => navigate(-1)}>Voltar</EditButton>

          {loadingSubmit && <Spinner size={'sm'} />}

          {!loadingSubmit && (
            <SaveButton
              onClick={onSubmit}
              disabled={tipsSelectedsCache.length === 0 || loading}
            >
              Salvar selecionados
            </SaveButton>
          )}
        </SpaceBetweenDiv>
      )}
    </>
  )
}

const Container = styled.div`
  padding: 4px;
`
