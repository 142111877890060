import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { LinkEdit } from '~/components/Link/styles'
import { DeleteButton } from '~/components/Button/styles'
import { ChangeStatusButton } from '~/components/SubTableOfCustomerTraveler/style'
import ReactTable from '~/components/Table'
import { getDateFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function Table({ data, onSubmit }) {
  const user = useSelector(state => state.auth.user)
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function showDetails(e) {
    navigate('/relationship-customers/enrollment-details', {
      state: { service: e },
    })
  }

  async function requestApiServicePut(serviceId) {
    try {
      setLoading(true)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/service/UpdateStatusOnly/${serviceId}`,
      )

      await onSubmit()
      setLoading(false)
      toast.success('Status do serviço atualizado com sucesso!')
    } catch (error) {
      // error.response.status == 404 && toast.info(`${error.response.data}`)
      console.log(error)
    }
  }

  const onDelete = async data => {
    try {
      setLoading(true)

      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/service/${data.id}`,
      )
      await onSubmit()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const toOperator = [
    {
      Header: 'Nome do Cliente',
      accessor: 'fullName',
      width: 175,
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      width: 150,
    },
    {
      Header: 'Telefone',
      accessor: 'phone',
      width: 120,
    },
    {
      Header: 'CPF/CNPJ',
      accessor: 'cpfCnpj',
      width: 120,
    },
    {
      Header: 'Aniversário',
      accessor: 'birthdayDate',
      width: 100,
      Cell: props => {
        return getDateFormated(props.row.original.birthDate)
      },
    },
    {
      Header: 'Cidade',
      accessor: 'city',
      width: 120,
    },
    {
      Header: 'Consultor',
      accessor: 'agent',
      width: 120,
    },
    {
      Header: 'Agência',
      accessor: 'belongingAgency',
      width: 100,
    },
    {
      Header: '',
      accessor: 'details',
      Cell: props => {
        return (
          // <LinkEdit to={'details'} state={{ customers: props.row.original }}>
          //   Detalhes
          // </LinkEdit>
          <IconButton
            id={props.row.index}
            type={'details'}
            onClick={() => showDetails(props.row.original)}
          />
        )
      },
      width: 90,
    },
  ]

  const toAgency = [
    {
      Header: 'Tipo do serviço',
      accessor: 'type',
      width: 100,
    },
    {
      Header: 'Nome do cliente',
      accessor: 'nameTraveler',
      width: 200,
    },
    {
      Header: 'Viagem',
      accessor: 'tiTleTravel',
      width: 200,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: props => {
        return props.row.original.status ? (
          <i className='fas fa-check-circle' style={{ color: 'green' }}></i>
        ) : (
          <i className='fas fa-clock'></i>
        )
      },
      width: 90,
    },
    {
      Header: '',
      accessor: 'validate',
      Cell: props => {
        return (
          <ChangeStatusButton
            type='button'
            onClick={() => requestApiServicePut(props.row.original.id)}
          >
            Mudar status
          </ChangeStatusButton>
        )
      },
      width: 120,
    },
    {
      Header: '',
      accessor: 'details',
      Cell: props => {
        return (
          // <LinkEdit
          //   to={'/relationship-customers/enrollment-details'}
          //   state={{ service: props.row.original }}
          // >
          //   Detalhes
          // </LinkEdit>
          <IconButton
            id={props.row.index}
            type={'details'}
            onClick={() => showDetails(props.row.original)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'remove',
      Cell: props => {
        return (
          // <DeleteButton
          //   type='button'
          //   onClick={() => {
          //     onDelete(props.row.original)
          //   }}
          // >
          //   Remove
          // </DeleteButton>
          <IconButton
            id={props.row.index}
            type={'trash'}
            onClick={() => onDelete(props.row.original)}
          />
        )
      },
      width: 20,
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={user.isOperator == true ? toOperator : toAgency}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
