export default function IncrementInput({ label, name, value, onChange }) {
  function onIncrement() {
    onChange({
      target: {
        name: name,
        value: (value ?? 0) + 1,
      },
    })
  }

  function onDecrement() {
    onChange({
      target: {
        name: name,
        value: (value ?? 0) - 1,
      },
    })
  }

  return (
    <div>
      <label>{label}</label>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span>{value ?? 0}</span>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button onClick={onIncrement} type='button'>
            <i className='fas fa-arrow-up' />
          </button>

          <button onClick={onDecrement} type='button'>
            <i className='fas fa-arrow-down' />
          </button>
        </div>
      </div>
    </div>
  )
}
