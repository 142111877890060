import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import { Panel, PanelBody, PanelHeader } from '../Panel'
import Table from '../Table'
import { getDateFormated } from '~/functions/Date'

export default function SubTableOfTraveler({ travelerId }) {
  const { axiosApis } = GetAxios()
  const [travels, setTravels] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/gettraveler/${travelerId}`,
        )
        setTravels(response.data)

        return setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    loadSource()
    return () => {
      cancel = true
    }
  }, [])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '8px',
      }}
    >
      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          <Table
            data={travels}
            loading={loading}
            columns={[
              {
                Header: 'Itinerário',
                accessor: 'title',
                width: 150,
              },
              {
                Header: 'Origem',
                accessor: 'origem',
                width: 200,
              },
              {
                Header: 'Destino',
                accessor: 'destino',
                width: 200,
              },
              {
                Header: 'Início',
                accessor: 'start',
                Cell: props => {
                  return getDateFormated(props.row.original.start)
                },
                width: 90,
              },
              {
                Header: 'Fim',
                accessor: 'end',
                Cell: props => {
                  return getDateFormated(props.row.original.end)
                },
                width: 90,
              },
              {
                Header: 'Nome do consultor',
                accessor: 'nameAgent',
                width: 200,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}
