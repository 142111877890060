import { useEffect, useRef, useState } from 'react'
import { Form } from 'reactstrap'
import styled from 'styled-components'
import { DivFlexRight } from '~/styles/divs'
import { EditButton, RegisterNewButton } from '../Button/styles'
import { DivFlexCentered } from '../ImageInput'
import Input from '../Input'
import TextAreaWrapper from '../TextArea'

export default function ValueInput({
  title = 'Título',
  placeholder,
  onChange = () => {},
  defaultValue,
  name,
}) {
  const ref = useRef()
  const [values, setValues] = useState([])
  const [value, setValue] = useState()
  const [showInputs, setShowInputs] = useState(false)

  useEffect(() => {
    if (defaultValue && defaultValue.startsWith('[')) {
      setValues(JSON.parse(defaultValue))
    } else {
      setValues([])
    }
  }, [defaultValue])

  function handleRemove(e, item) {
    const message = `${placeholder}: ${item}\n\nDeseja remover o item?`

    if (window.confirm(message)) {
      let newValues = [...values]

      newValues.splice(e, 1)

      setValues(newValues)

      onChange({
        target: {
          name: name,
          value: JSON.stringify(newValues),
        },
      })
    }
  }

  function handleSubmit() {
    if (value) {
      const newValues = values.concat([value])

      setValues(newValues)

      onChange({
        target: {
          name: name,
          value: JSON.stringify(newValues),
        },
      })

      setValue('')

      setShowInputs(false)
    }
  }

  return (
    <Form ref={ref} onSubmit={undefined}>
      <Container>
        <label htmlFor='value-input'>{title}</label>
      </Container>

      {values.map((item, i) => {
        return (
          <div
            key={i}
            style={{
              padding: '6px 6px 6px 12px',
              borderRadius: '5px',
              marginBottom: '8px',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
            }}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <b>{item}</b>
            </span>

            <IconRemove
              className={'fas fa-times'}
              onClick={() => handleRemove(i, item)}
            />
          </div>
        )
      })}

      {showInputs && (
        <>
          <Container>
            <TextAreaWrapper
              name={`value-input`}
              placeholder={placeholder}
              maxLength={500}
              value={value}
              rows={4}
              onChange={e => setValue(e.target.value)}
            />
          </Container>

          <DivFlexRight style={{ marginBottom: '8px' }}>
            <EditButton
              type='button'
              onClick={() => setShowInputs(state => !state)}
            >
              Cancelar
            </EditButton>

            {value && (
              <RegisterNewButton type='button' onClick={handleSubmit}>
                Adicionar
              </RegisterNewButton>
            )}
          </DivFlexRight>
        </>
      )}

      {!showInputs && (
        <DivFlexCentered style={{ height: '38px', alignItems: 'center' }}>
          <RegisterNewButton onClick={() => setShowInputs(state => !state)}>
            <IconAdd className='fas fa-plus' />
          </RegisterNewButton>
        </DivFlexCentered>
      )}
    </Form>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  .mb-3 {
    margin-bottom: 0px !important;
  }

  label {
    font-weight: 600;
    margin: 2px 4px;
  }
`

const IconAdd = styled.i`
  color: #1bc25b;
  cursor: pointer;
`

const IconRemove = styled.i`
  color: #ff7d70;
  padding: 5px;
  cursor: pointer;
`
