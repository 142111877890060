import PerfectScrollbar from 'react-perfect-scrollbar'
import SidebarNav from './Nav'
import { ButtonMenu } from '../Header/styles'
import { phoneNumber } from '~/utils/supportPhone'
import HoldingBackofficeContext from '~/config/HoldingBackofficeContext'
import IconButton from '~/components/IconButton'

export default function Sidebar() {
  return (
    <HoldingBackofficeContext.Consumer>
      {pageSettings => (
        <>
          <div
            id='sidebar'
            className={
              `sidebar` +
              (pageSettings.pageSidebarTransparent
                ? ' sidebar-transparent'
                : '')
            }
          >
            <PerfectScrollbar
              className='height-full'
              options={{ suppressScrollX: true }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <SidebarNav pageSettings={pageSettings} />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: pageSettings.pageSidebarMinify
                    ? 'center'
                    : 'flex-end',
                  gap: pageSettings.pageSidebarMinify ? '16px' : '4px',
                }}
              >
                {!pageSettings.pageSidebarMinify && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      fontSize: '14px',
                    }}
                  >
                    <ButtonMenu
                      style={{ color: 'grey' }}
                      target='_blank'
                      rel='noopener noreferrer'
                      type='button'
                      onClick={() =>
                        window.open(
                          `https://api.whatsapp.com/send?phone=${phoneNumber(
                            'support',
                          )}`,
                        )
                      }
                    >
                      <span>
                        <b>Precisa de ajuda?</b>
                      </span>

                      <br />

                      <span>
                        Entre em contato com o nosso suporte clicando aqui
                      </span>
                    </ButtonMenu>
                  </div>
                )}

                {pageSettings.pageSidebarMinify && (
                  <IconButton
                    id={'holding-sidebar-help'}
                    type='details'
                    iconOverride={'fas fa-question'}
                    tooltipOverride={'Precisa de ajuda?'}
                    colorOverride={'#7f2ec2'}
                    onClick={() => {
                      window.open(
                        `https://api.whatsapp.com/send?phone=${phoneNumber(
                          'support',
                        )}`,
                      )
                    }}
                  />
                )}

                <IconButton
                  id={'holding-sidebar-toggle-button'}
                  type='details'
                  colorOverride={'#7f2ec2'}
                  iconOverride={
                    pageSettings.pageSidebarMinify
                      ? 'fa fa-angle-double-right'
                      : 'fa fa-angle-double-left'
                  }
                  tooltipOverride={
                    pageSettings.pageSidebarMinify
                      ? 'Mostrar barra lateral'
                      : 'Ocultar barra lateral'
                  }
                  onClick={() => {
                    // setShow(state => !state)

                    pageSettings.toggleSidebarMinify()
                  }}
                />
              </div>
            </PerfectScrollbar>
          </div>

          <div className='sidebar-bg' />
        </>
      )}
    </HoldingBackofficeContext.Consumer>
  )
}
