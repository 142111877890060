import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import Context from '~/context/createContext'

export default function PageTitle({
  title,
  desc,
  to = -1,
  head = 'h1',
  onHeadButton = () => {
    return
  },
  noButton,
  centered,
  callbackOverride,
  noMargin,
}) {
  const { setShowModal } = useContext(Context)
  const navigate = useNavigate()

  const Component = ({ children }) => {
    return (
      <>
        {head === 'h1' && (
          <h1
            className='page-header'
            style={{ marginBottom: noMargin ? '0px' : '0.75em' }}
          >
            {children}
          </h1>
        )}

        {head === 'h2' && (
          <h2 style={{ marginBottom: noMargin ? '0px' : '0.75em' }}>
            {children}
          </h2>
        )}

        {head === 'h3' && (
          <h3 style={{ marginBottom: noMargin ? '0px' : '0.75em' }}>
            {children}
          </h3>
        )}

        {head === 'h4' && (
          <h4 style={{ marginBottom: noMargin ? '0px' : '0.75em' }}>
            {children}
          </h4>
        )}

        {head === 'h5' && <h5>{children}</h5>}

        {head === 'h6' && (
          <h6 style={{ marginBottom: noMargin ? '0px' : '0.75em' }}>
            {children}
          </h6>
        )}

        {head === 'button' && (
          <button type='button' onClick={onHeadButton}>
            {children}
          </button>
        )}
      </>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: centered ? 'center' : 'flex-start',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Component>
          {!noButton && (
            <button
              type='button'
              onClick={() => {
                if (callbackOverride) {
                  callbackOverride()
                } else {
                  navigate(to)
                }

                setTimeout(() => setShowModal(true), 2000)
              }}
              style={{
                border: 'none',
                fontSize: head === 'h1' ? '20px' : '14px',
                marginRight: '8px',
                backgroundColor: 'transparent',
              }}
            >
              <i className='fas fa-arrow-left' />
            </button>
          )}

          {title}
        </Component>
      </div>

      {desc && (
        <p
          style={{
            fontSize: '13px',
            color: '#777',
          }}
        >
          {desc}
        </p>
      )}
    </div>
  )
}
