import { Form } from '@unform/web'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import {
  DeleteButton,
  ResetButton,
  SearchButton,
} from '~/components/Button/styles'
import Input from '~/components/Input'
import Select from '~/components/Select'
import SlideInput from '~/components/SlideInput'
import TextArea from '~/components/TextArea'
import { getDateFormated } from '~/functions/Date'
import { getBase64 } from '~/functions/File'
import { GetAxios } from '~/services/api'
import { SpaceBetweenDiv } from '~/styles/divs'
import { Avatar, RightCol } from './styles'

export default function AgentDetails({ data, onCancel, onDelete }) {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [agent, setAgent] = useState({})
  const [roles, setRoles] = useState([])
  const [imagePreview, setImagePreview] = useState(
    `data:image/*;base64,${data.avatar}`,
  )
  const [ddi, setDdi] = useState({})

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      var experiences = ''
      var role = {}
      var ddi = {}

      data.experiences.map((item, i) => {
        if (i === 0) {
          experiences += item
        } else {
          experiences += `;${item}`
        }
      })

      const resRoles = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
      )

      resRoles.data.map(item => {
        if (item.value === data.role) {
          role = item
        }
      })

      setRoles(resRoles.data)

      setAgent({
        ...data,
        role: role,
        experiences: experiences,
        since: getDateFormated(data.since, 'YYYY'),
      })
    }

    loadSource()
    getOptionsDDI()

    return () => {
      cancel = true
    }
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )

      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  async function handleAvatar(image) {
    getBase64(image, setImagePreview)
  }

  const setInputField = (fieldName, value) => {
    setAgent({
      ...agent,
      [fieldName]: value,
    })
  }

  async function resetForm() {
    setAgent({})
  }

  async function submitForm() {
    try {
      setLoading(true)

      if (!agent.role) {
        setLoading(false)

        return toast.info('Escolha um perfil.')
      }

      if (agent.avatar && agent.avatar.size > 3145728) {
        setLoading(false)

        return toast.info('Insira uma imagem com tamanho máximo de 3 MB')
      }
      if (
        agent.avatar ||
        window.confirm('Deseja continuar sem o Avatar do Usuário?')
      ) {
        const formData = new FormData()

        formData.append('familyName', agent.familyName)
        formData.append('fullName', agent.fullName)
        formData.append('email', agent.email)
        formData.append('phone', agent.phone ?? '')
        formData.append('cpf', agent.cpf ?? '')
        formData.append('role', agent.role.value)
        formData.append('title', agent.title)
        formData.append('bio', agent.bio)
        formData.append('experiences', agent.experiences ?? '')
        formData.append(
          'since',
          agent.since ? `01/01/${agent.since}` : '01/01/0001',
        )
        formData.append('avatar', agent.avatar)
        formData.append('allowWhatsapp', agent.allowWhatsapp)
        formData.append('disabled', agent.disabled)
        formData.append('viewSales', agent.viewSales)
        formData.append(
          'ddiValue',
          agent.ddiValue?.value != '' ? agent.ddiValue.value : 0,
        )

        await axiosApis.put(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
          formData,
        )

        if (agent.avatar) {
          await axiosApis.put(
            `${process.env.REACT_APP_ENDPOINT_APIS}/agent/avatar`,
            formData,
          )
        }

        toast.success('Usuário editado com sucesso!')

        return navigate(-1)
      }

      return setLoading(false)
    } catch (error) {
      console.log(error)

      toast.info('Não foi possível editar o Usuário.')

      return setLoading(false)
    }
  }

  async function onRemove() {
    try {
      if (window.confirm('Você tem certeza que deseja excluir esse Usuário?')) {
        setLoading(true)

        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agent/${agent.id}`,
        )

        setLoading(false)

        toast.success('Usuário excluído com sucesso!')

        return onDelete()
      }
    } catch (error) {
      toast.info('Não foi possível remover o Usuário.')

      return setLoading(false)
    }
  }

  return (
    <Form ref={formRef} onReset={resetForm} onSubmit={submitForm}>
      <Row>
        <Col sm={12} md={6}>
          <Input
            name={'familyName'}
            label={'Nome de Preferência*'}
            value={agent['familyName']}
            onChange={e => setInputField(e.target.name, e.target.value)}
            maxLength={50}
            disabled={loading}
            required
          />
        </Col>

        <Col sm={12} md={6}>
          <Input
            name={'fullName'}
            label={'Nome Completo *'}
            value={agent['fullName']}
            onChange={e => setInputField(e.target.name, e.target.value)}
            maxLength={50}
            disabled={loading}
            required
          />
        </Col>

        <Col sm={12} md={6}>
          <Input
            name={'email'}
            label={'E-mail *'}
            value={agent['email']}
            onChange={e => setInputField(e.target.name, e.target.value)}
            maxLength={50}
            disabled={true}
            required
          />
        </Col>

        <Col sm={12} md={2}>
          <Select
            name={'ddiValue'}
            label={'DDI'}
            options={ddi}
            value={agent['ddiValue']}
            onChange={e => setInputField('ddiValue', e.target.value)}
            placeholder={'Selecione...'}
            disabled={loading}
            maxLength={30}
            isSearchable
            // required
          />
        </Col>

        <Col sm={12} md={4}>
          <Input
            mask={'(99) 99999-9999'}
            name={'phone'}
            label={'Telefone *'}
            placeholder={'telefone com (DDD)'}
            value={agent['phone']}
            onChange={e => setInputField(e.target.name, e.target.value)}
            disabled={loading}
            required
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <Input
            name={'cpf'}
            label={'CPF'}
            value={agent['cpf']}
            onChange={e => setInputField(e.target.name, e.target.value)}
            disabled={loading}
          />
        </Col>

        <Col sm={12} md={6}>
          <Select
            label={'Perfil'}
            name={'role'}
            options={roles}
            value={agent['role']}
            onChange={e => setInputField('role', e.target.value)}
            placeholder={'Selecione...'}
            disabled={loading}
            maxLength={30}
            isSearchable
            // required
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <Input
            name={'title'}
            label={'Profissão'}
            value={agent['title']}
            onChange={e => setInputField(e.target.name, e.target.value)}
            maxLength={50}
            disabled={loading}
            // required
          />
        </Col>

        <Col sm={12} md={6}>
          <Input
            disabled={false}
            name={'since'}
            label={
              'Anos de Experiência (Informe o ano em que o consultor começou na área)'
            }
            mask={'9999'}
            value={agent['since']}
            onChange={e => setInputField(e.target.name, e.target.value)}
            // required
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <TextArea
            name={'bio'}
            label={'Bio'}
            maxLength={2000}
            placeholder={'Escreva aqui...'}
            value={agent['bio']}
            onChange={e => setInputField(e.target.name, e.target.value)}
            disabled={loading}
            // required
          />
        </Col>

        <Col sm={12} md={6}>
          <Input
            name={'avatar'}
            label={'Avatar'}
            type='file'
            onChange={e => {
              handleAvatar(e.target.files[0])
              setInputField(e.target.name, e.target.files[0])
            }}
            accept='image/png, image/jpg, image/jpeg'
            disabled={loading}
          />

          <Avatar
            style={{
              width: '160px',
              height: '160px',
              backgroundSize: 'cover',
              backgroundImage: `url('${imagePreview}')`,
            }}
          />
        </Col>

        <Col sm={12} md={6}>
          <TextArea
            name={'experiences'}
            label={
              "Especialidades (para adicionar várias, separe-as por ' ; ')"
            }
            value={agent['experiences']}
            onChange={e => setInputField(e.target.name, e.target.value)}
            maxLength={200}
            rows='2'
            disabled={loading}
            // required
          />
        </Col>

        <Col sm={3} md={3}>
          <SlideInput
            name={'allowWhatsapp'}
            label={'Permitir alertas por WhatsApp aos seus viajantes'}
            value={agent['allowWhatsapp']}
            onChange={e => setInputField(e.target.name, e.target.checked)}
          />
        </Col>

        <Col sm={2} md={2}>
          <SlideInput
            name={'disabled'}
            label={'Desativar consultor'}
            value={agent['disabled']}
            onChange={e => setInputField(e.target.name, e.target.checked)}
          />
        </Col>

        <Col sm={2} md={2}>
          <SlideInput
            name={'viewSales'}
            label={'Mostrar módulo de vendas?'}
            value={agent['viewSales']}
            onChange={e => setInputField(e.target.name, e.target.checked)}
          />
        </Col>
      </Row>
      <SpaceBetweenDiv>
        {/* <DeleteButton type='button' onClick={onRemove}>
          Excluir
        </DeleteButton> */}

        <RightCol>
          <ResetButton type='button' onClick={onCancel}>
            Cancelar
          </ResetButton>

          <SearchButton disabled={loading} type='submit'>
            Salvar
          </SearchButton>
        </RightCol>
      </SpaceBetweenDiv>
    </Form>
  )
}
