import styled from 'styled-components'

export default function DivForHTML({ text, imgSrc, imgAlt }) {
  return (
    <Container
      dangerouslySetInnerHTML={{
        __html: `
          ${
            imgSrc
              ? `
                  <img
                    src='${imgSrc}'
                    alt='${imgAlt}'
                    style='
                      width: 50%;
                      float: right;
                      margin: 0 0 16px 16px;
                      border: 1px solid #ccc;
                      border-radius: 6px;
                      object-fit: contain;
                      break-inside: avoid-page;
                    '
                  />
                `
              : ''
          }

          ${text}
        `,
      }}
    />
  )
}

const Container = styled.div`
  width: 100%;

  p {
    margin: 0;
  }

  ul {
    margin: 0;
  }
`
