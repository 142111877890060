import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
`

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px;
`

export const TLink = styled(Link)`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
`

export const RightContent = styled.div`
  color: #000000;
  opacity: 0.92;
  background-color: #fff !important;
  width: 350px;
  padding: 15px;

  @media (max-width: 768px) {
    width: 100%;
  }

  .g-recaptcha {
    transform: scale(0.75);
    transform-origin: center;
  }

  /* i:hover {
  color: #00fcb6;
  cursor: pointer;
  } */

  /*.btn-disabled-login{
    background: #40c3d7;
  }*/
`
