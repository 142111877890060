import CalendarDefault from '../DefaultCalendar'

export default function CalendarSchedule({
  renderDay,
  onClickDay = () => {},
  onChangeMonth = () => {},
}) {
  return (
    <CalendarDefault
      renderDay={renderDay}
      onClickDay={onClickDay}
      onChangeMonth={onChangeMonth}
    />
  )
}
