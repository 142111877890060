import { Form } from '@unform/web'
import moment from 'moment'
import { useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { ResetButton, SaveButton } from '~/components/Button/styles'
import CitiesSelect from '~/components/CitiesSelect'
import Input from '~/components/Input'
import ValueInput from '~/components/ValueInput'
import { getDateFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import { Container, RightCol } from './styles'
import TextArea from '~/components/TextArea'

export default function Create({ toCreate, onClose }) {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)

  const initialState = {
    carData: {
      locator: '',
      provider: '',
      origin: '',
      originAddress: '',
      destiny: '',
      destinyAddress: '',
      checkin: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
      timeCheckin: getDateFormated(toCreate.date, '00:00'),
      checkout: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
      timeCheckout: getDateFormated(toCreate.date, '00:00'),
      originPurchase: '',
      tips: '[]',
    },
  }

  const [pageProps, setPageProps] = useState(initialState)

  const setInputField = (fieldName, value) => {
    const newData = {
      ...pageProps,
      carData: {
        ...pageProps.carData,
        [fieldName]: value,
      },
    }
    setPageProps(newData)
  }

  const resetForm = () => {
    setPageProps(initialState)
  }

  async function handleSubmit() {
    try {
      setLoading(true)

      var hoje = moment().format('YYYY-MM-DD')

      if (
        pageProps.carData.checkin < hoje &&
        !window.confirm(
          'Você inseriu uma data que já passou. Deseja continuar?',
        )
      ) {
        setLoading(false)
        return
      }

      await axiosApis.post(`${process.env.REACT_APP_ENDPOINT_APIS}/travelcar`, {
        locator: pageProps.carData.locator,
        originPurchase: pageProps.carData.originPurchase,
        provider: pageProps.carData.provider,
        origin: pageProps.carData.origin.value,
        originAddress: pageProps.carData.originAddress,
        destiny: pageProps.carData.destiny.value,
        destinyAddress: pageProps.carData.destinyAddress,
        checkin:
          pageProps.carData.checkin + ' ' + pageProps.carData.timeCheckin,
        checkout:
          pageProps.carData.checkout + ' ' + pageProps.carData.timeCheckout,
        travelId: travel.id,
        tips: pageProps.carData.tips,
        description: pageProps.carData.description,
      })
      setLoading(false)

      toast.success('Carro criado com sucesso!')

      onClose(true)

      setLoading(false)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível criar o carro.')
    }
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} md={6} lg={4}>
            <Input
              disabled={false}
              name='originPurchase'
              label={'Origem da Compra'}
              maxLength={30}
              value={pageProps.carData.originPurchase}
              onChange={e => setInputField('originPurchase', e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Input
              disabled={false}
              name='locator'
              label={'Código Reserva/Localizador'}
              maxLength={20}
              value={pageProps.carData.locator}
              onChange={e => setInputField('locator', e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Input
              disabled={false}
              name='provider'
              label={'Locadora/Fornecedor'}
              maxLength={30}
              value={pageProps.carData.provider}
              onChange={e => setInputField('provider', e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Input
              disabled={false}
              name='originAddress'
              label={'Local de partida'}
              type='text'
              value={pageProps.carData.originAddress}
              maxLength={200}
              onChange={e => setInputField('originAddress', e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <CitiesSelect
              name='origin'
              label={'Cidade do Transfer/Locação'}
              placeholder={'Pesquise por uma cidade...'}
              onChange={e => setInputField(e.target.name, e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={2}>
            <Input
              disabled={false}
              name='checkin'
              label={'Início *'}
              required
              type='date'
              value={pageProps.carData.checkin}
              onChange={e => setInputField('checkin', e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={2}>
            <Input
              disabled={false}
              name='timeCheckin'
              label={'Hora do Início *'}
              required
              type='time'
              value={pageProps.carData.timeCheckin}
              onChange={e => setInputField('timeCheckin', e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Input
              disabled={false}
              name='destinyAddress'
              label={'local de chegada'}
              type='text'
              value={pageProps.carData.destinyAddress}
              maxLength={200}
              onChange={e => setInputField('destinyAddress', e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={4}>
            <CitiesSelect
              name='destiny'
              label={'Cidade da Chegada/Entrega'}
              placeholder={'Pesquise por uma cidade...'}
              onChange={e => setInputField(e.target.name, e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={2}>
            <Input
              disabled={false}
              name='checkout'
              type='date'
              label={'Fim *'}
              required
              value={pageProps.carData.checkout}
              onChange={e => setInputField('checkout', e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={2}>
            <Input
              disabled={false}
              name='timeCheckin'
              label={'Hora do Fim *'}
              required
              type='time'
              value={pageProps.carData.timeCheckout}
              onChange={e => setInputField('timeCheckout', e.target.value)}
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={3}>
            <ValueInput
              name='tips'
              type='value'
              title='Amenidades'
              placeholder='Descrição'
              defaultValue={pageProps.carData.tips}
              onChange={e => setInputField('tips', e.target.value)}
            />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <TextArea
              name='description'
              label='Observação'
              placeholder='Observação'
              defaultValue={pageProps.carData.description}
              onChange={e => setInputField('description', e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <RightCol>
            <ResetButton onClick={resetForm} typer='reset'>
              Limpar
            </ResetButton>

            <SaveButton disabled={loading} type='submit'>
              Salvar
            </SaveButton>
          </RightCol>
        </Row>
      </Form>
    </Container>
  )
}
