import { useSelector } from 'react-redux'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import DefaultForm from '~/components/DefaultForm'
import { Container } from '~/styles/container'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import ReactTable from '~/components/Table'
import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import ModalDefault from '~/components/ModalDefault'
import { toast } from 'react-toastify'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'
import { FormatValueBRL } from '~/functions/FormatValue'

export default function ProposalList() {
  const [loading, setLoading] = useState(false)
  const [proposals, setProposals] = useState([])
  // const [customers, setCustomers] = useState([])
  const [newCustomer, setNewCustomer] = useState(false)
  const [proposalId, setProposalId] = useState(0)
  const { axiosApis } = GetAxios()
  const [showModalAssociate, setShowModalAssociate] = useState(false)
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate()
  const [totalValue, setTotalValue] = useState(0)
  const [ddi, setDdi] = useState({})
  const [selectDdi, setSelectDdi] = useState({})

  // useEffect(() => {
  //   requestApiListCustomers()
  // }, [])

  useEffect(() => {
    requestApiGetAllProposal()
    getOptionsDDI()
    setSelectDdi({
      label: '',
      value: '',
    })
  }, [showModalAssociate])

  const requestApiGetAllProposal = async () => {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal`,
      )

      const totalvalue = response.data.reduce((a, b) => {
        return a + b.fullValue
      }, 0)

      setTotalValue(totalvalue)

      setProposals(response.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const onSubmit = async data => {
    try {
      setLoading(true)

      const body = {
        ...data,
        proposalStatus: data.proposalStatus?.value,
        id: data.id == '' ? 0 : data.id,
      }

      const response = await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/for-filter`,
        body,
      )

      const totalvalue = response.data.reduce((a, b) => {
        return a + b.fullValue
      }, 0)

      setTotalValue(totalvalue)
      setProposals(response.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  async function showDetails(e) {
    navigate('/proposal/edit', { state: { id: e } })
  }
  async function showProposal(e) {
    // navigate('/proposta/', { state: { id: e } })
    window.open('/proposta/' + e, '_blank')
  }

  // const requestApiListCustomers = async () => {
  //   try {
  //     const { data } = await axiosApis.get(
  //       `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/list/customers`,
  //     )

  //     setCustomers(data)
  //   } catch (err) {
  //     setLoading(false)
  //     console.log(err)
  //   }
  // }

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  const connectCustomer = async data => {
    try {
      setLoading(true)

      const body = {
        label: data.customer?.label,
        value: data.customer?.value,
        newCustomer: data.newCustomer,
        name: data.name,
        email: data.email,
        phone: data.phone,
        ddiValue: data.ddi?.value,
        id: proposalId,
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/add-customer`,
        body,
      )

      handleShowModalAssociate()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.info(err?.response?.data)
      console.log(err)
    }
  }

  const handleShowModalAssociate = () => {
    setNewCustomer(false)
    setShowModalAssociate(!showModalAssociate)
  }

  const handleNewCustomer = () => {
    setNewCustomer(!newCustomer)
  }

  const sendProposal = async (phone, token, clientName, id, ddi) => {
    try {
      setLoading(true)

      const body = {
        phone,
        link: `${window.location.origin}/proposta/${token}`,
        clientName,
        id,
        ddi,
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/send-proposal`,
        body,
      )

      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  async function handleSubmitDelete(id) {
    try {
      setLoading(true)

      if (
        window.confirm('Você tem certeza que deseja excluir essa proposta?')
      ) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/${id}`,
        )

        setLoading(false)
        toast.success('Proposta excluída com sucesso! ')
        requestApiGetAllProposal()
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const GetInformationToSendProposalWpp = async proposal => {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/customer/${proposal.clientId}`,
      )

      const message = `Você tem certeza que deseja enviar a proposta ${proposal.title} para o número ${data.phone}?`

      if (!data.phone)
        toast.info('O cliente em questão não tem um número cadastrado')

      if (data.phone && window.confirm(message)) {
        sendProposal(
          data.phone,
          proposal.token,
          proposal.clientName,
          proposal.id,
          data.ddi,
        )
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const cloneProposal = async proposal => {
    try {
      setLoading(true)

      await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/clone/${proposal.id}`,
      )

      await requestApiGetAllProposal()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const createTravel = async proposalId => {
    try {
      setLoading(true)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/create-travel/${proposalId}`,
      )

      toast.success('Viagem criada com sucesso!')

      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <Container>
      <h1 className='page-header'>
        Proposta
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Pesquisar biblioteca de propostas feitas e criar nova proposta.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'number',
                name: 'id',
                label: 'Número da proposta',
                placeholder: 'Número da proposta',
                min: 0,
                sm: 3,
                lg: 3,
                md: 3,
              },
              {
                type: 'text',
                name: 'title',
                label: 'Titulo',
                placeholder: 'Titulo',
                sm: 3,
                lg: 3,
                md: 3,
              },
              {
                type: 'text',
                name: 'customer',
                label: 'Cliente',
                placeholder: 'Cliente',
                sm: 3,
                lg: 3,
                md: 3,
              },
              {
                type: 'text',
                name: 'agent',
                label: 'Consultor',
                placeholder: 'Consultor',
                sm: 3,
                lg: 3,
                md: 3,
              },
              {
                type: 'select',
                name: 'proposalStatus',
                label: 'Status',
                options: [
                  { label: 'Aprovado', value: 'Aprovado' },
                  { label: 'Em análise', value: 'Em análise' },
                  { label: 'Cancelada', value: 'Cancelada' },
                  { label: 'Em preparação', value: 'Em preparação' },
                ],
                sm: 2,
                lg: 2,
                md: 2,
              },
              {
                type: 'date',
                name: 'createdAtIni',
                label: 'Data inicial',
                sm: 3,
                lg: 3,
                md: 3,
              },
              {
                type: 'date',
                name: 'createdAtEnd',
                label: 'Data final',
                sm: 3,
                lg: 3,
                md: 3,
              },
              // user.role == 'admin'
              //   ? {
              //       type: 'text',
              //       name: 'agent',
              //       label: 'Consultor',
              //       placeholder: 'Consultor',
              //     }
              //   : {
              //       sm: 12,
              //       lg: 12,
              //       md: 12,
              //     },
              {
                type: 'check',
                name: 'proposalWithCustomer',
                label: 'Proposta com cliente',
                sm: 2,
                lg: 2,
                md: 2,
              },
              {
                type: 'check',
                name: 'template',
                label: 'Proposta template',
                sm: 2,
                lg: 2,
                md: 2,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      {showModalAssociate && (
        <ModalDefault
          title='Conectar Cliente'
          handleShow={handleShowModalAssociate}
          scroll='inherit'
          content={
            <DefaultForm
              callbackSubmit={connectCustomer}
              callbackReset={() => {
                return
              }}
              inputs={[
                {
                  type: 'check',
                  name: 'newCustomer',
                  label: 'Novo cliente?',
                  onChange: () => handleNewCustomer(),
                  // required: true,
                  sm: 12,
                  lg: 12,
                  md: 12,
                },
                newCustomer
                  ? {
                      sm: 12,
                      lg: 12,
                      md: 12,
                    }
                  : {
                      type: 'customerSelect',
                      name: 'customer',
                      label: 'Cliente',
                      placeholder: 'Pesquise por uma cliente',
                      required: true,
                      sm: 12,
                      lg: 12,
                      md: 12,
                    },
                newCustomer
                  ? {
                      type: 'text',
                      name: 'name',
                      label: 'Nome',
                      placeholder: 'Nome',
                      required: true,
                      sm: 4,
                      lg: 4,
                      md: 4,
                    }
                  : {},
                newCustomer
                  ? {
                      type: 'text',
                      name: 'email',
                      label: 'Email',
                      placeholder: 'Email',
                      required: true,
                      sm: 4,
                      lg: 4,
                      md: 4,
                    }
                  : {},
                newCustomer
                  ? {
                      type: 'select',
                      name: 'ddi',
                      label: 'DDI',
                      options: ddi,
                      placeholder: 'Selecione...',
                      onChange: e => setSelectDdi(e.target.value),
                      sm: 2,
                      md: 2,
                      lg: 2,
                      xl: 2,
                    }
                  : {},
                newCustomer
                  ? selectDdi.value == '' || selectDdi.value == 55
                    ? {
                        type: 'phone',
                        name: 'phone',
                        label: 'Telefone',
                        required: true,
                        sm: 12,
                        lg: 2,
                      }
                    : {
                        type: 'text',
                        name: 'phone',
                        label: 'Telefone',
                        mask: '999 99999999',
                        required: true,
                        sm: 12,
                        lg: 2,
                      }
                  : {},
              ]}
            />
          }
        />
      )}

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}>
          <div style={{ paddingRight: '5%' }}>
            <b>Total das propostas: {FormatValueBRL(totalValue)}</b>
          </div>
          {/* {(user?.agencyCnpj == '48.038.472/0001-60' ||
            user?.email == 'comercial2@tripmee.com.br') && (
            <LinkRegisterNewTraveler to='availability'>
              Verificar disponibilidade
            </LinkRegisterNewTraveler>
          )} */}
          <LinkRegisterNewTraveler to='create'>
            Nova proposta
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <ReactTable
            data={proposals}
            columns={[
              {
                Header: 'Número',
                accessor: 'id',
                width: 75,
              },
              {
                Header: 'Titulo',
                accessor: 'title',
                width: 200,
              },
              {
                Header: 'Cliente',
                accessor: 'clientName',
                width: 175,
              },
              {
                Header: 'Data de criação',
                accessor: 'createdAt',
                width: 120,
              },
              {
                Header: 'Status',
                accessor: 'proposalStatus',
                width: 120,
              },
              {
                Header: 'Valor da proposta',
                accessor: 'fullValue',
                width: 140,
                Cell: props => {
                  return FormatValueBRL(props.row.original.fullValue)
                },
              },
              {
                Header: 'Consultor',
                accessor: 'agent',
                width: 120,
              },
              {
                Header: '',
                accessor: 'detalhes',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.index}
                      type={'details'}
                      onClick={() => showDetails(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'generateTravel',
                Cell: props => (
                  <IconButton
                    id={props.row.index}
                    type={'travel'}
                    iconOverride={'fas fa-plane'}
                    tooltipOverride={'Cria viagem a partir dessa proposta'}
                    onClick={() => createTravel(props.row.original.id)}
                  />
                ),
                width: 20,
              },
              {
                Header: '',
                accessor: 'clientAssociate',
                Cell: props =>
                  props.row.original.clientId == 0 &&
                  props.row.original.clientName == null &&
                  user.id == props.row.original.userId ? (
                    <IconButton
                      id={props.row.index}
                      type={'conectar'}
                      iconOverride={'fa fa-link'}
                      tooltipOverride={'Conectar cliente'}
                      onClick={() => {
                        setProposalId(props.row.original.id)
                        handleShowModalAssociate()
                      }}
                    />
                  ) : (
                    <p></p>
                  ),
                width: 20,
              },
              {
                Header: '',
                accessor: 'pdf',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.index}
                      type={'view'}
                      iconOverride={'far fa-eye'}
                      tooltipOverride={'Ver proposta'}
                      onClick={() => showProposal(props.row.original.token)}
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'whatsappSend',
                Cell: props => {
                  if (
                    props.row.original.clientId &&
                    props.row.original.clientName &&
                    props.row.original.clientPhone
                  ) {
                    return (
                      <IconButton
                        id={props.row.index}
                        type={'wpp'}
                        iconOverride={'fab fa-whatsapp'}
                        tooltipOverride={
                          'Enviar proposta para o cliente pelo whatsapp'
                        }
                        onClick={() =>
                          GetInformationToSendProposalWpp(props.row.original)
                        }
                      />
                    )
                  }

                  return null
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'clone',
                Cell: props => (
                  <IconButton
                    id={props.row.index}
                    type={'clone'}
                    iconOverride={'far fa-clone'}
                    tooltipOverride={'Clonar proposta'}
                    onClick={() => cloneProposal(props.row.original)}
                  />
                ),
                width: 20,
              },
              {
                Header: '',
                accessor: 'delete-viagem',
                Cell: props => {
                  return (
                    <IconButton
                      id={props.row.index}
                      type={'trash'}
                      onClick={() => handleSubmitDelete(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
