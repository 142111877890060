import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { getDateFormated } from '~/functions/Date'
import { axiosApis } from '~/services/api'
import { Container } from '~/styles/container'

export default function Edit({ toEdit, onClose, onDelete }) {
  const [genericItem, setGenericItem] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)
        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelitem/${toEdit.entity.id}`,
        )

        setGenericItem({
          ...data,
          start: getDateFormated(data.start, 'YYYY-MM-DD'),
          timeStart: getDateFormated(data.start, 'HH:mm'),
          end: getDateFormated(data.end, 'YYYY-MM-DD'),
          timeEnd: getDateFormated(data.end, 'HH:mm'),
          city: {
            label: data.city,
            value: data.cityId,
          },
          startAddress: data.startAddress,
        })

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function handleSubmit(data) {
    const formData = new FormData()

    formData.append('originPurchase', data.originPurchase)
    formData.append('title', data.title)
    formData.append('desc', data.desc)
    formData.append('city', data.city.value)
    formData.append('start', data.start + ' ' + data.timeStart)
    formData.append('end', data.end + ' ' + data.timeEnd)
    formData.append('image', data.image)
    formData.append('tips', data.tips)
    formData.append('id', data.id)
    formData.append('startAddress', data.startAddress)

    try {
      setLoading(true)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelitem`,
        formData,
      )

      setLoading(false)

      toast.success('Serviço editado com sucesso!')

      onClose(true)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível editar o serviço.')
    }
  }

  async function handleDelete() {
    setLoading(true)

    await onDelete({ id: toEdit.entity.id, type: toEdit.entity.type })

    await onClose(true)

    setLoading(false)
  }

  return (
    <Container>
      <DefaultForm
        callbackSubmit={handleSubmit}
        callbackDelete={handleDelete}
        defaultValue={genericItem}
        loading={loading}
        disabled={loading}
        inputs={[
          {
            type: 'text',
            name: 'title',
            label: 'Título',
            required: true,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'text',
            name: 'startAddress',
            label: 'Endereço',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
            maxLength: 200,
          },

          {
            type: 'city',
            name: 'city',
            label: 'Cidade',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'text',
            name: 'originPurchase',
            label: 'Fornecedor',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'date',
            name: 'start',
            label: 'Data do Início',
            row: '2',
            required: true,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeStart',
            label: 'Hora do Início',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'date',
            name: 'end',
            label: 'Data do Fim',
            required: true,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeEnd',
            label: 'Hora do Fim',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'html',
            name: 'desc',
            label: 'Descrição',
            required: true,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 6,
          },
          {
            type: 'image',
            name: 'image',
            label: 'Imagem',
            sm: 12,
            md: 12,
            lg: 6,
            xl: 3,
          },
          {
            type: 'value',
            name: 'tips',
            label: 'Amenidades',
            placeholder: 'Descrição',
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
        ]}
      />
    </Container>
  )
}
