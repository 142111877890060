import { useState, useRef, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { Form } from '@unform/web'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import Input from '~/components/Input'
import { SaveButton, DeleteButton } from '~/components/Button/styles'
import { Container, RightCol, LeftCol } from './styles'
import ValueInput from '~/components/ValueInput'
import { getDateFormated } from '~/functions/Date'
import TextArea from '~/components/TextArea'

export default function Edit({ toEdit, onClose, onDelete }) {
  const formRef = useRef(null)
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)

  const [airToEdit, setAirToEdit] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveltrain/${toEdit.entity.id}`,
        )

        setAirToEdit({
          airportOrigin: data.airportOrigin,
          airportDestiny: data.airportDestiny,
          flight: data.flight,
          cia: data.cia,
          ciaIata: data.ciaIata,
          seat: data.seat,
          originPurchase: data.originPurchase,
          origin: data.origin,
          destiny: data.destiny,
          originIata: data.originIata,
          destinyIata: data.destinyIata,
          departure: getDateFormated(data.departure, 'YYYY-MM-DD'),
          arrival: getDateFormated(data.arrival, 'YYYY-MM-DD'),
          locator: data.locator,
          timeDeparture: getDateFormated(data.departure, 'HH:mm'),
          timeArrival: getDateFormated(data.arrival, 'HH:mm'),
          tips: data.tips,
          description: data.description,
          id: data.id,
        })

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    getSource()
  }, [])

  async function EditSubmit() {
    try {
      setLoading(true)

      const tempoDeparture = airToEdit.departure + ' ' + airToEdit.timeDeparture
      const tempoArrival = airToEdit.arrival + ' ' + airToEdit.timeArrival

      const body = {
        airportOrigin: airToEdit.airportOrigin,
        airportDestiny: airToEdit.airportDestiny,
        flight: airToEdit.flight,
        cia: airToEdit.cia,
        ciaIata: airToEdit.ciaIata,
        origin: airToEdit.origin,
        originIata: airToEdit.originIata,
        destiny: airToEdit.destiny,
        destinyIata: airToEdit.destinyIata,
        departure: tempoDeparture,
        arrival: tempoArrival,
        originPurchase: airToEdit.originPurchase,
        locator: airToEdit.locator,
        seat: airToEdit.seat,
        tips: airToEdit.tips,
        description: airToEdit.description,
        id: airToEdit.id,
        travelId: travel.id,
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveltrain`,
        body,
      )

      setLoading(false)

      toast.success('Trem editado com sucesso!')

      onClose(true)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível editar o aéreo.')
    }
  }

  async function onDeleteAir() {
    setLoading(true)

    await onDelete({ id: toEdit.entity.id, type: toEdit.entity.type })

    await onClose(true)

    setLoading(false)
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={EditSubmit}>
        <Row>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Input
              disabled={false}
              name={'originPurchase'}
              label={'Fornecedor'}
              value={airToEdit.originPurchase}
              maxLength={30}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  originPurchase: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={4}>
            <Input
              disabled={false}
              name={'seat'}
              label={'Assento'}
              maxLength={20}
              value={airToEdit.seat}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  seat: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={4}>
            <Input
              disabled={false}
              name={'locator'}
              label={'Bilhete/Localizador'}
              value={airToEdit.locator}
              maxLength={20}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  locator: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={4}>
            <Input
              disabled={false}
              name={'cia'}
              label={'Cia ferroviária'}
              value={airToEdit.cia}
              maxLength={30}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  cia: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={4}>
            <Input
              disabled={false}
              name={'ciaIata'}
              label={'Código cia ferroviária'}
              value={airToEdit.ciaIata}
              maxLength={5}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  ciaIata: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={4}>
            <Input
              disabled={false}
              name='flight'
              label={'Número do trem'}
              type={'number'}
              value={airToEdit.flight}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  flight: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={6}>
            <Input
              disabled={false}
              name={'airportOrigin'}
              label={'Estação cidade de origem'}
              placeholder={'Estação cidade de origem'}
              value={airToEdit.airportOrigin}
              maxLength={100}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  airportOrigin: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input
              disabled={false}
              name={'origin'}
              label={'Origem'}
              placeholder={'Origem'}
              value={airToEdit.origin}
              maxLength={100}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  origin: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={3} xl={6}>
            <Input
              disabled={false}
              name='departure'
              label={'Data de partida *'}
              type='date'
              value={airToEdit.departure}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  departure: e.target.value,
                })
              }
              required
            />
          </Col>

          <Col sm={12} md={6} lg={3} xl={6}>
            <Input
              name='hora-cons'
              type='time'
              value={airToEdit.timeDeparture}
              label={'Horário de partida *'}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  timeDeparture: e.target.value,
                })
              }
              required
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input
              disabled={false}
              name={'destiny'}
              label={'Estação cidade de destino'}
              placeholder={'Estação cidade de destino'}
              value={airToEdit.airportDestiny}
              maxLength={100}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  airportDestiny: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input
              disabled={false}
              name={'destiny'}
              label={'Destino'}
              placeholder={'Destino'}
              value={airToEdit.destiny}
              maxLength={100}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  destiny: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={6} lg={3} xl={6}>
            <Input
              disabled={false}
              name='arrival'
              label={'Data de chegada *'}
              type='date'
              value={airToEdit.arrival}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  arrival: e.target.value,
                })
              }
              required
            />
          </Col>

          <Col sm={12} md={6} lg={3} xl={6}>
            <Input
              name='hora-cons'
              type='time'
              value={airToEdit.timeArrival}
              label={'Horário de chegada *'}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  timeArrival: e.target.value,
                })
              }
              required
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={4}>
            <ValueInput
              name='tips'
              type='value'
              title='Amenidades'
              placeholder='Descrição'
              defaultValue={airToEdit.tips}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  tips: e.target.value,
                })
              }
            />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <TextArea
              name='description'
              label='Observação'
              placeholder='Observação'
              defaultValue={airToEdit.description}
              onChange={e =>
                setAirToEdit({
                  ...airToEdit,
                  description: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <LeftCol>
            <DeleteButton onClick={onDeleteAir} type='button'>
              Excluir
            </DeleteButton>
          </LeftCol>

          <RightCol>
            <SaveButton disabled={loading} type='submit'>
              Salvar
            </SaveButton>
          </RightCol>
        </Row>
      </Form>
    </Container>
  )
}
