import { useLocation } from 'react-router-dom'
import SidebarNavItem from './NavItem'
import { NavUlItems } from './styles'
import useHoldingBackofficeMenus from '~/hooks/useHoldingBackofficeMenus'

export default function SidebarNav({ pageSettings }) {
  let location = useLocation()
  const { menus } = useHoldingBackofficeMenus()

  return (
    <NavUlItems minify={pageSettings.pageSidebarMinify}>
      {menus.map((item, i) => {
        return (
          <SidebarNavItem
            data={item}
            location={location}
            pageSettings={pageSettings}
            keyItem={i}
            key={i}
          />
        )
      })}
    </NavUlItems>
  )
}
