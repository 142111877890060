import { Table } from 'reactstrap'
import * as S from '../TravelerHistory/styles'
import { getDateFormated } from '~/functions/Date'

export default function ServiceHistory({ traveler, index }) {
  return (
    <S.HistoryContainer
      style={{ background: `${index % 2 === 0 ? '' : '#f5f5f5'}` }}
    >
      <Table striped>
        <tbody>
          <tr>
            <th scope='row'>Itinerário</th>
            <td>{traveler?.title}</td>
          </tr>
          <tr>
            <th scope='row'>Forma de Contato</th>
            <td>{traveler?.contactForm}</td>
          </tr>
          <tr>
            <th scope='row'>Data do contato</th>
            <td>
              {traveler?.contactDate
                ? getDateFormated(traveler.contactDate)
                : ''}
            </td>
          </tr>
        </tbody>
      </Table>
    </S.HistoryContainer>
  )
}
