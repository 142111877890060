import styled from 'styled-components'

export default function PreviewAppTheme({
  image,
  primaryColor = '#7f2ec2',
  secondaryColor = '#d54a26',
}) {
  return (
    <Container>
      <Content>
        <Device>
          <Screen>
            <StatusBar
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
            >
              <i className='fas fa-bell' />

              <div>
                <i className='fas fa-wifi' />
                &nbsp;
                <i className='fas fa-battery-full' />
                &nbsp;
                <b>10:00</b>
              </div>
            </StatusBar>

            <Header primaryColor={primaryColor} secondaryColor={secondaryColor}>
              {image && (
                <img
                  src={image ?? ''}
                  alt='Logo da agência'
                  style={{
                    width: '25%',
                  }}
                />
              )}

              <b>Olá, Viajante</b>

              <i className='fas fa-bars' />
            </Header>

            <Body primaryColor={primaryColor} secondaryColor={secondaryColor}>
              <BodyCardTitle
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              >
                <div>
                  <i className='fas fa-plane' /> &nbsp;
                  <b>Minhas viagens</b>
                </div>

                <span>Ver mais</span>
              </BodyCardTitle>

              <BodyCard
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              >
                <span>Viagem para Cancún</span>

                <span>Em viagem</span>
              </BodyCard>

              <BodyCardTitle
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              >
                <div>
                  <i className='fas fa-users' /> &nbsp;
                  <b>Compartilhadas comigo</b>
                </div>

                <span>Ver mais</span>
              </BodyCardTitle>

              <BodyCard
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              >
                <span>Passeio com amigos</span>

                <span>1 jan - 5 jan</span>
              </BodyCard>

              <BodyCardTitle
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              >
                <div>
                  <i className='fas fa-user' /> &nbsp;
                  <b>Meus consultores</b>
                </div>

                <span>Ver mais</span>
              </BodyCardTitle>

              <BodyCard
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              >
                <span>Fulano de Tal</span>
                <span>Consultor de viagens nacionais</span>
              </BodyCard>
            </Body>

            <NavigationBar
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
            >
              <i className='fas fa-bars' />
              <i className='fas fa-circle' />
              <i className='fas fa-chevron-left' />
            </NavigationBar>
          </Screen>
        </Device>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  min-height: 480px;
  overflow: auto;
`

const Content = styled.div`
  margin: 0 auto;
  width: 240px;
  height: 480px;
`

const Device = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  padding: 16px 8px;
  background-color: black;
`

const Screen = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
`

const StatusBar = styled.div`
  background-color: ${props => props.primaryColor};
  border-radius: 16px 16px 0 0;
  padding: 4px 8px;
  font-size: 11px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  i,
  b {
    color: #fff;
  }
`

const Header = styled.div`
  flex: 1;
  background-color: #e6e6e6;
  padding: 8px 16px;
  font-size: 13px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  i,
  b {
    color: ${props => props.secondaryColor};
  }
`

const Body = styled.div`
  flex: 4;
  background-color: #ffffff;
  border-radius: 0 0 16px 16px;
  font-size: 9px;
  padding: 16px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const BodyCardTitle = styled.div`
  flex: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;

  i,
  span {
    color: ${props => props.secondaryColor};
  }
`

const BodyCard = styled.div`
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 80px;
  margin-bottom: 16px;
  background-color: #e6e6e6;
  padding: 8px;

  & > * {
    &:first-child {
      font-weight: 600;
      font-size: 12px;
      color: ${props => props.primaryColor};
    }

    &:last-child {
      font-weight: 600;
      font-size: 9px;
    }
  }
`

const NavigationBar = styled.div`
  flex: 0.5;
  padding: 0 15%;
  background-color: #020202;
  border-radius: 0 0 14px 14px;
  color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`
