import { useEffect, useState } from 'react'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import Table from './Table'
import { LinkRegisterNewPanel } from '~/components/Link/styles'
import { Container } from '~/styles/container'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import styled from 'styled-components'
import AutoNotifications from './AutoNotifications'
import Divisor from '~/components/Divisor'
import IconButton from '~/components/IconButton'

export default function NotificationsList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [travelsOptions, setTravelsOptions] = useState([])
  const [travelSelected, setTravelSelected] = useState()

  useEffect(() => {
    let cancel = false

    requestApiGetAllTravels(cancel)
    requestApiGetAllNotifications()

    return () => {
      cancel = true
    }
  }, [])

  async function requestApiGetAllNotifications() {
    try {
      setLoading(true)
      const { data: dataSchedules } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications`,
      )

      const { data: dataPushs } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications/push`,
      )

      setNotifications([...dataSchedules, ...dataPushs])
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  async function requestApiGetAllTravels(cancel) {
    if (cancel) return

    try {
      const { data: dataTravels } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel`,
      )

      setTravelsOptions([{ label: 'Ver todas', value: '0' }, ...dataTravels])
    } catch (error) {
      console.log(error)
    }
  }

  async function onChangeTravel(e) {
    try {
      setTravelSelected(e.target.value)

      if (e.target.value.value == '0') {
        setTravelSelected()
        requestApiGetAllNotifications()
      } else {
        setLoading(true)
        const { data: dataSchedules } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications/${e.target.value.value}`,
        )

        const { data: dataPushs } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelnotifications/push/${e.target.value.value}`,
        )

        setNotifications([...dataSchedules, ...dataPushs])
        setLoading(false)
      }
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  return (
    <Container>
      <Header>
        <h1 className='page-header'>
          Notificações de Viagens
          <IconButton
            id={1}
            type={'info'}
            iconOverride={'fa fa-info-circle'}
            tooltipOverride={
              'Na tela de notificação poderá enviar as notificação direta para todos os viajantes e acompanhantes da viagem selecionada. A Mensagem será visualizada pelo Viajante através do App.'
            }
            style={{ fontSize: '12px' }}
          />
        </h1>

        <DefaultForm
          inputs={[
            {
              type: 'select',
              name: 'travel',
              placeholder: 'Selecione uma viagem...',
              options: travelsOptions,
              onChange: onChangeTravel,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
          ]}
        />
      </Header>

      <Panel>
        <PanelHeader noButton>
          <LinkRegisterNewPanel to='/notifications/create'>
            Agendar Notificação
          </LinkRegisterNewPanel>

          {/* <LinkRegisterNewPanel
              to='/notifications/create-push'
              style={{ margin: 0 }}
            >
              Enviar Notificação Agora
            </LinkRegisterNewPanel> */}
        </PanelHeader>

        <PanelBody>
          <Table loading={loading} notifications={notifications} />

          {!loading && travelSelected && (
            <>
              <Divisor />

              <AutoNotifications travel={{ id: travelSelected.value }} />
            </>
          )}
        </PanelBody>
      </Panel>
    </Container>
  )
}

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 920px) {
    flex-direction: column;
  }
`
