const sendMoments = [
  {
    id: 1,
    label: '1 dia antes do INÍCIO da viagem',
    value: 'start-before-1',
    increment: -1,
  },
  {
    id: 2,
    label: '1 dia antes do FIM da viagem',
    value: 'end-before-1',
    increment: -1,
  },
  {
    id: 3,
    label: '3 dias antes do INÍCIO da viagem',
    value: 'start-before-3',
    increment: -3,
  },
  {
    id: 4,
    label: '3 dias antes do FIM da viagem',
    value: 'end-before-3',
    increment: -3,
  },
]

export { sendMoments }
