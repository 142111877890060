import DefaultForm from '~/components/DefaultForm'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import Table from '~/components/Table'
import { Container } from '~/styles/container'
import { useEffect, useState } from 'react'
import {
  getDateFormated,
  getDateTimeFormated,
  getMoment,
} from '~/functions/Date'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'

export default function SalesReleaseList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [dataOriginal, setDataOriginal] = useState([])
  const [statusFilterOptions, setStatusFilterOptions] = useState([])

  useEffect(() => {
    async function getSource() {
      try {
        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/IntegrationSales/listprotocols`,
        )

        setData(response.data)

        setDataOriginal(response.data)

        const newStatusFilter = []

        response.data.forEach(e => {
          if (!newStatusFilter.find(x => x.label === e.status)) {
            newStatusFilter.push({
              label: e.status,
              value: e.status,
            })
          }
        })

        setStatusFilterOptions(newStatusFilter)
      } catch (error) {
        console.log(error)
      }
    }

    getSource()

    return () => {
      setLoading(false)

      setData([])
    }
  }, [])

  async function onValidate(data) {
    if (!data.start && data.end) {
      toast.info('Preencha o campo Lançamentos de')

      return false
    }

    if (data.start && !data.end) {
      toast.info('Preencha o campo Lançamentos até')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    const startFilter = data.start
      ? getMoment(data.start + ' 00:00:00')
      : undefined
    const endFilter = data.start ? getMoment(data.end + ' 23:59:59') : undefined
    const statusFilter = data.status?.value ?? undefined
    const paxFilter = data.pax ?? undefined
    const protocolFilter = data.protocol ?? undefined

    if (
      (!startFilter || !endFilter) &&
      !startFilter &&
      !statusFilter &&
      !paxFilter &&
      !protocolFilter
    ) {
      setData(dataOriginal)

      return
    }

    const newData = dataOriginal.filter(x => {
      let finded = false

      if (
        startFilter &&
        endFilter &&
        getMoment(x.sentAt) >= startFilter &&
        getMoment(x.sentAt) <= endFilter
      ) {
        finded = true
      }

      if (statusFilter && x.status?.includes(statusFilter)) {
        finded = true
      }

      if (paxFilter && x.passenger?.includes(paxFilter)) {
        finded = true
      }

      if (protocolFilter && x.protocol?.includes(protocolFilter)) {
        finded = true
      }

      return finded
    })

    setData(newData)
  }

  return (
    <Container>
      <PageTitle head='h1' title={'Lançamentos'} />

      <Panel>
        <PanelHeader noButton title='Filtros' />

        <PanelBody>
          <DefaultForm
            onValidate={onValidate}
            callbackSubmit={onSubmit}
            callbackReset={() => setData(dataOriginal)}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'date',
                name: 'start',
                label: 'Lançamentos de',
                sm: 12,
                lg: 2,
              },
              {
                type: 'date',
                name: 'end',
                label: 'Lançamentos até',
                lg: 2,
              },
              {
                type: 'select',
                name: 'status',
                label: 'Status',
                options: statusFilterOptions,
                sm: 12,
                lg: 2,
              },
              {
                type: 'text',
                name: 'protocol',
                label: 'Protocolo',
                sm: 12,
                lg: 3,
              },
              {
                type: 'text',
                name: 'pax',
                label: 'Passageiro',
                sm: 12,
                lg: 3,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title='Resultado da pesquisa'>
          <LinkRegisterNewTraveler to='create'>
            Novo lançamento
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Protocolo',
                accessor: 'protocol',
                width: 250,
              },
              {
                Header: 'Status',
                accessor: 'status',
                width: 120,
              },
              {
                Header: 'Passageiro',
                accessor: 'passenger',
                width: 150,
              },
              {
                Header: 'Data-Hora',
                accessor: 'sentAt',
                width: 120,
                Cell: props => {
                  return (
                    <>
                      {getDateFormated(props.row.original.sentAt, 'DD/MM/YYYY')}

                      <br />

                      {getDateFormated(props.row.original.sentAt, 'HH:mm')}
                    </>
                  )
                },
              },
              {
                Header: 'Cliente',
                accessor: 'customer',
                width: 150,
              },
              {
                Header: 'Fornecedor',
                accessor: 'supplier',
                width: 150,
              },
              {
                Header: 'Prestador de Serviço',
                accessor: 'serviceProvider',
                width: 150,
              },
              {
                Header: 'Forma de Pagamento',
                accessor: 'paymentMethod',
                width: 150,
              },
              {
                Header: 'Emissor',
                accessor: 'issuer',
                width: 150,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
