import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import Divisor from '~/components/Divisor'
import LoadingContainer from '~/components/LoadingContainer'
// import { ref } from 'yup'

export default function RefundDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const [refund, setRefund] = useState([])
  const [loading, setLoading] = useState(false)
  const [refundStatus, setRefundStatus] = useState([])

  useEffect(() => {}, [])

  async function onChangeStatus(data) {
    // try {
    //   const formData = new FormData()
    //   formData.append('id', refund.id)
    //   formData.append('refundComent', data.refundComent)
    //   formData.append('statusRefund', data.statusRefund.value)
    //   await axiosApis.put(
    //     `${process.env.REACT_APP_ENDPOINT_APIS}/refund`,
    //     formData,
    //   )
    //   return toast.success('Atualização realizada com sucesso')
    // } catch (error) {
    //   return toast.error('Não foi possível atualizar o reembolso.')
    // }
  }

  return (
    <Container>
      <PageTitle title='Detalhes + nome da pagina' />

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          {refund && !loading && (
            <Container>
              <DefaultForm
                callbackSubmit={onChangeStatus}
                defaultValue={refund}
                disabled={loading}
                inputs={
                  [
                    // {
                    //   empty: true,
                    //   text: '<span style="font-size: 13px"><strong style="color: red">*</strong> A cada mudança de Status, um <b>EMAIL</b> é disparado para o viajante.</span>',
                    //   sm: 12,
                    // },
                    // {
                    //   type: 'select',
                    //   name: 'statusRefund',
                    //   label: 'Status do Reembolso',
                    //   options: refundStatus,
                    //   sm: 12,
                    // },
                    // {
                    //   type: 'textarea',
                    //   name: 'refundComent',
                    //   label: 'Comentário',
                    //   sm: 12,
                    // },
                  ]
                }
              />

              {/* <Divisor border /> */}

              {/* <RefundDocs docs={refund.refundDocs} /> */}

              <DefaultForm disabled inputs={[]} />
            </Container>
          )}

          {(!refund || loading) && <LoadingContainer />}
        </PanelBody>
      </Panel>
    </Container>
  )
}
