import { useState } from 'react'
import { GetAxios } from '~/services/api'
import { useNavigate, useOutletContext } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import { toast } from 'react-toastify'
import PageTitle from '~/components/PageTitle'

export default function TravelInsuranceAdd() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const { travel } = useOutletContext()
  const navigate = useNavigate()

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      for (let key in data) {
        formData.append(key, data[key])
      }

      formData.append('travelId', travel.id)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelInsurance`,
        formData,
      )

      navigate(-1)
      setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }

      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle
        title={'Adicionar seguro'}
        head={'h5'}
        onHeadButton={() => navigate(-1)}
      />

      <DefaultForm
        callbackSubmit={onSubmit}
        callbackCancel={() => {}}
        loading={loading}
        inputs={[
          {
            type: 'date',
            name: 'timeStart',
            label: 'Início',
            required: true,
            sm: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'date',
            name: 'timeEnd',
            label: 'Fim',
            required: true,
            sm: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'text',
            name: 'typeCoverage',
            label: 'Tipo de cobertura',
            required: true,
            sm: 4,
            lg: 4,
            xl: 4,
          },
          {
            type: 'text',
            name: 'numberPolicy',
            label: 'Número da apólice',
            sm: 6,
            lg: 6,
            xl: 6,
          },
          {
            type: 'text',
            name: 'nameOfTheInsured',
            label: 'Nome do(a) assegurado(a)',
            sm: 6,
            lg: 6,
            xl: 6,
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Descrição da cobertura do seguro',
            sm: 12,
            lg: 12,
            xl: 12,
          },
        ]}
      />
    </>
  )
}
