// import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import { getDateFormated } from '~/functions/Date'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import ReactTable from '~/components/Table'
import { inputs } from './inputs'

export default function RelationshipDetails({
  customerId,
  hideModal,
  onUpdate,
  tableProps,
  isEnterprise,
}) {
  const { axiosApis } = GetAxios()
  const user = useSelector(state => state.auth.user)
  const [defaultValue, setDefaultValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [ddi, setDdi] = useState({})
  const [selectDdi, setSelectDdi] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)
        const { data } = await axiosApis.get(`/customer/details/${customerId}`)

        setDefaultValue({
          fullName: data.fullName,
          email: data.email,
          ddi: data.ddiValue,
          phone: data.phone,
          cpfCnpj: data.cpfCnpj,
          nacionality: data.nacionality,
          rg: data.rg,
          n: data.n,
          profession: data.profession,
          address: data.address,
          city: {
            label: data.city == null ? 'Selecione uma cidade' : data.city,
            value: data.cityId,
          },
          state: data.state,
          company: data.company,
          country: data.country,
          belongingAgency: data.belongingAgency,
          birthdayDate: data.birthdayDate,
          commercialPhone: data.commercialPhone,
          compl: data.compl,
          birthday:
            data.birthdayDate != '0001-01-01T00:00:00'
              ? getDateFormated(data.birthdayDate, 'YYYY-MM-DD')
              : null,
          cep: data.zipCode,
          bairro: data.neighborhood,
          fidelidade: data.loyalty,
          origem: data.origin,
          isAgent: data.isAgent,
          avatar: data.avatarData,
          degreeOfKinship: data.degreeOfKinship,
          passportNumber: data.passportNumber,
          visaNumber: data.visaNumber,
          visaType: data.visaType,
          prospection: data.prospection,
          passportIssueDate: getDateFormated(
            data.passportIssueDate,
            'YYYY-MM-DD',
          ),
          passportExpirationDate: getDateFormated(
            data.passportExpirationDate,
            'YYYY-MM-DD',
          ),
          visaIssueDate: getDateFormated(data.visaIssueDate, 'YYYY-MM-DD'),
          visaExpirationDate: getDateFormated(
            data.visaExpirationDate,
            'YYYY-MM-DD',
          ),
          dependentOn: [...data.dependentOn],
          contactName: data.contactName,
          contactPhone: data.contactPhone,
          contactEmail: data.contactEmail,
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    getSource()
    getOptionsDDI()
    setSelectDdi({
      label: '',
      value: '',
    })
  }, [])

  async function onValidate(data) {
    if (!data.email && !data.phone) {
      toast.info('Informe email ou telefone.')

      return false
    }

    return true
  }

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('id', customerId)
      formData.append('fullName', data.fullName)
      formData.append('email', data.email ?? '')
      formData.append('ddi', data.ddi.value)
      formData.append('phone', data.phone ?? '')
      formData.append('cpfCnpj', data.cpfCnpj ?? '')
      formData.append('rg', data.rg ?? '')
      formData.append('nacionality', data.nacionality ?? '')
      formData.append('n', data.n ?? '')
      formData.append('compl', data.compl ?? '')
      formData.append('commercialPhone', data.commercialPhone ?? '')
      formData.append('birthdayDate', data.birthday ?? '')
      formData.append('profession', data.profession ?? '')
      formData.append('address', data.address ?? '')
      if (
        data.city.label != null &&
        data.city.label != 'Selecione uma cidade' &&
        data.city.value != null
      ) {
        formData.append('city', data.city?.label)
        formData.append('cityId', data.city?.value)
      }
      formData.append('state', data.state ?? '')
      formData.append('country', data.country ?? '')
      formData.append('company', data.company ?? '')
      formData.append('avatar', data.avatar ?? '')
      if (data.belongingAgency) {
        formData.append('belongingAgency', data.belongingAgency ?? '')
      }
      if (data.cep) {
        formData.append('zipCode', data.cep ?? '')
      }
      if (data.bairro) {
        formData.append('neighborhood', data.bairro ?? '')
      }
      if (data.prospection) {
        formData.append('prospection', data.prospection)
      }
      if (data.fidelidade) {
        formData.append('loyalty', data.fidelidade ?? '')
      }
      if (data.origem) {
        formData.append('origin', data.origem ?? '')
      }
      if (data.isAgent) {
        formData.append('isAgent', data.isAgent ?? '')
      }
      if (data.passportNumber) {
        formData.append('passportNumber', data.passportNumber ?? '')
      }
      if (data.visaNumber) {
        formData.append('visaNumber', data.visaNumber ?? '')
      }
      if (data.visaType) {
        formData.append('visaType', data.visaType ?? '')
      }
      if (data.passportIssueDate && data.passportIssueDate != 'Data inválida') {
        formData.append('passportIssueDate', data.passportIssueDate ?? '')
      }
      if (
        data.passportExpirationDate &&
        data.passportExpirationDate != 'Data inválida'
      ) {
        formData.append(
          'passportExpirationDate',
          data.passportExpirationDate ?? '',
        )
      }
      if (data.visaIssueDate && data.visaIssueDate != 'Data inválida') {
        formData.append('visaIssueDate', data.visaIssueDate ?? '')
      }
      if (
        data.visaExpirationDate &&
        data.visaExpirationDate != 'Data inválida'
      ) {
        formData.append('visaExpirationDate', data.visaExpirationDate ?? '')
      }
      if (defaultValue.degreeOfKinship) {
        formData.append('degreeOfKinship', defaultValue.degreeOfKinship ?? '')
      }
      if (data.contactName) {
        formData.append('contactName', data.contactName ?? '')
      }
      if (data.contactPhone) {
        formData.append('contactPhone', data.contactPhone ?? '')
      }
      if (data.contactEmail) {
        formData.append('contactEmail', data.contactEmail ?? '')
      }

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/edit`,
        formData,
      )

      toast.success('Cliente editado com sucesso!')

      hideModal()

      await onUpdate(
        tableProps.filters,
        (tableProps.page - 1) * tableProps.limit,
        tableProps.limit,
      )
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  async function onDelete() {
    if (window.confirm('Você tem certeza que deseja excluir esse cliente?')) {
      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/${customerId}`,
      )

      toast.success(`Cliente excluído com sucesso!`)

      hideModal()

      await onUpdate(
        tableProps.filters,
        (tableProps.page - 1) * tableProps.limit,
        tableProps.limit,
      )
    }
  }

  async function onSearchCEP(e, oldData = {}) {
    try {
      setLoading(true)

      var { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/cep/${e.target.value}`,
      )

      if (!data.cep) {
        toast.info('CEP não encontrado!')

        return setLoading(false)
      }

      setDefaultValue({
        ...oldData,
        cep: data.cep,
        address: data.logradouro,
        bairro: data.bairro,
        city: data.localidade,
        state: data.uf,
      })

      return setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }

      return setLoading(false)
    }
  }

  return (
    <Container>
      <Panel>
        <PanelBody>
          <Container>
            <DefaultForm
              onValidate={onValidate}
              callbackSubmit={onSubmit}
              callbackDelete={onDelete}
              defaultValue={defaultValue}
              loading={loading}
              disabled={loading}
              inputs={inputs(
                isEnterprise,
                ddi,
                setSelectDdi,
                selectDdi.Value,
                user.isOperator,
                onSearchCEP,
              )}
            />
          </Container>
        </PanelBody>
      </Panel>

      {defaultValue?.dependentOn?.length >= 1 && (
        <Panel>
          <PanelHeader
            title={`${defaultValue.fullName} é dependente de`}
            noButton
          />

          <PanelBody>
            <ReactTable
              data={defaultValue?.dependentOn ?? []}
              columns={[
                {
                  Header: 'Nome',
                  accessor: 'fullName',
                  width: 200,
                },
                {
                  Header: 'E-mail',
                  accessor: 'email',
                  width: 250,
                },
                {
                  Header: 'Telefone',
                  accessor: 'phone',
                  width: 150,
                },
              ]}
              tableProps={{
                defaultPageSize: 10,
                hasFooter: false,
                hasPagination: true,
                loading: loading,
                resizable: true,
              }}
              tableStyle={{
                fontSize: '13px',
                cellPadding: '6px 4px',
              }}
              tableLabels={{
                loadingText: 'Carregando...',
                noDataText: 'Sem registros',
                nextButtonText: 'Próximo',
                previousButtonText: 'Anterior',
                pageOfText: 'de',
                pageSizeSelectText: 'registros',
                pageText: 'Página',
              }}
            />
          </PanelBody>
        </Panel>
      )}
    </Container>
  )
}
