import { useContext } from 'react'
import { ChatContext } from '../../context/ChatContext'

export default function useChat() {
  const state = useContext(ChatContext)

  if (!state) {
    throw new Error('useChat must be used inside ChatContext')
  }

  return state
}
