import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import IconButton from '../IconButton'
import styled from 'styled-components'
import LoadingContainer from '../LoadingContainer'
import { createZApiInstance, saveIdInstance } from '~/services/chat'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import { useAllDocs } from 'use-pouchdb'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function ZChatQRCode({ user, handleShow }) {
  const [time, setTime] = useState(0)
  const [count, setCount] = useState(0)
  const [running, setRunning] = useState(false)
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState(false)
  const alldocs = useAllDocs({
    include_docs: true, // Load all document bodies
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    let isMounted = true // To handle component unmounting
    if (isMounted) {
      if (alldocs?.rows?.length > 0 && data != null) {
        const instance = alldocs.rows.find(instance => instance.id === data.id)
        if (instance?.doc?.connected) {
          setRunning(false)
          setTime(0)
          setData(null)
          saveIdInstance(instance.key, dispatch)
          handleShow()
          setTimeout(() => {
            navigate('/chat')
          }, [1000])
        }
      }
    }
    return () => {
      isMounted = false
    }
  }, [alldocs])

  useEffect(() => {
    let isMounted = true // To handle component unmounting
    const fetchData = async () => {
      setLoadingRequest(true)
      try {
        const response = await createZApiInstance(user.name, user.agencyName)
        if (isMounted) {
          setData(response)
          setTime(0)
          setRunning(true)
        }
      } catch (error) {
        if (isMounted) {
          setError(true)
          setTime(0)
          setRunning(false)
          toast.error('Erro ao tentar criar Instância')
          setData(null)
          console.log(error)
          throw new Error('Erro ao tentar criar Instância', error)
        }
      } finally {
        if (isMounted) {
          setLoadingRequest(false)
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let timer
    if (running) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime + 1)
      }, 1000)
    } else if (!running) {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [running])

  useEffect(() => {
    let called = false

    async function updateQRCode() {
      if (called) return
      if (count === 3) return
      setLoadingRequest(true)
      try {
        setCount(prevCount => prevCount + 1)
        var res = await createZApiInstance(user.name, user.agencyName)
        if (res) {
          setLoadingRequest(false)
          setTime(0)
          setRunning(true)
          console.log(res)
          setData(res)
        }
      } catch (e) {
        setError(true)
        console.log('Erro ao buscar QR Code')
      }
    }

    if (time === 10) {
      updateQRCode()
      setRunning(false)
    }

    return () => {
      called = true
    }
  }, [time])

  const resetCount = async () => {
    const response = await createZApiInstance(user.name, user.agencyName)
    setData(response)
    setCount(0)
    setRunning(true)
    setTime(0)
    setLoadingRequest(false)
  }

  return (
    <Container data-cy='qr-code'>
      <b style={{ margin: '2px 4px', fontWeight: '600' }}>
        WhatsApp da Agência
      </b>
      <Content>
        {error && <p>Um erro ocorreu</p>}
        {!error && (
          <>
            {loadingRequest && count < 3 && (
              <LoadingContainer size='sm' color='#ccc' height='200px' />
            )}
            {/* {!loadingRequest && <img src={data.qrCode} />} */}
            {!loadingRequest &&
              time >= 0 &&
              time <= 10 &&
              count < 3 &&
              data != null && (
                <>
                  <img src={data.qrCode} />
                  <div data-cy='qr-code-timer'>{time} seconds</div>
                </>
              )}
            {!loadingRequest && count === 3 && (
              <Button onClick={() => resetCount()} size='md'>
                Recarregar <i className='fas fa-redo'></i>
              </Button>
            )}
            <b>Escaneie o QRCode para iniciar uma sessão!</b>
            <ul>
              <li>
                Abra o <b>WhatsApp</b> e acesse o <b>Menu</b>.
              </li>
              <li>
                Vá em <b>Dispositivos Conectados</b> e clique em{' '}
                <b>Conectar um Dispositivo</b>.
              </li>
              <li>
                Escaneie o <b>QRCode acima</b> e pronto!
              </li>
            </ul>
          </>
        )}
      </Content>
    </Container>
  )
}

const Container = styled.div``

const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
