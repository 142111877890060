import React from 'react'
import styled from 'styled-components'

export default function NotFound({ message }) {
  return (
    <Container>
      <p>
        <b>{message}</b>
      </p>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
