import { useNavigate } from 'react-router-dom'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import { toast } from 'react-toastify'
import { GetAxios } from '~/services/api'
import PageTitle from '~/components/PageTitle'
import DefaultForm from '~/components/DefaultForm'

export default function Create() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()

  async function onValidate(data) {
    if (data && data.city && data.city.length === 0) {
      toast.info('Preencha um campo Cidade.')

      return false
    }

    return true
  }

  const onSubmit = async data => {
    try {
      const formData = new FormData()

      for (let key in data) {
        formData.append(key, data[key])
      }

      formData.set('city', data.city?.label)
      formData.set('cityId', data.city?.value)

      await axiosApis.post(`hotelLibrary`, formData)

      toast.success('Hotel criado com sucesso!')
      navigate('/library/hotel')
    } catch (error) {
      toast.error(
        'Não foi possível criar o hotel, por favor entre em contato com o suporte',
      )
    }
  }

  return (
    <Container>
      <PageTitle title={'Hotéis'} />

      <Panel>
        <PanelHeader title={'Cadastrar Novo Hotel'} noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={() => {}}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Nome',
                required: true,
                sm: 12,
                md: 12,
                lg: 3,
                xl: 3,
              },
              {
                type: 'city',
                name: 'city',
                label: 'Cidade',
                placeholder: 'Pesquise por uma cidade...',
                required: true,
                sm: 12,
                md: 12,
                lg: 2,
                xl: 2,
              },
              {
                type: 'text',
                name: 'fullAddress',
                label: 'Endereço',
                required: true,
                sm: 12,
                md: 12,
                lg: 3,
                xl: 3,
              },
              {
                type: 'number',
                name: 'value',
                label: 'Valor',
                min: 0,
                sm: 12,
                md: 12,
                lg: 2,
                xl: 2,
              },
              {
                type: 'text',
                name: 'originPurchase',
                label: 'Fornecedor',
                sm: 12,
                md: 12,
                lg: 2,
                xl: 2,
              },
              {
                type: 'textarea',
                name: 'desc',
                label: 'Descrição do check-in',
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'descCheckOut',
                label: 'Descrição do check-out',
                sm: 6,
                md: 6,
                lg: 6,
                xl: 6,
              },
              {
                type: 'textarea',
                name: 'importantInformation',
                label: 'Informações importantes',
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              {
                type: 'image',
                name: 'image1',
                label: 'Imagem',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
              {
                type: 'image',
                name: 'image2',
                label: 'Imagem',
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
