export const getTableValuesSum = values => {
  const fareSum =
    Number(values.tarifa1 ?? 0) +
    Number(values.tarifa2 ?? 0) +
    Number(values.tarifa3 ?? 0)

  const checkInTaxSum =
    Number(values.taxaEmbarque1 ?? 0) +
    Number(values.taxaEmbarque2 ?? 0) +
    Number(values.taxaEmbarque3 ?? 0)

  const duRavSum =
    Number(values.duRav1 ?? 0) +
    Number(values.duRav2 ?? 0) +
    Number(values.duRav3 ?? 0)

  const otherTaxSum =
    Number(values.outrasTaxas1Coluna1 ?? 0) +
    Number(values.outrasTaxas1Coluna2 ?? 0) +
    Number(values.outrasTaxas1Coluna3 ?? 0) +
    Number(values.outrasTaxas2Coluna1 ?? 0) +
    Number(values.outrasTaxas2Coluna2 ?? 0) +
    Number(values.outrasTaxas2Coluna3 ?? 0) +
    Number(values.outrasTaxas3Coluna1 ?? 0) +
    Number(values.outrasTaxas3Coluna2 ?? 0) +
    Number(values.outrasTaxas3Coluna3 ?? 0)

  const feeSum =
    Number(values.fee1Coluna1 ?? 0) +
    Number(values.fee1Coluna3 ?? 0) +
    Number(values.fee2Coluna1 ?? 0) +
    Number(values.fee2Coluna3 ?? 0)

  const markupSum =
    Number(values.markup1 ?? 0) +
    Number(values.markup2 ?? 0) +
    Number(values.markup3 ?? 0)

  const cashInvoicePaymentTotal =
    Number(values.tarifa1 ?? 0) +
    Number(values.taxaEmbarque1 ?? 0) +
    Number(values.duRav1 ?? 0) +
    Number(values.outrasTaxas1Coluna1 ?? 0) +
    Number(values.outrasTaxas2Coluna1 ?? 0) +
    Number(values.outrasTaxas3Coluna1 ?? 0) +
    Number(values.fee1Coluna1 ?? 0) +
    Number(values.fee2Coluna1 ?? 0) +
    Number(values.markup1 ?? 0)

  const cardProviderTotal =
    Number(values.tarifa2 ?? 0) +
    Number(values.taxaEmbarque2 ?? 0) +
    Number(values.duRav2 ?? 0) +
    Number(values.outrasTaxas1Coluna2 ?? 0) +
    Number(values.outrasTaxas2Coluna2 ?? 0) +
    Number(values.outrasTaxas3Coluna2 ?? 0) +
    Number(values.markup2 ?? 0)

  const agencyCardTotal =
    Number(values.tarifa3 ?? 0) +
    Number(values.taxaEmbarque3 ?? 0) +
    Number(values.duRav3 ?? 0) +
    Number(values.outrasTaxas1Coluna3 ?? 0) +
    Number(values.outrasTaxas2Coluna3 ?? 0) +
    Number(values.outrasTaxas3Coluna3 ?? 0) +
    Number(values.fee1Coluna3 ?? 0) +
    Number(values.fee2Coluna3 ?? 0) +
    Number(values.markup3 ?? 0)

  const total =
    fareSum + checkInTaxSum + duRavSum + otherTaxSum + feeSum + markupSum

  return {
    fareSum,
    checkInTaxSum,
    duRavSum,
    otherTaxSum,
    feeSum,
    markupSum,
    cashInvoicePaymentTotal,
    cardProviderTotal,
    agencyCardTotal,
    total,
  }
}

export const getCashDfMpCalc = (code, valueCash, valueDf, valueMp) => {
  if (!valueCash && !valueDf && !valueMp) {
    return undefined
  }

  let valor = {
    codigo: code,
  }

  const valueCashNumber = Number(valueCash ?? 0)
  const valueDfNumber = Number(valueDf ?? 0)
  const valueMpNumber = Number(valueMp ?? 0)

  valor['valor'] = valueCashNumber + valueDfNumber + valueMpNumber

  if (valueDfNumber !== 0 && valueDfNumber !== valor.valor) {
    valor['valorDf'] = valueDfNumber
  }

  if (valueMpNumber !== 0 && valueMpNumber !== valor.valor) {
    valor['valorMp'] = valueMpNumber
  }

  return valor
}

export const getFeeCalc = (code, valueCash, valueMp) => {
  if (!valueCash && !valueMp) {
    return undefined
  }

  let valor = {
    codigo: code,
  }

  if (valueCash && !valueMp) {
    valor['valor'] = Number(valueCash ?? 0)
    valor['valorMp'] = 0
  }

  if (!valueCash && valueMp) {
    valor['valor'] = Number(valueMp ?? 0)
  }

  if (valueCash && valueMp) {
    const valueCashNumber = Number(valueCash ?? 0)
    const valueMpNumber = Number(valueMp ?? 0)

    valor['valor'] = valueCashNumber + valueMpNumber
    valor['valorMp'] = valueMpNumber
  }

  return valor
}

export const getValueCalc = (code, valueCash) => {
  if (!valueCash) {
    return undefined
  }

  let valor = {
    codigo: code,
    valor: Number(valueCash ?? 0),
    valorDf: 0,
    valorMp: 0,
  }

  return valor
}
