import moment from 'moment'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { getDateFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function Create({ toCreate, onClose }) {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [defaultValue, setDefaultValue] = useState({
    start: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
    timeStart: getDateFormated(toCreate.date, '00:00'),
    end: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
    timeEnd: getDateFormated(toCreate.date, '00:00'),
  })

  async function handleSubmit(data) {
    try {
      setLoading(true)

      var hoje = moment().format('YYYY-MM-DD')

      if (
        data.start < hoje &&
        !window.confirm(
          'Você inseriu uma data que já passou. Deseja continuar?',
        )
      ) {
        setLoading(false)
        return
      }

      const formData = new FormData()

      formData.append('originPurchase', data.originPurchase)
      formData.append('title', data.title)
      formData.append('desc', data.desc)
      formData.append('city', data.city.value)
      formData.append('start', data.start + ' ' + data.timeStart)
      formData.append('end', data.end + ' ' + data.timeEnd)
      formData.append('image', data.image)
      formData.append('tips', data.tips)
      formData.append('travelId', travel.id)
      formData.append('startAddress', data.startAddress)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelitem`,
        formData,
      )

      toast.success('Serviço criado com sucesso!')

      setLoading(false)

      onClose(true)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível criar o serviço.')
    }
  }

  return (
    <Container>
      <DefaultForm
        callbackSubmit={handleSubmit}
        callbackReset={() => {
          return
        }}
        defaultValue={defaultValue}
        disabled={loading}
        loading={loading}
        inputs={[
          {
            type: 'text',
            name: 'title',
            label: 'Título',
            required: true,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'text',
            name: 'startAddress',
            label: 'Endereço',
            required: false,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
            maxLength: 200,
          },
          {
            type: 'city',
            name: 'city',
            label: 'Cidade',
            required: true,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'text',
            name: 'originPurchase',
            label: 'Fornecedor',
            required: true,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 3,
          },
          {
            type: 'date',
            name: 'start',
            label: 'Data do Início',
            row: '2',
            required: true,
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeStart',
            label: 'Hora do Início',
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'date',
            name: 'end',
            label: 'Data do Fim',
            required: true,
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'time',
            name: 'timeEnd',
            label: 'Hora do Fim',
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
          {
            type: 'html',
            name: 'desc',
            label: 'Descrição',
            required: true,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
          },
          {
            type: 'image',
            name: 'image',
            label: 'Imagem',
            sm: 12,
            md: 12,
            lg: 6,
            xl: 3,
          },
          {
            type: 'value',
            name: 'tips',
            label: 'Amenidades',
            placeholder: 'Descrição',
            sm: 12,
            md: 12,
            lg: 6,
            xl: 3,
          },
        ]}
      />
    </Container>
  )
}
