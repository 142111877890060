export function FormatPhoneOnlyNumbersBR(value) {
  if ((value && value.length === 10) || value.length === 14) {
    return value
      .replace(/\D/g, '')
      .replace(/^0/, '')
      .replace(/^(\d\d)(\d{4})(\d{4}).*/, '$1$2$3')
  }

  return value
    .replace(/\D/g, '')
    .replace(/^0/, '')
    .replace(/^(\d\d)(\d{5})(\d{4}).*/, '$1$2$3')
}

export function FormatPhoneBR(value) {
  if ((value && value.length === 10) || value.length === 14) {
    return value
      .replace(/\D/g, '')
      .replace(/^0/, '')
      .replace(/^(\d\d)(\d{4})(\d{4}).*/, '($1) $2-$3')
  }

  return value
    .replace(/\D/g, '')
    .replace(/^0/, '')
    .replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
}
