// import { useSelector } from 'react-redux'
// import { LinkEdit, LinkCreate } from '~/components/Link/styles'
import { useContext } from 'react'
import { LinkTravelDetails } from '~/components/Link/styles'
import SubTableOfCustomerTraveler from '~/components/SubTableOfCustomerTraveler'
import { ChangeStatusButton } from '~/components/SubTableOfCustomerTraveler/style'
import ReactTable from '~/components/Table'
import context from '~/context/createContext'

export default function Table({ data, loading }) {
  const {
    setValueModal,
    showModalCheckIn,
    setShowModalCheckIn,
    showModalMaterial,
    setShowModalMaterial,
  } = useContext(context)

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: () => null,
          id: 'expander',
          Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
              <i
                className={`
            fas fa-caret-${row.isExpanded ? 'up' : 'down'}
          `}
              />
            </span>
          ),
          width: 40,
        },
        {
          Header: 'Viagem',
          accessor: 'title',
          width: 150,
        },
        {
          Header: 'Ida',
          accessor: 'start',
          width: 150,
        },
        {
          Header: 'Volta',
          accessor: 'end',
          width: 150,
        },
        {
          Header: 'Promotor',
          accessor: 'promoter',
          width: 150,
        },
        {
          Header: 'Porcentagem',
          accessor: 'percentage',
          width: 150,
        },
        {
          Header: '',
          accessor: 'checkin',
          Cell: props => {
            return (
              <ChangeStatusButton
                type='button'
                onClick={() => {
                  setValueModal({
                    ...props.row.original,
                    nameTraveler: props.row.original.nameTraveler?.reduce(
                      (acc, e) => {
                        const aux = {
                          label: e,
                        }
                        acc.push(aux)
                        return acc
                      },
                      [],
                    ),
                  })
                  // await requestApiServiceGet()
                  setShowModalCheckIn(!showModalCheckIn)
                }}
              >
                CheckIn
              </ChangeStatusButton>
            )
          },
          width: 70,
        },
        {
          Header: '',
          accessor: 'entrega',
          Cell: props => {
            return (
              <ChangeStatusButton
                type='button'
                onClick={() => {
                  setValueModal(props.row.original)
                  // await requestApiServiceGet()
                  setShowModalMaterial(!showModalMaterial)
                }}
              >
                Entrega de material
              </ChangeStatusButton>
            )
          },
        },
        {
          Header: '',
          accessor: 'detalhes',
          Cell: props => {
            return (
              <LinkTravelDetails
                to={'/travels/details'}
                state={{ id: props.row.original.id }}
              >
                Detalhes
              </LinkTravelDetails>
            )
          },
          width: 70,
        },
      ]}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      subComponent={props => (
        <SubTableOfCustomerTraveler
          travelerId={props.row.original.travelerId}
          travelId={props.row.original.id}
        />
      )}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
