import IconButton from '~/components/IconButton'
import { getDateFormated, isValidDate } from '~/functions/Date'
import { FormatValueBRL } from '~/functions/FormatValue'
import { getSaleId, getSaleType, splitSaleValues } from '~/functions/Sales'
import { getSaleStateSpan } from '~/styles/sales'

export const getSalesFormFields = ({ filters, isOperator }) => {
  const fields = [
    {
      type: 'date',
      name: 'start',
      label: 'Emissões/Reservas de',
      sm: 12,
      lg: 2,
      infoText:
        'Informe a "Emissões/Reservas de" e "Emissões/Reservas até" para filtrar por "Emissão", "Reserva" ou "Atualização" da venda.',
    },
    {
      type: 'date',
      name: 'end',
      label: 'Emissões/Reservas até',
      sm: 12,
      lg: 2,
      infoText:
        'Informe a "Emissões/Reservas de" e "Emissões/Reservas até" para filtrar por "Emissão", "Reserva" ou "Atualização" da venda.',
    },
    {
      type: 'text',
      name: 'locator',
      label: 'Localizador',
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'pax',
      label: 'Passageiro',
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'state',
      label: 'Status',
      options: filters.states,
      isClearable: true,
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'saleType',
      label: 'Tipo de serviço',
      options: filters.saleTypes,
      isClearable: true,
      sm: 12,
      lg: 2,
    },
    {
      type: 'date',
      name: 'checkIn',
      label: 'Embarques de',
      sm: 12,
      lg: 2,
      infoText:
        'Informe "Embarques de" e "Embarques até" para filtrar pelo embarque.',
    },
    {
      type: 'date',
      name: 'checkOut',
      label: 'Embarques até',
      sm: 12,
      lg: 2,
      infoText:
        'Informe "Embarques de" e "Embarques até" para filtrar pelo embarque.',
    },
    {
      type: 'select',
      name: 'consolidator',
      label: 'Consolidadora',
      options: filters.consolidators,
      isClearable: true,
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'airline',
      label: 'Cia Aérea',
      options: filters.airlines,
      isClearable: true,
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'provider',
      label: 'Fornecedor',
      options: filters.providers,
      isClearable: true,
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'issuer',
      label: 'Consultor',
      options: filters.issuers,
      isClearable: true,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'agency',
      label: 'Agência',
      hide: !isOperator,
      sm: 12,
      lg: 2,
    },
    {
      type: 'text',
      name: 'saleId',
      label: 'Venda',
      sm: 12,
      lg: 2,
    },
  ]

  return fields.filter(x => !x.hide)
}

export const getSalesColumns = ({ showDetails, isOperator }) => {
  const columns = [
    {
      Header: '',
      accessor: 'type',
      width: 80,
      Cell: props => {
        const saleType = getSaleType(props.row.original.type)

        const saleId = getSaleId(props.row.original.id)

        return (
          <>
            <IconButton
              id={'sale-icon-' + props.row.index}
              iconOverride={saleType.icon}
              tooltipOverride={saleType.info}
            />

            {saleId && (
              <>
                <br />

                <b>{saleId}</b>
              </>
            )}
          </>
        )
      },
    },
    {
      Header: 'Agência',
      accessor: 'agency',
      width: 100,
      hide: !isOperator,
    },
    {
      Header: 'Status',
      accessor: 'state',
      width: 80,
      Cell: props => {
        return getSaleStateSpan(props.row.original.state)
      },
    },
    {
      Header: 'Cons.',
      accessor: 'consolidator',
      width: 80,
    },
    {
      Header: 'Fornecedor',
      accessor: 'provider',
      width: 150,
      Cell: props => {
        return splitSaleValues(props.row.original.provider)
      },
    },
    {
      Header: 'Cia',
      accessor: 'cia',
      width: 80,
    },
    {
      Header: 'Loc.',
      accessor: 'locator',
      width: 130,
      Cell: props => {
        return splitSaleValues(props.row.original.locator)
      },
    },
    {
      Header: 'Atualizado',
      accessor: 'lastUpdated',
      width: 90,
      Cell: props => {
        return (
          <div>
            <span>
              {getDateFormated(props.row.original.lastUpdated, 'DD/MM/YY')}
            </span>

            <br />

            <span>
              {getDateFormated(props.row.original.lastUpdated, 'HH:mm')}
            </span>
          </div>
        )
      },
    },
    {
      Header: 'Emissão',
      accessor: 'emissionDate',
      width: 80,
      Cell: props => {
        let date = props.row.original.emissionDate

        if (!isValidDate(date)) {
          date = props.row.original.lastUpdated
        }

        if (!isValidDate(date)) {
          return null
        }

        return (
          <div>
            <span>{getDateFormated(date, 'DD/MM/YY')}</span>

            <br />

            <span>{getDateFormated(date, 'HH:mm')}</span>
          </div>
        )
      },
    },
    {
      Header: 'Embarque',
      accessor: 'checkIn',
      width: 80,
      Cell: props => {
        if (!props.row.original.checkIn) {
          return null
        }

        return (
          <div>
            <span>
              {getDateFormated(props.row.original.checkIn, 'DD/MM/YY')}
            </span>

            <br />

            <span>{getDateFormated(props.row.original.checkIn, 'HH:mm')}</span>
          </div>
        )
      },
    },
    {
      Header: 'Rota',
      accessor: 'route',
      width: 80,
      Cell: props => {
        return props.row.original.route
      },
    },
    {
      Header: 'Passageiros',
      accessor: 'pax',
      width: 100,
      Cell: props => {
        return (
          <>
            <span>{props.row.original.pax}</span>

            {props.row.original.paxCount > 1 && (
              <>
                <br />

                <b>+ {props.row.original.paxCount}</b>
              </>
            )}
          </>
        )
      },
    },
    {
      Header: 'Consultor',
      accessor: 'issuerName',
      width: 100,
    },
    {
      Header: 'Valor',
      accessor: 'ammount',
      width: 90,
      Cell: props => {
        return FormatValueBRL(props.row.original.ammount)
      },
    },
    {
      Header: '',
      accessor: 'id',
      width: 60,
      Cell: props => {
        return (
          <IconButton
            id={props.row.index}
            type={'details'}
            onClick={() => showDetails(props.row.original)}
          />
        )
      },
    },
  ]

  return columns.filter(x => !x.hide)
}
