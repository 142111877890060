import { Badge } from 'reactstrap'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 0;
`

export const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ContainerTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: cener;
  height: 57px;
  width: 100%;
  background: #eaeaea;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
  color: #212529;

  .p-rating {
    margin-bottom: 8px;
    .p-rating-icon {
      font-size: 1.3rem;
    }
  }

  .p {
    font-size: 1rem;
  }

  .i {
    font-size: 68px;
  }
`
