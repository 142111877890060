import { useEffect, useState } from 'react'
import LoadingContainer from '~/components/LoadingContainer'
import { GetAxios } from '~/services/api'
import { getDateFormated } from '~/functions/Date'
import { FormatValueBRL } from '~/functions/FormatValue'
import { getSalePaxType, getSaleValueInfo } from '~/functions/Sales'
import Table from '~/components/Table'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'

export default function SaleDetails({ data }) {
  const [loading, setLoading] = useState(false)
  const { axiosApis } = GetAxios()
  const [sale, setSale] = useState()

  useEffect(() => {
    async function onSubmit() {
      try {
        setLoading(true)

        const res = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/sales/${data.id}`,
        )

        setSale(res.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    onSubmit()
  }, [])

  return (
    <div style={{ width: '100%' }}>
      {loading && (
        <div style={{ width: '100%' }}>
          <LoadingContainer size='lg' height='300px' />
        </div>
      )}

      {!loading && sale && (
        <>
          {sale.paxs.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title={'Passageiros'} />

                <PanelBody>
                  <Table
                    data={sale.paxs}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Primeiro nome',
                        accessor: 'firstName',
                        width: 150,
                      },
                      {
                        Header: 'Sobrenome',
                        accessor: 'surname',
                        width: 150,
                      },
                      {
                        Header: 'E-mail',
                        accessor: 'email',
                        width: 250,
                      },
                      {
                        Header: 'Telefone',
                        accessor: 'phone',
                        width: 120,
                      },
                      {
                        Header: 'Tipo',
                        accessor: 'type',
                        width: 120,
                        Cell: props => {
                          return getSalePaxType(props.row.original.type)
                        },
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.airs.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Aéreos' />

                <PanelBody>
                  <Table
                    data={sale.airs}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Embarque',
                        accessor: 'departureDate',
                        width: 250,
                        Cell: props => {
                          return (
                            <div>
                              <span>
                                {props.row.original.departureAirportCode}
                                {props.row.original.departure && (
                                  <span>
                                    &nbsp;-&nbsp;{props.row.original.departure}
                                  </span>
                                )}
                              </span>

                              <br />

                              <span>
                                {getDateFormated(
                                  props.row.original.departureDate,
                                  'DD/MM/YY',
                                )}
                              </span>

                              <br />

                              <span>
                                {getDateFormated(
                                  props.row.original.departureDate,
                                  'HH:mm',
                                )}
                              </span>
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Desembarque',
                        accessor: 'arrivalDate',
                        width: 250,
                        Cell: props => {
                          return (
                            <div>
                              <span>
                                {props.row.original.arrivalAirportCode}

                                {props.row.original.arrival && (
                                  <span>
                                    &nbsp;-&nbsp;{props.row.original.arrival}
                                  </span>
                                )}
                              </span>

                              <br />

                              <span>
                                {getDateFormated(
                                  props.row.original.arrivalDate,
                                  'DD/MM/YY',
                                )}
                              </span>

                              <br />

                              <span>
                                {getDateFormated(
                                  props.row.original.arrivalDate,
                                  'HH:mm',
                                )}
                              </span>
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Cia Aérea',
                        accessor: 'airlineName',
                        width: 120,
                      },
                      {
                        Header: 'Voo',
                        accessor: 'flight',
                        width: 120,
                        Cell: props => {
                          return `${props.row.original.airlineCode} ${props.row.original.flight}`
                        },
                      },
                      {
                        Header: 'Localizador',
                        accessor: 'locator',
                        width: 100,
                      },
                      {
                        Header: 'Classe',
                        accessor: 'serviceClass',
                        width: 100,
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.buses.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Ônibus' />

                <PanelBody>
                  <Table
                    data={sale.buses}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Embarque',
                        accessor: 'departureDate',
                        width: 250,
                        Cell: props => {
                          return (
                            <div>
                              <span>{props.row.original.departure}</span>

                              <br />

                              <span>
                                {getDateFormated(
                                  props.row.original.departureDate,
                                  'DD/MM/YY',
                                )}
                              </span>

                              <br />

                              <span>
                                {getDateFormated(
                                  props.row.original.departureDate,
                                  'HH:mm',
                                )}
                              </span>
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Desembarque',
                        accessor: 'arrivalDate',
                        width: 250,
                        Cell: props => {
                          return (
                            <div>
                              <span>{props.row.original.arrival}</span>

                              <br />

                              <span>
                                {getDateFormated(
                                  props.row.original.arrivalDate,
                                  'DD/MM/YY',
                                )}
                              </span>

                              <br />

                              <span>
                                {getDateFormated(
                                  props.row.original.arrivalDate,
                                  'HH:mm',
                                )}
                              </span>
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Empresa',
                        accessor: 'company',
                        width: 170,
                        Cell: props => {
                          return (
                            <div>
                              <span>{props.row.original.company}</span>

                              <br />

                              {props.row.original.companyImageUrl && (
                                <img
                                  src={props.row.original.companyImageUrl}
                                  style={{ height: '24px' }}
                                />
                              )}
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Tipo',
                        accessor: 'type',
                        width: 120,
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.hotels.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Hoteis' />

                <PanelBody>
                  <Table
                    data={sale.hotels}
                    loading={loading}
                    columns={[
                      {
                        Header: () => null,
                        id: 'expander',
                        Cell: ({ row }) => (
                          <span {...row.getToggleRowExpandedProps()}>
                            <i
                              className={`
                  fas fa-caret-${row.isExpanded ? 'up' : 'down'}
                `}
                            />
                          </span>
                        ),
                        width: 40,
                      },
                      {
                        Header: 'Nome do hotel',
                        accessor: 'name',
                        width: 200,
                      },
                      {
                        Header: 'Endereço',
                        accessor: 'fullAddress',
                        width: 200,
                      },
                      {
                        Header: 'Cidade',
                        accessor: 'city',
                        width: 120,
                      },
                      {
                        Header: 'CEP',
                        accessor: 'zipCode',
                        width: 120,
                      },
                      {
                        Header: 'Fornecedor',
                        accessor: 'provider',
                        width: 200,
                      },
                    ]}
                    subComponent={props => {
                      return (
                        <Panel style={{ width: '100%' }}>
                          <PanelHeader noButton title='Quartos' />

                          <PanelBody>
                            <Table
                              data={props.row.original.rooms}
                              loading={loading}
                              columns={[
                                {
                                  Header: () => null,
                                  id: 'expander',
                                  Cell: ({ row }) => (
                                    <span {...row.getToggleRowExpandedProps()}>
                                      <i
                                        className={`
                  fas fa-caret-${row.isExpanded ? 'up' : 'down'}
                `}
                                      />
                                    </span>
                                  ),
                                  width: 40,
                                },
                                {
                                  Header: 'Check-in',
                                  accessor: 'checkIn',
                                  width: 110,
                                  Cell: props => {
                                    return (
                                      <div>
                                        <span>
                                          {getDateFormated(
                                            props.row.original.checkIn,
                                            'DD/MM/YY',
                                          )}
                                        </span>

                                        <br />

                                        <span>
                                          {getDateFormated(
                                            props.row.original.checkIn,
                                            'HH:mm',
                                          )}
                                        </span>
                                      </div>
                                    )
                                  },
                                },
                                {
                                  Header: 'Check-out',
                                  accessor: 'checkOut',
                                  width: 110,
                                  Cell: props => {
                                    return (
                                      <div>
                                        <span>
                                          {getDateFormated(
                                            props.row.original.checkOut,
                                            'DD/MM/YY',
                                          )}
                                        </span>

                                        <br />

                                        <span>
                                          {getDateFormated(
                                            props.row.original.checkOut,
                                            'HH:mm',
                                          )}
                                        </span>
                                      </div>
                                    )
                                  },
                                },
                                {
                                  Header: 'Quarto',
                                  accessor: 'roomName',
                                  width: 120,
                                },
                                {
                                  Header: 'Descrição',
                                  accessor: 'roomDesc',
                                  width: 200,
                                },
                                {
                                  Header: 'Informações',
                                  accessor: 'roomBoard',
                                  width: 200,
                                },
                                {
                                  Header: 'Código do quarto',
                                  accessor: 'roomCode',
                                  width: 120,
                                },
                              ]}
                              subComponent={props => {
                                return (
                                  <Panel style={{ width: '100%' }}>
                                    <PanelHeader noButton title='Hóspedes' />

                                    <PanelBody>
                                      <Table
                                        data={props.row.original.paxs}
                                        loading={loading}
                                        columns={[
                                          {
                                            Header: 'Primeiro nome',
                                            accessor: 'firstName',
                                            width: 150,
                                          },
                                          {
                                            Header: 'Sobrenome',
                                            accessor: 'surname',
                                            width: 150,
                                          },
                                          {
                                            Header: 'E-mail',
                                            accessor: 'email',
                                            width: 250,
                                          },
                                          {
                                            Header: 'Telefone',
                                            accessor: 'phone',
                                            width: 120,
                                          },
                                          {
                                            Header: 'Tipo',
                                            accessor: 'type',
                                            width: 120,
                                            Cell: props => {
                                              return getSalePaxType(
                                                props.row.original.type,
                                              )
                                            },
                                          },
                                        ]}
                                      />
                                    </PanelBody>
                                  </Panel>
                                )
                              }}
                            />
                          </PanelBody>
                        </Panel>
                      )
                    }}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.tours.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Tours' />

                <PanelBody>
                  <Table
                    data={sale.tours}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Data',
                        accessor: 'date',
                        width: 120,
                        Cell: props => {
                          return (
                            <div>
                              <span>
                                {getDateFormated(
                                  props.row.original.departureDate,
                                  'DD/MM/YY',
                                )}
                              </span>

                              <br />

                              <span>
                                {getDateFormated(
                                  props.row.original.departureDate,
                                  'HH:mm',
                                )}
                              </span>
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Local',
                        accessor: 'name',
                        width: 400,
                      },
                      {
                        Header: 'Cidadee',
                        accessor: 'city',
                        width: 120,
                        Cell: props => {
                          return (
                            <div>
                              {props.row.original.city && (
                                <span>
                                  {props.row.original.city}
                                  {props.row.original.country &&
                                    ` (${props.row.original.country})`}
                                </span>
                              )}
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Fornecedor',
                        accessor: 'provider',
                        width: 120,
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.cruises.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Cruzeiro' />

                <PanelBody>
                  <Table
                    data={sale.cruises}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Nome',
                        accessor: 'name',
                        width: 150,
                        Cell: props => {
                          return (
                            <div>
                              <span>
                                <b>{props.row.original.name}</b>
                              </span>

                              {props.row.original.description && (
                                <>
                                  <br />

                                  <span>{props.row.original.description}</span>
                                </>
                              )}

                              {props.row.original.itineraryName && (
                                <>
                                  <br />

                                  <span>
                                    ({props.row.original.description})
                                  </span>
                                </>
                              )}
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'providerName',
                        accessor: 'providerName',
                        width: 150,
                      },
                      {
                        Header: 'Noites',
                        accessor: 'nights',
                        width: 100,
                      },
                      {
                        Header: 'Partida',
                        accessor: 'departure',
                        width: 250,
                        Cell: props => {
                          return (
                            <div>
                              {props.row.original.departurePortName && (
                                <>
                                  <span>
                                    {props.row.original.departurePortName}
                                  </span>

                                  <br />
                                </>
                              )}

                              {props.row.original.departurePortCode && (
                                <>
                                  <span>
                                    ({props.row.original.departurePortCode})
                                  </span>

                                  <br />
                                </>
                              )}

                              {props.row.original.departure && (
                                <>
                                  <span>
                                    {getDateFormated(
                                      props.row.original.departure,
                                      'DD/MM/YY',
                                    )}
                                  </span>

                                  <br />

                                  <span>
                                    {getDateFormated(
                                      props.row.original.departure,
                                      'HH:mm',
                                    )}
                                  </span>

                                  <br />
                                </>
                              )}
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Chegada',
                        accessor: 'arrival',
                        width: 250,
                        Cell: props => {
                          return (
                            <div>
                              {props.row.original.arrivalPortName && (
                                <>
                                  <span>
                                    {props.row.original.arrivalPortName}
                                  </span>

                                  <br />
                                </>
                              )}

                              {props.row.original.arrivalPortCode && (
                                <>
                                  <span>
                                    ({props.row.original.arrivalPortCode})
                                  </span>

                                  <br />
                                </>
                              )}

                              {props.row.original.arrival && (
                                <>
                                  <span>
                                    {getDateFormated(
                                      props.row.original.arrival,
                                      'DD/MM/YY',
                                    )}
                                  </span>

                                  <br />

                                  <span>
                                    {getDateFormated(
                                      props.row.original.arrival,
                                      'HH:mm',
                                    )}
                                  </span>

                                  <br />
                                </>
                              )}
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Embarcação',
                        accessor: 'shipName',
                        width: 150,
                        Cell: props => {
                          return (
                            <div>
                              {props.row.original.shipName}

                              {props.row.original.shipDescription && (
                                <>
                                  <br />

                                  <span>
                                    {props.row.original.shipDescription}
                                  </span>
                                </>
                              )}
                            </div>
                          )
                        },
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.transfers.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Transfers' />

                <PanelBody>
                  <Table
                    data={sale.transfers}
                    loading={loading}
                    columns={[
                      {
                        Header: () => null,
                        id: 'expander',
                        Cell: ({ row }) => (
                          <span {...row.getToggleRowExpandedProps()}>
                            <i
                              className={`
                  fas fa-caret-${row.isExpanded ? 'up' : 'down'}
                `}
                            />
                          </span>
                        ),
                        width: 40,
                      },
                      {
                        Header: 'Nome',
                        accessor: 'name',
                        width: 500,
                      },
                      {
                        Header: 'Início',
                        accessor: 'startDate',
                        width: 250,
                        Cell: props => {
                          return (
                            <div>
                              {props.row.original.startDate && (
                                <>
                                  <span>
                                    {getDateFormated(
                                      props.row.original.startDate,
                                      'DD/MM/YY',
                                    )}
                                  </span>

                                  <br />

                                  <span>
                                    {getDateFormated(
                                      props.row.original.startDate,
                                      'HH:mm',
                                    )}
                                  </span>
                                </>
                              )}
                            </div>
                          )
                        },
                      },
                      {
                        Header: 'Fim',
                        accessor: 'endDate',
                        width: 250,
                        Cell: props => {
                          return (
                            <div>
                              {props.row.original.endDate &&
                                props.row.original.endDate !==
                                  '0001-01-01T00:00:00' && (
                                  <>
                                    <span>
                                      {getDateFormated(
                                        props.row.original.endDate,
                                        'DD/MM/YY',
                                      )}
                                    </span>

                                    <br />

                                    <span>
                                      {getDateFormated(
                                        props.row.original.endDate,
                                        'HH:mm',
                                      )}
                                    </span>
                                  </>
                                )}
                            </div>
                          )
                        },
                      },
                    ]}
                    subComponent={props => {
                      return (
                        <Panel style={{ width: '100%' }}>
                          <PanelHeader noButton title='Segmentos' />

                          <PanelBody>
                            <Table
                              data={props.row.original.segments}
                              loading={loading}
                              columns={[
                                {
                                  Header: 'Data',
                                  accessor: 'date',
                                  width: 110,
                                  Cell: props => {
                                    return (
                                      <div>
                                        <span>
                                          {getDateFormated(
                                            props.row.original.date,
                                            'DD/MM/YY',
                                          )}
                                        </span>

                                        <br />

                                        <span>
                                          {getDateFormated(
                                            props.row.original.date,
                                            'HH:mm',
                                          )}
                                        </span>
                                      </div>
                                    )
                                  },
                                },
                                {
                                  Header: 'Descrição',
                                  accessor: 'name',
                                  width: 120,
                                },
                                {
                                  Header: 'Origem',
                                  accessor: 'origin',
                                  width: 150,
                                },
                                {
                                  Header: 'Destiny',
                                  accessor: 'destiny',
                                  width: 150,
                                },
                                {
                                  Header: 'Informações',
                                  accessor: 'type',
                                  width: 300,
                                  Cell: props => {
                                    return (
                                      <>
                                        {props.row.original.type && (
                                          <>
                                            <span>
                                              Tipo:&nbsp;
                                              <b>{props.row.original.type}</b>
                                            </span>

                                            <br />
                                          </>
                                        )}

                                        {props.row.original.transportType && (
                                          <>
                                            <span>
                                              Tipo transporte:&nbsp;
                                              <b>
                                                {
                                                  props.row.original
                                                    .transportType
                                                }
                                              </b>
                                            </span>

                                            <br />
                                          </>
                                        )}

                                        {props.row.original.number && (
                                          <>
                                            <span>
                                              Número:&nbsp;
                                              <b>{props.row.original.number}</b>
                                            </span>

                                            <br />
                                          </>
                                        )}
                                      </>
                                    )
                                  },
                                },
                              ]}
                            />
                          </PanelBody>
                        </Panel>
                      )
                    }}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.servicePackages && sale.servicePackages.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Pacotes de serviços' />

                <PanelBody>
                  <Table
                    data={sale.servicePackages}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Descrição',
                        accessor: 'name',
                        width: 500,
                      },
                      {
                        Header: 'Data',
                        accessor: 'date',
                        width: 250,
                        Cell: props => {
                          return (
                            <div>
                              {props.row.original.date && (
                                <>
                                  <span>
                                    {getDateFormated(
                                      props.row.original.date,
                                      'DD/MM/YY',
                                    )}
                                  </span>

                                  <br />

                                  <span>
                                    {getDateFormated(
                                      props.row.original.date,
                                      'HH:mm',
                                    )}
                                  </span>
                                </>
                              )}
                            </div>
                          )
                        },
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}

          {sale.values.length > 0 && (
            <>
              <Panel style={{ width: '100%' }}>
                <PanelHeader noButton title='Valores' />

                <PanelBody>
                  <Table
                    data={sale.values}
                    loading={loading}
                    columns={[
                      {
                        Header: 'Descrição',
                        accessor: 'info',
                        width: 220,
                        Cell: props => {
                          return getSaleValueInfo(props.row.original.info)
                        },
                      },
                      {
                        Header: 'Comissão a pagar',
                        accessor: 'commissionPayable',
                        Cell: props => {
                          if (props.row.original.commissionPayable === 0) {
                            return null
                          }

                          return FormatValueBRL(
                            props.row.original.commissionPayable,
                          )
                        },
                      },
                      {
                        Header: 'Comissão a receber',
                        accessor: 'commissionReceivable',
                        Cell: props => {
                          if (props.row.original.commissionReceivable === 0) {
                            return null
                          }

                          return FormatValueBRL(
                            props.row.original.commissionReceivable,
                          )
                        },
                      },
                      {
                        Header: 'Valor',
                        accessor: 'ammount',
                        width: 220,
                        Cell: props => {
                          return FormatValueBRL(props.row.original.ammount)
                        },
                      },
                    ]}
                  />
                </PanelBody>
              </Panel>
            </>
          )}
        </>
      )}
    </div>
  )
}
