import styled from 'styled-components'

export default function DocsList({ travelDocs }) {
  return (
    <>
      {travelDocs.length > 0 && (
        <Container>
          <Title>Vouchers</Title>
          <List>
            {travelDocs.map((e, i) => {
              return (
                <Item key={i}>
                  {e.docType === 'img' && <Icon className='fas fa-image' />}

                  {e.docType === 'pdf' && <Icon className='fas fa-file-pdf' />}

                  {e.docType !== 'pdf' && e.docType !== 'img' && (
                    <Icon className='fas fa-file' />
                  )}

                  <Link href={e.link} target='blank'>
                    <LinkDesc>
                      <span className='title'>{e.title}</span>

                      <span className='desc'>{e.desc}</span>
                    </LinkDesc>

                    <i className='fas fa-download' />
                  </Link>
                </Item>
              )
            })}
          </List>
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  padding: 8px;
`

const Title = styled.h3`
  padding: 0 0 0 40px;
  color: #d54a26;
  font-size: 1.6em;
  margin-bottom: 0.4em;
`
const Link = styled.a`
  width: calc(100% - 40px);
  background-color: #eaeaea;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  text-decoration: none;
  color: #777;

  &:hover {
    text-decoration: none;
    color: #777;
  }
`

const LinkDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-size: 16px;
    font-weight: 700;
  }

  .desc {
    font-size: 14px;
    font-weight: 500;
  }
`

const List = styled.ul`
  padding: 0;
  list-style-type: none;
`

const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Icon = styled.i`
  width: 40px;
  height: 40px;
  font-size: 28px;
  text-align: center;
  color: #d54a26;
`
