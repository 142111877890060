import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function PartnerCreate() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [partner, setPartner] = useState()
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/partner/${location.state.id}`,
        )

        setPartner(data)

        setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('id', partner.id)
      formData.append('avatar', data.avatar)
      formData.append('name', data.name)
      formData.append('desc', data.desc ?? '')

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/partner`,
        formData,
      )

      toast.success('Parceiro editado com sucesso.')

      return navigate(-1)
    } catch (error) {
      setLoading(false)

      toast.info('Não foi possível cadastrar parceiro.')
    }
  }

  async function onDelete() {
    try {
      if (window.confirm('Deseja realmente remover esse parceiro?')) {
        setLoading(true)

        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/partner/${location.state.id}`,
        )

        toast.success('Parceiro removido com sucesso.')

        return navigate(-1)
      }

      setLoading(false)
    } catch (error) {
      toast.info('Não foi possível cadastrar parceiro.')

      setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle title={'Parceiros'} />

      <Panel>
        <PanelHeader noButton title={'Cadastrar novo parceiro'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackDelete={onDelete}
            defaultValue={partner}
            disabled={loading}
            inputs={[
              {
                type: 'image',
                name: 'avatar',
                label: 'Logo do parceiro',
                sm: 12,
                maxWidth: '300px',
              },
              {
                type: 'text',
                name: 'name',
                label: 'Nome do parceiro',
                placeholder: 'Nome do parceiro',
                required: true,
                maxLength: 200,
                sm: 12,
              },
              {
                type: 'textarea',
                name: 'desc',
                label: 'Descrição do parceiro',
                placeholder: 'Descrição do parceiro',
                maxLength: 400,
                sm: 12,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
