import { useNavigate, useOutletContext, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultFormComponent from '~/components/DefaultFormComponent'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import * as Inputs from './inputs'
import { useEffect, useState } from 'react'

export default function TravelersCreate() {
  const { axiosApis } = GetAxios()
  const navigate = useNavigate()
  const [stage, setStage] = useState(false)
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [travelers] = useState(location?.state?.travelersOutTravel)
  const [ddi, setDdi] = useState({})
  const [selectDdi, setSelectDdi] = useState({})

  useEffect(() => {
    getOptionsDDI()
    setSelectDdi({
      label: '',
      value: '',
    })
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  async function onValidate(data) {
    if (!data.email && !data.phone) {
      toast.info('Informe email ou telefone.')

      return false
    }

    return true
  }

  async function onSubmitAdd(data) {
    try {
      setLoading(true)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travel/include-traveler-in-travel/${travel.id}`,
        data.traveler,
      )

      setLoading(false)
      toast.success(
        `${
          data.traveler.length == 1
            ? 'Viajante adicionado com sucesso!'
            : 'Viajantes adicionados com sucesso!'
        }`,
      )
      navigate(-1)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  async function onSubmitCreate(data) {
    try {
      setLoading(true)
      const formData = new FormData()

      for (let key in data) {
        formData.append(key, data[key])
      }

      formData.set('ddi', data.ddi?.value ?? 55)
      if (data.city) formData.set('city', data.city?.label ?? undefined)
      if (data.city) formData.set('cityId', data.city?.value ?? undefined)
      formData.append('travelId', travel.id)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/traveler`,
        formData,
      )

      setLoading(false)
      toast.success('Viajante criado com sucesso!')
      navigate(-1)
    } catch (error) {
      if (error && error.response.data) {
        toast.info(error.response.data)
      }

      setLoading(false)

      console.log(error)
    }
  }

  return (
    <Container>
      {travelers.length > 0 && !stage && (
        <DefaultFormComponent
          onReset={() => setStage(!stage)}
          onSubmit={onSubmitAdd}
          title={'Adicionar viajante'}
          inputs={Inputs.add(travelers)}
          submitLabel={'Adicionar viajante'}
          resetLabel={'Criar viajante'}
          loading={loading}
        />
      )}

      {((travelers.length == 0 && !stage) || stage) && (
        <DefaultFormComponent
          onSubmit={onSubmitCreate}
          onValidate={onValidate}
          title={'Adicionar viajante'}
          inputs={Inputs.create(ddi, selectDdi, setSelectDdi)}
          loading={loading}
        />
      )}
    </Container>
  )
}
