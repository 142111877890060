import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { EditButton } from '~/components/Button/styles'
import DefaultForm from '~/components/DefaultForm'
import { GetAxios } from '~/services/api'

export default function DetailsParticipantForm({ modalData, onClose }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const defaultData = modalData ? { ...modalData.details } : {}

  const navigate = useNavigate()

  async function onSubmit(data) {
    try {
      setLoading(true)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban`,
        {
          id: data.id,
          desc: data.desc,
        },
      )

      await onClose()

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function onClickDetails() {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban/get-participant-id/${defaultData.id}`,
      )

      setLoading(false)

      return navigate('/school/details', {
        state: {
          id: res.data,
        },
      })
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  return (
    <Container>
      <EditButton onClick={onClickDetails}>Ver todos os Dados</EditButton>

      <br></br>

      <DefaultForm
        defaultValue={defaultData}
        callbackSubmit={onSubmit}
        disabled={loading}
        inputs={[
          {
            type: 'textarea',
            name: 'desc',
            label: 'Observações',
            placeholder: 'Digite aqui...',
            sm: 12,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100;
`
