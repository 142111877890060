import { toast } from 'react-toastify'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import useModal from '~/hooks/useModal'
import { GetAxios } from '~/services/api'
import { KANBAN_LIST_FORMS_INPUT } from '../constants'

export default function EditListComponent({ data }) {
  const { axiosApis } = GetAxios()
  const { hideModal } = useModal()

  const defaultValue = {
    title: data.title,
    code: data.code,
    order: data.order,
    status: data.status,
    showTravelerButton: data.showTravelerButton,
    showProposalButton: data.showProposalButton,
    useAsLossRate: data.useAsLossRate,
    useAsConversionRate: data.useAsConversionRate,
    useAsInitContact: data.useAsInitContact,
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('code', data.code)
      formData.append('title', data.title)
      formData.append('status', data.status)
      formData.append('showTravelerButton', data.showTravelerButton)
      formData.append('showProposalButton', data.showProposalButton)
      formData.append('useAsLossRate', data.useAsLossRate)
      formData.append('useAsConversionRate', data.useAsConversionRate)
      formData.append('useAsInitContact', data.useAsInitContact)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban`,
        formData,
      )

      toast.success('Lista editada com sucesso!')

      hideModal()
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  async function onDelete() {
    try {
      if (window.confirm(`Deseja realmente excluir a lista ${data.title}?`)) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/${data.code}`,
        )

        hideModal()
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }
    }
  }

  async function onValidate(data) {
    if (data.title && !/[a-zA-Z0-9]{4,}/.test(data.title)) {
      toast.info(
        'O título da lista tem que conter letras ou números, e ter mais que 3 caracteres.',
      )

      return false
    }

    return true
  }

  return (
    <Container>
      <DefaultForm
        callbackSubmit={onSubmit}
        callbackDelete={onDelete}
        defaultValue={defaultValue}
        onValidate={onValidate}
        inputs={KANBAN_LIST_FORMS_INPUT}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
