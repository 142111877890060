import { useState } from 'react'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { RegisterNewButton } from '~/components/Button/styles'
import { GetAxios } from '~/services/api'

export default function SendToKanbanButton({ data }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(!data.alreadyInKanban)

  async function sendToKanban(e) {
    try {
      setLoading(true)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/participantskanban`,
        {
          id: e.id,
        },
      )

      toast.success(`"${e.fullName}" adicionado ao quadro com sucesso...`)

      setShow(false)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  return (
    <Container>
      {show && (
        <RegisterNewButton
          disabled={loading || data.alreadyInKanban}
          onClick={() => sendToKanban(data)}
          style={{ textAlign: 'center', borderRadius: '8px' }}
        >
          {loading ? (
            <Spinner color='#ccc' size={'sm'} />
          ) : (
            'Adicionar ao Quadro'
          )}
        </RegisterNewButton>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
