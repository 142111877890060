import { useEffect } from 'react'
import { LinkRegisterNewPanel } from '~/components/Link/styles'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import usePermissions from '~/hooks/usePermissions'
import { Container } from '~/styles/container'
import { SpaceBetweenDiv } from '~/styles/divs'
import { useState } from 'react'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import Table from '~/components/Table'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'

export default function List() {
  const { manageDestinys } = usePermissions()
  const { axiosApis } = GetAxios()
  const [destinys, setDestinys] = useState([])
  const [destinysOriginal, setDestinysOriginal] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    onSubmit()

    return () => {
      setDestinys([])
      setLoading(false)
    }
  }, [])

  async function showDetails(e) {
    navigate('/destinys/details', { state: { id: e } })
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperiencedestiny`,
        {
          params: {
            title: data?.title,
            cityId: data?.city?.value,
            flag: data?.flag,
          },
        },
      )

      setDestinys(res.data)

      setDestinysOriginal(res.data)

      return setLoading(false)
    } catch (error) {
      toast.info('Nenhum resultado encontrado.')

      return setLoading(false)
    }
  }

  async function onFilter(data) {
    setLoading(true)

    if (!data.tags && !data.city && !data.title) {
      setDestinys([])
      setLoading(false)
      onSubmit()
    }

    const destinysFiltered = destinysOriginal.filter(e => {
      if (
        (data.tags
          ? e.flag.toLowerCase().includes(data.tags.toLowerCase())
          : true) &&
        (data.city
          ? e.city.replace(' -', ',') === data.city?.label.replace(' -', ',')
          : true) &&
        (data.title
          ? e.title.toLowerCase().includes(data.title.toLowerCase())
          : true)
      ) {
        return true
      }

      return false
    })

    onSubmit([])

    setDestinys(destinysFiltered)

    setTimeout(() => setLoading(false), 500)
  }

  return (
    <Container>
      <h1 className='page-header'>
        Destinos
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'A Tela possui todos os destinos cadastrados pela Agencia, essa informação é única da agencia. Para facilitar temos integrações que trazem alguns dados para facilitar o cadastro.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader title='Filtros' noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => onFilter({})}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'title',
                label: 'Destino',
                sm: 12,
                lg: 4,
              },
              {
                type: 'city',
                name: 'city',
                label: 'Cidade',
                placeholder: 'Pesquise por uma cidade...',
                sm: 12,
                lg: 4,
                isClearable: true,
              },
              {
                type: 'text',
                name: 'flag',
                label: 'Tags',
                sm: 12,
                lg: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton>
          <SpaceBetweenDiv>
            <b>Resultado da pesquisa</b>

            {manageDestinys && (
              <LinkRegisterNewPanel to='create'>
                Novo Destino
              </LinkRegisterNewPanel>
            )}
          </SpaceBetweenDiv>
        </PanelHeader>

        <PanelBody>
          <Table
            columns={[
              {
                Header: 'Destino',
                accessor: 'title',
                width: 300,
              },
              {
                Header: 'Cidades',
                accessor: 'city',
                width: 300,
              },
              {
                Header: 'Tags',
                accessor: 'flag',
                width: 300,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    // <LinkTravelDetails
                    //   to={'details'}
                    //   state={{ id: props.row.original.id }}
                    // >
                    //   Detalhes
                    // </LinkTravelDetails>
                    <IconButton
                      id={props.row.index}
                      type={'details'}
                      onClick={() => showDetails(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            data={destinys}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
