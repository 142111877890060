import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import SaleDetails from '../components/SaleDetails'
import SaleDashboard from '../components/SaleDashboard'
import { toast } from 'react-toastify'
import { getDateDiff, getDateFormated, getMoment } from '~/functions/Date'
import {
  getSaleState,
  getSaleType,
  getSaleValuesJoined,
  splitSaleValues,
} from '~/functions/Sales'
import useModal from '~/hooks/useModal'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'
import IconButton from '~/components/IconButton'
import { getSalesColumns, getSalesFormFields } from './constants'
import { useSelector } from 'react-redux'

export default function SalesList() {
  const user = useSelector(state => state.auth.user)
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [dashboard, setDashboard] = useState([
    {
      title: 'Vendas Emitidas',
    },
    {
      title: 'Vendas Reservadas',
    },
    {
      title: 'Embarque Hoje',
    },
    {
      title: 'Embarque Próx. Semana',
    },
    {
      title: 'Embarque Este Mês',
    },
  ])
  const [filters, setFilters] = useState({
    consolidators: [],
    providers: [],
    issuers: [],
    saleTypes: [],
    states: [],
    airlines: [],
  })
  const { showModal } = useModal()

  const [defaultValueFilters, setDefaultValueFilters] = useState({
    start: getDateFormated(getMoment().add(-5, 'month'), 'YYYY-MM-DD'),
    end: getDateFormated(getMoment(), 'YYYY-MM-DD'),
  })

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const responseFilters = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/sales/filters`,
        )

        setFilters({
          ...responseFilters.data,
          consolidators: responseFilters.data.consolidators.map(x => ({
            label: x.label,
            value: x.value,
          })),
          saleTypes: responseFilters.data.saleTypes.map(x => ({
            label: getSaleType(x.label).info,
            value: x.value,
          })),
          states: responseFilters.data.states.map(x => ({
            label: getSaleState(x.label),
            value: x.value,
          })),
        })

        const response = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/sales`,
          {
            params: defaultValueFilters,
          },
        )

        setData(response.data)

        const responseDashboard = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/sales/dashboard`,
          {
            params: defaultValueFilters,
          },
        )

        setDashboard(responseDashboard.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    getSource()
  }, [])

  async function onSubmit(data) {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/sales`,
        {
          params: {
            start: data?.start,
            end: data?.end,
            checkIn: data.checkIn,
            checkOut: data.checkOut,
            locator: data?.locator,
            integrationId: data.consolidator ? data.consolidator.value : 0,
            saleType: data.saleType ? data.saleType.value : undefined,
            provider: data.provider ? data.provider.value : undefined,
            issuer: data.issuer ? data.issuer.value : undefined,
            state: data.state ? data.state.value : undefined,
            airline: data.airline ? data.airline.value : undefined,
            pax: data?.pax,
            agency: data?.agency,
            saleId: data?.saleId,
          },
        },
      )

      setData(response.data)

      const responseDashboard = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/sales/dashboard`,
        {
          params: {
            start: data?.start,
            end: data?.end,
            checkIn: data.checkIn,
            checkOut: data.checkOut,
            locator: data?.locator,
            integrationId: data.consolidator ? data.consolidator.value : 0,
            saleType: data.saleType ? data.saleType.value : undefined,
            provider: data.provider ? data.provider.value : undefined,
            issuer: data.issuer ? data.issuer.value : undefined,
            state: data.state ? data.state.value : undefined,
            airline: data.airline ? data.airline.value : undefined,
            pax: data?.pax,
            agency: data?.agency,
          },
        },
      )

      setDashboard(responseDashboard.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  async function onDashboardFilter(data) {
    if (!data.ids) {
      return
    }

    try {
      setLoading(true)

      let newData = []

      const chunkSize = 50

      for (let i = 0; i < data.ids.length; i += chunkSize) {
        const chunk = data.ids.slice(i, i + chunkSize)

        if (chunk.length > 0) {
          const response = await axiosApis.get(
            `${process.env.REACT_APP_ENDPOINT_APIS}/sales`,
            {
              params: {
                ids: JSON.stringify(chunk),
              },
            },
          )

          newData = newData.concat(response.data)
        }
      }

      setData(newData)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onValidate = data => {
    if ((data.start && !data.end) || (!data.start && data.end)) {
      toast.info('Preencha os campos "Data inicial" e "Data final".')

      return false
    }

    if (getMoment(data.start) > getMoment(data.end)) {
      toast.info('A "Data final" precisa ser maior que a "Data inicial".')

      return false
    }

    const diff = getDateDiff(data.start, data.end)

    if (diff.days > 186) {
      toast.info(
        'Informe um período de no máximo 6 meses para "Data inicial e final".',
      )

      return false
    }

    if ((data.checkIn && !data.checkOut) || (!data.checkIn && data.checkOut)) {
      toast.info('Preencha os campos "Embarques de" e "Embarques até".')

      return false
    }

    if (getMoment(data.checkIn) > getMoment(data.checkOut)) {
      toast.info('A "Embarques de" precisa ser maior que a "Embarques até".')

      return false
    }

    const diffCheckIn = getDateDiff(data.checkIn, data.checkOut)

    if (diffCheckIn.days > 186) {
      toast.info('Informe um período de no máximo 6 meses para Embarques.')

      return false
    }

    if (
      !data.start &&
      !data.end &&
      !data.checkIn &&
      !data.checkOut &&
      !data.locator &&
      !data.state &&
      !data.airline &&
      !data.saleType &&
      !data.consolidator &&
      !data.provider &&
      !data.pax &&
      !data.issuer &&
      !data.agency
    ) {
      toast.info('Preencha os filtros!')

      return false
    }

    return true
  }

  async function showDetails(e) {
    showModal({
      title: 'Detalhes da venda',
      desc: `${e.consolidator} - ${getSaleState(e.state)} - ${
        getSaleValuesJoined(e.locator) ?? ''
      }`,
      body: () => <SaleDetails data={e} />,
    })
  }

  async function onReset() {
    setDefaultValueFilters(undefined)

    setData([])

    var newDashboard = dashboard.map(e => {
      return {
        ...e,
        count: 0,
        ammount: 0,
      }
    })

    setDashboard(newDashboard)
  }

  return (
    <Container>
      <h1 className='page-header'>
        Vendas Consolidadas
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Na tela terá disponível todas as vendas emitidas através das integrações realizadas. Os dados são atualizados a cada 30 minutos. Para os dados serem visualizados é necessário o cadastro das integrações na tela Minha Agencia > Integrações.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <SaleDashboard
        data={dashboard}
        loading={loading}
        onDashboardFilter={onDashboardFilter}
      />

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={onReset}
            submitLabel={'Pesquisar'}
            defaultValue={defaultValueFilters}
            disabled={loading}
            inputs={getSalesFormFields({
              filters: filters,
              isOperator: user.isOperator,
            })}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton>
          <LinkRegisterNewTraveler to={'/sales-by-issuer'}>
            Vendas por Consultor
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={getSalesColumns({
              showDetails,
              isOperator: user.isOperator,
            })}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
