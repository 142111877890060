import { Navigate } from 'react-router-dom'
import usePermissions from '~/hooks/usePermissions'

export default function RedirectPage() {
  const { isMaster, isAdmin, isAgent, role } = usePermissions()

  return (
    <>
      {isMaster && <Navigate to={'/agencies'} />}

      {isAdmin && <Navigate to={'/schedule'} />}

      {isAgent && <Navigate to={'/schedule'} />}

      {role == 'library' && <Navigate to={'/destinys'} />}
    </>
  )
}
