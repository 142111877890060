import { Table } from 'reactstrap'
import SkeletonLine from '~/components/Skeleton'
import { HistoryContainer } from '../TravelerHistory/styles'

export default function HistorySkeleton() {
  return (
    <HistoryContainer>
      <SkeletonLine width={60} />

      <SkeletonLine />

      <SkeletonLine width={150} />

      <SkeletonLine />

      <SkeletonLine width={200} />

      <SkeletonLine width={120} />
    </HistoryContainer>
  )
}
