import { Outlet, Route } from 'react-router-dom'
import DefaultLayout from '~/pages/__layouts__/DefaultLayout'
import SalesBackofficeByIssuerList from './List'

export default function SalesBackofficeByIssuer() {
  return (
    <Route
      path='/sales-backoffice-by-issuer'
      element={
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      }
    >
      <Route index element={<SalesBackofficeByIssuerList />} />
    </Route>
  )
}
