import { useLocation } from 'react-router-dom'
import SidebarNavItem from './NavItem'
import { NavUlItems } from './styles'
import useMenus from '~/hooks/useMenus'
import { useState } from 'react'
import useHoldingBackofficeMenus from '~/hooks/useHoldingBackofficeMenus'
import context from '~/context/createContext'
import { useContext } from 'react'

export default function SidebarNav({ pageSettings }) {
  let location = useLocation()
  const menusHoldingBackoffice = useHoldingBackofficeMenus()
  const menusAgency = useMenus()
  const [showPopover, setShowPopover] = useState()
  const { handleProfile } = useContext(context)
  let menus = handleProfile ? menusHoldingBackoffice.menus : menusAgency.menus

  return (
    <NavUlItems minify={pageSettings.pageSidebarMinify}>
      {menus?.map((item, i) => {
        return (
          <SidebarNavItem
            data={item}
            location={location}
            pageSettings={pageSettings}
            keyItem={i}
            key={i}
            showPopover={showPopover}
            setShowPopover={setShowPopover}
          />
        )
      })}
    </NavUlItems>
  )
}
