import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import PreviewAppTheme from '~/components/PreviewAppTheme'
import { getBase64 } from '~/functions/File'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'

export default function AgencyCreate() {
  const navigate = useNavigate()
  const { axiosApis } = GetAxios()
  const [image, setImage] = useState()
  const [primaryColor, setPrimaryColor] = useState()
  const [secondaryColor, setSecondaryColor] = useState()
  const [groups, setGroups] = useState([])
  const [ddi, setDdi] = useState({})

  useEffect(() => {
    async function getSource() {
      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agencygroup`,
        )

        setGroups(data)
      } catch (error) {
        console.log(error)
      }
    }

    getSource()
    getOptionsDDI()
  }, [])

  async function getOptionsDDI() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/ddi`,
      )
      setDdi(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      formData.append('numAgent', data.numAgent)
      formData.append('image', data.image)
      formData.append('name', data.name)
      formData.append('desc', data.desc)
      formData.append('email', data.email)
      formData.append('phone', data.phone)
      formData.append('ddiValue', data.ddi.value)
      formData.append('cnpj', data.cnpj)
      formData.append('code', data.code)
      formData.append('primaryColor', data.primaryColor ?? '#000000')
      formData.append('secondaryColor', data.secondaryColor ?? '#000000')
      formData.append('promotionalText', data.promotionalText)

      if (data.enableChatCenter) {
        formData.append('enableChatCenter', data.enableChatCenter)
      }

      formData.append(
        'crmIncluded',
        data.crmIncluded ? data.crmIncluded : false,
      )
      formData.append('isOperator', data.isOperator ? data.isOperator : false)

      if (data.group) {
        formData.append('groupId', data.group.value)

        if (data.groupPartnershipType) {
          const groupPartnershipType = data.groupPartnershipType.value

          formData.append('groupPartnershipType', groupPartnershipType)
        }
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency`,
        formData,
      )

      return navigate(-1)
    } catch (error) {
      toast.info('Não foi possível criar a Agência.')
    }
  }

  async function onValidate(data) {
    if (
      (data.group && !data.groupPartnershipType) ||
      (!data.group && data.groupPartnershipType)
    ) {
      toast.info(
        'Ao selecionar o Grupo, é obrigatório selecionar o Vínculo com o Grupo.',
      )

      return false
    }

    return true
  }

  async function changeImage(e) {
    getBase64(e.target.value, setImage)
  }

  return (
    <Container>
      <PageTitle title={'Gestão de Agências'} />

      <Panel>
        <PanelHeader noButton title={'Nova agência'} />

        <PanelBody>
          <Row>
            <Col sm={12} xl={8}>
              <DefaultForm
                callbackReset={() => {
                  return
                }}
                callbackSubmit={onSubmit}
                onValidate={onValidate}
                inputs={[
                  {
                    type: 'image',
                    name: 'image',
                    label: 'Logo da agência',
                    placeholder: 'Logo da agência',
                    required: true,
                    onChange: changeImage,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                    maxWidth: '300px',
                  },
                  {
                    type: 'text',
                    name: 'name',
                    label: 'Nome da agência',
                    placeholder: 'Nome da agência',
                    required: true,
                    maxLength: 100,
                    sm: 12,
                    md: 12,
                    lg: 6,
                    xl: 4,
                  },
                  {
                    type: 'email',
                    name: 'email',
                    label: 'E-mail',
                    placeholder: 'E-mail',
                    required: true,
                    maxLength: 100,
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4,
                  },
                  {
                    type: 'select',
                    name: 'ddi',
                    label: 'DDI',
                    placeholder: 'Selecione...',
                    options: ddi,
                    required: true,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                  },
                  {
                    type: 'text',
                    name: 'phone',
                    label: 'Telefone',
                    placeholder: 'Telefone',
                    required: true,
                    mask: '(99) 99999-9999',
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                  },
                  {
                    type: 'text',
                    name: 'cnpj',
                    label: 'CNPJ',
                    placeholder: 'CNPJ',
                    required: true,
                    mask: '99.999.999/9999-99',
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4,
                  },
                  {
                    type: 'text',
                    name: 'code',
                    label: 'Código',
                    placeholder: 'Código',
                    required: true,
                    maxLength: 100,
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4,
                  },
                  {
                    type: 'color',
                    name: 'primaryColor',
                    label: 'Cor primária',
                    placeholder: 'Cor primária',
                    onChange: e => setPrimaryColor(e.target.value),
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'color',
                    name: 'secondaryColor',
                    label: 'Cor secundária',
                    placeholder: 'Cor secundária',
                    onChange: e => setSecondaryColor(e.target.value),
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'text',
                    name: 'promotionalText',
                    label: 'Texto promocional',
                    placeholder: 'Texto promocional',
                    required: true,
                    maxLength: 100,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 4,
                  },
                  {
                    type: 'number',
                    name: 'numAgent',
                    label: 'Número de consultores',
                    placeholder: 'Número de consultores',
                    required: true,
                    sm: 12,
                    md: 6,
                    lg: 4,
                    xl: 2,
                  },
                  {
                    type: 'check',
                    name: 'crmIncluded',
                    label: 'Plano Viagem + CRM?',
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'check',
                    name: 'isOperator',
                    label: 'É operadora?',
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    type: 'check',
                    name: 'enableChatCenter',
                    label: 'Ativar ChatCenter',
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2,
                  },
                  {
                    empty: true,
                    text: '',
                    sm: 8,
                  },
                  {
                    type: 'select',
                    name: 'group',
                    label: 'Grupo',
                    isClearable: true,
                    isSearchable: true,
                    options: groups,
                    sm: 12,
                    lg: 6,
                  },
                  {
                    type: 'select',
                    name: 'groupPartnershipType',
                    label: 'Vinculo com o grupo',
                    isClearable: true,
                    isSearchable: true,
                    options: [
                      { label: 'Holding', value: '0' },
                      { label: 'Matriz', value: '1' },
                      { label: 'Filial', value: '2' },
                      { label: 'Holding Backoffice', value: '3' },
                    ],
                    sm: 12,
                    lg: 6,
                  },
                ]}
              />
            </Col>

            <Col sm={12} xl={4}>
              <b
                style={{
                  margin: '2px 4px',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                Preview do App
              </b>

              <PreviewAppTheme
                image={image}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              />
            </Col>
          </Row>
        </PanelBody>
      </Panel>
    </Container>
  )
}
