import styled from 'styled-components'
import { FormGroup } from 'reactstrap'
import InputMask from 'react-input-mask'

export const Label = styled.label`
  margin: 2px 4px;
  font-weight: 600;
`

export const Group = styled(FormGroup)`
  width: 100%;
`

export const InputV = styled(InputMask)`
  height: 38px !important;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
`

export const Disabled = styled.button`
  right: 0px;
  border: none;
  background: transparent;
`

export const Search = styled.button`
  right: 0px;
  border: none;
  background: transparent;
`

export const Buscar = styled.button`
  right: 0px;
  margin-left: 16px;
  border: none;
  background: transparent;
  font-size: 13px;
  border-radius: 4px;
  background-color: white;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  font-style: sans-serif;
  padding: 7px 12px;
  font-weight: normal;
`

export const MessageError = styled.p`
  color: #a00;
  font-weight: bold;
  font-size: 11px;
`

export const MessageSuccess = styled.p`
  color: #1bc25b;
  font-weight: bold;
  font-size: 11px;
`

export const Color = styled.button`
  background-color: ${props => props.colorSelected};
  border: none;
  border-radius: 4px;
  width: 48px;
  height: 24px;
  margin: 0px 4px;
`
